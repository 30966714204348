import Phaser from "phaser";
import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { SwitchingTaskAnims, SwitchingTaskSheets } from "../../utils/assetsPaths/situations/work/switchingTask";
import { createBoss } from "../../utils/npcFactory.js/factory";

export default class SwitchingTaskSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1927, y: 200 };
        const mcPosition = { x: 2275, y: 353 };
        super(scene, markPosition, mcPosition, Badges.AIR);
        this.instructionLabel = "De pronto, tu jefe te cambia la tarea que estabas realizando, ¿qué haces?";
        this.optionsData = [{
            label: "Me enojo y no le hago caso.",
            onclick: this.runEvent(2500, () => this.refuse())
        }, {
            label: "Le pregunto la razón del cambio y realizo la nueva tarea.",
            onclick: this.runEvent(2500, () => this.dialogue())
        }, {
            label: "Hago la tarea que me indica.",
            onclick: this.runEvent(2500, () => this.switchTask())
        }]
    }

    preload() {
        Object.values(SwitchingTaskAnims).forEach(anim => this.scene.anims.create(anim));
    }

    refuse() {
        const anim = this.mcIsBoy ? SwitchingTaskAnims.MAN_IGNORE : SwitchingTaskAnims.WOMAN_IGNORE;
        this.scene.mc.play(anim.key)
            .once("animationcomplete", () => {
                this.addEvent(2000, () => addDialogTween(this.scene, "Mala desición"))
            })
    }

    dialogue() {
        const anim = this.mcIsBoy ? SwitchingTaskAnims.MAN_DIALOGUE : SwitchingTaskAnims.WOMAN_DIALOGUE;
        this.boss.sprite.setTexture(SwitchingTaskSheets.BOSS_BOX.key, 6);
        this.addEvent(1000, () => this.boss.sprite.setTexture(SwitchingTaskSheets.BOSS_BOX.key, 7));
        this.addEvent(2000, () => this.scene.mc.play(anim.key)
            .once("animationcomplete",
                () => addDialogTween(this.scene, "Buena desición", this.badge))
        )
    }

    switchTask() {
        const anim = this.mcIsBoy ? SwitchingTaskAnims.MAN_SWITCH_TASK : SwitchingTaskAnims.WOMAN_SWITCH_TASK;
        this.boss.sprite.setTexture(SwitchingTaskSheets.BOSS_BOX.key, 6)
        this.addEvent(1000, () => this.boss.sprite.setTexture(SwitchingTaskSheets.BOSS_BOX.key, 7));
        this.addEvent(2000, () => this.scene.mc.play(anim.key)
            .once("animationcomplete", () => {
                this.addEvent(2000, () => addDialogTween(this.scene, "Buena desición", this.badge))
            })
        )
    }

    reboot(onComplete) {
        this.boss.destroy();
        this.scene.follower.emit("onMovementComplete");
        onComplete();

    }

    start() {
        this.boss = createBoss(this.scene, 3111, 662);
        const anim = this.mcIsBoy ? SwitchingTaskAnims.MAN_START_TASK : SwitchingTaskAnims.WOMAN_START_TASK;
        const frame = this.mcIsBoy ? SwitchingTaskSheets.MAN_SWITCH : SwitchingTaskSheets.WOMAN_SWITCH;
        this.scene.mc
            .setFlipX(false)
            .play(anim.key)
            .once("animationcomplete", () => {
                this.scene.tweens.add({
                    targets: this.boss.sprite,
                    alpha: 1
                });
                this.boss.follower.goTo(new Phaser.Math.Vector2(2375, 353))
                    .onceMovementComplete(() => {
                        this.boss.sprite.setFlipX(true);
                        this.addEvent(1000, () =>
                            this.scene.mc.setTexture(frame.key, 11))
                        this.addEvent(2000, () => {
                            this.boss.sprite.toggleFlipX()
                                .play(SwitchingTaskAnims.BOSS_BOX.key)
                                .once("animationcomplete", () => {
                                    this.addEvent(2000, () => this.showHint())
                                })
                        })
                    })
            })
    }
}