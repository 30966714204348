import Phaser from 'phaser';
import { SceneKey } from '../utils/constants';
import {
    createBackgroundImage,
    createBtn, createGameLogo,
    createGameIcon, createUserTag, createMessageBox,
    createAccesibilityBtns, createZoomCamera, setZoomBtnOnclick
} from '../utils/factory';
import { Icons } from '../utils/assetsPaths/icons';
import { scaleImage } from '../../HabCogGame/utils/resize';

class MainMenuScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.MAIN_MENU });
    }

    create() {
        createBackgroundImage(this);
        createZoomCamera(this);
        createGameLogo(this);
        createGameIcon(this);
        createUserTag(this, this.game.sessionData);
        createMessageBox(this, "Selecciona una opción");
        createAccesibilityBtns(this);
        this.createBtns();
        this.speakAtStart();
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale);
        this.events.once("shutdown", () => this.scale.off("resize", scale))
        this.events.once("shutdown", () => this.game.voice.cancel());
        this.setBtnsInteractive();
    }

    createBtns() {
        this.playBtn = createBtn(this, "Jugar", Icons.PLAY);
        this.badgeBtn = createBtn(this, "Insignias", Icons.BADGES_MENU);
        this.settingsBtn = createBtn(this, "Ajustes", Icons.SETTINGS);
        this.instructionsBtn = createBtn(this, "Instrucciones", Icons.INSTRUCTIONS);
        this.exitBtn = createBtn(this, "Salir", Icons.EXIT);
        this.btns = [
            this.playBtn,
            this.badgeBtn,
            this.settingsBtn,
            this.instructionsBtn,
            this.exitBtn
        ];
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voice.speak(this.helpText);
        setTimeout(resumeInfinity, 5000)
    }

    stopVoice() {
        this.game.voice.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }
    setBtnsInteractive() {
        this.btns.forEach(btn => btn.setInteractive());
        this.playBtn.onclick(() => this.scene.start(SceneKey.WORLD_SELECTOR));
        this.badgeBtn.onclick(() => this.scene.start(SceneKey.BADGES, { key: this.scene.key }));
        this.settingsBtn.onclick(() => this.scene.start(SceneKey.CHARACTER_SELECTOR));
        this.instructionsBtn.onclick(() => this.scene.start(SceneKey.INSTRUCTIONS));
        this.exitBtn.onclick(() => this.scene
            .pause(SceneKey.MAIN_MENU)
            .launch(SceneKey.MODAL, { key: this.scene.key }));
        this.voiceOverBtn.onclick(() => this.game.voice.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);

    }

    resize() {
        const { width, height } = this.game.canvas;
        const btnWidth = width * 0.5;
        const btnHeight = height / 9;
        const { icon, background, userTag, msg, accesibilityBtns, btns } = this;

        background.setDisplaySize(width, height);
        scaleImage(icon, width / 2, height / 2, 25, 1);
        btns.forEach(btn => btn.resize(btnWidth, btnHeight, 25, 1));
        userTag.resize(width / 3, icon.getTopCenter().y, 25, 1);
        msg.resize(width / 2, height / 8, 25, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 2, height / 8, 25, 1));
        this.setElementsPositions()
    }

    setElementsPositions() {
        const DPR = window.devicePixelRatio;
        const { width, height } = this.game.canvas;
        const { logo, icon, accesibilityBtns, msg, btns, userTag } = this;
        const { displayHeight } = this.btns[0].background;
        const cellHeight = displayHeight * 1.25;
        const position = Phaser.Display.Align.CENTER;
        logo.setPosition(width / 4, height / 2);
        icon.setPosition(width * 0.75, height / 2);
        userTag.setPosition(width - userTag.background.displayWidth / 2 - 50 * DPR, icon.getTopCenter().y / 2);
        Phaser.Actions.GridAlign([msg, ...btns], {
            height: -1,
            cellHeight,
            position,
            x: width / 4,
            y: height / 2 - cellHeight
        });

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })
        scaleImage(logo, width / 2, msg.y, 100);
        logo.setPosition(width / 4, msg.y / 2);
    }
}
const SceneInstruction = "Estás en el menú principal del juego, aquí puedes acceder a distintas secciones del juego " +
    "presionando los siguientes botones:\n" +
    "El botón jugar, para iniciar una sesión de juego.\n" +
    "El botón ajustes, para ir al menú de ajustes y configurar varios parámetros del juego como la dificultad " +
    "y el tiempo de juego.\n" +
    "El botón instrucciones, para visualizar las indicaciones generales y reglas del juego.\n" +
    "El botón salir, para abandonar el juego.\n"
const resumeInfinity = () => {
    window.speechSynthesis.resume();
    // eslint-disable-next-line
    const timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
}

export default MainMenuScene;