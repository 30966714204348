import React, { Component } from "react";
import Footer from "../CommonComponents/Footer";
import { Grid, Image } from 'semantic-ui-react'
import "../../../App.css";
import { Link } from "react-router-dom";
import Universitieslogos from "../CommonComponents/Universitieslogos";

class GameTypeNavigator extends Component {
    componentDidMount() {

    }
    shouldComponentUpdate() {
        return true;
    }
    render() {
        return (<div className="App">
            <div className="page-container">
                <div className="content-wrap">
                    <Grid style={{ height: '82.5vh', marginTop: '4vh' }} verticalAlign='middle' textAlign="center">
                        <Grid.Row style={{ maxHeight: "20vh" }} >
                            <Image style={{ height: "20vh", width: "60vw" }}
                                src="/LudomingaUIAssets/commonAssets/LudomingaLogo2.png"  >
                            </Image>
                        </Grid.Row>
                        <Grid.Row verticalAlign="top" style={{ marginLeft: '5vw' }}>
                            <Grid verticalAlign='top' columns={3}>
                                <Grid.Column   >
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to="/ludoExactusCarousel">
                                            <Image className="menuButton" style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoExactusButton.png" />
                                        </Link>
                                    </Grid.Row>
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to="/ludoCheckCarousel">
                                            <Image className="menuButton" style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoCheckButton.png" />
                                        </Link>
                                    </Grid.Row>
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to={{
                                            pathname: "/ludoempleo",
                                            params: { organization_id: 1 },
                                        }} >
                                            <Image className="menuButton" style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoEmpleoButton.png" />
                                        </Link>
                                    </Grid.Row>

                                    <Grid.Row >
                                        <div><br></br></div>
                                        <div><br></br></div>
                                        <Link to="/" >
                                            <Image className="backButton" style={{ marginLeft: "10%", marginTop: "0%", padding: '0.25vh', width: '12vw' }}
                                                src="/LudomingaUIAssets/commonAssets/backButton.png" size="medium" />
                                        </Link></Grid.Row>
                                </Grid.Column>
                                <Grid.Column  >
                                    <Image src="/LudomingaUIAssets/commonAssets/hummingbird.png"
                                        style={{ height: "55vh", width: "35vw", padding: "0.15vw" }} ></Image>
                                </Grid.Column>
                                <Grid.Column  >
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to="/ludoAdivinadorCarousel">
                                            <Image className="menuButton" style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoAdivinadorButton.png" size="medium" />
                                        </Link>
                                    </Grid.Row>
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to="/ludoEspacialCarousel">
                                            <Image className="menuButton"
                                                style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoEspacialButton.png" size="medium" />
                                        </Link>
                                    </Grid.Row>
                                    <Grid.Row style={{ maxHeight: "20vh", marginTop: '2vh' }} >
                                        <Link to="/ludomundo">
                                            <Image className="menuButton"
                                                style={{ height: "16vh", width: "21vw", padding: "0.15vw" }}
                                                src="/LudomingaUIAssets/GameTypeNavigatorAssets/ludoMundoButton.png" size="medium" />
                                        </Link>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid>

                        </Grid.Row>

                    </Grid>

                    <Universitieslogos />
                </div>

                <Footer />
            </div>
        </div>);
    }
}


export default GameTypeNavigator;
