export const ludoAdivinatorGamesRoutes = [
    { name: "Perfiles", route: "/perfiles", image: "/LudomingaUIAssets/GameCardAssets/perfilesCard.png" },
    { name: "Luces y Sombras", route: "/lucesSombras", image: "/LudomingaUIAssets/GameCardAssets/lucesSombrasCard.png" },
    { name: "Caras Y gestos", route: "/carasGestos", image: "/LudomingaUIAssets/GameCardAssets/carasGestosCard.png" },
    { name: "Relacion", route: "/relacionEspacio", image: "/LudomingaUIAssets/GameCardAssets/relacionEspacioCard.png" }
];
export const ludoCheckGamesRoutes = [
    { name: "Clasifica", route: "/clasifica", image: "/LudomingaUIAssets/GameCardAssets/clasificaCard.png" },
    { name: "Algo en común", route: "/algoComun", image: "/LudomingaUIAssets/GameCardAssets/algoComunCard.png" },
    { name: "Intrusos", route: "/intrusos", image: "/LudomingaUIAssets/GameCardAssets/intrusosCard.png" },
    { name: "Gemelos", route: "/gemelos", image: "/LudomingaUIAssets/GameCardAssets/gemelosCard.png" },
    { name: "Algo falta", route: "/algoFalta", image: "/LudomingaUIAssets/GameCardAssets/algoFaltaCard.png" },

];
export const ludoEspacialGamesRoutes = [
    { name: "Reflejo Espejo", route: "/reflejoEspejo", image: "/LudomingaUIAssets/GameCardAssets/reflejoEspejoCard.png" },
    { name: "Rompecabezas", route: "/rompecabezas", image: "/LudomingaUIAssets/GameCardAssets/rompecabezasCard.png" },
    { name: "Paso a paso", route: "/pasoPaso", image: "/LudomingaUIAssets/GameCardAssets/pasoPasoCard.png" },

];
export const ludoExactusGamesRoutes = [
    { name: "Ventanas luminososa", route: "ventanasLuminosas", image: "/LudomingaUIAssets/GameCardAssets/ventanaLuminosasCard.png" },
    { name: "Todo cambia", route: "/todoCambia", image: "/LudomingaUIAssets/GameCardAssets/todoCambiaCard.png" },
    { name: "Cazando figuras", route: "/cazandoFiguras", image: "/LudomingaUIAssets/GameCardAssets/cazandoFigurasCard.png" },
    { name: "Recuerdo y ubico", route: "/recuerdoUbico", image: "/LudomingaUIAssets/GameCardAssets/recuerdoUbicoCard.png" },
    { name: "Aquí y allá", route: "/aquiAlla", image: "/LudomingaUIAssets/GameCardAssets/aquiAllaCard.png" },
    { name: "Fin finalin", route: "/finFinalin", image: "/LudomingaUIAssets/GameCardAssets/finFinalinCard.png" },
    { name: "Boing Boing", route: "/boingBoing", image: "/LudomingaUIAssets/GameCardAssets/boingBoingCard.png" },
    { name: "Preguntón", route: "/pregunton", image: "/LudomingaUIAssets/GameCardAssets/preguntonCard.png" },
    { name: "Igualitos", route: "/igualitos", image: "/LudomingaUIAssets/GameCardAssets/igualitosCard.png" },
    { name: "Vía de escape", route: "/viaEscape", image: "/LudomingaUIAssets/GameCardAssets/viaEscapeCard.png" }
];