import React, { useState, useEffect } from "react";
import Footer from "../CommonComponents/Footer";
import { Button, Card, Image, Grid, Icon, Form } from "semantic-ui-react";
import InterventionPlanService from "../../../services/InterventionPlanService";
import {
  Link,
  useParams,
} from "react-router-dom";
import OrganizationService from "../../../services/OrganizationService";

export default function IntervetionPlans(props) {
  const [interventionPlans, setInterventionPlans] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [search, setSearch] = useState([]);
  let { organization_id } = useParams();

  useEffect(() => {
    if (localStorage.getItem('user')) {


      InterventionPlanService.getInterventionPlans(organization_id).then(
        (json) => {
          setInterventionPlans(json.data);
        }
      );

      OrganizationService.getOrganization(organization_id).then((json) => {
        setOrganization(json.data);
      });
    }
    else {
      props.history.push(`/`);
    }
  }, [organization_id, props]);

  const handleInputChange = (event) => {
    //console.log(event.target.name)
    //console.log(event.target.value)
    setSearch({
      ...search,
      [event.target.name]: event.target.value.toUpperCase()
    })
    console.log(search)
  }

  function saveState(interventionPlan, organization_id) {
    localStorage.setItem('interventionPlan', JSON.stringify(interventionPlan))
    localStorage.setItem('organizationID', organization_id)
  }
  function eraseState() {
    if (localStorage.getItem('interventionPlan')) {
      localStorage.removeItem('interventionPlan')
    }
    if (localStorage.getItem('organizationID')) {
      localStorage.removeItem('organizationID')
    }
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user')
    }
  }



  return (
    <div className="App">
      <div className="page-container">
        <Grid style={{ "paddingTop": "2%" }} >
          <Grid.Row>
            <Grid.Column width={2} style={{ marginLeft: "5%" }}>
              <Image
                floated="left"
                src="/LudomingaUIAssets/commonAssets/hummingbird.png"
                size="large"

              ></Image>
            </Grid.Column>
            <Grid.Column width={10}>
              <Image
                src="/LudomingaUIAssets/commonAssets/LudomingaLogo2.png"
                verticalAlign="top"
              ></Image>
              <Form className="row" >
                <div className="col-md-3">
                  <Form.Input style={{ "padding": "10px", "marginTop": "10px" }} icon='search' size='big' type="text" placeholder="Buscar jugador"
                    onChange={handleInputChange} name="nombre"></Form.Input>
                </div>
              </Form>
            </Grid.Column>
            <Grid.Column width={2} style={{ "marginLeft": "3.5%" }}>
              <Card >
                <Card.Content>
                  <Card.Header>{organization.organization_name}</Card.Header>
                  <Card.Meta>
                    <span className="date">
                      {organization.organization_type_name}
                    </span>
                  </Card.Meta>
                  <Card.Description>
                    {localStorage.getItem('user')}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Link onClick={e => eraseState()} to="/">
                    <Icon name="user" />
                    Cerrar sesión
                  </Link>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={15}>
              <Card.Group style={{ marginLeft: "2%", "padding": "1%", marginBottom: "1%" }}>
                {interventionPlans.map((interventionPlan, i) => (
                  <span key={i}>{(interventionPlan.first_name + " " + interventionPlan.last_name).toUpperCase().match(search.nombre) &&
                    <Card.Group style={{ marginLeft: "2%", "padding": "1%", marginBottom: "1%" }}>
                      <Card  >
                        <Card.Content>
                          <Image
                            floated="right"
                            src={`/LudomingaUIAssets/GameTypeIconsAssets/${interventionPlan.note}.png`}
                            size="mini"
                          />
                          <Card.Header>
                            {interventionPlan.first_name}{" "}
                            {interventionPlan.last_name}
                          </Card.Header>
                          <Card.Meta>{interventionPlan.code}</Card.Meta>
                          <Card.Description>
                            <strong>{interventionPlan.name}</strong> <br></br>
                            {interventionPlan.executed_sessions} /{" "}
                            {interventionPlan.planned_sessions} sesiones
                        <br></br>
                            {interventionPlan.days} ({interventionPlan.time} min)
                      </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <Link
                            to={{
                              pathname: interventionPlan.url,
                              params: { interventionPlan, organization_id },
                            }}
                            className="ui two buttons"
                          >
                            <Button onClick={e => saveState(interventionPlan, organization_id)} basic color="green">
                              Jugar
                        </Button>
                          </Link>
                        </Card.Content>


                      </Card>
                    </Card.Group>
                  }</span>
                ))}
              </Card.Group>

            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
          </Grid.Row>
        </Grid>
        <Footer />
      </div>
    </div >
  );
}
