import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    SIZE_SHIFT: 0,
    COLOR_SHIFT: 1,
    SHAPE_SHIFT: 2
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Tamaño',
            header: 'Cambio de tamaño',
            description: 'El estímulo reduce su tamaño cada vez que es seleccionado',
            image: {
                key: 'shapeShiftImage',
                url: './HabCogGameAssets/settings/difficulty/sizeShift.png'
            }
        },
        {
            title: 'Color',
            header: 'Cambio de colores',
            description: 'El estímulo cambia de color cada vez que es seleccionado, la forma del ' +
                'estímulo seá un cuadrado.',
            image: {
                key: 'colorShiftImage',
                url: './HabCogGameAssets/settings/difficulty/colorShift.png'
            }
        },
        {
            title: 'Forma',
            header: 'Cambio de forma',
            description: 'El estímulo cambia de forma cada vez que es seleccionado.',
            image: {
                key: 'randomShiftImage',
                url: './HabCogGameAssets/settings/difficulty/shapeShift.png'
            }
        }
    ]
};

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);