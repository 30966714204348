import { CustomSettingsAssets } from "./settings";
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";
import { IntrudersPhotos } from "../../IntrudersGame/utils/assets";
import { GeomAssets } from "../../SpaceRelationGame/utils/assets";

export const MirrorReflectionIcons = {
    ROTATE: {
        key: 'rotateMirrorImage',
        url: './MirrorReflectionGameAssets/icons/rotateIcon.png'
    },
    FLIP: {
        key: 'flipMirrorImage',
        url: './MirrorReflectionGameAssets/icons/flipIcon.png'
    }
}

export const msgBackground = {
    key: 'msgBackground',
    url: './NosyGameAssets/msgBox.png'
};

export const sessionAssets = [
    ...Object.values(MirrorReflectionIcons),
    ...IntrudersPhotos,
    ...CustomSettingsAssets,
    ...SizeSettingAssets,
    ...TargetFeedbackAssets,
    ...TimeSettingAssets,
    ...GeomAssets.REGULAR.TWO_GEOM,
    msgBackground
]