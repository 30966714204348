import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { getDifferentRandomItem, getRandomElement, getRandomSubArray } from "../../HabCogGame/utils/functions";
import { PuzzleGamePhotosAssets, PuzzleGameIcons } from '../utils/assets';
import { Cursors } from '../../HabCogGame/utils/constants';
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { wrapVect2 } from '../../HabCogGame/gameObjects/vect2Wrapper';
import { DifficultySettingKey } from '../utils/settings';
import { Btns } from '../../HabCogGame/utils/assetsPaths/btn';
import { createBtn } from '../../HabCogGame/utils/gameObjectFactory';
const Container = Phaser.GameObjects.Container;
const Image = Phaser.GameObjects.Image;
const Graphics = Phaser.GameObjects.Graphics;
const Rectangle = Phaser.Geom.Rectangle;
const { BAD_TARGET } = TargetFeedback;
const SelectedLineWidth = 10 * window.devicePixelRatio;
const TilesLineWidth = 5 * window.devicePixelRatio;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.sTextures = PuzzleGamePhotosAssets;
        const initTexture = getRandomElement(this.sTextures);
        this.selected = undefined;
        this.createBtns(difficulty);
        const board = this.createAlignedBoard(difficulty, quantity, size, initTexture);
        this.graphics = new Graphics(this);
        this.board = getBoardPlugin(this, difficulty, this.rows, board);
        this.setBtnsInteractive(difficulty);
        this.gameArea.add([board, this.graphics]);
        this.startGameplay();
    }

    createBtns(difficulty) {
        this.btns = [];
        const { width, height } = this.getAvailableSize();
        const { CHECK, ROTATE } = PuzzleGameIcons;
        this.checkBtn = createBtn(this, 'Revisar', CHECK)
            .setPosition(0, height / 2)
            .resize(width / 4, height / 4, 100, 1);
        const { displayWidth } = this.checkBtn.background;
        this.checkBtn.setX(width - displayWidth / 1.5);
        this.btns.push(this.checkBtn);
        if (difficulty === DifficultySettingKey.ROTATE_AND_UNSORT) {
            this.swapFnBtn = createBtn(this, 'Girar', ROTATE, Btns.SECONDARY)
                .setPosition(0, height / 2)
                .resize(width / 4, height / 4, 100, 1);
            this.swapFnBtn.setX(displayWidth / 1.35);
            this.btns.push(this.swapFnBtn);
        }
        this.btns.forEach(btn => {
            btn.setInteractive(Cursors.pointerover);
            wrapVect2(btn);
        })
        this.gameArea.add(this.btns);
    }

    toggleFnBtn() {
        const { enable, enableRotate, enableMove } = this.board;
        const {ROTATE, MOVE} = PuzzleGameIcons;
        if (enable === enableRotate) {
            this.board.enable = enableMove;
            this.swapFnBtn.setLabel('Mover', MOVE);
        }
        else {
            this.board.enable = enableRotate;
            this.swapFnBtn.setLabel('Girar', ROTATE);
        }
        this.board.enable();
    }

    setBtnsInteractive(difficulty) {
        this.checkBtn.onclick(() => this.showFeedback());
        if (difficulty === DifficultySettingKey.ROTATE_AND_UNSORT) {
            this.board.enable = this.board.enableRotate;
            this.swapFnBtn.onclick(() => this.toggleFnBtn())
        }
    }

    getTiles() {
        return this.board.tiles;
    }

    getBoardTexture() {
        return this.boardTexture;
    }

    calculateBoardSize(difficulty, size, height) {
        const checkX = this.checkBtn.getLeftCenter().x;
        let availableSize;
        if (difficulty === DifficultySettingKey.ROTATE_AND_UNSORT) {
            const swapX = this.swapFnBtn.getRightCenter().x;
            const maxSize = checkX - swapX;
            availableSize = maxSize > height ? height : maxSize;
        }
        else
            availableSize = checkX > height ? height : checkX;
        this.targetSize = (availableSize - 200) * [0.5, 0.6, 0.8, 1][size];
    }

    setBoardPosition(difficulty, height, board, padding) {
        const checkX = this.checkBtn.getLeftCenter().x;
        board.setY(height / 2 - padding);
        let x;
        if (difficulty === DifficultySettingKey.ROTATE_AND_UNSORT) {
            const swapX = this.swapFnBtn.getRightCenter().x;
            x = (checkX + swapX) / 2;
        } else {
            x = checkX / 2
        }
        board.setX(x - padding);
    }

    createAlignedBoard(difficulty, quantity, size, texture) {
        const { height } = this.getAvailableSize();
        this.calculateBoardSize(difficulty, size, height);
        const boardLength = [4, 9][quantity];
        this.rows = Math.sqrt(boardLength);
        this.tileSize = this.targetSize / this.rows;
        const padding = this.rows === 2 ? this.tileSize / 2 : this.tileSize;
        const board = createGameBoard(this, this.rows, this.tileSize, texture)
        this.setBoardPosition(difficulty, height, board, padding)
        return board;
    }

    addFail() {
        super.addFail();
        const padding = this.rows === 2 ? this.tileSize / 2 : this.tileSize;
        const { x, y } = this.board.tiles;
        const img = new Image(this, x + padding, y + padding, BAD_TARGET.key)
            .setDisplaySize(this.targetSize, this.targetSize);
        this.tweens.add({
            targets: img,
            delay: 500,
            alpha: { from: 1, to: 0 },
            duration: 500,
            onComplete: () => img.destroy()
        });
        this.gameArea.add(img);
    }

    addHit() {
        super.addHit();
        removeBoardInteractive(this.board.tiles);
        this.graphics.clear();
        this.setBtnsVisible(false);
        this.time.addEvent({
            callback: () => this.startGameplay(),
            delay: 2500
        })
    }

    showFeedback() {
        const isCorrect = this.board.check();
        isCorrect ? this.addHit() : this.addFail();
    }

    setBtnsVisible(visible) {
        this.btns.forEach(btn => btn.setVisible(visible))
    }

    onHintClick(img) {
        img.destroy();
        const { tiles } = this.board;
        this.setBtnsVisible(true);
        setBoardVisible(tiles, true);
        strokeBoardTiles(this.graphics, tiles);
        setBoardTexture(tiles, this.rows, this.boardTexture);
        this.board.rearrange();
        this.board.enable();
    }

    startGameplay() {
        const { width, height } = this.getAvailableSize();
        const { tiles } = this.board;
        this.boardTexture = getDifferentRandomItem(this.sTextures, this.boardTexture);
        this.graphics.clear();
        this.setBtnsVisible(false);
        setBoardVisible(tiles, false);
        const hint = new Image(this, width * 0.5, height / 2, this.boardTexture.key)
            .setDisplaySize(this.targetSize, this.targetSize)
            .setInteractive(Cursors.pointerover)
            .on('pointerdown', () => this.onHintClick(hint));
        this.gameArea.add(hint);
    }
}

const getBoardPlugin = (scene, difficulty, rows, board) => {
    const getTiles = () => scene.getTiles();
    const getTexture = () => scene.getBoardTexture();
    switch (difficulty) {
        case DifficultySettingKey.ROTATE:
            return {
                rearrange: () => rotateBoardTiles(getTiles()),
                enable: () => enableTileRotation(getTiles()),
                check: () => checkRotations(getTiles()),
                tiles: board
            }
        case DifficultySettingKey.UNSORT:
            return {
                rearrange: () =>
                    unsortBoardTiles(getTiles(), getTexture(), rows),
                enable: () => enableTileMovement(scene, getTiles()),
                check: () => checkPositions(getTiles()),
                tiles: board
            };
        default:
            return {
                rearrange: () => {
                    rotateBoardTiles(getTiles());
                    unsortBoardTiles(getTiles(), getTexture(), rows);
                },
                enableRotate: () => enableTileRotation(getTiles()),
                enableMove: () => enableTileMovement(scene, getTiles()),
                check: () => checkRotations(getTiles()) &&
                    checkPositions(getTiles()),
                tiles: board
            }
    }
}

const enableTileMovement = (scene, board) => {
    board.getAll().forEach(child => child
        .off('pointerdown')
        .on('pointerdown', () => {
            const { selected, graphics } = scene
            if (selected) {
                const { x, y, currentIndex: index } = selected;
                selected.setPosition(child.x, child.y);
                selected.currentIndex = child.currentIndex;
                child.setPosition(x, y);
                child.currentIndex = index;
                scene.selected = undefined;
                strokeBoardTiles(graphics, board);
            }
            else {
                scene.selected = child;
                strokeTile(graphics, child, board);
            }
        }))
}

const getSpriteTexture = (texture, rows) =>
    `${texture.key}-spritesheet${rows}x${rows}`

const setBoardVisible = (board, visible) =>
    board.getAll().forEach(child =>
        child.setVisible(visible));

const setBoardTexture = (board, rows, texture) => board.getAll()
    .forEach((img, index) => img
        .setTexture(getSpriteTexture(texture, rows), index));

const rotateBoardTiles = (board) =>
    board.getAll().forEach(child => child
        .setAngle(getRandomElement([90, 180, 270])));

const enableTileRotation = (board) =>
    board.getAll().forEach(child => child.off('pointerdown')
        .on('pointerdown', () => child.setAngle(child.angle + 90)));

const removeBoardInteractive = (board) => board.getAll()
    .forEach(child => child.off('pointerdown'));

const checkRotations = (board) => {
    let finished = true;
    board.getAll().forEach(child => {
        if (child.angle % 360 !== 0)
            finished = false
    });
    return finished;
};

const checkPositions = (board) => {
    let finished = true;
    board.getAll().forEach(child => {
        if (child.destIndex !== child.currentIndex)
            finished = false;
    });
    return finished
};

const unsortBoardTiles = (board, texture, rows) => {
    const indexArray = createIndexArray(board.getAll().length);
    relocateTilePositions(board);
    board.getAll().forEach((child, index) => {
        child.setTexture(`${texture.key}-spritesheet${rows}x${rows}`, indexArray[index]);
        child.destIndex = indexArray[index];
        child.currentIndex = index;
    })
};

const relocateTilePositions = (board) => {
    board.getAll().forEach(child => {
        const { x, y } = child.originalPosition;
        child.setPosition(x, y);
    })
};

const createGameBoard = (scene, rows, tileSize, texture) => {
    const spriteTexture = getSpriteTexture(texture, rows);
    const board = [];
    for (let i = 0; i < rows; i++)
        for (let j = 0; j < rows; j++) {
            const x = j * tileSize;
            const y = i * tileSize;
            const tile = new Image(scene, x, y, spriteTexture, i + j)
                .setDisplaySize(tileSize, tileSize)
                .setInteractive(Cursors.pointerover);
            tile.originalPosition = { x, y };
            board.push(tile);
        }
    return new Container(scene, 0, 0, board);
};

const strokeTile = (graphics, tile, board) => {
    const { displayWidth, displayHeight } = tile;
    let x = board.x + tile.x - displayWidth / 2;
    let y = board.y + tile.y - displayHeight / 2;
    const rect = new Rectangle(x, y, displayWidth, displayHeight);
    graphics.lineStyle(SelectedLineWidth, 0xFFFF00).strokeRectShape(rect);
};

const strokeBoardTiles = (graphics, board) => {
    graphics.clear();
    graphics.lineStyle(TilesLineWidth, 0xBF42F5);
    board.getAll().forEach(child => {
        const { displayWidth, displayHeight } = child;
        const x = board.x + child.x - child.displayWidth / 2;
        const y = board.y + child.y - child.displayHeight / 2;
        const rect = new Rectangle(x, y, displayWidth, displayHeight);
        graphics.strokeRectShape(rect);
    });
};

const createIndexArray = (length) => {
    const array = [];
    for (let i = 0; i < length; i++)
        array.push(i);
    return getRandomSubArray(array, array.length)
};
