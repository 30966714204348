import phaser from 'phaser'
import buttonComponent from '../Button/button'
import { buttonPressAnimation, rotationAnimation } from '../../Functions/AnimatedFunctions'
import { AlertModalStructure } from './AlertModalStructure'
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions'
import score from '../../Services/score'




export default class AlertModal extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, title, finalScore, interventionID) {
        super(scene, x, y)

        this.workshopImage = null
        this.workshopTitle = null
        this.workshopDescription = null
        this.button = null
        this.scene = scene
        this.intents = 0;
        this.generated(scene, width, heigth, title, finalScore, interventionID)

    }

    generated(scene, width, heigth, title, finalScore, interventionID) {
        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'modalBackground')
        this.background.setDisplaySize(width, heigth);

        //Image
        var old = AlertModalStructure.message.scale
        AlertModalStructure.message.scale = scaleGameObject(this.scene, AlertModalStructure.message.scale)
        AlertModalStructure.message.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, 60)
        this.text = new phaser.GameObjects.Text(scene, AlertModalStructure.message.scale.objectPositionX,
            AlertModalStructure.message.scale.objectPositionY, title,
            AlertModalStructure.message.style)
        this.text.setOrigin(0.5)
        this.text.setWordWrapWidth(AlertModalStructure.message.scale.objectWidth)
        AlertModalStructure.message.scale = old
        //spiner
        old = AlertModalStructure.loadImage.scale
        AlertModalStructure.loadImage.scale = scaleGameObject(this.scene, AlertModalStructure.loadImage.scale)
        this.spiner = new phaser.GameObjects.Image(scene, AlertModalStructure.loadImage.scale.objectPositionX, AlertModalStructure.loadImage.scale.objectPositionY, 'spiner')
        this.spiner.setDisplaySize(AlertModalStructure.loadImage.scale.objectWidth, AlertModalStructure.loadImage.scale.objectHeight)
        this.spiner.setOrigin(0.5)
        //activation spiner animation
        rotationAnimation(scene, this.spiner)
        this.spiner.setVisible(false)
        AlertModalStructure.loadImage.scale = old
        //buttons
        old = AlertModalStructure.againButton.scale
        AlertModalStructure.againButton.scale = scaleGameObject(this.scene, AlertModalStructure.againButton.scale)
        this.againButton = new buttonComponent(scene, AlertModalStructure.againButton)
        this.againButton.setInteractive().on('pointerdown', () => {
            buttonPressAnimation(scene, this.againButton)
            this.createReport(scene, interventionID, finalScore)
        })
        this.againButton.setVisible(false)
        AlertModalStructure.againButton.scale = old
        //retry Button
        old = AlertModalStructure.retryButton.scale
        AlertModalStructure.retryButton.scale = scaleGameObject(this.scene, AlertModalStructure.retryButton.scale)
        this.retryButton = new buttonComponent(scene, AlertModalStructure.retryButton, 'buttonOutline')
        this.retryButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            buttonPressAnimation(scene, this.retryButton)
            this.createReport(scene, interventionID, finalScore)
        })
        AlertModalStructure.retryButton.scale = old
        //withoutButton
        old = AlertModalStructure.withoutButton.scale
        AlertModalStructure.withoutButton.scale = scaleGameObject(this.scene, AlertModalStructure.withoutButton.scale)
        this.withoutButton = new buttonComponent(scene, AlertModalStructure.withoutButton, 'buttonOutline')
        this.withoutButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            buttonPressAnimation(scene, this.withoutButton)
            this.scene.sound.play('clickSound')

        }).on('pointerup', () => {
            scene.scene.start("Workshops")
        })
        AlertModalStructure.withoutButton.scale = old

        //withoutSubmitButton
        old = AlertModalStructure.withoutSubmitButton.scale
        AlertModalStructure.withoutSubmitButton.scale = scaleGameObject(this.scene, AlertModalStructure.withoutSubmitButton.scale)
        this.withoutSubmitButton = new buttonComponent(scene, AlertModalStructure.withoutSubmitButton, 'buttonOutline')
        this.withoutSubmitButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            buttonPressAnimation(scene, this.withoutSubmitButton)
            this.scene.sound.play('clickSound')

        }).on('pointerup', () => {
            scene.scene.start("Workshops")
        })
        AlertModalStructure.withoutSubmitButton.scale = old

        if (interventionID === 0) {
            this.add([this.background, this.text, this.spiner, this.againButton, this.withoutSubmitButton])
        } else {
            this.add([this.background, this.text, this.spiner, this.againButton, this.retryButton, this.withoutButton])
        }
        this.setSize(width, heigth)
    }
    createReport(scene, id, finalscore) {

        this.text.text = 'Enviando'
        this.scene.sound.play('clickSound')
        this.intents++
        console.log(this.intents)
        this.againButton.setVisible(false)
        this.spiner.setVisible(true)
        this.retryButton.setVisible(false)
        this.withoutButton.setVisible(false)
        return score.postscore(id, finalscore)
            .then(() => {
                this.spiner.setVisible(false)
                this.text.text = 'Resultados enviados exitosamente'
                setTimeout(function () { scene.scene.start("Workshops") }, 1000);

            })
            .catch(error => {
                this.text.text = 'Error al enviar los resultados'
                if (this.intents <= 3) {
                    this.spiner.setVisible(false)
                    this.againButton.setVisible(true)
                }
                else {
                    this.spiner.setVisible(false)
                    this.retryButton.setVisible(true)
                    this.withoutButton.setVisible(true)
                }
            })
    }
}
