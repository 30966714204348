import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { Outlines } from '../utils/assetsPaths/outlines';
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image;


export default class AccesibilityButton extends Phaser.GameObjects.Container {

    constructor(scene, x, y, iconTexture) {
        super(scene, x, y)
        this.background = new Image(scene, 0, 0, Btns.ACCESIBILITY.key);
        this.icon = new Image(scene, 0, 0, iconTexture.key || iconTexture);
        this.add([this.background, this.icon]);
        this.addOutline();
    }

    setIcon(texture) {
        this.icon.setTexture(texture)
        console.log(this.icon)
    }

    addOutline() {
        this.outline = new Image(this.scene, 0, 0, Outlines.ACCESIBILITY.key).setVisible(false);
        this.add(this.outline).sendToBack(this.outline);
    }

    setInteractive() {
        this.background.setInteractive({ useHandCursor: true });
        this.setEvents();
        return this;
    }

    onclick(callback) {
        this.background.on('pointerdown', callback)
    }

    setEvents() {
        this.background
            .on('pointerover', () => this.addScaleTween(1.25, true))
            .on('pointerout', () => this.addScaleTween(1, false))
    }

    addScaleTween(scale, visible) {
        this.scene.tweens.add({
            targets: this, ease: 'Power3', scale, duration: 125,
            onStart: () => this.outline.setVisible(visible)
        })
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier)
        this.icon.setScale(this.background.scale);
        this.outline.setScale(this.background.scale);
    }
}