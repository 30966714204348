import Phaser from "phaser";
import { GameFont } from "../utils/constants";
import { scaleImage } from "../utils/resize";
const msgBackground = {
    key: 'msgBackground',
    url: './NosyGameAssets/msgBox.png'
};
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class DesignationMsgBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, title) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, msgBackground.key);
        this.title = new Text(scene, 0, 0, title, TextStyle).setOrigin(0.5);
        this.add([this.background, this.title]);
    }

    setText(text) {
        this.title.setText(text);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        const { displayHeight } = this.background;
        this.title.setFontSize(`${displayHeight * 0.4}px`);
    }
}

const TextStyle = {
    fontFamily: GameFont,
    color: 'white',
    fontSize: '3em'
};
