export const CustomGameFont = 'LudoMundoFont';
export const GameFont = `${CustomGameFont}, Helvetica, Arial`;

export const baseUrl = 'SocialInclusionAssets';

export const SceneKey = {
    BOOT: 'bootSplashScene',
    SPLASH: 'splashScene',
    MAIN_MENU: 'mainMenuScene',
    INSTRUCTIONS: 'instructionsScene',
    CHARACTER_SELECTOR: 'characterSelectorScene',
    WORLD_SELECTOR: 'worldSelectorScene',
    CITY_WORLD: 'cityWorldScene',
    HOME_WORLD: 'homeWorldScene',
    JOB_WORLD: 'jobWorldScene',
    BADGES: 'badgesScene',
    MODAL: 'modalScene'
};

export const CITY_MAP = {
    background: {
        key: 'cityMapBackground',
        url: './SocialInclusionAssets/maps/background.jpg'
    }
}
