import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { getDifferentRandomItem, getRandomElement } from "../../HabCogGame/utils/functions"
import { Cursors, HexColor } from "../../HabCogGame/utils/constants";

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, size } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.targetScale = [0.25, 0.50, 0.75, 1][size];
        this.expandTime = [20000, 15000, 10000, 5000][difficulty];
        this.setLineSpaces();
        this.startGameplay();
    }

    setLineSpaces() {
        const { width, height } = this.getAvailableSize();
        this.verticalSpaces = getGeomSpaces(width);
        this.horizontalSpaces = getGeomSpaces(height);
        this.gameplayFns = [
            (color) => this.createVerticalLine(width, height, color),
            (color) => this.createHorizontalLine(width, height, color)
        ];
    }

    startGameplay(color) {
        getRandomElement(this.gameplayFns)(color);
    }

    createVerticalLine(width, height, color) {
        const x = getRandomElement(this.verticalSpaces);
        const y = getRandomElement([0, height]);
        const nextColor = getRandomHexColor(color);
        const size = width / 4 * this.targetScale;
        const geom = this.add.rectangle(x, y, 1, 1, nextColor)
            .setDisplaySize(size, 1);
        geom.setOrigin(0, geom.y === 0 ? 0 : 1);
        this.gameArea.add(geom);
        this.createTween(geom, geom.scaleX, height);
    }

    playWhistleSound() {
        const sound = getRandomElement(['WHISTLE_UP', 'WHISTLE_DOWN']);
        this.audioProvider[sound].play();
    }


    createHorizontalLine(width, height, color) {
        const x = getRandomElement([0, width]);
        const y = getRandomElement(this.horizontalSpaces);
        const nextColor = getRandomHexColor(color);
        const size = height / 4 * this.targetScale;
        const geom = this.add.rectangle(x, y, 1, 1, nextColor)
            .setDisplaySize(1, size);
        geom.setOrigin(geom.x === 0 ? 0 : 1, 0);
        this.gameArea.add(geom);
        this.createTween(geom, width, geom.scaleY);
    }

    createTween(geom, scaleX = 1, scaleY = 1) {
        this.tweens.add({
            targets: geom,
            duration: this.expandTime,
            ease: 'Power0',
            scaleX,
            scaleY,
            onStart: this.playWhistleSound(),
            onComplete: () => {
                geom.setInteractive(Cursors.pointerover)
                    .once('pointerdown', () => this.onPointerdown(geom));
                this.gameZone.on('pointerdown', () => this.addFail());
            }
        })
    }

    onPointerdown(geom) {
        const color = geom.fillColor;
        geom.destroy();
        this.gameZone.off('pointerdown');
        this.addHit();
        this.startGameplay(color);
    }
}

const getGeomSpaces = (displaySize) => {
    const spaces = [];
    const start = displaySize / 15;
    const to = displaySize * 3 / 4;
    const step = displaySize / 4;
    for (let i = start; i < to; i += step)
        spaces.push(i);
    return spaces
};

const getRandomHexColor = (oldColor) =>
    getDifferentRandomItem(Object.values(HexColor), oldColor);
