import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { Icons } from '../utils/assetsPaths/icons';
import { ModalIcons } from '../utils/assetsPaths/modalIcons';
import ScoreLabel from './scoreLabel';
import Button from './button';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';

const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
const DOMElement = Phaser.GameObjects.DOMElement;

export default class Modal extends Phaser.GameObjects.Container {

    constructor(scene, x, y, headerIconTexture = ModalIcons.QUIT_HEADER, title, description, score, blockTextArea = true) {
        super(scene, x, y)
        this.background = new Image(scene, 0, 0, Btns.MODAL.key);
        this.icon = new Image(scene, 0, 0, headerIconTexture.key).setOrigin(-0.5, -0.5);
        this.headerLabel = new Text(scene, 0, 0, title, ModalTextStyle).setOrigin(0.5);
        this.descriptionLabel = new Text(scene, 0, 0, description, ModalTextStyle).setOrigin(0.5, 0);
        this.resultsLabel = new Text(scene, 0, 0, 'Resultados', ModalTextStyle).setOrigin(0.5, 0);
        this.feedbackLabel = new Text(scene, 0, 0, 'Comentarios', ModalTextStyle).setOrigin(0.5, -1);
        this.finishBtn = new Button(scene, 0, 0, 'Finalizar', Icons.RETURN, Btns.SECONDARY);
        this.add([
            this.background,
            this.icon,
            this.headerLabel,
            this.descriptionLabel,
            this.resultsLabel,
            this.feedbackLabel,
            this.finishBtn
        ]);
        this.addScoreLabels(score);
        this.addTextArea(blockTextArea);
    }

    addScoreLabels(score) {
        const scene = this.scene;
        const { attempts, hits, fails, time } = score;
        this.attemptsLabel = new ScoreLabel(scene, 0, 0, ModalIcons.ATTEMPTS, 'Intentos', `${attempts}`);
        this.hitsLabel = new ScoreLabel(scene, 0, 0, ModalIcons.HITS, 'Aciertos', `${hits}`);
        this.failsLabel = new ScoreLabel(scene, 0, 0, ModalIcons.FAILS, ' Fallos', `${fails}`);
        this.timeLabel = new ScoreLabel(scene, 0, 0, ModalIcons.TIME, 'Tiempo', `${parseTime(time)}`);
        this.scoreLabels = [
            this.timeLabel,
            this.attemptsLabel,
            this.hitsLabel,
            this.failsLabel
        ]
        this.add(this.scoreLabels);
    }

    addTextArea(blockTextArea) {
        const { displayWidth } = this.background;
        const width = displayWidth * 0.8;
        const height = this.finishBtn.y - this.feedbackLabel.getBottomCenter().y;
        const fontSize = this.icon.displayHeight / 3;
        const placeholder = blockTextArea ? 'Usuario invitado no puede ingresar comentarios ni guardar resultados en Ludominga.' :
            'Ingrese aquí los comentarios de la sesión de trabajo';
        const style = `width: ${width}px; height: ${height}px; font-size: ${fontSize}px; resize: none; font-family: ${GameFont}`;
        const defaultStyle = `id="username" name="username" rows="4" maxlength="1024" cols="50"`;
        const html = `<textarea ${blockTextArea ? 'readonly' : ''} type="text" placeholder="${placeholder}" ${defaultStyle} style="${style}"></textarea>`
        this.textArea = new DOMElement(this.scene, 0, 0).createFromHTML(html);
        this.textArea.setOrigin(0);
        this.add(this.textArea);
    }

    setInteractive() {
        this.finishBtn.setInteractive();
        this.icon.setInteractive({ useHandCursor: true })
            .on('pointerover', () => this.addIconTween(this.icon.resizedScale * 1.15))
            .on('pointerout', () => this.addIconTween(this.icon.resizedScale));
        return this;
    }

    onExit(callback) {
        this.finishBtn.onclick(callback);
        this.icon.on('pointerdown', callback)
    }

    getComments() {
        return document.getElementById('username').value;
    }

    addIconTween(scale) {
        this.scene.tweens.add({
            targets: this.icon,
            ease: 'Power3',
            scale,
            duration: 250
        })
    }

    resizeTextArea() {
        const textArea = document.getElementById('username');
        const { displayWidth } = this.background;
        const width = displayWidth * 0.75;
        const height = (this.finishBtn.y - this.feedbackLabel.y) / 2;
        textArea.style.width = `${width}px`
        textArea.style.height = `${height}px`;
        textArea.style.fontSize = `${this.icon.displayHeight / 4}px`
        this.textArea.setPosition(-width / 2, this.feedbackLabel.getBottomCenter().y);
        this.textAreaPosition = {
            x: this.textArea.x,
            y: this.textArea.y
        }
    }

    resizeScoreLabels() {
        const { displayWidth } = this.background;
        const halfWidth = displayWidth / 2;
        const labelHeight = this.finishBtn.background.displayHeight * 1.25;
        this.scoreLabels.forEach(label => label.resize(halfWidth, labelHeight, 0, 1));
        Phaser.Actions.GridAlign(this.scoreLabels, {
            width: 2,
            height: 2,
            cellWidth: displayWidth * 0.4,
            cellHeight: this.scoreLabels[0].background.displayHeight * 1.5,
            x: -displayWidth * 0.4 / 2,
            y: -this.icon.displayHeight / 4,
            position: Phaser.Display.Align.CENTER
        })
    }

    resizeLabels() {
        const { displayHeight } = this.icon
        const headerFontSize = displayHeight / 2;
        const subHeaderFontSize = headerFontSize * 0.85;
        this.headerLabel.setFontSize(`${headerFontSize}px`)
            .setY(this.icon.getCenter().y);
        this.descriptionLabel.setFontSize(`${this.icon.displayHeight * 0.25}px`)
            .setY(this.icon.getBottomCenter().y + displayHeight / 4).setWordWrapWidth(this.background.displayWidth * 0.85);
        this.resultsLabel.setFontSize(`${subHeaderFontSize}px`)
            .setY(-displayHeight * 1.25);
        this.feedbackLabel.setFontSize(`${headerFontSize * 0.85}px`)
            .setY(this.failsLabel.y);
    }

    resizeIcon() {
        this.icon.setScale(this.background.scale);
        this.icon.resizedScale = this.icon.scale;
        const { x, y } = this.background.getTopLeft();
        this.icon.setPosition(x, y);
    }

    resizeBtns() {
        const { displayWidth, displayHeight } = this.background;
        this.finishBtn.resize(displayWidth / 4, displayHeight / 10, 0, 1);
        this.finishBtn.setY(displayHeight / 2 - this.finishBtn.background.displayHeight);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.resizeIcon();
        this.resizeBtns();
        this.resizeScoreLabels();
        this.resizeLabels();
        this.resizeTextArea();
    }
}

const ModalTextStyle = {
    fontFamily: GameFont,
    color: '#204659'
}

const parseTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutesString}:${secondsString}`
};