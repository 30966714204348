import { generateFrameNames } from "../../../functions";

export const WorkingFastSheets = {
    COWORKER_PLATFORM: {
        key: "w3s5CoworkerPlatform",
        url: "SocialInclusionAssets/situations/workingFast/coworkerPlatform.png",
        frameWidth: 725 / 5,
        frameHeight: 774 / 4
    },
    MAN_BOX_ANGRY: {
        key: "w3s5manBoxAngry",
        url: "SocialInclusionAssets/situations/workingFast/manBoxAngry.png",
        frameWidth: 844 / 5,
        frameHeight: 679 / 5
    },
    MAN_BOX_DONT_CARE: {
        key: "w3s5manBoxDontCare",
        url: "SocialInclusionAssets/situations/workingFast/manBoxDontCare.png",
        frameWidth: 841 / 5,
        frameHeight: 541 / 4
    },
    MAN_BOX_SMILE: {
        key: "w3s5manBoxSmile",
        url: "SocialInclusionAssets/situations/workingFast/manBoxSmile.png",
        frameWidth: 844 / 5,
        frameHeight: 679 / 5
    },
    WOMAN_BOX_ANGRY: {
        key: "w3s5womanBoxAngry",
        url: "SocialInclusionAssets/situations/workingFast/womanBoxAngry.png",
        frameWidth: 841 / 5,
        frameHeight: 680 / 5
    },
    WOMAN_BOX_DONT_CARE: {
        key: "w3s5womanBoxDontCare",
        url: "SocialInclusionAssets/situations/workingFast/womanBoxDontCare.png",
        frameWidth: 843 / 5,
        frameHeight: 545 / 4
    },
    WOMAN_BOX_SMILE: {
        key: "w3s5womanBoxSmile",
        url: "SocialInclusionAssets/situations/workingFast/womanBoxSmile.png",
        frameWidth: 846 / 5,
        frameHeight: 684 / 5
    },
}

export const WorkingFastAnims = {
    COWORKER: {
        key: "w3s5CoworkerWork",
        frames: generateFrameNames(WorkingFastSheets.COWORKER_PLATFORM.key, 0, 18),
        frameRate: 2,
    },
    MAN_WORK: {
        key: "w3s5manWork",
        frames: generateFrameNames(WorkingFastSheets.MAN_BOX_DONT_CARE.key, 0, 18),
        frameRate: 1.5,
    },
    MAN_ANGRY: {
        key: "w3s5mangetAngry",
        frames: generateFrameNames(WorkingFastSheets.MAN_BOX_ANGRY.key, 19, 21),
        frameRate: 1.5,
    },
    MAN_CONGRAT: {
        key: "w3s5mangetCongrat",
        frames: generateFrameNames(WorkingFastSheets.MAN_BOX_SMILE.key, 19, 21),
        frameRate: 1.5,
    },
    WOMAN_WORK: {
        key: "w3s5womanWork",
        frames: generateFrameNames(WorkingFastSheets.WOMAN_BOX_DONT_CARE.key, 0, 18),
        frameRate: 1.5,
    },
    WOMAN_ANGRY: {
        key: "w3s5womangetAngry",
        frames: generateFrameNames(WorkingFastSheets.WOMAN_BOX_ANGRY.key, 19, 21),
        frameRate: 1.5,
    },
    WOMAN_CONGRAT: {
        key: "w3s5womangetCongrat",
        frames: generateFrameNames(WorkingFastSheets.WOMAN_BOX_SMILE.key, 19, 21),
        frameRate: 1.5,
    },
};

export const Platform = {
    key: "w3s5Platform",
    url: "SocialInclusionAssets/situations/workingFast/platform.png"
}

export const WorkingFastAssets = {
    Images: [Platform],
    SpriteSheets: Object.values(WorkingFastSheets),
}