import React from 'react';
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buttonsSetting, orientationSetting, difficultySetting } from "./utils/settings";
import { sessionAssets } from './utils/assets';
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

const data = {
    name: 'Reflejo espejo',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './MirrorReflectionGameAssets/logo.png',
    iconUrl: './MirrorReflectionGameAssets/icon.png',
    thumbnail: './MirrorReflectionGameAssets/thumbnail.png',
    getHint: () => 'Toca la figura para girarla hasta que quede como si se reflejara en un ' +
        'espejo, luego toca la figura modelo',
    userManual: 'Se presenta un estímulo patrón que sirve de referencia para que el ' +
        'usuario, mediante clics, mueva la imagen hasta lograr el reflejo en ' +
        'espejo (simetría). Se puede configurar el juego por: formas (fotografías ' +
        'o cuerpos geométricos), orientación, disponibilidad de botones para girar ' +
        'o voltear, tamaño de los estímulos y tiempo de duración de la sesión.',
    settings: [
        difficultySetting,
        orientationSetting,
        buttonsSetting,
        sizeSetting,
        timeSetting
    ]
};

export default function MirrorReflectionGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}