export const topBarComponentStructure = {
    barTitle: {
        text: '',
        style: {
            fontSize: '0px',
            fontFamily: 'ArialRounded',
            color: '#FFFFFF',
            align: 'left',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 1920,
            objectHeight: 38,
            objectPositionX: 960,
            objectPositionY: 0,
        }
    },
    gameLogo: {
        nameAsset: 'ludoIcon',
        text: '',
        style: {
            fontSize: '10px',
            fontFamily: 'Arial',
            color: '#FFFFFF',
            align: 'left',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 85,
            objectHeight: 85,
            objectPositionX: 100,
            objectPositionY: 50,
        }
    },
    barImage: {
        nameAsset: 'ludoMingaIcon',
        text: '',
        style: {
            fontSize: '10px',
            fontFamily: 'Arial',
            color: '#FFFFFF',
            align: 'left',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 150,
            objectHeight: 85,
            objectPositionX: 1800,
            objectPositionY: 50,
        }
    },
    barButton:
    {
        text: '',
        nameAsset: 'homeIcon',
        style: {
            fontSize: '18px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1650,
            objectPositionY: 35,
        }
    },
    barButtonLabel:
    {
        text: 'Pantalla principal',
        nameAsset: 'homeIcon',
        style: {
            fontSize: '18px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1650,
            objectPositionY: 82,
        }
    },
    exitButton:
    {
        text: '',
        nameAsset: 'exitButton',
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'left',
            stroke: '#ffffff'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1550,
            objectPositionY: 35,
        }
    },
    exitButtonLabel:
    {
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1550,
            objectPositionY: 82,
        }
    },
    barBack:
    {
        text: '',
        nameAsset: 'backIcon',
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'center',
            stroke: '#ffffff'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1450,
            objectPositionY: 35,
        }
    },
    barBackLabel:
    {

        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#ffffff',
            align: 'center',
            stroke: '#ffffff'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 50,
            objectHeight: 50,
            objectPositionX: 1450,
            objectPositionY: 82,
        }
    },
    soundButton:
    {
        text: '',
        nameAsset: 'soundBar',
        style: {
            fontSize: '25px',
            fontFamily: 'Arial',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 100,
            objectHeight: 50,
            objectPositionX: 410,
            objectPositionY: 50,
        }
    },
    stopVoiceOverBtn:
    {
        text: '',
        nameAsset: 'soundBar',
        style: {
            fontSize: '25px',
            fontFamily: 'Arial',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 100,
            objectHeight: 50,
            objectPositionX: 540,
            objectPositionY: 50,
        }
    },
    zoomButton:
    {
        text: '',
        nameAsset: 'zoomButton',
        style: {
            fontSize: '38px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 100,
            objectHeight: 50,
            objectPositionX: 280,
            objectPositionY: 50,
        }
    },

}