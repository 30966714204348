import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const StandBySettingKey = {
    FIVE_SEC: 0,
    TEN_SEC: 1,
    FIFTEEN_SEC: 2,
    TWENTY_SEC: 3
}
const standbySetting = {
    name: 'En espera',
    key: SettingKey.STANDBY,
    icon: PreviewIcons.TIMEOUT,
    tileIcon: TileIcons.TIMEOUT,
    options: [
        {
            key: StandBySettingKey.FIVE_SEC,
            title: '5 seg',
            header: 'Esperar 5 segundos',
            description: 'Si el usuario no responde en 5 segundos, aparecerá el siguiente estímulo.',
            image: {
                key: 'standByConfig',
                url: './HabCogGameAssets/settings/timeout/timeout.png',
            }
        },
        {
            key: StandBySettingKey.TEN_SEC,
            title: '10 seg',
            header: 'Esperar 10 segundos',
            description: 'Si el usuario no responde en 10 segundos, aparecerá el siguiente estímulo.',
            image: {
                key: 'standByConfig',
                url: './HabCogGameAssets/settings/timeout/timeout.png',
            }
        },
        {
            key: StandBySettingKey.FIFTEEN_SEC,
            title: '15 seg',
            header: 'Esperar 15 segundos',
            description: 'Si el usuario no responde en 15 segundos, aparecerá el siguiente estímulo.',
            image: {
                key: 'standByConfig',
                url: './HabCogGameAssets/settings/timeout/timeout.png',
            }
        },
        {
            key: StandBySettingKey.TWENTY_SEC,
            title: '20 seg',
            header: 'Esperar 20 segundos',
            description: 'Si el usuario no responde en 20 segundos, aparecerá el siguiente estímulo.',
            image: {
                key: 'standByConfig',
                url: './HabCogGameAssets/settings/timeout/timeout.png',
            }
        },
    ]
};

export default standbySetting;

export const StandBySettingAssets = standbySetting.options.map(({ image }) => image);
