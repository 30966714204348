export const ModalIcons = {
    ATTEMPTS: {
        key: 'attemptsModalIcon',
        url: './HabCogGameAssets/modalIcons/attemptsIcon.png'
    },
    FAILS: {
        key: 'failsModalIcon',
        url: './HabCogGameAssets/modalIcons/failsIcon.png'
    },
    HITS: {
        key: 'hitsModalIcon',
        url: './HabCogGameAssets/modalIcons/hitsIcon.png'
    },
    TIME: {
        key: 'timeModalIcon',
        url: './HabCogGameAssets/modalIcons/timeIcon.png'
    },
    QUIT_HEADER: {
        key: 'quitHeaderModalIcon',
        url: './HabCogGameAssets/modalIcons/quitHeaderIcon.png'
    },
    TIMEOUT_HEADER: {
        key: 'timeoutModalIcon',
        url: './HabCogGameAssets/modalIcons/timeoutHeaderIcon.png'
    }
}

export const ModalIconsAssets = Object.values(ModalIcons);