import Phaser from 'phaser';
import { SceneKey } from '../utils/constants';
import { createBackgroundImage, createGameIcon, createGameLogo } from '../utils/factory';
import { BtnAssets } from '../utils/assetsPaths/btn';
import { IconAssets } from '../utils/assetsPaths/icons';
import { OutlineAssets } from '../utils/assetsPaths/outlines';
import { CharactersAssets } from '../utils/assetsPaths/characters';
import { WorldsAssets, WorldJsons, Worlds } from '../utils/assetsPaths/worlds';
import { BadgeAssets } from '../utils/assetsPaths/badges';
import { PlayerAnimAssets, PlayerAnimImages } from '../utils/assetsPaths/playerAnim';
import { BurningHouseAssets } from '../utils/assetsPaths/situations/city/burningHouse';
import { StealingPersonAssets } from '../utils/assetsPaths/situations/city/stealingPerson';
import { PassingBallAssets } from '../utils/assetsPaths/situations/city/passingBall';
import { WaitingBusAssets } from '../utils/assetsPaths/situations/city/waitingBus';
import { RainningStoreAssets } from '../utils/assetsPaths/situations/city/rainningStore';
import { WarmingWeatherAssets } from '../utils/assetsPaths/situations/city/warmingWeather';
import { BuyingFruitsAssets } from '../utils/assetsPaths/situations/city/buyingFruits';
import { FallingPersonAssets } from '../utils/assetsPaths/situations/city/fallingPerson';
import { scaleImage } from '../utils/resize';
import LoadBar from '../gameObjects/loadBar';
import { SickPersonAssets } from '../utils/assetsPaths/situations/home/sickPerson';
import { DisgustingFoodAssets } from '../utils/assetsPaths/situations/home/disgustingFood';
import { GettingHungryAssets } from '../utils/assetsPaths/situations/home/gettingHungry';
import { PlayingCardsAssets } from '../utils/assetsPaths/situations/home/playingCards';
import { SearchingBooksAssets } from '../utils/assetsPaths/situations/home/searchingBooks';
import { LookingDressAssets } from '../utils/assetsPaths/situations/home/lookingDress';
import { CryingBrotherAssets } from '../utils/assetsPaths/situations/home/cryingBrother';
import { BoringWorkAssets } from '../utils/assetsPaths/situations/work/boringWork';
import { AggressiveCoworkerAssets } from '../utils/assetsPaths/situations/work/agressiveCoworker';
import { GoingMeetingAssets } from '../utils/assetsPaths/situations/work/goingMeeting';
import { AskingRestroomAssets } from '../utils/assetsPaths/situations/work/askingRestroom';
import { WorkingFastAssets } from '../utils/assetsPaths/situations/work/workingFast';
import { FeelingSadAssets } from '../utils/assetsPaths/situations/work/feelingSad';
import { SwitchingTaskAssets } from '../utils/assetsPaths/situations/work/switchingTask';
import { WorkingBadAssets } from '../utils/assetsPaths/situations/work/workingBad';


class SplashScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.SPLASH })
    }

    preload() {
        this.updateProgress = (progress) => this.loadBar.setProgress(progress);
        this.createStaticComponents();
        const commonAssets = [
            ...BtnAssets,
            ...IconAssets,
            ...OutlineAssets,
            ...CharactersAssets,
            ...WorldsAssets,
            ...BadgeAssets,
            ...PlayerAnimImages
        ];
        commonAssets.forEach(({ key, url }) => this.load.image(key, url));
        WorldJsons.forEach(({ key, url }) => this.load.tilemapTiledJSON(key, url));
        PlayerAnimAssets.forEach(({ key, url, frameWidth, frameHeight }) =>
            this.load.spritesheet(key, url, { frameWidth, frameHeight }))

        this.load.on('progress', this.updateProgress);
        const situations = [
            BurningHouseAssets,
            StealingPersonAssets,
            PassingBallAssets,
            WaitingBusAssets,
            RainningStoreAssets,
            WarmingWeatherAssets,
            BuyingFruitsAssets,
            FallingPersonAssets,

            SickPersonAssets,
            DisgustingFoodAssets,
            GettingHungryAssets,
            PlayingCardsAssets,
            SearchingBooksAssets,
            LookingDressAssets,
            CryingBrotherAssets,

            BoringWorkAssets,
            AggressiveCoworkerAssets,
            GoingMeetingAssets,
            AskingRestroomAssets,
            WorkingFastAssets,
            FeelingSadAssets,
            SwitchingTaskAssets,
            WorkingBadAssets
        ]
        situations.forEach(situation => this.loadSituationAssets(situation));

        this.load.once('loaderror', () => {
            this.load.off("progress", this.updateProgress);
            this.scene.restart();
        });

        this.tilemapCounter = 0;
        const increaseCounter = () => {
            this.tilemapCounter++;
            if (this.tilemapCounter === 3)
                this.createNodes();
        }
        this.load.on(`filecomplete-tilemapJSON-${Worlds.CITY.tilemap.key}`, increaseCounter);
        this.load.on(`filecomplete-tilemapJSON-${Worlds.HOME.tilemap.key}`, increaseCounter);
        this.load.on(`filecomplete-tilemapJSON-${Worlds.JOB.tilemap.key}`, increaseCounter);
    }

    createNodes() {
        const cityTilemap = this.add.tilemap(Worlds.CITY.tilemap.key);
        const homeTilemap = this.add.tilemap(Worlds.HOME.tilemap.key);
        const workTilemap = this.add.tilemap(Worlds.JOB.tilemap.key);
        try {
            this.game.nodes = {
                CITY: this.navMeshPlugin
                    .buildMeshFromTiled(SceneKey.CITY_WORLD, cityTilemap.getObjectLayer("navMesh"), 0),
                HOME: this.navMeshPlugin
                    .buildMeshFromTiled(SceneKey.HOME_WORLD, homeTilemap.getObjectLayer("navMesh"), 0),
                WORK: this.navMeshPlugin
                    .buildMeshFromTiled(SceneKey.JOB_WORLD, workTilemap.getObjectLayer("navMesh"), 0),
            }
            console.log("nodes created correctly");

        }
        catch (e) {
            console.log(e);
            window.location.reload();
        }
    }

    create() {
        this.game.voice.setVoices();
        this.scene.start(SceneKey.MAIN_MENU);
    }

    createStaticComponents() {
        createBackgroundImage(this);
        createGameIcon(this);
        createGameLogo(this);
        this.loadBar = new LoadBar(this, 0, 0);
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale)
        this.events.once("shutdown", () => this.scale.off("resize", scale))
    }

    resize() {
        const { width, height } = this.game.canvas;
        const { logo, icon, background, loadBar } = this;
        const cellWidth = width / 2;
        const cellHeight = height / 2;
        background.setDisplaySize(width, height)
        scaleImage(logo, cellWidth, cellHeight, 100, 1);
        scaleImage(icon, cellWidth, cellHeight, 25, 1);
        logo.setPosition(width / 4, height / 4);
        icon.setPosition(width * 0.75, cellHeight);
        loadBar.resize(cellWidth, height / 4, 100);
        loadBar.setPosition(width / 4, height * 0.75);
    }

    loadSituationAssets({ Images, SpriteSheets }) {
        Images.forEach(({ key, url }) => this.load.image(key, url));
        SpriteSheets.forEach(({ key, url, frameWidth, frameHeight }) =>
            this.load.spritesheet(key, url, { frameWidth, frameHeight }))
    }
}

export default SplashScene