const AnimalsPhotos = [
    {
        key: 'animalPhoto1',
        url: './photos/animals/animal1.png'
    }, {
        key: 'animalPhoto2',
        url: './photos/animals/animal2.png'
    }, {
        key: 'animalPhoto3',
        url: './photos/animals/animal3.png'
    }, {
        key: 'animalPhoto4',
        url: './photos/animals/animal4.png'
    }, {
        key: 'animalPhoto5',
        url: './photos/animals/animal5.png'
    }, {
        key: 'animalPhoto6',
        url: './photos/animals/animal6.png'
    }, {
        key: 'animalPhoto7',
        url: './photos/animals/animal7.png'
    }, {
        key: 'animalPhoto8',
        url: './photos/animals/animal8.png'
    }, {
        key: 'animalPhoto9',
        url: './photos/animals/animal9.png'
    }, {
        key: 'animalPhoto10',
        url: './photos/animals/animal10.png'
    }, {
        key: 'animalPhoto11',
        url: './photos/animals/animal11.png'
    }, {
        key: 'animalPhoto12',
        url: './photos/animals/animal12.png'
    }, {
        key: 'animalPhoto13',
        url: './photos/animals/animal13.png'
    }, {
        key: 'animalPhoto14',
        url: './photos/animals/animal14.png'
    }, {
        key: 'animalPhoto15',
        url: './photos/animals/animal15.png'
    }, {
        key: 'animalPhoto16',
        url: './photos/animals/animal16.png'
    }, {
        key: 'animalPhoto17',
        url: './photos/animals/animal17.png'
    }, {
        key: 'animalPhoto18',
        url: './photos/animals/animal18.png'
    }, {
        key: 'animalPhoto19',
        url: './photos/animals/animal19.png'
    }, {
        key: 'animalPhoto20',
        url: './photos/animals/animal20.png'
    }
]

export default AnimalsPhotos;