export const gameCardStructure = {
    cardImage: {
        text: '',
        style: {
            fontSize: '50px',
            fontFamily: 'Segoe UI Emoji',
            color: '#000000',
            align: 'center',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 150,
            objectHeight: 150,
            objectPositionX: 0,
            objectPositionY: 0,
        }
    }

}