import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene"
import { sortedGeomTextures, Cursors } from "../../HabCogGame/utils/constants";
import { getRandomElement, getRandomSubArray } from "../../HabCogGame/utils/functions";
const { Image, Rectangle } = Phaser.GameObjects;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, size } = this.game.userConfig;
        const { width } = this.getAvailableSize();
        const scale = [0.4, 0.6, 0.8, 1][size];
        this.targetSize = this.getAvailableSize().height * 0.5 * scale;
        const padding = this.targetSize / 2
        this.leavingPositions = [-padding, width + padding];
        this.tweenDuration = [60000, 6000, 1000, 500, 250][difficulty];
        this.panelWidth = width / 10;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.setSessionTextures();
        this.startGameplay();
    }

    setSessionTextures() {
        const textures = sortedGeomTextures.ALL;
        this.sTextures = getRandomSubArray(textures, textures.length);
        this.textureIndex = 0;
    }

    getNextTexture() {
        const restartIndex = this.textureIndex + 1 === this.sTextures.length;
        if (restartIndex) {
            this.textureIndex = 0;
            this.sTextures = Phaser.Utils.Array.GetRandom(this.sTextures);
        }
        else
            this.textureIndex++;
        return this.sTextures[this.textureIndex];
    }

    toogleTween() {
        const isPaused = this.activeTween.isPaused();
        isPaused ? this.activeTween.resume() : this.activeTween.pause();
    }

    createPanel(x, origin, isCorrect) {
        const { height } = this.getAvailableSize();
        const panel = new Rectangle(this, x, 0, this.panelWidth, height, 0xffffff)
            .setOrigin(origin, 0)
            .setInteractive(Cursors.pointerover)
            .on('pointerdown', () => this.onPanelClick(isCorrect));
        return panel;
    }

    createTarget(size, texture, callback) {
        const { width, height } = this.getAvailableSize();
        return new Image(this, width / 2, height / 2, texture.key)
            .setDisplaySize(size, size)
            .setInteractive(Cursors.pointerover)
            .on('pointerdown', callback);
    }

    onPanelClick(isCorrect) {
        isCorrect ? this.addHit() : this.addFail();
        this.standbyEvent.destroy();
        this.startGameplay();
    }

    startTween(target, direction, panels) {
        this.activeTween = this.tweens.add({
            targets: target,
            x: direction,
            duration: this.tweenDuration,
            onStart: () => this.activeTween.pause(),
            onComplete: () => {
                this.gameArea.add(panels);
                this.startStandby(() => this.startGameplay());
            }
        })
    }

    startGameplay() {
        this.clearGameArea();
        const { width } = this.getAvailableSize();
        const texture = this.getNextTexture();
        const size = this.targetSize;
        const direction = getRandomElement(this.leavingPositions);
        const dirIndex = this.leavingPositions.indexOf(direction);
        const leftPanel = this.createPanel(0, 0, dirIndex === 0)
        const rightPanel = this.createPanel(width, 1, dirIndex === 1)
        const panels = [leftPanel, rightPanel];
        const target = this.createTarget(size, texture, () => this.toogleTween());
        this.gameArea.add(target);
        this.startTween(target, direction, panels);
    }
}
