export const BuyingFruitsSheets = {
    SalesMan: {
        key: 'situationEightSalesman',
        url: 'SocialInclusionAssets/situations/buyingFruits/saleMan.png',
        frameWidth: 372 / 4,
        frameHeight: 586 / 4
    }
}


export const BuyingFruitsAssets = {
    Images: [],
    SpriteSheets: Object.values(BuyingFruitsSheets)
}