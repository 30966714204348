import { BOY_ANIMS, GIRL_ANIMS, BOY_SHEETS, GIRL_SHEETS } from "./playerAnim";

export const Characters = {
    MALE: {
        key: 'maleImagePic',
        name: 'Chico',
        url: 'SocialInclusionAssets/characters/male.png',
        anims: BOY_ANIMS,
        sheets: BOY_SHEETS
    },
    FEMALE: {
        key: 'femaleImagePic',
        name: 'Chica',
        url: 'SocialInclusionAssets/characters/female.png',
        anims: GIRL_ANIMS,
        sheets: GIRL_SHEETS
    }
}

export const CharactersAssets = Object.values(Characters);