import Phaser from 'phaser';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
import { Btns } from '../utils/assetsPaths/btn';
import { Icons } from '../utils/assetsPaths/icons';
import Button from './button';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class ConfirmDialog extends Phaser.GameObjects.Container {

    constructor(scene, x, y, title, description) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.CONFIRM_DIALOG.key);
        this.headerLabel = new Text(scene, 0, 0, title, ConfirmDialogTextStyle).setOrigin(0.5);
        this.descriptionLabel = new Text(scene, 0, 0, description, ConfirmDialogTextStyle)
            .setOrigin(0.5);
        this.confirmBtn = new Button(scene, 0, 0, 'Aceptar', Icons.CONTINUE, Btns.SECONDARY);
        this.spinner = new Image(scene, 0, 0, Icons.SPINNER.key).setVisible(false);
        this.add([
            this.background,
            this.headerLabel,
            this.descriptionLabel,
            this.confirmBtn,
            this.spinner
        ]);
        this.btns = [this.confirmBtn];
    }

    setInteractive() {
        this.btns.forEach(btn => btn.setInteractive());
        return this;
    }

    setLabels(title, description) {
        this.headerLabel.setText(title);
        this.descriptionLabel.setText(description);
        return this;
    }

    showLoadingSpinner() {
        this.btns.forEach(btn => btn.removeInteractive().setVisible(false));
        this.spinner.setVisible(true);
        this.spinnerTween = this.scene.tweens.add({
            targets: this.spinner,
            angle: { from: 0, to: 359 },
            ease: 'Sine.easeInOut',
            repeat: -1
        })
    }

    onBtnClick(callback, label = 'Cancelar', iconTexture = Icons.RETURN) {
        const btn = new Button(this.scene, 0, 0, label, iconTexture, Btns.SECONDARY);
        this.add(btn);
        btn.setInteractive().onclick(callback);
        this.btns = [btn, ...this.btns];
        this.resizeBtns();
        return this;
    }

    onConfirm(callback, label = 'Accept', iconTexture = Icons.ACCEPT) {
        this.confirmBtn.setInteractive().onclick(callback);
        this.confirmBtn.setLabel(label, iconTexture);
        return this;
    }

    resizeSpinner() {
        this.spinner.setScale(this.background.scale);
        this.spinner.setY(this.background.displayHeight / 4);
    }

    resizeLabels() {
        const { displayWidth, displayHeight } = this.background;
        const fontSize = displayHeight / 10
        this.headerLabel.setFontSize(`${fontSize}px`)
            .setY(-displayHeight / 4);
        this.descriptionLabel.setFontSize(`${fontSize * 0.7}px`)
            .setWordWrapWidth(displayWidth * 0.85)

    }

    resizeBtns() {
        const { displayWidth, displayHeight } = this.background;
        this.btns.forEach(btn => btn.resize(displayWidth / (this.btns.length + 1), displayHeight / 7, 0, 1));
        const cellWidth = this.btns[0].background.displayWidth * 1.35
        Phaser.Actions.GridAlign(this.btns, {
            width: -1,
            cellWidth,
            x: - cellWidth / 2 * (this.btns.length - 1),
            y: displayHeight / 2 - this.btns[0].background.displayHeight * 1.5,
            position: Phaser.Display.Align.CENTER,
        })
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.resizeLabels();
        this.resizeBtns();
        this.resizeSpinner();
    }
}

const ConfirmDialogTextStyle = {
    fontFamily: GameFont,
    color: '#204659'
}