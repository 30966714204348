export const CryingBrotherSheets = {
    SAD_BROTHER: {
        key: "world2Situation7sadBrother",
        url: "SocialInclusionAssets/situations/cryingBrother/sadBoy.png",
        frameWidth: 958 / 4,
        frameHeight: 363 / 3
    },
    MOM_HELP: {
        key: "world2Situation7momHelp",
        url: "SocialInclusionAssets/situations/cryingBrother/momHelp.png",
        frameWidth: 291 / 4,
        frameHeight: 401 / 3
    },
    MAN_HELP: {
        key: "world2Situation7manHelp",
        url: "SocialInclusionAssets/situations/cryingBrother/manHelp.png",
        frameWidth: 275 / 3,
        frameHeight: 393 / 3
    },
    MAN_MUSIC: {
        key: "world2Situation7manListenMusic",
        url: "SocialInclusionAssets/situations/cryingBrother/manMusic.png",
        frameWidth: 207 / 3,
        frameHeight: 262 / 2
    },
    WOMAN_HELP: {
        key: "world2Situation7womanHelp",
        url: "SocialInclusionAssets/situations/cryingBrother/womanHelp.png",
        frameWidth: 298 / 3,
        frameHeight: 412 / 3
    },
    WOMAN_MUSIC: {
        key: "world2Situation7womanListenMusic",
        url: "SocialInclusionAssets/situations/cryingBrother/womanMusic.png",
        frameWidth: 203 / 3,
        frameHeight: 274 / 2
    }
}

export const CryingBrotherAssets = {
    Images: [],
    SpriteSheets: Object.values(CryingBrotherSheets)
}