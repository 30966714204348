import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import getDrawDifficultySetting from "./utils/settings";
import { physicsModel, sessionAssets } from "./utils/assets";
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

const difficultySetting = getDrawDifficultySetting();

const data = {
    name: 'Paso a paso',
    sessionAssets,
    sessionScene: SessionScene,
    physics: 'matter',
    logoUrl: './StepByStepGameAssets/logo.png',
    iconUrl: './StepByStepGameAssets/icon.png',
    thumbnail: './StepByStepGameAssets/thumbnail.png',
    getHint: (userConfig) => {
        let { difficulty } = userConfig;
        if (difficulty === 0)
            return 'Selecciona el color de tu preferencia y el grosor de tu pincel para ' +
                'dibujar a tu gusto.';
        else if (difficulty === 1)
            return 'Selecciona el color de tu preferencia y el grosor de tu pincel para ' +
                'dibujar una figura como el modelo.';
        else return 'Selecciona el color de tu preferencia y el grosor de tu pincel para ' +
            'pintar la figura.';
    },
    userManual: 'El usuario puede crear imágenes en la pantalla dibujando libremente, ' +
        'replicando modelos o rellenando figuras.',
    settings: [
        difficultySetting,
        timeSetting
    ],
    onLoad: (scene) => {
        const { key, url } = physicsModel;
        scene.load.json(key, url)
    }
};

export default function StepByStepGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
