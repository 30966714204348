import axios from "axios";
import { API_BASE_URL } from "./constants";

export default class InterventionPlanService {
  static getInterventionPlans(interventionPlanId) {
    return axios.get(
      API_BASE_URL +
        "organizations/" +
        interventionPlanId +
        "/interventionplans"
    );
  }
}
