
import WorldScene from "./worldScene";
import { SceneKey } from "../utils/constants";
import BurningHouseSituation from '../situations/city/burningHouse';
import PassingBallSituation from '../situations/city/passingBall';
import WaitingBusSituation from '../situations/city/waitingBus';
import RainningStoreSituation from '../situations/city/rainningStore';
import WarmingWeatherSituation from '../situations/city/warmingWeather';
import FallingPersonSituation from '../situations/city/fallingPerson';
import BuyingFruitsSituation from '../situations/city/buyingFruits';
import StealingPersonSituation from '../situations/city/stealingPerson';
import { Worlds } from "../utils/assetsPaths/worlds";
import { HouseSheets } from "../utils/assetsPaths/situations/city/burningHouse";

export default class CityWorldScene extends WorldScene {

    constructor() {
        super({ key: SceneKey.CITY_WORLD });
        //this.startPoint = { x: 2561, y: 1772 };
        this.startPoint = { x: 1706, y: 350 };
        this.worldName = "ciudad";
        this.nodeKey = "CITY";

    }

    createSituations() {
        this.situations = [
            new BurningHouseSituation(this),
            new StealingPersonSituation(this),
            new PassingBallSituation(this),
            new WaitingBusSituation(this),
            new RainningStoreSituation(this),
            new WarmingWeatherSituation(this),
            new FallingPersonSituation(this),
            new BuyingFruitsSituation(this)
        ]
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.background;
        this.add.zone(720 * scaleX, y + 1568 * scaleY, 192 * scaleX, 192 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.JOB;
                this.scene.start(SceneKey.JOB_WORLD)
            });
            this.add.zone(3632 * scaleX, y + 592 * scaleY, 136 * scaleX, 152 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.HOME;
                this.scene.start(SceneKey.HOME_WORLD);
            });

            this.add.sprite(2750 * scaleX, 850 * scaleY + y, HouseSheets.DOG.key, 0)
            .setScale(Math.max(scaleX, scaleY)/2)
            .play(this.animalsAnims.DOG.key);
    }
}