import { CustomSettingsAssets } from "./settings";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const brushAsset = {
    key: 'brushAssets',
    url: './StepByStepGameAssets/brushWhite.png'
};

export const StepByStepIcons = {
    CLEAR_CANVAS: {
        key: 'clearCanvasIcon',
        url: './StepByStepGameAssets/icons/clearCanvasIcon.png'
    },
    NEXT_MODEL: {
        key: 'nextModelIcon',
        url: './StepByStepGameAssets/icons/nextModelIcon.png'
    },
    SHOW_MODEL: {
        key: 'showModelIcon',
        url: './StepByStepGameAssets/icons/showModelIcon.png'
    },
    DECREASE:{
        key: 'decreaseIconIMG',
        url: './StepByStepGameAssets/icons/decreaseIcon.png' 
    },    
    INCREASE:{
        key: 'increaseIconIMG',
        url: './StepByStepGameAssets/icons/increaseIcon.png' 
    }
}

export const modelAssets = [
    {
        key: 'squareModel',
        url: './StepByStepGameAssets/models/square/squareModel.png'
    }, {
        key: 'triangleModel',
        url: './StepByStepGameAssets/models/triangle/triangleModel.png'
    }, {
        key: 'circleModel',
        url: './StepByStepGameAssets/models/circle/circleModel.png'
    }, {
        key: 'starModel',
        url: './StepByStepGameAssets/models/star/starModel.png'
    }, {
        key: 'houseModel',
        url: './StepByStepGameAssets/models/house/houseModel.png'
    }, {
        key: 'carModel',
        url: './StepByStepGameAssets/models/car/carModel.png'
    }, {
        key: 'sunModel',
        url: './StepByStepGameAssets/models/sun/sunModel.png'
    },

];

export const physicsModel = {
    key: 'physicsModels',
    url: './StepByStepGameAssets/models/physicsModel.json'
};

export const sessionAssets = [
    brushAsset,
    ...Object.values(StepByStepIcons),
    ...modelAssets,
    ...CustomSettingsAssets,
    ...TimeSettingAssets
]
