import Phaser from "phaser";
import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { FallingPersonSheets } from "../../utils/assetsPaths/situations/city/fallingPerson";
import { generateFrameNames, addDialogTween } from "../../utils/functions";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { BOY_SHEETS } from "../../utils/assetsPaths/playerAnim";

const Vector2 = Phaser.Math.Vector2

class FallingPersonSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 2116, y: 604 };
        const mcPosition = { x: 2767, y: 1013 };
        super(scene, markPosition, mcPosition, Badges.HEALTH);
        this.instructionLabel = "Una persona se cayó, ¿qué haces?";
        this.optionsData = [{
            label: "Continúo mi camino.",
            onclick: this.runEvent(3500, () => this.continueWalk())
        }, {
            label: "Le ayudo a levantarse.",
            onclick: this.runEvent(2000, () => this.helpSkater())
        }, {
            label: "Llamo a alguien para que le ayude.",
            onclick: this.runEvent(2000, () => this.askHelp())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(SkaterAnims).forEach(animation => anims.create(animation));
        Object.values(NPCAnims).forEach(animation => anims.create(animation));
    }

    create() {
        super.create();
        this.skater = createSkater(this.scene)
        return this
    }

    createDummySprite() {
        this.skater.setAlpha(0);
        this.scene.mc.setAlpha(0);
        const { scaleX } = this.scene.background;
        this.auxSprite = this.scene.add.sprite(0, 0, FallingPersonSheets.MAN_HELP.key, 0)
            .setScale(this.scene.mc.scale)
            .setOrigin(0.5, 0.9)
            .setX(scaleX * (2767 + 2565) / 2)
            .setY(this.scene.mc.y)
    }

    destroyDummySprite() {
        this.auxSprite.destroy();
        this.skater.destroy();
        this.skater = createSkater(this.scene);
        this.scene.mc.setAlpha(1);
    }

    makeNPCHelp() {
        this.npc.follower.goTo(new Vector2(2767, 1013))
            .onMovementComplete(this.runEvent(2000, () => {
                this.skater.setAlpha(0);
                this.npc.sprite.setAlpha(0);
                const { scaleX } = this.scene.background;
                this.auxSprite = this.scene.add.sprite(0, 0, FallingPersonSheets.RANDOM_HELP.key, 0)
                    .setScale(this.scene.mc.scale)
                    .setOrigin(0.5, 0.9)
                    .setX(scaleX * (2767 + 2565) / 2)
                    .setY(this.npc.sprite.y).play(SkaterAnims.NPC_HELP.key)
                    .once("animationcomplete", this.runEvent(2000, () => {
                        addDialogTween(this.scene, "La persona recibió ayuda", this.badge)
                        this.destroyDummySprite();
                        this.scene.events.off("update", this.npc.move);
                        this.npc.follower.destroy();
                        this.npc.sprite.destroy()
                    }))
            }))
    }

    continueWalk() {
        const { LOOK_UP } = this.scene.game.ludo.character.anims;
        this.scene.moveMcTo(2934, 1212, () => {
            this.scene.mc.setFlipX(true);
            this.addEvent(750, () => this.scene.mc.setTexture(LOOK_UP.key))
            this.addEvent(2500, () => {
                addDialogTween(this.scene, "No ayudaste a la persona");
                this.skater.destroy();
                this.skater = createSkater(this.scene);
            })
        })
    }

    helpSkater() {
        const sheets = this.scene.game.ludo.character.sheets;
        const animation = sheets === BOY_SHEETS ? SkaterAnims.MAN_HELP : SkaterAnims.WOMAN_HELP
        this.createDummySprite();
        this.auxSprite.play(animation.key)
            .once('animationcomplete', this.runEvent(2000,
                () => {
                    addDialogTween(this.scene, "Ayudaste a la persona", this.badge)
                    this.destroyDummySprite();
                }))
    }

    askHelp() {
        const { SCARED } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(SCARED.key)
            .once("animationcomplete", this.runEvent(2000, () => {
                this.scene.moveMcTo(2934, 1212, () => {
                    this.scene.mc.setFlipX(true);
                    this.npc = createNPC(this.scene);
                    this.scene.events.on("update", this.npc.move);
                    this.scene.tweens.add({
                        targets: this.npc.sprite,
                        alpha: 1,
                        onComplete: () => this.makeNPCHelp()
                    })
                })
            }))
    }

    start() {
        this.scene.mc.setFlipX(true);
        const onComplete = () => makeSkaterFall(this.scene, this.skater,
            this.runEvent(2000, () => this.showHint()))
        createSkaterTween(this.scene, this.skater, onComplete)
    }
}

const createNPC = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 3617, 1158);
    const sprite = createCharacterSprite(scene, FallingPersonSheets.RANDOM_IDLE, 0)
        .setScale(scene.mc.scale).setAlpha(0).setFlipX(true)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(NPCAnims.WalkUp, true))
        .onTopRightMovement(anim(NPCAnims.WalkUp, false))
        .onBottomLeftMovement(anim(NPCAnims.WalkDown, false))
        .onBottomRightMovement(anim(NPCAnims.WalkDown, true))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(FallingPersonSheets.RANDOM_IDLE.key, 0))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);
    return { follower, sprite, move };
}

const createSkater = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    return scene.add.sprite(0, 0, "situation7skater", 0)
        .setScale(scene.mc.scale)
        .setX(2365 * scaleX)
        .setY(840 * scaleY + y)
        .setOrigin(0.5, 0.9)
        .setDepth(scene.mc.depth + 1)
}

const createSkaterTween = (scene, sprite, onComplete) => {
    const { scaleX, scaleY, y } = scene.background;
    scene.tweens.add({
        delay: 1000,
        targets: sprite,
        x: 2608 * scaleX,
        y: 1016 * scaleY + y,
        duration: 2000,
        repeat: 1,
        yoyo: true,
        onYoyo: () => sprite.play(SkaterAnims.SKATER_FLIP_DOWN_UP.key),
        onRepeat: () => sprite.play(SkaterAnims.SKATER_FLIP_UP_DOWN.key),
        onComplete: () => {
            sprite.play(SkaterAnims.SKATER_FLIP_UP_DOWN.key)
                .once("animationcomplete", onComplete)
        }
    })
}

const makeSkaterFall = (scene, sprite, onComplete) => {
    const { scaleX, scaleY, y } = scene.background;
    scene.tweens.add({
        targets: sprite,
        x: scaleX * (2608 + 2365) / 2,
        y: scaleY * (1016 + 840) / 2 + y,
        durtion: 2000,
        onComplete: () => scene.tweens.add({
            targets: sprite,
            duration: 2000,
            x: scaleX * 2565,
            y: scaleY * 1040 + y,
            onStart: () => sprite.play(SkaterAnims.SKATER_FALL.key),
            onComplete
        })
    })
}

const SkaterFlipFrameRate = 6;

const SkaterAnims = {
    SKATER_FLIP_DOWN_UP: {
        key: 'situation7skaterFlipDownUp',
        frames: generateFrameNames(FallingPersonSheets.SKATER.key, 2, 4),
        frameRate: SkaterFlipFrameRate
    },
    SKATER_FLIP_UP_DOWN: {
        key: 'situation7skaterFlipUpDown',
        frames: generateFrameNames(FallingPersonSheets.SKATER.key, 7, 9),
        frameRate: SkaterFlipFrameRate
    },
    SKATER_FALL: {
        key: "skaterFall",
        frames: generateFrameNames(FallingPersonSheets.SKATER.key, 20, 24),
        frameRate: 4
    },
    MAN_HELP: {
        key: 'manHelpSkater',
        frames: generateFrameNames(FallingPersonSheets.MAN_HELP.key, 0, 7),
        frameRate: 2
    },
    WOMAN_HELP: {
        key: 'womanHelpSkater',
        frames: generateFrameNames(FallingPersonSheets.WOMAN_HELP.key, 0, 7),
        frameRate: 2
    },
    NPC_HELP: {
        key: 'npcHelpSkater',
        frames: generateFrameNames(FallingPersonSheets.RANDOM_HELP.key, 0, 7),
        frameRate: 2
    }
}

const NPCAnims = {
    WalkUp: {
        key: 'npcSituation7WalkUp',
        frames: generateFrameNames(FallingPersonSheets.RANDOM_WALK_UP.key, 0, 11),
        frameRate: 5
    },
    WalkDown: {
        key: 'npcSituation7WalkDown',
        frames: generateFrameNames(FallingPersonSheets.RANDOM_WALK_DOWN.key, 0, 11),
        frameRate: 5
    }
}

export default FallingPersonSituation;