import Phaser from "phaser";
import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { WarmingWeatherSheets, Visor } from "../../utils/assetsPaths/situations/city/warmingWeather";
import { generateFrameNames, addDialogTween } from "../../utils/functions";

class WarmingWeatherSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1601, y: 471 };
        const mcPosition = { x: 1819, y: 380 };
        super(scene, markPosition, mcPosition, Badges.SUN);
        this.instructionLabel = 'Estás caminando y los rayos del sol son muy fuertes, ¿qué haces?';
        this.optionsData = [{
            label: 'Continúo caminando sin protegerme.',
            onclick: () => this.doNothing()
        }, {
            label: 'Me pongo una gorra y protector solar.',
            onclick: () => this.useSunblock()
        }, {
            label: 'Me quedo bajo una visera y bebo agua.',
            onclick: () => this.addEvent(2500, () => this.takeCover())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(SunAnims).forEach(a => anims.create(a));
    }

    doNothing() {
        const time = 1500;
        const mc = this.scene.mc;
        const { BURN } = this.scene.game.ludo.character.sheets;
        this.addEvent(time, () => mc.setTexture(BURN.key, 4))
        this.addEvent(time * 2, () => mc.setTexture(BURN.key, 5))
        this.addEvent(time * 3, () => mc.setTexture(BURN.key, 6))
        this.addEvent(time * 4, () => mc.setTexture(BURN.key, 7))
        this.addEvent(time * 5, () => {
            addDialogTween(this.scene, 'Te has quemado por el sol');
        })
    }

    useSunblock() {
        const { USE_HAT } = this.scene.game.ludo.character.sheets;
        this.addEvent(2500, () => this.scene.mc.play(USE_HAT.key)
            .once('animationcomplete', this.runEvent(2500,
                () => addDialogTween(this.scene, 'Te has protegido del sol', this.badge))))
    }

    takeCover() {
        const depth = this.scene.mc.depth
        this.visor.setDepth(depth + 1)
        console.log("me voy a cubrir")
        this.scene.follower.goTo(new Phaser.Math.Vector2(1860, 243))
            .onceMovementComplete(() => this.scene.tweens.add({
                targets: this.sun,
                alpha: 0,
                delay: 2500,
                duration: 2500,
                onComplete:
                    this.runEvent(2000, () =>
                        this.scene.follower.goTo(new Phaser.Math.Vector2(1819, 380))
                            .onceMovementComplete(this.runEvent(2000, () => {
                                addDialogTween(this.scene, "Te cubriste del sol", this.badge)
                                this.visor.setDepth(depth - 1)
                            })))
            }))

    }

    reboot(onComplete) {
        this.scene.follower.emit('onMovementComplete');
        this.sun.destroy();
        onComplete();
    }

    create() {
        const { scaleX, scaleY, y } = this.scene.background;
        super.create();
        this.visor = this.scene.add.image(0, 0, Visor.key)
            .setScale(scaleX, scaleY)
            .setX(1848 * scaleX)
            .setY(122 * scaleY + y)
        return this;
    }

    start() {
        const { BURN } = this.scene.game.ludo.character.sheets;
        this.sun = createSun(this.scene)
        this.sun.sunBody.play(SunAnims.BodyIdle.key);
        this.scene.mc.setFlipX(false);
        const time = 2500;
        this.scene.tweens.add({
            targets: this.sun,
            alpha: 1,
            duration: time,
            onComplete: () => this.addEvent(time, () =>
                this.sun.sunFace.play(SunAnims.PutGlasses.key)
                    .once('animationcomplete', () => this.sun.sunBody.play(SunAnims.BodyStrech.key)
                        .once('animationcomplete', () => {
                            this.sun.sunBody.play(SunAnims.BodyHeat.key);
                            this.addEvent(2500, () => this.scene.mc.setTexture(BURN.key, 3))
                            this.addEvent(5000, () => this.showHint())
                        })))
        })
    }
}

const createSun = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const sunBody = scene.add.sprite(0, 0, WarmingWeatherSheets.SUN_BODY.key, 0)
        .setFlipX(true);
    const sunFace = scene.add.sprite(0, 0, WarmingWeatherSheets.SUN_FACE.key, 0)
        .setFlipX(true);
    const children = [sunBody, sunFace];
    const container = scene.add.container(0, 0, children);
    container.sunBody = sunBody;
    container.sunFace = sunFace;
    children.forEach(child => child.setScale(scaleX, scaleY));
    container.setPosition(2275 * scaleX, 148 * scaleY + y)
        .setAlpha(0)
    return container;
}

const SunAnims = {
    PutGlasses: {
        key: 'sunPutGlasses',
        frames: generateFrameNames(WarmingWeatherSheets.SUN_FACE.key, 0, 11),
        frameRate: 6
    },
    BodyIdle: {
        key: 'sunBodyIdle',
        frames: generateFrameNames(WarmingWeatherSheets.SUN_BODY.key, 0, 2),
        frameRate: 8,
        repeat: -1
    },
    BodyStrech: {
        key: 'sunBodyStrech',
        frames: generateFrameNames(WarmingWeatherSheets.SUN_BODY.key, 3, 6),
        frameRate: 8
    },
    BodyHeat: {
        key: 'sunBodyHeat',
        frames: generateFrameNames(WarmingWeatherSheets.SUN_BODY.key, 7, 9),
        frameRate: 8,
        repeat: -1
    }

}

export default WarmingWeatherSituation;