import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import DesignationMsgBox from "../../HabCogGame/gameObjects/designationsMsgBox";
import { getRandomElement } from "../../HabCogGame/utils/functions";
import { TARGET, LEFT_SIGN } from "../utils/assets";
import { Cursors } from "../../HabCogGame/utils/constants";
import { Icons } from "../../HabCogGame/utils/assetsPaths/icons";
import TargetFeedback from "../../HabCogGame/utils/assetsPaths/targets";
import Button from "../../HabCogGame/gameObjects/button";

const Image = Phaser.GameObjects.Image;

const { GOOD_TARGET, BAD_TARGET } = TargetFeedback;
const STR = {
    ASK: '¿Hacia dónde se fue la pelota?',
    CORRECT: 'Respuesta Correcta',
    INCORRECT: 'Respuesta Incorrecta',
    CONTINUE: 'Continuar'
};

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, size } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.targetScale = [0.35, 0.5, 0.75, 1][size];
        this.duration = [20000, 15000, 10000, 5000, 1000][difficulty]
        this.hub.setDepth(10);
        this.startGameplay();
    }

    createMsgBox(label, scale = 1) {
        const { width, height } = this.getAvailableSize();
        const msg = new DesignationMsgBox(this, width / 2, height / 2, label);
        msg.resize(width * 0.75, height / 2, 100, scale);
        return msg;
    }

    toogleTween() {
        const isPaused = this.activeTween.isPaused();
        isPaused ? this.activeTween.resume() : this.activeTween.pause()
    }

    createBall(width, height) {
        const cellSize = width > height ? height : width
        const size = cellSize * 0.75 * this.targetScale;
        return new Image(this, width / 2, height / 2, TARGET.key)
            .setDisplaySize(size, size)
            .setInteractive(Cursors.pointerover)
            .on('pointerdown', () => this.toogleTween());
    }

    startTween({ x, y, rotation }, onComplete) {
        this.activeTween = this.tweens.add({
            targets: this.ball,
            x,
            y,
            rotation: { from: 0, to: rotation },
            duration: this.duration,
            delay: 0,
            onStart: () => this.activeTween.pause(),
            onComplete
        })
    }

    startGameplay() {
        this.speakInstruction();
        this.hub.updateText(this.sceneTextInstruction);
        this.clearGameArea();
        const { width, height } = this.getAvailableSize();
        const signs = createSigns(this, width, height);
        const msg = this.createMsgBox(STR.ASK);
        this.ball = this.createBall(width, height);
        const direction = getRandomPosition(width, height, this.ball, signs);
        const onComplete = () => {
            signs.forEach(sign => this.setSignInteractive(sign, [...signs, msg]))
            this.speak(STR.ASK);
            this.gameArea.add([...signs, msg]);
            this.hub.updateText(STR.ASK);
        }
        this.startTween(direction, onComplete);
        this.gameArea.add(this.ball);
    }

    setSignInteractive(target, gameObjects) {
        const { x, y } = target.opposite;
        target.setInteractive(Cursors.pointerover)
            .once('pointerdown', () => this.tweens.add({
                targets: target,
                x,
                y,
                alpha: { from: 1, to: 0.25 },
                onComplete: () => this.showAnswer(target),
                onStart: () => gameObjects.forEach(sign => {
                    if (sign !== target)
                        sign.destroy();
                })
            }))
    }

    showAnswer(sign) {
        const { isTarget = false } = sign;
        const { width, height } = this.getAvailableSize();
        const size = height * 0.35;
        const texture = isTarget ? GOOD_TARGET : BAD_TARGET;
        const str = isTarget ? STR.CORRECT : STR.INCORRECT;
        isTarget ? this.addHit() : this.addFail();
        this.ball.setPosition(width / 2, height / 2).setRotation(0)
            .setDisplaySize(size, size);
        const image = new Image(this, this.ball.x, this.ball.y, texture.key)
            .setDisplaySize(400, 400);
        const padding = this.ball.displayHeight * 1.5 / 2
        const message = new DesignationMsgBox(this, width / 2, height / 2 - padding, str);
        message.resize(width * 0.75, height / 5, 100, 1);
        this.continueBtn = new Button(this, width / 2, 0, 'Continuar', Icons.CONTINUE)
            .setY(height / 2 + padding)
            .setInteractive(Cursors.pointerover)
            .onclick(() => this.startGameplay())
            .resize(width / 2, message.background.displayHeight, 0, 1);
        this.gameArea.add([image, message, this.continueBtn]);
    }

}

const createSigns = (scene, width, height) => {
    const texture = LEFT_SIGN.key;
    const size = width > height ? height / 3 : width / 3
    const leftSign = new Image(scene, 0, height / 2, texture)
    const rightSign = new Image(scene, width, height / 2, texture).setFlipX(true);
    const upSign = new Image(scene, width / 2, 0, texture).setAngle(90);
    const downSign = new Image(scene, width / 2, height, texture).setAngle(270);
    const signs = [leftSign, rightSign, upSign, downSign];
    signs.forEach(sign => sign
        .setDisplaySize(100, size)
        .setScale(sign.scaleY, sign.scaleY));
    leftSign.setX(leftSign.displayWidth / 2);
    rightSign.setX(rightSign.x - rightSign.displayWidth / 2);
    upSign.setY(upSign.displayHeight / 2);
    downSign.setY(downSign.y - downSign.displayHeight / 2);
    leftSign.opposite = rightSign;
    rightSign.opposite = leftSign;
    upSign.opposite = downSign;
    downSign.opposite = upSign;
    return signs;
};

const getRandomPosition = (width, height, target, signs) => {
    const positions = [
        { x: -target.displayWidth / 2, y: target.y, rotation: -8 },
        { x: width + target.displayWidth / 2, y: target.y, rotation: 8 },
        { x: target.x, y: -target.displayHeight / 2, rotation: 4 },
        { x: target.x, y: height + target.displayHeight / 2, rotation: -4 }
    ];
    const direction = getRandomElement(positions);
    const index = positions.indexOf(direction);
    signs[index].isTarget = true;
    return direction;
};
