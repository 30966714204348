import { ShapeSettingKey } from "../settingsConstants/shapeSetting";

export const geomTextures = {
    SQUARE: {
        key: 'whiteSquareTexture',
        name: 'un cuadrado',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteSquare.png'
    },
    TRIANGLE: {
        key: 'whiteTriangleTexture',
        name: 'un triángulo',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteTriangle.png'
    },
    CIRCLE: {
        key: 'whiteCircleTexture',
        name: 'un círculo',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteCircle.png'
    },
    STAR: {
        key: 'whiteStarTexture',
        name: 'una estrella',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteStar.png'
    }
}

export const outlineGeomTextures = {
    SQUARE: {
        key: 'whiteSquareOutlineTexture',
        name: 'un cuadrado',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteSquareOutline.png'
    },
    TRIANGLE: {
        key: 'whiteTriangleOutlineTexture',
        name: 'un triángulo',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteTriangleOutline.png'
    },
    CIRCLE: {
        key: 'whiteCircleOutlineTexture',
        name: 'un círculo',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteCircleOutline.png'
    },
    STAR: {
        key: 'whiteStarOutlineTexture',
        name: 'una estrella',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteStarOutline.png'
    }
}

export const GeomTextureAssets = Object.values(geomTextures);
export const OutlinedGeomTexturesAssets = Object.values(outlineGeomTextures);

export const getShapeTexture = (key, geomTextures) => {
    switch (key) {
        case ShapeSettingKey.CIRCLE:
            return geomTextures.CIRCLE;
        case ShapeSettingKey.STAR:
            return geomTextures.STAR;
        case ShapeSettingKey.TRIANGLE:
            return geomTextures.TRIANGLE;
        default: return geomTextures.SQUARE
    }
}