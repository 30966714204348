import Phaser from 'phaser';
import { Cursors } from '../constants';

export const pushyTweens = [
    (scene, target, texture) => startPushyTween(1, 1, scene, target, texture),
    (scene, target, texture) => startPushyTween(-1, -1, scene, target, texture),
    (scene, target, texture) => startPushyTween(1, -1, scene, target, texture),
    (scene, target, texture) => startPushyTween(-1, 1, scene, target, texture),
    (scene, target, texture) => startPushyTween(0, 1, scene, target, texture),
    (scene, target, texture) => startPushyTween(1, 0, scene, target, texture),
    (scene, target, texture) => startPushyTween(-1, 0, scene, target, texture),
    (scene, target, texture) => startPushyTween(0, -1, scene, target, texture)
]

export const lineTweens = [
    (scene, target, texture) => startLineTween(1, 1, scene, target, texture),
    (scene, target, texture) => startLineTween(-1, -1, scene, target, texture),
    (scene, target, texture) => startLineTween(0, 1, scene, target, texture),
    (scene, target, texture) => startLineTween(1, -1, scene, target, texture),
    (scene, target, texture) => startLineTween(1, 0, scene, target, texture),
    (scene, target, texture) => startLineTween(-1, 1, scene, target, texture),
    (scene, target, texture) => startLineTween(-1, 0, scene, target, texture),
    (scene, target, texture) => startLineTween(0, -1, scene, target, texture),
]

export const leaveTweens = [
    (scene, target, cb) => startLeaveTween(1, 1, scene, target, cb),
    (scene, target, cb) => startLeaveTween(-1, -1, scene, target, cb),
    (scene, target, cb) => startLeaveTween(0, 1, scene, target, cb),
    (scene, target, cb) => startLeaveTween(1, -1, scene, target, cb),
    (scene, target, cb) => startLeaveTween(1, 0, scene, target, cb),
    (scene, target, cb) => startLeaveTween(-1, 1, scene, target, cb),
    (scene, target, cb) => startLeaveTween(-1, 0, scene, target, cb),
    (scene, target, cb) => startLeaveTween(0, -1, scene, target, cb),
]

const startTween = ({ scene, target, dummyTarget, padding }, tweenTargets,
    cb = () => { }) => {
    const { width, height } = scene.getAvailableSize();
    const { x, y } = padding;
    const zone = scene.add.rectangle(0, scene.gameArea.y, width, height)
        .setInteractive(Cursors.pointerout).setOrigin(0);
    scene.tweens.add({
        targets: tweenTargets,
        x: `+=${x}`, y: `+=${y}`,
        duration: 500,
        onComplete: () => {
            dummyTarget.destroy();
            target.clearMask(true);
            target.setInteractive(Cursors.pointerover);
            zone.destroy();
            cb();
        }
    })
}

const createTweenConfig = (xDir, yDir, scene, target, nextTexture) => {
    const { gameArea } = scene;
    const { x, y, displayWidth: sizeX, displayHeight: sizeY, texture } = target;
    const padding = { x: sizeX * xDir, y: sizeY * yDir };
    const graphics = scene.make.graphics();
    const dummyTarget = new Phaser.GameObjects.Image(scene, x, y, texture.key)
        .setDisplaySize(sizeX, sizeY)
    const corner = target.getTopLeft();
    graphics.fillStyle(0x2d2d2d)
        .setY(gameArea.y)
        .fillRect(corner.x - 2, corner.y - 2, sizeX + 2, sizeY + 2)
    dummyTarget.setMask(graphics.createGeometryMask());
    target.setMask(graphics.createGeometryMask())
    gameArea.add([dummyTarget]);
    nextTexture ?
        target.setTexture(nextTexture.key) :
        dummyTarget.setVisible(false);
    return { scene, target, dummyTarget, graphics, padding }
}

const startPushyTween = (xDir, yDir, scene, target, nextTexture) => {
    const config = createTweenConfig(xDir, yDir, scene, target, nextTexture);
    const targets = [target, config.dummyTarget];
    const { x, y } = config.padding;
    target.setPosition(target.x - x, target.y - y);
    startTween(config, targets);
}

const startLineTween = (xDir, yDir, scene, target, nextTexture) => {
    const config = createTweenConfig(xDir, yDir, scene, target, nextTexture);
    const targets = [config.graphics];
    const { x, y } = config.padding;
    const graphics = config.graphics;
    config.dummyTarget.mask.setInvertAlpha(true);
    config.graphics.setPosition(graphics.x - x, graphics.y - y);
    startTween(config, targets);
}

const startLeaveTween = (xDir, yDir, scene, target, onComplete) => {
    const config = createTweenConfig(xDir, yDir, scene, target);
    const { x, y } = target;
    startTween(config, target, () => {
        target.setPosition(x, y);
        target.setAlpha(0.000001);
        onComplete();
    });
}

