import Phaser from "phaser";
import { GameFont } from '../utils/constants'
import { scaleImage } from '../utils/resize'
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

class HubButton extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, icon) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, icon.key);
        this.label = new Text(scene, 0, 0, label, HubButtonTextStyle)
            .setOrigin(0.5, 0);
        this.add([this.background, this.label]);
    }

    setInteractive() {
        this.background.setInteractive({ useHandCursor: true });
        this.setEvents();
    }

    setEvents() {
        this.background
            .on('pointerover', () => this.addScaleTween(1.25))
            .on('pointerout', () => this.addScaleTween(1))
    }

    onclick(callback) {
        this.background.on('pointerdown', callback);
    }

    addScaleTween(scale) {
        this.scene.tweens.add({
            targets: this, ease: 'Power3', scale, duration: 125
        })
    }

    setPosition(x = 0, y = 0) {
        if (this.label)
            super.setPosition(x, y - this.label.displayHeight / 2);
        else super.setPosition(x, y);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.label.setFontSize(`${this.background.displayHeight / 3}px`)
            .setY(this.background.getBottomCenter().y);
        return this;
    }
}

const HubButtonTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}

export default HubButton;