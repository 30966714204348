export const PurpleCar = {
    key: 'purpleCarImage',
    url: 'SocialInclusionAssets/situations/passingBall/purpleCar.png'
}

export const TrafficLight = {
    Base: {
        key: 'baseTrafficLight',
        url: 'SocialInclusionAssets/situations/passingBall/baseLight.png'
    },
    Red: {
        key: 'redTrafficLight',
        url: 'SocialInclusionAssets/situations/passingBall/redLight.png'
    },
    Yellow: {
        key: 'yellowTrafficLight',
        url: 'SocialInclusionAssets/situations/passingBall/yellowLight.png'
    },
    Green: {
        key: 'greenTrafficLight',
        url: 'SocialInclusionAssets/situations/passingBall/greenLight.png'
    }

}

export const PassingBallSheets = {
    Tennis: {
        key: 'situationThreeTennis',
        url: 'SocialInclusionAssets/situations/passingBall/tennisPlayer.png',
        frameWidth: 2959 / 7,
        frameHeight: 2527 / 6
    },
    BALL: {
        key: 'situationThreeBounceBall',
        url: 'SocialInclusionAssets/situations/passingBall/bouncingBall.png',
        frameWidth: 369 / 3,
        frameHeight: 171 / 2
    },
    PERSON_DOWN: {
        key: 'situationThreePersonDown',
        url: 'SocialInclusionAssets/situations/passingBall/randBallDown.png',
        frameWidth: 306 / 4,
        frameHeight: 411 / 3
    },
    PERSON_UP: {
        key: 'situationThreePersonUp',
        url: 'SocialInclusionAssets/situations/passingBall/randBallUp.png',
        frameWidth: 185 / 3,
        frameHeight: 274 / 2
    }
}

export const PassingBallAssets = {
    Images: [
        ...Object.values(TrafficLight),
        PurpleCar
    ],
    SpriteSheets: Object.values(PassingBallSheets)
}