import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image

export default class MapPoint extends Phaser.GameObjects.Container {

    constructor(scene, { x, y }, markTexture = Btns.QUESTION) {
        super(scene, x, y);
        this.mark = new Image(scene, 0, 0, markTexture.key);
        this.originPosition = { x, y }
        this.add([this.mark]);
    }

    relocate(scaleX, scaleY, y) {
        return this.setX(this.originPosition.x * scaleX)
            .setY(this.originPosition.y * scaleY + y)

    }

    setInteractive() {
        this.mark.setInteractive({ useHandCursor: true });
        this.tween && this.tween.resume();
        return this;
    }

    disableInteractive() {
        this.mark.disableInteractive();
        //this.tween && this.tween.once('repeat', () => this.tween.pause())
    }

    onclick(callback) {
        this.mark.on('pointerdown', callback);
        return this;
    }

    onceclick(callback) {
        this.mark.off('pointerdown').once('pointerdown', callback);
        return this;
    }

    startTween() {
        if (this.tween)
            this.tween.resume()
        else
            this.tween = this.scene.tweens.add({
                targets: this,
                scale: 1.25,
                yoyo: true,
                ease: 'Power3',
                duration: 1500,
                repeat: -1,
                repeatDelay: 1000
            });
        return this;
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.mark, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        return this;
    }
}