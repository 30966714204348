import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const ShapeSettingKey = {
    SQUARE: 0,
    TRIANGLE: 1,
    CIRCLE: 2,
    STAR: 3
}

export const shapeSetting = {
    name: 'Forma',
    key: SettingKey.SHAPE,
    icon: PreviewIcons.SHAPE,
    tileIcon: TileIcons.SHAPE,
    options: [
        {
            key: ShapeSettingKey.SQUARE,
            title: 'Cuadrado',
            header: 'Solo cuadrados',
            description: 'Todos los estímulos tendrán forma de cuadrado.',
            image: {
                key: 'squareShape',
                url: './HabCogGameAssets/settings/shape/squareShape.png',
            }
        }, {
            key: ShapeSettingKey.TRIANGLE,
            title: 'Triángulo',
            header: 'Solo triángulos',
            description: 'Todos los estímulos tendrán forma de triángulo.',
            image: {
                key: 'triangleShape',
                url: './HabCogGameAssets/settings/shape/triangleShape.png',
            }
        }, {
            key: ShapeSettingKey.CIRCLE,
            title: 'Círculo',
            header: 'Solo círculos',
            description: 'Todos los estímulos tendrán forma de círculo.',
            image: {
                key: 'circleShape',
                url: './HabCogGameAssets/settings/shape/circleShape.png',
            }
        }, {
            key: ShapeSettingKey.STAR,
            title: 'Estrella',
            header: 'Solo estrellas',
            description: 'Todos los estímulos tendrán forma de estrella.',
            image: {
                key: 'starShape',
                url: './HabCogGameAssets/settings/shape/starShape.png',
            }
        }
    ]
};

export const ShapeSettingAssets = shapeSetting.options.map(({ image }) => image);