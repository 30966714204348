export const BooksFull = {
    key: "BooksFulll",
    url: 'SocialInclusionAssets/situations/searchingBooks/booksFull.png'
}

export const BooksEmpty = {
    key: "BooksEmpty",
    url: 'SocialInclusionAssets/situations/searchingBooks/booksEmpty.png'
}

export const SearchingBooksSheets = {
    MOM_HELP:{
        key: "world2Situation5momHelpBoooks",
        url: "SocialInclusionAssets/situations/searchingBooks/momBooks.png",
        frameWidth: 455 / 5,
        frameHeight: 552 / 4
    },
    MAN_GET_HELP: {
        key: "world2Situation5manHelp",
        url: "SocialInclusionAssets/situations/searchingBooks/manBookHelp.png",
        frameWidth: 488 / 5,
        frameHeight: 604 / 4
    },
    MAN_LEAVE:{
        key: "world2Situation5manLeaveBooks",
        url: "SocialInclusionAssets/situations/searchingBooks/manLeaveBooks.png",
        frameWidth: 624 / 5,
        frameHeight: 589 / 4
    },
    MAN_SORT:{
        key: "world2Situation5manOrderBooks",
        url: "SocialInclusionAssets/situations/searchingBooks/manOrderBooks.png",
        frameWidth: 746 / 6,
        frameHeight: 733 / 5
    },
    WOMAN_GET_HELP: {
        key: "world2Situation5womanGetHelp",
        url: "SocialInclusionAssets/situations/searchingBooks/womanBookHelp.png",
        frameWidth: 505 / 5,
        frameHeight: 608 / 4
    },
    WOMAN_LEAVE:{
        key: "world2Situation5womanLeaveBooks",
        url: "SocialInclusionAssets/situations/searchingBooks/womanLeaveBooks.png",
        frameWidth: 616 / 5,
        frameHeight: 581 / 4
    },
    WOMAN_SORT:{
        key: "world2Situation5womanOrderBooks",
        url: "SocialInclusionAssets/situations/searchingBooks/womanOrderBooks.png",
        frameWidth: 745 / 6,
        frameHeight: 732 / 5
    }
}

export const SearchingBooksAssets = {
    Images: [BooksFull, BooksEmpty],
    SpriteSheets: Object.values(SearchingBooksSheets)
}