import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { Outlines } from '../utils/assetsPaths/outlines';
import { scaleImage } from '../utils/resize';
import { GameFont } from '../utils/constants';
import { Icons } from '../utils/assetsPaths/icons';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class HubButton extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label = 'Salir', iconTexture = Icons.EXIT_SESSION) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.HUB.key);
        this.icon = new Image(scene, 0, 0, iconTexture.key);
        this.outline = new Image(scene, 0, 0, Outlines.HUB.key)
            .setVisible(false);
        this.label = new Text(scene, 0, 0, label, HubButtonTextStyle)
            .setOrigin(0.5, 0);
        this.add([this.outline, this.background, this.icon, this.label]);
    }

    setInteractive() {
        this.background.setInteractive({ useHandCursor: true });
        this.setEvents();
    }

    onclick(callback) {
        this.background.on('pointerdown', ()=>callback());
    }

    setEvents() {
        this.background
            .on('pointerover', () => this.addScaleTween(1.25, true))
            .on('pointerout', () => this.addScaleTween(1, false))
    }

    addScaleTween(scale, visible) {
        this.scene.tweens.add({
            targets: this, ease: 'Power3', scale, duration: 125,
            onStart: () => this.outline.setVisible(visible)
        })
    }

    setPosition(x = 0, y = 0) {
        if (this.label)
            super.setPosition(x, y - this.label.displayHeight / 2);
        else super.setPosition(x, y);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.icon.setScale(this.background.scale);
        this.outline.setScale(this.background.scale);
        this.label.setFontSize(`${this.background.displayHeight / 3}px`)
            .setY(this.background.getBottomCenter().y)
    }
}

const HubButtonTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}