import Phaser from 'phaser';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
const Rectangle = Phaser.GameObjects.Rectangle;
const Text = Phaser.GameObjects.Text;
const Image = Phaser.GameObjects.Image;

export default class BadgeBar extends Phaser.GameObjects.Container {

    constructor(scene, x, y, badges, color = 0xffffff) {
        super(scene, x, y);
        this.badges = badges.map(badge => new Image(scene, 0, 0, badge.key).setAlpha(0.5));
        this.items = badges.map(badge => badge.key);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color, 0.9).setOrigin(0);
        this.label = new Text(scene, 0, 0, 'Insignias', BadgeBarTextStyle)
            .setOrigin(0, 0.5);
        this.add([this.background, this.label, ...this.badges]);
    }

    getHeight() {
        return this.background.displayHeight;
    }

    getItemPosition(item) {
        const index = this.items.indexOf(item.key);
        const badge = this.badges[index];
        return {
            x: badge.x + this.x,
            y: badge.y + this.y,
            scale: badge.scale,
            index
        }
    }

    setItemAlpha(index, value) {
        this.badges[index].setAlpha(value);
    }

    getBottomLeft() {
        return { x: this.x, y: this.y + this.getHeight() }
    }

    resize(width, height) {
        this.background.setDisplaySize(width, height);
        this.label.setFontSize(`${height * 0.35}px`)
            .setPosition(height / 4, height / 2);
        scaleImage(this.badges[0], width, height * 0.75, 0, 1);
        const scale = this.badges[0].scale;
        this.badges.forEach(badge => badge.setScale(scale));
        const cellWidth = this.badges[0].displayWidth * 1.5;
        Phaser.Actions.GridAlign(this.badges, {
            width: -1,
            cellWidth,
            x: this.label.getRightCenter().x + height,
            y: height / 2,
            position: Phaser.Display.Align.CENTER
        })
        return this;
    }
}

const BadgeBarTextStyle = {
    fontFamily: GameFont,
    color: 'black'
}