import Phaser from 'phaser'
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { Cursors, sortedGeomTextures, sortedOutlinedGeomTextures } from "../../HabCogGame/utils/constants";
import { DifficultySettingKey } from '../utils/setting';
import { OutlineSettingKey } from '../../HabCogGame/utils/settingsConstants/outlineSetting';
import { pushyTweens, lineTweens } from '../../HabCogGame/utils/pushyTweens/pushyTweens';

const Image = Phaser.GameObjects.Image;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, size } = this.game.userConfig
        this.gameArea = this.add.container(0, this.hubHeight);
        this.targetScale = [0.5, 0.65, 0.8, 1][size];
        this.colorIndex = 0;
        this.textureIndex = 0;
        this.sTextures = this.getSessionTextures();
        this.enableFailOnMiss();
        this.setTweens();
        this.startGameplay(difficulty);
    }

    getSessionTextures() {
        const { difficulty, outline } = this.game.userConfig;
        const useOutlined = outline === OutlineSettingKey.ON;
        const texturesPack = useOutlined ? sortedOutlinedGeomTextures : sortedGeomTextures;
        if (difficulty === DifficultySettingKey.SHAPE_SHIFT)
            return texturesPack.byColor.WHITE;
        else if (difficulty === DifficultySettingKey.COLOR_SHIFT)
            return texturesPack.byShape.SQUARE;
        else return [texturesPack.byShape.SQUARE[4]];
    }

    getNextTexture() {
        const nextIndex = this.textureIndex + 1;
        const length = this.sTextures.length;
        this.textureIndex = nextIndex === length ? 0 : nextIndex;
        return this.sTextures[this.textureIndex];
    }

    startNextTween(nextTexture) {
        const nextIndex = this.tweensIndex + 1;
        this.tweensIndex = nextIndex === this.animTweens.length ? 0 : nextIndex;
        this.animTweens[this.tweensIndex](this, this.target, nextTexture);
    }

    setTweens(difficulty) {
        this.tweensIndex = 0;
        if (difficulty === DifficultySettingKey.SIZE_SHIFT)
            this.animTweens = pushyTweens;
        else
            this.animTweens = [...pushyTweens, ...lineTweens];
    }

    startGameplay(difficulty) {
        if (difficulty === DifficultySettingKey.SIZE_SHIFT)
            this.startSizeGameplay();
        else if (difficulty === DifficultySettingKey.COLOR_SHIFT)
            this.startTextureShiftGameplay(() => this.onColorGameplayHit());
        else
            this.startTextureShiftGameplay(() => this.onShapeGameplayHit());
    }
    startSizeGameplay() {
        const { width, height } = this.getAvailableSize();
        const x = width / 2;
        const y = height / 2;
        const texture = this.sTextures[0];
        this.targetSize = height * 0.75 * this.targetScale;
        this.sizeShifts = 1;
        this.target = new Image(this, x, y, texture.key)
            .setDisplaySize(this.targetSize, this.targetSize);
        this.gameArea.add([this.target]);
        this.target.setInteractive(Cursors.pointerover)
            .on('pointerdown', () => this.onSizeGameplayHit())
    }

    startTextureShiftGameplay(onHitCallback) {
        const { width, height } = this.getAvailableSize();
        const size = height * 0.5 * this.targetScale;
        const { key } = this.getNextTexture();
        const x = width / 2;
        const y = height / 2;
        this.target = new Image(this, x, y, key)
            .setDisplaySize(size, size);
        this.gameArea.add(this.target);
        this.target.setInteractive(Cursors.pointerover)
            .on('pointerdown', onHitCallback)
    }

    onColorGameplayHit() {
        this.addHit();
        this.startNextTween(this.getNextTexture());
    }

    onShapeGameplayHit() {
        this.addHit();
        this.startNextTween(this.getNextTexture());
    }

    onSizeGameplayHit() {
        this.addHit();
        const size = this.targetSize;
        if (this.sizeShifts === 4) {
            this.sizeShifts = 1;
            this.target.setDisplaySize(size, size);
            this.startNextTween();
        }
        else {
            this.sizeShifts++;
            const nextSize = this.target.displayWidth / 2
            this.target.setDisplaySize(nextSize, nextSize);
        }
    }
}
