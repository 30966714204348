const VegetablesPhotos = [
    {
        key: 'vegetablePhoto1',
        url: './photos/vegetables/vegetable1.png'
    }, {
        key: 'vegetablePhoto2',
        url: './photos/vegetables/vegetable2.png'
    }, {
        key: 'vegetablePhoto3',
        url: './photos/vegetables/vegetable3.png'
    }, {
        key: 'vegetablePhoto4',
        url: './photos/vegetables/vegetable4.png'
    }, {
        key: 'vegetablePhoto5',
        url: './photos/vegetables/vegetable5.png'
    }
]

export default VegetablesPhotos;