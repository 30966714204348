import { generateFrameNames } from "../../../functions"

export const BoringWorkSheets = {
    MAN_LIFT_BOX: {
        key: "world3manLiftBox",
        url: "SocialInclusionAssets/situations/boringWork/manLiftBox.png",
        frameWidth: 1073 / 6,
        frameHeight: 1471 / 6
    },
    MAN_LISTEN_MUSIC: {
        key: "world3manlistenmusic",
        url: "SocialInclusionAssets/situations/boringWork/manListenWorkMusic.png",
        frameWidth: 1253 / 7,
        frameHeight: 1501 / 6
    },
    MAN_TAKE_NAP: {
        key: "world3manTakeNap",
        url: "SocialInclusionAssets/situations/boringWork/manTakeNap.png",
        frameWidth: 1155 / 6,
        frameHeight: 1474 / 6
    },
    MAN_WASH_FACE: {
        key: "world3manWashFace",
        url: "SocialInclusionAssets/situations/boringWork/manWashFace.png",
        frameWidth: 194 / 3,
        frameHeight: 392 / 3
    },
    WOMAN_LIFT_BOX: {
        key: "world3womanLiftBox",
        url: "SocialInclusionAssets/situations/boringWork/womanLiftBox.png",
        frameWidth: 1072 / 6,
        frameHeight: 1497 / 6
    },
    WOMAN_LISTEN_MUSIC: {
        key: "world3womanlistenmusic",
        url: "SocialInclusionAssets/situations/boringWork/womanListenWorkMusic.png",
        frameWidth: 1263 / 7,
        frameHeight: 1499 / 6
    },
    WOMAN_TAKE_NAP: {
        key: "world3womanTakeNap",
        url: "SocialInclusionAssets/situations/boringWork/womanTakeNap.png",
        frameWidth: 1149 / 6,
        frameHeight: 1495 / 6
    },
    WOMAN_WASH_FACE: {
        key: "world3womanWashFace",
        url: "SocialInclusionAssets/situations/boringWork/womanWashFace.png",
        frameWidth: 205 / 3,
        frameHeight: 399 / 3
    },
}

export const WorkStation = {
    key: "w3s1WorkStation",
    url: "SocialInclusionAssets/situations/boringWork/workStation.png"
}

export const BoringWorkAnims = {
    MAN_LIFT_BOX: {
        key: "w3s1ManLiftBox",
        frames: generateFrameNames(BoringWorkSheets.MAN_LIFT_BOX.key, 0, 20),
        frameRate: 3
    },
    MAN_USE_HEADPHONES: {
        key: "w3s1ManPutHeadPhones",
        frames: generateFrameNames(BoringWorkSheets.MAN_LISTEN_MUSIC.key, 20, 27),
        frameRate: 3
    },
    MAN_LIFT_BOX_HEADPHONES: {
        key: "w3s1ManListenMusic",
        frames: generateFrameNames(BoringWorkSheets.MAN_LISTEN_MUSIC.key, 28, 37),
        frameRate: 3,
    },
    MAN_TAKE_NAP: {
        key: "w3s1ManTakeNap",
        frames: generateFrameNames(BoringWorkSheets.MAN_TAKE_NAP.key, 22, 30),
        frameRate: 3
    },
    MAN_GET_BORED: {
        key: "w3s1ManGetBored",
        frames: generateFrameNames(BoringWorkSheets.MAN_LIFT_BOX.key, 20, 21),
        frameRate: 1
    },
    MAN_WASH_FACE: {
        key: "w3s1ManWashFace",
        frames: generateFrameNames(BoringWorkSheets.MAN_WASH_FACE.key, 0, 8),
        frameRate: 3,
        repeat: 1
    },
    WOMAN_LIFT_BOX: {
        key: "w3s1woManLiftBox",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_LIFT_BOX.key, 0, 20),
        frameRate: 3
    },
    WOMAN_USE_HEADPHONES: {
        key: "w3s1woManPutHeadPhones",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_LISTEN_MUSIC.key, 20, 27),
        frameRate: 3,
    },
    WOMAN_LIFT_BOX_HEADPHONES: {
        key: "w3s1woManListenMusic",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_LISTEN_MUSIC.key, 28, 37),
        frameRate: 3
    },
    WOMAN_TAKE_NAP: {
        key: "w3s1woManTakeNap",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_TAKE_NAP.key, 22, 30),
        frameRate: 3
    },
    WOMAN_GET_BORED: {
        key: "w3s1woManGetBored",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_LIFT_BOX.key, 20, 21),
        frameRate: 1
    },
    WOMAN_WASH_FACE: {
        key: "w3s1woManWashFace",
        frames: generateFrameNames(BoringWorkSheets.WOMAN_WASH_FACE.key, 0, 8),
        frameRate: 3,
        repeat: 1
    },
}

export const ManWorkIdle = {
    FRONT: {
        key: "worldWorkManIdle",
        url: "SocialInclusionAssets/situations/boringWork/manIdle.png"
    },
    BACK: {
        key: "worldWorkManBackIdle",
        url: "SocialInclusionAssets/situations/boringWork/manBackIdle.png"
    }
}

export const WomanWorkIdle = {
    FRONT: {
        key: "worldWorkwomanIdle",
        url: "SocialInclusionAssets/situations/boringWork/womanIdle.png"
    },
    BACK: {
        key: "worldWorkwomanBackIdle",
        url: "SocialInclusionAssets/situations/boringWork/womanBackIdle.png"
    }
}


export const ManWorkSheets = {
    FRONT: {
        key: "worldWorkManFrontWalk",
        url: "SocialInclusionAssets/situations/boringWork/manWalkFront.png",
        frameWidth: 200 / 3,
        frameHeight: 275 / 2
    },
    BACK: {
        key: "worldWorkManBackWalk",
        url: "SocialInclusionAssets/situations/boringWork/manWalkBack.png",
        frameWidth: 181 / 3,
        frameHeight: 275 / 2
    }
}

export const ManWorkAnims = {
    FRONT: {
        key: "worldWorkManFrontWalk",
        frames: generateFrameNames(ManWorkSheets.FRONT.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    BACK: {
        key: "worldWorkManBackWalk",
        frames: generateFrameNames(ManWorkSheets.BACK.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

export const WomanWorkSheets = {
    FRONT: {
        key: "worldWorkwoManFrontWalk",
        url: "SocialInclusionAssets/situations/boringWork/womanWalkFront.png",
        frameWidth: 210 / 3,
        frameHeight: 293 / 2
    },
    BACK: {
        key: "worldWorkwoManBackWalk",
        url: "SocialInclusionAssets/situations/boringWork/womanWalkBack.png",
        frameWidth: 186 / 3,
        frameHeight: 276 / 2
    }
}

export const WomanWorkAnims = {
    FRONT: {
        key: "worldWorkwoManFrontWalk",
        frames: generateFrameNames(WomanWorkSheets.FRONT.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    BACK: {
        key: "worldWorkwoManBackWalk",
        frames: generateFrameNames(WomanWorkSheets.BACK.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

export const BoringWorkAssets = {
    Images: [...Object.values(ManWorkIdle), ...Object.values(WomanWorkIdle), WorkStation],
    SpriteSheets: [
        ...Object.values(BoringWorkSheets),
        ...Object.values(ManWorkSheets),
        ...Object.values(WomanWorkSheets)
    ]
}