import React from 'react';
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { difficultySetting, DifficultySettingAssets } from "./utils/settings";
import feedbackSetting, { FeedbackSettingAssets } from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { QuantitySettingAssets } from '../HabCogGame/utils/settingsConstants/quantitySetting';
import { TargetFeedbackAssets } from '../HabCogGame/utils/assetsPaths/targets';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';
import { IntrudersPhotos } from './utils/assets';

const sessionAssets = [
    ...DifficultySettingAssets,
    ...IntrudersPhotos,
    ...SizeSettingAssets,
    ...FeedbackSettingAssets,
    ...QuantitySettingAssets,
    ...TargetFeedbackAssets,
    ...TimeSettingAssets
];

const data = {
    name: 'Intrusos',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './IntrudersGameAssets/logo.png',
    iconUrl: './IntrudersGameAssets/icon.png',
    thumbnail: './IntrudersGameAssets/thumbnail.png',
    getHint: () => 'Toca la imagen diferente.',
    userManual: 'En un conjunto de imágenes, el usuario debe señalar la que es diferente ' +
        'por: forma, inversión o simetría, según se haya definido en ajustes. La ' +
        'configuración del juego permite establecer: tipo de dificultad, tamaño, ' +
        'número de estímulos, identificación del error y tiempo de duración de la ' +
        'sesión.',
    settings: [
        difficultySetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function IntrudersGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
