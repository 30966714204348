import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { OutlineSettingKey } from '../../HabCogGame/utils/settingsConstants/outlineSetting';
import {
    getDifferentRandomItem, randomNum,
    getRandomSubArray, getTargetSize
} from "../../HabCogGame/utils/functions";
import {
    sortedGeomTextures, sortedOutlinedGeomTextures, Cursors
} from "../../HabCogGame/utils/constants";

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { userConfig } = this.game;
        const { difficulty, color, shape, outline, quantity } = userConfig;
        const { width, height } = this.getAvailableSize();
        const tPack = getTexturesPack(outline);
        const length = getGeomsLength(quantity);
        this.sTextures = getSessionTextures(difficulty, color, shape, tPack);
        const geoms = createImagesArray(this, length, this.sTextures[0]);
        const { size, positions } = getGeomsPositions(width, height, length);
        alignGeoms(geoms, positions, size);
        this.gameArea = this.add.container(0, this.hubHeight).setDepth(1);
        this.gameArea.add(geoms);
        this.posibleTargets = calculateTargetsNum(quantity);
        this.geomScale = geoms[0].scale;
        drawPatterBorder(this, geoms[0]);
        geoms[0].removeInteractive();
        this.startGameplay(geoms);
    }

    startGameInstruction({ name, color }, targetsLength, callback) {
        const isSingle = targetsLength === 1;
        const { difficulty } = this.game.userConfig;
        const colorInstruction = difficulty !== 0 ? `de color ${color}` : '';
        const { pronoun, shapeName } = getPronounTexts(isSingle, name);
        const instruction = `Toca ${pronoun} ${shapeName} ${colorInstruction}`;
        this.sceneTextInstruction = instruction;
        this.hub.updateText(instruction);
        if (localStorage.getItem('voiceSound') >= 0) {
            this.speak(this.sceneTextInstruction);
        }
        this.startStandby(callback);
    }

    startLeaveTween(target) {
        startTween(target, this.geomScale, this.tweens, false)
    }

    startEnterTween(target) {
        startTween(target, this.geomScale, this.tweens, true);
    }

    startGameplay(geoms, lastTexture, lastTargetNum) {
        const getTexture = (i) => getDifferentRandomItem(this.sTextures, i);
        const targetTexture = getTexture(lastTexture);
        const patterGeom = geoms[0];
        const posibleTargets = getPosibleTargets(this.posibleTargets, lastTargetNum);
        const targets = getGeomsTargets(geoms, posibleTargets);
        const onStandBy = () => this.startGameplay(geoms, targetTexture, posibleTargets);
        let targetsHitted = 0;

        geoms.forEach(geom => geom
            .off('pointerdown')
            .once('pointerdown', () => this.addFail())
            .setTexture(getTexture(targetTexture).key));
        patterGeom.setTexture(targetTexture.key);
        targets.forEach(target =>
            target.setTexture(targetTexture.key)
                .off('pointerdown').once('pointerdown', () => {
                    targetsHitted++;
                    this.startLeaveTween(target);
                    this.addHit();
                    if (targetsHitted === targets.length) {
                        this.standbyEvent.destroy();
                        this.startGameplay(geoms, targetTexture, posibleTargets);
                    }
                }));
        this.startGameInstruction(targetTexture, targets.length, onStandBy);
        geoms.forEach(geom => this.startEnterTween(geom))
    }
}

const getGeomsTargets = (geoms, posibleTargets) =>
    getRandomSubArray(geoms.slice(1, geoms.length), posibleTargets);

const getPronounTexts = (isSingleTarget, name) => {
    const finishedInA = name[name.length - 1] === 'a';
    const malePronouns = { singular: 'el', plural: 'todos los' };
    const femalePronouns = { singular: 'la', plural: 'todas las' };
    const additive = isSingleTarget ? '' : 's';
    const { singular, plural } = finishedInA ? femalePronouns : malePronouns;
    return {
        pronoun: isSingleTarget ? singular : plural,
        shapeName: `${name}${additive}`,
        figures: isSingleTarget ? 'la' : 'todas las'
    }
};

const getTexturesPack = (outline) =>
    outline === OutlineSettingKey.OFF ? sortedGeomTextures : sortedOutlinedGeomTextures;

const getSessionTextures = (difficulty, color, shape, { byColor, byShape, ALL }) => {
    switch (difficulty) {
        case 0:
            return Object.values(byColor)[color];
        case 1:
            return Object.values(byShape)[shape];
        default: return ALL;
    }
};

const alignGeoms = (geoms, positions, size) => geoms.forEach((geom, index) => {
    const { x, y } = positions[index];
    const padding = size / 2;
    geom
        .setInteractive(Cursors.pointerover)
        .setDisplaySize(size, size)
        .setPosition(x + padding, y + padding)
});

const startTween = (geom, scale, tweens, isEntering) => {
    tweens.add({
        targets: geom,
        scale: isEntering ? { from: 0, to: scale } : { from: scale, to: 0 },
        duration: 500
    })
};

const getGeomsLength = (quantity) => [3, 4, 5, 6, 9, 24, 48, 108][quantity];

const createImagesArray = (scene, quantity, texture) => {
    const targets = [];
    for (let i = 0; i < quantity; i++)
        targets.push(new Phaser.GameObjects.Image(scene, 0, 0, texture.key));
    return targets
};

const getGeomsPositions = (width, height, geomsLength) => {
    const calc = (...args) => calculateTargetPositions(width, height, ...args);
    let config;
    switch (geomsLength) {
        case 3:
            config = calc(2, 2);
            config.positions.pop();
            return config;
        case 4: return calc(2, 2);
        case 5:
            const regularSize = getTargetSize(width, height, 2, 2);
            const fixedSize = width / 3 * 0.95;
            const size = fixedSize < regularSize ? fixedSize : regularSize;
            config = calc(2, 2, size);
            config.positions.push({ x: (width - size) / 2, y: (height - size) / 2 });
            return config;
        case 6: return calc(2, 3);
        case 9: return calc(3, 3);
        case 24: return calc(4, 6);
        case 48: return calc(6, 8);
        default: return calc(9, 12);
    }
};

const calculateTargetsNum = (quantity) => {
    const num = quantity < 3 ? 3 : quantity;
    switch (num) {
        case 3: return { min: 1, max: 2 };
        case 4: return { min: 2, max: 5 };
        case 5: return { min: 7, max: 12 };
        case 6: return { min: 12, max: 24 };
        default: return { min: 27, max: 54 };
    }
};

const getPosibleTargets = ({ max, min }, last) => {
    const range = max - min;
    let targets = randomNum(range) + min;
    while (targets === last) targets = randomNum(range) + min;
    return targets;
};

const drawPatterBorder = (scene, gameObject) => {
    const graphics = scene.make.graphics({}, true);
    gameObject.setDepth(10000);
    const x = 0;
    const y = scene.hubHeight;
    const { displayWidth } = gameObject;
    graphics.fillStyle(0xBF42F5)
        .fillRect(x, y, displayWidth, displayWidth);
};

const calculateTargetPositions = (width, height, rows, cols, fixedSize) => {
    const positions = [];
    const size = fixedSize || getTargetSize(width, height, rows, cols);
    const cellWidth = (width - size) / (cols - 1);
    const cellHeight = (height - size) / (rows - 1);
    for (let i = 0; i < rows; i++)
        for (let j = 0; j < cols; j++)
            positions.push({
                x: j * cellWidth,
                y: i * cellHeight
            });
    return { size, positions }
};
