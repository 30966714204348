export const Btns = {
    PRIMARY: {
        key: 'primaryBtnBackground',
        url: './HabCogGameAssets/btns/primaryBtnBackground.png'
    },
    SECONDARY: {
        key: 'secondaryBtnBackground',
        url: './HabCogGameAssets/btns/secondaryBtnBackground.png'
    },
    INSTRUCTION_BOX: {
        key: 'instructionBoxBackground',
        url: './SocialInclusionAssets/controllers/instructionboxBackground.png'
    },
    MESSAGE_BOX: {
        key: 'messageBoxBackground',
        url: './HabCogGameAssets/btns/messageBoxBackground.png'
    },
    LARGE_MESSAGE_BOX: {
        key: 'largeMessageBoxBackground',
        url: './HabCogGameAssets/btns/largeMessageBoxBackground.png'
    },
    HAPPY_FACE: {
        key: 'happyFaceImg',
        url: 'SocialInclusionAssets/controllers/happyFace.png'
    },
    SAD_FACE: {
        key: 'sadFaceImg',
        url: 'SocialInclusionAssets/controllers/sadFace.png'
    },
    PROMPT: {
        key: 'promptImg',
        url: 'SocialInclusionAssets/controllers/promptBackground.png'
    },
    HINT: {
        key: 'hintImg',
        url: 'SocialInclusionAssets/controllers/hintMark.png'
    },
    QUESTION: {
        key: 'questionMark',
        url: 'SocialInclusionAssets/controllers/questionMark.png'
    },
    LUDO: {
        key: 'ludoBtn',
        url: 'SocialInclusionAssets/controllers/ludo.png'
    },
    ACCESIBILITY: {
        key: 'accesibilityBtnBackground',
        url: './HabCogGameAssets/btns/accessBtnBackground.png'
    },
    USER_TAG: {
        key: 'userTagBackground',
        url: './HabCogGameAssets/btns/userTagBackground.png'
    },
    CHARACTER_BORDER: {
        key: "characterBorderImg",
        url: "SocialInclusionAssets/controllers/characterBorder.png"
    },
    BADGE_CONTAINER: {
        key: "badgeContainerImg",
        url: "SocialInclusionAssets/controllers/badgeContainer.png"
    },
    MODAL: {
        key: 'modalBackground',
        url: './HabCogGameAssets/btns/modalBackground.png'
    },
    LUDO_ASK: {
        key: "ludoaskimg",
        url: "SocialInclusionAssets/controllers/ludoAsk.png"
    },
    QUESTION_BOX: {
        key: "Questionbackground",
        url: "SocialInclusionAssets/controllers/questionBackground.png"
    },
    SITUATION_OP: {
        key: "optionbackgroundimg",
        url: "SocialInclusionAssets/controllers/optionBackground.png"
    },
    SOUND_ICON: {
        key: "soundiconimgs",
        url: "SocialInclusionAssets/controllers/soundIcon.png"
    },
    CONFIRM_DIALOG: {
        key: 'confirmDialogBackground',
        url: './HabCogGameAssets/btns/confirmDialogBackground.png'
    }
}

export const BtnAssets = Object.values(Btns);