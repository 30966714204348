import Phaser from "phaser";
import { Btns } from "../utils/assetsPaths/btn";
import { GameFont } from "../utils/constants";
import { scaleImage } from "../utils/resize";
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

class Dialog extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, badge) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.LARGE_MESSAGE_BOX.key)
            .setInteractive();
        this.label = new Text(scene, 0, 0, label, DialogTextStyle).setOrigin(0.5).setAlpha(0);
        const texture = badge ? Btns.HAPPY_FACE : Btns.SAD_FACE;
        this.img = new Image(scene, 0, 0, texture.key);
        this.add([this.background, this.label, this.img]);
        this.setDepth(10);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        const { displayWidth, displayHeight } = this.background;
        this.label.setFontSize(`${displayHeight / 10}px`)
            .setY(this.background.getTopCenter().y + displayHeight * 0.2);
        scaleImage(this.img, displayWidth, displayHeight * 0.75, 50, 1);
        return this;
    }
}

const DialogTextStyle = {
    fontFamily: GameFont,
    color: '#204659'
}

export default Dialog;