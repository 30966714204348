import phaser from 'phaser'
import buttonComponent from '../Button/button'
import { smallCardComponentStructure } from './SmallCardComponentStructure'
import { buttonPressAnimation, mouseOverAnimation } from '../../Functions/AnimatedFunctions'


export default class smallCardComponentComponent extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, title, image, description, id) {
        super(scene, x, y)

        this.image = null
        this.title = null
        this.workshopId = id
        this.description = null
        this.button = null
        this.scene = scene

        this.generated(scene, x, y, width, heigth, title, image, description, id)


    }

    generated(scene, x, y, width, heigth, title, image, description, id) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);
        //outline
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, 'rectangleBoardOutline').setVisible(false)
        this.outline.setDisplaySize(width * 1.1, heigth * 1.05);
        //Title
        this.nameBackGround = new phaser.GameObjects.Image(scene, smallCardComponentStructure.cardTitle.scale.objectPositionX, smallCardComponentStructure.cardTitle.scale.objectPositionY, 'blackBackground')
        this.nameBackGround.setDisplaySize(smallCardComponentStructure.cardTitle.scale.objectWidth, smallCardComponentStructure.cardTitle.scale.objectHeight);
        this.title = new phaser.GameObjects.Text(this.scene, smallCardComponentStructure.cardTitle.scale.objectPositionX, smallCardComponentStructure.cardTitle.scale.objectPositionY, title, smallCardComponentStructure.cardTitle.style)
        this.title.setOrigin(0.5)

        //Image
        //smallCardComponentStructure.cardImage.style.fontSize = ScaleFunctionText(this.scene.sys.canvas.width, this.scene.sys.canvas.height, smallCardComponentStructure.cardImage.style.fontSize)
        this.image = new phaser.GameObjects.Image(this.scene, 0, 0, image)
        this.image.setDisplaySize(smallCardComponentStructure.cardImage.scale.objectWidth, smallCardComponentStructure.cardImage.scale.objectHeight)

        //Description
        this.descriptionBackground = new phaser.GameObjects.Image(scene, smallCardComponentStructure.cardDescription.scale.objectPositionX, smallCardComponentStructure.cardDescription.scale.objectPositionY, 'blackBackground')
        this.descriptionBackground.setDisplaySize(smallCardComponentStructure.cardDescription.scale.objectWidth, smallCardComponentStructure.cardDescription.scale.objectHeight);
        this.description = new phaser.GameObjects.Text(this.scene, smallCardComponentStructure.cardDescription.scale.objectPositionX, smallCardComponentStructure.cardDescription.scale.objectPositionY, description, smallCardComponentStructure.cardDescription.style)
        this.description.setWordWrapWidth(smallCardComponentStructure.cardDescription.scale.objectWidth);
        this.description.setOrigin(0.5)

        //button
        this.button = new buttonComponent(this.scene, smallCardComponentStructure.cardButton)
        this.button.setInteractive().on('pointerdown', () => {
            console.log("Sonido " + id)
            this.scene.game.voice.speak(title)

        });
        this.click = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.click.setDisplaySize(width, heigth);
        this.click.setAlpha(0.001)
        this.click.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            console.log("A Vocabulario " + id)
            buttonPressAnimation(scene, this)
            this.scene.game.voice.speak(title)
        }).on('pointerover', () => {
            mouseOverAnimation(scene, this, 1.005)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimation(scene, this, 1)
            this.outline.setVisible(false)
        });



        this.add([this.outline, this.background, this.image, this.nameBackGround, this.title, this.descriptionBackground, this.description, this.button, this.click])
        this.setSize(width, heigth)
    }

}