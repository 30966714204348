
import { base } from '../configServices'

export default {
    getStories(id) {
        return base.url + '/workshops/' + id + '/histories'

    },
    getLaminesNumber() {
        return base.url + '/lamines'
    }
}