import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Muy lento',
            header: 'Veinte segundos',
            description: 'El estímulo abandonará la pantalla en 20 segundos.',
            image: {
                key: 'verySlowTarget',
                url: './HabCogGameAssets/settings/speed/verySlowTarget.png'
            }
        },
        {
            title: 'Lento',
            header: 'Quince segundos',
            description: 'El estímulo abandonará la pantalla en 15 segundos.',
            image: {
                key: 'slowTarget',
                url: './HabCogGameAssets/settings/speed/slowTarget.png'
            }
        },
        {
            title: 'Normal',
            header: 'Diez segundos',
            description: 'El estímulo abandonará la pantalla en 10 segundos.',
            image: {
                key: 'normalTarget',
                url: './HabCogGameAssets/settings/speed/normalTarget.png'
            }
        },
        {
            title: 'Rápido',
            header: 'Cinco segundos',
            description: 'El estímulo abandonará la pantalla en 5 segundos.',
            image: {
                key: 'fastTarget',
                url: './HabCogGameAssets/settings/speed/fastTarget.png'
            }
        },
        {
            title: 'Muy rápido',
            header: 'Un segundo',
            description: 'El estímulo abandonará la pantalla en 1 segundo.',
            image: {
                key: 'veryFastTarget',
                url: './HabCogGameAssets/settings/speed/veryFastTarget.png'
            }
        }
    ]
};

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image)
