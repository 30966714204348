import Phaser from "phaser";
import { Btns } from "../utils/assetsPaths/btn";
import { GameFont } from "../utils/constants";
import { scaleImage } from "../utils/resize";
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class LargeMessageBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label) {
        super(scene, x, y)
        this.background = new Image(scene, 0, 0, Btns.LARGE_MESSAGE_BOX.key);
        this.label = new Text(scene, 0, 0, label, LargeMessageBoxTextStyle).setOrigin(0.5);
        this.add([this.background, this.label]);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.label.setFontSize(`${this.background.displayHeight / 16}px`)
            .setWordWrapWidth(this.background.displayWidth * 0.85);
    }
}

const LargeMessageBoxTextStyle = {
    fontFamily: GameFont,
    color: '#204659',
    align: 'justify',
}