import Phaser from "phaser";
import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { AskingRestroomSheets, AskingRestroomAnims } from "../../utils/assetsPaths/situations/work/askingRestroom";
import { ManWorkIdle, WomanWorkIdle } from "../../utils/assetsPaths/situations/work/boringWork";
import { createBaldMan, createFriend } from "../../utils/npcFactory.js/factory";
import { FriendAnims } from "../../utils/npcFactory.js/friend";
import { BaldManAnims } from "../../utils/npcFactory.js/baldMan";

export default class AskingRestroomSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 3630, y: 212 };
        const mcPosition = { x: 3275, y: 335 };
        super(scene, markPosition, mcPosition, Badges.SIGN);
        this.instructionLabel = "Una persona te pregunta dónde está el baño, ¿qué haces?";
        this.optionsData = [{
            label: "Aparento no escucharle.",
            onclick: this.runEvent(2500, () => this.ignoreMan())
        }, {
            label: "Le indico cómo llegar.",
            onclick: this.runEvent(2500, () => this.help())
        }, {
            label: "Pido que alguien le acompañe.",
            onclick: this.runEvent(2500, () => this.call())
        }]
    }

    preload() {
        window.scene = this;
        Object.values(AskingRestroomAnims).forEach(anim => this.scene.anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.cook = this.scene.add.sprite(0, 0, AskingRestroomSheets.COOK.key, 0)
            .setScale(this.scene.mc.scale)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 3360, scaleY * 175 + y)
        return this;
    }

    showBaldMan() {
        this.baldMan = createBaldMan(this.scene, 2230, 467)
        this.baldMan.sprite.setDepth(this.scene.mc.depth + 1);
        this.scene.tweens.add({
            targets: this.baldMan.sprite,
            alpha: 1
        })
        this.baldMan.follower.goTo(new Phaser.Math.Vector2(3100, 335))
            .onceMovementComplete(() => {
                this.baldMan.sprite.setFlipX(false)
                this.addEvent(2000, () => this.baldMan.sprite.play(BaldManAnims.ASK.key)
                    .once("animationcomplete", () => {
                        this.baldMan.follower.emit("onMovementComplete");
                        this.addEvent(2500, () => {
                            this.scene.mc.play(this.mcIsBoy ? AskingRestroomAnims.MAN_TURN_BACK.key : AskingRestroomAnims.WOMAN_TURN_BACK.key)
                                .once("animationcomplete", this.runEvent(2000, () => this.showHint()))
                        })
                    }))
            }
            )
    }

    reboot(onComplete) {
        this.baldMan.destroy();
        this.scene.follower.emit("onMovementComplete");
        onComplete();

    }

    ignoreMan() {
        const anims = AskingRestroomAnims;
        const ignore = this.mcIsBoy ? anims.MAN_IGNORE : anims.WOMAN_IGNORE;
        const mc = this.scene.mc;
        mc.play(ignore.key)
            .once("animationcomplete",
                this.runEvent(2500, () => addDialogTween(this.scene, "Mala decisión")))
    }

    help() {
        const anims = AskingRestroomAnims;
        const help = this.mcIsBoy ? anims.MAN_HELP : anims.WOMAN_HELP;
        const mc = this.scene.mc;
        mc.play(help.key)
            .once("animationcomplete", () => {
                this.scene.tweens.add({
                    targets: this.baldMan.sprite,
                    alpha: 0,
                    delay: 3000,
                })
                this.addEvent(2000, () => {
                    this.scene.follower.emit("onMovementComplete");
                    this.baldMan.follower.goTo(new Phaser.Math.Vector2(3766, 696))
                        .onceMovementComplete(
                            () => addDialogTween(this.scene, "Buena decisión", this.badge)
                        )
                }
                )


            })
    }

    call() {
        const friend = createFriend(this.scene, 2230, 467)
        const anims = AskingRestroomAnims;
        const call = this.mcIsBoy ? anims.MAN_CALL : anims.WOMAN_CALL;
        const mc = this.scene.mc;
        mc.play(call.key)
            .once("animationcomplete", () => {
                this.addEvent(2000, () => {
                    this.scene.tweens.add({
                        targets: friend.sprite,
                        alpha: 1
                    });
                    friend.follower.goTo(new Phaser.Math.Vector2(2929, 344))
                        .onceMovementComplete(() => {
                            friend.sprite.setFlipX(false);
                            this.baldMan.sprite.setFlipX(true);
                            this.addEvent(2500, () => {
                                friend.sprite.play(FriendAnims.HELP.key)
                                    .once("animationcomplete", () => {
                                        this.addEvent(2500, () => {
                                            this.scene.tweens.add({
                                                targets: [this.baldMan.sprite, friend.sprite],
                                                alpha: 0,
                                                delay: 3000,
                                            })
                                            this.addEvent(2000, () => {
                                                friend.follower.goTo(new Phaser.Math.Vector2(3766, 696));
                                                friend.sprite.setDepth(this.baldMan.sprite.depth + 1);
                                                this.scene.follower.emit("onMovementComplete");
                                                this.addEvent(1000, () => {
                                                    this.baldMan.follower.goTo(new Phaser.Math.Vector2(3766, 696))
                                                        .onceMovementComplete(
                                                            () => {
                                                                friend.destroy();
                                                                addDialogTween(this.scene, "Buena decisión", this.badge)
                                                            }
                                                        )
                                                })
                                            }
                                            )
                                        })
                                    })
                            })
                        })
                })
            })
    }

    start() {
        const mc = this.scene.mc;
        const anims = AskingRestroomAnims;
        const idle = this.mcIsBoy ? ManWorkIdle.BACK : WomanWorkIdle.BACK;
        const grabTee = this.mcIsBoy ? anims.MAN_GRAB_TEE : anims.WOMAN_GRAB_TEE;
        mc.setTexture(idle.key).setFlipX(true);
        this.addEvent(2000, () => {
            this.cook.play(AskingRestroomAnims.COOK.key)
                .once("animationcomplete", this.runEvent(2000, () => {
                    mc.setFlipX(false);
                    mc.play(grabTee.key)
                        .once("animationcomplete", () => {
                            this.cook.setTexture(AskingRestroomSheets.COOK.key, 0);
                            this.addEvent(1500, () => this.showBaldMan())
                        })
                }))
        })
    }


}