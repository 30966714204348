import Phaser from 'phaser'


export default class Splashlb extends Phaser.Scene {
    constructor() {
        super('bootGame');
    }
    preload() {

        //Pre comon assets
        this.load.image('ludoSplash', '/LaboralInclusionAssets/Splash/SplashIcon.png')
        this.load.image('background', './LaboralInclusionAssets/background/sceneBackground.png')
        this.game.voice.setVoices()
        //font load
        this.arialRoundedFont = new window.FontFace('ArialRounded', 'url(/LaboralInclusionAssets/font/ArialRoundedMT.ttf)');
        this.arialRoundedFont.load()
            .then(loadedFont => document.fonts.add(loadedFont))
            .catch((e) => console.log('error al cargar la fuente', e));
        this.load.on('complete', function () {
            this.scene.scene.start("Splash")
        });

    }

}

