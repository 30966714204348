import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Formas',
            header: 'Formas distintas',
            description: 'El estímulo objetivo tendrá una forma diferente.',
            image: {
                key: 'shapeShift',
                url: './TwinsGameAssets/settings/difficulty/shapeShift.png'
            },
        },
        {
            title: 'Inversiones',
            header: 'Forma invertida',
            description: 'El estímulo objetivo se presentará de forma invertida.',
            image: {
                key: 'flipyShift',
                url: './TwinsGameAssets/settings/difficulty/flipyShift.png'
            },
        },
        {
            title: 'Simetrías',
            header: 'Forma simétrica',
            description: 'El estímulo objetivo se presentará de forma simétrica.',
            image: {
                key: 'flipxShift',
                url: './TwinsGameAssets/settings/difficulty/flipxShift.png'
            },
        }
    ]
};

export default difficultySetting;

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);
