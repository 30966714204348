import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { FeedbackSettingAssets } from "../../HabCogGame/utils/settingsConstants/feedbackSetting";
import { DifficultySettingAssets } from "./settings";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const planeAssets = {
    COMPLETE: {
        key: 'completePlane',
        url: './SomethingMissingGameAssets/plane/completePlane.png'
    },
    WRONG: [
        {
            key: 'wrongPlane1',
            url: './SomethingMissingGameAssets/plane/wrongPlane1.png'
        },
        {
            key: 'wrongPlane2',
            url: './SomethingMissingGameAssets/plane/wrongPlane2.png'
        },
        {
            key: 'wrongPlane3',
            url: './SomethingMissingGameAssets/plane/wrongPlane3.png'
        },
        {
            key: 'wrongPlane4',
            url: './SomethingMissingGameAssets/plane/wrongPlane4.png'
        },
        {
            key: 'wrongPlane5',
            url: './SomethingMissingGameAssets/plane/wrongPlane5.png'
        },
        {
            key: 'wrongPlane6',
            url: './SomethingMissingGameAssets/plane/wrongPlane6.png'
        },
        {
            key: 'wrongPlane7',
            url: './SomethingMissingGameAssets/plane/wrongPlane7.png'
        },
        {
            key: 'wrongPlane8',
            url: './SomethingMissingGameAssets/plane/wrongPlane8.png'
        },
        {
            key: 'wrongPlane9',
            url: './SomethingMissingGameAssets/plane/wrongPlane9.png'
        }]
};

export const carAssets = {
    COMPLETE: {
        key: 'completeCar',
        url: './SomethingMissingGameAssets/car/completeCar.png'
    },
    WRONG: [
        {
            key: 'wrongCar1',
            url: './SomethingMissingGameAssets/car/wrongCar1.png'
        },
        {
            key: 'wrongCar2',
            url: './SomethingMissingGameAssets/car/wrongCar2.png'
        },
        {
            key: 'wrongCar3',
            url: './SomethingMissingGameAssets/car/wrongCar3.png'
        },
        {
            key: 'wrongCar4',
            url: './SomethingMissingGameAssets/car/wrongCar4.png'
        },
        {
            key: 'wrongCar5',
            url: './SomethingMissingGameAssets/car/wrongCar5.png'
        },
        {
            key: 'wrongCar6',
            url: './SomethingMissingGameAssets/car/wrongCar6.png'
        },
        {
            key: 'wrongCar7',
            url: './SomethingMissingGameAssets/car/wrongCar7.png'
        },
        {
            key: 'wrongCar8',
            url: './SomethingMissingGameAssets/car/wrongCar8.png'
        },
        {
            key: 'wrongCar9',
            url: './SomethingMissingGameAssets/car/wrongCar9.png'
        }]
};

export const submarineAssets = {
    COMPLETE: {
        key: 'completeSubmarine',
        url: './SomethingMissingGameAssets/submarine/completeSubmarine.png'
    },
    WRONG: [
        {
            key: 'wrongSubmarine1',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine1.png'
        },
        {
            key: 'wrongSubmarine2',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine2.png'
        },
        {
            key: 'wrongSubmarine3',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine3.png'
        },
        {
            key: 'wrongSubmarine4',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine4.png'
        },
        {
            key: 'wrongSubmarine5',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine5.png'
        },
        {
            key: 'wrongSubmarine6',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine6.png'
        },
        {
            key: 'wrongSubmarine7',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine7.png'
        },
        {
            key: 'wrongSubmarine8',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine8.png'
        },
        {
            key: 'wrongSubmarine9',
            url: './SomethingMissingGameAssets/submarine/wrongSubmarine9.png'
        }
    ]
};

export const helicopterAssets = {
    COMPLETE: {
        key: 'completeHelicopter',
        url: './SomethingMissingGameAssets/helicopter/completeHelicopter.png'
    },
    WRONG: [
        {
            key: 'wrongHelicopter1',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter1.png'
        },
        {
            key: 'wrongHelicopter2',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter2.png'
        },
        {
            key: 'wrongHelicopter3',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter3.png'
        },
        {
            key: 'wrongHelicopter4',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter4.png'
        },
        {
            key: 'wrongHelicopter5',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter5.png'
        },
        {
            key: 'wrongHelicopter6',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter6.png'
        },
        {
            key: 'wrongHelicopter7',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter7.png'
        },
        {
            key: 'wrongHelicopter8',
            url: './SomethingMissingGameAssets/helicopter/wrongHelicopter8.png'
        }]
};

export const sessionAssets = [
    submarineAssets.COMPLETE,
    ...submarineAssets.WRONG,
    carAssets.COMPLETE,
    ...carAssets.WRONG,
    helicopterAssets.COMPLETE,
    ...helicopterAssets.WRONG,
    planeAssets.COMPLETE,
    ...planeAssets.WRONG,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...FeedbackSettingAssets,
    ...TargetFeedbackAssets,
    ...DifficultySettingAssets,
    ...TimeSettingAssets
];
