import Phaser from "phaser";
import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { AggressiveCoworkerSheets, AggressiveCoworkerAnims } from "../../utils/assetsPaths/situations/work/agressiveCoworker";
import { createBoss } from "../../utils/npcFactory.js/factory";
import { BossAnims } from "../../utils/npcFactory.js/boss";
import { ManWorkIdle, WomanWorkIdle } from "../../utils/assetsPaths/situations/work/boringWork";

export default class AggressiveCoworkerSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 600, y: 184 };
        const mcPosition = { x: 293, y: 575 };
        super(scene, markPosition, mcPosition, Badges.SWORD);
        this.instructionLabel = "Alguien te está gritando, ¿qué haces?";
        this.optionsData = [{
            label: "También le grito.",
            onclick: this.runEvent(2500, () => this.beAggresive())
        }, {
            label: "Me voy de ese lugar.",
            onclick: this.runEvent(2500, () => this.leave())
        }, {
            label: "Aviso a mi jefe.",
            onclick: this.runEvent(2500, () => this.callBoss())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(AggressiveCoworkerAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.coworker = this.scene.add.sprite(0, 0, AggressiveCoworkerSheets.COWORKER_LIFT.key, 0)
            .setScale(this.scene.mc.scale)
            .setPosition(scaleX * 435, scaleY * 415 + y);
        return this;
    }

    reboot(onComplete) {
        this.coworker.setTexture(AggressiveCoworkerSheets.COWORKER_LIFT.key, 0);
        this.scene.follower.emit("onMovementComplete");
        onComplete();
    }

    beAggresive() {
        //
        const anim = this.mcIsBoy ?
            AggressiveCoworkerAnims.MAN_AGGRESIVE : AggressiveCoworkerAnims.WOMAN_AGGRESIVE;
        this.scene.mc.play(anim.key)
            .once("animationcomplete", this.runEvent(2500, () => addDialogTween(this.scene, "Mala desición")))
    }

    leave() {
        const anim = this.mcIsBoy ? AggressiveCoworkerAnims.MAN_REFUSE : AggressiveCoworkerAnims.WOMAN_REFUSE;
        this.scene.mc.play(anim.key)
            .once("animationcomplete", this.runEvent(1500,
                () => this.scene.moveMcTo(170, 875, () => {
                    this.addEvent(2000, () => addDialogTween(this.scene, "Buena desición", this.badge));
                })))
    }

    callBoss() {
        this.boss = createBoss(this.scene, 1103, 994);
        this.boss.sprite.setDepth(this.scene.mc.depth - 1);
        const idle = this.mcIsBoy ? ManWorkIdle.BACK : WomanWorkIdle.BACK;
        this.scene.moveMcTo(365, 775, () => {
            this.scene.tweens.add({
                targets: this.boss.sprite,
                alpha: 1
            })
            this.scene.mc.setTexture(idle.key).setFlipX(true);
            this.boss.follower.goTo(new Phaser.Math.Vector2(293, 575))
                .onceMovementComplete(this.runEvent(1500, () => {
                    this.boss.sprite.play(BossAnims.ANGRY.key)
                        .once("animationcomplete", () => {
                            this.coworker
                                .setTexture(AggressiveCoworkerSheets.COWORKER_ANGRY.key, 0);
                            this.addEvent(2500, () => {
                                addDialogTween(this.scene, "Buena desición", this.badge);
                                this.boss.destroy();
                            })
                        })
                }))
        })
    }

    start() {
        const anims = AggressiveCoworkerAnims;
        const shock = this.mcIsBoy ? anims.MAN_TALK : anims.WOMAN_TALK
        const shockIdle = this.mcIsBoy ?
            AggressiveCoworkerSheets.MAN_SHOCK_ANGRY
            : AggressiveCoworkerSheets.WOMAN_SHOCK_ANGRY
        this.scene.mc.setFlipX(false);
        this.addEvent(2500, () => {
            this.coworker.play(anims.COWORKER_TAKE_BOX.key)
                .once("animationcomplete", () =>
                    this.coworker.play(anims.COWORKER_RETURN_TO_BASE.key)
                        .once("animationcomplete", () => {
                            this.coworker.setTexture(AggressiveCoworkerSheets.COWORKER_ANGRY.key, 0)
                            this.addEvent(2500, () =>
                                this.scene.mc.play(shock.key)
                                    .once("animationcomplete", () => {
                                        this.addEvent(1500, () => this.coworker.play(anims.COWORKER_ANGRY.key)
                                            .once("animationcomplete", () => {
                                                this.scene.mc.setTexture(shockIdle.key, 3);
                                                this.addEvent(2500, () => this.showHint())
                                            }))
                                    })
                            )
                        })
                )
        })

    }

}