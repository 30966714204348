import Phaser from 'phaser';
import { GameAsset, GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
const Text = Phaser.GameObjects.Text;
const Rectangle = Phaser.GameObjects.Rectangle;
const Image = Phaser.GameObjects.Image;

export default class HeaderBar extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, color = 0x2F7BA1) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color);
        this.icon = new Image(scene, 0, 0, GameAsset.THUMBNAIL);
        this.label = new Text(scene, 0, 0, label, HeaderBarTextStyle).setOrigin(0.5);
        this.add([this.background, this.icon, this.label]);
    }

    resize(width, height) {
        let halfHeight = height / 2;
        this.background.setSize(width * 1.1, height);
        scaleImage(this.icon, height, halfHeight, 0, 1);
        this.icon.setPosition(this.icon.displayWidth / 4 + halfHeight, halfHeight);
        this.label.setFontSize(`${height * 0.35}px`)
            .setPosition(width / 2, halfHeight)
            .setWordWrapWidth(width - this.icon.getRightCenter().x * 2)
    }

    alignLabelRightPosition(gameWidth, width) {
        const availableWidth = width - this.icon.getRightCenter().x;
        const rightCenter = this.label.getRightCenter().x;
        const iconCorner = this.icon.getRightCenter();
        if (rightCenter.x < width)
            this.label.setX(gameWidth / 2);
        else if (this.label.getRightCenter().x > width && availableWidth > this.label.displayWidth)
            this.label.setX(width / 2 + iconCorner.x / 2)
        else if (this.label.getRightCenter().x > width && availableWidth < this.label.displayWidth)
            this.label
                .setWordWrapWidth(availableWidth * 0.75)
                .setX(width / 2 + iconCorner.x / 2)
                .setFontSize(`${this.background.displayHeight * 0.2}px`);
    }

    alignLabelLeftPosition(gameWidth) {
        const availableWidth = gameWidth - this.icon.getRightCenter().x;
        const leftCenter = this.label.getLeftCenter();
        const iconRightCenter = this.icon.getRightCenter();
        if (leftCenter.x < iconRightCenter.x && availableWidth > this.label.displayWidth)
            this.label.setX(gameWidth / 2 + iconRightCenter.x / 2);


    }
}

const HeaderBarTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'center'
}