export const IntrudersPhotos = [
    {
        key: 'animalPhoto1',
        url: './photos/animals/animal1.png'
    }, {
        key: 'animalPhoto2',
        url: './photos/animals/animal2.png'
    }, {
        key: 'animalPhoto3',
        url: './photos/animals/animal3.png'
    }, {
        key: 'animalPhoto4',
        url: './photos/animals/animal4.png'
    }, {
        key: 'animalPhoto5',
        url: './photos/animals/animal5.png'
    }, {
        key: 'animalPhoto6',
        url: './photos/animals/animal6.png'
    }, {
        key: 'animalPhoto7',
        url: './photos/animals/animal7.png'
    }, {
        key: 'animalPhoto8',
        url: './photos/animals/animal8.png'
    }, {
        key: 'animalPhoto9',
        url: './photos/animals/animal9.png'
    }, {
        key: 'animalPhoto10',
        url: './photos/animals/animal10.png'
    }, {
        key: 'animalPhoto11',
        url: './photos/animals/animal11.png'
    }, {
        key: 'animalPhoto12',
        url: './photos/animals/animal12.png'
    }, {
        key: 'animalPhoto13',
        url: './photos/animals/animal13.png'
    }, {
        key: 'animalPhoto14',
        url: './photos/animals/animal14.png'
    }, {
        key: 'animalPhoto15',
        url: './photos/animals/animal15.png'
    }, {
        key: 'animalPhoto16',
        url: './photos/animals/animal16.png'
    }, {
        key: 'animalPhoto17',
        url: './photos/animals/animal17.png'
    }, {
        key: 'animalPhoto19',
        url: './photos/animals/animal19.png'
    }, {
        key: 'animalPhoto20',
        url: './photos/animals/animal20.png'
    }, {
        key: 'clothesPhoto1',
        url: './photos/clothes/clothes1.png'
    }, {
        key: 'clothesPhoto5',
        url: './photos/clothes/clothes5.png'
    }, {
        key: 'clothesPhoto9',
        url: './photos/clothes/clothes9.png'
    }, {
        key: 'clothesPhoto22',
        url: './photos/clothes/clothes22.png'
    }, {
        key: 'clothesPhoto24',
        url: './photos/clothes/clothes24.png'
    }, {
        key: 'fruitPhoto1',
        url: './photos/fruits/fruit1.png'
    }, {
        key: 'fruitPhoto2',
        url: './photos/fruits/fruit2.png'
    }, {
        key: 'fruitPhoto3',
        url: './photos/fruits/fruit3.png'
    }, {
        key: 'fruitPhoto4',
        url: './photos/fruits/fruit4.png'
    }, {
        key: 'fruitPhoto5',
        url: './photos/fruits/fruit5.png'
    }, {
        key: 'carbPhoto1',
        url: './photos/carbs/carb1.png'
    }, {
        key: 'carbPhoto2',
        url: './photos/carbs/carb2.png'
    }, {
        key: 'carbPhoto4',
        url: './photos/carbs/carb4.png'
    }, {
        key: 'dairyPhoto1',
        url: './photos/dairies/dairy1.png'
    }, {
        key: 'dairyPhoto3',
        url: './photos/dairies/dairy3.png'
    }, {
        key: 'dairyPhoto5',
        url: './photos/dairies/dairy5.png'
    }, {
        key: 'grainPhoto1',
        url: './photos/grains/grain1.png'
    }, {
        key: 'vegetablePhoto3',
        url: './photos/vegetables/vegetable3.png'
    }, {
        key: 'vegetablePhoto4',
        url: './photos/vegetables/vegetable4.png'
    }, {
        key: 'vegetablePhoto5',
        url: './photos/vegetables/vegetable5.png'
    }, {
        key: 'vehiclePhoto1',
        url: './photos/vehicles/vehicle1.png'
    }, {
        key: 'vehiclePhoto2',
        url: './photos/vehicles/vehicle2.png'
    }, {
        key: 'vehiclePhoto3',
        url: './photos/vehicles/vehicle3.png'
    }, {
        key: 'vehiclePhoto4',
        url: './photos/vehicles/vehicle4.png'
    }, {
        key: 'vehiclePhoto5',
        url: './photos/vehicles/vehicle5.png'
    }, {
        key: 'vehiclePhoto6',
        url: './photos/vehicles/vehicle6.png'
    }, {
        key: 'vehiclePhoto10',
        url: './photos/vehicles/vehicle10.png'
    }, {
        key: 'vehiclePhoto11',
        url: './photos/vehicles/vehicle11.png'
    }, {
        key: 'vehiclePhoto14',
        url: './photos/vehicles/vehicle14.png'
    }, {
        key: 'vehiclePhoto15',
        url: './photos/vehicles/vehicle15.png'
    }, {
        key: 'vehiclePhoto16',
        url: './photos/vehicles/vehicle16.png'
    }, {
        key: 'vehiclePhoto17',
        url: './photos/vehicles/vehicle17.png'
    }, {
        key: 'vehiclePhoto18',
        url: './photos/vehicles/vehicle18.png'
    }
];

export const IntrudersPhotosGroups = [
    [
        {
            key: 'animalPhoto1',
            url: './photos/animals/animal1.png'
        }, {
            key: 'animalPhoto2',
            url: './photos/animals/animal2.png'
        }, {
            key: 'animalPhoto3',
            url: './photos/animals/animal3.png'
        }, {
            key: 'animalPhoto4',
            url: './photos/animals/animal4.png'
        }, {
            key: 'animalPhoto5',
            url: './photos/animals/animal5.png'
        }, {
            key: 'animalPhoto6',
            url: './photos/animals/animal6.png'
        }, {
            key: 'animalPhoto7',
            url: './photos/animals/animal7.png'
        }, {
            key: 'animalPhoto8',
            url: './photos/animals/animal8.png'
        }, {
            key: 'animalPhoto9',
            url: './photos/animals/animal9.png'
        }, {
            key: 'animalPhoto10',
            url: './photos/animals/animal10.png'
        }, {
            key: 'animalPhoto11',
            url: './photos/animals/animal11.png'
        }, {
            key: 'animalPhoto12',
            url: './photos/animals/animal12.png'
        }, {
            key: 'animalPhoto13',
            url: './photos/animals/animal13.png'
        }, {
            key: 'animalPhoto14',
            url: './photos/animals/animal14.png'
        }, {
            key: 'animalPhoto15',
            url: './photos/animals/animal15.png'
        }, {
            key: 'animalPhoto16',
            url: './photos/animals/animal16.png'
        }, {
            key: 'animalPhoto17',
            url: './photos/animals/animal17.png'
        }, {
            key: 'animalPhoto19',
            url: './photos/animals/animal19.png'
        }, {
            key: 'animalPhoto20',
            url: './photos/animals/animal20.png'
        }
    ], [
        {
            key: 'clothesPhoto1',
            url: './photos/clothes/clothes1.png'
        }, {
            key: 'clothesPhoto5',
            url: './photos/clothes/clothes5.png'
        }, {
            key: 'clothesPhoto9',
            url: './photos/clothes/clothes9.png'
        }, {
            key: 'clothesPhoto22',
            url: './photos/clothes/clothes22.png'
        }, {
            key: 'clothesPhoto24',
            url: './photos/clothes/clothes24.png'
        }
    ], [
        {
            key: 'fruitPhoto1',
            url: './photos/fruits/fruit1.png'
        }, {
            key: 'fruitPhoto2',
            url: './photos/fruits/fruit2.png'
        }, {
            key: 'fruitPhoto3',
            url: './photos/fruits/fruit3.png'
        }, {
            key: 'fruitPhoto4',
            url: './photos/fruits/fruit4.png'
        }, {
            key: 'fruitPhoto5',
            url: './photos/fruits/fruit5.png'
        }, {
            key: 'carbPhoto1',
            url: './photos/carbs/carb1.png'
        }, {
            key: 'carbPhoto2',
            url: './photos/carbs/carb2.png'
        }, {
            key: 'carbPhoto4',
            url: './photos/carbs/carb4.png'
        }, {
            key: 'dairyPhoto1',
            url: './photos/dairies/dairy1.png'
        }, {
            key: 'dairyPhoto3',
            url: './photos/dairies/dairy3.png'
        }, {
            key: 'dairyPhoto5',
            url: './photos/dairies/dairy5.png'
        }, {
            key: 'grainPhoto1',
            url: './photos/grains/grain1.png'
        }, {
            key: 'vegetablePhoto3',
            url: './photos/vegetables/vegetable3.png'
        }, {
            key: 'vegetablePhoto4',
            url: './photos/vegetables/vegetable4.png'
        }, {
            key: 'vegetablePhoto5',
            url: './photos/vegetables/vegetable5.png'
        }
    ], [
        {
            key: 'vehiclePhoto1',
            url: './photos/vehicles/vehicle1.png'
        }, {
            key: 'vehiclePhoto2',
            url: './photos/vehicles/vehicle2.png'
        }, {
            key: 'vehiclePhoto3',
            url: './photos/vehicles/vehicle3.png'
        }, {
            key: 'vehiclePhoto4',
            url: './photos/vehicles/vehicle4.png'
        }, {
            key: 'vehiclePhoto5',
            url: './photos/vehicles/vehicle5.png'
        }, {
            key: 'vehiclePhoto6',
            url: './photos/vehicles/vehicle6.png'
        }, {
            key: 'vehiclePhoto10',
            url: './photos/vehicles/vehicle10.png'
        }, {
            key: 'vehiclePhoto11',
            url: './photos/vehicles/vehicle11.png'
        }, {
            key: 'vehiclePhoto14',
            url: './photos/vehicles/vehicle14.png'
        }, {
            key: 'vehiclePhoto15',
            url: './photos/vehicles/vehicle15.png'
        }, {
            key: 'vehiclePhoto16',
            url: './photos/vehicles/vehicle16.png'
        }, {
            key: 'vehiclePhoto17',
            url: './photos/vehicles/vehicle17.png'
        }, {
            key: 'vehiclePhoto18',
            url: './photos/vehicles/vehicle18.png'
        }
    ]

];