import Phaser from 'phaser'
export class RoundedCamera extends Phaser.Cameras.Scene2D.Camera {
    constructor(scene, x, y, size, zoom) {
        super(x, y, size, size)
            .setZoom(zoom)
            .setScene(scene)
            .setBackgroundColor(0x2d2d2d);
    }

    relocate(pointer) {
        let { width, height } = this.scene.game.canvas;
        let zoomScale = Math.pow(this.zoom, 2)
        let deadWidth = width - this.displayWidth * zoomScale
        let deadHeight = height - this.displayHeight * zoomScale
        let x = pointer.x > deadWidth ? deadWidth : pointer.x;
        let y = pointer.y > deadHeight ? deadHeight : pointer.y;
        this.setPosition(x + 1, y + 1).centerOn(pointer.x, pointer.y);
    }
}