import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import {
    createBackgroundImage,
    createGameIcon,
    createGameLogo,
    createMessageBox,
    createAccesibilityBtns,
    createUserTag,
    createZoomCamera,
    setZoomBtnOnclick
} from '../utils/gameObjectFactory';
import { wrapResizeFn, scaleImage } from '../utils/resize';
import Button from '../gameObjects/button';
import { Icons } from '../utils/assetsPaths/icons';

export default class MainMenuScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.MAIN_MENU });
    }

    create() {
        const sessionData = this.game.data.sessionData;
        createZoomCamera(this);
        createBackgroundImage(this);
        createGameLogo(this);
        createGameIcon(this);
        createMessageBox(this, 'Selecciona una opción');
        createAccesibilityBtns(this);
        createUserTag(this, sessionData);
        this.createBtns();
        this.setBtnsInteractive();
        this.speakAtStart();
        wrapResizeFn(this);
    }

    createBtns() {
        this.playBtn = new Button(this, 0, 0, 'Jugar', Icons.PLAY);
        this.settingsBtn = new Button(this, 0, 0, 'Ajustes', Icons.SETTINGS);
        this.instructionsBtn = new Button(this, 0, 0, 'Instrucciones', Icons.INSTRUCTIONS);
        this.exitBtn = new Button(this, 0, 0, 'Salir', Icons.EXIT);
        this.btns = [this.playBtn, this.settingsBtn, this.instructionsBtn, this.exitBtn];
        this.btns.forEach(btn => this.add.existing(btn));
    }
    setBtnsInteractive() {
        this.btns.forEach(btn => btn.setInteractive());
        this.playBtn.onclick(() => this.scene.start(SceneKey.SESSION_HINT));
        this.settingsBtn.onclick(() => this.scene.start(SceneKey.SETTINGS_MENU));
        this.instructionsBtn.onclick(() => this.scene.start(SceneKey.INSTRUCTIONS));
        this.exitBtn.onclick(() => window.history.back());
        this.voiceOverBtn.onclick(() => this.game.voiceAssistant.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
        this.events.once('shutdown', () => this.game.voiceAssistant.cancel());
    }

    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voiceAssistant.speak(this.helpText);
        setTimeout(resumeInfinity, 5000)
    }
    stopVoice() {
        this.game.voiceAssistant.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }

    resizeLandscape(width, height) {
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        const eightHeight = height / 8;
        const { icon, background, userTag, msg, accesibilityBtns, btns } = this;
        background.setDisplaySize(width, height);
        scaleImage(icon, halfWidth, height * 0.6, 50);
        btns.forEach(btn => btn.resize(halfWidth, eightHeight, 50, 1));
        accesibilityBtns.forEach(btn => btn.resize(halfWidth / 4, eightHeight, 50, 1))
        msg.resize(halfWidth, eightHeight, 50, 1);
        userTag.resize(width / 3, icon.getTopCenter().y, 25, 1);
        this.setLandscapePositions(width, height, halfWidth, halfHeight);
    }

    setLandscapePositions(width, height, halfWidth, halfHeight) {
        const quarterWidth = width / 4;
        const { logo, icon, accesibilityBtns, msg, btns, userTag } = this;
        const cellHeight = btns[0].background.displayHeight * 1.25;
        const cellWidth = accesibilityBtns[0].background.displayWidth * 1.25;
        const position = Phaser.Display.Align.CENTER;
        logo.setPosition(quarterWidth, halfHeight);
        icon.setPosition(width * 0.75, halfHeight);
        userTag.setPosition(width - userTag.background.displayWidth / 2 - 100, icon.getTopCenter().y / 2);

        Phaser.Actions.GridAlign([msg, ...btns], {
            height: -1, cellHeight, position,
            x: quarterWidth,
            y: halfHeight - cellHeight
        })
        scaleImage(logo, halfWidth, msg.y, 200);
        logo.setPosition(quarterWidth, msg.y / 2);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })
    }

    resizePortrait(width, height) {
        const halfWidth = width / 2;
        const eightHeight = height / 8;
        const { background, userTag, btns, accesibilityBtns, msg } = this;
        background.setDisplaySize(100, height).setScale(background.scaleY, background.scaleY);
        userTag.resize(width, height / 10, 25, 1);
        msg.resize(width, eightHeight, 100, 1);
        btns.forEach(btn => btn.resize(width, eightHeight, 100, 1));
        accesibilityBtns.forEach(btn => btn.resize(halfWidth, eightHeight, 100, 1));
        this.setPortraitPositions(width, height, halfWidth);
    }

    setPortraitPositions(width, height, halfWidth) {
        const { logo, icon, userTag, btns, accesibilityBtns, msg } = this;
        const cellHeight = btns[0].background.displayHeight * 1.2;
        const position = Phaser.Display.Align.CENTER;
        const cellWidth = accesibilityBtns[0].background.displayWidth * 1.5;

        Phaser.Actions.GridAlign([msg, ...btns], {
            height: -1, cellHeight, position,
            x: halfWidth, y: height - (btns.length + 1) * cellHeight
        })

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            x: halfWidth - cellWidth / 2,
            y: msg.y - msg.background.displayHeight / 2 - accesibilityBtns[0].background.displayHeight
        })

        userTag.setPosition(halfWidth, userTag.background.displayHeight * 0.75);
        const availableHeight = accesibilityBtns[0].getTopCenter().y - userTag.getBottomCenter().y;
        scaleImage(icon, width, availableHeight / 2, 100, 1);
        scaleImage(logo, width, availableHeight / 2, 100, 1);
        logo.setPosition(halfWidth, userTag.getBottomCenter().y + logo.displayHeight * 0.55);
        icon.setPosition(halfWidth, (accesibilityBtns[0].getTopCenter().y + logo.getBottomCenter().y) / 2)
    }
}

const SceneInstruction = "Estás en el menú principal del juego, aquí puedes acceder a distintas secciones del juego " +
    "presionando los siguientes botones: El botón jugar, para iniciar una sesión de juego. " +
    "El botón ajustes, para ir al menú de ajustes y configurar varios parametros del juego como la dificultad " +
    "y el tiempo de juego. " +
    "El botón instrucciones, para visualizar las indicaciones generales y reglas del juego." +
    "El botón salir, para abandonar el juego. "
const resumeInfinity = () => {
    window.speechSynthesis.resume();
    // eslint-disable-next-line
    const timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
}
