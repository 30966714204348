import Phaser from "phaser";
import { SceneKey } from "../utils/constants";
import { createZoomCamera, createBackgroundImage, createAccesibilityBtns, createBtn, createMenuBar, createUserTag, setZoomBtnOnclick, createBadgeContainer } from "../utils/factory";
import { Icons } from "../utils/assetsPaths/icons";
import { Btns } from "../utils/assetsPaths/btn";

export default class BadgeScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.BADGES })
    }

    init(data) {
        this.targetScene = data.key;
    }


    create() {
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        createBadgeContainer(this);
        this.returnBtn = createBtn(this, "Continuar", Icons.RETURN, Btns.SECONDARY);
        createMenuBar(this, "Insignias");
        createUserTag(this, this.game.sessionData);
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale);
        this.events.on("shutdown", () => this.scale.off("resize", scale));
        this.setBtnsInteractive();
        this.speakAtStart();
        this.events.once("shutdown", () => this.game.voice.cancel());
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voice.speak(this.helpText);
    }

    stopVoice() {
        this.game.voice.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }

    setBtnsInteractive() {
        this.game.voice.speak(SceneInstruction);
        this.returnBtn.setInteractive();
        this.returnBtn.onclick(() => this.scene.start(this.targetScene));
        this.voiceOverBtn.onclick(() => this.game.voice.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
    }

    resize() {
        const { width, height } = this.game.canvas;
        const headerHeight = height / 7;
        const { background, accesibilityBtns, returnBtn, header, userTag } = this;
        background.setDisplaySize(width, height);
        returnBtn.resize(width / 2, height / 8, 25, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 2, height / 8, 25, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 25, 1);
        this.setElementsPositions(headerHeight);

    }

    setElementsPositions(headerHeight) {
        const { width, height } = this.game.canvas;
        const { accesibilityBtns, returnBtn, userTag, header, badgeContainer } = this;
        const position = Phaser.Display.Align.CENTER;
        userTag.setPosition(width - userTag.background.displayWidth / 2 * 1.1, headerHeight / 2);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })
        returnBtn.setPosition(width / 2, accesibilityBtns[0].y);
        header.alignLabel(userTag.getLeftCenter().x);

        badgeContainer.setPosition(width / 2, (returnBtn.getTopCenter().y + header.getHeight()) / 2)
            .resize(width, (returnBtn.getTopCenter().y - header.getHeight()), 25, 1)

    }
}

const SceneInstruction = "Mira todas las insignias que puedes ganar en cada mundo.";