export const wrapVect2 = (gameObject) => {
    gameObject.getTopCenter = getTopCenter(gameObject);
    gameObject.getBottomCenter = getBottomCenter(gameObject);
    gameObject.getLeftCenter = getLeftCenter(gameObject);
    gameObject.getRightCenter = getRightCenter(gameObject);
    gameObject.getTopLeft = getTopLeft(gameObject);
    gameObject.getTopRight = getTopRight(gameObject);
    gameObject.getBottomLeft = getBottomLeft(gameObject);
    gameObject.getBottomRight = getBottomRight(gameObject);
}

const getTopCenter = (gameObject) =>
    getVerticalCenter(false, gameObject);

const getBottomCenter = (gameObject) =>
    getVerticalCenter(true, gameObject);

const getVerticalCenter = (isBottom, gameObject) => () => ({
    x: gameObject.x,
    y: gameObject.y + gameObject.background.displayHeight / 2 * (isBottom ? 1 : -1)
})

const getRightCenter = (gameObject) =>
    getHorizontalCenter(true, gameObject)
const getLeftCenter = (gameObject) =>
    getHorizontalCenter(false, gameObject)

const getHorizontalCenter = (isRight, gameObject) => () => ({
    x: gameObject.x + (gameObject.background.displayWidth / 2 * (isRight ? 1 : -1)),
    y: gameObject.y
})

const getTopLeft = (gameObject) =>
    getCorner(false, false, gameObject)
const getTopRight = (gameObject) =>
    getCorner(true, false, gameObject)

const getBottomLeft = (gameObject) =>
    getCorner(false, true, gameObject)
const getBottomRight = (gameObject) =>
    getCorner(true, true, gameObject)

const getCorner = (isRight, isBottom, gameObject) => () => ({
    x: gameObject.x + (gameObject.background.displayWidth / 2 * (isRight ? 1 : -1)),
    y: gameObject.y + (gameObject.background.displayHeight / 2 * (isBottom ? 1 : -1))
})