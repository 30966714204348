import {optionsData, quantitySettingHeader} from "./quantitySetting";

export const buildQuantitySetting = (quantityList) => {
    let setting = duplicateObject(quantityList);
    let options = [];
    Object.entries(quantitySettingHeader).forEach(entry => setting[entry[0]] = entry[1]);
    quantityList.forEach(quantity => options.push(optionsData[quantity]));
    setting.options = options;
    return setting;
};

export const attachChildSettings = (setting, children) => {
    let composedSetting = {};
    Object.entries(setting).forEach(entry => composedSetting[entry[0]] = entry[1]);
    composedSetting.hasChild = true;
    composedSetting.options.forEach((option, index) => option.child = children[index]);
    return composedSetting;
};

const duplicateObject = (setting) => {
    let duplicatedSetting = {};
    Object.entries(setting).forEach(entry=>duplicatedSetting[entry[0]] = entry[1]);
    return duplicatedSetting;
};