export const listComponentStructure = {
    listTitle: {
        text: '',
        style: {
            fontSize: '32px',
            fontFamily: 'arial rounded',
            color: '#feff00',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 10,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 65,
            objectPositionX: 0,
            objectPositionY: -281,
        }
    },
    listImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 305,
            objectHeight: 625,
            objectPositionX: 0,
            objectPositionY: 0,
        }
    },
    listDescription:
    {
        text: '',
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'left',
            stroke: '#205067',
            strokeThickness: 0.5,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 150,
            objectPositionX: 0,
            objectPositionY: 240,
        }

    },
    listButton:
    {
        text: '',
        nameAsset: 'playIcon',
        style: {
            fontSize: '20px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 70,
            objectHeight: 90,
            objectPositionX: 100,
            objectPositionY: 0,
        }
    },
    listDificult: {
        text: '',
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 0.5,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 160,
            objectHeight: 30,
            objectPositionX: -70,
            objectPositionY: -240,
        }
    },
    listLamines: {
        text: '',
        style: {
            fontSize: '22px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 0.5,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 160,
            objectHeight: 30,
            objectPositionX: 95,
            objectPositionY: -240,
        }
    },
    miselaneus: {
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 35,
            objectPositionX: 0,
            objectPositionY: -240,
        }

    }
}