import Phaser from 'phaser';
import { SceneKey, CustomGameFont } from '../utils/constants';
import { SplashAssets } from '../utils/assetsPaths/splash';
import RoundPipeline from '../../HabCogGame/pipelines/roundPipeline';
import GrayScalePipeline from '../../HabCogGame/pipelines/grayTextureTint';

class BootScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.BOOT });
    }

    preload() {
        SplashAssets.forEach(({ key, url }) => this.load.image(key, url));
        this.game.roundPipeline = this.game.renderer.addPipeline('Roundzoom', new RoundPipeline(this.game));
        this.game.grayPipeline = this.game.renderer.addPipeline("grayKey", new GrayScalePipeline(this.game));
        this.arialRoundedFont = new window
            .FontFace(CustomGameFont, `url(/SocialInclusionAssets/fonts/ArialRoundedMT.ttf)`);
        this.arialRoundedFont.load()
            .then(loadedFont => document.fonts.add(loadedFont))
            .catch((e) => console.log('error al cargar la fuente', e));
        this.load.on('complete', () => this.scene.start(SceneKey.SPLASH));
    }
}



export default BootScene;