import Phaser from 'phaser'
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { sortedGeomTextures, Cursors } from "../../HabCogGame/utils/constants";
import { PlotShapeAssets, SerpentineAsset } from "../utils/assets";
import { getRandomElement } from "../../HabCogGame/utils/functions";
import { PlotSettingKey } from '../utils/settings';

const Image = Phaser.GameObjects.Image;
const Rectangle = Phaser.Geom.Rectangle;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { userConfig: { difficulty, size } } = this.game;
        const speed = [50, 150, 300, 600][difficulty];
        const scale = [0.25, 0.50, 0.75, 1][size];
        this.gameArea = this.add.container(0, this.hubHeight);
        this.enableFailOnMiss();
        this.isPhysicsActive = true;
        this.sTexture = this.getSessionTexture();
        this.startGameplay(speed, scale);
    }

    getSessionTexture() {
        const { userConfig: { plot, shape, color } } = this.game;
        if (plot === PlotSettingKey.PLOT)
            return PlotShapeAssets[shape];
        const textures = Object.values(sortedGeomTextures.byColor);
        return textures[color][shape];
    }

    showSerpentine() {
        const { width, height } = this.game.canvas;
        const { key } = SerpentineAsset;
        const img = new Image(this, 0, 0, key)
            .setDisplaySize(width, 100)
            .setOrigin(0, 1)
        img.setScale(img.scaleX, img.scaleX);
        this.audioProvider.BOUNCE_SONG.play();
        this.tweens.add({
            targets: img,
            ease: 'Power1',
            y: { from: 0, to: height + img.displayHeight },
            onStart: () => this.gameArea.add(img),
            onComplete: () => img.destroy(),
            duration: 4000
        })
    }

    addHit() {
        super.addHit();
        const { hits } = this.userScore;
        if (hits % 10 === 0) this.showSerpentine();
    }

    tooglePhysics() {
        this.isPhysicsActive = false;
        this.addHit();
        this.physics.pause();
        this.time.addEvent({
            delay: 1000, callbackScope: this, callback: () => {
                this.physics.resume();
                this.isPhysicsActive = true;
            }
        });
    }

    enableTargetRotation(target) {
        const shouldTargetRotate = this.game.userConfig.shape % 2 === 0;
        const angleIncrement = shouldTargetRotate ? 90 : 2;
        const cb = () => {
            if (this.isPhysicsActive)
                target.setAngle(target.angle + angleIncrement)
        }
        this.time.addEvent({
            loop: true,
            delay: shouldTargetRotate ? 250 : 1,
            callback: cb
        })
    }

    playBounceSound() {
        this.audioProvider.BOING.stop();
        this.audioProvider.BOING.play();
    }

    startGameplay(speed, scale) {
        const { width, height } = this.getAvailableSize();
        const { key } = this.sTexture;
        const xVelocity = getRandomElement([speed, -speed]);
        const yVelocity = getRandomElement([speed, -speed]);
        const size = height / 2 * scale;
        const hubBounds = new Rectangle(0, this.gameArea.y, width, height);
        const click = () => this.isPhysicsActive ? this.tooglePhysics() : 0;
        const createImage = (...args) => this.physics.add.image(...args);
        const walls = createWalls(createImage, width, height, this.gameArea.y);
        const target = this.physics.add.image(width / 2, height / 2, key)
            .setBounce(1, 1)
            .setVelocity(xVelocity, yVelocity)
            .setCollideWorldBounds(true, 1, 1)
            .setDisplaySize(size, size)
            .setInteractive(Cursors.pointerover)
            .on('pointerdown', click);
        target.body.setBoundsRectangle(hubBounds);
        this.physics.add.overlap(target, walls, () => this.playBounceSound());
        if (this.game.userConfig.plot === 1)
            this.enableTargetRotation(target);
    }
}

const createWalls = (createImage, width, height, hubHeight) => {
    const hSize = { width, height: 1 };
    const vSize = { width: 1, height };
    const positions = [
        { x: 0, y: hubHeight, origin: { x: 0, y: 0 }, size: hSize },
        { x: 0, y: height + hubHeight, origin: { x: 0, y: 1 }, size: hSize },
        { x: 0, y: hubHeight, origin: { x: 0, y: 0 }, size: vSize },
        { x: width, y: hubHeight, origin: { x: 1, y: 0 }, size: vSize }
    ];
    return positions.map(({ x, y, origin, size: { width, height } }) =>
        createImage(x, y, '')
            .setOrigin(origin.x, origin.y)
            .setDisplaySize(width, height)
            .setVisible(false))
};
