import Phaser from 'phaser';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';

import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
import { smallCardComponentStructure } from '../../GameObjects/SmallCardComponent/SmallCardComponentStructure';
import { Vocabulary, InstructionText, RigthButtonStructure, LeftButtonStructure } from './VocabularyStructure';
import smallCardComponentComponent from '../../GameObjects/SmallCardComponent/SmallCardComponent';
import InstructionTextComponent from '../../GameObjects/InstructionTextComponent/InstructionTextComponent';
import vocabulary from '../../Services/vocabulary'
import { rotationAnimation } from '../../Functions/AnimatedFunctions';
import { carouselLogic } from '../../Functions/carouselFunctions';


export default class VocabularyScene extends Phaser.Scene {
    constructor() {
        super('Vocabulary');
    }
    init(data) {
        this.historyID = data.historyid;
        this.historyName = data.historyname;
        this.historyDescription = data.historydescription
    }

    preload() {

        this.cardScale = smallCardComponentStructure
        this.scaleOld = this.clone(Vocabulary)
        this.InstructionTextScale = this.clone(InstructionText)
        this.rigthButtonScale = this.clone(RigthButtonStructure)
        this.leftButtonScale = this.clone(LeftButtonStructure)

        //load vocabulary Service
        this.load.json('Vocabulary' + this.historyID, vocabulary.getWords(this.historyID))
        this.vocabulary = []
        this.load.on('filecomplete-json-Vocabulary' + this.historyID, (key, type, data) => {
            for (var i = 0; i < data.length; i++) {
                this.vocabulary.push(data[i])
                this.load.image('vocabulary' + data[i].id, data[i].image);
            }
        }
        )
        //loading image
        var spiner = new Phaser.GameObjects.Image(this, this.sys.canvas.width / 2, this.sys.canvas.height / 1.5, 'spiner')
        spiner.setDisplaySize(this.sys.canvas.height / 3, this.sys.canvas.height / 3)
        spiner.setOrigin(0.5)
        rotationAnimation(this, spiner)
        this.load.on('progress', () => {
            this.add.existing(spiner)
        }).on('complete', function () {
            spiner.destroy()
        });
        //Resize functions
        this.saveScaleState()

        //Scale this scene
        this.scaleOld.scale = scaleGameObject(this, this.scaleOld.scale)
        this.InstructionTextScale = scaleGameObject(this, this.InstructionTextScale)
        this.leftButtonScale = scaleGameObject(this, this.leftButtonScale)
        this.rigthButtonScale = scaleGameObject(this, this.rigthButtonScale)
        //Scale cardComponent
        this.cardScale.cardTitle.scale = scaleGameObject(this, this.cardScale.cardTitle.scale)
        this.cardScale.cardTitle.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardTitle.style.fontSize)
        this.cardScale.cardImage.scale = scaleGameObject(this, this.cardScale.cardImage.scale)
        this.cardScale.cardDescription.scale = scaleGameObject(this, this.cardScale.cardDescription.scale)
        this.cardScale.cardDescription.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardDescription.style.fontSize)
        this.cardScale.cardButton.scale = scaleGameObject(this, this.cardScale.cardButton.scale)
        //background Preload
        this.drawPreloadBackground()
    }
    create() {
        this.vocabulary = this.cache.json.get('Vocabulary' + this.historyID);
        if (this.vocabulary === undefined) {
            this.vocabulary = []
        }

        // Card container variables
        const originX = this.scaleOld.scale.objectPositionX
        const originY = this.scaleOld.scale.objectPositionY
        const width = this.scaleOld.scale.objectWidth
        const height = this.scaleOld.scale.objectHeight

        //Card element builder
        var cardComponentArray = []
        for (var i = 0; i < this.vocabulary.length; i++) {
            this.scaleOld.scale.objectPositionX = (originX + (width * (i % 4))) * 1.2
            const vocabulary = new smallCardComponentComponent(this,
                this.scaleOld.scale.objectPositionX,
                this.scaleOld.scale.objectPositionY,
                width,
                height,
                this.vocabulary[i].name,
                'vocabulary' + this.vocabulary[i].id,
                this.vocabulary[i].word_type,
                this.vocabulary[i].id);
            vocabulary.setName(i)
            cardComponentArray.push(vocabulary)
        }
        if (this.vocabulary.length === 0) {
            var infoCard = this.add.image(originX, originY, 'warningVocabulary').setOrigin(0, 0.5)
            infoCard.setDisplaySize(width * 4, height)
        }

        for (i = 0; i < cardComponentArray.length; i++) {
            this.add.existing(cardComponentArray[i])
            if (i > 3) {
                cardComponentArray[i].setVisible(false)
            }

        }
        var rigthButton = this.add.image(this.rigthButtonScale.objectPositionX, this.rigthButtonScale.objectPositionY, 'TriangleButton')
        rigthButton.setDisplaySize(this.rigthButtonScale.objectWidth, this.rigthButtonScale.objectHeight)
        var leftButton = this.add.image(this.leftButtonScale.objectPositionX, this.leftButtonScale.objectPositionY, 'TriangleButtonInv')
        leftButton.setDisplaySize(this.leftButtonScale.objectWidth, this.leftButtonScale.objectHeight)
        leftButton.setAlpha(0.4)
        //Carousel logic
        carouselLogic(cardComponentArray, rigthButton, leftButton, this)


        this.loadOldScale()
    }

    drawPreloadBackground() {
        this.background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / this.background.width
        let scaleY = this.sys.canvas.height / this.background.height
        this.background.setScale(scaleX, scaleY).setScrollFactor(0)
        scaleX = this.sys.canvas.width / 1920
        scaleY = this.sys.canvas.height / 1080

        //Top Bar Component
        var bar = new topBar(this, 0, 0, this.sys.canvas.width, 400, this.historyName, 'Stories',
            this.historyDescription +
            '\nAntes de continuar al juego es necesario que se conozcan algunas palabras necesarias. ' +
            'Selecciona cada una de ellas para escuchar como se las nombran')
        this.add.existing(bar)

        //Draw Instructions componet
        var instructions = new InstructionTextComponent(this, this.InstructionTextScale.objectPositionX,
            this.InstructionTextScale.objectPositionY,
            this.InstructionTextScale.objectWidth
            , this.InstructionTextScale.objectHeight,
            this.historyDescription
            ,
            'ludoIcon', this.historyID, this.undefined, this.historyName, 'Aprende nuevas palabras'
        )
        this.add.existing(instructions)

    }

    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    loadOldScale() {
        this.cardScale.cardTitle = this.cardTitle
        this.cardScale.cardImage = this.cardImage
        this.cardScale.cardDescription = this.cardDescription
        this.cardScale.cardButton = this.cardButton

    }
    saveScaleState() {
        //save scale state
        this.cardTitle = this.clone(this.cardScale.cardTitle)
        this.cardImage = this.clone(this.cardScale.cardImage)
        this.cardDescription = this.clone(this.cardScale.cardDescription)
        this.cardButton = this.clone(this.cardScale.cardButton)

    }

}