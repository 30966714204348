import React from "react";
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import difficultySetting from "./utils/settings";
import {sizeSetting} from "../HabCogGame/utils/settingsConstants/sizeSetting";
import {buildQuantitySetting} from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import {sessionAssets} from "./utils/assets";
import timeSetting from "../HabCogGame/utils/settingsConstants/timeSetting";

const data = {
    name: 'Algo falta',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './SomethingMissingGameAssets/logo.png',
    iconUrl: './SomethingMissingGameAssets/icon.png',
    thumbnail: './SomethingMissingGameAssets/thumbnail.png',
    getHint: ()=>'Toca la fotografía a la que le falta una parte.',
    userManual: 'Se presenta un conjunto de fotografías que muestran la misma imagen ' +
        'pero a una de ellas le falta alguna parte. El usuario deberá seleccionar la ' +
        'imagen incompleta. La configuración del juego permite establecer: tipo de dificultad, tamaño, número de ' +
        'estímulos ',
    settings: [
        difficultySetting,
        sizeSetting,
        buildQuantitySetting([3,4,5,6,9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function SomethingMissingGame(props){
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}