export function buttonPressAnimation(scene, target) {
    scene.tweens.add({
        targets: target,
        scaleX: {
            from: 0.9, to: target.scaleX
        },
        scaleY: {
            from: 0.9, to: target.scaleY
        },
        ease: 'Sine.easeInOut',
        duration: 150,
        delay: 0,
        repeat: 0,
    });

}
export function mouseOverAnimation(scene, target, scale) {
    scene.tweens.add({
        targets: target,
        scale: scale,
        ease: 'Power3',
        duration: 150,
        delay: 0,
        repeat: 0,
    });
}
export function mouseOverAnimatioArrows(scene, target, scaleX, scaleY) {
    scene.tweens.add({
        targets: target,
        scaleX: scaleX,
        scaleY: scaleY,
        ease: 'Power3',
        duration: 150,
        delay: 0,
        repeat: 0,
    });
}
export function modalMovementAnimation(scene, target, finalX, finalY) {
    scene.tweens.add({
        targets: target,
        x: finalX,
        y: finalY,
        ease: 'Sine.easeInOut',
        duration: 2000,
        delay: 0,
        repeat: 0,
    })

}

export function scaleAnimation(scene, target, beginningScaleX, beginningScaleY, finalScaleX, finalScaleY, beningAlpha, finalAlpha) {
    scene.tweens.add({
        targets: target,
        scaleX: { from: beginningScaleX, to: finalScaleX },
        scaleY: { from: beginningScaleY, to: finalScaleY },
        ease: 'Sine.easeInOut',
        duration: 400,
        delay: 0,
        repeat: 0,
        alpha: {
            from: beningAlpha,
            to: finalAlpha
        },
    });
}
export function rotationAnimation(scene, target) {
    scene.tweens.add({
        targets: target,
        angle: {
            from: 0, to: 360
        },
        ease: 'Sine.easeInOut',
        duration: 1000,
        delay: 0,
        repeat: -1,
    });
}