import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { carAssets, helicopterAssets, planeAssets, submarineAssets } from "../utils/assets";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { createRandomNumericArray } from '../../HabCogGame/utils/imgSelection';
import { DifficultySettingKey } from "../utils/settings";
const { GOOD_TARGET } = TargetFeedback;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.sTextures = this.setSessionTextures(difficulty);
        this.createImgsList(quantity, size, this.sTextures.COMPLETE, false);
        this.setFailFn(feedback);
        this.fillTargetIndexes();
        this.fillTextureIndexes();
        this.startGameplay();
    }

    setSessionTextures(difficulty) {
        switch (difficulty) {
            case DifficultySettingKey.HELICOPTER:
                return helicopterAssets;
            case DifficultySettingKey.CAR:
                return carAssets
            case DifficultySettingKey.SUBMARINE:
                return submarineAssets;
            default:
                return planeAssets;
        }
    }

    getNextTarget() {
        let index = this.targetIndexes.pop();
        if (index === undefined) {
            this.fillTargetIndexes();
            index = this.targetIndexes.pop();
        }
        return this.imgList[index];
    }

    getNextTexture() {
        let index = this.textureIndexes.pop();
        if (index !== undefined) {
            this.fillTextureIndexes();
            index = this.textureIndexes.pop();
        }
        return this.sTextures.WRONG[index];
    }

    fillTargetIndexes() {
        const length = this.imgList.length;
        this.targetIndexes = createRandomNumericArray(length);
    }

    fillTextureIndexes() {
        const length = this.sTextures.WRONG.length;
        this.textureIndexes = createRandomNumericArray(length);
    }

    setTargetsTexture() {
        const hitTexture = this.getNextTexture();
        const getTexture = () => this.sTextures.COMPLETE;
        this.imgList.forEach(img => img.setTexture(getTexture().key));
        return this.getNextTarget().setTexture(hitTexture.key);
    }

    setTargetsInteractive(hitTarget) {
        this.imgList.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTarget.off('pointerdown')
            .once('pointerdown', () => this.addHit(hitTarget))
    }

    addHit(img) {
        super.addHit();
        this.targetsHitted++;
        this.createFeedbackImage(img, GOOD_TARGET);
        this.restartGameplay();
    }

    restartGameplay() {
        this.imgList.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }
    startGameplay() {
        this.feedbackImgs = [];
        const hitTarget = this.setTargetsTexture();
        this.setTargetsInteractive(hitTarget);
    }
}