import Phaser from 'phaser';
import { GameAsset, GameFont } from '../utils/constants';
import { createAccesibilityBtns, createUserTag } from '../utils/gameObjectFactory';
import HubButton from './hubButton';
import { wrapVect2 } from './vect2Wrapper';
import { scaleImage } from '../utils/resize';
const Text = Phaser.GameObjects.Text;
const Rectangle = Phaser.GameObjects.Rectangle;
const Image = Phaser.GameObjects.Image;

export default class Hub extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, sessionData, color = 0x2F7BA1) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color);
        this.accesibilityBtns = createAccesibilityBtns(scene);
        this.userTag = createUserTag(scene, sessionData);
        this.quitBtn = new HubButton(scene, 0, 0);
        this.quitBtn.setInteractive();
        wrapVect2(this.quitBtn);
        this.icon = new Image(scene, 0, 0, GameAsset.THUMBNAIL);
        this.label = new Text(scene, 0, 0, label, HubTextStyle).setOrigin(0.5);
        this.add([this.background, this.icon, this.label,
        ...this.accesibilityBtns,
        this.userTag,
        this.quitBtn
        ]);
    }

    updateText(text) {
        this.label.setText(text);
    }

    onExit(callback = Phaser.Utils.NOOP) {
        this.quitBtn.onclick(callback);
    }

    resizeLandscape(width, height) {
        const halfHeight = height / 2;
        const btnWidth = width / 25;
        this.background.setSize(width * 1.1, height);
        scaleImage(this.icon, height, halfHeight, 0, 1);
        this.icon.setPosition(this.icon.displayWidth / 4 + halfHeight, halfHeight);
        this.quitBtn.resize(width, height, 50, 1);
        this.quitBtn.setPosition(width - this.quitBtn.background.displayWidth, halfHeight);
        this.userTag.resize(width / 3, height, 75, 1);
        this.accesibilityBtns.forEach(btn => btn.resize(btnWidth, halfHeight, 0, 1));
        const cellWidth = this.accesibilityBtns[0].background.displayWidth * 1.2;
        Phaser.Actions.GridAlign(this.accesibilityBtns, {
            width: -1, position: Phaser.Display.Align.CENTER, cellWidth,
            x: this.icon.getRightCenter().x + cellWidth * 3 / 4,
            y: halfHeight
        })
        const x = this.quitBtn.getLeftCenter().x - this.userTag.background.displayWidth / 2 - height / 8;
        this.userTag.setPosition(x, halfHeight);
        const rightCenterX = this.accesibilityBtns[1].getRightCenter().x;
        this.label.setFontSize(`${height * 0.2}px`)
            .setPosition((this.userTag.getLeftCenter().x + rightCenterX) / 2, halfHeight)
            .setWordWrapWidth(this.userTag.getLeftCenter().x - rightCenterX * 2 - height / 6);
        if (this.label.displayHeight > height)
            this.label.setFontSize(`${height * 0.15}px`);
    }

    resizePortrait(width, height) {
        const halfHeight = height / 2;
        const quarterHeight = height / 4;
        this.background.setSize(width * 1.1, height);
        scaleImage(this.icon, halfHeight, quarterHeight, 0, 1);
        this.icon.setPosition(this.icon.displayWidth / 4 + quarterHeight, quarterHeight);
        this.quitBtn.resize(width, height / 3, 50, 1);
        this.quitBtn.setPosition(width - this.quitBtn.background.displayWidth, quarterHeight);
        this.userTag.setPosition(-2000, -2000);
        const btnWidth = (this.quitBtn.getLeftCenter().x - this.icon.getRightCenter().y) / 3
        this.accesibilityBtns.forEach(btn => btn.resize(btnWidth, quarterHeight, 0, 1));
        const cellWidth = this.accesibilityBtns[0].background.displayWidth * 1.2;
        Phaser.Actions.GridAlign(this.accesibilityBtns, {
            width: -1, cellWidth,
            x: width / 2 - cellWidth / 2,
            y: quarterHeight,
            position: Phaser.Display.Align.CENTER
        })
        this.label.setFontSize(`${height * 0.1}px`)
            .setPosition(width / 2, height * 3 / 4)
            .setWordWrapWidth(width * 0.9);
        if (this.label.displayHeight > halfHeight)
            this.label.setFontSize(`${height * 0.075}px`);
    }
}

const HubTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'justify'
}