import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const FeedbackSettingKey = {
    ON: 0,
    OFF: 1
}

const feedbackSetting = {
    name: 'Errores',
    key: SettingKey.FEEDBACK,
    icon: PreviewIcons.FEEDBACK,
    tileIcon: TileIcons.FEEDBACK,
    options: [
        {
            key: FeedbackSettingKey.ON,
            title: 'Señalar',
            header: 'Señalar errores',
            description: 'Al seleccionar un estímulo equivocado, se marcará el error.',
            image: {
                key: 'feedbackOn',
                url: './HabCogGameAssets/settings/feedback/feedbackOn.png',
            }
        },{
            key: FeedbackSettingKey.OFF,
            title: 'No señalar',
            header: 'No señalar errores',
            description: 'Al seleccionar un estímulo equivocado, no se señalará el error.',
            image: {
                key: 'feedbackOff',
                url: './HabCogGameAssets/settings/feedback/feedbackOff.png',
            }
        },
    ]
};

export default feedbackSetting;

export const FeedbackSettingAssets = feedbackSetting.options.map(({ image }) => image);
