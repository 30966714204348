import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    TEXTURE_SHIFT: 0,
    INVERT: 1,
    SYMMETRIC: 2
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Formas',
            header: 'Presentación del estímulo',
            description: 'El estímulo objetivo se presenta de forma distinta.',
            image: {
                key: 'shapeShift',
                url: './IntrudersGameAssets/settings/difficulty/shapeShift.png'
            },
        },
        {
            title: 'Inversiones',
            header: 'Presentación del estímulo',
            description: 'El estímulo objetivo se presenta de forma invertida.',
            image: {
                key: 'flipyShift',
                url: './IntrudersGameAssets/settings/difficulty/flipyShift.png'
            },
        },
        {
            title: 'Simetrías',
            header: 'Presentación del estímulo',
            description: 'El estímulo objetivo se presenta de forma simétrica.',
            image: {
                key: 'flipxShift',
                url: './IntrudersGameAssets/settings/difficulty/flipxShift.png'
            },
        }
    ]
};

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);