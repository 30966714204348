import { filledGeomTextures } from "./assetsPaths/filledGeoms";
import { outlinedGeomTextures } from "./assetsPaths/outlinedGeoms";

export const GameFont = 'HabCogFont';

export const ColorScalePipelineKey = {
    GRAY: 'grayScale',
    RED: 'redScale',
    BLUE: 'blueScale',
    GREEN: 'greenScale',
    YELLOW: 'yellowScale'
}

export const Cursors = {
    pointerout: {
        cursor: 'url(./HabCogGameAssets/cursors/gameSessionPointer.png), pointer'
    },
    pointerover: {
        cursor: 'url(./HabCogGameAssets/cursors/gameSessionPointerOver.png), pointer'
    }
}

export const SceneKey = {
    BOOT: 'bootSplashScene',
    SPLASH: 'splashScene',
    MAIN_MENU: 'mainMenuScene',
    SETTINGS_MENU: 'settingsMenuScene',
    SESSION_HINT: 'sessionHintScene',
    GAME_SESSION: 'gameSessionScene',
    INSTRUCTIONS: 'instructionsScene',
    MODAL: 'modalScene'
};

export const GameAsset = {
    LOGO: 'gameLogo',
    ICON: 'gameIcon',
    THUMBNAIL: 'gameThumbnail'
};

export const SettingKey = {
    DIFFICULTY: 'difficulty',
    SIZE: 'size',
    QUANTITY: 'quantity',
    TIME: 'time',
    COLOR: 'color',
    SHAPE: 'shape',
    OUTLINE: 'outline',
    PLOT: 'plot',
    FEEDBACK: 'feedback',
    STANDBY: 'standby',
    ORIENTATION: 'orientation',
    BUTTONS: 'buttons',
    MODEL: 'model',
    PRESENTATION: 'presentation',
    ROTATION: 'rotation'
};

export const HexColor = {
    RED: 0xFF0000,
    BLUE: 0x0000FF,
    YELLOW: 0xFFFF00,
    GREEN: 0x1DFF00,
    WHITE: 0xFFFFFF
};

const sortGeomTextures = (geomTextures) => ({
    ALL: [
        geomTextures.BLUE_SQUARE,
        geomTextures.RED_TRIANGLE,
        geomTextures.YELLOW_CIRCLE,
        geomTextures.GREEN_STAR,
        geomTextures.WHITE_SQUARE,
        geomTextures.BLUE_TRIANGLE,
        geomTextures.RED_CIRCLE,
        geomTextures.YELLOW_STAR,
        geomTextures.GREEN_SQUARE,
        geomTextures.WHITE_TRIANGLE,
        geomTextures.BLUE_CIRCLE,
        geomTextures.RED_STAR,
        geomTextures.YELLOW_SQUARE,
        geomTextures.GREEN_TRIANGLE,
        geomTextures.WHITE_CIRCLE,
        geomTextures.BLUE_STAR,
    ],
    byShape: {
        SQUARE: [
            geomTextures.BLUE_SQUARE,
            geomTextures.RED_SQUARE,
            geomTextures.YELLOW_SQUARE,
            geomTextures.GREEN_SQUARE,
            geomTextures.WHITE_SQUARE
        ],
        TRIANGLE: [
            geomTextures.BLUE_TRIANGLE,
            geomTextures.RED_TRIANGLE,
            geomTextures.YELLOW_TRIANGLE,
            geomTextures.GREEN_TRIANGLE,
            geomTextures.WHITE_TRIANGLE
        ],
        CIRCLE: [
            geomTextures.BLUE_CIRCLE,
            geomTextures.RED_CIRCLE,
            geomTextures.YELLOW_CIRCLE,
            geomTextures.GREEN_CIRCLE,
            geomTextures.WHITE_CIRCLE
        ],
        STAR: [
            geomTextures.BLUE_STAR,
            geomTextures.RED_STAR,
            geomTextures.YELLOW_STAR,
            geomTextures.GREEN_STAR,
            geomTextures.WHITE_STAR
        ]
    },
    byColor: {
        WHITE: [
            geomTextures.WHITE_SQUARE,
            geomTextures.WHITE_TRIANGLE,
            geomTextures.WHITE_CIRCLE,
            geomTextures.WHITE_STAR
        ],
        YELLOW: [
            geomTextures.YELLOW_SQUARE,
            geomTextures.YELLOW_TRIANGLE,
            geomTextures.YELLOW_CIRCLE,
            geomTextures.YELLOW_STAR
        ],
        BLUE: [
            geomTextures.BLUE_SQUARE,
            geomTextures.BLUE_TRIANGLE,
            geomTextures.BLUE_CIRCLE,
            geomTextures.BLUE_STAR
        ],
        RED: [
            geomTextures.RED_SQUARE,
            geomTextures.RED_TRIANGLE,
            geomTextures.RED_CIRCLE,
            geomTextures.RED_STAR
        ],
        GREEN: [
            geomTextures.GREEN_SQUARE,
            geomTextures.GREEN_TRIANGLE,
            geomTextures.GREEN_CIRCLE,
            geomTextures.GREEN_STAR
        ]
    }
});

export const sortedGeomTextures = sortGeomTextures(filledGeomTextures);

export const sortedOutlinedGeomTextures = sortGeomTextures(outlinedGeomTextures);
