import React from "react";
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import { colorScaleSetting, difficultySetting } from "./utils/settings";
import { SpaceRelationGameAssets } from "./utils/assets";
import GrayScalePipeline from "../HabCogGame/pipelines/grayTextureTint";
import RedScalePipeline from "../HabCogGame/pipelines/redTextureTint";
import GreenScalePipeline from "../HabCogGame/pipelines/greenTextureTint";
import YellowScalePipeline from "../HabCogGame/pipelines/yellowTextureTint";
import BlueScalePipeline from "../HabCogGame/pipelines/blueTextureTint";
import { ColorScalePipelineKey } from "../HabCogGame/utils/constants";
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

export const data = {
    name: 'Relación en el espacio',
    sessionAssets: SpaceRelationGameAssets,
    sessionScene: SessionScene,
    logoUrl: './SpaceRelationGameAssets/logo.png',
    iconUrl: './SpaceRelationGameAssets/icon.png',
    thumbnail: './SpaceRelationGameAssets/thumbnail.png',
    getHint: () => 'Observa el recuadro y señala los cuerpos geométricos ubicados en ' +
        'igual posición.',
    userManual: 'Se presentan cuerpos geométricos en diferentes posiciones, el usuario ' +
        'debe seleccionar aquel que coincide con el estímulo patrón prestando ' +
        'atención a la relación espacial entre dichos cuerpos. En ajustes se puede ' +
        'configurar el juego según: nivel de dificultad, contraste, tamaño y ' +
        'número de estímulos, señalización de errores y tiempo de duración de la ' +
        'sesión. ',
    settings: [
        difficultySetting,
        colorScaleSetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ],
    onLoad: (scene) => {
        const game = scene.game;
        const grayScalePipeline = new GrayScalePipeline(game);
        const redScalePipeline = new RedScalePipeline(game);
        const blueScalePipeline = new BlueScalePipeline(game);
        const yellowScalePipeline = new YellowScalePipeline(game);
        const greenScalePipeline = new GreenScalePipeline(game);
        const { GRAY, RED, GREEN, BLUE, YELLOW } = ColorScalePipelineKey;
        game.renderer.addPipeline(GRAY, grayScalePipeline);
        game.renderer.addPipeline(RED, redScalePipeline);
        game.renderer.addPipeline(BLUE, blueScalePipeline);
        game.renderer.addPipeline(GREEN, greenScalePipeline);
        game.renderer.addPipeline(YELLOW, yellowScalePipeline);
    }
};

export default function SpaceRelationGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}