import { GameAsset } from "./constants";
import { LudoAsset, SceneBackground } from "./assetsPaths/splash";
import MessageBox from '../gameObjects/messageBox';
import AccesibilityButton from '../gameObjects/accesibilityButton';
import { Icons } from "./assetsPaths/icons";
import UserTag from "../gameObjects/userTag";
import { RoundedCamera } from "../pipelines/roundedCamera";
import Button from "../gameObjects/button";
import { Btns } from "./assetsPaths/btn";
import HeaderBar from "../gameObjects/headerBar";
import LargeMessageBox from "../gameObjects/largeMessageBox";
import { wrapVect2 } from "../gameObjects/vect2Wrapper";
import PreviewBox from "../gameObjects/previewBox";
import SettingTile from "../gameObjects/settingTile";
import Hub from "../gameObjects/hub";
import AccesibilityHtmlBtn from "../gameObjects/accesibiltyHtmlButton";

export const createLudoImg = (scene) =>
    scene.ludo = scene.add.image(0, 0, LudoAsset.key).setOrigin(1);

export const createGameIcon = (scene) =>
    scene.icon = scene.add.image(0, 0, GameAsset.ICON);

export const createGameLogo = (scene) =>
    scene.logo = scene.add.image(0, 0, GameAsset.LOGO);

export const createBackgroundImage = (scene) => {
    const { add, game } = scene;
    const { height } = game.canvas;
    scene.background = add.image(0, 0, SceneBackground.key)
        .setOrigin(0)
        .setDisplaySize(100, height);
    scene.background
        .setScale(scene.background.scaleY, scene.background.scaleY);
    return scene.background;
}

export const createMessageBox = (scene, instruction) => {
    const msg = new MessageBox(scene, 0, 0, instruction);
    wrapVect2(msg);
    scene.msg = msg;
    scene.add.existing(msg);
}

export const createBtn = (scene, label, icon, background = Btns.PRIMARY) => {
    const btn = new Button(scene, 0, 0, label, icon, background);
    wrapVect2(btn);
    scene.add.existing(btn);
    return btn;
}

const createVoiceOverBtn = (scene) => {
    const isIOS = scene.game.isIOSDevice;
    const icon = Icons.VOICE_OVER;
    if (isIOS)
        return new AccesibilityHtmlBtn(scene, 0, 0, icon);
    else
        return new AccesibilityButton(scene, 0, 0, icon);
}
const createStopVoiceOverBtn = (scene) => {
    const isIOS = scene.game.isIOSDevice;
    const icon = (localStorage.getItem('voiceSound') >= 0) ? Icons.SPEAK_ICON : Icons.STOP_SPEAK_ICON;
    if (isIOS)
        return new AccesibilityHtmlBtn(scene, 500, 0, icon);
    else
        return new AccesibilityButton(scene, 500, 0, icon);
}

export const createAccesibilityBtns = (scene) => {
    scene.voiceOverBtn = createVoiceOverBtn(scene);
    scene.stopVoiceOverBtn = createStopVoiceOverBtn(scene);
    scene.zoomBtn = new AccesibilityButton(scene, 0, 0, Icons.ZOOM);
    scene.accesibilityBtns = [scene.stopVoiceOverBtn, scene.voiceOverBtn, scene.zoomBtn];
    scene.accesibilityBtns.forEach(btn => {
        wrapVect2(btn);
        btn.setInteractive();
        scene.add.existing(btn)
    });
    return scene.accesibilityBtns;
}
export const createZoomCamera = (scene) => {
    scene.isZoomActive = false;
    scene.zoomCamera = new RoundedCamera(scene, 0, 0, 300, 1.5)
        .setRenderToTexture(scene.game.roundPipeline);
    scene.zoomCamera.setVisible(false);
    scene.cameras.addExisting(scene.zoomCamera);
    const inputCallback = (pointer) => {
        if (scene.isZoomActive)
            scene.zoomCamera.relocate(pointer);
    }
    scene.input.on('pointermove', inputCallback)
    scene.events.once('shutdown', () => scene.input.off('pointermove', inputCallback));
}

export const createUserTag = (scene, sessionData) => {
    scene.userTag = new UserTag(scene, 0, 0, sessionData);
    wrapVect2(scene.userTag);
    scene.add.existing(scene.userTag);
    return scene.userTag;
}

export const createHeaderBar = (scene, label) => {
    scene.header = new HeaderBar(scene, 0, 0, label);
    scene.add.existing(scene.header);
}

export const createLargeMessageBox = (scene, label) => {
    scene.msgBox = new LargeMessageBox(scene, 0, 0, label);
    wrapVect2(scene.msgBox);
    scene.add.existing(scene.msgBox);
}

export const createPreviewBox = (scene) => {
    scene.previewBox = new PreviewBox(scene, 0, 0);
    scene.add.existing(scene.previewBox);
    return scene.previewBox;
}

export const createSettingTile = (scene, { tileIcon, name, options }, index) => {
    const optionLabels = options.map(({ title }) => title);
    const tile = new SettingTile(scene, 0, 0, tileIcon, name, optionLabels, index);
    wrapVect2(tile);
    scene.add.existing(tile);
    return tile;
}

export const createHub = (scene, label, sessionData) => {
    scene.hub = new Hub(scene, 0, 0, label, sessionData);
    scene.add.existing(scene.hub);
}

export const setZoomBtnOnclick = (scene, zoomBtn) => {
    zoomBtn.onclick(() => {
        scene.zoomCamera.relocate(scene.input.activePointer);
        scene.isZoomActive = !scene.isZoomActive;
        scene.zoomCamera.setVisible(scene.isZoomActive);
    });
}
