export const Vocabulary =
{
    nameAsset: '',
    scale: {
        objectWidthRatio: 1,
        objectHeightRatio: 1,
        objectWidth: 325,
        objectHeight: 600,
        objectPositionX: 310,
        objectPositionY: 700,
    }


}

export const InstructionText = {
    nameAsset: '',
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 250,
    objectPositionX: 970,
    objectPositionY: 220,



}
export const RigthButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 130,
    objectHeight: 160,
    objectPositionX: 1810,
    objectPositionY: 600,
}
export const LeftButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 130,
    objectHeight: 160,
    objectPositionX: 100,
    objectPositionY: 600,
}