export const blackCardStructure = {
    cardTitle: {
        text: '',
        style: {
            fontSize: '50px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#205067'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 150,
            objectHeight: 150,
            objectPositionX: 0,
            objectPositionY: 0,
        }
    }

}