import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { shapeSetting, ShapeSettingAssets } from "../HabCogGame/utils/settingsConstants/shapeSetting";
import { difficultySetting, getPlotSetting, CustomSettingAssets } from "./utils/settings";
import { PlotShapeAssets, SerpentineAsset } from "./utils/assets";
import { FilledGeomsAssets } from '../HabCogGame/utils/assetsPaths/filledGeoms';
import { ColorSettingAssets } from '../HabCogGame/utils/settingsConstants/colorSetting';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const plotSetting = getPlotSetting();
const sessionAssets = [
    ...CustomSettingAssets,
    ...ShapeSettingAssets,
    ...SizeSettingAssets,
    ...FilledGeomsAssets,
    ...PlotShapeAssets,
    ...ColorSettingAssets,
    ...TimeSettingAssets,
    SerpentineAsset
]

const data = {
    name: 'Boing boing',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './BoingBoingGameAssets/logo.png',
    iconUrl: './BoingBoingGameAssets/icon.png',
    thumbnail: './BoingBoingGameAssets/thumbnail.png',
    getHint: () => 'Toca la figura para detenerla. Si aciertas 10 veces tendrás una sorpresa.',
    userManual: 'El estímulo se desplaza a velocidad constante en sentido diagonal, al ' +
        'llegar a un borde de la pantalla rebota y cambia de dirección. Cada vez ' +
        'que el usuario toca el estímulo, detiene momentáneamente el ' +
        'movimiento.',
    settings: [
        difficultySetting,
        shapeSetting,
        plotSetting,
        sizeSetting,
        timeSetting
    ]
};

export default function BoingBoingGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
