import Phaser from 'phaser';
import VoiceManager from './voiceManager';
import Score from './score';

export default class BaseGame extends Phaser.Game {

    constructor(config) {
        super(config);
        this.voice = new VoiceManager();
        this.ludo = {};
        this.sessionData = config.sessionData
        this.score = new Score();
    }

    startCountdown(scene) {
        scene.time.addEvent({
            delay: 1000,
            callback: () => {
                this.score.time++;
            },
            callbackScope: this,
            loop: true
        });
    }
}