import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    PLANE: 0,
    HELICOPTER: 1,
    CAR: 2,
    SUBMARINE: 3
}

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Avión',
            header: 'Forma de avión',
            description: 'Los estímulos tendrán forma de avión.',
            image: {
                key: 'planeDifficultySetting',
                url: './SomethingMissingGameAssets/settings/difficulty/planeSetting.png'
            },
        }, {
            title: 'Helicóptero',
            header: 'Forma de helicóptero',
            description: 'Los estímulos tendrán forma de helicóptero.',
            image: {
                key: 'chopperDifficultySetting',
                url: './SomethingMissingGameAssets/settings/difficulty/helicopterSetting.png'
            },
        }, {
            title: 'Automóvil',
            header: 'Forma de automóvil',
            description: 'Los estímulos tendrán forma de automóvil.',
            image: {
                key: 'carDifficultySetting',
                url: './SomethingMissingGameAssets/settings/difficulty/carSetting.png'
            },
        }, {
            title: 'Submarino',
            header: 'Forma de submarino',
            description: 'Los estímulos tendrán forma de submarino.',
            image: {
                key: 'subMarineDifficultySetting',
                url: './SomethingMissingGameAssets/settings/difficulty/submarineSetting.png'
            },
        }
    ]
};

export default difficultySetting;

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);
