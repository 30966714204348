import Phaser from 'phaser';
import { GameFont } from '../utils/constants';
import HubButton from './hubButton';
import { Icons } from '../utils/assetsPaths/icons';
import { Badges } from '../utils/assetsPaths/badges';
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image;
const Rectangle = Phaser.GameObjects.Rectangle;
const Text = Phaser.GameObjects.Text;

class HeaderHub extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, badges = Object.values(Badges), sceneBadges, color = 0x2F7BA1) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color).setOrigin(0);
        this.label = new Text(scene, 0, 0, label, HeaderHubTextStyle).setOrigin(0.5);
        this.homeBtn = new HubButton(scene, 0, 0, "Inicio", Icons.HOME);
        this.worldBtn = new HubButton(scene, 0, 0, "Mundos", Icons.WORLD);
        this.badgesBtn = new HubButton(scene, 0, 0, "Insignias", Icons.BADGES);
        this.modalBtn = new HubButton(scene, 0, 0, "Salir", Icons.EXIT_WORLD);
        this.btns = [this.homeBtn, this.worldBtn, this.badgesBtn, this.modalBtn];
        this.badges = badges.map(badge => {
            const img = new Image(scene, 0, 0, badge.key);
            if (sceneBadges.indexOf(badge) === -1)
                img.setAlpha(0.5);
            return img;

        });
        this.badgeKeys = badges.map(({ key }) => key);
        this.add([this.background, this.label, ...this.btns, ...this.badges]);
    }

    getHeight() {
        return this.background.displayHeight;
    }

    setInteractive() {
        this.btns.forEach(btn => btn.setInteractive());
    }

    getBadgePosition(item) {
        const index = this.badgeKeys.indexOf(item.key);
        const badge = this.badges[index];
        return {
            x: badge.x + this.x,
            y: badge.y + this.y,
            scale: badge.scale,
            index
        }
    }

    setBadgeAlpha(index, alpha) {
        this.badges[index].setAlpha(alpha)
    }

    resizeBtns(height) {
        this.btns.forEach(btn => btn.resize(height, height / 2, 0, 1));
        const { label } = this.btns[0];
        Phaser.Actions.GridAlign(this.btns, {
            width: -1,
            cellWidth: this.homeBtn.background.displayWidth * 1.65,
            x: height / 2,
            y: height / 2 - label.displayHeight / 2,
            position: Phaser.Display.Align.CENTER
        })
    }

    resizeBadges(width, height) {
        const w = width / (2.5 * this.badges.length);
        scaleImage(this.badges[0], w, height / 2, 0, 1);
        this.badges.forEach(badge => badge.setScale(this.badges[0].scale));
        const cellWidth = this.badges[0].displayWidth * 1.25;
        Phaser.Actions.GridAlign(this.badges, {
            width: -1,
            cellWidth,
            x: width - this.badges.length * cellWidth,
            y: height / 2,
            position: Phaser.Display.Align.CENTER
        })
    }

    resizeLabel(width, height) {
        const bX = this.badges[0].getLeftCenter().x;
        const rX = this.worldBtn.x + this.worldBtn.background.displayWidth / 2;
        this.label.setFontSize(`${height * 0.35}px`)
        const x = this.label.getRightCenter().x > rX ?
            (bX + rX) / 2 : width / 2;
        this.label.setPosition(x, height / 2);
    }

    resize(width, height) {
        this.background.setDisplaySize(width, height);
        this.resizeBtns(height);
        this.resizeBadges(width, height);
        this.resizeLabel(width, height);
        return this;
    }
}

const HeaderHubTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}

export default HeaderHub;