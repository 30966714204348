export const cardComponentStructure = {
    cardTitle: {
        text: '',
        style: {
            fontSize: '26px',
            fontFamily: 'ArialRounded',
            color: '#feff00',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 10,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 65,
            objectPositionX: 0,
            objectPositionY: -281,
        }
    },
    cardImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 305,
            objectHeight: 625,
            objectPositionX: 0,
            objectPositionY: 0,
        }
    },
    cardDescription:
    {
        text: '',
        style: {
            fontSize: '25px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'left',

        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 150,
            objectPositionX: 0,
            objectPositionY: 240
        }

    },
    cardButton:
    {
        text: '',
        nameAsset: 'playIcon',
        style: {
            fontSize: '15px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 70,
            objectHeight: 90,
            objectPositionX: 100,
            objectPositionY: 0,
        }

    }
}