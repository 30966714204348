export const Btns = {
    PRIMARY: {
        key: 'primaryBtnBackground',
        url: './HabCogGameAssets/btns/primaryBtnBackground.png'
    },
    SECONDARY: {
        key: 'secondaryBtnBackground',
        url: './HabCogGameAssets/btns/secondaryBtnBackground.png'
    },
    MESSAGE_BOX: {
        key: 'messageBoxBackground',
        url: './HabCogGameAssets/btns/messageBoxBackground.png'
    },
    ACCESIBILITY: {
        key: 'accesibilityBtnBackground',
        url: './HabCogGameAssets/btns/accessBtnBackground.png'
    },
    USER_TAG: {
        key: 'userTagBackground',
        url: './HabCogGameAssets/btns/userTagBackground.png'
    },
    LARGE_MESSAGE_BOX: {
        key: 'largeMessageBoxBackground',
        url: './HabCogGameAssets/btns/largeMessageBoxBackground.png'
    },
    PREVIEW_BOX: {
        key: 'previewBoxBackground',
        url: './HabCogGameAssets/btns/previewBoxBackground.png'
    },
    WHITE_BOX: {
        key: 'whiteBoxBackground',
        url: './HabCogGameAssets/btns/whiteSquare.png'
    },
    SETTING_TILE: {
        key: 'settingTileBackground',
        url: './HabCogGameAssets/btns/settingTileBackground.png'
    },
    ARROW: {
        key: 'settingArrow',
        url: './HabCogGameAssets/btns/rightArrow.png'
    },
    OPTION_INDICATOR: {
        key: 'optionIndicatorBackground',
        url: './HabCogGameAssets/btns/optionsIndicatorBackground.png'
    },
    OPTION_SELECTED: {
        key: 'optionSelectedIndicatorBackgroundPng',
        url: './HabCogGameAssets/btns/optionSelectedIndicator.png'
    },
    HUB: {
        key: 'hubBtnBackground',
        url: './HabCogGameAssets/btns/hubBtnBackground.png'
    },
    MODAL: {
        key: 'modalBackground',
        url: './HabCogGameAssets/btns/modalBackground.png'
    },
    CONFIRM_DIALOG: {
        key: 'confirmDialogBackground',
        url: './HabCogGameAssets/btns/confirmDialogBackground.png'
    }
}

export const BtnAssets = Object.values(Btns);