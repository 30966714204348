import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Muy lento',
            header: 'Dos segundos',
            description: 'El estímulo cambiará de estado durante 2 segundos.',
            image: {
                key: 'verySlowTarget',
                url: './HabCogGameAssets/settings/speed/verySlowTarget.png'
            }
        },
        {
            title: 'Lento',
            header: 'Un segundo',
            description: 'El estímulo cambiará de estado durante 1 segundo.',
            image: {
                key: 'slowTarget',
                url: './HabCogGameAssets/settings/speed/slowTarget.png'
            }
        },
        {
            title: 'Normal',
            header: '3/4 de segundo',
            description: 'El estímulo cambiará de estado durante 750 segundo.',
            image: {
                key: 'normalTarget',
                url: './HabCogGameAssets/settings/speed/normalTarget.png'
            }
        },
        {
            title: 'Rápido',
            header: 'Medio segundo',
            description: 'El estímulo cambiará de estado durante 500 milisegundos.',
            image: {
                key: 'fastTarget',
                url: './HabCogGameAssets/settings/speed/fastTarget.png'
            }
        },
        {
            title: 'Muy rápido',
            header: 'Cuarto de segundo',
            description: 'El estímulo cambiará de estado durante 250 milisegundos.',
            image: {
                key: 'veryFastTarget',
                url: './HabCogGameAssets/settings/speed/fastestTarget.png'

            }
        },
        {
            title: 'Extra rápido',
            header: 'Octavo de segundo',
            description: 'El estímulo cambiará de estado durante 125 milisegundos.',
            image: {
                key: 'fastestTarget',
                url: './HabCogGameAssets/settings/speed/veryFastTarget.png'
            }
        }
    ]
};

export const DifficultySettingsAssets = difficultySetting.options.map(({ image }) => image);

