import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const OutlineSettingKey = {
    OFF: 0,
    ON: 1
}

export const outlineSetting = {
    key: SettingKey.OUTLINE,
    name: 'Contorno',
    icon: PreviewIcons.OUTLINE,
    tileIcon: TileIcons.OUTLINE,
    options: [
        {
            key: OutlineSettingKey.OFF,
            title: 'No',
            header: 'Estímulos sin contorno',
            description: 'Los estímulos presentados en pantalla estarán pintados completamente.',
            image: {
                key: 'noOutlinedTargetsPreview',
                url: './HabCogGameAssets/settings/outlineShape/noOutlinedShapes.png'
            }
        },{
            key: OutlineSettingKey.ON,
            title: 'Sí',
            header: 'Estímulos con contorno',
            description: 'Los estímulos presentados en pantalla tendrán pintado únicamente su contorno.',
            image: {
                key: 'outlinedTargetsPreview',
                url: './HabCogGameAssets/settings/outlineShape/outlinedShapes.png'
            }
        }
    ]
};

export const OutlineSettingAssets = outlineSetting.options.map(({ image }) => image);
