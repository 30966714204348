import axios from "axios";
import { API_BASE_URL } from "./constants";

export default class AuthService {
  static postUser(user) {
    return axios.post(
      API_BASE_URL +
      'users/auth', user
    );
  }
}

