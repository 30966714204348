import { SettingKey } from "../../HabCogGame/utils/constants";
import { attachChildSettings } from "../../HabCogGame/utils/settingsConstants/functions";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    FREE: 0,
    COPY: 1,
    COLOR: 2
}

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Libre',
            header: 'Dibujo libre',
            description: 'No se presentará modelos en pantalla.',
            image: {
                key: 'freeStepByStepGameAssets',
                url: './StepByStepGameAssets/settings/difficulty/freeModel.png'
            }
        }, {
            title: 'Replicar',
            header: 'Replica de modelos',
            description: 'Se presentarán modelos que desaparecen para replicar su forma.',
            image: {
                key: 'replicateStepByStepGameAssets',
                url: './StepByStepGameAssets/settings/difficulty/replicateModel.png'
            }
        }, {
            title: 'Relleno',
            header: 'Relleno de formas',
            description: 'Se presentarán modelos  para pintar.',
            image: {
                key: 'paintStepByStepGameAssets',
                url: './StepByStepGameAssets/settings/difficulty/paintModel.png'
            }
        }
    ]
};

const singleModelSetting = {
    key: 'dummy',
    name: 'Modelo',
    icon: PreviewIcons.MODEL,
    tileIcon: TileIcons.MODEL,
    options: [
        {
            title: 'Ninguno',
            header: 'Dibujo libre',
            description: 'El usuario puede dibujar a gusto.',
            image: {
                key: 'shapeShiftImage',
                url: './StepByStepGameAssets/settings/difficulty/emptyCanvas.png'
            }
        }
    ]
};

const modelSetting = {
    key: SettingKey.MODEL,
    name: 'Modelo',
    icon: PreviewIcons.MODEL,
    tileIcon: TileIcons.MODEL,
    options: [
        {
            title: 'Cuadrado',
            header: 'Dibuja un cuadrado',
            description: 'Se presentará un cuadrado como modelo para dibujar.',
            image: {
                key: 'squareShape',
                url: './HabCogGameAssets/settings/shape/squareShape.png',
            }
        }, {
            title: 'Triángulo',
            header: 'Dibuja un triángulo',
            description: 'Se presentará un triángulo como modelo para dibujar.',
            image: {
                key: 'triangleShape',
                url: './HabCogGameAssets/settings/shape/triangleShape.png',
            }
        }, {
            title: 'Círculo',
            header: 'Dibuja un círculo',
            description: 'Se presentará un círculo como modelo para dibujar.',
            image: {
                key: 'circleShape',
                url: './HabCogGameAssets/settings/shape/circleShape.png',
            }
        }, {
            title: 'Estrella',
            header: 'Dibuja una estrella',
            description: 'Se presentará una estrella como modelo para dibujar.',
            image: {
                key: 'starShape',
                url: './HabCogGameAssets/settings/shape/starShape.png',
            }
        }, {
            title: 'Casa',
            header: 'Dibuja una casa',
            description: 'Se presentará una casa como modelo para dibujar.',
            image: {
                key: 'houseSetting',
                url: './StepByStepGameAssets/settings/model/houseModel.png',
            }
        }, {
            title: 'Automóvil',
            header: 'Dibuja un automóvil',
            description: 'Se presentará un automóvil como modelo para dibujar.',
            image: {
                key: 'carSetting',
                url: './StepByStepGameAssets/settings/model/carModel.png',
            }
        }, {
            title: 'Sol',
            header: 'Dibuja un sol',
            description: 'Se presentará un sol como modelo para dibujar.',
            image: {
                key: 'sunSetting',
                url: './StepByStepGameAssets/settings/model/sunModel.png',
            }
        }

    ]
};

const getStepByStepGameAssetsDifficultySetting = () =>
    attachChildSettings(difficultySetting, [singleModelSetting, modelSetting, modelSetting]);

export default getStepByStepGameAssetsDifficultySetting;

export const CustomSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...singleModelSetting.options.map(({ image }) => image),
    ...modelSetting.options.map(({ image }) => image)
]
