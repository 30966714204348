import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const ColorSettingKey = {
    WHITE: 0,
    YELLOW: 1,
    BLUE: 2,
    RED: 3,
    GREEN: 4
}

export const colorSetting = {
    name: 'Color',
    key: SettingKey.COLOR,
    icon: PreviewIcons.COLOR,
    tileIcon: TileIcons.COLOR,
    options: [
        {
            key: ColorSettingKey.WHITE,
            title: 'Blanco',
            header: 'Estímulos blancos',
            description: 'Todos los estímulos serán de color blanco.',
            image: {
                key: 'whiteColorSetting',
                url: './HabCogGameAssets/settings/color/whiteColor.png',
            }
        }, {
            key: ColorSettingKey.YELLOW,
            title: 'Amarillo',
            header: 'Estímulos amarillos',
            description: 'Todos los estímulos serán de color amarillo.',
            image: {
                key: 'yellowColorSetting',
                url: './HabCogGameAssets/settings/color/yellowColor.png',
            }
        }, {
            key: ColorSettingKey.BLUE,
            title: 'Azul',
            header: 'Estímulos azules',
            description: 'Todos los estímulos serán de color azul.',
            image: {
                key: 'blueColorSetting',
                url: './HabCogGameAssets/settings/color/blueColor.png',
            }
        }, {
            key: ColorSettingKey.RED,
            title: 'Rojo',
            header: 'Estímulos rojos',
            description: 'Todos los estímulos serán de color rojo.',
            image: {
                key: 'redColorSetting',
                url: './HabCogGameAssets/settings/color/redColor.png',
            }
        }, {
            key: ColorSettingKey.GREEN,
            title: 'Verde',
            header: 'Estímulos verdes',
            description: 'Todos los estímulos serán de color verde.',
            image: {
                key: 'greenColorSetting',
                url: './HabCogGameAssets/settings/color/greenColor.png',
            }
        }
    ]
};

export const ColorSettingAssets = colorSetting.options.map(({ image }) => image);

const HexColor = {
    RED: 0xFF0000,
    BLUE: 0x0000FF,
    YELLOW: 0xFFFF00,
    GREEN: 0x1DFF00,
    WHITE: 0xFFFFFF
};

export const getHexColor = (key) => {
    switch (key) {
        case ColorSettingKey.BLUE:
            return HexColor.BLUE;
        case ColorSettingKey.YELLOW:
            return HexColor.YELLOW;
        case ColorSettingKey.GREEN:
            return HexColor.GREEN;
        case HexColor.RED:
            return HexColor.RED;
        default: return HexColor.WHITE
    }
}

