import Phaser from 'phaser';
import cardComponent from '../../GameObjects/CardComponent/cardComponent';
import { WorkshopStructure, RigthButtonStructure, LeftButtonStructure, InstructionText } from './WorkshopStructure';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import { cardComponentStructure } from '../../GameObjects/CardComponent/cardCompomponentStructure';
import InstructionTextComponent from '../../GameObjects/InstructionTextComponent/InstructionTextComponent';
import { buttonPressAnimation, mouseOverAnimation } from '../../Functions/AnimatedFunctions';
import { VoiceManager } from '../../Plug-ins/voiceManager';
import { carouselLogic } from '../../Functions/carouselFunctions';
import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
//import workshops from '../../Services/workshops';


export default class WorkshopsScene extends Phaser.Scene {
    constructor() {
        super('Workshops');
    }

    preload() {
        //load Scale
        this.cardScale = cardComponentStructure
        this.workshopStructure = this.clone(WorkshopStructure)
        this.InstructionTextScale = this.clone(InstructionText)
        this.rigthButtonScale = this.clone(RigthButtonStructure)
        this.leftButtonScale = this.clone(LeftButtonStructure)
        //preload Background
        this.drawPreloadBackground()
        //save components scale
        this.saveScaleState()
        //Scale this scene elements
        this.workshopStructure.scale = scaleGameObject(this, this.workshopStructure.scale)
        this.leftButtonScale = scaleGameObject(this, this.leftButtonScale)
        this.rigthButtonScale = scaleGameObject(this, this.rigthButtonScale)
        this.InstructionTextScale = scaleGameObject(this, this.InstructionTextScale)
        //Scale cardComponent
        this.cardScale.cardTitle.scale = scaleGameObject(this, this.cardScale.cardTitle.scale)
        this.cardScale.cardTitle.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardTitle.style.fontSize)
        this.cardScale.cardImage.scale = scaleGameObject(this, this.cardScale.cardImage.scale)
        this.cardScale.cardDescription.scale = scaleGameObject(this, this.cardScale.cardDescription.scale)
        this.cardScale.cardDescription.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardDescription.style.fontSize)
        this.cardScale.cardButton.scale = scaleGameObject(this, this.cardScale.cardButton.scale)

    }
    create() {
        //voice
        var voice = new VoiceManager('Paulina')
        this.workshops = this.cache.json.get('levelData');
        if (this.workshops === undefined) {
            this.workshops = []
        }

        //drawing components
        const originX = this.workshopStructure.scale.objectPositionX
        const originY = this.workshopStructure.scale.objectPositionY
        const width = this.workshopStructure.scale.objectWidth
        const height = this.workshopStructure.scale.objectHeight

        var cardComponentArray = []

        for (var i = 0; i < this.workshops.length; i++) {
            this.workshopStructure.scale.objectPositionX = (originX + (width * (i % 4))) * 1.2
            const workshop = new cardComponent(this,
                this.workshopStructure.scale.objectPositionX,
                this.workshopStructure.scale.objectPositionY,
                width,
                height,
                this.workshops[i].name,
                this.workshops[i].id,
                this.workshops[i].description,
                this.workshops[i].id,
            );
            workshop.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
                buttonPressAnimation(this, workshop)
                voice.speak('')
                this.sound.play('clickSound')
            })
            workshop.setInteractive().on('pointerup', () => {
                workshop.startScene()
            }).on('pointerover', () => {
                mouseOverAnimation(this, workshop, 1.05)
                workshop.outlineVisible(true)
            }).on('pointerout', () => {
                mouseOverAnimation(this, workshop, 1)
                workshop.outlineVisible(false)
            })

            cardComponentArray.push(workshop)
        }
        if (this.workshops.length === 0) {
            var infoCard = this.add.image(originX, originY, 'warningWorkshops').setOrigin(0, 0.5)
            infoCard.setDisplaySize(width * 4, height)
        }
        //Rigth and left button 
        var rigthButton = this.add.image(this.rigthButtonScale.objectPositionX, this.rigthButtonScale.objectPositionY, 'TriangleButton')
        rigthButton.setDisplaySize(this.rigthButtonScale.objectWidth, this.rigthButtonScale.objectHeight)
        var leftButton = this.add.image(this.leftButtonScale.objectPositionX, this.leftButtonScale.objectPositionY, 'TriangleButtonInv')
        leftButton.setDisplaySize(this.leftButtonScale.objectWidth, this.leftButtonScale.objectHeight)
        leftButton.setAlpha(0.4)
        //Carousel logic
        carouselLogic(cardComponentArray, rigthButton, leftButton, this)
        //Draw Instructions componet
        var instructions = new InstructionTextComponent(this, this.InstructionTextScale.objectPositionX,
            this.InstructionTextScale.objectPositionY,
            this.InstructionTextScale.objectWidth
            , this.InstructionTextScale.objectHeight,
            'Selecciona el taller que desees jugar',
            'ludoIcon', this.historyID, this.undefined, this.historyName
        )
        this.add.existing(instructions)

        //Top Bar Component
        var bar = new topBar(this, 0, 0, this.sys.canvas.width, 400, 'Talleres', '', 'Selecciona el taller que desees jugar')
        this.add.existing(bar)

        this.loadScale()

    }
    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    drawPreloadBackground() {
        var background = this.add.image(0, 0, 'background').setOrigin(0)
        var bar = this.add.image(0, 0, 'Bar')
        let scaleX = this.sys.canvas.width / background.width
        let scaleY = this.sys.canvas.height / background.height
        background.setScale(scaleX, scaleY).setScrollFactor(0)
        scaleX = this.sys.canvas.width / 1920
        scaleY = this.sys.canvas.height / 1080
        var scalefull = this.sys.canvas.width / bar.width
        bar.setDisplaySize(bar.width * scalefull, bar.height * scaleY).setOrigin(0)
        var tittleScene = this.add.text(this.cameras.main.width / 2, 0, 'TALLERES').setOrigin(0.5, 0)
        let font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 95)
        tittleScene.setFontSize(font)
    }
    loadScale() {
        this.cardScale.cardTitle.scale = this.cardTitle
        this.cardScale.cardTitle.style.fontSize = this.fontSize
        this.cardScale.cardImage.scale = this.cardImage
        this.cardScale.cardDescription.scale = this.cardDescription
        this.cardScale.cardDescription.style.fontSize = this.cardDescriptionFontSize
        this.cardScale.cardButton.scale = this.cardButton

    }
    saveScaleState() {
        //save scale state
        this.cardTitle = this.cardScale.cardTitle.scale
        this.fontSize = this.cardScale.cardTitle.style.fontSize
        this.cardImage = this.cardScale.cardImage.scale
        this.cardDescription = this.cardScale.cardDescription.scale
        this.cardDescriptionFontSize = this.cardScale.cardDescription.style.fontSize
        this.cardButton = this.cardScale.cardButton.scale
    }

}