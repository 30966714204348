import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { difficultySetting, DifficultySettingAssets } from "./utils/settings";
import standbySetting, { StandBySettingAssets } from "../HabCogGame/utils/settingsConstants/standbySetting";
import SessionScene from './scenes/sessionScene';
import { FilledGeomsAssets } from '../HabCogGame/utils/assetsPaths/filledGeoms';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const data = {
    name: 'Aquí y allá',
    sessionAssets: [
        ...DifficultySettingAssets,
        ...FilledGeomsAssets,
        ...SizeSettingAssets,
        ...StandBySettingAssets,
        ...TimeSettingAssets,
    ],
    sessionScene: SessionScene,
    logoUrl: './HereAndThereGameAssets/logo.png',
    iconUrl: './HereAndThereGameAssets/icon.png',
    thumbnail: './HereAndThereGameAssets/thumbnail.png',
    getHint: () => 'Toca la figura para que se mueva y cuando se haya ido, señala la ' +
        'dirección que siguió con un clic o toque.',
    userManual: 'Al hacer clic (tocar) en el estímulo que se presenta en el centro de la pantalla, ' +
        'éste se desplazará en sentido horizontal hasta desaparecer. En la ' +
        'siguiente pantalla, el usuario indicará con un clic la dirección del ' +
        'desplazamiento.',
    settings: [
        difficultySetting,
        sizeSetting,
        standbySetting,
        timeSetting
    ]
};

export default function HereAndThereGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}