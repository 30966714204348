import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import difficultySetting from "./utils/settings";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { SessionAssets, PuzzleGamePhotosAssets } from './utils/assets';
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

const data = {
    name: 'Rompecabezas',
    sessionAssets: SessionAssets,
    sessionScene: SessionScene,
    logoUrl: './PuzzleGameAssets/logo.png',
    iconUrl: './PuzzleGameAssets/icon.png',
    thumbnail: './PuzzleGameAssets/thumbnail.png',
    getHint: () => 'Mira la imagen prestando atención a los detalles. Toca y tendrás ' +
        'las piezas para armar el rompecabezas.',
    userManual: 'Se presenta una imagen completa, cuando el usuario la seleccione se ' +
        'fraccionará en piezas de rompecabezas. Mediante clics, el usuario puede ' +
        'mover o rotar cada pieza hasta completar la imagen original. Es posible ' +
        'establecer ajustes relacionados con: nivel de dificultad (giro, posición o ' +
        'ambos), tamaño y número de estímulos, y tiempo de duración de la ' +
        'sesión.',
    settings: [
        difficultySetting,
        sizeSetting,
        buildQuantitySetting([4, 9]),
        timeSetting
    ],
    onLoad: function (scene) {
        const frameWidth = 750 / 2;
        const frameHeight = 750 / 2;
        const smallFrameWidth = 750 / 3;
        const smallFrameHeight = 750 / 3;
        PuzzleGamePhotosAssets.forEach(({ url, key }) => {
            scene.load.spritesheet(`${key}-spritesheet2x2`, url,
                { frameWidth, frameHeight });
            scene.load.spritesheet(`${key}-spritesheet3x3`, url,
                { frameWidth: smallFrameWidth, frameHeight: smallFrameHeight })
        })
    }
};

export default function PuzzleGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}