const CarbsPhotos = [
    {
        key: 'carbPhoto1',
        url: './photos/carbs/carb1.png'
    }, {
        key: 'carbPhoto2',
        url: './photos/carbs/carb2.png'
    }, {
        key: 'carbPhoto3',
        url: './photos/carbs/carb3.png'
    }, {
        key: 'carbPhoto4',
        url: './photos/carbs/carb4.png'
    }, {
        key: 'carbPhoto5',
        url: './photos/carbs/carb5.png'
    }
];

export default CarbsPhotos;