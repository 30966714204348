export const RainningStoreSheets = {
    CLOUD: {
        key: 'situation5cloud',
        url: 'SocialInclusionAssets/situations/rainningStore/cloud.png',
        frameWidth: 1149 / 3,
        frameHeight: 567 / 2
    },
    DROPS: {
        key: 'situation5cloudDrops',
        url: 'SocialInclusionAssets/situations/rainningStore/cloudDrops.png',
        frameWidth: 723 / 2,
        frameHeight: 1299 / 2
    }
};

export const RainningStoreAssets = {
    Images: [],
    SpriteSheets: Object.values(RainningStoreSheets)
};