import { SceneKey } from "../utils/constants";
import WorldScene from "./worldScene";
import BoringWorkSituation from "../situations/work/boringWork";
import AggressiveCoworkerSituation from "../situations/work/agressiveCoworker";
import GoingMeetingSituation from "../situations/work/goingMeeting";
import AskingRestroomSituation from "../situations/work/askingRestroom";
import FeelingSadSituation from "../situations/work/feelingSad";
import SwitchingTaskSituation from "../situations/work/switchingTask";
import WorkingBadSituation from "../situations/work/workingBad";
import WorkingFastSituation from "../situations/work/workingFast";
import { ManWorkIdle, WomanWorkIdle, ManWorkAnims, WomanWorkAnims } from "../utils/assetsPaths/situations/work/boringWork";
import { BOY_SHEETS } from "../utils/assetsPaths/playerAnim";
import { npcAnims } from "../utils/npcFactory.js/factory";
import { Worlds } from "../utils/assetsPaths/worlds";

export default class WorkWorldScene extends WorldScene {
    constructor() {
        super({
            key: SceneKey.JOB_WORLD
        })
        this.startPoint = { x: 1531, y: 1034 }
        //this.startPoint = { x: 2549, y: 375 }
        this.worldName = "trabajo";
        this.nodeKey = "WORK";
    }

    preload() {
        super.preload();
        npcAnims.forEach(anim =>this.anims.create(anim));

    }

    createSituations() {
        this.situations = [
            new BoringWorkSituation(this),
            new AggressiveCoworkerSituation(this),
            new GoingMeetingSituation(this),
            new AskingRestroomSituation(this),
            new WorkingFastSituation(this),
            new FeelingSadSituation(this),
            new SwitchingTaskSituation(this),
            new WorkingBadSituation(this),
        ]
    }

    preload

    enablePlayerAnim() {
        const { FRONT, BACK } = this.game.ludo.character.sheets === BOY_SHEETS ?
            ManWorkAnims : WomanWorkAnims;
        const IDLE = this.game.ludo.character.sheets === BOY_SHEETS ? ManWorkIdle : WomanWorkIdle;
        const anims = this.anims;
        anims.create(FRONT);
        anims.create(BACK);
        const play = ({ key }, flipX) => () => this.mc.play(key).setFlipX(flipX);
        this.follower.onTopLeftMovement(play(BACK, false))
            .onTopRightMovement(play(BACK, true))
            .onBottomLeftMovement(play(FRONT, true))
            .onBottomRightMovement(play(FRONT, false))
            .onMovementComplete(() => this.mc.anims.stop()
                .setTexture(IDLE.FRONT.key, 0));
        this.mc.setTexture(IDLE.FRONT.key)
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.background;
        this.add.zone(3288 * scaleX, y + 1544 * scaleY, 120 * scaleX, 128 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.HOME;
                this.scene.start(SceneKey.HOME_WORLD)
            });
            this.add.zone(1072 * scaleX, y + 304 * scaleY, 112 * scaleX, 168 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.CITY;
                this.scene.start(SceneKey.CITY_WORLD);
            });
    }
}