import { generateFrameNames, generateFrameNamesByArray } from "../../../functions"

export const WorkingBadSheets = {
    BOSS_COMMAND: {
        key: "w3s8Boss",
        url: "SocialInclusionAssets/situations/workingBad/bossCommand.png",
        frameWidth: 198 / 3,
        frameHeight: 267 / 2
    },
    MAN_ACCEPT: {
        key: "w3s8manAccept",
        url: "SocialInclusionAssets/situations/workingBad/manAccept.png",
        frameWidth: 806 / 6,
        frameHeight: 754 / 5
    },
    MAN_GET_ANGRY: {
        key: "w3s8manGtAngry",
        url: "SocialInclusionAssets/situations/workingBad/manGetAngry.png",
        frameWidth: 410 / 4,
        frameHeight: 538 / 4
    },
    MAN_SWITCH: {
        key: "w3s8manSwitchTask",
        url: "SocialInclusionAssets/situations/workingBad/manSwitch.png",
        frameWidth: 806 / 6,
        frameHeight: 754 / 5
    },
    WOMAN_ACCEPT: {
        key: "w3s8womanAccept",
        url: "SocialInclusionAssets/situations/workingBad/womanAccept.png",
        frameWidth: 841 / 6,
        frameHeight: 752 / 5
    },
    WOMAN_GET_ANGRY: {
        key: "w3s8womanGtAngry",
        url: "SocialInclusionAssets/situations/workingBad/womanGetAngry.png",
        frameWidth: 410 / 4,
        frameHeight: 540 / 4
    },
    WOMAN_SWITCH: {
        key: "w3s8womanSwitchTask",
        url: "SocialInclusionAssets/situations/workingBad/womanSwitch.png",
        frameWidth: 840 / 6,
        frameHeight: 752 / 5
    },
}

export const WorkingBadAnims = {
    BOSS: {
        key: "w3s8BOss",
        frames: generateFrameNames(WorkingBadSheets.BOSS_COMMAND.key, 0, 5),
        frameRate: 2,
    },
    MAN_PAINT: {
        key: "w3s8MANPAINT",
        frames: generateFrameNamesByArray(WorkingBadSheets.MAN_ACCEPT.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 6, 7, 6, 7, 6, 7
        ]),
        frameRate: 2,
    },
    MAN_GET_ANGRY: {
        key: "w3s8MANAngry",
        frames: generateFrameNamesByArray(WorkingBadSheets.MAN_GET_ANGRY.key, [
            10, 11, 12, 12, 12, 12, 12, 13, 14, 15
        ]),
        frameRate: 2,
    },
    MAN_ACCEPT: {
        key: "w3s8MANAccept",
        frames: generateFrameNamesByArray(WorkingBadSheets.MAN_ACCEPT.key, [
            13, 13, 13, 13, 14, 14, 14, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
        ]),
        frameRate: 2,
    },
    MAN_DIALOGUE: {
        key: "w3s8MANdialogue",
        frames: generateFrameNamesByArray(WorkingBadSheets.MAN_SWITCH.key, [
            12, 12, 12, 12, 13, 13, 13, 14, 14, 14, 15, 16, 17, 18, 19, 20, 21
        ]),
        frameRate: 2,
    },
    WOMAN_PAINT: {
        key: "w3s8woMANPAINT",
        frames: generateFrameNamesByArray(WorkingBadSheets.WOMAN_ACCEPT.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 6, 7, 6, 7, 6, 7
        ]),
        frameRate: 2,
    },
    WOMAN_GET_ANGRY: {
        key: "w3s8woMANAngry",
        frames: generateFrameNamesByArray(WorkingBadSheets.WOMAN_GET_ANGRY.key, [
            10, 11, 12, 12, 12, 12, 12, 13, 14, 15
        ]),
        frameRate: 2,
    },
    WOMAN_ACCEPT: {
        key: "w3s8woMANAccept",
        frames: generateFrameNamesByArray(WorkingBadSheets.WOMAN_ACCEPT.key, [
            13, 13, 13, 13, 14, 14, 14, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
        ]),
        frameRate: 2,
    },
    WOMAN_DIALOGUE: {
        key: "w3s8woMANdialogue",
        frames: generateFrameNamesByArray(WorkingBadSheets.WOMAN_SWITCH.key, [
            12, 12, 12, 12, 13, 13, 13, 14, 14, 14, 15, 16, 17, 18, 19, 20, 21
        ]),
        frameRate: 2,
    },
}

export const Paint = {
    key: "w3s8PaintTars",
    url: "SocialInclusionAssets/situations/workingBad/paint.png"
}

export const WorkingBadAssets = {
    Images: [Paint],
    SpriteSheets: Object.values(WorkingBadSheets)
}