import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    TWO_SHAPES: 0,
    THREE_SHAPES: 1,
    FOUR_SHAPE: 2
}

export const ColorScaleSettingKey = {
    REGULAR: 0,
    GRAY: 1,
    BLUE: 2,
    RED: 3,
    GREEN: 4,
    YELLOW: 5
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: '2 formas',
            header: 'Dos formas por estímulos',
            description: 'Cada estímulo estará compuesto por dos figuras tridimensionales.',
            image: {
                key: 'twoGeomConfig',
                url: './SpaceRelationGameAssets/settings/difficulty/twoGeom.png'
            },
        },
        {
            title: '3 formas',
            header: 'Tres formas por estímulo',
            description: 'Cada estímulo estará compuesto por tres figuras tridimensionales.',
            image: {
                key: 'threeGeomConfig',
                url: './SpaceRelationGameAssets/settings/difficulty/threeGeom.png'
            },
        },
        {
            title: '4 formas',
            header: 'Cuatro formas',
            description: 'Cada estímulo estará compuesto por cuatro figuras tridimensionales.',
            image: {
                key: 'fourGeomConfig',
                url: './SpaceRelationGameAssets/settings/difficulty/fourGeom.png'
            },
        }
    ]
};

export const colorScaleSetting = {
    name: 'Contraste',
    key: SettingKey.COLOR,
    icon: PreviewIcons.COLOR,
    tileIcon: TileIcons.COLOR,
    options: [
        {
            title: 'Variado',
            header: 'Varios colores',
            description: 'Los estímulos se presentarán en varios colores.',
            image: {
                key: 'multipleColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/regularScale.png',
            }
        }, {
            title: 'Grises',
            header: 'Escala de grises',
            description: 'Todos los estímulos serán de color gris.',
            image: {
                key: 'grayColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/grayScale.png',
            }
        },
        {
            title: 'M. azul',
            header: 'Monocromático azul',
            description: 'Todos los estímulos serán de color azul.',
            image: {
                key: 'blueColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/blueScale.png',
            }
        }, {
            title: 'M. rojo',
            header: 'Monocromático rojo',
            description: 'Todos los estímulos serán de color rojo.',
            image: {
                key: 'redColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/redScale.png',
            }
        }, {
            title: 'M. verde',
            header: 'Monocromático verde',
            description: 'Todos los estímulos serán de color verde.',
            image: {
                key: 'greenColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/greenScale.png',
            }
        }, {
            title: 'M. amarillo',
            header: 'Monocromático amarillo',
            description: 'Todos los estímulos serán de color amarillo.',
            image: {
                key: 'yellowColorSetting',
                url: './SpaceRelationGameAssets/settings/contrast/yellowScale.png',
            }
        }
    ]
};

export const CustomSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...colorScaleSetting.options.map(({ image }) => image)
]

