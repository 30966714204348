import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { GoingMeetingSheets, GoingMeetingAnims, Table } from "../../utils/assetsPaths/situations/work/goingMeeting";
import { ManWorkIdle, WomanWorkIdle } from "../../utils/assetsPaths/situations/work/boringWork";

export default class GoingMeetingSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 3405, y: 675 };
        const mcPosition = { x: 3220, y: 1331 };
        super(scene, markPosition, mcPosition, Badges.COIN);
        this.instructionLabel = "Te llamaron a una reunión, cuando llegas al lugar, ¿qué haces?";
        this.optionsData = [{
            label: "Entro y guardo silencio.",
            onclick: this.runEvent(2500, () => this.beRude())
        }, {
            label: "Ingreso y saludo a cada persona.",
            onclick: this.runEvent(2500, () => this.greetAll())
        }, {
            label: "Entro y saludo de forma general.",
            onclick: this.runEvent(2500, () => this.sayHi())
        }]
    }

    preload() {
        Object.values(GoingMeetingAnims)
            .forEach(anim => this.scene.anims.create(anim))
    }

    create() {
        super.create();
        const scale = this.scene.mc.scale;
        const { scaleX, scaleY, y } = this.scene.background;
        this.table = this.scene.add.sprite(0, 0, Table.key)
            .setScale(this.scene.mc.scale * 0.4)
            .setPosition(scaleX * 3725, scaleY * 1325 + y);
        this.oldMan = this.scene.add.sprite(0, 0, GoingMeetingSheets.OLD_MAN_GIVE_HAND.key, 0)
            .setScale(scale)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 3693, scaleY * 1203 + y);

        this.sitMan = this.scene.add.sprite(0, 0, GoingMeetingSheets.SIT_MAN_GIVE_HAND.key, 0)
            .setScale(scale)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 3571, scaleY * 1501 + y)
        return this;
    }

    beRude() {
        const sheets = GoingMeetingSheets;
        this.addEvent(2000, () => {
            this.oldMan.setTexture(sheets.OLD_MAN_ANGRY.key, 2);
            this.sitMan.setTexture(sheets.SIT_MAN_ANGRY.key, 2);
            this.addEvent(1000, () => this.scene.moveMcTo(3436, 1039, () => {
                this.scene.mc.setFlipX(true)
                    .setTexture(this.mcIsBoy ? ManWorkIdle.BACK.key : WomanWorkIdle.BACK.key);
                this.addEvent(2000, () => { addDialogTween(this.scene, "Mala decisión") })
            }))
        })
    }

    greetAll() {
        const anim = this.mcIsBoy ? GoingMeetingAnims.MAN_GIVE_HAND : GoingMeetingAnims.WOMAN_GIVE_HAND;
        const sheet = this.mcIsBoy ? GoingMeetingSheets.MAN_GIVE_HAND : GoingMeetingSheets.WOMAN_GIVE_HAND;
        this.scene.moveMcTo(3435, 1435, () => {
            this.addEvent(1000, () => this.scene.mc.play(anim.key)
                .once("animationcomplete", () => {
                    this.sitMan.play(GoingMeetingAnims.SIT_MAN_GIVE_HAND.key)
                        .once("animationcomplete", () => {
                            this.addEvent(1000, () => this.scene.mc.setTexture(sheet.key, 3))
                            this.addEvent(2000, () => this.scene.mc.setTexture(sheet.key, 4))
                            this.addEvent(3000, () => {
                                this.scene.mc.setTexture(sheet.key, 0);
                                this.sitMan.setTexture(GoingMeetingSheets.SIT_MAN_GIVE_HAND.key, 0)
                            })
                            this.addEvent(4000, () => {
                                this.scene.moveMcTo(3540, 1225, () => {
                                    this.scene.mc.setFlipX(false);
                                    this.oldMan.play(GoingMeetingAnims.OLDMAN_GIVE_HAND.key)
                                        .once("animationcomplete", () => {
                                            this.addEvent(1000, () => this.scene.mc.setTexture(sheet.key, 3))
                                            this.addEvent(2000, () => this.scene.mc.setTexture(sheet.key, 4))
                                            this.addEvent(3000, () => {
                                                this.scene.mc.setTexture(sheet.key, 0);
                                                this.oldMan.setTexture(GoingMeetingSheets.OLD_MAN_GIVE_HAND.key, 0);
                                            })
                                            this.addEvent(4000, () => this.scene.moveMcTo(3436, 1039, () => {
                                                this.scene.mc.setFlipX(true)
                                                    .setTexture(this.mcIsBoy ? ManWorkIdle.BACK.key : WomanWorkIdle.BACK.key);
                                                this.addEvent(2000,
                                                    () => { addDialogTween(this.scene, "Buena decisión", this.badge) })
                                            }))

                                        })
                                })
                            })
                        })
                }))
        })
    }

    sayHi() {
        const anim = this.mcIsBoy ? GoingMeetingAnims.MAN_SAYHI : GoingMeetingAnims.WOMAN_SAYHI;
        this.scene.mc.play(anim.key)
            .once("animationcomplete", this.runEvent(2000, () => {
                this.scene.moveMcTo(3436, 1039, () => {
                    this.scene.mc.setFlipX(true)
                        .setTexture(this.mcIsBoy ? ManWorkIdle.BACK.key : WomanWorkIdle.BACK.key);
                    this.addEvent(2000,
                        () => { addDialogTween(this.scene, "Buena decisión", this.badge) })
                })
            }))
    }

    start() {
        const mc = this.scene.mc;
        mc.setFlipX(false);
        this.addEvent(2000, () => this.oldMan
            .setTexture(GoingMeetingSheets.OLD_MAN_GIVE_HAND.key, 1)
        )
        this.addEvent(3000, () => this.sitMan
            .setTexture(GoingMeetingSheets.SIT_MAN_GIVE_HAND.key, 2))
        this.addEvent(4000, () => this.showHint())
    }

}