import React from 'react';
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { difficultySetting, rotationSetting } from "./utils/settings";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import { LightsAndShadowsGameAssets } from "./utils/assets";
import timeSetting from "../HabCogGame/utils/settingsConstants/timeSetting";

const data = {
    name: 'Luces y sombras',
    sessionAssets: LightsAndShadowsGameAssets,
    sessionScene: SessionScene,
    logoUrl: './LightsAndShadowsGameAssets/logo.png',
    iconUrl: './LightsAndShadowsGameAssets/icon.png',
    thumbnail: './LightsAndShadowsGameAssets/thumbnail.png',
    getHint: () => 'Toca la imagen que corresponde a la sombra',
    userManual: 'El estímulo patrón es una silueta difuminada. Entre varias imágenes, el ' +
        'usuario debe seleccionar la fotografía que encaja con la silueta. En ' +
        'ajustes es posible definir opciones relacionadas con: grado de distorsión ' +
        'de la silueta, rotación de la imagen, tamaño y número de estímulos, ' +
        'señalización de errores y tiempo de duración de la sesión. ',
    settings: [
        difficultySetting,
        rotationSetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function lightsAndShadowsGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}