import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { PlayingCardsSheets } from "../../utils/assetsPaths/situations/home/playingCards";
import { addDialogTween, generateFrameNames } from "../../utils/functions";

export default class PlayingCardsSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1411, y: 340 };
        const mcPosition = { x: 1862, y: 530 };
        super(scene, markPosition, mcPosition, Badges.GAME);
        this.instructionLabel = "Has perdido un juego de cartas, ¿qué haces?";
        this.optionsData = [{
            label: "Me enojo y dejo de jugar.",
            onclick: this.runEvent(2500, () => this.getAngry())
        }, {
            label: "Felicito al ganador y sigo jugando.",
            onclick: this.runEvent(2500, () => this.congratNPC())
        }, {
            label: "Sigo jugando porque es divertido.",
            onclick: this.runEvent(2500, () => this.keepPlaying())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(NPCAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        this.npc = createNPC(this.scene);
        return this;
    }

    getAngry() {
        const { CARD_ANGRY } = this.scene.game.ludo.character.sheets;
        const { ANGRY_LOSE_CARD } = this.scene.game.ludo.character.anims;
        const npcKey = PlayingCardsSheets.NPC_BAD.key;
        this.scene.mc.setTexture(CARD_ANGRY.key, 13);
        this.addEvent(1500, () => this.npc.setTexture(npcKey, 12))
        this.addEvent(2250, () => this.scene.mc.play(ANGRY_LOSE_CARD.key)
            .once("animationcomplete", this.runEvent(2000, () => {
                this.npc.setTexture(npcKey, 13);
                this.addEvent(2500, () => addDialogTween(this.scene, "Fuiste mal perdedor"));
            })))
    }

    congratNPC() {
        const { CARD_CONGRAT } = this.scene.game.ludo.character.sheets;
        const { CONGRAT_CARD } = this.scene.game.ludo.character.anims;
        const npcKey = PlayingCardsSheets.NPC_GOOD.key;
        this.scene.mc.setTexture(CARD_CONGRAT.key, 13);
        this.addEvent(1500, () => this.npc.setTexture(npcKey, 11))
        this.addEvent(2250, () => this.scene.mc.play(CONGRAT_CARD.key)
            .once("animationcomplete", this.runEvent(2500, () => {
                this.npc.setTexture(npcKey, 12);
                this.addEvent(2500,
                    () => addDialogTween(this.scene, "Fuiste buen perdedor", this.badge));
            })
            ))
    }

    keepPlaying() {
        const { CARD_ACCEPT } = this.scene.game.ludo.character.anims;
        const npcKey = PlayingCardsSheets.NPC_GOOD.key;
        const mc = this.scene.mc;
        mc.setTexture(CARD_ACCEPT.key, 13);
        this.addEvent(1500, () => this.npc.setTexture(npcKey, 11))
        this.addEvent(2250, () => mc.play(CARD_ACCEPT.key)
            .once("animationcomplete", this.runEvent(2500, () => {
                this.npc.setTexture(npcKey, 12);
                this.addEvent(2500,
                    () => addDialogTween(this.scene, "Fuiste buen perdedor", this.badge));
            })
            ))
    }

    reboot(onComplete) {
        const npcKey = PlayingCardsSheets.NPC_GOOD.key;
        this.npc.setTexture(npcKey, 0);
        this.scene.follower.emit("onMovementComplete");
        this.scene.mc.setFlipX(true);
        onComplete();
    }

    start() {
        const mc = this.scene.mc;
        mc.setFlipX(true);
        const { TAKE_CARD_SEAT, PLAY_CARDS } = this.scene.game.ludo.character.anims;
        this.addEvent(1500, () => {
            mc.setFlipX(false);
            mc.play(TAKE_CARD_SEAT.key)
            this.addEvent(1500, () => {
                this.npc.play(NPCAnims.PLAY.key)
                    .once("animationcomplete", this.runEvent(1500, () => this.showHint()))
                this.addEvent(1000, () => mc.play(PLAY_CARDS.key))
            })
        })
    }
}

const createNPC = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    return scene.add.sprite(0, 0, PlayingCardsSheets.NPC_GOOD.key, 0)
        .setScale(scene.mc.scale)
        .setX(1750 * scaleX)
        .setY(450 * scaleY + y)
        .setDepth(scene.mc.depth + 1)
}

const NPCAnims = {
    PLAY: {
        key: "NPCCardPlay",
        frames: generateFrameNames(PlayingCardsSheets.NPC_GOOD.key, 1, 10),
        frameRate: 1
    }
}
