import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { BuyingFruitsSheets } from "../../utils/assetsPaths/situations/city/buyingFruits";
import { generateFrameNames, addDialogTween } from "../../utils/functions";

class BuyingFruitsSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 423, y: 1362 };
        const mcPosition = { x: 775, y: 1400 };
        super(scene, markPosition, mcPosition, Badges.MONEY);
        this.instructionLabel = "Compraste una manzana y el vendedor no recuerda si ya pagaste, ¿qué haces?";
        this.optionsData = [{
            label: 'Le digo que ya le pagué y me voy.',
            onclick: this.runEvent(2000, () => this.lie())
        }, {
            label: 'Recuerdo\nque no pagué, le entrego el dinero y continúo mi camino.',
            onclick: this.runEvent(2000, () => this.rememberPay())
        }, {
            label: 'Veo que tengo la cantidad de dinero que llevé, le pago y me voy.',
            onclick: this.runEvent(2000, () => this.checkPay())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(NPCAnims).forEach(animation => anims.create(animation));
    }

    create() {
        super.create();
        this.salesMan = createSalesMan(this.scene);
        return this;
    }

    lie() {
        const { REFUSE_PAY } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(REFUSE_PAY.key)
            .once("animationcomplete", this.runEvent(2500, () => {
                addDialogTween(this.scene, "Has mentido");
            }))
    }

    rememberPay() {
        const { REMEMBER_PAY, FINISH_EAT } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(REMEMBER_PAY.key)
            .once("animationcomplete", this.runEvent(2500, () => {
                this.salesMan.setTexture(BuyingFruitsSheets.SalesMan.key, 10);
                this.addEvent(2000, () => {
                    this.salesMan.setTexture(BuyingFruitsSheets.SalesMan.key, 0);
                    this.scene.mc.play(FINISH_EAT.key).once("animationcomplete", this.runEvent(2000, () => {
                        addDialogTween(this.scene, "Pagaste por el caramelo", this.badge);
                    }))
                })
            }))
    }

    checkPay() {
        const { CHECK_PAY, FINISH_EAT } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(CHECK_PAY.key)
            .once("animationcomplete", this.runEvent(2500, () => {
                this.salesMan.setTexture(BuyingFruitsSheets.SalesMan.key, 10);
                this.addEvent(2000, () => {
                    this.salesMan.setTexture(BuyingFruitsSheets.SalesMan.key, 0);
                    this.scene.mc.play(FINISH_EAT.key).once("animationcomplete", this.runEvent(2000, () => {
                        addDialogTween(this.scene, "Pagaste por el caramelo", this.badge);
                    }))
                })
            }))
    }

    start() {
        this.scene.mc.setFlipX(true);
        const { EXTEND_HAND, EAT_CANDY } = this.scene.game.ludo.character.anims;
        const { REFUSE_PAY } = this.scene.game.ludo.character.sheets;
        const time = 250
        this.addEvent(time, () => {
            this.salesMan.play(NPCAnims.OFFER.key)
                .once("animationcomplete", this.runEvent(time, () => {
                    this.scene.mc.play(EXTEND_HAND.key)
                        .once("animationcomplete", this.runEvent(time, () => {
                            this.scene.mc.setTexture(REFUSE_PAY.key, 3);
                            this.salesMan.setTexture(BuyingFruitsSheets.SalesMan.key, 0);
                            this.addEvent(time, () => {
                                this.scene.mc.play(EAT_CANDY.key)
                                    .once("animationcomplete", this.runEvent(1000, () => {
                                        this.salesMan.play(NPCAnims.REMEMBER.key)
                                            .once("animationcomplete", this.runEvent(1000, () => {
                                                this.scene.mc.setTexture(REFUSE_PAY.key, 5);
                                                this.addEvent(2000, () => this.showHint())
                                            }))
                                    }))
                            })
                        }))
                }))
        })
    }
}


const createSalesMan = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    return scene.add.sprite(0, 0, BuyingFruitsSheets.SalesMan.key, 0)
        .setScale(scene.mc.scale)
        .setOrigin(0.5, 0.9)
        .setX(645 * scaleX)
        .setY(1430 * scaleY + y)
}

const NPCAnims = {
    OFFER: {
        key: 'offercandySit8',
        frames: generateFrameNames(BuyingFruitsSheets.SalesMan.key, 0, 3),
        frameRate: 3
    },
    REMEMBER: {
        key: "rememberBuyingSalesman",
        frames: generateFrameNames(BuyingFruitsSheets.SalesMan.key, 4, 9),
        frameRate: 1
    }
}
export default BuyingFruitsSituation;