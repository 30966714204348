import Phaser from 'phaser';
import { scaleImage } from '../utils/resize';
import { GameFont } from '../utils/constants';
import { Btns } from '../utils/assetsPaths/btn';

export default class CharacterPic extends Phaser.GameObjects.Container {

    constructor(scene, x, y, texture, label) {
        super(scene, x, y);
        this.background = new Phaser.GameObjects.Image(scene, 0, 0, texture.key);
        this.border = new Phaser.GameObjects.Image(scene, 0, 0, Btns.CHARACTER_BORDER.key).setAlpha(0);
        this.label = new Phaser.GameObjects.Text(scene, 0, 0, label, CharacterPicTextStyle)
            .setOrigin(0.5)
        this.add([
            this.background,
            this.label,
            this.border
        ]);
        this.isAvailable = true;
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier)
        this.border.setScale(this.background.scale);
        this.label.setFontSize(`${this.background.displayWidth / 8}px`)
            .setY(this.background.getBottomCenter().y + this.label.displayHeight * 0.6);
        return this;
    }

    setInteractive() {
        this.background.setInteractive({ useHandCursor: true });
        this.setEvents();
        return this;
    }

    disableInteractive() {
        this.isAvailable = false;
        return this;
    }

    enableInteractive() {
        this.isAvailable = true;
        this.background.emit("pointerout")
        return this;
    }

    setEvents() {
        this.background.on('pointerover', () => {
            this.border.setAlpha(1);
        });
        this.background.on('pointerout', () => {
            this.border.setAlpha(0);
        });
    }

    increaseSize() {
        this.addScaleTween(1.4);
    }

    decreaseSize() {
        this.addScaleTween(1);
    }

    addScaleTween(scale) {
        if (this.isAvailable)
            this.scene.tweens.add({
                targets: this,
                scale,
                duration: 125,
                ease: 'Power3'
            });
    }

    onPointerover(callback) {
        this.background.on('pointerover', callback);
        return this;
    }

    onclick(callback) {
        this.background.on('pointerdown', callback);
        return this;
    }
}


const CharacterPicTextStyle = {
    fontFamily: GameFont,
    color: '#437A9D',
    align: 'center'
}