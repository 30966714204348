import { generateFrameNames, generateFrameNamesByArray } from "../../../functions"
import { ManWorkIdle, WomanWorkIdle } from "./boringWork"

export const GoingMeetingSheets = {
    OLD_MAN_ANGRY: {
        key: "w3s3oldManAngry",
        url: "SocialInclusionAssets/situations/goingMeeting/oldManAngry.png",
        frameWidth: 194 / 2,
        frameHeight: 137 / 2
    },
    OLD_MAN_GIVE_HAND: {
        key: "w3s3oldManGiveHand",
        url: "SocialInclusionAssets/situations/goingMeeting/oldManGiveHand.png",
        frameWidth: 374 / 3,
        frameHeight: 139 / 2
    },
    SIT_MAN_ANGRY: {
        key: "w3s3oldsitManAngry",
        url: "SocialInclusionAssets/situations/goingMeeting/sitManAngry.png",
        frameWidth: 224 / 2,
        frameHeight: 242 / 2
    },
    SIT_MAN_GIVE_HAND: {
        key: "w3s3oldsitManGiveHand",
        url: "SocialInclusionAssets/situations/goingMeeting/sitManGiveHand.png",
        frameWidth: 336 / 3,
        frameHeight: 362 / 3
    },
    MAN_GIVE_HAND: {
        key: "w3s3ManGiveHand",
        url: "SocialInclusionAssets/situations/goingMeeting/manGiveHand.png",
        frameWidth: 179 / 3,
        frameHeight: 262 / 2
    },
    MAN_HI: {
        key: "w3s3ManHi",
        url: "SocialInclusionAssets/situations/goingMeeting/manHi.png",
        frameWidth: 172 / 3,
        frameHeight: 389 / 3
    },
    WOMAN_GIVE_HAND: {
        key: "w3s3woManGiveHand",
        url: "SocialInclusionAssets/situations/goingMeeting/womanGiveHand.png",
        frameWidth: 196 / 3,
        frameHeight: 274 / 2
    },
    WOMAN_HI: {
        key: "w3s3woManHi",
        url: "SocialInclusionAssets/situations/goingMeeting/womanHi.png",
        frameWidth: 191 / 3,
        frameHeight: 400 / 3
    },
}

export const Table = {
    key: "w3s3Table",
    url: "SocialInclusionAssets/situations/goingMeeting/table.png"
}

export const GoingMeetingAnims = {
    OLDMAN_ANGRY: {
        key: "w3s3OldmanisAngry",
        frames: generateFrameNames(GoingMeetingSheets.OLD_MAN_ANGRY.key, 0, 2),
        frameRate: 2,
    },
    OLDMAN_GIVE_HAND: {
        key: "w3s3OldmanisGivingHand",
        frames: generateFrameNames(GoingMeetingSheets.OLD_MAN_GIVE_HAND.key, 0, 3),
        frameRate: 2,
    },
    SIT_MAN_ANGRY: {
        key: "w3s3SITMANISANGRY",
        frames: generateFrameNames(GoingMeetingSheets.SIT_MAN_ANGRY.key, 0, 3),
        frameRate: 2,
    },
    SIT_MAN_GIVE_HAND: {
        key: "w3s3SITMANisGivingHand",
        frames: generateFrameNames(GoingMeetingSheets.SIT_MAN_GIVE_HAND.key, 2, 4),
        frameRate: 2,
    },
    MAN_SAYHI: {
        key: "w3s3manSayHi",
        frames: generateFrameNamesByArray(GoingMeetingSheets.MAN_HI.key, [
            0, 1, 2, 3, 4, 3, 2, 3, 4, 5, 6
        ]),
        frameRate: 2,
    },
    MAN_GIVE_HAND: {
        key: "w3s3manGiveHand",
        frames: generateFrameNames(GoingMeetingSheets.MAN_GIVE_HAND.key, 0, 2),
        frameRate: 2,
    },
    WOMAN_SAYHI: {
        key: "w3s3womanSayHi",
        frames: generateFrameNamesByArray(GoingMeetingSheets.WOMAN_HI.key, [
            0, 1, 2, 3, 4, 3, 2, 3, 4, 5, 6
        ]),
        frameRate: 2,
    },
    WOMAN_GIVE_HAND: {
        key: "w3s3womanGiveHand",
        frames: generateFrameNames(GoingMeetingSheets.WOMAN_GIVE_HAND.key, 0, 2),
        frameRate: 2,
    },
}

export const GoingMeetingAssets = {
    Images: [
        ...Object.values(ManWorkIdle), 
        ...Object.values(WomanWorkIdle),
        Table
    ],
    SpriteSheets: Object.values(GoingMeetingSheets)
}