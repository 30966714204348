import Phaser from "phaser";
import { GameFont } from "../utils/constants";
import { LoadBtnProgress, LoadBtnBackground } from "../utils/assetsPaths/splash";
import { scaleImage } from "../utils/resize";
const Image = Phaser.GameObjects.Image;

export default class LoadBar {

    constructor(scene, x, y) {
        const { add } = scene;
        this.background = add.image(x, y, LoadBtnBackground.key);
        this.createMask();
        this.createText();
        this.progress = 0;
    }

    createMask() {
        const { scene, x, y, displayWidth } = this.background;
        const texture = LoadBtnProgress
        this.loadMask = new Image(scene, x - displayWidth, y, texture.key).setOrigin(1, 0.5)

        this.completeBackground = scene.add.image(x, y, texture.key)
        this.completeBackground.setMask(this.loadMask.createBitmapMask());
    }

    createText() {
        const { scene, displayHeight } = this.background;
        const vec2 = this.background.getCenter();
        this.label = scene.add.text(vec2.x, vec2.y, 'Cargando', LoadBarTextStyle)
            .setOrigin(0.5)
            .setFontSize(`${displayHeight * 0.5}px`);
    }

    setProgress(progress) {
        this.progress = progress;
        if (progress < 1) {
            const { displayWidth } = this.background;
            const { x, y } = this.background.getLeftCenter();
            this.loadMask.setPosition(x + displayWidth * progress, y);
            this.label.setText(`Cargando ${Math.floor(progress * 100)}%`);
        } else {
            const { x, y } = this.background;
            this.completeBackground
                .clearMask(true)
                .setPosition(x, y);
            this.label.setText('Completado');
        }
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding = 0, scaleMultiplier = 1) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.label.setFontSize(`${this.background.displayHeight * 0.5}px`);
        this.completeBackground.setScale(this.background.scale);
        this.loadMask.setScale(this.background.scale);
    }

    setPosition(x, y) {
        this.background.setPosition(x, y);
        this.label.setPosition(x, y);
        this.completeBackground.setPosition(x, y);
        const vect2 = this.background.getLeftCenter();
        this.loadMask.setPosition(vect2.x + this.background.displayWidth * this.progress, y);
    }
}

const LoadBarTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}