export const imageStructure = {

    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 250,
    objectHeight: 350,
    objectPositionX: 225,
    objectPositionY: 800,

}
export const solutionStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 800,
    objectHeight: 600,
    objectPositionX: 1400,
    objectPositionY: 600,
}
export const blankStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 250,
    objectHeight: 350,
    objectPositionX: 225,
    objectPositionY: 300,
}