export const outlinedGeomTextures = {
    BLUE_SQUARE: {
        key: 'blueSquareOutlineTexture',
        name: 'cuadrado',
        color: 'azul',
        url: './HabCogGameAssets/outlinedGeomTextures/blueSquareOutline.png'
    },
    BLUE_TRIANGLE: {
        key: 'blueTriangleOutlineTexture',
        name: 'triángulo',
        color: 'azul',
        url: './HabCogGameAssets/outlinedGeomTextures/blueTriangleOutline.png'
    },
    BLUE_CIRCLE: {
        key: 'blueCircleOutlineTexture',
        name: 'círculo',
        color: 'azul',
        url: './HabCogGameAssets/outlinedGeomTextures/blueCircleOutline.png'
    },
    BLUE_STAR: {
        key: 'blueStarOutlineTexture',
        name: 'estrella',
        color: 'azul',
        url: './HabCogGameAssets/outlinedGeomTextures/blueStarOutline.png'
    },
    RED_SQUARE: {
        key: 'redSquareOutlineTexture',
        name: 'cuadrado',
        color: 'rojo',
        url: './HabCogGameAssets/outlinedGeomTextures/redSquareOutline.png'
    },
    RED_TRIANGLE: {
        key: 'redTriangleOutlineTexture',
        name: 'triángulo',
        color: 'rojo',
        url: './HabCogGameAssets/outlinedGeomTextures/redTriangleOutline.png'
    },
    RED_CIRCLE: {
        key: 'redCircleOutlineTexture',
        name: 'círculo',
        color: 'rojo',
        url: './HabCogGameAssets/outlinedGeomTextures/redCircleOutline.png'
    },
    RED_STAR: {
        key: 'redStarOutlineTexture',
        name: 'estrella',
        color: 'rojo',
        url: './HabCogGameAssets/outlinedGeomTextures/redStarOutline.png'
    },
    YELLOW_SQUARE: {
        key: 'yellowSquareOutlineTexture',
        name: 'cuadrado',
        color: 'amarillo',
        url: './HabCogGameAssets/outlinedGeomTextures/yellowSquareOutline.png'
    },
    YELLOW_TRIANGLE: {
        key: 'yellowTriangleOutlineTexture',
        name: 'triángulo',
        color: 'amarillo',
        url: './HabCogGameAssets/outlinedGeomTextures/yellowTriangleOutline.png'
    },
    YELLOW_CIRCLE: {
        key: 'yellowCircleOutlineTexture',
        name: 'círculo',
        color: 'amarillo',
        url: './HabCogGameAssets/outlinedGeomTextures/yellowCircleOutline.png'
    },
    YELLOW_STAR: {
        key: 'yellowStarOutlineTexture',
        name: 'estrella',
        color: 'amarillo',
        url: './HabCogGameAssets/outlinedGeomTextures/yellowStarOutline.png'
    },
    GREEN_SQUARE: {
        key: 'greenSquareOutlineTexture',
        name: 'cuadrado',
        color: 'verde',
        url: './HabCogGameAssets/outlinedGeomTextures/greenSquareOutline.png'
    },
    GREEN_TRIANGLE: {
        key: 'greenTriangleOutlineTexture',
        name: 'triángulo',
        color: 'verde',
        url: './HabCogGameAssets/outlinedGeomTextures/greenTriangleOutline.png'
    },
    GREEN_CIRCLE: {
        key: 'greenCircleOutlineTexture',
        name: 'círculo',
        color: 'verde',
        url: './HabCogGameAssets/outlinedGeomTextures/greenCircleOutline.png'
    },
    GREEN_STAR: {
        key: 'greenStarOutlineTexture',
        name: 'estrella',
        color: 'verde',
        url: './HabCogGameAssets/outlinedGeomTextures/greenStarOutline.png'
    },
    WHITE_SQUARE: {
        key: 'whiteSquareOutlineTexture',
        name: 'cuadrado',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteSquareOutline.png'
    },
    WHITE_TRIANGLE: {
        key: 'whiteTriangleOutlineTexture',
        name: 'triángulo',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteTriangleOutline.png'
    },
    WHITE_CIRCLE: {
        key: 'whiteCircleOutlineTexture',
        name: 'círculo',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteCircleOutline.png'
    },
    WHITE_STAR: {
        key: 'whiteStarOutlineTexture',
        name: 'estrella',
        color: 'blanco',
        url: './HabCogGameAssets/outlinedGeomTextures/whiteStarOutline.png'
    }
};

export const OutlinedGeomsAssets = Object.values(outlinedGeomTextures);