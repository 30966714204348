export const TableAsset = {
    key: "world2Situation2Table",
    url: "SocialInclusionAssets/situations/disgustingFood/table.png"
}


export const DisgustingFoodSheets = {
    MAN_ACCEPT_FOOD:{
        key: "world2Situation2manAcceptFood",
        url: "SocialInclusionAssets/situations/disgustingFood/manAcceptFood.png",
        frameWidth: 929 / 4,
        frameHeight: 790 / 4
    },
    MAN_REFUSE_FOOD:{
        key: "world2Situation2manRefuseFood",
        url: "SocialInclusionAssets/situations/disgustingFood/manRefuseFood.png",
        frameWidth: 929 / 4,
        frameHeight: 593 / 3
    },
    MAN_THANK_FOOD:{
        key: "world2Situation2manThankFood",
        url: "SocialInclusionAssets/situations/disgustingFood/manThankFood.png",
        frameWidth: 929 / 4,
        frameHeight: 791 / 4
    },
    WOMAN_ACCEPT_FOOD:{
        key: "world2Situation2womanAcceptFood",
        url: "SocialInclusionAssets/situations/disgustingFood/womanAcceptFood.png",
        frameWidth: 959 / 4,
        frameHeight: 791 / 4
    },
    WOMAN_REFUSE_FOOD:{
        key: "world2Situation2womanRefuseFood",
        url: "SocialInclusionAssets/situations/disgustingFood/womanRefuseFood.png",
        frameWidth: 959 / 4,
        frameHeight: 593 / 3
    },
    WOMAN_THANK_FOOD:{
        key: "world2Situation2womanThankFood",
        url: "SocialInclusionAssets/situations/disgustingFood/womanThankFood.png",
        frameWidth: 959 / 4,
        frameHeight: 791 / 4
    }

}

export const DisgustingFoodAssets = {
    Images: [TableAsset],
    SpriteSheets: Object.values(DisgustingFoodSheets)
}