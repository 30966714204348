import phaser from 'phaser'
import buttonComponent from '../Button/button'
import { listComponentStructure } from './listComponentStructure'
import { buttonPressAnimation, mouseOverAnimation } from '../../Functions/AnimatedFunctions'
import { VoiceManager } from '../../Plug-ins/voiceManager'



export default class listComponent extends phaser.GameObjects.Container {

    constructor(scene, x, y, width, heigth, title, image, description, id, lamines) {
        super(scene, x, y)
        this.storyImage = null
        this.storyTitle = null
        this.storyId = id
        this.storyDescription = null
        this.button = null
        this.lamines = null
        this.scene = scene
        this.generated(scene, width, heigth, title, image, description, id, lamines)
    }

    generated(scene, width, heigth, title, image, description, id, lamines) {
        //voice
        var voice = new VoiceManager('Paulina')
        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);
        //outline
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, 'rectangleBoardOutline').setVisible(false)
        this.outline.setDisplaySize(width * 1.1, heigth * 1.05);
        //Title
        this.storyTitle = new phaser.GameObjects.Text(this.scene, listComponentStructure.listTitle.scale.objectPositionX, listComponentStructure.listTitle.scale.objectPositionY, title, listComponentStructure.listTitle.style)
        this.storyTitle.setOrigin(0.5)
        this.tittleBackground = new phaser.GameObjects.Image(scene, listComponentStructure.listTitle.scale.objectPositionX, listComponentStructure.listTitle.scale.objectPositionY, 'blackBackground')
        this.tittleBackground.setDisplaySize(listComponentStructure.listTitle.scale.objectWidth, listComponentStructure.listTitle.scale.objectHeight);
        //Image
        //cardComponentStructure.cardImage.style.fontSize = ScaleFunctionText(this.scene.sys.canvas.width, this.scene.sys.canvas.height, cardComponentStructure.cardImage.style.fontSize)
        this.storyImage = new phaser.GameObjects.Image(this.scene, listComponentStructure.listImage.scale.objectPositionX, listComponentStructure.listImage.scale.objectPositionY, image)
        this.storyImage.setDisplaySize(listComponentStructure.listImage.scale.objectWidth, listComponentStructure.listImage.scale.objectHeight)
        //Description
        var smalldescription = ''
        if (description.length < 91) {
            smalldescription = description.slice(0, 90)
        } else { smalldescription = description.slice(0, 90) + '......' }
        this.descriptionBackground = new phaser.GameObjects.Image(scene, listComponentStructure.listDescription.scale.objectPositionX, listComponentStructure.listDescription.scale.objectPositionY, 'blackBackground')
        this.descriptionBackground.setDisplaySize(listComponentStructure.listDescription.scale.objectWidth, listComponentStructure.listDescription.scale.objectHeight);
        this.storyDescription = new phaser.GameObjects.Text(this.scene, listComponentStructure.listDescription.scale.objectPositionX, listComponentStructure.listDescription.scale.objectPositionY, smalldescription, listComponentStructure.listDescription.style)
        this.storyDescription.setWordWrapWidth(listComponentStructure.listDescription.scale.objectWidth);
        this.storyDescription.setOrigin(0.5)
        //dificult
        var dificult = 'N/A'
        if (lamines < 6) {
            dificult = 'Fácil'
        } else {
            if (lamines < 10) {
                dificult = 'Normal'

            }
            else {
                dificult = 'Difícil'
            }
        }
        this.storyDificult = new phaser.GameObjects.Text(this.scene, listComponentStructure.listDificult.scale.objectPositionX, listComponentStructure.listDificult.scale.objectPositionY, 'Dificultad: ' + dificult, listComponentStructure.listDificult.style)
        this.storyDificult.setOrigin(0.5)

        //Lamine Number
        this.storylamines = new phaser.GameObjects.Text(this.scene, listComponentStructure.listLamines.scale.objectPositionX, listComponentStructure.listLamines.scale.objectPositionY, 'Láminas: ' + lamines, listComponentStructure.listLamines.style)
        this.storylamines.setOrigin(0.5)

        //button
        this.button = new buttonComponent(this.scene, listComponentStructure.listButton)
        this.button.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.scene.scene.start('Vocabulary', {
                historyid: id,
                historyname: title
            })
        });
        //misselaneus background
        this.misselaneusBackground = new phaser.GameObjects.Image(scene, 0, listComponentStructure.miselaneus.scale.objectPositionY, 'blackBackground')
        this.misselaneusBackground.setDisplaySize(listComponentStructure.miselaneus.scale.objectWidth, listComponentStructure.miselaneus.scale.objectHeight);

        this.click = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.click.setDisplaySize(width, heigth);
        this.click.setAlpha(0.001)
        this.click.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            voice.speak('')
            this.scene.sound.play('clickSound')
            buttonPressAnimation(scene, this)
        }).on('pointerup', () => {
            this.scene.scene.start('Vocabulary', {
                historyid: id,
                historyname: title,
                historydescription: description
            })
        }).on('pointerover', () => {
            mouseOverAnimation(scene, this, 1.05)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimation(scene, this, 1)
            this.outline.setVisible(false)
        })


        this.add([this.outline, this.background, this.storyImage, this.tittleBackground, this.storyTitle,
        this.descriptionBackground, this.storyDescription, this.misselaneusBackground,
        this.button, this.storyDificult, this.storylamines, this.click])
        this.setSize(width, heigth)

    }
}
