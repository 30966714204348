import Phaser from 'phaser';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
import InstructionTextComponent from '../../GameObjects/InstructionTextComponent/InstructionTextComponent';
import { InstructionText, imageStructure, RigthButtonStructure, LeftButtonStructure, playButton } from './SequenceStructure';
import mediumCardComponentComponent from '../../GameObjects/MediumCardComponent/MediumCardComponent';
import { mediumCardComponentStructure } from '../../GameObjects/MediumCardComponent/MediumCardComponentStructure'
import sequences from '../../Services/sequences'
import { buttonPressAnimation, scaleAnimation, rotationAnimation, mouseOverAnimatioArrows } from '../../Functions/AnimatedFunctions';
import { VoiceManager } from '../../Plug-ins/voiceManager';



export default class SequenceScene extends Phaser.Scene {
    constructor() {
        super('Sequence');
    }
    init(data) {
        this.historyID = data.historyid;
        this.historyName = data.name;
    }

    preload() {

        this.cardScale = mediumCardComponentStructure
        this.InstructionTextScale = this.clone(InstructionText)
        this.scaleImage = this.clone(imageStructure)
        this.rigthButtonScale = this.clone(RigthButtonStructure)
        this.leftButtonScale = this.clone(LeftButtonStructure)
        this.playButtonScale = this.clone(playButton)

        //Get sequence 
        this.load.json('Sequence' + this.historyID, sequences.getSequences(this.historyID))
        this.sequence = []
        this.load.on('filecomplete-json-Sequence' + this.historyID, (key, type, data) => {
            console.log("cargado JSON ")
            for (var i = 0; i < data.length; i++) {
                this.sequence.push(data[i])
                this.load.image('Sequence' + data[i].id, data[i].image);
            }

        }
        )
        //loading image
        var spiner = new Phaser.GameObjects.Image(this, this.sys.canvas.width / 2, this.sys.canvas.height / 1.5, 'spiner')
        spiner.setDisplaySize(this.sys.canvas.height / 3, this.sys.canvas.height / 3)
        spiner.setOrigin(0.5)
        rotationAnimation(this, spiner)
        this.load.on('progress', () => {
            this.add.existing(spiner)
        }).on('complete', function () {
            spiner.destroy()
        });



        //Resize functions
        this.saveScaleState()


        //Scale this scene
        this.InstructionTextScale = scaleGameObject(this, this.InstructionTextScale)
        this.scaleImage = scaleGameObject(this, this.scaleImage)
        this.leftButtonScale = scaleGameObject(this, this.leftButtonScale)
        this.rigthButtonScale = scaleGameObject(this, this.rigthButtonScale)
        this.playButtonScale = scaleGameObject(this, this.playButtonScale)
        //Scale cardComponent
        this.cardScale.cardTitle.scale = scaleGameObject(this, this.cardScale.cardTitle.scale)
        this.cardScale.cardTitle.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardTitle.style.fontSize)
        this.cardScale.cardImage.scale = scaleGameObject(this, this.cardScale.cardImage.scale)
        this.cardScale.cardDescription.scale = scaleGameObject(this, this.cardScale.cardDescription.scale)
        this.cardScale.cardDescription.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.cardScale.cardDescription.style.fontSize)
        this.cardScale.cardButton.scale = scaleGameObject(this, this.cardScale.cardButton.scale)
        //preload Background
        this.drawPreloadBackground()
    }
    create() {
        //voice
        var voice = new VoiceManager('Paulina')
        this.sequence = this.cache.json.get('Sequence' + this.historyID);


        //Card element builder
        var rigthButton = this.add.image(this.rigthButtonScale.objectPositionX, this.rigthButtonScale.objectPositionY, 'TriangleButton')
        rigthButton.setDisplaySize(this.rigthButtonScale.objectWidth, this.rigthButtonScale.objectHeight)
        var leftButton = this.add.image(this.leftButtonScale.objectPositionX, this.leftButtonScale.objectPositionY, 'TriangleButtonInv')
        leftButton.setDisplaySize(this.leftButtonScale.objectWidth, this.leftButtonScale.objectHeight)
        leftButton.setAlpha(0.4)
        var lamineArray = []
        for (var i = 0; i < this.sequence.length; i++) {
            const lamine = new mediumCardComponentComponent(this,
                this.scaleImage.objectPositionX,
                this.scaleImage.objectPositionY,
                this.scaleImage.objectWidth,
                this.scaleImage.objectHeight,
                this.sequence[i].name,
                'Sequence' + this.sequence[i].id,
                this.sequence[i].description,
                this.sequence[i].id);
            lamineArray.push(lamine)
        }
        var imageDisplayedPosition = 0
        for (i = 0; i < lamineArray.length; i++) {
            this.add.existing(lamineArray[i])
            if (i > 0) {
                lamineArray[i].setVisible(false)
            }

        }
        this.instructions.setText(this.sequence[imageDisplayedPosition].description)

        rigthButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            if (imageDisplayedPosition < lamineArray.length - 1) {
                buttonPressAnimation(this, rigthButton)
                this.sound.play('clickSound')
                lamineArray[imageDisplayedPosition].setVisible(false)
                imageDisplayedPosition++
                lamineArray[imageDisplayedPosition].setVisible(true)
                this.instructions.setText(this.sequence[imageDisplayedPosition].description)
                if (imageDisplayedPosition === lamineArray.length - 1) {
                    leftButton.setAlpha(0.4)
                    playButton.setVisible(true)
                    //play button apear
                    scaleAnimation(this, playButton, 0, 0, playScalex, playScaley, 0.5, 1)
                }
                leftButton.setAlpha(1)
            }
            if (imageDisplayedPosition === lamineArray.length - 1) {
                rigthButton.setAlpha(0.4)
            }

        }).on('pointerover', () => {
            if (rigthButton.alpha === 1)
                mouseOverAnimatioArrows(this, rigthButton, rigthButton.scaleX * 1.1, rigthButton.scaleY * 1.1)
        }).on('pointerout', () => {
            if (rigthButton.alpha === 1)
                mouseOverAnimatioArrows(this, rigthButton, rigthButton.scaleX / 1.1, rigthButton.scaleY / 1.1)
        })
        leftButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            if (0 < imageDisplayedPosition) {
                buttonPressAnimation(this, leftButton)
                this.sound.play('clickSound')
                if (imageDisplayedPosition === lamineArray.length - 1) {
                    //play button disappear
                    scaleAnimation(this, playButton, playScalex, playScaley, 0, 0, 1, 0.5)
                    rigthButton.setAlpha(1)
                }
                lamineArray[imageDisplayedPosition].setVisible(false).setActive(false)
                imageDisplayedPosition--
                lamineArray[imageDisplayedPosition].setVisible(true);
                this.instructions.setText(this.sequence[imageDisplayedPosition].description)
            }
            if (imageDisplayedPosition === 0) {
                leftButton.setAlpha(0.4)
            }
        }).on('pointerover', () => {
            if (leftButton.alpha === 1)
                mouseOverAnimatioArrows(this, leftButton, leftButton.scaleX * 1.1, leftButton.scaleY * 1.1)
        }).on('pointerout', () => {
            if (leftButton.alpha === 1)
                mouseOverAnimatioArrows(this, leftButton, leftButton.scaleX / 1.1, leftButton.scaleY / 1.1)
        })

        //Play button
        this.outline = new Phaser.GameObjects.Image(this, this.playButtonScale.objectPositionX, this.playButtonScale.objectPositionY, 'buttonOutline').setVisible(false)
        this.outline.setDisplaySize(this.playButtonScale.objectWidth * 1.12, this.playButtonScale.objectHeight * 1.2);
        this.add.existing(this.outline)
        var playButton = new Phaser.GameObjects.Image(this, this.playButtonScale.objectPositionX, this.playButtonScale.objectPositionY, 'playButton')
        playButton.setDisplaySize(this.playButtonScale.objectWidth, this.playButtonScale.objectHeight)
        const playScalex = playButton.scaleX
        const playScaley = playButton.scaleY
        this.add.existing(playButton)
        playButton.setVisible(false)

        playButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            voice.speak('')
            this.sound.play('clickSound')
            buttonPressAnimation(this, playButton)

        }).on('pointerup', () => {
            this.scene.start('Game', { historyid: this.historyID, name: this.historyName })
        }).on('pointerover', () => {
            mouseOverAnimatioArrows(this, playButton, playScalex * 1.1, playScaley * 1.1)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimatioArrows(this, playButton, playScalex, playScaley)
            this.outline.setVisible(false)
        })


        this.loadOldScale()

    }
    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    loadOldScale() {
        this.cardScale.cardTitle = this.cardTitle
        this.cardScale.cardImage = this.cardImage
        this.cardScale.cardDescription = this.cardDescription
        this.cardScale.cardButton = this.cardButton

    }
    saveScaleState() {
        this.cardTitle = this.clone(this.cardScale.cardTitle)
        this.cardImage = this.clone(this.cardScale.cardImage)
        this.cardDescription = this.clone(this.cardScale.cardDescription)
        this.cardButton = this.clone(this.cardScale.cardButton)
    }
    drawPreloadBackground() {
        //background Preload
        this.background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / this.background.width
        let scaleY = this.sys.canvas.height / this.background.height
        this.background.setScale(scaleX, scaleY).setScrollFactor(0)

        //top bar 
        var bar = new topBar(this, 0, 0, this.sys.canvas.width, 400, 'Secuencia: ' + this.historyName, 'Vocabulary',
            'Antes de continuar al juego es necesario que conozcas la secuencia de ' + this.historyName +
            '. Da click en la imagen para escuchar su descripción')
        this.add.existing(bar)

        //Instructions componet
        this.instructions = new InstructionTextComponent(this, this.InstructionTextScale.objectPositionX,
            this.InstructionTextScale.objectPositionY,
            this.InstructionTextScale.objectWidth
            , this.InstructionTextScale.objectHeight,
            'Cargando secuencia',
            'ludoIcon', this.historyID, 'jugar', this.undefined
        )
        this.add.existing(this.instructions)
    }


}