import Phaser from "phaser";
import ConfirmDialog from "../gameObjects/confirmDialog";
import postScore from "../utils/API/functions";
import { Icons } from "../utils/assetsPaths/icons";
import { SceneKey } from "../utils/constants";
import { createModal } from "../utils/factory";

export default class ModalScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.MODAL })
    }

    init(data) {
        this.targetScene = data.key;
        this.score = this.game.score;
        this.sessionData = this.game.sessionData;
    }

    create() {
        const postId = this.game.sessionData.postId;
        const { width, height } = this.game.canvas;
        this.background = this.add.rectangle(0, 0, width, height, 0, 0.5)
            .setOrigin(0);
        createModal(this, !postId).setAlpha(0.0000001);
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale);
        this.events.on("shutdown", () => this.scale.off("resize", scale))
        this.startModalTween();
    }

    startModalTween() {
        const height = this.game.canvas.height;
        this.modal.setAlpha(1).setY(height + this.modal.background.displayHeight / 2)
        this.tweens.add({
            targets: this.modal,
            y: height / 2,
            ease: "Power3",
            onComplete: () => this.setModalInteractive()
        })
    }

    setModalInteractive() {
        const time = this.game.score.time;
        const postId = this.game.sessionData.postId;
        const sendResultsToPlatform = () => {
            this.score.comments = this.modal.getComments();
            this.modal.setPosition(-100000,-100000);
            this.modal = undefined;
            this.createFirstAttemptDialog();

        }
        this.modal.setInteractive()
            .onContinue(() => this.scene.stop(SceneKey.MODAL).resume(this.targetScene))
            .onExit(() => {
                //postId && time !== 0
                if (postId && time !== 0)
                    sendResultsToPlatform();
                else {
                    this.startMainMenuScene();
                }

            });
    }

    createConfirmDialog(labels, callback = Phaser.Utils.NOOP, icon = Icons.ACCEPT) {
        if (this.confirmDialog)
            this.confirmDialog.destroy();
        const { width, height } = this.game.canvas;
        const { title, description, confirmLabel } = labels;
        this.confirmDialog = new ConfirmDialog(this, width / 2, height / 2, title, description)
            .onConfirm(callback, confirmLabel, icon);
        this.resizeConfirmDialog(width, height);
        this.add.existing(this.confirmDialog);
        this.addConfirmDialogTween();
    }

    createFirstAttemptDialog() {
        const { refuseLabel } = SaveResultsFirstTime;
        this.createConfirmDialog(SaveResultsFirstTime,
            () => this.postResult(), Icons.SEND);
        this.confirmDialog.onBtnClick(
            () => this.startMainMenuScene(), refuseLabel, Icons.NO_SEND);
    }

    startMainMenuScene() {
        this.scene.stop();
        window.history.back();
    }

    createPostFailedDialog() {
        const { refuseLabel } = SaveResultsError;
        this.createConfirmDialog(SaveResultsError,
            () => this.postResult(), Icons.SEND);
        if (this.postAttempts >= 3)
            this.confirmDialog.onBtnClick(
                () => this.startMainMenuScene(), refuseLabel, Icons.RETURN);
    }

    createResultPostedDialog() {
        this.createConfirmDialog(ResultsPosted,
            () => this.startMainMenuScene());
    }

    postResult() {
        this.showLoadingDialog();
        const { postId } = this.sessionData;
        const result = this.createPostJSON();
        postScore(postId, result)
            .then(res => this.createResultPostedDialog()
            ).catch(e => {
                this.postAttempts++;
                this.createPostFailedDialog();
            });
    }

    createPostJSON() {
        const { hits, fails, time, comments } = this.score;
        const d = new Date();
        return {
            date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
            success: hits,
            failures: fails,
            time_played: time,
            observation: comments,
            omissions: 0
        }
    }

    addConfirmDialogTween() {
        this.tweens.add({
            targets: this.confirmDialog,
            scale: { from: 0, to: 1 },
            ease: 'Power3',
            duration: 500
        })
    }

    showLoadingDialog() {
        const { title, description } = SendingResults;
        this.confirmDialog.setLabels(title, description)
            .showLoadingSpinner();
    }

    resizeConfirmDialog() {
        const {width, height} = this.game.canvas;
        this.confirmDialog.resize(width * 0.75, height, 100, 1);
        this.confirmDialog.setPosition(width / 2, height / 2)
    }

    resize() {
        const { width, height } = this.game.canvas;
        this.background.setDisplaySize(width, height);
        if (this.modal) {
            this.modal.resize(width, height, 100, 1);
            this.modal.setPosition(width / 2, height / 2);
        }
        if (this.confirmDialog) {
            this.resizeConfirmDialog();
        }
    }
}

const SaveResultsFirstTime = {
    title: 'Enviar resultados',
    description: '¿Deseas enviar los resultados a la plataforma?',
    confirmLabel: 'Enviar',
    refuseLabel: 'No enviar'
};

const SaveResultsError = {
    title: 'Ocurrió un problema',
    description: 'No fue posible enviar los resultados a la plataforma. ' +
        'Asegúrate de de tener conexión a internet y vuelve a intentarlo.',
    confirmLabel: 'Reintentar',
    refuseLabel: 'No enviar'
}

const SendingResults = {
    title: 'Enviando resultados',
    description: 'Por favor espera.'
}

const ResultsPosted = {
    title: 'Envio exitoso',
    description: 'Los resultados se han guardado correctamente, presiona el ' +
        'botón salir para regresar al menú principal.',
    confirmLabel: 'Salir'
}