import React from 'react';
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import difficultySettings, { DifficultySettingKey } from "./utils/settings";
import { clasifyAssets } from "./utils/assets";
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

const getHint = ({ difficulty }) => {
    switch (difficulty) {
        case DifficultySettingKey.ANIMALS:
            return 'Toca todos los animales';
        case DifficultySettingKey.PEOPLE:
            return 'Toca a todas las personas';
        case DifficultySettingKey.FOOD:
            return 'Toca todos los alimentos';
        case DifficultySettingKey.CLOTHES:
            return 'Toca todas las prendas de vestir';
        case DifficultySettingKey.VEHICLES:
            return 'Toca todos los vehículos';
        default:
            return 'Nan'
    }
}

const data = {
    name: 'Clasifica',
    sessionAssets: clasifyAssets,
    sessionScene: SessionScene,
    logoUrl: './ClasifyGameAssets/logo.png',
    iconUrl: './ClasifyGameAssets/icon.png',
    thumbnail: './ClasifyGameAssets/thumbnail.png',
    getHint,
    userManual: 'Se presentan fotografías agrupadas por categorías, algunas de ellas corresponden ' +
        'a la misma categoría del estímulo patrón y deben ser seleccionadas por el usuario. ',
    settings: [
        difficultySettings,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function ClasifyGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}