export const MomIdle = {
    key: "world2Situation1MomIdle",
    url: "SocialInclusionAssets/situations/sickPerson/momIdle.png",
}

export const MomBack = {
    key: "world2Situation1MomBack",
    url: "SocialInclusionAssets/situations/sickPerson/momBack.png",
}

export const DoctorIdle = {
    key: "world2Situation1DoctorIdle",
    url: "SocialInclusionAssets/situations/sickPerson/doctorIdle.png",
}

export const SickPerson = {
    key: "world2Situation1SickIdle",
    url: "SocialInclusionAssets/situations/sickPerson/sickIdle.png",
}

export const SickBed = {
    key: "world2Situation1SickBed",
    url: "SocialInclusionAssets/situations/sickPerson/sickBed.png",
}

export const SickPersonSheets = {
    MOM_WALK_UP: {
        key: "world2Situation1MomWalkUp",
        url: "SocialInclusionAssets/situations/sickPerson/momWalkUp.png",
        frameWidth: 185 / 3,
        frameHeight: 269 / 2
    },
    MOM_WALK_DOWN: {
        key: "world2Situation1MomWalkDown",
        url: "SocialInclusionAssets/situations/sickPerson/momWalkDown.png",
        frameWidth: 179 / 3,
        frameHeight: 262 / 2
    },
    DOCTOR_UP: {
        key: "world2Situation1DoctorWalkUp",
        url: "SocialInclusionAssets/situations/sickPerson/doctorWalkUp.png",
        frameWidth: 189 / 3,
        frameHeight: 272 / 2
    },
    DOCTOR_DOWN: {
        key: "world2Situation1DoctorWalkDown",
        url: "SocialInclusionAssets/situations/sickPerson/doctorWalkDown.png",
        frameWidth: 196 / 3,
        frameHeight: 273 / 2
    },
    MOM_GIVE_MEDICINE: {
        key: "world2Situation1MomGiveMedicine",
        url: "SocialInclusionAssets/situations/sickPerson/momGiveMedicine.png",
        frameWidth: 744 / 4,
        frameHeight: 538 / 3
    },
    DOCTOR_GIVE_MEDICINE: {
        key: "world2Situation1DoctorGiveMedicine",
        url: "SocialInclusionAssets/situations/sickPerson/doctorGiveMedicine.png",
        frameWidth: 744 / 4,
        frameHeight: 530 / 3
    },
    WOMAN_GIVE_MEDINE: {
        key: "womanGiveMedicine",
        url: 'SocialInclusionAssets/situations/sickPerson/womanGiveMedicine.png',
        frameWidth: 744 / 4,
        frameHeight: 537 / 3
    },
    MAN_GIVE_MEDINE: {
        key: "manGiveMedicine",
        url: 'SocialInclusionAssets/situations/sickPerson/manGiveMedicine.png',
        frameWidth: 744 / 4,
        frameHeight: 518 / 3
    }
}

export const SickPersonAssets = {
    Images: [
        MomIdle,
        DoctorIdle,
        SickPerson,
        SickBed,
        MomBack
    ],
    SpriteSheets: Object.values(SickPersonSheets)
}