import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { BoringWorkAnims, BoringWorkSheets, WorkStation } from "../../utils/assetsPaths/situations/work/boringWork";

export default class BoringWorkSituation extends Situation {
    constructor(scene) {
        const markPosition = { x: 764, y: 1035  };
        const mcPosition = { x: 1205, y: 1254 };
        super(scene, markPosition, mcPosition, Badges.FACE);
        this.instructionLabel = "Estás aburrido en el trabajo, ¿qué haces?";
        this.optionsData = [{
            label: "Me duermo.",
            onclick: this.runEvent(2500, () => this.takeNap())
        }, {
            label: "Escucho música y continúo trabajando.",
            onclick: this.runEvent(2500, () => this.listenMusic())
        }, {
            label: "Me lavo la cara y sigo con mi trabajo.",
            onclick: this.runEvent(2500, () => this.washFace())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(BoringWorkAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.sprite = this.scene.add.sprite(0, 0, WorkStation.key, 0)
            .setScale(this.scene.mc.scale)
            .setOrigin(0, 0.5)
            .setPosition(scaleX * 915, scaleY * 1260 + y)
        return this;
    }

    takeNap() {
        const anim = this.mcIsBoy ? BoringWorkAnims.MAN_TAKE_NAP : BoringWorkAnims.WOMAN_TAKE_NAP;
        this.sprite.play(anim.key)
            .once("animationcomplete", this.runEvent(2500,
                () => addDialogTween(this.scene, "Mala desición")));
    }

    listenMusic() {
        const anim = this.mcIsBoy ? BoringWorkAnims.MAN_USE_HEADPHONES : BoringWorkAnims.WOMAN_USE_HEADPHONES;
        const liftAnim = this.mcIsBoy ? BoringWorkAnims.MAN_LIFT_BOX_HEADPHONES : BoringWorkAnims.WOMAN_LIFT_BOX_HEADPHONES;
        this.sprite.play(anim.key)
            .once("animationcomplete", this.runEvent(2000,
                () => this.sprite.play(liftAnim.key)
                    .once("animationcomplete", this.runEvent(2000,
                        () => addDialogTween(this.scene, "Buena desición", this.badge)))))
    }

    washFace() {
        const anim = this.mcIsBoy ? BoringWorkAnims.MAN_WASH_FACE : BoringWorkAnims.WOMAN_WASH_FACE;
        const liftAnim = this.mcIsBoy ? BoringWorkAnims.MAN_LIFT_BOX : BoringWorkAnims.WOMAN_LIFT_BOX;
        this.sprite.setTexture(WorkStation.key);
        this.scene.mc.setAlpha(1);
        this.scene.moveMcTo(370, 1452, () => {
            this.scene.mc.setTexture(this.mcIsBoy ? BoringWorkSheets.MAN_WASH_FACE.key : BoringWorkSheets.WOMAN_WASH_FACE.key, 0)
            this.addEvent(2000, () => {
                this.scene.mc.play(anim.key)
                    .once("animationcomplete", this.runEvent(2000, () => {
                        //{ x: 1177, y: 1254 }
                        this.scene.moveMcTo(1205, 1254, () => {
                            this.scene.mc.setFlipX(true);
                            this.addEvent(2500, () => {
                                this.scene.mc.setAlpha(0);
                                this.sprite.play(liftAnim.key)
                                    .once("animationcomplete", () => {
                                        this.scene.follower.emit("onMovementComplete");
                                        this.addEvent(2000,
                                            () => addDialogTween(this.scene, "Buena desición", this.badge))
                                    })
                            })
                        })
                    }))
            })
        })
    }

    start() {
        const anim = this.mcIsBoy ? BoringWorkAnims.MAN_LIFT_BOX : BoringWorkAnims.WOMAN_LIFT_BOX;
        const bored = this.mcIsBoy ? BoringWorkAnims.MAN_GET_BORED : BoringWorkAnims.WOMAN_GET_BORED;
        this.addEvent(1000, () => {
            this.scene.mc.setFlipX(true).setAlpha(0);
            this.sprite.play(anim.key)
                .once("animationcomplete", () => this.sprite.play(bored.key)
                    .once("animationcomplete", this.runEvent(2500, () => this.showHint()))
                )
        }
        )

    }

    reboot(onComplete) {
        this.sprite.setTexture(WorkStation.key);
        this.scene.mc.setAlpha(1);
        this.scene.follower.emit("onMovementComplete");
        onComplete();
    }
}