import { BossSheets } from "../assetsPaths/situations/work/agressiveCoworker";
import { generateFrameNames } from "../functions";

export const BossAnims = {
    ANGRY: {
        key: "bossAnimAngry",
        frames: generateFrameNames(BossSheets.ANGRY.key, 0, 1),
        frameRate: 2,
        repeat: 3,
    },
    WALK_FRONT: {
        key: "bossAnimWalkFront",
        frames: generateFrameNames(BossSheets.FRONT.key, 0, 5),
        frameRate: 7,
        repeat: -1,
    },
    WALK_BACK: {
        key: "bossAnimWalkBack",
        frames: generateFrameNames(BossSheets.BACK.key, 0, 5),
        frameRate: 7,
        repeat: -1,
    }
}
