import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { getDifferentRandomItem, getRandomElement } from "../../HabCogGame/utils/functions";
import { Cursors } from "../../HabCogGame/utils/constants";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { OrientationSettingKey, DifficultySettingKey } from '../utils/settings';
import { scaleImage } from '../../HabCogGame/utils/resize';
import DesignationMsgBox from '../../HabCogGame/gameObjects/designationsMsgBox';
import { Btns } from '../../HabCogGame/utils/assetsPaths/btn';
import { createBtn } from '../../HabCogGame/utils/gameObjectFactory';
import { MirrorReflectionIcons } from '../utils/assets';
import { IntrudersPhotos } from '../../IntrudersGame/utils/assets';
import { GeomAssets } from '../../SpaceRelationGame/utils/assets';
const Image = Phaser.GameObjects.Image;
const { GOOD_TARGET, BAD_TARGET } = TargetFeedback;
const lineWidth = 7.5 * window.devicePixelRatio;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, orientation, size, buttons } = this.game.userConfig;
        const targetScale = [0.5, 0.6, 0.8, 1][size];
        const isLandscape = orientation === OrientationSettingKey.LANDSCAPE;
        this.sTextures = this.getSessionTextures(difficulty);
        this.setGraphics(isLandscape);
        this.gameArea = this.add.container(0, this.hubHeight);
        this.createTargets(isLandscape, buttons, targetScale);
        this.setFlipFn(isLandscape);
        this.setMsgFn(isLandscape);
        this.setInteractiveFns(buttons);
        this.strokePattern();
        this.startGameplay();
    }

    getSessionTextures(difficulty) {
        if (difficulty === DifficultySettingKey.GEOMS)
            return GeomAssets.REGULAR.TWO_GEOM
        else return IntrudersPhotos;
    }

    setGraphics(isLandscape) {
        const color = 0xBF42F5;
        const { width, height } = this.getAvailableSize();
        this.graphics = this.add.graphics({
            x: 0,
            y: this.hubHeight,
            lineStyle: { color, width: lineWidth },
            fillStyle: { color }
        })
        if (isLandscape)
            this.graphics.fillRect(width / 2, 0, lineWidth, height);
        else
            this.graphics.fillRect(0, height / 2, width, lineWidth);
    }

    setFlipFn(isLandscape) {
        this.actions = 0;
        const flipX = (value) => this.target.setFlipX(value);
        const flipY = (value) => this.target.setFlipY(value);
        const toggleX = () => this.target.toggleFlipX();
        const toggleY = () => this.target.toggleFlipY();
        const isFlippedX = () => this.target.flipX;
        const isFlippedY = () => this.target.flipY;
        this.flipTarget = isLandscape ? flipX : flipY;
        this.toggleTargetAxis = isLandscape ? toggleX : toggleY;
        this.getTargetFlip = isLandscape ? isFlippedX : isFlippedY;
    }

    setMsgFn(isLandscape) {
        const { width, height } = this.getAvailableSize();
        const landscapeResize = (img, msg) => {
            scaleImage(img, width / 2, height, 0, 0.55);
            msg.resize(width / 2, height, 100, 1);
            msg.setX(img.x)
            msg.setY(msg.background.displayHeight / 2);
        };
        const portraitResize = (img, msg) => {
            scaleImage(img, width, height / 2, 0, 0.55);
            msg.resize(width, height / 4, 100, 1);
            msg.setX(width / 2);
            msg.setY(height / 2);
        }
        this.resizeFeedbackItems = isLandscape ? landscapeResize : portraitResize;
    }

    setInteractiveFns(buttons) {
        const removeBtns = () => this.removeBtnInteractive();
        const removeImg = () => this.removeImgInteractive();
        const interactiveBtns = () => this.setBtnsInteractive();
        const interactiveTargets = () => this.setTargetsInteractive();
        this.removeSceneInteractive = buttons ? removeBtns : removeImg;
        this.setGameInteractive = buttons ? interactiveBtns : interactiveTargets;
    }

    createTargets(isLandscape, useButtons, scale) {
        this.targetTexture = getRandomElement(this.sTextures);
        this.pattern = new Image(this, 0, 0, this.targetTexture.key);
        this.target = new Image(this, 0, 0, this.targetTexture.key);
        if (useButtons) {
            const { flipBtn, rotateBtn } = createBtns(this);
            this.flipBtn = flipBtn;
            this.rotateBtn = rotateBtn;
            alignButtonInUseTargets(this, isLandscape, scale);
        }
        else
            alignButtonlessTargets(this, isLandscape, scale)
        this.gameArea.add([this.pattern, this.target]);
        this.pattern.setInteractive(Cursors.pointerover);
        this.target.setInteractive(Cursors.pointerover);

    }

    strokePattern() {
        const { displayWidth, displayHeight } = this.pattern;
        const { x, y } = this.pattern.getTopLeft();
        const padding = lineWidth / 2;
        this.graphics.strokeRect(x - padding, y - padding,
            displayWidth + lineWidth, displayHeight + lineWidth);
    }

    rotateTarget() {
        const angle = getRandomElement([90, 180, 270]);
        const flipState = getRandomElement([true, false]);
        this.target.setAngle(angle);
        this.flipTarget(flipState);
    }

    updateTargetsTexture() {
        const texture = this.targetTexture;
        this.targetTexture = getDifferentRandomItem(this.sTextures, texture)
        this.target.setTexture(this.targetTexture.key);
        this.pattern.setTexture(this.targetTexture.key);
    }

    onTargetHit() {
        if (this.actions < 4) {
            this.actions++;
            this.target.setAngle(this.target.angle + 90);
        } else {
            this.actions = 0;
            this.toggleTargetAxis();
        }
    }

    setBtnsInteractive() {
        const { pattern, target } = this;
        pattern.on('pointerdown', () => this.onPatternHit());
        this.flipBtn.onclick(() => this.toggleTargetAxis());
        this.rotateBtn.onclick(() => target.setAngle(target.angle + 90));
    }

    setTargetsInteractive() {
        const { pattern, target } = this;
        target.on('pointerdown', () => this.onTargetHit());
        pattern.on('pointerdown', () => this.onPatternHit());
    }

    addFeedbackTween(targets, onComplete) {
        this.tweens.add({
            delay: 1000,
            duration: 1000,
            alpha: 0,
            targets,
            onComplete,
        })
    }

    addHit(feedbackItems) {
        super.addHit();
        this.addFeedbackTween(feedbackItems, () => {
            feedbackItems.forEach(item => item.destroy());
            this.startGameplay();
        })
    }

    addFail(feedbackItems) {
        super.addFail();
        this.addFeedbackTween(feedbackItems, () => {
            feedbackItems.forEach(item => item.destroy());
            this.setGameInteractive();
        })
    }

    removeImgInteractive() {
        this.target.off('pointerdown');
        this.pattern.off('pointerdown');
    }

    removeBtnInteractive() {
        this.pattern.off('pointerdown');
        this.flipBtn.removeClick();
        this.rotateBtn.removeClick();
    }

    onPatternHit() {
        const { target, pattern } = this;
        const { x, y, displayWidth: size } = this.target;
        const isAngleAligned = target.angle % 360 === pattern.angle;
        const isFlipped = this.getTargetFlip();
        const isCorrect = isAngleAligned && isFlipped;
        const texture = isCorrect ? GOOD_TARGET : BAD_TARGET;
        const label = `Respuesta ${isCorrect ? 'correcta' : 'incorrecta'}`;
        const img = new Image(this, x, y, texture.key)
            .setDisplaySize(size, size);
        const msg = new DesignationMsgBox(this, 0, 0, label);
        const feedbackItems = [img, msg];
        this.resizeFeedbackItems(img, msg);
        this.gameArea.add(feedbackItems);
        this.removeSceneInteractive();
        isCorrect ? this.addHit(feedbackItems) : this.addFail(feedbackItems);

    }

    startGameplay() {
        this.updateTargetsTexture();
        this.rotateTarget();
        this.setGameInteractive();
    };
}

const createBtns = (scene) => {
    const { ROTATE, FLIP } = MirrorReflectionIcons;
    const flipBtn = createBtn(scene, 'Voltear', FLIP, Btns.SECONDARY);
    const rotateBtn = createBtn(scene, 'Girar', ROTATE)
    const btns = [flipBtn, rotateBtn];
    btns.forEach(btn => btn.setInteractive(Cursors.pointerover))
    scene.gameArea.add(btns);
    return { flipBtn, rotateBtn }
}

const alignDoubleLandscapeBtns = (width, height, btns) => {
    btns.forEach(btn => btn.resize(width / 4, height / 4, 100, 0.75));
    const cellWidth = btns[0].background.displayWidth * 1.2;
    Phaser.Actions.GridAlign(btns, {
        width: -1,
        cellWidth,
        x: width * 0.75 - cellWidth / 2,
        y: height - btns[0].background.displayHeight,
        position: Phaser.Display.Align.CENTER
    });
}

const alignLandscapeBtns = (width, height, btns) => {
    btns.forEach(btn => btn.resize(width / 2, height / 4, 100, 0.75));
    const cellHeight = btns[0].background.displayHeight * 1.2;
    Phaser.Actions.GridAlign(btns, {
        height: -1,
        cellHeight,
        x: width * 0.75,
        y: height - cellHeight * 1.75,
        position: Phaser.Display.Align.CENTER
    });
}

const getLandscapeCenter = (target, pattern, flipBtn) => {
    const { displayHeight } = target;
    const topCenter = flipBtn.getTopCenter()
    if (pattern.y + displayHeight / 2 < topCenter.y)
        return pattern.y;
    else
        return topCenter.y / 2
}

const alignLandscapeTargets = (width, height, pattern, target, flipBtn, scale) => {
    const size = (height + flipBtn.getTopCenter().y - height / 2) / 2;
    scaleImage(target, width / 2, size, 100, scale);
    pattern.setPosition(width / 4, height / 2);
    const y = getLandscapeCenter(target, pattern, flipBtn);
    target.setPosition(width * 0.75, y);
}

const alignLandscape = (width, height, target, pattern, btns, scale) => {
    if (width > height)
        alignDoubleLandscapeBtns(width, height, btns);
    else
        alignLandscapeBtns(width, height, btns);
    alignLandscapeTargets(width, height, pattern, target, btns[0], scale)
}

const alignDoublePortraitBtns = (width, height, btns) => {
    const btnHeight = height / 8;
    btns.forEach(btn => btn.resize(width, btnHeight, 100, 1));
    const cellHeight = btns[0].background.displayHeight * 1.3;
    Phaser.Actions.GridAlign(btns, {
        height: -1,
        cellHeight,
        x: width / 2,
        y: height - cellHeight * btns.length * 0.8,
        position: Phaser.Display.Align.CENTER
    })
}

const alignPortraitBtns = (width, height, btns) => {
    btns.forEach(btn => btn.resize(width / 4, height / 7.5, 100, 1))
    const { displayWidth, displayHeight } = btns[0].background;
    btns[0].setPosition(displayWidth / 1.25, height - displayHeight);
    btns[1].setPosition(width - displayWidth / 1.5, btns[0].y);
}

const alignPortrait = (width, height, target, pattern, btns) => {
    pattern.setPosition(width / 2, height / 4);
    if (width > height) {
        alignPortraitBtns(width, height, btns);
        target.setPosition(width / 2, height * 0.75).setScale(pattern.scale);
        const topRight = btns[0].getTopRight();
        const bottomCenter = target.getBottomCenter();
        if (topRight.y < bottomCenter.y) {
            const freeSpace = btns[1].getLeftCenter().x - topRight.x;
            scaleImage(target, freeSpace, pattern.displayHeight, 100, 1);
        }
    }
    else {
        alignDoublePortraitBtns(width, height, btns);
        scaleImage(target, width, btns[0].getTopCenter().y - height / 2, 100, 1);
        const y = (height + btns[0].getTopCenter().y - height / 2) / 2;
        target.setPosition(width / 2, y);
    }
}

const alignButtonInUseTargets = (scene, isLandscape, scale) => {
    const { target, pattern, flipBtn, rotateBtn } = scene;
    const { width, height } = scene.getAvailableSize();
    const maxWidth = isLandscape ? width / 2 : width;
    const maxHeight = isLandscape ? height : height / 2;
    scaleImage(pattern, maxWidth, maxHeight, 100, scale);
    const btns = [flipBtn, rotateBtn];
    if (isLandscape)
        alignLandscape(width, height, target, pattern, btns, scale);
    else
        alignPortrait(width, height, target, pattern, btns, scale)


}

const alignButtonlessTargets = (scene, isLandscape, scale) => {
    const { pattern, target } = scene;
    const { width, height } = scene.getAvailableSize();
    const maxWidth = isLandscape ? width / 2 : width;
    const maxHeight = isLandscape ? height : height / 2;
    scaleImage(pattern, maxWidth, maxHeight, 100, scale);
    scaleImage(target, maxWidth, maxHeight, 100, scale);
    if (isLandscape) {
        pattern.setPosition(width / 4, height / 2);
        target.setPosition(width * 0.75, height / 2);
    }
    else {
        pattern.setPosition(width / 2, height / 4);
        target.setPosition(width / 2, height * 0.75);
    }
}
