import { generateFrameNames, generateFrameNamesByArray } from "../../../functions"

export const FeelingSadSheets = {
    MAN_CRY: {
        key: "w3s6Mancries",
        url: "SocialInclusionAssets/situations/feelingSad/manCry.png",
        frameWidth: 382 / 4,
        frameHeight: 542 / 4
    },
    MAN_DONT_CRY: {
        key: "w3s6Mandontcry",
        url: "SocialInclusionAssets/situations/feelingSad/manDontCry.png",
        frameWidth: 528 / 5,
        frameHeight: 680 / 5
    },
    MAN_GET_HELP: {
        key: "w3s6ManGetHelp",
        url: "SocialInclusionAssets/situations/feelingSad/manGetHelp.png",
        frameWidth: 602 / 6,
        frameHeight: 709 / 5
    },
    WOMAN_CRY: {
        key: "w3s6woMancries",
        url: "SocialInclusionAssets/situations/feelingSad/womanCry.png",
        frameWidth: 389 / 4,
        frameHeight: 564 / 4
    },
    WOMAN_DONT_CRY: {
        key: "w3s6woMandontcry",
        url: "SocialInclusionAssets/situations/feelingSad/womanDontCry.png",
        frameWidth: 390 / 5,
        frameHeight: 709 / 5
    },
    WOMAN_GET_HELP: {
        key: "w3s6woManGetHelp",
        url: "SocialInclusionAssets/situations/feelingSad/womanGetHelp.png",
        frameWidth: 615 / 6,
        frameHeight: 730 / 5
    },
}

export const FeelingSadAnims = {
    MAN_TAKE_SIT: {
        key: "w3s6ManSwept",
        frames: generateFrameNamesByArray(FeelingSadSheets.MAN_DONT_CRY.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 7, 6, 7, 8, 7, 6, 7, 8, 9, 10, 11, 12
        ]),
        frameRate: 3,
    },
    MAN_CRY: {
        key: "w3s6ManCry",
        frames: generateFrameNamesByArray(FeelingSadSheets.MAN_CRY.key, [
            13, 14
        ]),
        frameRate: 2,
        repeat: 3
    },
    MAN_DONT_CRY: {
        key: "w3s6MandontCry",
        frames: generateFrameNamesByArray(FeelingSadSheets.MAN_DONT_CRY.key, [
            13, 14, 15, 14, 15, 14, 15, 16, 17, 18, 19, 20, 21, 22
        ]),
        frameRate: 3,
    },
    MAN_CALL: {
        key: "w3s6Mancall",
        frames: generateFrameNamesByArray(FeelingSadSheets.MAN_GET_HELP.key, [
            13, 14, 15, 14, 15, 14, 15, 12
        ]),
        frameRate: 3,
    },
    MAN_GET_HELP: {
        key: "w3s6MangetHelp",
        frames: generateFrameNamesByArray(FeelingSadSheets.MAN_GET_HELP.key, [
            16, 17, 18, 19, 20, 21, 22, 21, 22, 21, 22, 23
        ]),
        frameRate: 2,
    },
    MAN_BACK_TO_WORK: {
        key: "w3s6ManbackToWork",
        frames: generateFrameNames(FeelingSadSheets.MAN_GET_HELP.key, 26, 28),
        frameRate: 2,
    },
    WOMAN_TAKE_SIT: {
        key: "w3s6woManSwept",
        frames: generateFrameNamesByArray(FeelingSadSheets.WOMAN_DONT_CRY.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 7, 6, 7, 8, 7, 6, 7, 8, 9, 10, 11, 12
        ]),
        frameRate: 3,
    },
    WOMAN_CRY: {
        key: "w3s6woManCry",
        frames: generateFrameNamesByArray(FeelingSadSheets.WOMAN_CRY.key, [
            13, 14
        ]),
        frameRate: 2,
        repeat: 3
    },
    WOMAN_DONT_CRY: {
        key: "w3s6woMandontCry",
        frames: generateFrameNamesByArray(FeelingSadSheets.WOMAN_DONT_CRY.key, [
            13, 14, 15, 14, 15, 14, 15, 16, 17, 18, 19, 20, 21, 22
        ]),
        frameRate: 3,
    },
    WOMAN_CALL: {
        key: "w3s6woManCall",
        frames: generateFrameNamesByArray(FeelingSadSheets.WOMAN_GET_HELP.key, [
            13, 14, 15, 14, 15, 14, 15, 12
        ]),
        frameRate: 3,
    },
    WOMAN_GET_HELP: {
        key: "w3s6woMangetHelp",
        frames: generateFrameNamesByArray(FeelingSadSheets.WOMAN_GET_HELP.key, [
            16, 17, 18, 19, 20, 21, 22, 21, 22, 21, 22, 23
        ]),
        frameRate: 2,
    },
    WOMAN_BACK_TO_WORK: {
        key: "w3s6woManbackToWork",
        frames: generateFrameNames(FeelingSadSheets.WOMAN_GET_HELP.key, 26, 28),
        frameRate: 2,
    },
}

export const FeelingSadAssets = {
    Images: [],
    SpriteSheets: Object.values(FeelingSadSheets)
}