import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";


export const RotationSettingKey = {
    NO_SHIFT: 0,
    INVERT: 1,
    SYMMETRIC: 2
}

export const DifficultySettingKey = {
    LOW_DISTORSION: 0,
    HIGH_DISTORSION: 1
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Poca distorsión',
            header: 'Imágenes poco distorsionadas',
            description: 'Las distorsiones del estímulo patrón serán delgadas.',
            image: {
                key: 'lowDistorsion',
                url: './LightsAndShadowsGameAssets/settings/difficulty/lowDistorsion.png'
            }
        }, {
            title: 'Mucha distorsión',
            header: 'Imágenes muy distorsionadas',
            description: 'Las distorsiones del estímulo patrón serán gruesas.',
            image: {
                key: 'highDistorsion',
                url: './LightsAndShadowsGameAssets/settings/difficulty/highDistorsion.png'
            }
        }
    ]
};

export const rotationSetting = {
    key: SettingKey.ROTATION,
    name: 'Rotación',
    icon: PreviewIcons.ROTATE,
    tileIcon: TileIcons.ROTATE,
    options: [
        {
            title: 'Sin cambios',
            header: 'Sin cambios espaciales',
            description: 'El estímulo patrón no presentará ningún cambio espacial respecto al estímulo objetivo.',
            image: {
                key: 'noShiftSetting',
                url: './LightsAndShadowsGameAssets/settings/rotation/noShift.png'
            },
        }, {
            title: 'Inversiones',
            header: 'Forma invertida',
            description: 'El estímulo patrón se presentará como una inversión espacial respecto al estímulo objetivo.',
            image: {
                key: 'inversionShift',
                url: './LightsAndShadowsGameAssets/settings/rotation/inversionShift.png'
            },
        }, {
            title: 'Simetrías',
            header: 'Forma simétrica',
            description: 'El estímulo patrón se presentará como una simetría respecto al estímulo objetivo.',
            image: {
                key: 'symmetricShift',
                url: './LightsAndShadowsGameAssets/settings/rotation/symmetricShift.png'
            },
        }
    ]
};

export const CustomSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...rotationSetting.options.map(({ image }) => image)
]
