import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { outlineSetting, OutlineSettingAssets } from "../HabCogGame/utils/settingsConstants/outlineSetting";
import { difficultySetting, DifficultySettingAssets } from "./utils/settings";
import { sortedGeomTextures } from '../HabCogGame/utils/constants';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const data = {
    name: 'Ventanas luminosas',
    sessionAssets: [
        ...sortedGeomTextures.byShape.SQUARE,
        ...OutlineSettingAssets,
        ...DifficultySettingAssets,
        ...TimeSettingAssets
    ],
    sessionScene: SessionScene,
    logoUrl: './LuminousWindowsGameAssets/logo.png',
    iconUrl: './LuminousWindowsGameAssets/icon.png',
    thumbnail: './LuminousWindowsGameAssets/thumbnail.png',
    getHint: () => 'Toca la región que se ilumina.',
    userManual: 'Una región de la pantalla se iluminará con un color y cambiará de ' +
        'posición con cada selección que realice el usuario. Las opciones de ' +
        'configuración permiten cambiar: tamaño de la región iluminada, ' +
        'visualizarla con contorno o sin él, y el tiempo de duración. \n',
    settings: [
        difficultySetting,
        outlineSetting,
        timeSetting
    ]
};

export default function LuminousWindowsGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}