import React from "react";
import HabCogGame from "../HabCogGame/HabCogGame";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import SessionScene from "./scenes/sessionScene";
import { colorScaleSetting, difficultySetting } from "./utils/settings";
import { FacesAndGesturesGameAssets } from "./utils/assets";
import GrayScalePipeline from "../HabCogGame//pipelines/grayTextureTint";
import { ColorScalePipelineKey } from "../HabCogGame/utils/constants";
import timeSetting from "../HabCogGame/utils/settingsConstants/timeSetting";

const data = {
    name: 'Caras y gestos',
    sessionAssets: FacesAndGesturesGameAssets,
    sessionScene: SessionScene,
    logoUrl: './FacesAndGesturesGameAssets/logo.png',
    iconUrl: './FacesAndGesturesGameAssets/icon.png',
    thumbnail: './FacesAndGesturesGameAssets/thumbnail.png',
    getHint: () => 'Toca las expresiones faciales según la instrucción',
    userManual: 'El estímulo patrón representa emoción humana. El usuario debe ' +
        'seleccionar los rostros que muestran una expresión facial similar a la del ' +
        'dibujo. ',
    settings: [
        difficultySetting,
        colorScaleSetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        timeSetting
    ],
    onLoad: (scene) => {
        const game = scene.game;
        const grayScalePipeline = new GrayScalePipeline(game);
        const key = ColorScalePipelineKey.GRAY;
        game.renderer.addPipeline(key, grayScalePipeline);
    }

};

export default function FacesAndGesturesGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}