import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const SizeSettingKey = {
    VERY_SMALL: 0,
    SMALL: 1,
    LARGE: 2,
    VERY_LARGE: 3
}

export const sizeSetting = {
    key: SettingKey.SIZE,
    name: 'Tamaño',
    icon: PreviewIcons.SIZE,
    tileIcon: TileIcons.SIZE,
    options: [
        {
            key: SizeSettingKey.VERY_SMALL,
            title: 'Muy pequeño',
            header: 'Estímulos muy pequeños',
            description: '',
            image: {
                key: 'verySmallSizePreviewImage',
                url: './HabCogGameAssets/settings/size/verySmallSize.png'
            }
        }, {
            key: SizeSettingKey.SMALL,
            title: 'Pequeño',
            header: 'Estímulos pequeños',
            description: '',
            image: {
                key: 'smallSizePreviewImage',
                url: './HabCogGameAssets/settings/size/smallSize.png'
            }
        }, {
            key: SizeSettingKey.LARGE,
            title: 'Grande',
            header: 'Estímulos grandes',
            description: '',
            image: {
                key: 'regularSizePreviewImage',
                url: './HabCogGameAssets/settings/size/regularSize.png'
            }
        }, {
            key: SizeSettingKey.VERY_LARGE,
            title: 'Muy grande',
            header: 'Estímulos muy grandes',
            description: '',
            image: {
                key: 'largeSizePreviewImage',
                url: './HabCogGameAssets/settings/size/largeSize.png'
            }
        }
    ]
};

export const SizeSettingAssets = sizeSetting.options.map(({ image }) => image);