export const loadingBar = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1200,
    objectHeight: 230,
    objectPositionX: 400,
    objectPositionY: 750,
}
export const progressbar = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1190,
    objectHeight: 180,
    objectPositionX: 405,
    objectPositionY: 780,
}
export const textScale = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1190,
    objectHeight: 180,
    objectPositionX: 605,
    objectPositionY: 850,
}
export const splashImage = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1200,
    objectHeight: 650,
    objectPositionX: 1000,
    objectPositionY: 400,
}