import Phaser from "phaser";
import { GameFont } from "../utils/constants";
import { scaleImage } from "../utils/resize";
import { Btns } from "../utils/assetsPaths/btn";
import { Icons } from "../utils/assetsPaths/icons";
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;


export default class ScoreLabel extends Phaser.GameObjects.Container {

    constructor(scene, x, y, iconTexture = Icons.ATTEMPTS, title, scoreNum) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.PRIMARY.key);
        this.icon = new Image(scene, 0, 0, iconTexture.key);
        this.titleLabel = new Text(scene, 0, 0, title, ScoreLabelTextStyle).setOrigin(0, 0.5);
        this.scoreLabel = new Text(scene, 0, 0, scoreNum, ScoreLabelTextStyle).setOrigin(1, 0.5);
        this.add([
            this.background,
            this.icon,
            this.titleLabel,
            this.scoreLabel
        ]);
    }

    resizeLabels() {
        const fontSize = this.background.displayHeight * 0.35
        this.titleLabel.setFontSize(`${fontSize}px`)
            .setX(this.background.getLeftCenter().x + this.icon.displayWidth * 3 / 4);
        this.scoreLabel.setFontSize(`${fontSize}px`)
            .setX(this.background.getRightCenter().x - this.icon.displayWidth * 0.35);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.icon.setScale(this.background.scale);
        this.icon.setX(this.background.getLeftCenter().x);
        this.resizeLabels();
    }

}

const ScoreLabelTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}