import Phaser from "phaser";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { SickPersonSheets, MomIdle, SickPerson, SickBed, DoctorIdle } from "../../utils/assetsPaths/situations/home/sickPerson";
import { addDialogTween, generateFrameNames } from "../../utils/functions";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { BOY_SHEETS } from "../../utils/assetsPaths/playerAnim";

const Vector2 = Phaser.Math.Vector2;

export default class SickPersonSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 3598, y: 676 };
        //const mcPosition = { x: 3219, y: 343 };
        const mcPosition = { x: 3400, y: 425 }
        super(scene, markPosition, mcPosition, Badges.MEDIC);
        this.instructionLabel = "En casa, alguien se siente enfermo, ¿qué haces?";
        this.optionsData = [{
            label: "Busco una pastilla y le doy.",
            onclick: this.runEvent(2500, () => this.giveMedicine())
        }, {
            label: "Aviso a una persona adulta.",
            onclick: this.runEvent(2500, () => this.callMom())
        }, {
            label: "Llamo al médico y le pido que venga.",
            onclick: this.runEvent(2500, () => this.callDoctor())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(MotherAnims).forEach(anim => anims.create(anim));
        Object.values(DoctorAnims).forEach(anim => anims.create(anim));
        Object.values(NPCAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        this.npc = createNPC(this.scene);
        return this;
    }

    giveMedicine() {
        this.scene.mc.setAlpha(0);
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ? NPCAnims.RECIEVE_MAN_MEDICINE : NPCAnims.RECIEVE_WOMAN_MEDICINE;
        this.npc.play(anim.key)
            .once("animationcomplete", this.runEvent(2500,
                () => {
                    this.scene.mc.setAlpha(1);
                    this.npc.setTexture(SickPerson.key);
                    addDialogTween(this.scene, "El enfermo se puso peor")
                }))
    }

    callMom() {
        const { SCARED } = this.scene.game.ludo.character.anims;
        this.mom = createMother(this.scene);
        attachMotherToEvents(this.scene, this.mom);
        const giveMedicine = this.runEvent(2500, () => {
            this.mom.sprite.setAlpha(0);
            this.npc.play(NPCAnims.RECIEVE_MOM_MEDICINE.key)
                .once("animationcomplete", this.runEvent(1500, () => {
                    this.npc.setTexture(SickPerson.key);
                    addDialogTween(this.scene, "El enfermo se siente mejor", this.badge);
                    this.scene.events.off("update", this.mom.move)
                    this.mom.follower.destroy();
                    this.mom.sprite.destroy();
                    this.mom = undefined;
                }))
        })
        this.scene.mc.play(SCARED.key)
            .once("animationcomplete", () => {
                this.scene.moveMcTo(3219, 343, () => {
                    this.scene.mc.setFlipX(false);
                    this.mom.follower.goTo(new Vector2(3400, 425))
                        .onceMovementComplete(() => {
                            this.mom.sprite.setFlipX(false)
                            giveMedicine()
                        })
                })
                this.scene.tweens.add({ targets: this.mom.sprite, alpha: 1 })
            }
            )
    }

    callDoctor() {
        const { PHONE_CALL } = this.scene.game.ludo.character.anims;
        this.doctor = createDoctor(this.scene);
        attachMotherToEvents(this.scene, this.doctor);
        const giveMedicine = this.runEvent(2500, () => {
            this.doctor.sprite.setAlpha(0);
            this.npc.play(NPCAnims.RECIEVE_DOCTOR_MEDICINE.key)
                .once("animationcomplete", this.runEvent(1500, () => {
                    this.npc.setTexture(SickPerson.key);
                    addDialogTween(this.scene, "El enfermo se siente mejor", this.badge);
                    this.scene.events.off("update", this.doctor.move)
                    this.doctor.follower.destroy();
                    this.doctor.sprite.destroy();
                    this.doctor = undefined;
                }))
        })
        this.scene.mc.play(PHONE_CALL.key)
            .once("animationcomplete", () => {
                this.addEvent(2000, () =>
                    this.scene.moveMcTo(3219, 343, () => {
                        this.scene.mc.setFlipX(false)
                        this.doctor.follower.goTo(new Vector2(3400, 425))
                            .onceMovementComplete(() => {
                                this.doctor.sprite.setFlipX(false)
                                giveMedicine()
                            })

                    }))
                this.scene.tweens.add({ targets: this.doctor.sprite, alpha: 1 })
            })
    }

    start() {
        this.scene.mc.setFlipX(false);
        this.addEvent(2500, () => this.showHint());
    }


}

export const MotherAnims = {
    WalkUp: {
        key: 'motherWalkUp',
        frames: generateFrameNames(SickPersonSheets.MOM_WALK_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    WalkDown: {
        key: 'motherWalkDown',
        frames: generateFrameNames(SickPersonSheets.MOM_WALK_DOWN.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

const DoctorAnims = {
    WalkUp: {
        key: 'doctorWalkUp',
        frames: generateFrameNames(SickPersonSheets.DOCTOR_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    WalkDown: {
        key: 'doctorWalkDown',
        frames: generateFrameNames(SickPersonSheets.DOCTOR_DOWN.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

const NPCFrameRate = 1;
const NPCAnims = {
    RECIEVE_MAN_MEDICINE: {
        key: 'mangiveMedicine',
        frames: generateFrameNames(SickPersonSheets.MAN_GIVE_MEDINE.key, 0, 10),
        frameRate: NPCFrameRate
    },
    RECIEVE_WOMAN_MEDICINE: {
        key: 'womangiveMedicine',
        frames: generateFrameNames(SickPersonSheets.WOMAN_GIVE_MEDINE.key, 0, 10),
        frameRate: NPCFrameRate
    },
    RECIEVE_MOM_MEDICINE: {
        key: "motherGiveMedicine",
        frames: generateFrameNames(SickPersonSheets.MOM_GIVE_MEDICINE.key, 0, 10),
        frameRate: NPCFrameRate
    },
    RECIEVE_DOCTOR_MEDICINE: {
        key: "doctorGiveMedicine",
        frames: generateFrameNames(SickPersonSheets.DOCTOR_GIVE_MEDICINE.key, 0, 10),
        frameRate: NPCFrameRate
    }
}

const createMother = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2784, 620);
    const sprite = createCharacterSprite(scene, MomIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0).setDepth(scene.mc.depth + 1)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(MotherAnims.WalkUp, false))
        .onTopRightMovement(anim(MotherAnims.WalkUp, true))
        .onBottomLeftMovement(anim(MotherAnims.WalkDown, true))
        .onBottomRightMovement(anim(MotherAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(MomIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}

const attachMotherToEvents = (scene, mom) => {
    scene.events.on("update", mom.move);
    scene.events.once("shutdown",
        () => scene.events.off("update", mom.move));
}

const createDoctor = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2784, 620);
    const sprite = createCharacterSprite(scene, DoctorIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0).setDepth(scene.mc.depth + 1)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(DoctorAnims.WalkUp, false))
        .onTopRightMovement(anim(DoctorAnims.WalkUp, true))
        .onBottomLeftMovement(anim(DoctorAnims.WalkDown, true))
        .onBottomRightMovement(anim(DoctorAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(DoctorIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}



const createNPC = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const sickSprite = scene.add.sprite(0, 0, SickPerson.key)
        .setScale(scene.mc.scale)
        .setX(3486 * scaleX)
        .setY(358 * scaleY + y)
        .setDepth(scene.mc.depth + 2)
    scene.add.sprite(sickSprite.x, sickSprite.y, SickBed.key)
        .setScale(sickSprite.scale)
    return sickSprite;
}