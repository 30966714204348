export const WaitingBusSheets = {
    PREGNANT_STAND: {
        key: 'pregnantStandSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/pregnantStand.png',
        frameWidth: 94 / 2,
        frameHeight: 131
    },
    PREGNANT_WALK_DOWN: {
        key: 'pregnantWalkDownSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/pregnantWalkingDown.png',
        frameWidth: 206 / 3,
        frameHeight: 290 / 2
    },
    PREGNANT_WALK_UP: {
        key: 'pregnantWalkUpSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/pregnantWalkingUp.png',
        frameWidth: 169 / 3,
        frameHeight: 276 / 2
    },
    RANDOM_GUY_ONE: {
        key: 'situationFourRandomOneSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/randomGuy1.png',
        frameWidth: 212 / 3,
        frameHeight: 258 / 2
    },
    RANDOM_GUY_TWO: {
        key: 'situationFourRandomTwoSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/randomGuy2.png',
        frameWidth: 208 / 3,
        frameHeight: 262 / 2
    },
    RANDOM_GUY_THREE: {
        key: 'situationFourRandomThreeSheet',
        url: 'SocialInclusionAssets/situations/waitingBus/randomGuy3.png',
        frameWidth: 338 / 3,
        frameHeight: 268 / 2
    }
}

export const WaitingBusAssets = {
    Images: [],
    SpriteSheets: Object.values(WaitingBusSheets)
}