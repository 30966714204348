import Phaser from 'phaser';
import { Badges } from '../../utils/assetsPaths/badges';
import { StealingPersonSheets } from '../../utils/assetsPaths/situations/city/stealingPerson';
import Situation from '../../utils/classes/situation';
import { generateFrameNames, generateFrameNamesByArray, addDialogTween } from '../../utils/functions';
import { createSecondaryFollower, createCharacterSprite } from '../../utils/factory';

const Vector2 = Phaser.Math.Vector2;

class StealingPersonSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 3319, y: 1865 };
        const mcPosition = { x: 2932, y: 1276 };
        super(scene, markPosition, mcPosition, Badges.POLICE);
        this.instructionLabel = "Un extraño se acerca y empieza a hablar contigo, ¿qué haces?"
        this.optionsData = [{
            label: 'Le escucho y le pregunto su nombre.',
            onclick: () => this.scene.time.addEvent({
                delay: 2500,
                callback: () => this.acceptInviteSequence()
            })
        }, {
            label: 'Me alejo.',
            onclick: () => this.scene.time.addEvent({
                delay: 2500,
                callback: () => this.sendMcAwaySequence()
            })
        }, {
            label: 'Aviso a la policía.',
            onclick: () => this.scene.time.addEvent({
                delay: 2500,
                callback: () => this.callPoliceSequence()
            })
        }]
    }

    preload() {
        const anims = this.scene.anims;
        anims.create({
            key: StealingPersonSheets.Seesaw.key,
            frames: anims.generateFrameNames(StealingPersonSheets.Seesaw.key),
            frameRate: 5,
            repeat: -1
        });
        anims.create({
            key: StealingPersonSheets.Slide.key,
            frames: generateFrameNames(StealingPersonSheets.Slide.key, 0, 14, 1),
            frameRate: 6,
            repeat: -1
        });
        Object.values(ThiefAnims).forEach(ta => anims.create(ta));
        Object.values(PoliceAnims).forEach(pa => anims.create(pa));
    }

    create() {
        super.create();
        this.dummySprites = createChildrenSprites(this.scene);
        this.dummySprites.forEach(sprite => sprite.anims.pause());
        this.thief = createThief(this.scene);
        this.scene.events.on('update', this.thief.moveSprite)
            .once('shutdown', () =>
                this.scene.events.off('update', this.thief.moveSprite));
        return this;
    }

    reboot(onComplete) {
        destroyThief(this);
        this.thief = createThief(this.scene);
        this.scene.events.on('update', this.thief.moveSprite)
            .once('shutdown', () =>
                this.scene.events.off('update', this.thief.moveSprite));
        this.thief.sprite.setAlpha(1);
        onComplete();
    }

    acceptInviteSequence() {
        const delayCallback = (callback) => () => this.scene.time.addEvent({
            delay: 1500,
            callback
        })
        const { BEEN_STOLEN, FINISH_STOLEN } = this.scene.game.ludo.character.anims;
        const BSSheet = this.scene.game.ludo.character.sheets.BEEN_STOLEN;
        const sprite = this.thief.sprite;
        this.thief.sprite.play(ThiefAnims.SPEAK.key)
            .once('animationcomplete', delayCallback(() => {
                this.scene.moveMcTo(2955, 1705, () => {
                    this.scene.mc.play(BEEN_STOLEN.key)
                        .once('animationcomplete', delayCallback(leaveThief))
                });
            }))

        const leaveThief = () => {
            this.scene.events.off('update', this.thief.moveSprite);
            this.scene.tweens.add({
                targets: sprite,
                delay: 500,
                y: this.scene.background.getBottomCenter().y + sprite.displayHeight,
                onStart: () => {
                    sprite.play(ThiefAnims.RUN.key);
                    this.scene.mc.setTexture(BSSheet.key, 7);
                },
                onComplete: () => this.scene.mc.play(FINISH_STOLEN.key)
                    .once('animationcomplete', () => {
                        this.scene.mc.setTexture(BSSheet.key, 0);
                        addDialogTween(this.scene, "El extraño te ha robado");
                        this.dummySprites.forEach(sprite => sprite.anims.pause());
                        const garbage = [this.thief.follower, this.thief.sprite]
                        this.scene.tweens.add({
                            targets: garbage,
                            alpha: 0,
                            onComplete: () => garbage.forEach(item => item.destroy())
                        });
                    }),
                duration: 3000
            })
        }
    }

    sendMcAwaySequence() {
        const { REFUSE } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(REFUSE.key)
            .once('animationcomplete', () => this.scene.moveMcTo(2948, 1256,
                () => {
                    addDialogTween(this.scene, "Te has alejado del extraño", this.badge);
                    this.dummySprites.forEach(sprite => sprite.anims.pause());
                }))
        this.thief.sprite.setTexture(StealingPersonSheets.Thief.key, 1);
    }

    callPoliceSequence() {
        const { SCARED } = this.scene.game.ludo.character.anims;
        this.police = createPolice(this.scene);
        const leavePeople = () => {
            this.thief.follower.goTo(new Vector2(2024, 1961))
                .onceMovementComplete(() =>
                    this.scene.tweens.add({ targets: this.thief.sprite, alpha: 0 }));
            this.police.follower.goTo(new Vector2(2934, 1212))
                .onceMovementComplete(() =>
                    this.scene.tweens.add({
                        targets: this.police.sprite, alpha: 0,
                        onComplete: () => {
                            addDialogTween(this.scene, "La policía te brindo apoyo", this.badge);
                            this.dummySprites.forEach(sprite => sprite.anims.pause());
                        }
                    }));
        }
        this.scene.mc.play(SCARED.key)
            .once('animationcomplete', () => addPolice(this,
                () => this.scene.time.addEvent({ delay: 2000, callback: leavePeople })));
    }

    startSituation() {
        this.scene.mc.setFlipX(false)
        this.dummySprites.forEach(sprite => sprite.anims.play());
        const addDelayCb = (delay, callback) =>
            this.scene.time.addEvent({ delay, callback });
        this.thief.sprite.play(ThiefAnims.LOOK.key);
        const startTalk = () => {
            this.thief.sprite.setFlipX(true).anims.stop()
                .setTexture(StealingPersonSheets.Thief.key, 1);
            this.scene.mc.setFlipX(true);
            addDelayCb(2500, () => this.showHint(true))
        }
        this.scene.time.addEvent({ delay: 2500, callback: startTalk })
    }

    start() {
        this.scene.mc.setFlipX(false);
        const position = { x: 3050, y: 1699 };
        this.scene.time.addEvent({
            delay: 1000,
            callback: () => this.scene.moveMcTo(position.x, position.y,
                () => this.startSituation())
        })
    }
}

const createThief = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2850, 1699)
    const sprite = createCharacterSprite(scene, StealingPersonSheets.Thief, 0)
        .setScale(scene.mc.scale)
        .setDepth(8);
    const anim = (flipX) => () => sprite.play(ThiefAnims.WALK.key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(true))
        .onTopRightMovement(anim(false))
        .onBottomLeftMovement(anim(true))
        .onBottomRightMovement(anim(false))
        .onMovementComplete(() => sprite.anims.stop());
    const moveSprite = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y)
    return { follower, sprite, moveSprite };
};

const destroyThief = (situation) => {
    const { follower, sprite, moveSprite } = situation.thief;
    follower.destroy();
    sprite.destroy();
    situation.scene.events.off('update', moveSprite);
}

const createPolice = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2107, 1426);
    const sprite = createCharacterSprite(scene, StealingPersonSheets.PoliceAction, 0)
        .setScale(scene.mc.scale).setAlpha(0);
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(PoliceAnims.WalkUp, false))
        .onTopRightMovement(anim(PoliceAnims.WalkUp, true))
        .onBottomLeftMovement(anim(PoliceAnims.WalkDown, true))
        .onBottomRightMovement(anim(PoliceAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(StealingPersonSheets.PoliceAction.key, 0))
    const moveSprite = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);
    return { follower, sprite, moveSprite };
}

const addPolice = ({ scene, police, thief }, onComplete) => {
    const makeThiefLook = () => {
        thief.sprite.toggleFlipX().anims.stop()
            .setTexture(StealingPersonSheets.Thief.key, 1);
        scene.time.addEvent({ duration: 3000, callback: onComplete })
    };
    const activatePolice = () => police.sprite.play(PoliceAnims.Action.key)
        .once('animationcomplete', makeThiefLook);
    police.follower.goTo(new Vector2(2725, 1699))
        .onceMovementComplete(activatePolice);

    scene.events.on('update', police.moveSprite);
    scene.tweens.add({ targets: police.sprite, alpha: 1 });
}

const createChildrenSprites = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const slideKey = StealingPersonSheets.Slide.key;
    const seesawKey = StealingPersonSheets.Seesaw.key;
    const slide = scene.add.sprite(0, 0, slideKey, 0)
        .setScale(scaleX, scaleY)
        .setX(3120 * scaleX, 1871)
        .setY(1871 * scaleY + y)
        .setAlpha(0)
        .play(slideKey);
    const seesaw = scene.add.sprite(0, 0, seesawKey, 0)
        .setScale(scaleX, scaleY)
        .setX(3252 * scaleX)
        .setY(1561 * scaleY + y)
        .setAlpha(0)
        .play(seesawKey);
    scene.tweens.add({ targets: [slide, seesaw], alpha: 1 })
    return [slide, seesaw];
}

const ThiefAnims = {
    LOOK: {
        key: 'thiefLooking',
        frames: generateFrameNamesByArray(StealingPersonSheets.Thief.key, [0, 1]),
        frameRate: 2,
        repeat: -1
    },
    SPEAK: {
        key: 'thiefSpeaking',
        frames: generateFrameNamesByArray(StealingPersonSheets.Thief.key,
            [3, 4]),
        frameRate: 6,
        repeat: 5
    },
    RUN: {
        key: 'thiefRunning',
        frames: generateFrameNames(StealingPersonSheets.Thief.key, 6, 11, 1),
        frameRate: 6,
        repeat: -1
    },
    WALK: {
        key: 'thiefWalking',
        frames: generateFrameNames(StealingPersonSheets.ThiefWalk.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

const PoliceAnims = {
    WalkDown: {
        key: 'policeWalkDown',
        frames: generateFrameNames(StealingPersonSheets.PoliceDown.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    WalkUp: {
        key: 'policeWalkUp',
        frames: generateFrameNames(StealingPersonSheets.PoliceUp.key, 0, 11),
        frameRate: 7,
        repeat: -1
    },
    Action: {
        key: 'policeAction',
        frames: generateFrameNamesByArray(StealingPersonSheets.PoliceAction.key, [
            1, 2
        ]),
        frameRate: 7,
        repeat: 5
    }
}


export default StealingPersonSituation;