import Phaser from "phaser"
import { Btns } from "../utils/assetsPaths/btn";
import { scaleImage } from "../utils/resize";
import { GameFont } from "../utils/constants";
import { Badges } from "../utils/assetsPaths/badges";
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class BadgesContainer extends Phaser.GameObjects.Container {

    constructor(scene, x, y) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.BADGE_CONTAINER.key);
        this.cityLabel = new Text(scene, 0, 0, "Ciudad", BadgesContainerTextStyle).setOrigin(0, 0.5);
        this.homeLabel = new Text(scene, 0, 0, "Hogar", BadgesContainerTextStyle).setOrigin(0, 0.5);
        this.workLabel = new Text(scene, 0, 0, "Trabajo", BadgesContainerTextStyle).setOrigin(0, 0.5);
        this.labels = [this.cityLabel, this.homeLabel, this.workLabel]
        this.add([this.background, ...this.labels]);
        this.createBadges();
    }

    createBadges() {
        this.cityBadges = [
            Badges.FIRE,
            Badges.POLICE,
            Badges.TRAFFIC_LIGHTS,
            Badges.SEAT_BELT,
            Badges.UMBRELLA,
            Badges.SUN,
            Badges.HEALTH,
            Badges.MONEY
        ].map(badge => {
            const img = new Image(this.scene, 0, 0, badge.key);
            img.badge = badge;
            return img;
        });
        this.homeBadges = [
            Badges.MEDIC,
            Badges.FORK,
            Badges.COOKIE,
            Badges.GAME,
            Badges.BOOK,
            Badges.SCARF,
            Badges.GIFT,
        ].map(badge => {
            const img = new Image(this.scene, 0, 0, badge.key);
            img.badge = badge;
            return img;
        });
        this.workBadges = [
            Badges.FACE,
            Badges.SWORD,
            Badges.COIN,
            Badges.SIGN,
            Badges.CAR,
            Badges.STAR,
            Badges.AIR,
            Badges.ICE
        ].map(badge => {
            const img = new Image(this.scene, 0, 0, badge.key);
            img.badge = badge;
            return img;
        });
        this.badges = [
            ...this.cityBadges,
            ...this.homeBadges,
            ...this.workBadges
        ];
        this.setEarnedBadges();
        this.add(this.badges);
    }

    setEarnedBadges() {
        const { cityBadges, homeBadges, workBadges } = this.scene.game.score;
        this.cityBadges.forEach(img => {
            if (cityBadges.indexOf(img.badge) === -1)
                img.setTexture(`${img.texture.key}Gray`)
        })
        this.homeBadges.forEach(img => {
            if (homeBadges.indexOf(img.badge) === -1)
                img.setTexture(`${img.texture.key}Gray`)
        })
        this.workBadges.forEach(img => {
            if (workBadges.indexOf(img.badge) === -1)
                img.setTexture(`${img.texture.key}Gray`)
        })
    }

    resizeLabels() {
        const fontSize = this.background.displayHeight / 8;
        const x = this.background.getLeftCenter().x + fontSize
        this.labels.forEach(label => label.setFontSize(`${fontSize}px`));
        this.labels.forEach(label => label.setX(x));
        this.cityLabel.setY(-this.background.displayHeight / 3);
        this.workLabel.setY(this.background.displayHeight / 3);
    }

    relocateBadges() {
        const cellHeight = this.background.displayHeight / 3;
        const cellWidth = this.badges[0].displayWidth * 1.65;
        const x = this.cityLabel.getRightCenter().x + this.badges[0].displayWidth;
        this.cityBadges.forEach((badge, index) => {
            badge.setPosition(x + index * cellWidth, -cellHeight)
        })
        this.homeBadges.forEach((badge, index) => {
            badge.setX(x + index * cellWidth)
        })
        this.workBadges.forEach((badge, index) => {
            badge.setPosition(x + index * cellWidth, cellHeight)
        })
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.badges[0].setDisplaySize(100, this.background.displayHeight / 4);
        const scale = this.badges[0].scaleY;
        this.badges.forEach(badge => badge.setScale(scale));
        this.resizeLabels();
        this.relocateBadges();

    }
}

const BadgesContainerTextStyle = {
    fontFamily: GameFont,
    fontSize: '5em',
    color: '#F8F38A'
}