import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

export const QuantitySettingKey = {
    ONE: 0,
    TWO: 1,
    THREE: 2,
    FOUR: 3,
    FIVE: 4,
    SIX: 5,
    NINE: 6,
    TWENTY_FOUR: 7,
    FOURTY_EIGHT: 8,
    ONE_HUNDRED_EIGHT: 9
}

export const optionsData = {
    1: {
        key: QuantitySettingKey.ONE,
        title: 'Uno',
        header: 'Un estímulo',
        description: '',
        image: {
            key: 'oneTargetsPreview',
            url: './HabCogGameAssets/settings/targets/oneTarget.png'
        }
    },
    2: {
        key: QuantitySettingKey.TWO,
        title: 'Dos',
        header: 'Dos estímulos',
        description: '',
        image: {
            key: 'twoTargetsPreview',
            url: './HabCogGameAssets/settings/targets/twoTargets.png'
        }
    },
    3: {
        key: QuantitySettingKey.THREE,
        title: 'Tres',
        header: 'Tres estímulos',
        description: '',
        image: {
            key: 'threeTargetsPreview',
            url: './HabCogGameAssets/settings/targets/threeTargets.png'
        }
    },
    4: {
        key: QuantitySettingKey.FOUR,
        title: 'Cuatro',
        header: 'Cuatro estímulos',
        description: '',
        image: {
            key: 'fourTargetsPreview',
            url: './HabCogGameAssets/settings/targets/fourTargets.png'
        }
    },
    5: {
        key: QuantitySettingKey.FIVE,
        title: 'Cinco',
        header: 'Cinco estímulos',
        description: '',
        image: {
            key: 'fiveTargetsPreview',
            url: './HabCogGameAssets/settings/targets/fiveTargets.png'
        }
    },
    6: {
        key: QuantitySettingKey.SIX,
        title: 'Seis',
        header: 'Seis estímulos',
        description: '',
        image: {
            key: 'sixTargetsPreview',
            url: './HabCogGameAssets/settings/targets/sixTargets.png'
        }
    },
    9: {
        key: QuantitySettingKey.NINE,
        title: 'Nueve',
        header: 'Nueve estímulos',
        description: '',
        image: {
            key: 'nineTargetsPreview',
            url: './HabCogGameAssets/settings/targets/nineTargets.png'
        }
    },
    24: {
        key: QuantitySettingKey.TWENTY_FOUR,
        title: 'Veinticuatro',
        header: 'Veinticuatro estímulos',
        description: '',
        image: {
            key: 'twentyFourTargetsPreview',
            url: './HabCogGameAssets/settings/targets/twentyFourTargets.png'
        }
    },
    48: {
        key: QuantitySettingKey.FOURTY_EIGHT,
        title: 'Cuarenta y ocho',
        header: 'Cuarenta y ocho estímulos',
        description: '',
        image: {
            key: 'fourtyEightTargetsPreview',
            url: './HabCogGameAssets/settings/targets/fourtyEightTargets.png'
        }
    },
    108: {
        key: QuantitySettingKey.ONE_HUNDRED_EIGHT,
        title: 'Ciento ocho',
        header: 'Ciento ocho estímulos',
        description: '',
        image: {
            key: 'onehundredEightTargetsPreview',
            url: './HabCogGameAssets/settings/targets/onehundredEightTargets.png'
        }
    }
};

export const quantitySettingHeader = {
    key: SettingKey.QUANTITY,
    name: 'Estímulos',
    icon: PreviewIcons.QUANTITY,
    tileIcon: TileIcons.QUANTITY
};

export const QuantitySettingAssets = Object.values(optionsData).map(({ image }) => image);
