import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { difficultySetting } from "./utils/settings";
import SessionScene from "./scenes/sessionScene";
import { sessionAssets } from "./utils/assets";
import timeSetting from '../HabCogGame/utils/settingsConstants/timeSetting';

const data = {
    name: 'Vía de escape',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './EscapeRouteGameAssets/logo.png',
    iconUrl: './EscapeRouteGameAssets/icon.png',
    thumbnail: './EscapeRouteGameAssets/thumbnail.png',
    getHint: () => 'Toca la figura y mira hacia dónde se mueve.',
    userManual: 'Se presenta un estímulo en el centro de la pantalla. Cuando el usuario lo ' +
        'seleccione se moverá a velocidad constante, en una dirección distinta ' +
        'cada vez, hasta desaparecer. Seguidamente, se le preguntará sobre la ' +
        'dirección que siguió el estímulo, mostrándole opciones de flechas para ' +
        'que seleccione la trayectoria del movimiento.',
    settings: [
        difficultySetting,
        sizeSetting,
        timeSetting
    ]
};

export default function EscapeRouteGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
