import { SettingKey } from "../../HabCogGame/utils/constants";
import { attachChildSettings } from "../../HabCogGame/utils/settingsConstants/functions";
import { colorSetting } from "../../HabCogGame/utils/settingsConstants/colorSetting";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const PlotSettingKey = {
    SMOOTH: 0,
    PLOT: 1
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Muy lento',
            header: '50 unidades',
            description: 'El estímulo se moverá a una velocidad constante de 50 unidades por segundo.',
            image: {
                key: 'verySlowTarget',
                url: './HabCogGameAssets/settings/speed/verySlowTarget.png'
            }
        },
        {
            title: 'Lento',
            header: '150 unidades',
            description: 'El estímulo se moverá a una velocidad constante de 150 unidades por segundo.',
            image: {
                key: 'slowTarget',
                url: './HabCogGameAssets/settings/speed/slowTarget.png'
            }
        },
        {
            title: 'Rápido',
            header: '300 unidades',
            description: 'El estímulo se moverá a una velocidad constante de 300 unidades por segundo.',
            image: {
                key: 'fastTarget',
                url: './HabCogGameAssets/settings/speed/fastTarget.png'
            }
        },
        {
            title: 'Muy rápido',
            header: '600 unidades',
            description: 'El estímulo se moverá a una velocidad constante de 600 unidades por segundo.',
            image: {
                key: 'veryFastTarget',
                url: './HabCogGameAssets/settings/speed/veryFastTarget.png'
            }
        }
    ]
};

const plotSetting = {
    key: SettingKey.PLOT,
    name: 'Apariencia',
    icon: PreviewIcons.PLOT,
    hasChild: true,
    tileIcon: TileIcons.PLOT,
    options: [
        {
            title: 'Liso',
            header: 'Estímulo de un solo color',
            description: 'El estímulo estará pintado completamente de un solo color.',
            image: {
                key: 'noPlotShapes',
                url: './BoingBoingGameAssets/settings/plot/noPlotShapes.png'
            }
        },
        {
            title: 'Trama',
            header: 'Estímulo de varios colores',
            description: 'El estímulo estará pintado de varios colores.',
            image: {
                key: 'plotShapes',
                url: './BoingBoingGameAssets/settings/plot/plotShapes.png'
            }
        }
    ]
};

const plotColorSetting = {
    name: 'Color',
    key: 'dummy',
    icon: PreviewIcons.COLOR,
    tileIcon: TileIcons.COLOR,
    options: [
        {
            title: 'Trama',
            header: 'Varios colores',
            description: 'El estímulo estará pintado de varios colores.',
            image: {
                key: 'plotColorSetting',
                url: './BoingBoingGameAssets/settings/plotColor/plotColor.png',
            }
        }
    ]
};

export const getPlotSetting = () =>
    attachChildSettings(plotSetting, [colorSetting, plotColorSetting]);

export const CustomSettingAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...plotSetting.options.map(({ image }) => image),
    ...plotColorSetting.options.map(({ image }) => image)
]
