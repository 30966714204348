import React from "react";
import LoginForm from "./LoginForm";
import Footer from "../CommonComponents/Footer"
import Universitieslogos from '../CommonComponents/Universitieslogos'
export default function Home(props) {
    return (
        <div className="App">
            <div className="page-container">
                <div className="content-wrap">
                    <LoginForm props={props} />
                    <Universitieslogos />
                </div>

                <Footer />
            </div>
        </div>
    );
}