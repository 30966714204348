import Phaser from 'phaser';
import { RegularHouse, HouseSheets, BurnedHouse, firemanDown, firemanUp } from "../../utils/assetsPaths/situations/city/burningHouse";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from '../../utils/classes/situation';
import { addAlphaTween, addDialogTween, generateFrameNames } from '../../utils/functions';

const Vector2 = Phaser.Math.Vector2;

class BurningHouseSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 2972, y: 273 };
        const mcPosition = { x: 2319, y: 430 };
        super(scene, markPosition, mcPosition, Badges.FIRE);
        this.instructionLabel = "Miras un edificio en llamas, ¿qué haces?";
        this.optionsData = [{
            label: 'Me alejo.',
            onclick: () => this.startLeaveHouseSequence()
        }, {
            label: 'Llamo al servicio de emergencia 911.',
            onclick: () => this.startCall911Sequence()
        }, {
            label: 'Pido ayuda.',
            onclick: () => this.startAskHelpSequence()
        }]
    }

    preload() {
        const anims = this.scene.anims;
        anims.create({
            key: HouseSheets.FireMan.key,
            frames: generateFrameNames(HouseSheets.FireMan.key, 0, 35),
            frameRate: 10
        })
        anims.create({
            key: HouseSheets.Fire.key,
            frames: anims.generateFrameNames(HouseSheets.Fire.key),
            frameRate: 7,
            repeat: -1
        });

        anims.create({
            key: HouseSheets.Smoke.key,
            frames: anims.generateFrameNames(HouseSheets.Smoke.key),
            frameRate: 15,
            repeat: -1
        });

        anims.create({
            key: HouseSheets.FireOff.key,
            frames: anims.generateFrameNames(HouseSheets.FireOff.key),
            frameRate: 10
        });
    }

    reboot(onComplete) {
        this.disposeElements.forEach(item => item.destroy())
        onComplete();
    }

    startLeaveHouseSequence() {
        const label = "Oh no, la casa se ha quemado";
        const { x, y, scaleX, scaleY } = this.smoke.setDepth(5);
        const fire = this.scene.add.sprite(x, y, HouseSheets.Fire.key, 0)
            .setScale(scaleX, scaleY)
            .setAlpha(0)
            .setOrigin(0.5, 0.75)
            .play(HouseSheets.Fire.key).setDepth(5);
        const burnedHouse = createHouseSprite(this.scene)
            .setTexture(BurnedHouse.key);

        const clear = () => {
            addDialogTween(this.scene, label);
            this.smoke.destroy();
            fire.destroy();
            this.disposeElements.push(burnedHouse);
        }
        const burnHouse = () => addAlphaTween(this.scene, burnedHouse, fire, clear);
        const startFire = () => addAlphaTween(this.scene, fire, this.smoke, burnHouse);
        this.scene.moveMcTo(1935, 465, () => {
            this.scene.mc.setFlipX(false);
            startFire();
        })
    }

    sendFiremanToStation(follower, fireman, moveFireman) {
        const label = 'Se ha apagado el incendio';
        follower.onceMovementComplete(() => this.scene.tweens.add({
            targets: fireman,
            alpha: 0,
            onComplete: () => {
                this.scene.events.off('update', moveFireman);
                fireman.destroy();
                follower.destroy();
                addDialogTween(this.scene, label, this.badge);
            }
        }));
        follower.goTo(new Vector2(1091, 630));
    }

    turnOffFire(follower, fireman, moveFireman) {
        fireman.setFlipX(false);
        const destroySmoke = () => this.smoke.destroy();
        const sendToStation = () => this.sendFiremanToStation(follower, fireman, moveFireman);
        fireman.play(HouseSheets.FireMan.key)
            .once('animationcomplete', sendToStation);
        this.scene.tweens.add({
            targets: this.smoke,
            alpha: 0,
            delay: 1500,
            onComplete: destroySmoke
        })
    }

    dispatchFireman() {
        const { follower, fireman, moveFireman } = createFireman(this.scene);
        this.scene.events.on('update', moveFireman);
        follower.onceMovementComplete(() => this.turnOffFire(follower, fireman, moveFireman));
        follower.goTo(new Vector2(2261, 421));
    }

    startCall911Sequence() {
        const { PHONE_CALL, LOOK_UP } = this.scene.game.ludo.character.anims;
        this.scene.moveMcTo(2706, 584, () => this.scene.mc.play(PHONE_CALL.key)
            .setFlipX(true)
            .once('animationcomplete', () => {
                this.scene.mc.setTexture(LOOK_UP.key)
                this.dispatchFireman()
            }));
    }

    startAskHelpSequence() {
        const { SCARED, LOOK_UP } = this.scene.game.ludo.character.anims;
        this.scene.moveMcTo(2706, 584, () => this.scene.mc.play(SCARED.key)
            .setFlipX(true)
            .once('animationcomplete', () => {
                this.scene.mc.setTexture(LOOK_UP.key)
                this.dispatchFireman()
            }));
    }

    start() {
        this.scene.mc.setFlipX(false);
        const scene = this.scene;
        const { tweens, time } = scene;
        this.smoke = createHouseSprite(scene)
            .play(HouseSheets.Smoke.key)
            .setOrigin(0.55, 0.7)
        tweens.add({ targets: this.smoke, alpha: 1 });
        time.addEvent({
            delay: 2500,
            callback: () => this.showHint()
        })
    }
}

const createHouseSprite = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const p = RegularHouse;
    const key = HouseSheets.Smoke.key;
    return scene.add.sprite(p.x * scaleX, p.y * scaleY + y, key, 0)
        .setScale(scaleX, scaleY)
        .setAlpha(0)
}

const createFireman = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 1091, 630);
    const fireman = createCharacterSprite(scene, HouseSheets.FireMan, 0)
    fireman.setScale(fireman.scale)
    const anim = ({ key }, flipX) => () => fireman.setTexture(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(firemanUp, true))
        .onTopRightMovement(anim(firemanUp, false))
        .onBottomLeftMovement(anim(firemanDown, true))
        .onBottomRightMovement(anim(firemanDown, false));
    const moveFireman = () =>
        fireman.setPosition(follower.x * scaleX, follower.y * scaleY + y)
    return { follower, fireman, moveFireman };
}

export default BurningHouseSituation;