import Phaser from 'phaser'
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { getDifferentRandomItem } from "../../HabCogGame/utils/functions";
import { Cursors } from "../../HabCogGame/utils/constants";

const Rectangle = Phaser.Geom.Rectangle;
const Circle = Phaser.Geom.Circle;
const Zone = Phaser.GameObjects.Zone;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, size, quantity } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.hintTime = [2, 1, 0.75, 0.5, 0.25, 0.125][difficulty] * 1000;
        const lineWidth = 15 * window.devicePixelRatio;
        const canvas = this.getAvailableSize();
        const geometries = createGridGeom(quantity, size, lineWidth, canvas);
        this.graphics = this.createGraphics(lineWidth);
        this.setSessionColors();
        this.targets = createTargets(this, geometries);
        this.setStrokeFns(quantity);
        this.startGameplay();
    }

    createGraphics(lineWidth) {
        const padding = lineWidth / 2;
        return this.add.graphics({
            x: padding,
            y: this.hubHeight + padding,
            fillStyle: { color: 0x000000 },
            lineStyle: { color: 0xffffff, width: lineWidth }
        });
    }

    setStrokeFns(quantity) {
        this.isCircleTurn = false;
        const graphics = this.graphics
        this.circleGeom = {
            stroke: ({ circleShape }) => graphics.strokeCircleShape(circleShape),
            fill: ({ circleShape }) => graphics.fillCircleShape(circleShape)

        }
        this.rectGeom = {
            stroke: ({ rectShape }) => graphics.strokeRectShape(rectShape),
            fill: ({ rectShape }) => graphics.fillRectShape(rectShape)
        }
        this.geom = this.rectGeom;
        this.toogleGeom = quantity > 0 ? () => this.toogleStrokeFn() : () => { }
    }

    toogleStrokeFn() {
        this.geom = this.geom === this.rectGeom ? this.circleGeom : this.rectGeom;
    }

    setSessionColors() {
        this.sColors = [0xFF0000, 0x0000FF, 0xFFFF00, 0x1DFF00];
        this.colorIndex = 0;
    }

    getNextColor() {
        const nextIndex = this.colorIndex + 1;
        const length = this.sColors.length;
        this.colorIndex = nextIndex === length ? 0 : this.colorIndex + 1;
        return this.sColors[this.colorIndex];
    }

    startGameplay() {
        this.graphics.clear();
        this.targets.forEach(target => this.geom.stroke(target));
        this.time.addEvent({
            delay: 2000,
            callbackScope: this,
            callback: () => this.showHint()
        })
    }

    showHint() {
        this.currentTarget = getDifferentRandomItem(this.targets, this.currentTarget);
        this.graphics.fillStyle(this.getNextColor());
        this.geom.fill(this.currentTarget);
        this.geom.stroke(this.currentTarget);
        this.time.addEvent({
            delay: this.hintTime,
            callback: () => this.setPointerdown()
        })
    }

    setPointerdown() {
        const { zone } = this.currentTarget;
        this.graphics.fillStyle(0x000000);
        this.geom.fill(this.currentTarget);
        this.geom.stroke(this.currentTarget);
        this.targets.forEach(({ zone }) =>
            zone.once('pointerdown', () => {
                this.showAnswer();
                this.addFail();
            }))
        zone.off('pointerdown').once('pointerdown', () => {
            this.showAnswer();
            this.addHit();
        });
        this.startStandby(() => this.showAnswer())
    }

    showAnswer() {
        this.standbyEvent.destroy();
        this.targets.forEach(({ zone }) => zone.off('pointerdown'));
        this.graphics.clear();
        this.geom.fill(this.currentTarget);
        this.geom.stroke(this.currentTarget);
        this.toogleGeom();
        this.time.addEvent({
            delay: 2000,
            callbackScope: this,
            callback: () => this.startGameplay()
        })
    }
}

const createTwoGeom = (width, height, lineWidth, scale) => {
    const cellWidth = width - lineWidth;
    const cellHeight = height - lineWidth;
    const size = cellWidth * 0.45;
    const x = cellWidth - size;
    const y = cellHeight * (1 - scale);
    const rectList = [
        new Rectangle(0, 0, size, cellHeight * scale),
        new Rectangle(x, y, size, cellHeight * scale)];
    return { rectList, circleList: [0, 0] }
};

const createGridGeom = (quantity, sizeIndex, lineWidth, { width, height }) => {
    const scale = [0.55, 0.70, 0.85, 1][sizeIndex];
    const minSize = width > height ? height : width;
    const size = (minSize - lineWidth) * 0.9 * scale / (quantity > 2 ? 3 : 2);
    const cellWidth = width - lineWidth - size;
    const cellHeight = height - lineWidth - size;
    let geoms = [];
    switch (quantity) {
        case 0:
            return createTwoGeom(width, height, lineWidth, scale);
        case 1:
            geoms = createGeoms(cellWidth, cellHeight, 2, 2, size);
            geoms.rectList.pop();
            geoms.circleList.pop();
            return geoms;
        case 2:
            return createGeoms(cellWidth, cellHeight, 2, 2, size);
        case 3:
            const x = (cellWidth - size) / 2;
            const y = (cellHeight - size) / 2;
            const middleRect = new Rectangle(x, y, size, size);
            const middleCir = new Circle(width / 2, height / 2, size / 2)
            geoms = createGeoms(cellWidth, cellHeight, 2, 2, size);
            geoms.rectList.push(middleRect);
            geoms.circleList.push(middleCir);
            return geoms;
        case 4:
            return createGeoms(cellWidth, cellHeight, 2, 3, size);
        case 5:
            return createGeoms(cellWidth, cellHeight, 3, 3, size);
        default:
            console.log('unhandle num items');
            break;
    }
};

const createGeoms = (cellWidth, cellHeight, rows, cols, size) => {
    const rectList = [];
    const circleList = [];
    const radius = size / 2;
    for (let i = 0; i < rows; i++)
        for (let j = 0; j < cols; j++) {
            const x = j * cellWidth / (cols - 1);
            const y = i * cellHeight / (rows - 1);
            const rect = new Rectangle(x, y, size, size);
            const circle = new Circle(x + radius, y + radius, radius);
            rectList.push(rect);
            circleList.push(circle);
        }
    return { rectList, circleList }
};

const createTargets = (scene, geometries) => {
    const targets = [];
    for (let i = 0; i < geometries.rectList.length; i++) {
        const rectShape = geometries.rectList[i];
        const circleShape = geometries.circleList[i];
        const { x, y, width, height } = rectShape;
        const zone = new Zone(scene, x, y, width, height)
            .setOrigin(0)
            .setInteractive(Cursors.pointerover);
        targets.push({ rectShape, circleShape, zone })
    }
    targets.forEach(({ zone }) => scene.gameArea.add(zone));
    return targets
};
