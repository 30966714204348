const VehiclesPhotos = [
    {
        key: 'vehiclePhoto1',
        url: './photos/vehicles/vehicle1.png'
    }, {
        key: 'vehiclePhoto2',
        url: './photos/vehicles/vehicle2.png'
    }, {
        key: 'vehiclePhoto3',
        url: './photos/vehicles/vehicle3.png'
    }, {
        key: 'vehiclePhoto4',
        url: './photos/vehicles/vehicle4.png'
    }, {
        key: 'vehiclePhoto5',
        url: './photos/vehicles/vehicle5.png'
    }, {
        key: 'vehiclePhoto6',
        url: './photos/vehicles/vehicle6.png'
    }, {
        key: 'vehiclePhoto7',
        url: './photos/vehicles/vehicle7.png'
    }, {
        key: 'vehiclePhoto8',
        url: './photos/vehicles/vehicle8.png'
    }, {
        key: 'vehiclePhoto9',
        url: './photos/vehicles/vehicle9.png'
    },{
        key: 'vehiclePhoto10',
        url: './photos/vehicles/vehicle10.png'
    },{
        key: 'vehiclePhoto11',
        url: './photos/vehicles/vehicle11.png'
    }, {
        key: 'vehiclePhoto12',
        url: './photos/vehicles/vehicle12.png'
    }, {
        key: 'vehiclePhoto13',
        url: './photos/vehicles/vehicle13.png'
    }, {
        key: 'vehiclePhoto14',
        url: './photos/vehicles/vehicle14.png'
    }, {
        key: 'vehiclePhoto15',
        url: './photos/vehicles/vehicle15.png'
    }, {
        key: 'vehiclePhoto16',
        url: './photos/vehicles/vehicle16.png'
    }, {
        key: 'vehiclePhoto17',
        url: './photos/vehicles/vehicle17.png'
    }, {
        key: 'vehiclePhoto18',
        url: './photos/vehicles/vehicle18.png'
    }, {
        key: 'vehiclePhoto19',
        url: './photos/vehicles/vehicle19.png'
    },{
        key: 'vehiclePhoto20',
        url: './photos/vehicles/vehicle20.png'
    }
];

export default VehiclesPhotos;