export const Closet = {
    key: "LudomundoCloset",
    url: "SocialInclusionAssets/situations/lookingDress/closet.png"
}

export const LookingDressSheets = {
    MOM_SCARF: {
        key: "world2Situation6MomHelpScarf",
        url: "SocialInclusionAssets/situations/lookingDress/momScarf.png",
        frameWidth: 239 / 3,
        frameHeight: 410 / 3
    },
    MAN_SCARF: {
        key: "world2Situation6ManScarf",
        url: "SocialInclusionAssets/situations/lookingDress/manScarf.png",
        frameWidth: 574 / 5,
        frameHeight: 546 / 4
    },
    MAN_LAZY: {
        key: "world2Situation6ManLazy",
        url: "SocialInclusionAssets/situations/lookingDress/manLazy.png",
        frameWidth: 243 / 3,
        frameHeight: 403 / 3
    },
    WOMAN_SCARF: {
        key: "world2Situation6womanScarf",
        url: "SocialInclusionAssets/situations/lookingDress/womanScarf.png",
        frameWidth: 572 / 5,
        frameHeight: 558 / 4
    },
    WOMAN_LAZY: {
        key: "world2Situation6womanLazy",
        url: "SocialInclusionAssets/situations/lookingDress/womanLazy.png",
        frameWidth: 267 / 3,
        frameHeight: 405 / 3
    },
}

export const LookingDressAssets = {
    Images: [Closet],
    SpriteSheets: Object.values(LookingDressSheets)
}