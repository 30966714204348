export const Outlines = {
    BTN: {
        key: 'primaryOutlineBtnBackground',
        url: './HabCogGameAssets/outlines/outlineBtn.png'
    },
    ICON: {
        key: 'outlineIcon.png',
        url: './HabCogGameAssets/outlines/outlineIcon.png'
    },
    ACCESIBILITY: {
        key: 'accesibilityOutlineBtnBackground',
        url: './HabCogGameAssets/outlines/outlineAccessibilityBtn.png'
    },
    SETTING_TILE: {
        key: 'settingTileOutlineBackground',
        url: './HabCogGameAssets/outlines/outlineTile.png'
    },
    HUB: {
        key: 'hubOutlineBtnBackground',
        url: './HabCogGameAssets/outlines/outlineHubBtn.png'
    }
}

export const OutlineAssets = Object.values(Outlines);