const TargetFeedback = {
    GOOD_TARGET: {
        key: 'goodTargetImage',
        url: './HabCogGameAssets/correctTarget.png'
    },
    BAD_TARGET: {
        key: 'badTargetImage',
        url: './HabCogGameAssets/wrongTarget.png'
    }
}

export default TargetFeedback;

export const TargetFeedbackAssets = Object.values(TargetFeedback);