import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    LARGE_SIZE: 0,
    SMALL_SIZE: 1,
    COLOR_SHIFT: 2,
    SHAPE_SHIFT: 3
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Tamaño XL',
            header: 'Cambio de gran tamaño',
            description: 'Estímulos de igual forma y color con diferencias significativas\n' +
                '(notables) de tamaño.',
            image: {
                key: 'sizeShiftXS',
                url: './HabCogGameAssets/settings/difficulty/largeSizeShift.png'
            },
        }, {
            title: 'Tamaño XS',
            header: 'Cambio de poco tamaño',
            description: 'Estímulos de igual forma y color con leves diferencias de tamaño.',
            image: {
                key: 'sizeShiftXL',
                url: './HabCogGameAssets/settings/difficulty/sizeShift.png'
            },
        }, {
            title: 'Color',
            header: 'Cambio de colores',
            description: 'Estímulos de igual forma y distinto color.',
            image: {
                key: 'colorShiftImage',
                url: './HabCogGameAssets/settings/difficulty/colorShift.png'
            },
        }, {
            title: 'Forma',
            header: 'Cambio de formas y Colores',
            description: 'Estímulos de igual color y distinta forma.',
            image: {
                key: 'shapeShiftImage',
                url: './HabCogGameAssets/settings/difficulty/shapeShift.png'
            },
        }
    ]
};

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);
