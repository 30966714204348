export const PreviewIcons = {
    DIFFICULTY: {
        key: 'difficultPreviewIcon',
        url: './HabCogGameAssets/previewBox/difficultPreviewIcon.png'
    },
    SIZE: {
        key: 'sizePreviewIcon',
        url: './HabCogGameAssets/previewBox/sizePreviewIcon.png'
    },
    QUANTITY: {
        key: 'quantityPreviewIcon',
        url: './HabCogGameAssets/previewBox/quantityPreviewIcon.png'
    },
    TIME: {
        key: 'timePreviewIcon',
        url: './HabCogGameAssets/previewBox/timePreviewIcon.png'
    },
    COLOR: {
        key: 'colorPreviewIcon',
        url: './HabCogGameAssets/previewBox/colorPreviewIcon.png'
    },
    SHAPE: {
        key: 'shapePreviewIcon',
        url: './HabCogGameAssets/previewBox/shapePreviewIcon.png'
    },
    OUTLINE: {
        key: 'outlinePreviewIcon',
        url: './HabCogGameAssets/previewBox/outlinePreviewIcon.png'
    },
    PLOT: {
        key: 'plotPreviewIcon',
        url: './HabCogGameAssets/previewBox/plotPreviewIcon.png'
    },
    FEEDBACK: {
        key: 'feedbackPreviewIcon',
        url: './HabCogGameAssets/previewBox/feedbackPreviewIcon.png'
    },
    PRESENTATION: {
        key: 'presentationPreviewIcon',
        url: './HabCogGameAssets/previewBox/presentationPreviewIcon.png'
    },
    ROTATE: {
        key: 'rotatePreviewIcon',
        url: './HabCogGameAssets/previewBox/rotatePreviewIcon.png'
    },
    ORIENTATION: {
        key: 'orientationPreviewIcon',
        url: './HabCogGameAssets/previewBox/orientationPreviewIcon.png'
    },
    BUTTONS: {
        key: 'buttonsPreviewIcon',
        url: './HabCogGameAssets/previewBox/buttonsPreviewIcon.png'
    },
    MODEL: {
        key: 'modelPreviewIcon',
        url: './HabCogGameAssets/previewBox/modelPreviewIcon.png'
    },
    TIMEOUT: {
        key: 'timeoutPreviewIcon',
        url: './HabCogGameAssets/previewBox/timeoutPreviewIcon.png'
    },
    CONTRAST: {
        key: 'contrastPreviewIcon',
        url: './HabCogGameAssets/previewBox/contrastPreviewIcon.png'
    }
};

export const PreviewIconsAssets = Object.values(PreviewIcons);