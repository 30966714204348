import Phaser from 'phaser'
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import workshops from '../../Services/workshops';
import { loadingBar, progressbar, textScale, splashImage } from './SplashStructure';


export default class SplashScene extends Phaser.Scene {
    constructor() {
        super('Splash');
    }
    preload() {
        this.game.roundPipeline = this.game.renderer.addPipeline('Custom', new CustomPipeline(this.game));

        this.loadingText = this.clone(textScale)
        this.progressbar = this.clone(progressbar)
        this.loadingBar = this.clone(loadingBar)
        this.splashImage = this.clone(splashImage)

        this.loadingBar = scaleGameObject(this, this.loadingBar)
        this.progressbar = scaleGameObject(this, this.progressbar)
        this.loadingText = scaleGameObject(this, this.loadingText)
        this.splashImage = scaleGameObject(this, this.splashImage)

        //preload Background
        var background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / background.width
        let scaleY = this.sys.canvas.height / background.height
        background.setScale(scaleX, scaleY).setScrollFactor(0)
        var ludoIMG = this.add.image(this.splashImage.objectPositionX, this.splashImage.objectPositionY, 'ludoSplash')
        ludoIMG.setDisplaySize(this.splashImage.objectWidth, this.splashImage.objectHeight)

        //LOADING BAR 
        this.graphics = this.add.graphics();
        this.newGraphics = this.add.graphics();
        var progressBar = new Phaser.Geom.Rectangle(this.loadingBar.objectPositionX, this.loadingBar.objectPositionY, this.loadingBar.objectWidth, this.loadingBar.objectHeight);
        var progressBarFill = new Phaser.Geom.Rectangle(this.progressbar.objectPositionX, this.progressbar.objectPositionY, this.progressbar.objectWidth, this.progressbar.objectHeight);
        this.graphics.fillStyle(0xffffff, 1);
        this.graphics.fillRectShape(progressBar);
        this.newGraphics.fillStyle(0x84609d, 1);
        this.newGraphics.fillRectShape(progressBarFill);
        var font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 50)
        var loadingText = this.add.text(this.loadingText.objectPositionX, this.loadingText.objectPositionY, "Cargando: ", { fontSize: font, fontFamily: 'ArialRounded', fill: '#FFF' });

        //comon assets
        this.load.image('BackgroundCard', '../LaboralInclusionAssets/rectangleBoard.png')
        this.load.image('background', '/LaboralInclusionAssets/background/sceneBackground.png')
        this.load.image('scoreBackground', '/LaboralInclusionAssets/background/scoreBackground.png')
        this.load.image('Bar', '/LaboralInclusionAssets/TopBar/Bar.jpeg')
        this.load.image('homeIcon', '/LaboralInclusionAssets/TopBar/homeIcon.png')
        this.load.image('backIcon', '/LaboralInclusionAssets/TopBar/returnIcon.png')
        this.load.image('soundIcon', '/LaboralInclusionAssets/voiceIcon.png')
        this.load.image('successImage', '/LaboralInclusionAssets/successImage.png')
        this.load.image('mistakeImage', '/LaboralInclusionAssets/mistakeImage.png')
        this.load.image('ludoMingaIcon', '/LaboralInclusionAssets/ludoMingaLogo.png')
        this.load.image('ludoIcon', '/LaboralInclusionAssets/ludo.png')
        this.load.image('playIcon', '/LaboralInclusionAssets/playButton.png')
        this.load.image('blackBackground', '/LaboralInclusionAssets/textBackground.png')
        this.load.image('spiner', '/LaboralInclusionAssets/Modal/spinner.png')


        //Buttons
        this.load.image('sequenceButton', '/LaboralInclusionAssets/Buttons/sequenceButton.png')
        this.load.image('exitButton', '/LaboralInclusionAssets/Buttons/exitButton.png')
        this.load.image('zoomButton', '/LaboralInclusionAssets/Buttons/accesibilityButton.png')
        this.load.image('soundBar', '/LaboralInclusionAssets/Buttons/soundBarButton.png')
        this.load.image('noVoiceButton', '/LaboralInclusionAssets/Buttons/noVoiceButton.png')
        this.load.image('voiceButton', '/LaboralInclusionAssets/Buttons/voiceButton.png')


        //Load Modal Assets
        this.load.image('spiner', '../LaboralInclusionAssets/Modal/spinner.png')
        this.load.image('modalBackground', '../LaboralInclusionAssets/Modal/modalBackground.png')
        this.load.image('sendButton', '../LaboralInclusionAssets/Modal/sendButton.png')
        this.load.image('dontSendButton', '../LaboralInclusionAssets/Modal/dontSendButton.png')
        this.load.image('retryButton', '../LaboralInclusionAssets/Modal/retryButton.png')
        this.load.image('modalWinCard', '../LaboralInclusionAssets/Modal/modalWinCard.png')

        //Load Assets
        this.load.image('TriangleButton', '../LaboralInclusionAssets/rigthArrow.png')
        this.load.image('TriangleButtonInv', '../LaboralInclusionAssets/leftArrow.png')

        //Image
        this.load.image('bar', '/LaboralInclusionAssets/Bar.png');
        this.load.image('warningStories', '../LaboralInclusionAssets/InfoCards/withoutStoriesCard.png')
        this.load.image('warningWorkshops', '../LaboralInclusionAssets/InfoCards/withoutWorkshopCard.png')
        this.load.image('warningVocabulary', '../LaboralInclusionAssets/InfoCards/withoutVocabularyCard.png')

        //vocabulary Assets
        this.load.image('playButton', '../LaboralInclusionAssets/Buttons/playButton.png')

        //score Assets
        this.load.image('summitButton', '../LaboralInclusionAssets/btnEnviar.png')

        //load outline assets
        this.load.image('accesiblityOutline', '../LaboralInclusionAssets/outlines/accesibilityButton.png')
        this.load.image('rectangleBoardOutline', '../LaboralInclusionAssets/outlines/rectangleBoardOutline.png')
        this.load.image('buttonOutline', '../LaboralInclusionAssets/outlines/sequenceOutline.png')
        this.load.image('buttonOutlineScore', '../LaboralInclusionAssets/outlines/outlineTile.png')
        this.load.image('hubOutline', '../LaboralInclusionAssets/outlines/outlineCircleButton.png')

        //sound Element
        this.load.audio('clickSound', 'LaboralInclusionAssets/Sounds/click2.mp3')
        this.load.audio('aplauseSound', 'LaboralInclusionAssets/Sounds/applause3.mp3')

        //load in-game Sound
        this.load.audio('errorSound', '../LaboralInclusionAssets/Sounds/boing.mp3')
        this.load.audio('successSound', '../LaboralInclusionAssets/Sounds/hitSound.mp3')
        //load Service workshops.getWorkshops(1)
        try {
            this.load.json('levelData', workshops.getWorkshops(this.game.data.organizationID));
            this.load.json('loaderror', () => {
                console.log('error')
                this.workshops = []
            });
            this.workshops = []
            this.load.on('filecomplete-json-levelData', (key, type, data) => {
                console.log("cargado JSON ")
                for (var i = 0; i < data.length; i++) {
                    this.workshops.push(data[i])
                    this.load.image(data[i].id, data[i].image);
                }

            }
            )

        } catch (error) {
            console.log(error)
        }
        //load audio
        this.load.audio('errorSound', '../LaboralInclusionAssets/Sounds/boing.mp3')
        this.load.audio('successSound', '../LaboralInclusionAssets/Sounds/hitSound.mp3')

        //font load
        this.arialRoundedFont = new window.FontFace('ArialRounded', 'url(/LaboralInclusionAssets/font/ArialRoundedMT.ttf)');
        this.arialRoundedFont.load()
            .then(loadedFont => document.fonts.add(loadedFont))
            .catch((e) => console.log('error al cargar la fuente', e));

        //load Functions
        this.load.on('progress', this.updateBar, { newGraphics: this.newGraphics, loadingText: loadingText, scale: this.progressbar });

        this.load.on('complete', function () {
            this.scene.scene.start("Workshops")
        });

    }
    updateBar(percentage) {
        this.newGraphics.clear();
        this.newGraphics.fillStyle(0x84609d, 1);
        this.newGraphics.fillRectShape(new Phaser.Geom.Rectangle(this.scale.objectPositionX, this.scale.objectPositionY, percentage * this.scale.objectWidth, this.scale.objectHeight));
        percentage = percentage * 100;
        this.loadingText.setText("Cargando: " + percentage.toFixed(2) + "%");
    }
    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }


}
var CustomPipeline = new Phaser.Class({

    Extends: Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline,

    initialize:

        function CustomPipeline(game) {
            Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline.call(this, {
                game: game,
                renderer: game.renderer,
                fragShader: `
                precision mediump float;
                uniform sampler2D uMainSampler;
                varying vec2 outTexCoord;
                void main(void){
                    if (length(outTexCoord.xy - vec2(0.5, 0.5)) > 0.5) {
                        discard;
                    } else {
                        gl_FragColor = texture2D(uMainSampler, outTexCoord);
                    }
                }`
            });
        }


});

