import Phaser from "phaser";
import { Btns } from "../utils/assetsPaths/btn";
import { GameFont } from "../utils/constants";
import { scaleImage } from "../utils/resize";

export default class OptionBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label) {
        super(scene, x, y);
        this.background = new Phaser.GameObjects.Image(scene, 0, 0, Btns.SITUATION_OP.key);
        this.border = new Phaser.GameObjects.Image(scene, 0, 0, Btns.CHARACTER_BORDER.key).setAlpha(0);
        this.icon = new Phaser.GameObjects.Image(scene, 0, 0, Btns.SOUND_ICON.key).setOrigin(1, 0)
            .on("pointerdown", () => scene.game.voice.speakHelpText(label))
        this.label = new Phaser.GameObjects.Text(scene, 0, 0, label, OptionBoxTextStyle)
            .setOrigin(0.5, 0.5);
        this.add([
            this.background,
            this.border,
            this.label,
            this.icon
        ])
    }

    resizeLabel() {
        const { displayWidth, displayHeight } = this.background;
        this.label.setWordWrapWidth(displayWidth * 0.9).setFontSize(`${displayHeight * 0.1}px`)
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier)
        this.border.setScale(this.background.scale);
        const pos = this.background.getTopRight();
        this.icon.setScale(this.background.scale)
            .setPosition(pos.x, pos.y)
        this.resizeLabel();
        return this;
    }

    setInteractive() {
        this.background.setInteractive({ useHandCursor: true });
        this.icon.setInteractive({ useHandCursor: true });
        this.setEvents();
        return this;
    }

    setEvents() {
        this.background.on('pointerover', () => {
            this.increaseSize();
            this.border.setAlpha(1);
        });
        this.background.on('pointerout', () => {
            this.decreaseSize();
            this.border.setAlpha(0);
        });
        this.icon.on('pointerover', () => {
            this.increaseSize();
            this.border.setAlpha(1);
        });
        this.icon.on('pointerout', () => {
            this.decreaseSize();
            this.border.setAlpha(0);
        });
    }

    increaseSize() {
        this.addScaleTween(1.2);
    }

    decreaseSize() {
        this.addScaleTween(1);
    }

    addScaleTween(scale) {
        this.scene.tweens.add({
            targets: this,
            scale,
            duration: 125,
            ease: 'Power3'
        });
    }

    onclick(callback) {
        this.background.on('pointerdown', callback);
        return this;
    }
}

const OptionBoxTextStyle = {
    fontFamily: GameFont,
    color: '#204659',
    align: 'center'
}