import Phaser from "phaser";
import { GameFont } from "../utils/constants";
import { Icons } from "../utils/assetsPaths/icons";
const Image = Phaser.GameObjects.Image;
const Rectangle = Phaser.GameObjects.Rectangle;
const Text = Phaser.GameObjects.Text;

export default class MenuBar extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, color = 0x2F7BA1) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color).setOrigin(0);
        this.label = new Text(scene, 0, 0, label, HeaderBarTextStyle).setOrigin(0.5);
        this.icon = new Image(scene, 0, 0, Icons.GAME_ICON.key);
        this.add([
            this.background,
            this.label,
            this.icon
        ]);
    }

    getHeight() {
        return this.background.displayHeight;
    }

    resize(width, height) {
        const cellHeight = height / 2;
        this.background.setDisplaySize(width, height);
        this.label.setFontSize(`${height * 0.35}px`)
            .setPosition(width / 2, cellHeight);
        this.icon.setPosition(cellHeight, cellHeight)
            .setDisplaySize(cellHeight, cellHeight);
        return this;
    }

    alignLabel(widthLimit) {
        if (this.label.getRightCenter().x > widthLimit)
            this.label.setX(widthLimit / 2)
    }
}

const HeaderBarTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'center'
}