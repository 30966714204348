import Phaser from "phaser";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { BooksFull, SearchingBooksSheets, BooksEmpty } from "../../utils/assetsPaths/situations/home/searchingBooks";
import { addDialogTween, generateFrameNames, generateFrameNamesByArray } from "../../utils/functions";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { MotherAnims } from "./sickPerson";
import { MomIdle } from "../../utils/assetsPaths/situations/home/sickPerson";

export default class SearchingBooksSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1569, y: 1932 };
        const mcPosition = { x: 1390, y: 1760 };
        super(scene, markPosition, mcPosition, Badges.BOOK);
        this.instructionLabel = "Al tomar un libro, varios cayeron al piso, ¿qué haces?";
        this.optionsData = [{
            label: "Me voy, alguien los ordenará.",
            onclick: this.runEvent(2500, () => this.leaveBooks())
        }, {
            label: "Pongo los libros en su lugar.",
            onclick: this.runEvent(2500, () => this.orderBooks())
        }, {
            label: "Aviso lo que pasó y pido ayuda para acomodarlos.",
            onclick: this.runEvent(2500, () => this.callMom())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(Anims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.books = this.scene.add.sprite(0, 0, BooksFull.key)
            .setScale(this.scene.mc.scale / 2)
            .setX(1230 * scaleX)
            .setY(1596 * scaleY + y)
        return this;
    }

    leaveBooks() {
        this.scene.mc.setFlipX(false)
            .play(this.mcIsBoy ? Anims.MAN_LEAVE_BOOKS.key : Anims.WOMAN_LEAVE_BOOKS.key)
            .once("animationcomplete", () => this.scene.tweens.add({
                targets: this.scene.follower,
                onStart: () => this.scene.mc.play(this.mcIsBoy ? Anims.MAN_BOOK_WALK.key : Anims.WOMAN_BOOK_WALK.key),
                x: 1390 + 100,
                duration: 1500,
                onComplete: () => this.scene.mc
                    .play(this.mcIsBoy ? Anims.MAN_TAKE_SIT.key : Anims.WOMAN_TAKE_SIT.key)
                    .once("animationcomplete",
                        this.runEvent(2500, () => addDialogTween(this.scene, "Dejaste las cosas desordenadas")))
            }))
    }

    orderBooks() {
        this.scene.mc.setFlipX(false)
            .play(this.mcIsBoy ? Anims.MAN_ORDER_BOOK.key : Anims.WOMAN_ORDER_BOOK.key)
            .once("animationcomplete", () => {
                this.books.setTexture(BooksFull.key)
                this.addEvent(2000, () => this.scene.tweens.add({
                    targets: this.scene.follower,
                    onStart: () => this.scene.mc
                        .play(this.mcIsBoy ? Anims.MAN_BOOK_WALK.key : Anims.WOMAN_BOOK_WALK.key),
                    x: 1390 + 100,
                    duration: 1500,
                    onComplete: () => this.scene.mc
                        .play(this.mcIsBoy ? Anims.MAN_TAKE_SIT.key : Anims.WOMAN_TAKE_SIT.key)
                        .once("animationcomplete",
                            this.runEvent(2500, () => addDialogTween(this.scene, "Todo quedó ordenado", this.badge)))
                }))
            })
    }

    callMom() {
        const callMomKey = this.mcIsBoy ? Anims.MAN_CALL_MOM.key : Anims.WOMAN_CALL_MOM.key;
        const stepBackKey = this.mcIsBoy ? Anims.MAN_STEP_BACK.key : Anims.WOMAN_STEP_BACK.key;
        this.mom = createMother(this.scene);
        attachMotherToEvents(this.scene, this.mom);
        this.scene.mc.play(callMomKey)
            .once("animationcomplete", this.runEvent(2000, () => this.scene.tweens.add({
                targets: this.scene.follower,
                x: 1390 + 100,
                onStart: () => this.scene.mc.play(stepBackKey),
                onComplete: () => {
                    this.scene.tweens.add({
                        targets: this.mom.sprite,
                        alpha: 1
                    })
                    this.mom.follower.goTo(new Phaser.Math.Vector2(1390, 1760))
                        .onceMovementComplete(() => {
                            this.mom.sprite.setFlipX(false);
                            this.addEvent(2000, () =>
                                this.mom.sprite.play(Anims.MOM_HELP.key)
                                    .once("animationcomplete", () => {
                                        this.books.setTexture(BooksFull.key)
                                        this.addEvent(2000, () => {
                                            this.scene.events.off(this.mom.move)
                                            this.mom.sprite.destroy();
                                            this.mom.follower.destroy();
                                            addDialogTween(this.scene, "Todo quedó ordenado", this.badge)
                                        })
                                    }
                                    ))
                        })
                }
            })))
    }

    reboot(onComplete) {
        this.books.setTexture(BooksFull.key);
        this.scene.follower.emit("onMovementComplete")
        onComplete();
    }

    start() {
        this.scene.mc.setFlipX(true);
        this.addEvent(2000, () => this.scene.mc.setFlipX(false)
            .play(this.mcIsBoy ? Anims.MAN_TAKE_BOOK.key : Anims.WOMAN_TAKE_BOOK.key)
            .once("animationcomplete", () => {
                this.books.setTexture(BooksEmpty.key);
                this.addEvent(1000,
                    () => this.scene.mc.play(this.mcIsBoy ? Anims.MAN_DISORDER.key : Anims.WOMAN_DISORDER.key)
                        .once("animationcomplete", this.runEvent(2000, () => this.showHint())))
            }))
    }
}

const Anims = {
    MOM_HELP: {
        key: 'momHelpBooks',
        frames: generateFrameNames(SearchingBooksSheets.MOM_HELP.key, 0, 16),
        frameRate: 2
    },
    MAN_TAKE_BOOK: {
        key: 'manTakeBooks',
        frames: generateFrameNames(SearchingBooksSheets.MAN_LEAVE.key, 0, 4),
        frameRate: 2
    },
    MAN_DISORDER: {
        key: 'manDisorderBooks',
        frames: generateFrameNames(SearchingBooksSheets.MAN_LEAVE.key, 5, 8),
        frameRate: 2
    },
    MAN_LEAVE_BOOKS: {
        key: "manLeaveBooks",
        frames: generateFrameNames(SearchingBooksSheets.MAN_LEAVE.key, 9, 11),
        frameRate: 2
    },
    MAN_BOOK_WALK: {
        key: "manBooksWalk",
        frames: generateFrameNames(SearchingBooksSheets.MAN_LEAVE.key, 12, 14),
        frameRate: 2
    },
    MAN_TAKE_SIT: {
        key: "manTakeSitBooks",
        frames: generateFrameNames(SearchingBooksSheets.MAN_LEAVE.key, 15, 18),
        frameRate: 2
    },
    MAN_ORDER_BOOK: {
        key: "manOrderBooks",
        frames: generateFrameNames(SearchingBooksSheets.MAN_SORT.key, 9, 22),
        frameRate: 2
    },
    MAN_CALL_MOM: {
        key: "manCallBookMom",
        frames: generateFrameNamesByArray(SearchingBooksSheets.MAN_GET_HELP.key, [
            9, 10, 11, 11, 12, 11, 11, 12
        ]),
        frameRate: 1
    },
    MAN_STEP_BACK: {
        key: "manBookStepBack",
        frames: generateFrameNames(SearchingBooksSheets.MAN_GET_HELP.key, 14, 16),
        frameRate: 2
    },
    WOMAN_TAKE_BOOK: {
        key: 'womanTakeBooks',
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_LEAVE.key, 0, 4),
        frameRate: 2
    },
    WOMAN_DISORDER: {
        key: 'womanDisorderBooks',
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_LEAVE.key, 5, 8),
        frameRate: 2
    },
    WOMAN_LEAVE_BOOKS: {
        key: "womanLeaveBooks",
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_LEAVE.key, 9, 11),
        frameRate: 2
    },
    WOMAN_BOOK_WALK: {
        key: "womanBookWalk",
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_LEAVE.key, 12, 14),
        frameRate: 2
    },
    WOMAN_TAKE_SIT: {
        key: "womanTakeSitBooks",
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_LEAVE.key, 15, 18),
        frameRate: 2
    },
    WOMAN_ORDER_BOOK: {
        key: "womanOrderBooks",
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_SORT.key, 9, 22),
        frameRate: 2
    },
    WOMAN_CALL_MOM: {
        key: "womanCallBookMom",
        frames: generateFrameNamesByArray(SearchingBooksSheets.WOMAN_GET_HELP.key, [
            9, 10, 11, 11, 12, 11, 11, 12
        ]),
        frameRate: 1
    },
    WOMAN_STEP_BACK: {
        key: "womanBookStepBack",
        frames: generateFrameNames(SearchingBooksSheets.WOMAN_GET_HELP.key, 14, 16),
        frameRate: 2
    },
}

const createMother = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2146, 1542);
    const sprite = createCharacterSprite(scene, MomIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0).setDepth(scene.mc.depth + 1)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(MotherAnims.WalkUp, false))
        .onTopRightMovement(anim(MotherAnims.WalkUp, true))
        .onBottomLeftMovement(anim(MotherAnims.WalkDown, true))
        .onBottomRightMovement(anim(MotherAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(MomIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}

const attachMotherToEvents = (scene, mom) => {
    scene.events.on("update", mom.move);
    scene.events.once("shutdown",
        () => scene.events.off("update", mom.move));
}