import { createDialog } from "./factory";
import { calculateImageScale } from "./resize";
import { SceneKey } from "./constants";

export const createCharacterAnim = (scene, characterAnim) => {
    const anims = scene.anims;
    const { key, frameRate, repeat } = characterAnim;
    const frames = characterAnim.frames || anims.generateFrameNames(key);
    anims.create({
        key,
        frames,
        frameRate: frameRate,
        repeat
    })
}

export const addAlphaTween = (scene, targetOn, targetOff, onComplete) => {
    scene.tweens.addCounter({
        from: 0, to: 1000,
        duration: 5000,
        onUpdate: (tween) => {
            const value = tween.getValue() / 1000;
            targetOff.setAlpha(1 - tween.getValue() / 1000);
            targetOn.setAlpha(value);
        },
        onComplete
    })
}

export const addBadgeToScore = (scene, badge) => {
    const key = scene.scene.key;
    const score = scene.game.score;
    switch (key) {
        case SceneKey.CITY_WORLD:
            if (score.cityBadges.indexOf(badge) === -1)
                score.cityBadges.push(badge);
            break;
        case SceneKey.HOME_WORLD:
            if (score.homeBadges.indexOf(badge) === -1)
                score.homeBadges.push(badge);
            break;
        case SceneKey.JOB_WORLD:
            if (score.workBadges.indexOf(badge) === -1)
                score.workBadges.push(badge);
            break;
        default:
            console.log("undefined");

    }
}

export const addDialogTween = (scene, label, badge) => {
    const targets = createDialog(scene, label, badge);
    if (badge)
        addBadgeToScore(scene, badge);
    scene.tweens.add({
        targets,
        scale: { from: 0, to: 1 },
        ease: 'Power3',
        yoyo: true,
        hold: 2000,
        duration: 500,
        onStart: () => {
            targets.setAlpha(1);
        },
        onComplete: () => {
            targets.destroy();
            if (badge) {
                scene.addHit();
                showBadge(scene, badge)
            }
            else {
                scene.addFail();
                scene.enableSituations();
            }
        }
    })
}

export const showBadge = (scene, badge) => {
    const { width, height } = scene.game.canvas;
    const img = scene.add.image(width / 2, height / 2, badge.key).setDepth(10);
    const badgeScale = calculateImageScale(img.width, img.height, width, height * 0.65, 50);
    const onComplete = () => addBadgeToHeader(scene, badge, img);
    img.setScale(0);

    scene.tweens.add({
        targets: img,
        scale: badgeScale,
        duration: 500,
        ease: 'Power3',
        onComplete
    })
}

export const addBadgeToHeader = (scene, badge, img) => {
    const { x, y, scale, index } = scene.header.getBadgePosition(badge);
    scene.tweens.add({
        targets: img,
        x, y, ease: 'Power3',
        scale,
        delay: 1500,
        onComplete: () => {
            img.destroy();
            scene.header.setBadgeAlpha(index, 1);
            scene.enableSituations();
        }
    })
}

export const generateFrameNamesByArray = (key, array) => {
    const frameNames = [];
    array.forEach(item => frameNames.push({
        key, frame: item
    }))
    return frameNames;
}

export const generateFrameNames = (key, from, to, step = 1) => {
    const frameNames = [];
    for (let i = from; i <= to; i += step)
        frameNames.push({
            key,
            frame: i
        })
    return frameNames;
}
