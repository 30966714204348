export const Waffle = {
    key: "world2SituationNWaffle",
    url: 'SocialInclusionAssets/situations/gettingHungry/cook.png'
}

export const GettingHungrySheets = {
    MAN_COOK: {
        key: "world2SituationNManCook",
        url: "SocialInclusionAssets/situations/gettingHungry/manCook.png",
        frameWidth: 593 / 6,
        frameHeight: 702 / 5
    },
    MAN_GET_SNACK: {
        key: "world2SituationNManGetSnack",
        url: "SocialInclusionAssets/situations/gettingHungry/manGetSnack.png",
        frameWidth: 494 / 5,
        frameHeight: 574 / 4
    },
    MAN_REFUSE: {
        key: "world2SituationNManRefuse",
        url: "SocialInclusionAssets/situations/gettingHungry/manRefuseCook.png",
        frameWidth: 390 / 6,
        frameHeight: 704 / 5
    },
    WOMAN_COOK: {
        key: "world2SituationNWomanCook",
        url: "SocialInclusionAssets/situations/gettingHungry/womanCook.png",
        frameWidth: 555 / 6,
        frameHeight: 703 / 5
    },
    WOMAN_GET_SNACK: {
        key: "world2SituationNwomanGetSnack",
        url: "SocialInclusionAssets/situations/gettingHungry/womanGetSnack.png",
        frameWidth: 469 / 5,
        frameHeight: 562 / 4
    },
    WOMAN_REFUSE: {
        key: "world2SituationNWomanRefuse",
        url: "SocialInclusionAssets/situations/gettingHungry/womanRefuseCook.png",
        frameWidth: 394 / 6,
        frameHeight: 703 / 5
    },
}

export const GettingHungryAssets = {
    Images: [Waffle],
    SpriteSheets: Object.values(GettingHungrySheets)
}