import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    FULL: 0,
    HORIZONTAL_HALF: 1,
    VERTICAL_HALF: 2,
    QUARTER: 3
};

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Completa',
            header: 'Pantalla completa',
            description: 'El estímulo ocupará toda el área disponible.',
            image: {
                key: 'fullScreenPreview',
                url: './LuminousWindowsGameAssets/settings/fullScreenSize.png'
            }
        }, {
            title: 'Mitad horizontal',
            header: 'Mitad de la pantalla',
            description: 'El estímulo ocupará la mitad horizontal del área disponible.',
            image: {
                key: 'halfHorizontalScreenPreview',
                url: './LuminousWindowsGameAssets/settings/halfHorizontalScreenSize.png'
            }
        }, {
            title: 'Mitad vertical',
            header: 'Mitad de la pantalla',
            description: 'El estímulo ocupará la mitad vertical del área disponible.',
            image: {
                key: 'halfVerticalScreenPreview',
                url: './LuminousWindowsGameAssets/settings/halfVerticalScreenSize.png'
            }
        }, {
            title: '1/4 de pantalla',
            header: '1/4 de la pantalla',
            description: 'El estímulo ocupará un cuarto del área disponible.',
            image: {
                key: 'quarterScreenPreview',
                url: './LuminousWindowsGameAssets/settings/quarterScreenSize.png'
            }
        }
    ]
};

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);
