const ClothesPhotos = [
    {
        key: 'clothesPhoto1',
        url: './photos/clothes/clothes1.png'
    }, {
        key: 'clothesPhoto2',
        url: './photos/clothes/clothes2.png'
    }, {
        key: 'clothesPhoto3',
        url: './photos/clothes/clothes3.png'
    }, {
        key: 'clothesPhoto4',
        url: './photos/clothes/clothes4.png'
    }, {
        key: 'clothesPhoto5',
        url: './photos/clothes/clothes5.png'
    }, {
        key: 'clothesPhoto6',
        url: './photos/clothes/clothes6.png'
    }, {
        key: 'clothesPhoto7',
        url: './photos/clothes/clothes7.png'
    }, {
        key: 'clothesPhoto8',
        url: './photos/clothes/clothes8.png'
    }, {
        key: 'clothesPhoto9',
        url: './photos/clothes/clothes9.png'
    }, {
        key: 'clothesPhoto10',
        url: './photos/clothes/clothes10.png'
    }, {
        key: 'clothesPhoto11',
        url: './photos/clothes/clothes11.png'
    }, {
        key: 'clothesPhoto12',
        url: './photos/clothes/clothes12.png'
    }, {
        key: 'clothesPhoto13',
        url: './photos/clothes/clothes13.png'
    }, {
        key: 'clothesPhoto14',
        url: './photos/clothes/clothes14.png'
    }, {
        key: 'clothesPhoto15',
        url: './photos/clothes/clothes15.png'
    }, {
        key: 'clothesPhoto16',
        url: './photos/clothes/clothes16.png'
    }, {
        key: 'clothesPhoto17',
        url: './photos/clothes/clothes17.png'
    }, {
        key: 'clothesPhoto18',
        url: './photos/clothes/clothes18.png'
    }, {
        key: 'clothesPhoto19',
        url: './photos/clothes/clothes19.png'
    }, {
        key: 'clothesPhoto20',
        url: './photos/clothes/clothes20.png'
    }, {
        key: 'clothesPhoto21',
        url: './photos/clothes/clothes21.png'
    }, {
        key: 'clothesPhoto22',
        url: './photos/clothes/clothes22.png'
    }, {
        key: 'clothesPhoto23',
        url: './photos/clothes/clothes23.png'
    }, {
        key: 'clothesPhoto24',
        url: './photos/clothes/clothes24.png'
    }
]

export default ClothesPhotos;