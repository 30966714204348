import Phaser from 'phaser'
import { VoiceManager } from "./voiceManager";
import { getEmptyUserConfig } from "../functions";

export class BaseGame extends Phaser.Game {

    constructor(config) {
        super(config);
        this.voiceAssistant = new VoiceManager('Paulina');
        this.data = config.gameData;
        this.assets = config.gameData.sessionAssets;
        this.userConfig = getEmptyUserConfig();
        this.gameConfig = {};
        this.isIOSDevice = this.device.os.iOS;
        const isAndroid = this.device.os.android;
        this.isMovileDevice = this.isIOSDevice || isAndroid;
    }
}
