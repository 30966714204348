import React from "react";
import HabCogGame from "../HabCogGame/HabCogGame";
import SessionScene from "./scenes/sessionScene";
import { sizeSetting } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import difficultySetting from "./utils/settings";
import { SomethingInCommonSessionAssets } from "./utils/assets";
import timeSetting from "../HabCogGame/utils/settingsConstants/timeSetting";

const data = {
    name: 'Algo en común',
    sessionAssets: SomethingInCommonSessionAssets,
    sessionScene: SessionScene,
    logoUrl: './SomethingInCommonGameAssets/logo.png',
    iconUrl: './SomethingInCommonGameAssets/icon.png',
    thumbnail: './SomethingInCommonGameAssets/thumbnail.png',
    getHint: () => 'Toca la fotografía que es igual al modelo.',
    userManual: 'Se presenta un conjunto de fotografías que tienen una característica ' +
        'interna en común excepto una, la que debe ser seleccionada por el ' +
        'usuario.',
    settings: [
        difficultySetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function SomethingInCommonGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}