import { SceneKey } from "../utils/constants";
import WorldScene from "./worldScene";
import SickPersonSituation from "../situations/home/sickPerson";
import DisgustingFoodSituation from "../situations/home/disgustingFood";
import GettingHungrySituation from "../situations/home/gettingHungry";
import PlayingCardsSituation from "../situations/home/playingCards";
import SearchingBooksSituation from "../situations/home/searchingBooks";
import LookingDressSituation from "../situations/home/lookingDress";
import CryingBrotherSituation from "../situations/home/cryingBrother";
import { HomeHander, Worlds } from "../utils/assetsPaths/worlds";
import { HouseSheets } from "../utils/assetsPaths/situations/city/burningHouse";

export default class HomeWorld extends WorldScene {

    constructor() {
        super({ key: SceneKey.HOME_WORLD })
        this.startPoint = { x: 3000, y: 1600 }
        this.ludoScale = 2;
        this.worldName = "hogar";
        this.nodeKey = "HOME";
    }

    createSituations() {
        this.situations = [
            new SickPersonSituation(this),
            new DisgustingFoodSituation(this),
            new GettingHungrySituation(this),
            new PlayingCardsSituation(this),
            new SearchingBooksSituation(this),
            new LookingDressSituation(this),
            new CryingBrotherSituation(this)
        ];
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.background;
        this.hands = this.add.sprite(0, 0, HomeHander.key)
            .setOrigin(0)
            .setScale(scaleX, scaleY)
            .setY(y)
        const updateHandDepth = () => {
            const depth = this.follower.y > 645 ? -1 : 1;
            this.hands.setDepth(this.mc.depth + depth);
        }
        this.events.on("update", updateHandDepth)
        this.events.once("shutdown", () => this.events.off("update", updateHandDepth));
        
        this.add.zone(1848 * scaleX, y + 40 * scaleY, 96 * scaleX, 160 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.JOB;
                this.scene.start(SceneKey.JOB_WORLD)
            });
            this.add.zone(1336 * scaleX, y + 616 * scaleY, 160 * scaleX, 152 * scaleY)
            .setOrigin(0)
            .setInteractive({ useHandCursor: true })
            .once("pointerdown", () => {
                this.game.ludo.world = Worlds.CITY;
                this.scene.start(SceneKey.CITY_WORLD);
            });

            this.add.sprite(3562 * scaleX, 1550 * scaleY + y, HouseSheets.CAT.key, 0)
            .setScale(Math.max(scaleX, scaleY)/2)
            .play(this.animalsAnims.CAT.key);
    }
}