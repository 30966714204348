export const succesText = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 200,
    objectHeight: 250,
    objectPositionX: 1100,
    objectPositionY: 265,
}
export const successImage = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 450,
    objectHeight: 100,
    objectPositionX: 950,
    objectPositionY: 300,
}
export const modal = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 900,
    objectHeight: 600,
    objectPositionX: 600,
    objectPositionY: 500,
}
export const observation = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 800,
    objectHeight: 210,
    objectPositionX: 950,
    objectPositionY: 785,

}
export const scoreButton = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 450,
    objectHeight: 100,
    objectPositionX: 950,
    objectPositionY: 980,

}
export const scoreTitle = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 250,
    objectPositionX: 820,
    objectPositionY: 150,

}
export const coments = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 250,
    objectPositionX: 795,
    objectPositionY: 590,

}
export const errorText = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 250,
    objectPositionX: 1100,
    objectPositionY: 415,

}
export const mistakeImage = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 450,
    objectHeight: 100,
    objectPositionX: 950,
    objectPositionY: 450,
}
export const scoreBackground = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 900,
    objectHeight: 970,
    objectPositionX: 955,
    objectPositionY: 580,
}
