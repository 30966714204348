import AnimalsPhotos from "./animals";
import ClothesPhotos from "./clothes";
import PeoplePhotos from "./people";
import FruitsPhotos from "./fruits";
import VehiclesPhotos from "./vehicles";
import CarbsPhotos from "./carbs";
import DairiesPhotos from "./dairies";
import GrainsPhotos from "./grains";
import VegetablesPhotos from "./vegetables";

const PhotosAssets = [
    ...AnimalsPhotos,
    ...ClothesPhotos,
    ...PeoplePhotos,
    ...FruitsPhotos,
    ...VehiclesPhotos,
    ...CarbsPhotos,
    ...DairiesPhotos,
    ...GrainsPhotos,
    ...VegetablesPhotos
];

export default PhotosAssets
