const PeoplePhotos = [
    {
        key: 'personPhoto1',
        url: './photos/people/person1.png'
    }, {
        key: 'personPhoto2',
        url: './photos/people/person2.png'
    }, {
        key: 'personPhoto3',
        url: './photos/people/person3.png'
    }, {
        key: 'personPhoto4',
        url: './photos/people/person4.png'
    }, {
        key: 'personPhoto5',
        url: './photos/people/person5.png'
    }, {
        key: 'personPhoto6',
        url: './photos/people/person6.png'
    }, {
        key: 'personPhoto7',
        url: './photos/people/person7.png'
    }, {
        key: 'personPhoto8',
        url: './photos/people/person8.png'
    }, {
        key: 'personPhoto9',
        url: './photos/people/person9.png'
    }, {
        key: 'personPhoto10',
        url: './photos/people/person10.png'
    }, {
        key: 'personPhoto11',
        url: './photos/people/person11.png'
    }, {
        key: 'personPhoto12',
        url: './photos/people/person12.png'
    }, {
        key: 'personPhoto13',
        url: './photos/people/person13.png'
    }, {
        key: 'personPhoto14',
        url: './photos/people/person14.png'
    }, {
        key: 'personPhoto15',
        url: './photos/people/person15.png'
    }, {
        key: 'personPhoto16',
        url: './photos/people/person16.png'
    }, {
        key: 'personPhoto17',
        url: './photos/people/person17.png'
    }, {
        key: 'personPhoto18',
        url: './photos/people/person18.png'
    }, {
        key: 'personPhoto19',
        url: './photos/people/person19.png'
    }, {
        key: 'personPhoto20',
        url: './photos/people/person20.png'
    }
];

export default PeoplePhotos;