export function scaleGameObject(Scene, currentScale) {

    const currentWidthCanvas = Scene.sys.canvas.width;
    const currentHeightCanvas = Scene.sys.canvas.height;

    const defaultWidthCanvas = 1920;
    const defaultHeightCanvas = 1080;
    const ratioWidth = currentWidthCanvas / defaultWidthCanvas;
    const ratioHeight = currentHeightCanvas / defaultHeightCanvas;

    const newObjectScale = {
        objectWidthRatio: ratioWidth,
        objectHeightRatio: ratioHeight,
        objectWidth: Math.trunc(currentScale.objectWidth * ratioWidth),
        objectHeight: Math.trunc(currentScale.objectHeight * ratioHeight),
        objectPositionX: Math.trunc(currentScale.objectPositionX * ratioWidth),
        objectPositionY: Math.trunc(currentScale.objectPositionY * ratioHeight),
    };
    return newObjectScale;
}

export function ScaleFunctionText(currentWidthCanvas, currentHeightCanvas, currentfontSize) {

    const defaultWidthCanvas = 1920;
    const defaultHeightCanvas = 1080;
    const hypotDefaul = Math.hypot(defaultWidthCanvas, defaultHeightCanvas);

    const hypotCurrent = Math.hypot(currentHeightCanvas, currentWidthCanvas);

    const fontSize = Math.trunc(parseInt(currentfontSize) * hypotCurrent / hypotDefaul);
    return fontSize + 'px';

}

