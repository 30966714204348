import React, { Component } from "react";
import Phaser from "phaser";
import Splash from "./Scenes/Splash-lb"
import WorkshopsScene from "./Scenes/Workshops/WorkshopScene";
import StoriesScene from "./Scenes/Stories/StoriesScene";
import VocabularyScene from "./Scenes/Vocabulary/VocabularyScene";
import SequenceScene from "./Scenes/Sequence/SequenceScene";
import GameScene from "./Scenes/GameSequence/Game";
import ScoreScene from "./Scenes/Score/ScoreScene";
import SplashScene from "./Scenes/Splash/SplashScene";
import { VoiceManager } from "./Plug-ins/voiceManager";




class LaboralInclusionGame extends Component {

    componentDidMount() {
        if (localStorage.getItem('voiceSound') === null) {
            localStorage.setItem('voiceSound', 1)
        }
        var intervention_id = 0
        var organization_id = 1
        var interventionPlan = JSON.parse(localStorage.getItem('interventionPlan')) ? JSON.parse(localStorage.getItem('interventionPlan')) : null
        var organizationID = localStorage.getItem('organizationID') ? localStorage.getItem('organizationID') : null
        if (organizationID) {
            if (interventionPlan) {
                intervention_id = interventionPlan.interventionPlanId
                console.log("tiene plan" + intervention_id)
            }
            organization_id = organizationID
        }

        const config = {
            backgroundColor: '#705F9F',
            type: Phaser.AUTO,
            scene:
                [Splash, SplashScene, WorkshopsScene, StoriesScene, VocabularyScene, SequenceScene, GameScene, ScoreScene],
            scale: {
                parent: 'phaser-game',
                mode: Phaser.Scale.FIT,
                height: (window.innerHeight) * window.devicePixelRatio,
                width: window.innerWidth * window.devicePixelRatio,
            },
            dom: {
                createContainer: true
            }
        };
        this.game = new Phaser.Game(config)
        //aqui estan quemados los datos

        this.game.data = {
            interventionID: intervention_id
            , organizationID: organization_id
        }
        this.game.voice = new VoiceManager('Paulina')


    }
    componentWillUnmount() {
        var voice = new VoiceManager('Paulina')
        voice.speak('')
        this.game.destroy(true);
    }
    shouldComponentUpdate() {
        return true;
    }

    render() {
        return <div id="phaser-game" />;
    }

}


export default LaboralInclusionGame;
