const DairiesPhotos = [
    {
        key: 'dairyPhoto1',
        url: './photos/dairies/dairy1.png'
    }, {
        key: 'dairyPhoto2',
        url: './photos/dairies/dairy2.png'
    }, {
        key: 'dairyPhoto3',
        url: './photos/dairies/dairy3.png'
    }, {
        key: 'dairyPhoto4',
        url: './photos/dairies/dairy4.png'
    }, {
        key: 'dairyPhoto5',
        url: './photos/dairies/dairy5.png'
    }
]

export default DairiesPhotos;