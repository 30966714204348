import axios from 'axios'

const apiClient = axios.create({
    baseURL: `https://service.ludominga.com`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiClient

export const base = {
    url: 'https://service.ludominga.com'
}