import phaser from 'phaser'
import { cardComponentStructure } from './cardCompomponentStructure'
import buttonComponent from '../Button/button'

export default class cardComponent extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, title, image, description, id) {
        super(scene, x, y)

        this.workshopImage = null
        this.workshopTitle = null
        this.workshopId = id
        this.workshopDescription = null
        this.button = null
        this.scene = scene
        this.description = description

        this.generated(scene, x, y, width, heigth, title, image, description, id)


    }

    generated(scene, x, y, width, heigth, title, image, description, id) {
        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);

        //outline
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, 'rectangleBoardOutline').setVisible(false)
        this.outline.setDisplaySize(width * 1.1, heigth * 1.05);

        //Title
        this.workshopTitle = new phaser.GameObjects.Text(this.scene, cardComponentStructure.cardTitle.scale.objectPositionX, cardComponentStructure.cardTitle.scale.objectPositionY, title, cardComponentStructure.cardTitle.style)
        this.workshopTitle.setOrigin(0.5)

        this.workshopBackground = new phaser.GameObjects.Image(scene, cardComponentStructure.cardTitle.scale.objectPositionX, cardComponentStructure.cardTitle.scale.objectPositionY, 'blackBackground')
        this.workshopBackground.setDisplaySize(cardComponentStructure.cardTitle.scale.objectWidth, cardComponentStructure.cardTitle.scale.objectHeight);

        //Image
        //cardComponentStructure.cardImage.style.fontSize = ScaleFunctionText(this.scene.sys.canvas.width, this.scene.sys.canvas.height, cardComponentStructure.cardImage.style.fontSize)
        this.workshopImage = new phaser.GameObjects.Image(this.scene, cardComponentStructure.cardImage.scale.objectPositionX, cardComponentStructure.cardImage.scale.objectPositionY, image)
        this.workshopImage.setDisplaySize(cardComponentStructure.cardImage.scale.objectWidth, cardComponentStructure.cardImage.scale.objectHeight)
        //Description
        var smalldescription = ''
        if (description.length < 91) {
            smalldescription = description.slice(0, 90)
        } else { smalldescription = description.slice(0, 90) + '......' }
        this.descriptionBackground = new phaser.GameObjects.Image(scene, cardComponentStructure.cardDescription.scale.objectPositionX, cardComponentStructure.cardDescription.scale.objectPositionY, 'blackBackground')
        this.descriptionBackground.setDisplaySize(cardComponentStructure.cardDescription.scale.objectWidth, cardComponentStructure.cardDescription.scale.objectHeight);
        this.workshopDescription = new phaser.GameObjects.Text(this.scene, cardComponentStructure.cardDescription.scale.objectPositionX, cardComponentStructure.cardDescription.scale.objectPositionY, smalldescription, cardComponentStructure.cardDescription.style)
        this.workshopDescription.setWordWrapWidth(cardComponentStructure.cardDescription.scale.objectWidth);
        this.workshopDescription.setOrigin(0.5)

        //button
        this.button = new buttonComponent(this.scene, cardComponentStructure.cardButton)
        // this.button.setInteractive().on('pointerup', () => {

        //     this.scene.scene.start('Stories', { id: id, name: title, desciption: description })
        // });
        // this.button.setInteractive().on('pointerdown', () => {
        //     console.log(smalldescription)
        //     buttonPressAnimation(scene, this)
        // })
        this.add([this.outline, this.workshopImage, this.background, this.workshopBackground, this.workshopTitle, this.descriptionBackground, this.workshopDescription, this.button])
        this.setSize(width, heigth)
    }
    startScene() {
        this.scene.scene.start('Stories', { id: this.workshopId, name: this.workshopTitle.text, desciption: this.description })
    }
    outlineVisible(isOn) {
        this.outline.setVisible(isOn)
    }

}