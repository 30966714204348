import Phaser from 'phaser';
import { SceneKey } from '../utils/constants';
import {
    createBackgroundImage, createZoomCamera, createAccesibilityBtns,
    createBtn, createMenuBar, createUserTag,
    createInstructionBox, createCharacterPic, setZoomBtnOnclick
} from '../utils/factory';
import { CharactersAssets, Characters } from '../utils/assetsPaths/characters';
import { Icons } from '../utils/assetsPaths/icons';
import { Btns } from '../utils/assetsPaths/btn';

export default class CharacterSelectorScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.CHARACTER_SELECTOR });
    }

    create() {
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        createMenuBar(this, "LudoMundo");
        createUserTag(this, this.game.sessionData);
        createInstructionBox(this, 'Elige tu personaje');
        this.returnBtn = createBtn(this, "Regresar", Icons.RETURN, Btns.SECONDARY);
        this.characters = CharactersAssets.map(texture => {
            let key = this.game.ludo.character ? this.game.ludo.character.key : ""
            const pic = createCharacterPic(this, texture).setInteractive();
            if (key === texture.key)
                pic.increaseSize()
            return pic;
        })
        this.resize();
        this.speakAtStart();
        const scale = () => this.resize();
        this.scale.on("resize", scale)
        this.events.once("shutdown", () => this.scale.off("resize", scale));
        this.setBtnsInteractive();
        this.events.once("shutdown", () => this.game.voice.cancel());
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voice.speak(this.helpText);
    }

    stopVoice() {
        this.game.voice.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }
    setBtnsInteractive() {
        this.returnBtn.setInteractive().onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        const [boyPic, girlPic] = this.characters;
        boyPic.onclick(() => {
            boyPic.increaseSize();
            girlPic.decreaseSize();
            this.game.ludo.character = Characters.MALE;
        });
        girlPic.onclick(() => {
            girlPic.increaseSize();
            boyPic.decreaseSize();
            this.game.ludo.character = Characters.FEMALE
        });
        this.voiceOverBtn.onclick(() => this.game.voice.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
    }

    resize() {
        const { width, height } = this.game.canvas;
        const headerHeight = height / 7;
        const { background, accesibilityBtns, returnBtn, header, userTag, instructionBox } = this;
        background.setDisplaySize(width, height);
        returnBtn.resize(width / 2, height / 8, 25, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 2, height / 8, 25, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 25, 1);
        instructionBox.resize(width * 0.6, height / 5, 50, 1);
        this.setElementsPositions(headerHeight);
    }

    setElementsPositions(headerHeight) {
        const { width, height } = this.game.canvas;
        const { accesibilityBtns, returnBtn, userTag, header, instructionBox, characters } = this;
        const position = Phaser.Display.Align.CENTER;
        userTag.setPosition(width - userTag.background.displayWidth / 2 * 1.1, headerHeight / 2);
        instructionBox.setX(width / 2)
            .setY(headerHeight + instructionBox.ludo.displayHeight * 0.65);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })

        returnBtn.setPosition(width / 2, accesibilityBtns[0].y);
        header.alignLabel(userTag.getLeftCenter().x);

        const characterHeight = (returnBtn.getTopCenter().y - instructionBox.getBottomCenter().y) * 0.65;
        characters.forEach(c => c.resize(width / characters.length, characterHeight, 75, 1));
        const cellWidth = characters[0].background.displayWidth * 1.5;

        Phaser.Actions.GridAlign(characters, {
            width: -1,
            cellWidth,
            y: (returnBtn.getTopCenter().y + instructionBox.getBottomCenter().y) / 2,
            x: width / 2 - (cellWidth * (characters.length - 1)) / 2,
            position
        })
    }
}

const SceneInstruction = "Elije el personaje con el que quieres jugar";
