import { SceneBackground, GameIcon, GameLogo } from "./assetsPaths/splash";
import Button from "../gameObjects/button";
import { wrapVect2 } from "./vect2Wrapper";
import { Btns } from "./assetsPaths/btn";
import HeaderBar from "../gameObjects/headerBar";
import InstructionBox from "../gameObjects/instructionBox";
import CharacterPic from "../gameObjects/characterPic";
import BadgeBar from "../gameObjects/badgeBar";
import Follower from "../gameObjects/follower";
import Dialog from "../gameObjects/dialog";
import HeaderHub from "../gameObjects/headerHub"
import MapPoint from "../gameObjects/mapPoint";
import UserTag from "../gameObjects/userTag";
import MessageBox from "../gameObjects/messageBox";
import AccesibilityButton from "../gameObjects/accesibilityButton";
import { Icons } from "./assetsPaths/icons";
import AccesibilityHtmlBtn from "../gameObjects/accesibilityHtmlBtn";
import { RoundedCamera } from "../../HabCogGame/pipelines/roundedCamera";
import MenuBar from "../gameObjects/menuBar";
import LargeMessageBox from "../gameObjects/largeMessageBox";
import BadgesContainer from "../gameObjects/badgesContainer";
import Modal from "../gameObjects/modal";
import { SceneKey } from "./constants";

export const createBackgroundImage = (scene) => {
    const { add, game } = scene;
    const { width, height } = game.canvas;
    scene.background = add.image(0, 0, SceneBackground.key)
        .setOrigin(0)
        .setDisplaySize(width, height);
    return scene.background;
}

export const createUserTag = (scene, sessionData) => {
    scene.userTag = new UserTag(scene, 0, 0, sessionData);
    wrapVect2(scene.userTag);
    scene.add.existing(scene.userTag);
    return scene.userTag;
}

export const createMessageBox = (scene, instruction) => {
    const msg = new MessageBox(scene, 0, 0, instruction);
    wrapVect2(msg);
    scene.msg = msg;
    scene.add.existing(msg);
}

const createVoiceOverBtn = (scene) => {
    const isIOS = scene.game.isIOSDevice;
    const icon = Icons.VOICE_OVER;
    if (isIOS)
        return new AccesibilityHtmlBtn(scene, 0, 0, icon);
    else
        return new AccesibilityButton(scene, 0, 0, icon);
}
const createStopVoiceOverBtn = (scene) => {
    const isIOS = scene.game.isIOSDevice;
    const icon = (localStorage.getItem('voiceSound') >= 0) ? Icons.SPEAK_ICON : Icons.STOP_SPEAK_ICON;
    if (isIOS)
        return new AccesibilityHtmlBtn(scene, 500, 0, icon);
    else
        return new AccesibilityButton(scene, 500, 0, icon);
}

export const createAccesibilityBtns = (scene) => {
    scene.voiceOverBtn = createVoiceOverBtn(scene);
    scene.stopVoiceOverBtn = createStopVoiceOverBtn(scene);
    scene.zoomBtn = new AccesibilityButton(scene, 0, 0, Icons.ZOOM);
    scene.accesibilityBtns = [scene.stopVoiceOverBtn, scene.voiceOverBtn, scene.zoomBtn];
    scene.accesibilityBtns.forEach(btn => {
        wrapVect2(btn);
        btn.setInteractive();
        scene.add.existing(btn)
    });
    return scene.accesibilityBtns;
}

export const createBadgeContainer = scene => {
    scene.badgeContainer = new BadgesContainer(scene, 0, 0);
    scene.add.existing(scene.badgeContainer);
    return scene.badgeContainer;
}

export const createGameIcon = (scene) =>
    scene.icon = scene.add.image(0, 0, GameIcon.key);

export const createGameLogo = (scene) =>
    scene.logo = scene.add.image(0, 0, GameLogo.key);

export const createModal = (scene, blockTextArea) => {
    scene.modal = new Modal(scene, 0, 0, scene.game.score, blockTextArea);
    scene.add.existing(scene.modal);
    return scene.modal;
}

export const createBtn = (scene, label, icon, background = Btns.PRIMARY) => {
    const btn = new Button(scene, 0, 0, label, icon, background);
    wrapVect2(btn);
    scene.add.existing(btn);
    return btn;
}

export const createHeaderBar = (scene, label) => {
    scene.header = new HeaderBar(scene, 0, 0, label);
    scene.add.existing(scene.header);
}
export const createMenuBar = (scene, label) => {
    scene.header = new MenuBar(scene, 0, 0, label);
    scene.add.existing(scene.header);
}

export const createLargeMessageBox = (scene, label) => {
    scene.msgBox = new LargeMessageBox(scene, 0, 0, label);
    wrapVect2(scene.msgBox);
    scene.add.existing(scene.msgBox);
}

export const createHub = (scene, label, badges) => {
    let sceneBadges;
    switch (scene.scene.key) {
        case SceneKey.CITY_WORLD:
            sceneBadges = scene.game.score.cityBadges;
            break;
        case SceneKey.HOME_WORLD:
            sceneBadges = scene.game.score.homeBadges;
            break;
        case SceneKey.JOB_WORLD:
            sceneBadges = scene.game.score.workBadges;
            break;
        default:
            console.log("undefined world");
    }
    scene.header = new HeaderHub(scene, 0, 0, label, badges, sceneBadges).setDepth(7);
    scene.add.existing(scene.header);
}

export const createInstructionBox = (scene, label) => {
    const instructionBox = new InstructionBox(scene, 0, 0, label);
    scene.instructionBox = instructionBox;
    wrapVect2(instructionBox);
    scene.add.existing(scene.instructionBox);
}

export const createCharacterPic = (scene, texture, label) => {
    const cPic = new CharacterPic(scene, 0, 0, texture, label || texture.name);
    wrapVect2(cPic);
    scene.add.existing(cPic);
    return cPic;
}

export const createBadgeBar = (scene, badges) => {
    scene.badgeBar = new BadgeBar(scene, 0, 0, badges).setDepth(1);
    scene.add.existing(scene.badgeBar);
    return scene.badgeBar;
}

export const createMapBackground = (scene, { key }) =>
    scene.background = scene.add.image(0, 0, key).setOrigin(0);

export const createFollower = (scene) => {
    const { x, y } = scene.startPoint;
    scene.follower = new Follower(scene, x, y, scene.game.nodes[scene.nodeKey], '')
        .setVisible(false);
}

export const createSecondaryFollower = (scene, x, y) => {
    const navMesh = scene.follower.navMesh;
    return new Follower(scene, x, y, navMesh, '').setVisible(false)
}

export const createMainCharacter = (scene, { key }) => {
    const mc = scene.add.sprite(0, 0, key).setOrigin(0.5, 0.9).setDepth(5);
    scene.physics.world.enable(mc);
    scene.mc = mc
    return mc;
}

export const createCharacterSprite = (scene, texture, frame) => {
    const { scaleX, scaleY } = scene.background;
    const scale = (scaleX + scaleY) / 2;
    return scene.add.sprite(0, 0, texture.key, frame)
        .setOrigin(0.5, 0.9)
        .setScale(scale)
}

export const createDialog = (scene, label, badge) => {
    const { width, height } = scene.game.canvas;
    const dialog = new Dialog(scene, width / 2, height / 2, label, badge)
        .resize(width, height * 0.65, 50, 1)
        .setScale(0);
    scene.add.existing(dialog);
    return dialog;
}

export const createMapPoint = (scene, position, texture) => {
    const mapPoint = new MapPoint(scene, position, texture);
    scene.add.existing(mapPoint);
    return mapPoint;
}

export const createZoomCamera = (scene) => {
    scene.isZoomActive = false;
    scene.zoomCamera = new RoundedCamera(scene, 0, 0, 300, 1.5)
        .setRenderToTexture(scene.game.roundPipeline);
    scene.zoomCamera.setVisible(false);
    scene.cameras.addExisting(scene.zoomCamera);
    const inputCallback = (pointer) => {
        if (scene.isZoomActive)
            scene.zoomCamera.relocate(pointer);
    }
    scene.input.on('pointermove', inputCallback)
    scene.events.once('shutdown', () => scene.input.off('pointermove', inputCallback));
}

export const setZoomBtnOnclick = (scene, zoomBtn) => {
    zoomBtn.onclick(() => {
        scene.zoomCamera.relocate(scene.input.activePointer);
        scene.isZoomActive = !scene.isZoomActive;
        scene.zoomCamera.setVisible(scene.isZoomActive);
    });
}
