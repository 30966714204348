import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { outlineSetting, OutlineSettingAssets } from "../HabCogGame/utils/settingsConstants/outlineSetting";
import { difficultySetting, DifficultySettingAssets } from "./utils/settings";
import { FilledGeomsAssets } from '../HabCogGame/utils/assetsPaths/filledGeoms';
import { OutlinedGeomsAssets } from '../HabCogGame/utils/assetsPaths/outlinedGeoms';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';
const data = {
    name: 'Preguntón',
    sessionAssets: [
        ...DifficultySettingAssets,
        ...FilledGeomsAssets,
        ...OutlinedGeomsAssets,
        ...OutlineSettingAssets,
        ...TimeSettingAssets
    ],
    sessionScene: SessionScene,
    logoUrl: './NosyGameAssets/logo.png',
    iconUrl: './NosyGameAssets/icon.png',
    thumbnail: './NosyGameAssets/thumbnail.png',
    getHint: () => 'Toca la figura que te pida.',
    userManual: 'Se presentan dos estímulos que tienen una propiedad en común (forma o ' +
        'color) y se diferencian por un atributo (tamaño, color o forma), según se ' +
        'haya seleccionado. El usuario señalará el estímulo que cumpla la ' +
        'propiedad que se solicita mediante una pregunta.',
    settings: [
        difficultySetting,
        outlineSetting,
        timeSetting
    ]
};

export default function NosyGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
