import React from "react";
import "./Footer.css";
import { Container, Grid, Image } from 'semantic-ui-react'



export default function Universitieslogos() {
    return (
        <div>
            <Container text >
                <Grid textAlign='center' columns={3} >
                    <Grid.Column  >
                        <Image style={{ maxWidth: '7.5vw' }} src="/LudomingaUIAssets/commonAssets/PUCE.png"></Image>

                    </Grid.Column>
                    <Grid.Column  >
                        <Image style={{ maxWidth: '5vw' }} src="/LudomingaUIAssets/commonAssets/EPN.png"></Image>

                    </Grid.Column>
                    <Grid.Column >
                        <Image style={{ maxWidth: '5vw' }} src="/LudomingaUIAssets/commonAssets/UDLA.png"></Image>
                    </Grid.Column>
                </Grid>
            </Container>
        </div >
    );
}

