import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import {
    getDifferentRandomItem,
    getRandomElement
} from "../../HabCogGame/utils/functions";
import { createRandomNumericArray } from '../../HabCogGame/utils/imgSelection';
import { IntrudersPhotosGroups } from "../../IntrudersGame/utils/assets";

const { GOOD_TARGET } = TargetFeedback;
export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight).setDepth(10);
        this.sTexturesGroups = IntrudersPhotosGroups;
        this.createImgsList(quantity, size, IntrudersPhotosGroups[0][0]);
        this.setHitTargetInteractive = this.getHitTargetInteractiveFn(difficulty);
        this.setFailFn(feedback);
        this.fillTargetIndexes();
        this.startGameplay();
    }

    fillTargetIndexes() {
        const length = this.targets.length;
        this.targetIndexes = createRandomNumericArray(length);
    }

    getSTextures() {
        return this.sTextures;
    }

    getHitTargetInteractiveFn(difficulty) {
        switch (difficulty) {
            case DifficultySettingKey.INVERT:
                this.imgList[0].setFlipY(true);
                return (hitTarget) => hitTarget.setFlipY(true);
            case DifficultySettingKey.SYMMETRIC:
                this.imgList[0].setFlipX(true);
                return (hitTarget) => hitTarget.setFlipX(true);
            default:
                return (hitTarget, texture) => {
                    const sT = this.getSTextures();
                    const diffTexture = getDifferentRandomItem(sT, texture);
                    this.imgList[0].setTexture(diffTexture.key);
                    hitTarget.setTexture(diffTexture.key);
                }
        }
    }

    getNextTarget() {
        let index = this.targetIndexes.pop();
        if (index === undefined) {
            this.fillTargetIndexes();
            index = this.targetIndexes.pop();
        }
        return this.targets[index];
    }

    setTargetsTexture(hitTarget) {
        const commonTexture = getRandomElement(this.sTextures);
        this.imgList.forEach(img => img.setTexture(commonTexture.key));
        this.setHitTargetInteractive(hitTarget, commonTexture);
    }

    setTargetsInteractive(hitTarget) {
        this.targets.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTarget.off('pointerdown')
            .once('pointerdown', () => this.addHit(hitTarget))
    }

    addHit(img) {
        super.addHit();
        this.targetsHitted++;
        this.createFeedbackImage(img, GOOD_TARGET);
        this.restartGameplay(img);
    }

    restartGameplay(img) {
        this.imgList.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                img.setFlipX(false).setFlipY(false);
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }

    startGameplay() {
        this.feedbackImgs = [];
        const lastGroup = this.sTextures;
        this.sTextures = getDifferentRandomItem(this.sTexturesGroups, lastGroup);
        const hitTarget = this.getNextTarget();
        this.setTargetsTexture(hitTarget);
        this.setTargetsInteractive(hitTarget);
    }
}

const DifficultySettingKey = {
    TEXTURE_SHIFT: 0,
    INVERT: 1,
    SYMMETRIC: 2
}