import { SettingKey } from "../constants";
import { PreviewIcons } from "../assetsPaths/previewIcons";
import { TileIcons } from "../assetsPaths/tileIcons";

const buildTimeSettingOptions = (length, step, start) => {
    let options = [];
    for (let i = start; i <= length; i += step) {
        options.push({
            key: i,
            title: `${i} min`,
            header: 'Duración de la sesión',
            description: '',
            image: {
                key: 'timeSettingImage',
                url: './HabCogGameAssets/settings/time/settingTimer.png'
            }
        })
    }
    return options;
}

const timeSetting = {
    key: SettingKey.TIME,
    name: 'Tiempo',
    icon: PreviewIcons.TIME,
    tileIcon: TileIcons.TIME,
    options: [
        // {
        //     key: 0.25,
        //     title: `${15} seg`,
        //     header: 'Duración de la sesión',
        //     description: '',
        //     image: {
        //         key: 'timeSettingImage',
        //         url: './HabCogGameAssets/settings/time/settingTimer.png'
        //     }
        // }, 
        ...buildTimeSettingOptions(60, 5, 5)
    ]
}
export default timeSetting;

export const TimeSettingAssets = [
    {
        key: 'timeSettingImage',
        url: './HabCogGameAssets/settings/time/settingTimer.png'
    }
]