import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
import { Ludo } from '../utils/assetsPaths/splash';
const Text = Phaser.GameObjects.Text;
const Image = Phaser.GameObjects.Image;

export default class InstructionBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.INSTRUCTION_BOX.key);
        this.label = new Text(scene, 0, 0, label, InstructionBoxTextStyle)
            .setOrigin(0.5);
        this.ludo = new Image(scene, 0, 0, Ludo.key).setOrigin(1, 0.5);
        this.add([this.background, this.label, this.ludo]);
    }

    resizeLabel() {
        const { displayWidth, displayHeight } = this.background;
        this.label.setFontSize(`${displayHeight * 0.35}px`)
            .setWordWrapWidth(displayWidth * 0.9);
        if (this.label.displayWidth > displayWidth)
            this.label.setFontSize(`${displayHeight * 0.2}px`)
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.ludo.setScale(this.background.scale)
            .setX(this.background.getLeftCenter().x);
        this.resizeLabel();
        return this;
    }
}

const InstructionBoxTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'center'
}