import Phaser from "phaser";
import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { Paint, WorkingBadSheets, WorkingBadAnims } from "../../utils/assetsPaths/situations/work/workingBad";
import { ManWorkIdle, WomanWorkIdle } from "../../utils/assetsPaths/situations/work/boringWork";
import { createBoss } from "../../utils/npcFactory.js/factory";
import { BossIdle } from "../../utils/assetsPaths/situations/work/agressiveCoworker";

export default class WorkingBadSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1558, y: 1650 };
        const mcPosition = { x: 1895, y: 1455 };
        super(scene, markPosition, mcPosition, Badges.ICE);
        this.instructionLabel = "Tu jefe te dice que has hecho mal tu trabajo, ¿qué haces?";
        this.optionsData = [{
            label: "Me enojo y culpo a otra persona.",
            onclick: this.runEvent(2500, () => this.getAngry())
        }, {
            label: "Me siento molesto pero acepto mi responsabilidad.",
            onclick: this.runEvent(2500, () => this.accept())
        }, {
            label: "Pregunto qué está mal y corrijo el error.",
            onclick: this.runEvent(2500, () => this.dialogue())
        }]
    }

    preload() {
        Object.values(WorkingBadAnims).forEach(anim => this.scene.anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.tar = this.scene.add.sprite(0, 0, Paint.key)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 1900, scaleY * 1450 + y)
            .setScale(this.scene.mc.scale);
        this.sprite = this.scene.add.sprite(0, 0, WorkingBadSheets.MAN_ACCEPT.key, 0)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 1895, scaleY * 1455 + y)
            .setScale(this.scene.mc.scale)
            .setAlpha(0);
        return this;
    }

    getAngry() {
        const anim = this.mcIsBoy ? WorkingBadAnims.MAN_GET_ANGRY : WorkingBadAnims.WOMAN_GET_ANGRY;
        this.sprite.play(anim.key)
            .once("animationcomplete", () => {
                this.addEvent(2000, () => {
                    addDialogTween(this.scene, "Mala decisión");
                })
            })
    }

    accept() {
        const anim = this.mcIsBoy ? WorkingBadAnims.MAN_ACCEPT : WorkingBadAnims.WOMAN_ACCEPT;
        this.sprite.play(anim.key)
            .once("animationcomplete", () => {
                this.addEvent(2500, () => {
                    addDialogTween(this.scene, "Buena decisión", this.badge);
                })
            })
    }

    dialogue() {
        const anim = this.mcIsBoy ? WorkingBadAnims.MAN_DIALOGUE : WorkingBadAnims.WOMAN_DIALOGUE;
        this.sprite.play(anim.key)
            .once("animationcomplete", () => {
                this.addEvent(2500, () => {
                    addDialogTween(this.scene, "Buena decisión", this.badge);
                })
            })
    }

    reboot(onComplete) {
        this.tar.setAlpha(1);
        this.sprite.setAlpha(0);
        this.scene.mc.setAlpha(1);
        this.boss.destroy();
        onComplete();
    }

    start() {
        this.tar.setAlpha(0);
        const idle = this.mcIsBoy ? ManWorkIdle.BACK : WomanWorkIdle.BACK;
        const paint = this.mcIsBoy ? WorkingBadAnims.MAN_PAINT : WorkingBadAnims.WOMAN_PAINT;
        const sheet = this.mcIsBoy ? WorkingBadSheets.MAN_ACCEPT : WorkingBadSheets.WOMAN_ACCEPT;
        this.scene.mc.setTexture(idle.key).setFlipX(true).setAlpha(0);
        this.sprite.setAlpha(1).setTexture(sheet.key, 0);
        this.boss = createBoss(this.scene, 2537, 1700);
        this.addEvent(2000, () => {
            this.sprite.play(paint.key)
                .once("animationcomplete", this.runEvent(2000, () => {
                    this.scene.tweens.add({
                        targets: this.boss.sprite,
                        alpha: 1
                    });
                    this.boss.follower.goTo(new Phaser.Math.Vector2(2041, 1506))
                        .onceMovementComplete(() => {
                            this.boss.sprite.setTexture(BossIdle.BACK.key);
                            this.sprite.setTexture(sheet.key, 8);
                            this.addEvent(1500, () => {
                                this.boss.sprite.play(WorkingBadAnims.BOSS.key)
                                    .once("animationcomplete", () => {
                                        this.addEvent(2000, () => this.showHint())
                                    });
                            })
                        })
                }))
        })
    }



}