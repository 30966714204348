import Phaser from 'phaser';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import listComponent from '../../GameObjects/ListComponent/listComponent';
import { StoriesStructure, RigthButtonStructure, LeftButtonStructure, InstructionText } from './StoriesStructure';
import { listComponentStructure } from '../../GameObjects/ListComponent/listComponentStructure';
import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
import stories from '../../Services/stories';
import InstructionTextComponent from '../../GameObjects/InstructionTextComponent/InstructionTextComponent';
import { rotationAnimation } from '../../Functions/AnimatedFunctions';
import { carouselLogic } from '../../Functions/carouselFunctions';




export default class StoriesScene extends Phaser.Scene {
    constructor() {
        super('Stories');
    }

    init(data) {
        this.workshopId = data.id;
        this.workshopName = data.name;
        this.workshopDescription = data.desciption;
    }

    preload() {
        this.containerStructure = this.clone(StoriesStructure)
        this.rigthButtonScale = this.clone(RigthButtonStructure)
        this.leftButtonScale = this.clone(LeftButtonStructure)
        this.InstructionTextScale = this.clone(InstructionText)

        //service Data
        this.story = 'levelData' + this.workshopId
        this.load.json(this.story, stories.getStories(this.workshopId));
        this.load.json('lamineNumber', stories.getLaminesNumber())
        this.historia = []
        this.load.on('filecomplete-json-' + this.story, (key, type, data) => {
            for (var i = 0; i < data.length; i++) {
                this.historia.push(data[i])
                this.load.image('story' + data[i].id, data[i].image);
            }

        }
        )
        var spiner = new Phaser.GameObjects.Image(this, this.sys.canvas.width / 2, this.sys.canvas.height / 1.5, 'spiner')
        spiner.setDisplaySize(this.sys.canvas.height / 3, this.sys.canvas.height / 3)
        spiner.setOrigin(0.5)
        rotationAnimation(this, spiner)
        this.load.on('progress', () => {
            this.add.existing(spiner)
        }).on('complete', function () {
            spiner.destroy()
        });

        this.saveScaleState()
        this.listComponentScale = listComponentStructure
        //Scale this scene
        this.containerStructure.scale = scaleGameObject(this, this.containerStructure.scale)
        this.leftButtonScale = scaleGameObject(this, this.leftButtonScale)
        this.rigthButtonScale = scaleGameObject(this, this.rigthButtonScale)
        this.InstructionTextScale = scaleGameObject(this, this.InstructionTextScale)
        //Scale cardComponent
        this.listComponentScale.listTitle.scale = scaleGameObject(this, this.listComponentScale.listTitle.scale)
        this.listComponentScale.listTitle.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.listComponentScale.listTitle.style.fontSize)
        this.listComponentScale.listImage.scale = scaleGameObject(this, this.listComponentScale.listImage.scale)
        this.listComponentScale.listDescription.scale = scaleGameObject(this, this.listComponentScale.listDescription.scale)
        this.listComponentScale.listDescription.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.listComponentScale.listDescription.style.fontSize)
        this.listComponentScale.listButton.scale = scaleGameObject(this, this.listComponentScale.listButton.scale)
        this.listComponentScale.listDificult.scale = scaleGameObject(this, this.listComponentScale.listDificult.scale)
        this.listComponentScale.listDificult.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.listComponentScale.listDificult.style.fontSize)
        this.listComponentScale.listLamines.scale = scaleGameObject(this, this.listComponentScale.listLamines.scale)
        this.listComponentScale.listLamines.style.fontSize = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, this.listComponentScale.listLamines.style.fontSize)
        this.listComponentScale.miselaneus.scale = scaleGameObject(this, this.listComponentScale.miselaneus.scale)

        //Preload Image
        this.drawPreloadBackground()
    }
    create() {
        //load jscon data
        this.stories = this.cache.json.get(this.story);
        if (this.stories === undefined) {
            this.stories = []
        }
        this.laminas = this.cache.json.get('lamineNumber')
        if (this.laminas === undefined) {
            this.laminas = []
        }
        //list container variables
        const originX = this.containerStructure.scale.objectPositionX
        const originY = this.containerStructure.scale.objectPositionY
        const width = this.containerStructure.scale.objectWidth
        const height = this.containerStructure.scale.objectHeight
        //published condition
        var publishedArray = []
        for (var j = 0; j < this.stories.length; j++) {
            if (this.stories[j].published) {
                publishedArray.push(this.stories[j])

            }
        }
        //Card element builder
        var listComponentArray = []
        var number = 0
        for (var i = 0; i < publishedArray.length; i++) {
            this.containerStructure.scale.objectPositionX = (originX + (width * (i % 4))) * 1.2
            for (j = 0; j < this.laminas.length; j++) {
                if (publishedArray[i].id === this.laminas[j].history_id) {
                    number = this.laminas[j].lamines_number
                    break;
                }
                else {
                    number = 0
                }
            }
            const story = new listComponent(this,
                this.containerStructure.scale.objectPositionX,
                this.containerStructure.scale.objectPositionY,
                width,
                height,
                publishedArray[i].name,
                'story' + publishedArray[i].id,
                publishedArray[i].description,
                publishedArray[i].id,
                number);
            listComponentArray.push(story)
        }
        if (publishedArray.length === 0) {
            var infoCard = this.add.image(originX, originY, 'warningStories').setOrigin(0, 0.5)
            infoCard.setDisplaySize(width * 4, height)
        }
        //Rigth and left button 
        var rigthButton = this.add.image(this.rigthButtonScale.objectPositionX, this.rigthButtonScale.objectPositionY, 'TriangleButton')
        rigthButton.setDisplaySize(this.rigthButtonScale.objectWidth, this.rigthButtonScale.objectHeight)
        var leftButton = this.add.image(this.leftButtonScale.objectPositionX, this.leftButtonScale.objectPositionY, 'TriangleButtonInv')
        leftButton.setDisplaySize(this.leftButtonScale.objectWidth, this.leftButtonScale.objectHeight)
        leftButton.setDisplaySize(this.leftButtonScale.objectWidth, this.leftButtonScale.objectHeight)
        leftButton.setAlpha(0.4)
        //Carousel logic
        carouselLogic(listComponentArray, rigthButton, leftButton, this)


        //reload size state
        this.loadSizeState()
    }
    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    drawPreloadBackground() {
        var background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / background.width
        let scaleY = this.sys.canvas.height / background.height
        background.setScale(scaleX, scaleY).setScrollFactor(0)
        scaleX = this.sys.canvas.width / 1920
        scaleY = this.sys.canvas.height / 1080

        //Top bar Home Button
        var bar = new topBar
            (this, 0, 0, this.sys.canvas.width, 400, this.workshopName, 'Workshops',
                this.workshopName + '' + this.workshopDescription + '. \n  selecciona la historia que desees jugar')
        this.add.existing(bar)

        //Draw Instructions componet
        var instructions = new InstructionTextComponent(this, this.InstructionTextScale.objectPositionX,
            this.InstructionTextScale.objectPositionY,
            this.InstructionTextScale.objectWidth
            , this.InstructionTextScale.objectHeight,
            '' + this.workshopDescription + '.',
            'ludoIcon', this.historyID, this.undefined, this.historyName, 'Selecciona la historia que desees jugar'

        )
        this.add.existing(instructions)
    }
    loadSizeState() {
        listComponentStructure.listTitle = this.listTitle
        listComponentStructure.listImage = this.listImage
        listComponentStructure.listDescription = this.listDescription
        listComponentStructure.listButton = this.listButton
        listComponentStructure.listDificult = this.listDificult
        listComponentStructure.listLamines = this.listLamines
        listComponentStructure.miselaneus = this.miselaneus
    }
    saveScaleState() {
        this.listTitle = this.clone(listComponentStructure.listTitle)
        this.listImage = this.clone(listComponentStructure.listImage)
        this.listDescription = this.clone(listComponentStructure.listDescription)
        this.listButton = this.clone(listComponentStructure.listButton)
        this.listDificult = this.clone(listComponentStructure.listDificult)
        this.listLamines = this.clone(listComponentStructure.listLamines)
        this.miselaneus = this.clone(listComponentStructure.miselaneus)
    }
}