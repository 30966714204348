import { SettingKey } from "./constants";

export const randomNum = (length) =>
    Math.round(Math.random() * length);

export const getEmptyUserConfig = () => {
    let userConfig = {};
    Object.values(SettingKey).forEach(value => userConfig[value] = 0);
    userConfig.size = 3;
    return userConfig;
};

export const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutesString}:${secondsString}`
};

export const getRandomElement = (array) => array[randomNum(array.length - 1)];

export const getDifferentRandomItem = (items, currentItem) => {
    let item = getRandomElement(items);
    while (item === currentItem && items.length > 2)
        item = getRandomElement(items);
    return item
};
export const getRandomSubArray = (arr, n) => {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

export const getTargetSize = (width, height, rows, cols) => {
    const cellWidth = width / cols;
    const cellHeight = height / rows;
    const size = cellWidth > cellHeight ? cellHeight : cellWidth;
    return size * 0.95;
};
