import phaser from 'phaser'
import { blackCardStructure } from './BlankCardStructure'

export default class BlankCard extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, text) {
        super(scene, x, y)
        this.title = null
        this.workshopDescription = null
        this.button = null
        this.scene = scene
        this.generated(scene, x, y, width, heigth, text)
    }

    generated(scene, x, y, width, heigth, text) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);

        //Number
        var old = blackCardStructure.cardTitle.scale
        var oldScale = blackCardStructure.cardTitle.style.fontSize
        this.description = new phaser.GameObjects.Text(this.scene, blackCardStructure.cardTitle.scale.objectPositionX,
            blackCardStructure.cardTitle.scale.objectPositionY, text,
            blackCardStructure.cardTitle.style)
        this.description.setFontSize(blackCardStructure.cardTitle.style.fontSize)
        this.description.setWordWrapWidth(blackCardStructure.cardTitle.scale.objectWidth);
        this.description.setOrigin(0.5)

        blackCardStructure.cardTitle.scale = old
        blackCardStructure.cardTitle.style.fontSize = oldScale




        this.add([this.background, this.description])
        this.setSize(width, heigth)

    }




}