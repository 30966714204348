import React, { Component } from "react";
import "./LoginForm.css";
import { Container, Grid, Form, Image, Button, Header, Modal } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";



export default class LoginForm extends Component {
    constructor(params) {
        super(params);

        if (localStorage.getItem('voiceSound') === null) {
            localStorage.setItem('voiceSound', 1)
        }
        console.log(params)
        this.state = {
            email: "",
            password: "",
            typeUser: "",
            modalOpen: false,
            user: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.eraseState = this.eraseState.bind(this);

    }
    eraseState() {
        if (localStorage.getItem('interventionPlan')) {
            localStorage.removeItem('interventionPlan')
        }
        if (localStorage.getItem('organizationID')) {
            localStorage.removeItem('organizationID')
        }
    }
    handleSubmit(event) {
        const { email, password, typeUser } = this.state;
        let user = {
            email: email,
            password: password,
            typeUser: typeUser
        }
        AuthService.postUser(user).then((json) => {
            if (json.data.length) {
                this.setState({ user: json.data });
                localStorage.setItem('user', json.data[0].first_name + " " + json.data[0].last_name)
                this.props.props.history.push(`/InterventionPlans/${json.data[0].organization_id}`);
            }
            else {
                this.handleOpen()
            }

        });
        event.preventDefault();
    }
    handleClose = () => this.setState({ modalOpen: false })
    handleOpen = () => this.setState({ modalOpen: true })
    render() {
        return (
            <div>
                <Container style={{ marginBottom: "3vh" }}>
                    <Grid textAlign="center" style={{ height: '85vh', marginTop: '1vh' }} verticalAlign='top' columns={2}>
                        <Grid.Row>
                            <Image style={{ width: "40vw", height: "15vh" }}
                                src="./LudomingaUIAssets/LogInAssets/welcome.png"
                                centered></Image>
                        </Grid.Row>
                        <Grid.Column >
                            <Image style={{ marginBottom: "4%", height: "40vh", width: "100vw" }} src="/LudomingaUIAssets/commonAssets/LUDO.png"></Image>
                            <Link to="/GameTypeNavigator"><Button onClick={e => this.eraseState()} id="button" style={{ fontSize: '1.01vw', height: "6.5vh", width: "8.5vw" }}  >
                                Jugar
                        </Button>
                            </Link>
                        </Grid.Column>

                        <Grid.Column style={{ height: '57vh', width: "20vw" }} id="login-card"  >
                            <Header as='h1' style={{
                                color: '#3b007e',
                                fontFamily: "rounded",
                                fontSize: '2.78vw',
                                marginTop: "0.1vh"
                            }}>
                                Iniciar
                                sesión
                        </Header>
                            <Form onSubmit={this.handleSubmit}>
                                <Container style={{
                                    backgroundColor: 'transparent',
                                    borderWidth: 0,
                                    borderRadius: '50px',
                                }} >
                                    <Form.Select
                                        style={{
                                            backgroundColor: '',
                                            color: "#3b007e",
                                            borderColor: "transparent transparent #83609e transparent",
                                            opacity: 1,
                                            borderWidth: '2px',
                                            marginTop: '1.5vh',
                                            height: "5vh"
                                        }}
                                        fluid
                                        options={[{ key: 'Tutor', text: 'Tutor', value: 'tutor' }]}
                                        onChange={(e, { value }) => this.setState({ typeUser: value })}
                                        required
                                        placeholder='Tipo de Usuario'
                                    />
                                    <Form.Input
                                        required
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: "#3b007e",
                                            borderColor: "transparent transparent #83609e transparent",
                                            opacity: 1,
                                            borderWidth: '2px',
                                            height: "5vh"
                                        }}
                                        src="/LudomingaUIAssets/commonAssets/LUDO.png"
                                        icon='user' iconPosition='left' placeholder='Correo electronico' />
                                    <Form.Input
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: "#3b007e",
                                            borderColor: "transparent transparent #83609e transparent",
                                            opacity: 1,
                                            borderWidth: '2px',
                                            height: "5vh"
                                        }}
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Contraseña'
                                        type='password'
                                        required={true}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                    />
                                    <Form.Button id="button" type="submit" style={{ marginTop: "0.2vh", fontSize: '1.1vw', height: "6.5vh", width: "8.5vw" }}>
                                        Empezar
                                </Form.Button>

                                </Container>

                            </Form>
                        </Grid.Column>


                    </Grid>
                </Container>
                <Modal size="mini" closeIcon={true}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}>
                    <Modal.Header>Credenciales Incorrectas</Modal.Header>
                    <Modal.Content>
                        <p>Usuario y contraseña incorrectos</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={this.handleClose}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='continuar'
                        />
                    </Modal.Actions>
                </Modal>
            </div >
        );
    }

}


