export const AudioKey = {
    HIT: {
        key: 'hitAudio',
        url: './HabCogGameAssets/audio/hitSound.mp3'
    },
    HIT_2: {
        key: 'hitAudio2',
        url: './HabCogGameAssets/audio/hitSound2.mp3'
    },
    HIT_3: {
        key: 'hitAudio2',
        url: './HabCogGameAssets/audio/applause1.mp3'
    },
    ERROR_SOUND: {
        key: 'errorAudio',
        url: './HabCogGameAssets/audio/errorSound.mp3'
    },
    ERROR_SOUND_OUCH: {
        key: 'errorAudioOuch',
        url: './HabCogGameAssets/audio/errorSoundOuch.mp3'
    },
    KISS: {
        key: 'kissAudio',
        url: './HabCogGameAssets/audio/kiss.mp3'
    },
    BOING: {
        key: 'boingSound',
        url: './HabCogGameAssets/audio/boing.mp3'
    },
    PHASE_JUMP: {
        key: 'phaseJumpSound',
        url: './HabCogGameAssets/audio/phaseJump1.mp3'
    },
    PHASE_JUMP_2: {
        key: 'phaseJump2Sound',
        url: './HabCogGameAssets/audio/phaseJump2.mp3'
    },
    WHISTLE_UP: {
        key: 'whistleUpSound',
        url: './HabCogGameAssets/audio/slideWhistleUp.mp3'
    },
    WHISTLE_DOWN: {
        key: 'whistleDownSound',
        url: './HabCogGameAssets/audio/slideWhistleDown.mp3'
    },
    BOUNCE_SONG: {
        key: 'bounceGameSong',
        url: './HabCogGameAssets/audio/funSong.mp3'
    }
};

export const AudioAssets = Object.values(AudioKey);