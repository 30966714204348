export const RegularHouse = {
    key: 'regularHouseImg',
    url: 'SocialInclusionAssets/situations/burnedHouse/regularHouse.png',
    x: 2608,
    y: 306
}

export const BurnedHouse = {
    key: 'burnedHouseImg',
    url: 'SocialInclusionAssets/situations/burnedHouse/burnedHouse.png',
    x: 2608,
    y: 306
}

export const HouseSheets = {
    Fire: {
        key: 'situationOneHouseOnFire',
        url: 'SocialInclusionAssets/situations/burnedHouse/fire.png',
        frameWidth: 814 / 2,
        frameHeight: 846 / 2
    },
    Smoke: {
        key: 'situationOneHouseOnSmoke',
        url: 'SocialInclusionAssets/situations/burnedHouse/smoke.png',
        frameWidth: 2126 / 5,
        frameHeight: 1626 / 4
    },
    FireOff: {
        key: 'situationOneHouseFireOff',
        url: 'SocialInclusionAssets/situations/burnedHouse/fireOff.png',
        frameWidth: 454,
        frameHeight: 395
    },
    FireMan: {
        key: 'situationOneFiremanTruck',
        url: 'SocialInclusionAssets/situations/burnedHouse/fireman.png',
        frameWidth: 2978 / 7,
        frameHeight: 1690 / 6
    },
    DOG: {
        key: "dogImg",
        url: "SocialInclusionAssets/controllers/dog.png",
        frameWidth: 1407 / 4,
        frameHeight: 1300 / 3
    },
    CAT: {
        key: "catIMG",
        url: "SocialInclusionAssets/controllers/cat.png",
        frameWidth: 1010 / 4,
        frameHeight: 988 / 3
    },
    MOUSE: {
        key: "mouseImg",
        url: "SocialInclusionAssets/controllers/mouse.png",
        frameWidth: 1377 / 4,
        frameHeight: 870 / 3
    },
}

export const firemanUp = {
    key: 'firemanUpView',
    url: 'SocialInclusionAssets/situations/burnedHouse/firemanUp.png'
}

export const firemanDown = {
    key: 'firemanDownView',
    url: 'SocialInclusionAssets/situations/burnedHouse/firemanDown.png'
}

export const BurningHouseAssets = {
    Images: [
        RegularHouse,
        BurnedHouse,
        firemanUp,
        firemanDown
    ],
    SpriteSheets: Object.values(HouseSheets)
}