export const SceneBackground = {
    key: 'sceneBackground',
    url: 'SocialInclusionAssets/splash/sceneBackground.png'
}

export const Ludo = {
    key: 'ludoBirdImage',
    url: 'SocialInclusionAssets/splash/ludo.png'
}

export const GameIcon = {
    key: "ludomundoIcon",
    url: "./SocialInclusionAssets/splash/icon.png"
}
export const GameLogo = {
    key: "ludomungoLogoImage",
    url: "./SocialInclusionAssets/splash/logo.png"
}

export const LoadBtnBackground = {
    key: 'loadBtnBackground',
    url: './HabCogGameAssets/splash/loadBtnBackground.png'
}

export const LoadBtnProgress = {
    key: 'loadBtnProgress',
    url: './HabCogGameAssets/splash/loadBtnProgress.png'
}

export const SplashAssets = [SceneBackground, GameIcon, Ludo, GameLogo, LoadBtnBackground, LoadBtnProgress];