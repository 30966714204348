import Phaser from "phaser";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { CryingBrotherSheets } from "../../utils/assetsPaths/situations/home/cryingBrother";
import { addDialogTween, generateFrameNames } from "../../utils/functions";
import { BOY_SHEETS } from "../../utils/assetsPaths/playerAnim";
import { MotherAnims } from "./sickPerson";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { MomIdle } from "../../utils/assetsPaths/situations/home/sickPerson";

export default class CryingBrotherSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 2813, y: 160 };
        const mcPosition = { x: 2500, y: 325 };
        super(scene, markPosition, mcPosition, Badges.GIFT);
        this.instructionLabel = "Un amigo está llorando, ¿qué haces?";
        this.optionsData = [{
            label: "Me alejo.",
            onclick: this.runEvent(2500, () => this.ignoreBrother())
        }, {
            label: "Me acerco y converso para animarle.",
            onclick: this.runEvent(2500, () => this.giveHope())
        }, {
            label: "Busco una persona para que le ayude.",
            onclick: this.runEvent(250, () => this.callMom())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(Anims).forEach(anim => anims.create(anim))
    }

    ignoreBrother() {
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ?
            Anims.MAN_MUSIC : Anims.WOMAN_MUSIC;
        this.scene.moveMcTo(2582, 602, () => {
            this.scene.mc.play(anim.key)
                .once("animationcomplete", this.runEvent(2000,
                    () => addDialogTween(this.scene, "Tu hermano quedó triste")))
        })
    }

    giveHope() {
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ?
            Anims.MAN_HELP : Anims.WOMAN_HELP;
        this.scene.mc.setFlipX(false);
        this.scene.mc.play(anim.key)
            .once("animationcomplete", this.runEvent(2000,
                () => this.npc.play(Anims.NPC_GET_CALM.key)
                    .once("animationcomplete", 
                    this.runEvent(2000, () => addDialogTween(this.scene, "Tu hermano se animó", this.badge)))))
    }

    callMom() {
        this.mom = createMother(this.scene);
        attachMotherToEvents(this.scene, this.mom);
        const { SCARED } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(SCARED.key)
            .once("animationcomplete", () => this.scene.moveMcTo(2582, 602, () => {
                this.scene.mc.setFlipX(true);
                this.scene.tweens.add({
                    targets: this.mom.sprite, alpha: 1
                })
                this.mom.follower.goTo(new Phaser.Math.Vector2(2400, 325))
                    .onceMovementComplete(() => {
                        this.mom.sprite.setFlipX(false);
                        this.mom.sprite.play(Anims.MOM_START_HELP.key)
                            .once("animationcomplete", () => {
                                this.npc.play(Anims.NPC_CRY.key);
                                this.mom.sprite.play(Anims.MOM_HELP.key)
                                    .once("animationcomplete", () => {
                                        this.npc.setTexture(CryingBrotherSheets.SAD_BROTHER.key, 10);
                                        this.mom.sprite.play(Anims.MOM_COMPLETE.key)
                                            .once("animationcomplete",
                                                this.runEvent(2000, () => {
                                                    this.scene.events.off("update", this.mom.move)
                                                    this.mom.sprite.destroy();
                                                    this.mom.follower.destroy();
                                                    addDialogTween(this.scene, "Tu hermano se animó", this.badge)
                                                }))
                                    })
                            })
                    })
            }))
    }

    reboot(onComplete) {
        this.npc.setTexture(CryingBrotherSheets.SAD_BROTHER.key, 0);
        this.scene.follower.emit("onMovementComplete");
        onComplete()
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.npc = this.scene.add.sprite(0, 0, CryingBrotherSheets.SAD_BROTHER.key, 0)
            .setScale(this.scene.mc.scale)
            .setPosition(2400 * scaleX, 225 * scaleY + y)
        return this;
    }

    start() {
        this.scene.mc.setFlipX(true)
        this.npc.play(Anims.NPC_START_CRY.key)
            .once("animationcomplete", () =>
                this.npc.play(Anims.NPC_CRY.key)
                    .once("animationcomplete", this.runEvent(2000, () => this.showHint()))
            )
    }
}

const Anims = {
    NPC_START_CRY: {
        key: "npcStartCries",
        frames: generateFrameNames(CryingBrotherSheets.SAD_BROTHER.key, 0, 4),
        frameRate: 3
    },
    NPC_CRY: {
        key: "npcCries",
        frames: generateFrameNames(CryingBrotherSheets.SAD_BROTHER.key, 4, 6),
        repeat: 4,
        frameRate: 3
    },
    NPC_GET_CALM: {
        key: "npcGetCalm",
        frames: generateFrameNames(CryingBrotherSheets.SAD_BROTHER.key, 7, 10),
        frameRate: 3
    },
    MOM_START_HELP: {
        key: "momStartHelpBrother",
        frames: generateFrameNames(CryingBrotherSheets.MOM_HELP.key, 0, 4),
        frameRate: 3
    },
    MOM_HELP: {
        key: "momHelpCries",
        frames: generateFrameNames(CryingBrotherSheets.MOM_HELP.key, 4, 6),
        repeat: 4,
        frameRate: 3
    },
    MOM_COMPLETE: {
        key: "momCompleteHelpCries",
        frames: generateFrameNames(CryingBrotherSheets.MOM_HELP.key, 8, 10),
        frameRate: 3
    },
    MAN_MUSIC: {
        key: 'manListenMusic',
        frames: generateFrameNames(CryingBrotherSheets.MAN_MUSIC.key, 0, 5),
        frameRate: 2
    },
    MAN_HELP: {
        key: 'manHelpBrother',
        frames: generateFrameNames(CryingBrotherSheets.MAN_HELP.key, 0, 7),
        frameRate: 1
    },
    WOMAN_MUSIC: {
        key: 'womanListenMusic',
        frames: generateFrameNames(CryingBrotherSheets.WOMAN_MUSIC.key, 0, 5),
        frameRate: 2
    },
    WOMAN_HELP: {
        key: 'womanHelpBrother',
        frames: generateFrameNames(CryingBrotherSheets.WOMAN_HELP.key, 0, 7),
        frameRate: 1
    }
}

const createMother = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 1954, 655);
    const sprite = createCharacterSprite(scene, MomIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0).setDepth(scene.mc.depth + 1)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(MotherAnims.WalkUp, false))
        .onTopRightMovement(anim(MotherAnims.WalkUp, true))
        .onBottomLeftMovement(anim(MotherAnims.WalkDown, true))
        .onBottomRightMovement(anim(MotherAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(MomIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}

const attachMotherToEvents = (scene, mom) => {
    scene.events.on("update", mom.move);
    scene.events.once("shutdown",
        () => scene.events.off("update", mom.move));
}

