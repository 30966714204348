
export const filledGeomTextures = {
    BLUE_SQUARE: {
        key: 'blueSquareTexture',
        name: 'cuadrado',
        color: 'azul',
        url: './HabCogGameAssets/geomTextures/blueSquare.png'
    },
    BLUE_TRIANGLE: {
        key: 'blueTriangleTexture',
        name: 'triángulo',
        color: 'azul',
        url: './HabCogGameAssets/geomTextures/blueTriangle.png'
    },
    BLUE_CIRCLE: {
        key: 'blueCircleTexture',
        name: 'círculo',
        color: 'azul',
        url: './HabCogGameAssets/geomTextures/blueCircle.png'
    },
    BLUE_STAR: {
        key: 'blueStarTexture',
        name: 'estrella',
        color: 'azul',
        url: './HabCogGameAssets/geomTextures/blueStar.png'
    },
    RED_SQUARE: {
        key: 'redSquareTexture',
        name: 'cuadrado',
        color: 'rojo',
        url: './HabCogGameAssets/geomTextures/redSquare.png'
    },
    RED_TRIANGLE: {
        key: 'redTriangleTexture',
        name: 'triángulo',
        color: 'rojo',
        url: './HabCogGameAssets/geomTextures/redTriangle.png'
    },
    RED_CIRCLE: {
        key: 'redCircleTexture',
        name: 'círculo',
        color: 'rojo',
        url: './HabCogGameAssets/geomTextures/redCircle.png'
    },
    RED_STAR: {
        key: 'redStarTexture',
        name: 'estrella',
        color: 'rojo',
        url: './HabCogGameAssets/geomTextures/redStar.png'
    },
    YELLOW_SQUARE: {
        key: 'yellowSquareTexture',
        name: 'cuadrado',
        color: 'amarillo',
        url: './HabCogGameAssets/geomTextures/yellowSquare.png'
    },
    YELLOW_TRIANGLE: {
        key: 'yellowTriangleTexture',
        name: 'triángulo',
        color: 'amarillo',
        url: './HabCogGameAssets/geomTextures/yellowTriangle.png'
    },
    YELLOW_CIRCLE: {
        key: 'yellowCircleTexture',
        name: 'círculo',
        color: 'amarillo',
        url: './HabCogGameAssets/geomTextures/yellowCircle.png'
    },
    YELLOW_STAR: {
        key: 'yellowStarTexture',
        name: 'estrella',
        color: 'amarillo',
        url: './HabCogGameAssets/geomTextures/yellowStar.png'
    },
    GREEN_SQUARE: {
        key: 'greenSquareTexture',
        name: 'cuadrado',
        color: 'verde',
        url: './HabCogGameAssets/geomTextures/greenSquare.png'
    },
    GREEN_TRIANGLE: {
        key: 'greenTriangleTexture',
        name: 'triángulo',
        color: 'verde',
        url: './HabCogGameAssets/geomTextures/greenTriangle.png'
    },
    GREEN_CIRCLE: {
        key: 'greenCircleTexture',
        name: 'círculo',
        color: 'verde',
        url: './HabCogGameAssets/geomTextures/greenCircle.png'
    },
    GREEN_STAR: {
        key: 'greenStarTexture',
        name: 'estrella',
        color: 'verde',
        url: './HabCogGameAssets/geomTextures/greenStar.png'
    },
    WHITE_SQUARE: {
        key: 'whiteSquareTexture',
        name: 'cuadrado',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteSquare.png'
    },
    WHITE_TRIANGLE: {
        key: 'whiteTriangleTexture',
        name: 'triángulo',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteTriangle.png'
    },
    WHITE_CIRCLE: {
        key: 'whiteCircleTexture',
        name: 'círculo',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteCircle.png'
    },
    WHITE_STAR: {
        key: 'whiteStarTexture',
        name: 'estrella',
        color: 'blanco',
        url: './HabCogGameAssets/geomTextures/whiteStar.png'
    }
};

export const FilledGeomsAssets = Object.values(filledGeomTextures);