import Phaser from 'phaser';
import { ModalIcons } from "../utils/assetsPaths/modalIcons";
import { Icons } from "../utils/assetsPaths/icons";
import { Btns } from "../utils/assetsPaths/btn";
import Button from "./button";
import Modal from './modal';

const description = 'Para volver al menú principal presiona el botón finalizar, ' +
    'para seguir jugando presiona el botón continuar.'

export default class ModalPause extends Modal {

    constructor(scene, x, y, score, block) {
        super(scene, x, y, ModalIcons.QUIT_HEADER, 'Salir', description, score, block);
        this.continueBtn = new Button(scene, 0, 0, 'Continuar', Icons.CONTINUE, Btns.SECONDARY);
        this.add(this.continueBtn);
        this.btns = [this.continueBtn, this.finishBtn];
    }

    setInteractive() {
        super.setInteractive();
        this.continueBtn.setInteractive();
        return this;
    }

    onContinue(callback) {
        this.continueBtn.onclick(callback);
    }

    resizeBtns() {
        const { displayWidth, displayHeight } = this.background;
        this.btns.forEach(btn => btn.resize(displayWidth / 4, displayHeight / 10, 0, 1))
        Phaser.Actions.GridAlign(this.btns, {
            width: -1,
            cellWidth: displayWidth / 3,
            x: -displayWidth / 6,
            y: displayHeight / 2 - this.finishBtn.background.displayHeight,
            position: Phaser.Display.Align.CENTER
        })
    }
}