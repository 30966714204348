import Phaser from "phaser";
import { SceneKey } from "../../HabCogGame/utils/constants";
import { createZoomCamera, createBackgroundImage, createAccesibilityBtns, createBtn, createUserTag, createMenuBar, createLargeMessageBox, setZoomBtnOnclick } from "../utils/factory";
import { Icons } from "../utils/assetsPaths/icons";
import { Btns } from "../utils/assetsPaths/btn";

export default class InstructionsScene extends Phaser.Scene {

    constructor() {
        super({
            key: SceneKey.INSTRUCTIONS
        })
    }

    create() {
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        this.returnBtn = createBtn(this, 'Regresar', Icons.RETURN, Btns.SECONDARY);
        createMenuBar(this, 'Descripción del juego');
        createLargeMessageBox(this, SceneInstruction);
        createUserTag(this, this.game.sessionData);
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale)
        this.events.once("shutdown", () => this.scale.off("resize", scale));
        this.setBtnsInteractive();
        this.speakAtStart();
        this.events.once("shutdown", () => this.game.voice.cancel());
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voice.speak(this.helpText);
    }

    stopVoice() {
        this.game.voice.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }

    setBtnsInteractive() {
        this.returnBtn.setInteractive()
        this.returnBtn.onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.voiceOverBtn.onclick(() => this.game.voice.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
    }

    resize() {
        const { width, height } = this.game.canvas;
        const headerHeight = height / 7;
        const { background, accesibilityBtns, returnBtn, header, userTag } = this;
        background.setDisplaySize(width, height);
        returnBtn.resize(width / 2, height / 8, 25, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 2, height / 8, 25, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 25, 1);
        this.setElementsPositions(headerHeight);
    }

    setElementsPositions(headerHeight) {
        const { width, height } = this.game.canvas;
        const { accesibilityBtns, returnBtn, msgBox, userTag, header } = this;
        const position = Phaser.Display.Align.CENTER;
        msgBox.setPosition(width / 2, height / 2);
        userTag.setPosition(width - userTag.background.displayWidth / 2 * 1.1, headerHeight / 2);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })

        returnBtn.setPosition(width / 2, accesibilityBtns[0].y);
        msgBox.resize(width, (returnBtn.getTopCenter().y - headerHeight), 25, 1);
        header.alignLabel(userTag.getLeftCenter().x);
        msgBox.setPosition(width / 2, (returnBtn.getTopCenter().y + headerHeight) / 2);
    }
}

const SceneInstruction = "LudoMundo es un juego para desarrollar " +
    "habilidades de conducta adaptativa para una mejor interacción en el " +
    "entorno. El usuario puede seleccionar el personaje (chico o chica), el " +
    "mundo en el que desea jugar y, dentro de éste, un escenario. Cada " +
    "situación plantea tres alternativas, dos son correctas. Si la respuesta " +
    "es acertada, gana una insignia. No hay límite de tiempo, el usuario " +
    "puede volver a un escenario las veces que desee. "