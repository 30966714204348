import Phaser from 'phaser';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
import { smallCardComponentStructure } from '../../GameObjects/SmallCardComponent/SmallCardComponentStructure';
import { InstructionText } from '../Sequence/SequenceStructure';
import { imageStructure, blankStructure, solutionStructure } from './GameStructure';
import BlankCard from '../../GameObjects/BlankCard/BlankCard';
import sequences from '../../Services/sequences'
import gameCardComponent from '../../GameObjects/gameCardComponent/gameCardComponent';




export default class GameScene extends Phaser.Scene {
    constructor() {
        super('Game');
    }
    init(data) {
        this.historyID = data.historyid;
        this.hisoryName = data.name;
    }

    preload() {
        //Get sequence service
        this.load.json('Sequence' + this.historyID, sequences.getSequences(this.historyID))
        this.sequence = []
        this.load.on('filecomplete-json-Sequence' + this.historyID, (key, type, data) => {
            for (var i = 0; i < data.length; i++) {
                this.sequence.push(data[i])
                this.load.image('Sequence' + data[i].id, data[i].image);
            }

        }
        )
        //Drawing Preload
        this.drawPreloadBackground()
        //Resize functions
        this.saveScaleState()
        //Scale this scene
        this.solutionStructure = scaleGameObject(this, this.solutionStructure)
        this.InstructionTextScale = scaleGameObject(this, this.InstructionTextScale)
        this.scaleImage = scaleGameObject(this, this.scaleImage)
        this.blank = scaleGameObject(this, this.blank)
    }
    create() {
        //Contador de tiempo
        this.startTime = performance.now();
        this.sequence = this.cache.json.get('Sequence' + this.historyID);
        if (this.sequence === undefined) {
            this.sequence = []
        }
        var background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / background.width
        let scaleY = this.sys.canvas.height / background.height
        background.setScale(scaleX, scaleY).setScrollFactor(0)

        //Setting game into the camera, resize of the cardas per number
        if (this.sequence.length > 5 && this.sequence.length < 10) {
            this.scaleImage.objectWidth = this.scaleImage.objectWidth / 1.5
            this.blank.objectWidth = this.blank.objectWidth / 1.5
            this.blank.objectPositionX = this.blank.objectPositionX * 0.9
            this.scaleImage.objectPositionX = this.scaleImage.objectPositionX * 0.9
        } else if (this.sequence.length > 9 && this.sequence.length < 13) {
            this.scaleImage.objectWidth = this.scaleImage.objectWidth / 2
            this.blank.objectWidth = this.blank.objectWidth / 2
            this.blank.objectPositionX = this.blank.objectPositionX * 0.7
            this.scaleImage.objectPositionX = this.scaleImage.objectPositionX * 0.7
        }

        //Blank set
        const originX1 = this.blank.objectPositionX
        var crossair = []
        for (var i = 0; i < this.sequence.length; i++) {
            this.blank.objectPositionX = (originX1 + (this.blank.objectWidth * i) * 1.2)
            const crossairSeq = new BlankCard(this, this.blank.objectPositionX,
                this.blank.objectPositionY, this.blank.objectWidth, this.blank.objectHeight, i + 1)
            crossair.push(crossairSeq)
        }
        for (i = 0; i < crossair.length; i++) {
            crossair[i].setInteractive()
            crossair[i].input.dropZone = true
            crossair[i].setData('sol', i)
            this.add.existing(crossair[i])

        }
        //score variables
        this.errors = 0
        this.succeses = 0
        //random function
        var order = []
        for (i = 0; i < this.sequence.length; i++) {
            order.push(i)
        }
        var randomIndex;
        var selection;
        let orderLength = this.sequence.length
        var randomArray = []
        for (i = 0; i < orderLength; i++) {
            selection = Math.floor(Math.random() * (order.length));
            randomIndex = order[selection];
            order.splice(selection, 1);
            randomArray.push(randomIndex)

        }

        //Election builder
        const originX = this.scaleImage.objectPositionX
        var sequence = []

        for (i = 0; i < this.sequence.length; i++) {
            randomIndex = randomArray[i]
            var pat = 'Sequence' + this.sequence[randomIndex].id
            this.scaleImage.objectPositionX = (originX + (this.scaleImage.objectWidth * i) * 1.2)
            const imageSequence = new gameCardComponent(this, this.scaleImage.objectPositionX,
                this.scaleImage.objectPositionY, this.scaleImage.objectWidth, this.scaleImage.objectHeight, pat)
            imageSequence.setData('img', randomIndex)
            sequence.push(imageSequence)
        }

        for (i = 0; i < sequence.length; i++) {
            sequence[i].setInteractive({ useHandCursor: true })
            this.input.setDraggable(sequence[i]);
            this.add.existing(sequence[i])


        }
        //Drag and drop Logic
        this.input.on('dragstart', function (pointer, gameObject) {
            this.children.bringToTop(gameObject);

        }, this);

        this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;

        });
        this.input.on('dragend', function (pointer, gameObject, dropped) {
            if (!dropped) {
                gameObject.x = gameObject.input.dragStartX;
                gameObject.y = gameObject.input.dragStartY;
                this.scene.sound.play('errorSound')
            }

        });
        this.input.on('drop', function (pointer, gameObject, dropZone) {
            if (gameObject.data.list.img === dropZone.data.list.sol) {
                console.log('iguales')
                gameObject.x = dropZone.x;
                gameObject.y = dropZone.y;
                this.scene.sound.play('successSound')
                gameObject.input.enabled = false;
                this.scene.succeses++
                if (this.scene.succeses === this.scene.sequence.length) {
                    console.log('gano')
                    var endTime = performance.now();
                    this.scene.windCardAppear(winCard, endTime)
                }

            }
            else {
                gameObject.x = gameObject.input.dragStartX;
                gameObject.y = gameObject.input.dragStartY;
                this.scene.sound.play('errorSound')
                this.scene.errors++
            }

        });



        //Top Bar Component
        var bar = new topBar(this, 0, 0, this.sys.canvas.width, 400, 'Ordena la secuencia', 'Vocabulary', "Ordena la secuencia para ganar")
        var winCard = new Phaser.GameObjects.Container(this, this.sys.canvas.width / 2, this.sys.canvas.height / 2)

        //Win Card component
        var winCardTitle = new Phaser.GameObjects.Text(this, 0, 0,
            'Felicidades ganaste!!, click para continuar').setOrigin(0.5)
        let font = ScaleFunctionText(this.sys.canvas.width / 2, this.sys.canvas.height / 2, 115)
        winCardTitle.setWordWrapWidth(this.sys.canvas.width / 2.2, this.sys.canvas.height / 2)
        winCardTitle.setColor('#205067');
        winCardTitle.setFontSize(font)

        var tittlebackground = new Phaser.GameObjects.Image(this, 0, 0, 'modalWinCard').setOrigin(0.5)
        tittlebackground.setDisplaySize(this.solutionStructure.objectWidth, this.solutionStructure.objectHeight)
        winCard.add(tittlebackground)
        //winCard.add(winCardTitle)
        winCard.setSize(this.sys.canvas.width / 2, this.sys.canvas.height / 2)


        this.add.existing(bar)
    }
    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    saveScaleState() {
        this.cardScale = smallCardComponentStructure
        this.InstructionTextScale = this.clone(InstructionText)
        this.scaleImage = this.clone(imageStructure)
        this.blank = this.clone(blankStructure)
        this.solutionStructure = this.clone(solutionStructure)
    }
    drawPreloadBackground() {
        this.background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / this.background.width
        let scaleY = this.sys.canvas.height / this.background.height
        this.background.setScale(scaleX, scaleY).setScrollFactor(0)
        //top bar without funcionality
        var bar = this.add.image(0, 0, 'Bar')
        scaleX = this.sys.canvas.width / 1920
        scaleY = this.sys.canvas.height / 1080
        var scalefull = this.sys.canvas.width / bar.width
        bar.setDisplaySize(bar.width * scalefull, bar.height * scaleY).setOrigin(0)

    }
    windCardAppear(winCard, endTime) {
        this.add.existing(winCard)
        this.sound.play('aplauseSound')
        winCard.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            var playedTime = endTime - this.startTime
            console.log(this.startTime)
            this.scene.start('Score', {
                historyid: this.historyID,
                errors: this.errors,
                success: this.succeses,
                historyName: this.hisoryName,
                time: playedTime
            })
        }
        )
    }


}