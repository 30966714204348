import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { CustomSettingsAssets } from "./settings";
import { FeedbackSettingAssets } from "../../HabCogGame/utils/settingsConstants/feedbackSetting";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const GeomAssets = {
    REGULAR: {
        TWO_GEOM: [
            {
                key: 'regulartwoGeom1',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom1.png'
            }, {
                key: 'regulartwoGeom2',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom2.png'
            }, {
                key: 'regulartwoGeom3',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom3.png'
            }, {
                key: 'regulartwoGeom4',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom4.png'
            }, {
                key: 'regulartwoGeom5',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom5.png'
            }, {
                key: 'regulartwoGeom6',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom6.png'
            }, {
                key: 'regulartwoGeom7',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom7.png'
            }, {
                key: 'regulartwoGeom8',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom8.png'
            }, {
                key: 'regulartwoGeom9',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom9.png'
            }, {
                key: 'regulartwoGeom10',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom10.png'
            }, {
                key: 'regulartwoGeom11',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom11.png'
            }, {
                key: 'regulartwoGeom12',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom12.png'
            }, {
                key: 'regulartwoGeom13',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom13.png'
            }, {
                key: 'regulartwoGeom14',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom14.png'
            }, {
                key: 'regulartwoGeom15',
                url: './SpaceRelationGameAssets/geoms/regular/twoGeometry/twoGeom15.png'
            }
        ],
        THREE_GEOM: [
            {
                key: 'regularthreeGeom1',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom1.png'
            }, {
                key: 'regularthreeGeom2',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom2.png'
            }, {
                key: 'regularthreeGeom3',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom3.png'
            }, {
                key: 'regularthreeGeom4',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom4.png'
            }, {
                key: 'regularthreeGeom5',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom5.png'
            }, {
                key: 'regularthreeGeom6',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom6.png'
            }, {
                key: 'regularthreeGeom7',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom7.png'
            }, {
                key: 'regularthreeGeom8',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom8.png'
            }, {
                key: 'regularthreeGeom9',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom9.png'
            }, {
                key: 'regularthreeGeom10',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom10.png'
            }, {
                key: 'regularthreeGeom11',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom11.png'
            }, {
                key: 'regularthreeGeom12',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom12.png'
            }, {
                key: 'regularthreeGeom13',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom13.png'
            }, {
                key: 'regularthreeGeom14',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom14.png'
            }, {
                key: 'regularthreeGeom15',
                url: './SpaceRelationGameAssets/geoms/regular/threeGeometry/threeGeom15.png'
            }
        ],
        FOUR_GEOM: [
            {
                key: 'regularfourGeom1',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom1.png'
            }, {
                key: 'regularfourGeom2',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom2.png'
            }, {
                key: 'regularfourGeom3',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom3.png'
            }, {
                key: 'regularfourGeom4',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom4.png'
            }, {
                key: 'regularfourGeom5',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom5.png'
            }, {
                key: 'regularfourGeom6',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom6.png'
            }, {
                key: 'regularfourGeom7',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom7.png'
            }, {
                key: 'regularfourGeom8',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom8.png'
            }, {
                key: 'regularfourGeom9',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom9.png'
            }, {
                key: 'regularfourGeom10',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom10.png'
            }, {
                key: 'regularfourGeom11',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom11.png'
            }, {
                key: 'regularfourGeom12',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom12.png'
            }, {
                key: 'regularfourGeom13',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom13.png'
            }, {
                key: 'regularfourGeom14',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom14.png'
            }, {
                key: 'regularfourGeom15',
                url: './SpaceRelationGameAssets/geoms/regular/fourGeometry/fourGeom15.png'
            }
        ]
    }
};

export const SpaceRelationGameAssets = [
    ...GeomAssets.REGULAR.TWO_GEOM,
    ...GeomAssets.REGULAR.THREE_GEOM,
    ...GeomAssets.REGULAR.FOUR_GEOM,
    ...TargetFeedbackAssets,
    ...QuantitySettingAssets,
    ...SizeSettingAssets,
    ...CustomSettingsAssets,
    ...FeedbackSettingAssets,
    ...TimeSettingAssets
];
