import React, { Component } from "react";
import Phaser from "phaser";
import BootScene from "./scenes/bootScene";
import { BaseGame } from "./utils/classes/baseGame";
import { SplashScene } from "./scenes/splashScene";
import MainMenuScene from "./scenes/mainMenuScene";
import SettingsScene from "./scenes/settingsScene";
import InstructionsScene from "./scenes/instructionsScene";
import ModalScene from "./scenes/modalScene";
import SessionHintScene from "./scenes/sessionHintScene";

export default class HabCogGame extends Component {

    componentDidMount() {
        this.props.data.sessionData = getSessionData();
        const config = buildConfig(this.props.data);
        const gameDiv = document.getElementById('phaser-game');
        this.game = new BaseGame(config, gameDiv);
        this.resizeGame = resizeGame(this.game, gameDiv);
        window.addEventListener('resize', this.resizeGame);
        if (localStorage.getItem('voiceSound') === null) {
            localStorage.setItem('voiceSound', 1)
        }
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        const width = `${document.documentElement.clientWidth}px`;
        const height = `${document.documentElement.clientHeight}px`;
        return <div id="phaser-game"
            style={{ width, height, backgroundColor: "black" }} />;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeGame);
        this.game.voiceAssistant.cancel();
        this.game.destroy(true);
    }
}

const resizeGame = (game, container) => () => {
    const DPR = window.devicePixelRatio;
    const { clientWidth, clientHeight } = document.documentElement;
    container.style.width = `${window.innerWidth}px`;
    container.style.height = `${window.innerHeight}px`;
    game.scale.resize(clientWidth * DPR, clientHeight * DPR);
}

const getSessionData = () => {
    var interventionPlan = JSON.parse(localStorage.getItem('interventionPlan')) ? JSON.parse(localStorage.getItem('interventionPlan')) : {}
    return {
        name: `${interventionPlan.first_name || 'Usuario'} ${interventionPlan.last_name || 'Invitado'}`,
        time: interventionPlan.time,
        piid: interventionPlan.code || "N/A",
        postId: interventionPlan.interventionPlanId
    }
}

const buildConfig = (data) => {
    const DPR = window.devicePixelRatio;
    return {
        backgroundColor: '#000000',
        type: Phaser.AUTO,
        scene: [BootScene,
            SplashScene,
            MainMenuScene,
            SettingsScene,
            InstructionsScene,
            SessionHintScene,
            data.sessionScene,
            ModalScene
        ],
        physics: { default: data.physics || 'arcade' },
        scale: {
            parent: 'phaser-game',
            mode: Phaser.Scale.NONE,
            height: document.documentElement.clientHeight * DPR,
            width: document.documentElement.clientWidth * DPR,
            zoom: 1 / DPR
        },
        gameData: data,
        dom: {
            createContainer: true
        }
    }
}
