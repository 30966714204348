import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { OutlineSettingKey } from '../../HabCogGame/utils/settingsConstants/outlineSetting';
import { DifficultySettingKey } from '../utils/settings';
import {
    getDifferentRandomItem, getRandomElement
} from "../../HabCogGame/utils/functions";
import {
    sortedGeomTextures, sortedOutlinedGeomTextures, Cursors
} from "../../HabCogGame/utils/constants";
import { scaleImage } from '../../HabCogGame/utils/resize';


const Image = Phaser.GameObjects.Image;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, outline } = this.game.userConfig;
        this.sTextures = getTexturePack(difficulty, outline);
        this.gameArea = this.add.container(0, this.hubHeight);
        this.startGameplay(difficulty);
    }

    createTargets(leftTexture, rightTexture = leftTexture) {
        const { width, height } = this.getAvailableSize();
        const cellWidth = width / 2;
        const cellHeight = height * 0.75;
        const y = height / 2
        const leftTarget = new Image(this, 0, y, leftTexture.key)
            .setOrigin(0, 0.5)
            .setInteractive(Cursors.pointerover);
        const rightTarget = new Image(this, width, y, rightTexture.key)
            .setOrigin(1, 0.5)
            .setInteractive(Cursors.pointerover);
        scaleImage(leftTarget, cellWidth, cellHeight, 10, 1);
        scaleImage(rightTarget, cellWidth, cellHeight, 10, 1);
        return { leftTarget, rightTarget };
    }

    startGameplay(difficulty) {
        const { COLOR_SHIFT, SHAPE_SHIFT } = DifficultySettingKey;
        const { width, height } = this.getAvailableSize();
        switch (difficulty) {
            case COLOR_SHIFT:
                this.getInstruction = getColorInstruction;
                this.texturesGroup = Object.values(this.sTextures);
                this.startTextureGameplay();
                break;
            case SHAPE_SHIFT:
                this.getInstruction = getShapeInstruction;
                this.texturesGroup = Object.values(this.sTextures);
                this.startTextureGameplay();
                break;
            default:
                this.orders = ['grande', 'pequeño'];
                this.targetScale = getTargetScale(difficulty);
                this.startSizeGameplay(width, height);
        }
    }

    updateSceneInstruction(order) {
        this.sceneTextInstruction = (localStorage.getItem('voiceSound') >= 0) ? order : ' ';
        this.hub.updateText(order);
        this.speakInstruction();
    }

    updateTextureInstruction(texture) {
        const text = this.getInstruction(texture);
        this.updateSceneInstruction(text);
    }

    startTextureGameplay(lastGroup) {
        this.clearGameArea();
        const textures = getDifferentRandomItem(this.texturesGroup, lastGroup);
        const leftTexture = getRandomElement(textures);
        const rightTexture = getDifferentRandomItem(textures, leftTexture);
        const targets = this.createTargets(leftTexture, rightTexture);
        const { leftTarget, rightTarget } = targets;
        const targetTexture = getRandomElement([leftTexture, rightTexture]);
        const onclick = (texture, selected, missed, textures) => () =>
            this.checkTextureValue(texture, selected, missed, textures);
        leftTarget.on('pointerdown', onclick(targetTexture, leftTarget, rightTarget));
        rightTarget.on('pointerdown', onclick(targetTexture, rightTarget, leftTarget));
        this.gameArea.add([leftTarget, rightTarget]);
        this.updateTextureInstruction(targetTexture);
    }

    checkTextureValue(texture, selected, missed, textures) {
        missed.off('pointerdown');
        const isCorrect = selected.texture.key === texture.key;
        isCorrect ? this.addHit() : this.addFail();
        this.time.addEvent({
            delay: 1000,
            callback: () => this.startTextureGameplay(textures)
        })
    }

    startSizeGameplay() {
        this.clearGameArea();
        const texture = getRandomElement(this.sTextures);
        const turnOrder = getRandomElement(this.orders);
        const turnOrderIndex = this.orders.indexOf(turnOrder);
        const { leftTarget, rightTarget } = this.createTargets(texture);
        const targetScale = leftTarget.scale * this.targetScale;
        const onClick = (selected, missed) => () =>
            this.checkSizeValue(turnOrderIndex, selected, missed)
        leftTarget.once('pointerdown', onClick(leftTarget, rightTarget));
        rightTarget.once('pointerdown', onClick(rightTarget, leftTarget));
        getRandomElement([leftTarget, rightTarget])
            .setScale(targetScale);
        this.gameArea.add([leftTarget, rightTarget]);
        this.updateSceneInstruction(`Toca al más ${turnOrder}`);
    };

    checkSizeValue(order, selected, missed) {
        missed.off('pointerdown');
        const isTargetLarge = order === 0 && selected.scale > missed.scale;
        const isTargetSmall = order === 1 && selected.scale < missed.scale;
        (isTargetLarge || isTargetSmall) ? this.addHit() : this.addFail();
        this.time.addEvent({
            callback: () => this.startSizeGameplay(),
            delay: 1000
        })
    }
}

const getColorInstruction = ({ color }) => `¿Cuál es de color ${color}?`;

const getShapeInstruction = ({ name }) => {
    const isFinishedInA = name[name.length - 1] === 'a';
    const pronoun = isFinishedInA ? 'una' : 'un';
    return `¿Cuál es ${pronoun} ${name}?`
}

const getTargetScale = (difficulty) => {
    const { LARGE_SIZE } = DifficultySettingKey;
    return difficulty === LARGE_SIZE ? 0.5 : 0.8;
}

const getTexturePack = (difficulty, outline) => {
    const useOutlined = outline === OutlineSettingKey.ON;
    const texturePack = useOutlined ? sortedOutlinedGeomTextures : sortedGeomTextures;
    const { COLOR_SHIFT, SHAPE_SHIFT } = DifficultySettingKey;
    switch (difficulty) {
        case COLOR_SHIFT:
            return texturePack.byShape;
        case SHAPE_SHIFT:
            return texturePack.byColor;
        default:
            return texturePack.ALL;
    }
}