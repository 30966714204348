import Phaser from 'phaser';
import { GameAsset, SceneKey, GameFont } from "../utils/constants";
import RoundPipeline from "../pipelines/roundPipeline";
import { SplashAssets } from "../utils/assetsPaths/splash";

export default class BootScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.BOOT })
    }

    preload() {
        SplashAssets.forEach(({ key, url }) => this.load.image(key, url));
        this.load.image(GameAsset.ICON, this.game.data.iconUrl);
        this.load.image(GameAsset.LOGO, this.game.data.logoUrl);
        this.load.image(GameAsset.THUMBNAIL, this.game.data.thumbnail);
        this.game.roundPipeline = this.game.renderer.addPipeline('Roundzoom', new RoundPipeline(this.game));
        this.arialRoundedFont = new window.FontFace(GameFont, 'url(/HabCogGameAssets/fonts/ArialRoundedMT.ttf)');
        this.arialRoundedFont.load()
            .then(loadedFont => document.fonts.add(loadedFont))
            .catch((e) => console.log('error al cargar la fuente', e));
        this.load.on('complete', () => this.scene.start(SceneKey.SPLASH));
    }
}
