import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { getDifferentRandomItem, getRandomElement } from "../../HabCogGame/utils/functions";
import { sortedGeomTextures, Cursors } from "../../HabCogGame/utils/constants";
import { DifficultySettingKey } from '../utils/settings';
import { pushyTweens, leaveTweens } from '../../HabCogGame/utils/pushyTweens/pushyTweens';

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty } = this.game.userConfig;
        const { width, height } = this.getAvailableSize();
        this.positions = getPositions(difficulty, width, height);
        this.lineWidth = 10 * window.devicePixelRatio;
        this.graphics = this.add.graphics()
            .lineStyle(this.lineWidth, 0xffffff)
            .setDepth(100);
        this.sTextures = sortedGeomTextures.byShape.SQUARE;
        this.animTweens = pushyTweens.map(cb => (target) => cb(this, target));
        if (difficulty !== DifficultySettingKey.FULL)
            this.enableFailOnMiss();
        this.gameArea = this.add.container(0, this.hubHeight);
        this.startGameplay();
    }

    strokeLine(target) {
        const padding = this.lineWidth / 2;
        const x = target.x + padding
        const y = target.y + this.gameArea.y + padding;
        const width = target.displayWidth - this.lineWidth;
        const height = target.displayHeight - this.lineWidth;
        this.graphics.clear();
        this.graphics.lineStyle(this.lineWidth, 0xffffff)
            .strokeRect(x, y, width, height);
    }

    startGameplay(lastPosition, lastTexture) {
        this.clearGameArea();
        const positions = this.positions;
        const pos = getDifferentRandomItem(positions, lastPosition);
        const { x, y, width, height } = pos;
        const nextTexture = getRandomElement(this.sTextures, lastTexture);
        const { outline } = this.game.userConfig;
        const target = new Phaser.GameObjects.Image(this, x, y, nextTexture.key)
            .setDisplaySize(width, height)
            .setInteractive(Cursors.pointerover)
            .setOrigin(0)
            .once('pointerdown', () => this.onTargetHit(positions, pos, target));
        this.gameArea.add(target);
        if (outline)
            this.strokeLine(target);
        getRandomElement(this.animTweens)(target);
    }

    onTargetHit(positions, currentPosition, target) {
        const nextPosition = getDifferentRandomItem(positions, currentPosition);
        target.setPosition(nextPosition.x, nextPosition.y);
        if (this.game.userConfig.outline)
            this.strokeLine(target);
        this.addHit();
        getRandomElement(leaveTweens)(this, target,
            () => target.once('pointerdown', () => {
                this.addHit();
                target.destroy();
                this.startGameplay()
            }))
    }
}

const getPositions = (difficulty, width, height) => {
    const { FULL, VERTICAL_HALF, HORIZONTAL_HALF } = DifficultySettingKey
    switch (difficulty) {
        case FULL:
            return [{ x: 0, y: 0, width, height }];
        case HORIZONTAL_HALF:
            return [
                { x: 0, y: 0, width, height: height / 2 },
                { x: 0, y: height / 2, width, height: height / 2 }];
        case VERTICAL_HALF:
            return [
                { x: 0, y: 0, width: width / 2, height },
                { x: width / 2, y: 0, width: width / 2, height }];
        default:
            return [
                { x: 0, y: 0, width: width / 2, height: height / 2 },
                { x: width / 2, y: 0, width: width / 2, height: height / 2 },
                { x: 0, y: height / 2, width: width / 2, height: height / 2 },
                { x: width / 2, y: height / 2, width: width / 2, height: height / 2 }
            ]
    }
} 
