import Phaser from "phaser";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { Closet, LookingDressSheets } from "../../utils/assetsPaths/situations/home/lookingDress";
import { addDialogTween, generateFrameNames, generateFrameNamesByArray } from "../../utils/functions";
import { MotherAnims } from "./sickPerson";
import { MomIdle } from "../../utils/assetsPaths/situations/home/sickPerson";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";

export default class LookingDressSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 3507, y: 1043 };
        const mcPosition = { x: 2800, y: 1200 };
        super(scene, markPosition, mcPosition, Badges.SCARF);
        this.instructionLabel = "Quieres tu bufanda, pero no la encuentras, ¿qué haces?";
        this.optionsData = [{
            label: "Dejo de buscar y miro mi celular.",
            onclick: this.runEvent(2500, () => this.lookPhone())
        }, {
            label: "Reviso en el armario.",
            onclick: this.runEvent(2500, () => this.selfSearch())
        }, {
            label: "Pido ayuda para encontrarla.",
            onclick: this.runEvent(2500, () => this.callMom())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(Anims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.closet = this.scene.add.sprite(0, 0, Closet.key)
            .setScale(scaleX, scaleY)
            .setPosition(3279 * scaleX, 875 * scaleY + y)
        return this;
    }

    lookPhone() {
        this.scene.mc.play(this.mcIsBoy ? Anims.MAN_DO_NOTHING.key : Anims.WOMAN_DO_NOTHING.key)
            .once("animationcomplete", this.runEvent(2500,
                () => addDialogTween(this.scene, "No encontraste la bufanda")))
    }

    selfSearch() {
        const showDialog = () => addDialogTween(this.scene, "Encontraste la bufanda", this.badge)
        this.scene.moveMcTo(3000, 1020, () => {
            this.scene.mc.setFlipX(true)
                .setTexture(this.mcIsBoy ?
                    LookingDressSheets.MAN_SCARF.key : LookingDressSheets.WOMAN_SCARF.key, 1)
            this.addEvent(1750, () => {
                this.scene.mc.setFlipX(false)
                this.scene.mc.play(this.mcIsBoy ?
                    Anims.MAN_SEARCH_CLOSET.key : Anims.WOMAN_SEARCH_CLOSET.key)
                    .once("animationcomplete", this.runEvent(2500, showDialog))
            })
        })
    }

    callMom() {
        const sheet = this.mcIsBoy ? LookingDressSheets.MAN_SCARF : LookingDressSheets.WOMAN_SCARF;
        const setFrame = (frame) => () => this.scene.mc.setTexture(sheet.key, frame)
        const { SCARED } = this.scene.game.ludo.character.anims;
        this.mom = createMother(this.scene);
        attachMotherToEvents(this.scene, this.mom);
        this.addEvent(2000, () => this.scene.mc.play(SCARED.key)
            .once("animationcomplete", () => this.scene.moveMcTo(3100, 1075, () => {
                this.scene.mc.setFlipX(true);
                this.scene.tweens.add({
                    targets: this.mom.sprite,
                    alpha: 1
                })
                this.mom.follower.goTo(new Phaser.Math.Vector2(3000, 1050))
                    .onceMovementComplete(() => {
                        this.mom.sprite.setFlipX(false)
                            .play(Anims.MOM_SEARCH.key)
                            .once("animationcomplete", () => {
                                this.addEvent(1500, () => {
                                    this.mom.follower.emit("onMovementComplete");
                                    setFrame(17)();
                                    this.addEvent(1500, setFrame(18))
                                    this.addEvent(2500, setFrame(19))
                                    this.addEvent(5000, () => {
                                        this.scene.events.off("update", this.mom.move);
                                        this.mom.sprite.destroy();
                                        this.mom.follower.destroy();
                                        addDialogTween(this.scene, "Encontraste la bufanda", this.badge)
                                    })
                                })

                            })
                    })
            })))


    }

    reboot(onComplete) {
        this.scene.follower.emit("onMovementComplete")
        onComplete();
    }

    start() {
        this.scene.mc.setFlipX(true);
        this.addEvent(2000, () => {
            this.scene.mc.setFlipX(false);
            this.scene.mc.play(this.mcIsBoy ? Anims.MAN_SEARCH.key : Anims.WOMAN_SEARCH.key)
                .once("animationcomplete", this.runEvent(2000, () => this.showHint()))
        }
        )
    }


}

const createMother = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2400, 1334);
    const sprite = createCharacterSprite(scene, MomIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(MotherAnims.WalkUp, false))
        .onTopRightMovement(anim(MotherAnims.WalkUp, true))
        .onBottomLeftMovement(anim(MotherAnims.WalkDown, true))
        .onBottomRightMovement(anim(MotherAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(MomIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}

const attachMotherToEvents = (scene, mom) => {
    scene.events.on("update", mom.move);
    scene.events.once("shutdown",
        () => scene.events.off("update", mom.move));
}

const Anims = {
    MOM_SEARCH: {
        key: "momSearchScarf",
        frames: generateFrameNames(LookingDressSheets.MOM_SCARF.key, 0, 7),
        frameRate: 1
    },
    MAN_SEARCH: {
        key: "manSearchScarf",
        frames: generateFrameNamesByArray(LookingDressSheets.MAN_SCARF.key, [
            0, 1, 2, 3, 4,
            3, 2,
            3, 4,
            3, 2,
            3, 4,
            5
        ]),
        frameRate: 2
    },
    MAN_SEARCH_CLOSET: {
        key: "manSearchScarfOnCloset",
        frames: generateFrameNamesByArray(LookingDressSheets.MAN_SCARF.key, [
            13, 15, 16, 17, 18, 19]),
        frameRate: 1
    },
    MAN_DO_NOTHING: {
        key: "manDontSearchScarf",
        frames: generateFrameNames(LookingDressSheets.MAN_LAZY.key, 6, 8),
        frameRate: 1
    },
    WOMAN_SEARCH: {
        key: "womanSearchScarf",
        frames: generateFrameNamesByArray(LookingDressSheets.WOMAN_SCARF.key, [
            0, 1, 2, 3, 4,
            3, 2,
            3, 4,
            3, 2,
            3, 4,
            5
        ]),
        frameRate: 1
    },
    WOMAN_SEARCH_CLOSET: {
        key: "womanSearchScarfOnCloset",
        frames: generateFrameNamesByArray(LookingDressSheets.WOMAN_SCARF.key, [
            13, 15, 16, 17, 18, 19]),
        frameRate: 1
    },
    WOMAN_DO_NOTHING: {
        key: "womanDontSearchScarf",
        frames: generateFrameNames(LookingDressSheets.WOMAN_LAZY.key, 6, 8),
        frameRate: 1
    },
}