export const mediumCardComponentStructure = {
    cardImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 470,
            objectHeight: 720,
            objectPositionX: 0,
            objectPositionY: 0,
        }
    },
    cardTitle: {
        text: '',
        style: {
            fontSize: '38px',
            fontFamily: 'Segoe UI Emoji',
            color: '#FF0000',
            align: 'center',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 450,
            objectHeight: 50,
            objectPositionX: 0,
            objectPositionY: 0
        }
    },
    cardDescription:
    {
        text: '',
        style: {
            fontSize: '22px',
            fontFamily: 'Segoe UI Emoji',
            color: '#000000',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 1,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 475,
            objectHeight: 225,
            objectPositionX: 0,
            objectPositionY: 185,
        }

    },
    cardButton:
    {
        text: '',
        nameAsset: 'soundIcon',
        style: {
            fontSize: '30px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 100,
            objectHeight: 100,
            objectPositionX: 170,
            objectPositionY: -250,
        }

    }
}