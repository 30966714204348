export const BaseContourPhotos = [
    {
        key: 'bikePhoto',
        url: './contourPhotos/bike.png'
    }, {
        key: 'cameraPhoto',
        url: './contourPhotos/camera.png'
    }, {
        key: 'carPhoto',
        url: './contourPhotos/car.png'
    }, {
        key: 'carrotPhoto',
        url: './contourPhotos/carrot.png'
    }, {
        key: 'coffeePhoto',
        url: './contourPhotos/coffee.png'
    }, {
        key: 'dressPhoto',
        url: './contourPhotos/dress.png'
    }, {
        key: 'elephantPhoto',
        url: './contourPhotos/elephant.png'
    }, {
        key: 'flamingoPhoto',
        url: './contourPhotos/flamingo.png'
    }, {
        key: 'glovesPhoto',
        url: './contourPhotos/gloves.png'
    }, {
        key: 'guitarPhoto',
        url: './contourPhotos/guitar.png'
    }, {
        key: 'housePhoto',
        url: './contourPhotos/house.png'
    }, {
        key: 'parrotPhoto',
        url: './contourPhotos/parrot.png'
    }, {
        key: 'phonePhoto',
        url: './contourPhotos/phone.png'
    }, {
        key: 'planePhoto',
        url: './contourPhotos/plane.png'
    }, {
        key: 'pursePhoto',
        url: './contourPhotos/purse.png'
    }, {
        key: 'shelfPhoto',
        url: './contourPhotos/shelf.png'
    }, {
        key: 'shoesPhoto',
        url: './contourPhotos/shoes.png'
    }, {
        key: 'sofaPhoto',
        url: './contourPhotos/sofa.png'
    }, {
        key: 'teapotPhoto',
        url: './contourPhotos/teapot.png'
    }, {
        key: 'turtlePhoto',
        url: './contourPhotos/turtle.png'
    }
]

export const ContourPhotos = [
    {
        key: 'bikePhotoContour',
        url: './contourPhotos/bikeContour.png'
    }, {
        key: 'cameraPhotoContour',
        url: './contourPhotos/cameraContour.png'
    }, {
        key: 'carPhotoContour',
        url: './contourPhotos/carContour.png'
    }, {
        key: 'carrotPhotoContour',
        url: './contourPhotos/carrotContour.png'
    }, {
        key: 'coffeePhotoContour',
        url: './contourPhotos/coffeeContour.png'
    }, {
        key: 'dressPhotoContour',
        url: './contourPhotos/dressContour.png'
    }, {
        key: 'elephantPhotoContour',
        url: './contourPhotos/elephantContour.png'
    }, {
        key: 'flamingoPhotoContour',
        url: './contourPhotos/flamingoContour.png'
    }, {
        key: 'glovesPhotoContour',
        url: './contourPhotos/glovesContour.png'
    }, {
        key: 'guitarPhotoContour',
        url: './contourPhotos/guitarContour.png'
    }, {
        key: 'housePhotoContour',
        url: './contourPhotos/houseContour.png'
    }, {
        key: 'parrotPhotoContour',
        url: './contourPhotos/parrotContour.png'
    }, {
        key: 'phonePhotoContour',
        url: './contourPhotos/phoneContour.png'
    }, {
        key: 'planePhotoContour',
        url: './contourPhotos/planeContour.png'
    }, {
        key: 'pursePhotoContour',
        url: './contourPhotos/purseContour.png'
    }, {
        key: 'shelfPhotoContour',
        url: './contourPhotos/shelfContour.png'
    }, {
        key: 'shoesPhotoContour',
        url: './contourPhotos/shoesContour.png'
    }, {
        key: 'sofaPhotoContour',
        url: './contourPhotos/sofaContour.png'
    }, {
        key: 'teapotPhotoContour',
        url: './contourPhotos/teapotContour.png'
    }, {
        key: 'turtlePhotoContour',
        url: './contourPhotos/turtleContour.png'
    }
]

export const ContourPhotosAssets = [...BaseContourPhotos, ...ContourPhotos];