export const InstructionTextStructure = {
    instructionImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 150,
            objectHeight: 150,
            objectPositionX: -650,
            objectPositionY: 0,
        }
    },
    soundButton:
    {
        text: '',
        nameAsset: 'soundIcon',
        style: {
            fontSize: '38px',
            fontFamily: 'ArialRounded',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 35,
            objectHeight: 35,
            objectPositionX: -600,
            objectPositionY: -60,
        }
    },
    instructionDescription:
    {
        text: '',
        style: {
            fontSize: '50px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'left',
            stroke: '#ff00ff'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 1400,
            objectHeight: 500,
            objectPositionX: 0,
            objectPositionY: -25,
        }

    },
    descriptionReduxFont:
    {
        text: '',
        style: {
            fontSize: '50px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'justify',
            stroke: '#205067'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 1400,
            objectHeight: 500,
            objectPositionX: 0,
            objectPositionY: -20,
        }

    },
    instructionColor: {
        text: '',
        style: {
            fontSize: '35px',
            fontFamily: 'Arial',
            color: '#84609d',
            align: 'center',
            stroke: '#84609d'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 1000,
            objectHeight: 500,
            objectPositionX: 0,
            objectPositionY: 100,
        }
    },
    instructionColorOtherPosition: {
        text: '',
        style: {
            fontSize: '35px',
            fontFamily: 'Arial',
            color: '#84609d',
            align: 'center',
            stroke: '#84609d'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 1000,
            objectHeight: 500,
            objectPositionX: 0,
            objectPositionY: 70,
        }
    },
    nextButton:
    {
        text: '',
        nameAsset: 'sequenceButton',
        style: {
            fontSize: '25px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 220,
            objectHeight: 50,
            objectPositionX: 0,
            objectPositionY: 130,
        }
    }
}