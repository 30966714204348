import { BossIdle } from "../assetsPaths/situations/work/agressiveCoworker";
import { createSecondaryFollower, createCharacterSprite } from "../factory";
import { BossAnims } from "./boss";
import { FriendAnims } from "./friend";
import { FriendIdle, BaldManIdle } from "../assetsPaths/situations/work/askingRestroom";
import { BaldManAnims } from "./baldMan";

const createNPC = (scene, px, py, idle, frontAnim, backAnim, alpha = 0, is) => {
    console.log(frontAnim, backAnim);
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, px, py);
    const sprite = createCharacterSprite(scene, idle, 0)
        .setScale(scene.mc.scale).setAlpha(alpha)
    const anim = ({ key }, flipX) => () => { sprite.play(key).setFlipX(flipX) };
    follower
        .onTopLeftMovement(anim(backAnim, false))
        .onTopRightMovement(anim(backAnim, true))
        .onBottomLeftMovement(anim(frontAnim, true))
        .onBottomRightMovement(anim(frontAnim, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(idle.key)
        )
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);
    scene.events.on("update", move);
    scene.events.once("shutdown",
        () => scene.events.off("update", move));
    const destroy = () => {
        scene.events.off("update", move);
        follower.destroy();
        sprite.destroy();
    }
    return { follower, sprite, move, destroy };
}

const createBoss = (scene, x, y) =>
    createNPC(scene, x, y, BossIdle.FRONT, BossAnims.WALK_FRONT, BossAnims.WALK_BACK);

const createFriend = (scene, x, y) =>
    createNPC(scene, x, y, FriendIdle.FRONT, FriendAnims.WALK_FRONT, FriendAnims.WALK_BACK);

const createBaldMan = (scene, x, y) =>
    createNPC(scene, x, y, BaldManIdle.FRONT, BaldManAnims.WALK_FRONT, BaldManAnims.WALK_BACK);

const npcAnims = [
    ...Object.values(BossAnims),
    ...Object.values(FriendAnims),
    ...Object.values(BaldManAnims)
]

export {
    createNPC,
    createBoss,
    createFriend,
    createBaldMan,
    npcAnims,
}