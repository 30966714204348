import { DifficultySettingAssets } from "./settings";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const TARGET = {
    key: 'ballTarget',
    url: './EscapeRouteGameAssets/ballTarget.png'
}
export const LEFT_SIGN = {
    key: 'leftChevronSign',
    url: './EscapeRouteGameAssets/leftChevron.png'
}

export const msgBackground = {
    key: 'msgBackground',
    url: './NosyGameAssets/msgBox.png'
};

export const sessionAssets = [
    TARGET,
    LEFT_SIGN,
    msgBackground,
    ...DifficultySettingAssets,
    ...SizeSettingAssets,
    ...TargetFeedbackAssets,
    ...TimeSettingAssets
]