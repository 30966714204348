import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { outlineSetting, OutlineSettingAssets } from "../HabCogGame/utils/settingsConstants/outlineSetting";
import { difficultySetting, DifficultySettingAssets } from "./utils/setting";
import { FilledGeomsAssets } from '../HabCogGame/utils/assetsPaths/filledGeoms';
import { OutlinedGeomsAssets } from '../HabCogGame/utils/assetsPaths/outlinedGeoms';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const getHint = (userConfig) => {
    let instruction = 'Toca para cambiar de ';
    if (userConfig.difficulty === 0)
        return instruction + 'tamaño.';
    else if (userConfig.difficulty === 1)
        return instruction + 'color.';
    return instruction + 'forma.'
};

const data = {
    name: 'Todo cambia',
    sessionAssets: [
        ...FilledGeomsAssets,
        ...OutlinedGeomsAssets,
        ...SizeSettingAssets,
        ...OutlineSettingAssets,
        ...DifficultySettingAssets,
        ...TimeSettingAssets
    ],
    sessionScene: SessionScene,
    logoUrl: './EverythingChangesGameAssets/logo.png',
    iconUrl: './EverythingChangesGameAssets/icon.png',
    thumbnail: './EverythingChangesGameAssets/thumbnail.png',
    getHint,
    userManual: 'Se presenta un estímulo en el centro de la pantalla, con cada selección ' +
        'que realice el usuario cambiará de tamaño, forma o color según la ' +
        'dificultad que se haya seleccionado en ajustes.'
    ,
    settings: [
        difficultySetting,
        sizeSetting,
        outlineSetting,
        timeSetting
    ]
};

export default function EverythingChangesGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
