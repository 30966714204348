export default class Score {
    constructor() {
        this.attempts = 0;
        this.hits = 0;
        this.fails = 0;
        this.time = 0;
        this.cityBadges = [];
        this.homeBadges = [];
        this.workBadges = [];
    }

    addHits(score = 1) {
        this.hits += score;
        this.attempts += score;
    }

    addFails(score = 1) {
        this.fails += score;
        this.attempts += score;
    }



}
