import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { outlineSetting, OutlineSettingAssets } from "../HabCogGame/utils/settingsConstants/outlineSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { getDifficultySetting, DifficultySettingAssets } from "./utils/settings";
import standbySetting, { StandBySettingAssets } from "../HabCogGame/utils/settingsConstants/standbySetting";
import { FilledGeomsAssets } from '../HabCogGame/utils/assetsPaths/filledGeoms';
import { OutlinedGeomsAssets } from '../HabCogGame/utils/assetsPaths/outlinedGeoms';
import { QuantitySettingAssets } from '../HabCogGame/utils/settingsConstants/quantitySetting';
import { ColorSettingAssets } from '../HabCogGame/utils/settingsConstants/colorSetting';
import { ShapeSettingAssets } from '../HabCogGame/utils/settingsConstants/shapeSetting';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const difficultySetting = getDifficultySetting();
const quantitySetting = buildQuantitySetting([3, 4, 5, 6, 9, 24, 48, 108]);

const sessionAssets = [
    ...DifficultySettingAssets,
    ...ColorSettingAssets,
    ...ShapeSettingAssets,
    ...QuantitySettingAssets,
    ...OutlineSettingAssets,
    ...StandBySettingAssets,
    ...FilledGeomsAssets,
    ...OutlinedGeomsAssets,
    ...TimeSettingAssets
]

const data = {
    name: 'Igualitos',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './AlikeGameAssets/logo.png',
    iconUrl: './AlikeGameAssets/icon.png',
    thumbnail: './AlikeGameAssets/thumbnail.png',
    getHint: () => 'Toca las figuras que te pida.',
    userManual: 'Se presenta un conjunto de estímulos alineados en filas y columnas, el ' +
        'usuario debe seleccionar aquellos que tienen iguales atributos de forma y ' +
        'color que el modelo.',
    settings: [
        difficultySetting,
        quantitySetting,
        outlineSetting,
        standbySetting,
        timeSetting
    ]
};

export default function AlikeGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
