import Phaser from 'phaser';
import { Btns } from "../utils/assetsPaths/btn";
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image;

export default class OptionSelectedIndicator extends Phaser.GameObjects.Container {

    constructor(scene, x, y, length = 1, selectedIndex = 0) {
        super(scene, x, y);
        this.optionsLength = length;
        this.selectedIndex = selectedIndex;
        this.background = new Image(scene, 0, 0, Btns.OPTION_INDICATOR.key);
        this.indicator = new Image(scene, 0, 0, Btns.OPTION_SELECTED.key).setOrigin(0);
        this.add([this.background, this.indicator]);
        this.calculatePositions();
    }

    calculatePositions() {
        this.positions = [];
        for (let i = 0; i < this.optionsLength; i++)
            this.positions.push(i * this.background.displayWidth / (this.optionsLength));
    }

    updateIndicator() {
        const { x, y } = this.background.getTopLeft();
        this.scene.tweens.add({
            targets: this.indicator,
            ease: 'Power3',
            duration: 450,
            x: x + this.positions[this.selectedIndex],
            y
        });
    }

    setSelectedIndex(index) {
        this.selectedIndex = index;
        this.updateIndicator();
    }

    resize(tileBackground) {
        const { displayWidth, displayHeight } = tileBackground;
        scaleImage(this.background, displayWidth / 5, displayHeight / 10, 0, 1);
        this.indicator.setScale(this.background.scale);
        this.indicator.setDisplaySize(this.background.displayWidth / this.optionsLength, this.indicator.displayHeight);
        this.calculatePositions();
        this.updateIndicator();
    }
}