import { generateFrameNamesByArray, generateFrameNames } from "../../../functions"

export const SwitchingTaskSheets = {
    BOSS_BOX: {
        key: "w3s7BossBox",
        url: "SocialInclusionAssets/situations/switchingTask/bossBox.png",
        frameWidth: 216 / 3,
        frameHeight: 394 / 3
    },
    MAN_IGNORE: {
        key: "w3s7ManIgnoreBox",
        url: "SocialInclusionAssets/situations/switchingTask/manIgnore.png",
        frameWidth: 385 / 5,
        frameHeight: 712 / 5
    },
    MAN_SWITCH: {
        key: "w3s7manSwitchTask",
        url: "SocialInclusionAssets/situations/switchingTask/manSwitch.png",
        frameWidth: 526 / 5,
        frameHeight: 579 / 4
    },
    MAN_TALK: {
        key: "w3s7manTalkBox",
        url: "SocialInclusionAssets/situations/switchingTask/manTalk.png",
        frameWidth: 627 / 6,
        frameHeight: 718 / 5
    },
    WOMAN_IGNORE: {
        key: "w3s7woManIgnoreBox",
        url: "SocialInclusionAssets/situations/switchingTask/womanIgnore.png",
        frameWidth: 372 / 5,
        frameHeight: 720 / 5
    },
    WOMAN_SWITCH: {
        key: "w3s7womanSwitchTask",
        url: "SocialInclusionAssets/situations/switchingTask/womanSwitch.png",
        frameWidth: 453 / 5,
        frameHeight: 586 / 4
    },
    WOMAN_TALK: {
        key: "w3s7womanTalkBox",
        url: "SocialInclusionAssets/situations/switchingTask/womanTalk.png",
        frameWidth: 543 / 6,
        frameHeight: 732 / 5
    },
}

export const SwitchingTaskAnims = {
    MAN_START_TASK: {
        key: "W3S7ManstartTask",
        frames: generateFrameNamesByArray(SwitchingTaskSheets.MAN_SWITCH.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 5, 6, 7, 8, 9, 10
        ]),
        frameRate: 2,
    },
    MAN_IGNORE: {
        key: "W3S7Manignoretask",
        frames: generateFrameNames(SwitchingTaskSheets.MAN_IGNORE.key, 12, 23),
        frameRate: 2,
    },
    MAN_DIALOGUE: {
        key: "W3S7Mandialogue",
        frames: generateFrameNamesByArray(SwitchingTaskSheets.MAN_TALK.key, [
            12, 13, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26,
            21, 22, 23, 24, 25, 26
        ]),
        frameRate: 2,
    },
    MAN_SWITCH_TASK: {
        key: "W3S7Manswitchtaskaa",
        frames: generateFrameNames(SwitchingTaskSheets.MAN_SWITCH.key, 14, 19),
        frameRate: 2,
        repeat: 1
    },
    WOMAN_START_TASK: {
        key: "W3S7woManstartTask",
        frames: generateFrameNamesByArray(SwitchingTaskSheets.WOMAN_SWITCH.key, [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 5, 6, 7, 8, 9, 10
        ]),
        frameRate: 2,
    },
    WOMAN_IGNORE: {
        key: "W3S7woManignoretask",
        frames: generateFrameNames(SwitchingTaskSheets.WOMAN_IGNORE.key, 12, 23),
        frameRate: 2,
    },
    WOMAN_DIALOGUE: {
        key: "W3S7woMandialogue",
        frames: generateFrameNamesByArray(SwitchingTaskSheets.WOMAN_TALK.key, [
            12, 13, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26,
            21, 22, 23, 24, 25, 26
        ]),
        frameRate: 2,
    },
    WOMAN_SWITCH_TASK: {
        key: "W3S7woManswitchtaskaa",
        frames: generateFrameNames(SwitchingTaskSheets.WOMAN_SWITCH.key, 14, 19),
        frameRate: 2,
        repeat: 1,
    },
    BOSS_BOX: {
        key: "W3S7bossBoxAnim",
        frames: generateFrameNamesByArray(SwitchingTaskSheets.BOSS_BOX.key, [
            0, 1, 2, 3, 2, 3, 2, 3, 4, 5
        ]),
        frameRate: 2,
    }

}

export const SwitchingTaskAssets = {
    Images: [],
    SpriteSheets: Object.values(SwitchingTaskSheets),
}