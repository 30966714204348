import Phaser from "phaser";
import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { FeelingSadSheets, FeelingSadAnims } from "../../utils/assetsPaths/situations/work/feelingSad";
import { createFriend } from "../../utils/npcFactory.js/factory";

export default class FeelingSadSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 2665, y: 739 };
        const mcPosition = { x: 2265, y: 1030 };
        super(scene, markPosition, mcPosition, Badges.STAR);
        this.instructionLabel = "Estás en tu trabajo y te sientes triste, ¿qué haces?";
        this.optionsData = [{
            label: "Me siento a llorar y dejo de trabajar.",
            onclick: this.runEvent(2500, () => this.cry())
        }, {
            label: "Lloro un rato, me seco las lágrimas y sigo trabajando.",
            onclick: this.runEvent(2500, () => this.dontCry())
        }, {
            label: "Busco a una persona que me pueda ayudar.",
            onclick: this.runEvent(2500, () => this.getHelp())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(FeelingSadAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        const frame = this.mcIsBoy ? FeelingSadSheets.MAN_DONT_CRY: FeelingSadSheets.WOMAN_DONT_CRY;
        this.sprite = this.scene.add.sprite(0, 0, frame.key, 0)
            .setScale(this.scene.mc.scale)
            .setAlpha(0)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 2300, scaleY * 1025 + y);
        return this;
    }

    cry() {
        const anim = this.mcIsBoy ? FeelingSadAnims.MAN_CRY : FeelingSadAnims.WOMAN_CRY;
        this.sprite.play(anim.key)
            .once("animationcomplete", () => {
                addDialogTween(this.scene, "Mala decisión");
            })
    }

    dontCry() {
        const anim = this.mcIsBoy ? FeelingSadAnims.MAN_DONT_CRY : FeelingSadAnims.WOMAN_DONT_CRY;
        this.sprite.play(anim.key)
            .once("animationcomplete", this.runEvent(2000, () =>
                addDialogTween(this.scene, "Buena desición", this.badge)
            ))
    }

    getHelp() {
        const friend = createFriend(this.scene, 2813, 1442);
        const sheet = this.mcIsBoy ? FeelingSadSheets.MAN_GET_HELP : FeelingSadSheets.WOMAN_GET_HELP;
        const anim = this.mcIsBoy ? FeelingSadAnims.MAN_CALL : FeelingSadAnims.WOMAN_CALL;
        const getHelp = this.mcIsBoy ? FeelingSadAnims.MAN_GET_HELP : FeelingSadAnims.WOMAN_GET_HELP;
        const backToWork = this.mcIsBoy ? FeelingSadAnims.MAN_BACK_TO_WORK : FeelingSadAnims.WOMAN_BACK_TO_WORK;
        this.sprite.play(anim.key)
            .once("animationcomplete", this.runEvent(2000, () => {
                this.scene.tweens.add({
                    targets: friend.sprite,
                    alpha: 1
                });
                friend.follower.goTo(new Phaser.Math.Vector2(2360, 1030))
                    .onceMovementComplete(() => {
                        friend.sprite.setFlipX(true);
                        this.addEvent(1500, () => {
                            friend.sprite.setAlpha(0);
                            this.sprite.play(getHelp.key)
                                .once("animationcomplete", () => {
                                    this.addEvent(1500, () => {
                                        friend.sprite.setAlpha(1);
                                        this.sprite.setTexture(sheet.key, 24)
                                        this.addEvent(1000, () => this.sprite.setTexture(sheet.key, 25))
                                        this.addEvent(2000, () => friend.follower.goTo(new Phaser.Math.Vector2(2813, 1442))
                                            .onceMovementComplete(() => friend.destroy()))
                                        this.addEvent(4000, () => {
                                            this.sprite.play(backToWork.key)
                                                .once("animationcomplete", this.runEvent(2000, () => addDialogTween(this.scene, "Buena decisión", this.badge)));
                                        })
                                        this.scene.tweens.add({
                                            targets: friend.sprite,
                                            alpha: 0,
                                            delay: 3000,
                                        })


                                    })
                                })
                        })
                    })
            }))
    }

    reboot(onComplete) {
        const frame = this.mcIsBoy ? FeelingSadSheets.MAN_DONT_CRY: FeelingSadSheets.WOMAN_DONT_CRY;
        this.sprite.setAlpha(0).setTexture(frame.key, 0);
        this.scene.mc.setAlpha(1);
        onComplete();

    }

    start() {
        const mc = this.scene.mc;

        this.sprite.setAlpha(1);
        mc.setAlpha(0);
        const takeSit = this.mcIsBoy ? FeelingSadAnims.MAN_TAKE_SIT : FeelingSadAnims.WOMAN_TAKE_SIT
        mc.setFlipX(false);
        this.addEvent(2000, () => {

            this.sprite.play(takeSit.key)
                .once("animationcomplete", this.runEvent(2000, () => this.showHint()))
        })
    }
}