import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import { wrapResizeFn } from '../utils/resize';
import { createZoomCamera, createBackgroundImage, createAccesibilityBtns, createBtn, createHeaderBar, createLargeMessageBox, createUserTag, setZoomBtnOnclick } from '../utils/gameObjectFactory';
import { Icons } from '../utils/assetsPaths/icons';
import { Btns } from '../utils/assetsPaths/btn';

export default class SessionHintScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.SESSION_HINT });
    }

    create() {
        this.textDescription = this.game.data.getHint(this.game.userConfig);
        let { sessionData, name } = this.game.data;
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        this.returnBtn = createBtn(this, 'Regresar', Icons.RETURN, Btns.SECONDARY);
        this.continueBtn = createBtn(this, 'Continuar', Icons.CONTINUE, Btns.SECONDARY);
        this.btns = [this.returnBtn, this.continueBtn];
        createHeaderBar(this, name);
        createLargeMessageBox(this, this.textDescription);
        createUserTag(this, sessionData);
        this.setBtnsInteractive();
        this.speakAtStart();
        wrapResizeFn(this);
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.textDescription : ' '
        this.game.voiceAssistant.speak(this.helpText);
    }

    stopVoice() {
        this.game.voiceAssistant.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.textDescription : ' '
    }
    setBtnsInteractive() {
        this.continueBtn.setInteractive().onclick(() => this.scene.start(SceneKey.GAME_SESSION));
        this.returnBtn.setInteractive().onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.voiceOverBtn.onclick(() => this.game.voiceAssistant.speakHelpText(this.helpText));
        setZoomBtnOnclick(this, this.zoomBtn);
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        this.events.once('shutdown', () => this.game.voiceAssistant.cancel());
    }

    resizeLandscape(width, height) {
        const { background, accesibilityBtns, btns, header, userTag } = this;
        const headerHeight = height / 7;
        const halfWidth = width / 2;
        const quarterWidth = width / 2;
        const eightHeight = height / 8;
        const eightWidth = width / 8
        background.setDisplaySize(width, height);
        btns.forEach(btn => btn.resize(quarterWidth, eightHeight, 75, 1));
        accesibilityBtns.forEach(btn => btn.resize(eightWidth, eightHeight, 50, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 100, 1);
        this.setLandscapePositions(width, height, halfWidth, headerHeight);
    }

    setLandscapePositions(width, height, halfWidth, headerHeight) {
        const { accesibilityBtns, btns, msgBox, userTag, header } = this;
        const position = Phaser.Display.Align.CENTER
        msgBox.setPosition(halfWidth, height / 2);
        userTag.setPosition(width - userTag.background.displayWidth / 2 - headerHeight / 4, headerHeight / 2);
        header.alignLabelRightPosition(width, userTag.getLeftCenter().x);
        msgBox.setPosition(halfWidth, height / 2);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5,
            y: height - accesibilityBtns[0].background.displayHeight
        });

        const cellWidth = btns[0].background.displayWidth * 1.25;
        Phaser.Actions.GridAlign(btns, {
            width: -1, cellWidth, position,
            x: halfWidth - cellWidth / 2,
            y: accesibilityBtns[0].y,
        })
        const { x } = accesibilityBtns[0].getLeftCenter();
        if (btns[1].getRightCenter().x > x - 10 * window.devicePixelRatio)
            Phaser.Actions.GridAlign(btns, {
                width: -1, cellWidth, position,
                x: x / 2 - cellWidth / 2,
                y: accesibilityBtns[0].y,
            })
        msgBox.resize(width, (btns[0].getTopCenter().y - headerHeight), 100, 1);
    }

    resizePortrait(width, height) {
        const { background, header, userTag, btns, accesibilityBtns } = this;
        const headerHeight = height / 7.5;
        const halfWidth = width / 2;
        const eightHeight = height / 8;
        background.setDisplaySize(100, height).setScale(background.scaleY, background.scaleY);
        header.resize(width, headerHeight);
        btns.forEach(btn => btn.resize(width, eightHeight, 100, 1));
        accesibilityBtns.forEach(btn => btn.resize(halfWidth, eightHeight, 100, 1));
        userTag.resize(width, height / 5, 100, 1);
        this.setPortraitPositions(width, height);
    }

    setPortraitPositions(width, height) {
        const { btns, userTag, header, msgBox, accesibilityBtns } = this;
        const halfWidth = width / 2;
        const { displayHeight, displayWidth } = accesibilityBtns[0].background;
        const cellHeight = btns[0].background.displayHeight * 1.2;
        const position = Phaser.Display.Align.CENTER;
        const cellWidth = displayWidth * 1.5;
        userTag.setPosition(halfWidth, header.background.height + userTag.background.displayHeight * 0.6);
        header.alignLabelLeftPosition(width);

        Phaser.Actions.GridAlign(btns, {
            height: -1, cellHeight, position,
            x: halfWidth,
            y: height - btns.length * cellHeight
        })
        msgBox.resize(width, btns[0].getTopCenter().y - userTag.getBottomCenter().y - displayHeight, 100, 1);
        msgBox.setPosition(halfWidth, (btns[0].getTopCenter().y + userTag.getBottomCenter().y) / 2 - displayHeight);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            x: halfWidth - cellWidth / 2,
            y: msgBox.getBottomCenter().y + displayHeight
        })
    }
}
