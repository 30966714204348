import { Badges } from "../../utils/assetsPaths/badges";
import Situation from "../../utils/classes/situation";
import { addDialogTween } from "../../utils/functions";
import { Platform, WorkingFastSheets, WorkingFastAnims } from "../../utils/assetsPaths/situations/work/workingFast";
import { ManWorkIdle, WomanWorkIdle } from "../../utils/assetsPaths/situations/work/boringWork";

export default class WorkingFastSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1500, y: 350 };
        const mcPosition = { x: 1775, y: 540 };
        super(scene, markPosition, mcPosition, Badges.CAR);
        this.instructionLabel = "Tu compañero terminó el trabajo y tú no, ¿qué haces?";
        this.optionsData = [{
            label: "Me enojo y dejo de trabajar.",
            onclick: this.runEvent(2500, () => this.getAngry())
        }, {
            label: "Le felicito y continúo trabajando.",
            onclick: this.runEvent(2500, () => this.dontCare())
        }, {
            label: "Sigo trabajando hasta terminar.",
            onclick: this.runEvent(2500, () => this.congrat())
        }]
    }

    preload() {
        Object.values(WorkingFastAnims).forEach(anim => this.scene.anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.sprite = this.scene.add.sprite(0, 0, Platform.key, 0)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 1830, scaleY * 550 + y)
            .setScale(this.scene.mc.scale);
        this.coworker = this.scene.add.sprite(0, 0, WorkingFastSheets.COWORKER_PLATFORM.key, 0)
            .setOrigin(0.5, 0.9)
            .setPosition(scaleX * 1475, scaleY * 850 + y)
            .setScale(this.scene.mc.scale);
        return this;
    }

    getAngry() {
        const anim = this.mcIsBoy ? WorkingFastAnims.MAN_ANGRY : WorkingFastAnims.WOMAN_WORK;
        this.sprite.play(anim.key)
            .once("animationcomplete", this.runEvent(2000, () => addDialogTween(this.scene, "Mala desición")))
    }

    dontCare() {
        const sheet = this.mcIsBoy ? WorkingFastSheets.MAN_BOX_DONT_CARE : WorkingFastSheets.WOMAN_BOX_DONT_CARE;
        this.sprite.setTexture(sheet.key, 19);
        this.addEvent(2000, () => addDialogTween(this.scene, "Buena decisión", this.badge))
    }

    congrat() {
        const anim = this.mcIsBoy ? WorkingFastAnims.MAN_CONGRAT : WorkingFastAnims.WOMAN_CONGRAT;
        this.sprite.play(anim.key)
            .once("animationcomplete",
                this.runEvent(2000,
                    () => addDialogTween(this.scene, "Buena desición", this.badge)));
    }

    reboot(onComplete) {
        this.sprite.setTexture(Platform.key);
        this.coworker.setTexture(WorkingFastSheets.COWORKER_PLATFORM.key, 0);
        this.scene.mc.setAlpha(1);
        onComplete();
    }

    start() {
        const idle = this.mcIsBoy ? ManWorkIdle.BACK : WomanWorkIdle.BACK;
        const anim = this.mcIsBoy ? WorkingFastAnims.MAN_WORK : WorkingFastAnims.WOMAN_WORK;
        this.scene.mc.setTexture(idle.key).setFlipX(true);
        this.addEvent(2000, () => {
            this.scene.mc.setAlpha(0);
            this.coworker.play(WorkingFastAnims.COWORKER.key);
            this.sprite.play(anim.key)
                .once("animationcomplete", () => {
                    this.addEvent(2000, () => this.showHint())
                })

        })
    }

}