import { createMapPoint } from '../factory';
import OptionSelector from '../../gameObjects/optionSelector';
import { Btns } from '../assetsPaths/btn';
import { BOY_SHEETS } from '../assetsPaths/playerAnim';

class Situation {

    constructor(scene, markPosition, mcPosition, badge) {
        this.position = markPosition;
        this.mcPosition = mcPosition;
        this.scene = scene;
        this.badge = badge;
        this.instructionLabel = 'No especificado';
        this.optionsData = [];
        this.isDirty = false;
        this.disposeElements = [];
        this.addEvent = (delay, callback) => this.scene.time.addEvent({
            delay, callback
        })
        this.runEvent = (delay, callback) => () => this.addEvent(delay, callback);
        this.mcIsBoy = this.scene.game.ludo.character.sheets === BOY_SHEETS;
    }

    preload() {

    }

    reboot(onComplete) {
        onComplete();
    }

    create() {
        this.mapPoint = createMapPoint(this.scene, this.position).setDepth(this.scene.mc.depth - 1);
        return this;
    }

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    createOptionsSelector(width, height) {
        this.shuffle(this.optionsData);
        const op = new OptionSelector(this.scene, 0, height,
            this.instructionLabel, this.optionsData)
            .resize(width, height - this.scene.background.y);
        return op;
    }

    enable() {
        const enableMark = () => {
            this.mapPoint.setInteractive()
                .onceclick(() => this.init())
            this.scene.tweens.add({
                targets: this.mapPoint,
                alpha: 1
            })
        }
        this.isDirty ? this.reboot(enableMark) : enableMark();
        //TODO check this
        this.isDirty = false;
        return this;
    }

    disableMark() {
        this.mapPoint.disableInteractive();
    }

    speak(text) {
        // this.scene.game.voice.speak(text);
    }

    speakOptionsInstruction() {
        const labels = this.optionsData.map(item => item.label);
        let optionsText = '';
        labels.forEach(label => optionsText += label + ',,,     ');
        this.speak(`${this.instructionLabel}, ${optionsText}`);
    }

    showOptions() {
        const { width, height } = this.scene.game.canvas;
        const selector = this.createOptionsSelector(width, height);
        this.scene.tweens.add({
            targets: selector,
            ease: 'Power4',
            duration: 1000,
            onStart: () => {
                this.scene.add.existing(selector);
                this.hintPoint.disableInteractive();
            },
            y: this.scene.background.y,
            onComplete: () => {
                this.speakOptionsInstruction();
            }
        });
        this.scene.tweens.add({
            targets: this.hintPoint, alpha: 0,
            onComplete: () => this.hintPoint.destroy()
        })

    }

    showHint() {
        const { displayHeight } = this.scene.background;
        const cellSize = displayHeight / 7;
        this.hintPoint = createMapPoint(this.scene, this.position, Btns.HINT, 1)
            .setAlpha(0)
            .setDepth(9)
            .setPosition(this.mapPoint.x, this.mapPoint.y)
            .resize(cellSize, cellSize, 25, 1)
            .startTween()
        this.scene.tweens.add({
            targets: this.hintPoint,
            scale: { from: 0, to: 1 },
            duration: 500,
            ease: 'Power3',
            onStart: () => this.hintPoint.setAlpha(1).setInteractive()
                .onclick(() => this.showOptions())
        })
    }

    init() {
        const { x, y } = this.mcPosition;
        this.scene.situations.forEach(s => s.disableMark());
        this.scene.moveMcTo(x, y, () => {
            this.isDirty = true;
            this.start();
        })
    }

    start() {
        // addDialogTween(this.scene, "Aún en desarrollo", this.badge);
        this.showHint();
    }

    resize() {
        const { scaleX, scaleY, y, displayHeight } = this.scene.background;
        const cellSize = displayHeight / 7;
        this.mapPoint.relocate(scaleX, scaleY, y)
            .resize(cellSize, cellSize, 25, 1);
        return this;
    }
}

export default Situation;