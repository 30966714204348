import { BaldManSheets } from "../assetsPaths/situations/work/askingRestroom";
import { generateFrameNames, generateFrameNamesByArray } from "../functions";

export const BaldManAnims = {
    WALK_FRONT: {
        key: "baldmanWalkFront",
        frames: generateFrameNames(BaldManSheets.FRONT.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },

    WALK_BACK: {
        key: "baldmanWalkBack",
        frames: generateFrameNames(BaldManSheets.BACK.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    ASK: {
        key: "baldmanAsk",
        frames: generateFrameNamesByArray(BaldManSheets.ASK.key, [
            0, 1, 0, 1, 0, 1, 0, 1, 2
        ]),
        frameRate: 2,
    }
} 