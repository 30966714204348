import { generateFrameNames, generateFrameNamesByArray } from "../../../functions"

export const AskingRestroomSheets = {
    COOK: {
        key: "w3s4CookSheet",
        url: "SocialInclusionAssets/situations/askingRestroom/cooker.png",
        frameWidth: 177 / 3,
        frameHeight: 139 / 2
    },
    MAN_T_HELP: {
        key: "w3s4ManHelp",
        url: "SocialInclusionAssets/situations/askingRestroom/manTeeHelp.png",
        frameWidth: 328 / 4,
        frameHeight: 541 / 4
    },
    MAN_T_IGNORE: {
        key: "w3s4ManIgnore",
        url: "SocialInclusionAssets/situations/askingRestroom/manTeeIgnore.png",
        frameWidth: 180 / 3,
        frameHeight: 406 / 3
    },
    MAN_T_CALL: {
        key: "w3s4ManCallHelp",
        url: "SocialInclusionAssets/situations/askingRestroom/manTeeCall.png",
        frameWidth: 249 / 4,
        frameHeight: 408 / 3
    },
    WOMAN_T_HELP: {
        key: "w3s4woManHelp",
        url: "SocialInclusionAssets/situations/askingRestroom/womanTeeHelp.png",
        frameWidth: 320 / 4,
        frameHeight: 537 / 4
    },
    WOMAN_T_IGNORE: {
        key: "w3s4woManIgnore",
        url: "SocialInclusionAssets/situations/askingRestroom/womanTeeIgnore.png",
        frameWidth: 199 / 3,
        frameHeight: 399 / 3
    },
    WOMAN_T_CALL: {
        key: "w3s4woManCallHelp",
        url: "SocialInclusionAssets/situations/askingRestroom/womanTeeCall.png",
        frameWidth: 266 / 4,
        frameHeight: 402 / 3
    },

}

export const BaldManIdle = {
    FRONT: {
        key: "w3s4BaldGuyIdle",
        url: "SocialInclusionAssets/situations/askingRestroom/baldIdle.png",
    },
    BACK: {
        key: "w3s4BaldGuyIdleBack",
        url: "SocialInclusionAssets/situations/askingRestroom/baldIdleBack.png",
    }
}

export const FriendIdle = {
    FRONT: {
        key: "w3s4FriendIdle",
        url: "SocialInclusionAssets/situations/askingRestroom/friendIdle.png",
    },
    BACK: {
        key: "w3s4FriendIdleBack",
        url: "SocialInclusionAssets/situations/askingRestroom/friendIdleBack.png",
    }
}

export const BaldManSheets = {
    FRONT: {
        key: "w3s4BaldGuyWalk",
        url: "SocialInclusionAssets/situations/askingRestroom/baldWalkFront.png",
        frameWidth: 194 / 3,
        frameHeight: 276 / 2
    },
    BACK: {
        key: "w3s4BaldGuyWalkBack",
        url: "SocialInclusionAssets/situations/askingRestroom/baldWalkBack.png",
        frameWidth: 185 / 3,
        frameHeight: 268 / 2
    },
    ASK: {
        key: "w3s4BaldGuyAsk",
        url: "SocialInclusionAssets/situations/askingRestroom/baldmanAsk.png",
        frameWidth: 103 / 2,
        frameHeight: 272 / 2
    }
}

export const FriendSheets = {
    FRONT: {
        key: "w3s4FriendWalk",
        url: "SocialInclusionAssets/situations/askingRestroom/friendWalkFront.png",
        frameWidth: 202 / 3,
        frameHeight: 278 / 2
    },
    BACK: {
        key: "w3s4FriendWalkBack",
        url: "SocialInclusionAssets/situations/askingRestroom/friendWalkBack.png",
        frameWidth: 189 / 3,
        frameHeight: 270 / 2
    },
    HELP: {
        key: "w3s4FriendHelp",
        url: "SocialInclusionAssets/situations/askingRestroom/friendHelp.png",
        frameWidth: 103 / 2,
        frameHeight: 260 / 2
    },
}

export const AskingRestroomAnims = {
    COOK: {
        key: "w3s4Cook",
        frames: generateFrameNames(AskingRestroomSheets.COOK.key, 0, 3),
        frameRate: 1,
    },
    MAN_GRAB_TEE: {
        key: "w3s4manGrabTee",
        frames: generateFrameNames(AskingRestroomSheets.MAN_T_CALL.key, 1, 3),
        frameRate: 2,
    },
    MAN_TURN_BACK: {
        key: "w3s4manTurnBack",
        frames: generateFrameNames(AskingRestroomSheets.MAN_T_CALL.key, 5, 6),
        frameRate: 2,
    },
    MAN_IGNORE: {
        key: "w3s4manIgnoreperson",
        frames: generateFrameNames(AskingRestroomSheets.MAN_T_IGNORE.key, 7, 8),
        frameRate: 0.5,
    },
    MAN_HELP: {
        key: "w3s4manHelp",
        frames: generateFrameNames(AskingRestroomSheets.MAN_T_HELP.key, 8, 12),
        frameRate: 2,
    },
    MAN_CALL: {
        key: "w3s4manCallFriend",
        frames: generateFrameNamesByArray(AskingRestroomSheets.MAN_T_CALL.key, [
            7, 8, 9, 8, 9, 8, 9, 8, 9, 10
        ]),
        frameRate: 2,
    },
    WOMAN_GRAB_TEE: {
        key: "w3s4womanGrabTee",
        frames: generateFrameNames(AskingRestroomSheets.WOMAN_T_CALL.key, 1, 3),
        frameRate: 2,
    },
    WOMAN_TURN_BACK: {
        key: "w3s4WOmanTurnback",
        frames: generateFrameNames(AskingRestroomSheets.WOMAN_T_CALL.key, 5, 6),
        frameRate: 2,
    },
    WOMAN_IGNORE: {
        key: "w3s4womanIgnoreperson",
        frames: generateFrameNames(AskingRestroomSheets.WOMAN_T_IGNORE.key, 7, 8),
        frameRate: 0.5,
    },
    WOMAN_HELP: {
        key: "w3s4womanHelp",
        frames: generateFrameNames(AskingRestroomSheets.WOMAN_T_HELP.key, 8, 12),
        frameRate: 2,
    },
    WOMAN_CALL: {
        key: "w3s4womanCallFriend",
        frames: generateFrameNamesByArray(AskingRestroomSheets.WOMAN_T_CALL.key, [
            7, 8, 9, 8, 9, 8, 9, 8, 9, 10
        ]),
        frameRate: 2,
    },
}

export const AskingRestroomAssets = {
    Images: [
        ...Object.values(BaldManIdle),
        ...Object.values(FriendIdle)
    ],
    SpriteSheets: [
        ...Object.values(AskingRestroomSheets),
        ...Object.values(BaldManSheets),
        ...Object.values(FriendSheets)
    ]
}