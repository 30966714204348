import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { faces, hintAssets } from "../utils/assets";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import {
    getDifferentRandomItem,
    getRandomElement,
    getRandomSubArray
} from "../../HabCogGame/utils/functions";
import { DifficultySettingKey, ColorScaleSettingKey } from '../utils/settings';
import { ColorScalePipelineKey } from "../../HabCogGame/utils/constants";

const { GOOD_TARGET } = TargetFeedback;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback, color } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.sTextures = faces;
        this.expressions = getExpressions(difficulty);
        this.expressionIndex = 1;
        this.createImgsList(quantity, size, this.sTextures[0]['HAPPY']);
        this.setColorScale(color);
        this.setFailFn(feedback);
        this.startGameplay();
    }

    getCurrentExpression() {
        return this.expressions[this.expressionIndex];
    }

    getNextExpression() {
        const nextIndex = this.expressionIndex + 1;
        const isLastTexture = nextIndex === this.expressions.length
        this.expressionIndex = isLastTexture ? 0 : nextIndex;
        return this.expressions[this.expressionIndex];
    }

    setColorScale(color) {
        if (color === ColorScaleSettingKey.GRAY) {
            const key = ColorScalePipelineKey.GRAY
            this.targets.forEach(img => img.setPipeline(key));
        }
    }

    setErrorTargetsTexture(face, currentExpression) {
        const errorFaces = [];
        this.expressions.forEach(expression => {
            if (expression !== currentExpression)
                errorFaces.push(expression)
        })
        this.targets.forEach(img => {
            const expression = getRandomElement(errorFaces);
            const isFlipped = getRandomElement([true, false])
            img.setTexture(face[expression].key)
                .setFlipX(isFlipped);
        })
    }

    setTargetsTexture(face) {
        const currentExpresion = this.getNextExpression();
        const length = this.targets.length;
        const targetLength = Math.floor(length / 2);
        this.pattern.setTexture(hintAssets[currentExpresion].key)
        this.setErrorTargetsTexture(face, currentExpresion);
        const targetTexture = face[currentExpresion];
        const hitTargets = getRandomSubArray(this.targets, targetLength)
            .map(img => img.setTexture(targetTexture.key)
                .setFlipX(getRandomElement([true, false]))
            );
        return hitTargets
    }

    setTargetsInteractive(hitTargets) {
        const length = hitTargets.length;
        this.targets.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTargets.forEach(img => img
            .off('pointerdown')
            .once('pointerdown', () => this.addHit(img, length)));
    }

    addHit(img, length) {
        super.addHit();
        this.targetsHitted++;
        this.createFeedbackImage(img, GOOD_TARGET);
        if (this.targetsHitted === length)
            this.restartGameplay();
    }

    restartGameplay() {
        this.targets.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }

    speakOrder() {
        let instruction = 'Toca a todas las personas ';
        switch (this.getCurrentExpression()) {
            case 'HAPPY':
                instruction += 'felices';
                break;
            case 'SAD':
                instruction += 'tristes';
                break;
            case 'ANGRY':
                instruction += 'enojadas';
                break;
            case 'SCARED':
                instruction += 'asustadas';
                break;
            default:
                instruction = 'No c bro disculpa';
        }
        this.hub.updateText(instruction);
        this.speak(instruction);
    }

    startGameplay(lastFace) {
        this.feedbackImgs = [];
        this.targetsHitted = 0;
        const currentFace = getDifferentRandomItem(this.sTextures, lastFace);
        const hitTargets = this.setTargetsTexture(currentFace);
        this.setTargetsInteractive(hitTargets);
        if (localStorage.getItem('voiceSound') >= 0) {
            this.speakOrder()
        }
    }
}

const getExpressions = (difficulty) => {
    switch (difficulty) {
        case DifficultySettingKey.HAPPY_SAD:
            return ['HAPPY', 'SAD'];
        case DifficultySettingKey.HAPPY_SAD_ANGRY:
            return ['HAPPY', 'SAD', 'ANGRY'];
        default:
            return ['HAPPY', 'SAD', 'ANGRY', 'SCARED'];
    }
}
