import PhotoAssets from '../../HabCogGame/utils/assetsPaths/photos/assets';
import { TargetFeedbackAssets } from '../../HabCogGame/utils/assetsPaths/targets';
import { DifficultySettingAssets } from './settings';
import { SizeSettingAssets } from '../../HabCogGame/utils/settingsConstants/sizeSetting';
import { QuantitySettingAssets } from '../../HabCogGame/utils/settingsConstants/quantitySetting';
import { FeedbackSettingAssets } from '../../HabCogGame/utils/settingsConstants/feedbackSetting';
import  { TimeSettingAssets } from '../../HabCogGame/utils/settingsConstants/timeSetting';

export const hintAssets = {
    ANIMAL: {
        key: 'animalHint',
        url: './ClasifyGameAssets/hint/animalHint.png'
    },
    PERSON: {
        key: 'personHint',
        url: './ClasifyGameAssets/hint/personHint.png'
    },
    FOOD: {
        key: 'foodHint',
        url: './ClasifyGameAssets/hint/fruitHint.png'
    },
    CLOTH: {
        key: 'clothHint',
        url: './ClasifyGameAssets/hint/clothHint.png'
    },
    VEHICLE: {
        key: 'vehicleHint',
        url: './ClasifyGameAssets/hint/vehicleHint.png'
    }
};

const HintAssets = Object.values(hintAssets);

export const clasifyAssets = [
    ...PhotoAssets,
    ...HintAssets,
    ...TargetFeedbackAssets,
    ...DifficultySettingAssets,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...FeedbackSettingAssets,
    ...TimeSettingAssets
];
