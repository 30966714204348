import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { difficultySetting, DifficultySettingsAssets } from "./utils/settings";
import standbySetting, { StandBySettingAssets } from "../HabCogGame/utils/settingsConstants/standbySetting";
import SessionScene from "./scenes/sessionScene";
import { QuantitySettingAssets } from '../HabCogGame/utils/settingsConstants/quantitySetting';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const quantitySetting = buildQuantitySetting([2, 3, 4, 5, 6, 9]);

const data = {
    name: 'Recuerdo y ubico',
    sessionAssets: [
        ...DifficultySettingsAssets,
        ...SizeSettingAssets,
        ...QuantitySettingAssets,
        ...StandBySettingAssets,
        ...TimeSettingAssets
    ],
    sessionScene: SessionScene,
    logoUrl: './RememberAndLocateGameAssets/logo.png',
    iconUrl: './RememberAndLocateGameAssets/icon.png',
    thumbnail: './RememberAndLocateGameAssets/thumbnail.png',
    getHint: () => 'Mira la figura con color y cuando desaparezca toca donde estaba iluminado.',
    userManual: 'Durante un breve lapso se presenta un estímulo iluminado, a ' +
        'continuación aparece el estímulo sin color. El usuario debe hacer clic ' +
        '(tocar) en la ubicación donde se presentó el estímulo iluminado. ',
    settings: [
        difficultySetting,
        sizeSetting,
        quantitySetting,
        standbySetting,
        timeSetting
    ]
};

export default function RememberAndLocateGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
