import { FriendSheets } from "../assetsPaths/situations/work/askingRestroom";
import { generateFrameNames } from "../functions";

export const FriendAnims = {
    HELP: {
        key: "FRIENDHELPPeolple",
        frames: generateFrameNames(FriendSheets.HELP.key, 0, 3),
        frameRate: 2,
    },
    WALK_FRONT: {
        key: "friendWalkFront",
        frames: generateFrameNames(FriendSheets.FRONT.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    WALK_BACK: {
        key: "friendWalkBack",
        frames: generateFrameNames(FriendSheets.BACK.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }

}