import { SettingKey } from "../../HabCogGame/utils/constants";
import { colorSetting, ColorSettingAssets } from "../../HabCogGame/utils/settingsConstants/colorSetting";
import { shapeSetting, ShapeSettingAssets } from "../../HabCogGame/utils/settingsConstants/shapeSetting";
import { attachChildSettings } from "../../HabCogGame/utils/settingsConstants/functions";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    SHAPE_SHIFT: 0,
    COLOR_SHIFT: 1,
    DOUBLE_SHIFT: 2
}

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            key: DifficultySettingKey.SHAPE_SHIFT,
            title: 'Formas',
            header: 'Cambio de formas',
            description: 'Los estímulos serán de formas diferentes, pero de igual color.',
            image: {
                key: 'shapeShift',
                url: './HabCogGameAssets/settings/difficulty/shapeShift.png'
            },
        }, {
            key: DifficultySettingKey.COLOR_SHIFT,
            title: 'Color',
            header: 'Cambio de colores',
            description: 'Los estímulos serán de formas iguales, pero de diferentes colores.',
            image: {
                key: 'colorShift',
                url: './HabCogGameAssets/settings/difficulty/colorShift.png'
            },
        }, {
            key: DifficultySettingKey.DOUBLE_SHIFT,
            title: 'Forma y Color',
            header: 'Cambio de formas y colores',
            description: 'Los estímulos serán de formas y colores diferentes.',
            image: {
                key: 'shapeNcolorShift',
                url: './HabCogGameAssets/settings/difficulty/randomShift.png'
            },
        }
    ]
};

const naSetting = {
    name: 'No aplica',
    key: 'dummy',
    icon: {},
    tileIcon: TileIcons.NA,
    options: [
        {
            key: 'noop',
            title: 'Dificultad fija',
            header: 'Dificultad fija',
            description: 'La dificultad seleccionada no permite configurar figuras o colores específicos.',
            image: {
                key: 'randomShiftImage',
                url: './HabCogGameAssets/settings/shape/starShape.png',
            }
        }
    ]
};

export const getHuntingFiguresDifficultySetting = () =>
    attachChildSettings(difficultySetting, [colorSetting, shapeSetting, naSetting]);

export const HuntingFiguresSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...naSetting.options.map(({ image }) => image),
    ...ColorSettingAssets,
    ...ShapeSettingAssets
]