const FruitsPhotos = [
    {
        key: 'fruitPhoto1',
        url: './photos/fruits/fruit1.png'
    }, {
        key: 'fruitPhoto2',
        url: './photos/fruits/fruit2.png'
    }, {
        key: 'fruitPhoto3',
        url: './photos/fruits/fruit3.png'
    }, {
        key: 'fruitPhoto4',
        url: './photos/fruits/fruit4.png'
    }, {
        key: 'fruitPhoto5',
        url: './photos/fruits/fruit5.png'
    }
]

export default FruitsPhotos;