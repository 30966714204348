import React, { Component } from "react";
import Phaser from "phaser";
import BootScene from "./scenes/bootScene";
import MainMenuScene from "./scenes/mainMenuScene";
import SplashScene from "./scenes/splashScene";
import CharacterSelectorScene from "./scenes/characterSelectorScene";
import BaseGame from './utils/classes/baseGame';
import WorldSelectorScene from "./scenes/worldSelectorScene";
import PhaserNavMeshPlugin from 'phaser-navmesh';
import HomeWorld from "./scenes/homeWorldScene";
import InstructionsScene from "./scenes/instructionsScene";
import CityWorldScene from "./scenes/cityWorldScene";
import BadgeScene from "./scenes/badgeScene";
import ModalScene from "./scenes/modalScene";
import WorkWorldScene from "./scenes/workWorldScene";
import { SceneKey } from "./utils/constants";

export class SocialInclusion extends Component {


  componentDidMount() {
    const sessionData = this.getSessionData();
    this.game = new BaseGame({ ...LudomundoGameConfig, sessionData });
    this.resizeGame = resizeGame(this.game);
    window.addEventListener('resize', this.resizeGame);
    if (localStorage.getItem('voiceSound') === null) {
      localStorage.setItem('voiceSound', 1)
    }
  }

  getSessionData() {
    const item = "interventionPlan";
    const interventionPlan = JSON.parse(localStorage.getItem(item)) ? JSON.parse(localStorage.getItem(item)) : {}
    return {
      name: `${interventionPlan.first_name || 'Usuario'} ${interventionPlan.last_name || 'Invitado'}`,
      time: interventionPlan.time,
      piid: interventionPlan.code || "N/A",
      postId: interventionPlan.interventionPlanId
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const width = `${document.documentElement.clientWidth}px`;
    const height = `${document.documentElement.clientHeight}px`;
    return <div id="phaser-game"
      style={{ width, height, backgroundColor: "black" }} />;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeGame);
    this.game.voice.cancel();
    this.game.plugins.removeScenePlugin("PhaserNavMeshPlugin")
    this.game.plugins.destroy()
    this.game.scene.getScene(SceneKey.BOOT).navMeshPlugin.destroy = () => { };
  }
}

const resizeGame = (game) => () => {
  const DPR = window.devicePixelRatio;
  const container = document.getElementById('phaser-game');
  const { clientWidth, clientHeight } = document.documentElement;
  container.style.width = `${window.innerWidth}px`;
  container.style.height = `${window.innerHeight}px`;
  game.scale.resize(clientWidth * DPR, clientHeight * DPR);
}

const DPR = window.devicePixelRatio
const LudomundoGameConfig = {
  plugins: {
    scene: [
      {
        key: "PhaserNavMeshPlugin",
        plugin: PhaserNavMeshPlugin,
        mapping: "navMeshPlugin",
        start: true
      }
    ]
  },
  type: Phaser.WEBGL,
  backgroundColor: '#000000',
  scene: [BootScene,
    SplashScene,
    MainMenuScene,
    BadgeScene,
    CharacterSelectorScene,
    InstructionsScene,
    WorldSelectorScene,
    CityWorldScene,
    HomeWorld,
    WorkWorldScene,
    ModalScene
  ],
  scale: {
    parent: 'phaser-game',
    mode: Phaser.Scale.NONE,
    height: document.documentElement.clientHeight * DPR,
    width: document.documentElement.clientWidth * DPR,
    zoom: 1 / DPR
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: 0
    }
  },
  dom: {
    createContainer: true
  }
};

export default function SocialInclusionGame(props) {
  const params = props.location.params;
  const interventionPlan = params ? props.location.params.interventionPlan : {};
  return <SocialInclusion interventionPlan={interventionPlan} />

}
