import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting"
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting"
import { FeedbackSettingAssets } from "../../HabCogGame/utils/settingsConstants/feedbackSetting"
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets"
import { DifficultySettingAssets } from "./settings"
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting"

export const SomethingInCommonAssets = {
    DOG: [
        {
            key: 'dogPhoto1',
            url: './SomethingInCommonGameAssets/photos/dog/dog1.png'
        },
        {
            key: 'dogPhoto2',
            url: './SomethingInCommonGameAssets/photos/dog/dog2.png'
        },
        {
            key: 'dogPhoto3',
            url: './SomethingInCommonGameAssets/photos/dog/dog3.png'
        },
        {
            key: 'dogPhoto4',
            url: './SomethingInCommonGameAssets/photos/dog/dog4.png'
        },
        {
            key: 'dogPhoto5',
            url: './SomethingInCommonGameAssets/photos/dog/dog5.png'
        },
        {
            key: 'dogPhoto6',
            url: './SomethingInCommonGameAssets/photos/dog/dog6.png'
        },
        {
            key: 'dogPhoto7',
            url: './SomethingInCommonGameAssets/photos/dog/dog7.png'
        },
        {
            key: 'dogPhoto8',
            url: './SomethingInCommonGameAssets/photos/dog/dog8.png'
        },
        {
            key: 'dogPhoto9',
            url: './SomethingInCommonGameAssets/photos/dog/dog9.png'
        },
        {
            key: 'dogPhoto10',
            url: './SomethingInCommonGameAssets/photos/dog/dog10.png'
        }
    ],
    CAT: [
        {
            key: 'catPhoto1',
            url: './SomethingInCommonGameAssets/photos/cat/cat1.png'
        },
        {
            key: 'catPhoto2',
            url: './SomethingInCommonGameAssets/photos/cat/cat2.png'
        },
        {
            key: 'catPhoto3',
            url: './SomethingInCommonGameAssets/photos/cat/cat3.png'
        },
        {
            key: 'catPhoto4',
            url: './SomethingInCommonGameAssets/photos/cat/cat4.png'
        },
        {
            key: 'catPhoto5',
            url: './SomethingInCommonGameAssets/photos/cat/cat5.png'
        },
        {
            key: 'catPhoto6',
            url: './SomethingInCommonGameAssets/photos/cat/cat6.png'
        },
        {
            key: 'catPhoto7',
            url: './SomethingInCommonGameAssets/photos/cat/cat7.png'
        },
        {
            key: 'catPhoto8',
            url: './SomethingInCommonGameAssets/photos/cat/cat8.png'
        },
        {
            key: 'catPhoto9',
            url: './SomethingInCommonGameAssets/photos/cat/cat9.png'
        },
        {
            key: 'catPhoto10',
            url: './SomethingInCommonGameAssets/photos/cat/cat10.png'
        }
    ],
    POLICE:[
        {
            key: 'policePhoto1',
            url: './SomethingInCommonGameAssets/photos/police/police1.png'
        },
        {
            key: 'policePhoto2',
            url: './SomethingInCommonGameAssets/photos/police/police2.png'
        },
        {
            key: 'policePhoto3',
            url: './SomethingInCommonGameAssets/photos/police/police3.png'
        },
        {
            key: 'policePhoto4',
            url: './SomethingInCommonGameAssets/photos/police/police4.png'
        },
        {
            key: 'policePhoto5',
            url: './SomethingInCommonGameAssets/photos/police/police5.png'
        },
        {
            key: 'policePhoto6',
            url: './SomethingInCommonGameAssets/photos/police/police6.png'
        },
        {
            key: 'policePhoto7',
            url: './SomethingInCommonGameAssets/photos/police/police7.png'
        },
        {
            key: 'policePhoto8',
            url: './SomethingInCommonGameAssets/photos/police/police8.png'
        },
        {
            key: 'policePhoto9',
            url: './SomethingInCommonGameAssets/photos/police/police9.png'
        },
        {
            key: 'policePhoto10',
            url: './SomethingInCommonGameAssets/photos/police/police10.png'
        }
    ],
    NURSE:[
        {
            key: 'nursePhoto1',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse1.png'
        },
        {
            key: 'nursePhoto2',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse2.png'
        },
        {
            key: 'nursePhoto3',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse3.png'
        },
        {
            key: 'nursePhoto4',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse4.png'
        },
        {
            key: 'nursePhoto5',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse5.png'
        },
        {
            key: 'nursePhoto6',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse6.png'
        },
        {
            key: 'nursePhoto7',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse7.png'
        },
        {
            key: 'nursePhoto8',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse8.png'
        },
        {
            key: 'nursePhoto9',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse9.png'
        },
        {
            key: 'nursePhoto10',
            url: './SomethingInCommonGameAssets/photos/nurse/nurse10.png'
        }
    ]
};

export const SomethingInCommonSessionAssets = [
    ...SomethingInCommonAssets.CAT,
    ...SomethingInCommonAssets.DOG,
    ...SomethingInCommonAssets.NURSE,
    ...SomethingInCommonAssets.POLICE,
    ...QuantitySettingAssets,
    ...SizeSettingAssets,
    ...FeedbackSettingAssets,
    ...TargetFeedbackAssets,
    ...DifficultySettingAssets,
    ...TimeSettingAssets
];
