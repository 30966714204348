import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import {
    createZoomCamera,
    createBackgroundImage,
    createAccesibilityBtns,
    createBtn,
    createHeaderBar,
    createUserTag,
    createMessageBox,
    createPreviewBox,
    createSettingTile,
    setZoomBtnOnclick
} from "../utils/gameObjectFactory";
import { Icons } from '../utils/assetsPaths/icons';
import { Btns } from '../utils/assetsPaths/btn';
import { wrapResizeFn } from '../utils/resize';

export default class SettingsScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.SETTINGS_MENU })
    }

    create() {
        this.userConfig = this.game.userConfig;
        this.selectedTile = undefined;
        let { sessionData, name } = this.game.data;
        createBackgroundImage(this);
        createZoomCamera(this);
        createMessageBox(this, 'Configura el juego');
        createAccesibilityBtns(this);
        createHeaderBar(this, `Ajustes de ${name}`);
        createUserTag(this, sessionData);
        createPreviewBox(this);
        this.createBtns();
        this.createTiles();
        this.speakAtStart();
        wrapResizeFn(this);
        this.setSceneInteractive();
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voiceAssistant.speak(this.helpText);
    }

    stopVoice() {
        this.game.voiceAssistant.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }

    createBtns() {
        const body = Btns.SECONDARY;
        this.continueBtn = createBtn(this, 'Continuar', Icons.CONTINUE, body);
        this.defaultBtn = createBtn(this, 'Por defecto', Icons.DEFAULT, body);
        this.btns = [this.defaultBtn, this.continueBtn];
    }

    createTiles() {
        const { userConfig } = this.game;
        const { settings } = this.game.data;
        this.tiles = [];
        settings.forEach(setting => {
            const { hasChild, options, key } = setting;
            const index = userConfig[key] || 0;
            let container;
            if (hasChild) {
                const childData = options.map(({ child }) => child);
                container = this.createTilesContainer(childData, index)
            }
            const tile = this.createInteractiveTile(setting, container);
            this.tiles.push(tile);
            if (container)
                this.tiles.push(container);
        });
        this.updatePreviewBoxContent(settings[0], this.tiles[0]);
    }

    createInteractiveTile(setting, container) {
        const selectedIndex = this.game.userConfig[setting.key] || 0;
        const tile = createSettingTile(this, setting, selectedIndex);
        this.setTileInteractive(setting, tile, container);
        this.setTileOnOptionChange(setting, tile, container);
        return tile;
    }

    createTilesContainer(settings, selectedIndex) {
        const container = this.add.container(0, 0);
        const tiles = settings.map(st => this.createInteractiveTile(st));
        container.add(tiles);
        container.getAll().forEach(tile => tile.setVisible(false));
        container.getAt(selectedIndex).setVisible(true);
        container.resize = function (...args) {
            this.getAll().forEach(child => child.resize(...args))
        };
        container.restoreDefault = function () {
            this.getAll().forEach(child =>
                child.setVisible(false).restoreDefault())
            this.getAt(0).setVisible(true);
        };
        return container;
    }

    setTileInteractive(setting, tile) {
        tile.setInteractive(() => {
            if (this.selectedTile && this.selectedTile !== tile)
                this.selectedTile.focusOff();
            this.selectedTile = tile;
            this.updatePreviewBoxContent(setting, tile);
        })
    }

    setTileOnOptionChange(setting, tile, container) {
        tile.onOptionChange(() => {
            if (this.selectedTile === tile) {
                this.userConfig[setting.key] = tile.selectedIndex;
                this.game.gameConfig[setting.key] = setting.options[tile.selectedIndex].key;
                if (container) {
                    container.getAll().forEach(child => child.setVisible(false));
                    container.getAt(tile.selectedIndex).setVisible(true);
                }
            }
            this.updatePreviewBoxContent(setting, tile);
        });
    }

    updatePreviewBoxContent({ name, icon, options }, tile) {
        const { header, description, image } = options[tile.getSelectedIndex()];
        this.previewBox.updateContent(name, icon, header, description, image);
    }

    setSceneInteractive() {
        this.btns.forEach(btn => btn.setInteractive());
        this.continueBtn.onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.defaultBtn.onclick(() => {
            this.tiles.forEach(tile => tile.restoreDefault());
            this.selectedTile && this.selectedTile.updateArrowVisible();
            Object.keys(this.game.userConfig).forEach(key => this.game.userConfig[key] = 0);
        });
        this.voiceOverBtn.onclick(() => this.game.voiceAssistant.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
        this.events.once('shutdown', () => this.game.voiceAssistant.cancel());
    }

    resizeLandscape(width, height) {
        const { background, btns, header, userTag, accesibilityBtns, msg } = this;
        const headerHeight = height / 7;
        const eightWidth = width / 8;
        const eightHeight = height / 8;
        const quarterWidth = width / 4;
        background.setDisplaySize(width, height);
        btns.forEach(btn => btn.resize(quarterWidth, headerHeight, 100, 1));
        accesibilityBtns.forEach(btn => btn.resize(eightWidth, eightHeight, 50, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 100, 1);
        msg.resize(width / 2, headerHeight, 75, 1);
        this.setLandscapePositions(width, height, headerHeight);
    }

    setLandscapePositions(width, height, headerHeight) {
        const { btns, accesibilityBtns, userTag, header, previewBox, msg, tiles } = this;
        const position = Phaser.Display.Align.CENTER;
        const quarterWidth = width / 4
        let cellWidth = btns[0].background.displayWidth * 1.2;
        userTag.setPosition(width - userTag.background.displayWidth / 2 - headerHeight / 4, headerHeight / 2);
        header.alignLabelRightPosition(width, userTag.getLeftCenter().x);
        msg.setPosition(quarterWidth, headerHeight + msg.background.displayHeight * 0.75);

        Phaser.Actions.GridAlign(btns, {
            width: -1, cellWidth, position,
            x: quarterWidth - cellWidth / 2,
            y: height - btns[0].background.displayHeight,

        });

        cellWidth = accesibilityBtns[0].background.displayWidth * 1.25;
        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        });

        previewBox.resize(width / 2, accesibilityBtns[0].getTopCenter().y - header.background.height, 100, 1);
        previewBox.setPosition(width * 3 / 4, (accesibilityBtns[0].getTopCenter().y + header.background.height) / 2);

        const padding = 10 * window.devicePixelRatio;
        const tileWidth = width / 2.75;
        const tileHeight = (btns[0].getTopCenter().y - msg.getBottomCenter().y - 2 * padding) / (tiles.length * 1.25);
        tiles.forEach(tile => tile.resize(tileWidth, tileHeight, 0, 1));
        const cellHeight = tiles[0].background.displayHeight * 1.25;

        Phaser.Actions.GridAlign(tiles, {
            height: -1, position, cellHeight,
            x: quarterWidth,
            y: msg.getBottomCenter().y + padding + cellHeight / 2,
        })
    }

    resizePortrait(width, height) {
        const { background, btns, accesibilityBtns, header, msg, tiles } = this;
        const headerHeight = height / 10;
        const halfWidth = width / 2;
        background.setDisplaySize(100, height).setScale(background.scaleY, background.scaleY);
        header.resize(width, headerHeight);
        btns.forEach(btn => btn.resize(width, headerHeight, 100, 1));
        accesibilityBtns.forEach(btn => btn.resize(halfWidth, headerHeight, 100, 1));
        msg.resize(width, tiles[0].background.displayHeight, 0, 1);
        this.setPortraitPositions(width, height, headerHeight);
    }

    setPortraitPositions(width, height, headerHeight) {
        const { btns, accesibilityBtns, tiles, msg, header, userTag, previewBox } = this;
        const halfWidth = width / 2;
        const position = Phaser.Display.Align.CENTER;
        let cellHeight = btns[0].background.displayHeight * 1.2;

        Phaser.Actions.GridAlign(btns, {
            height: -1, cellHeight, position,
            x: halfWidth,
            y: height - btns.length * cellHeight
        });

        const tileWidth = width / 1.5;
        const tileHeight = (btns[0].getTopCenter().y - headerHeight) / (2 * (tiles.length) * 1.25);
        tiles.forEach(tile => tile.resize(tileWidth, tileHeight, 25, 1));
        cellHeight = tiles[0].background.displayHeight * 1.2;

        Phaser.Actions.GridAlign(tiles, {
            height: -1, position, cellHeight,
            x: halfWidth,
            y: btns[0].getTopCenter().y - cellHeight * tiles.length
        })

        msg.setPosition(halfWidth, tiles[0].y - tiles[0].background.displayHeight * 1.25 / 2 - msg.background.displayHeight * 0.6);
        const { displayWidth } = accesibilityBtns[0].background;
        const cellWidth = displayWidth * 1.5;

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            x: halfWidth - cellWidth / 2,
            y: msg.getTopCenter().y - accesibilityBtns[0].background.displayHeight * 0.7
        });

        userTag.setPosition(-2000, -2000);
        const { y } = accesibilityBtns[0].getTopCenter();
        previewBox.resize(width, y - headerHeight, 100, 1);
        previewBox.setPosition(halfWidth, (y + headerHeight) / 2);
        header.alignLabelLeftPosition(width);
    }
}

const SceneInstruction = 'Estás en el menú de ajustes, aquí puedes configurar diferentes aspectos del juego como ' +
    'dificultad, tamaño, número de estímulos y el tiempo de sesión del juego. Presiona el botón, valores ' +
    'por defecto, para que cada ajuste regrese a su valor por inicial. \n' +
    'Al presionar el botón regresar, regresarás al menú principal'
