export const LudoAsset = {
    key: 'ludoAsset',
    url: './HabCogGameAssets/splash/ludo.png'
};

export const SceneBackground = {
    key: 'sceneBackground',
    url: './HabCogGameAssets/splash/sceneBackground.png'
};

export const LoadBtnBackground = {
    key: 'loadBtnBackground',
    url: './HabCogGameAssets/splash/loadBtnBackground.png'
}

export const LoadBtnProgress = {
    key: 'loadBtnProgress',
    url: './HabCogGameAssets/splash/loadBtnProgress.png'
}

export const SplashAssets = [LudoAsset, SceneBackground, LoadBtnBackground, LoadBtnProgress];