
import React from "react";
import "./App.css";
import Routes from './Routes'



export default function App() {
  return (
    <Routes />
  );
}

