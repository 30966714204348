import Phaser from "phaser";
import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { PassingBallSheets, TrafficLight, PurpleCar } from "../../utils/assetsPaths/situations/city/passingBall";
import { generateFrameNames, addDialogTween } from "../../utils/functions";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";

const Vector2 = Phaser.Math.Vector2;

class PassingBallSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1136, y: 974 };
        const mcPosition = { x: 1807, y: 789 };
        super(scene, markPosition, mcPosition, Badges.TRAFFIC_LIGHTS);
        this.instructionLabel = 'Te piden pasar la pelota al otro lado de la calle, ¿qué haces?';
        this.optionsData = [{
            label: 'Cruzo la calle sin mirar el semáforo para entregarles la pelota.',
            onclick: () => this.carelessAction()
        }, {
            label: 'Espero que\nel semáforo esté en rojo y los vehículos se hayan detenido para cruzar.',
            onclick: () => this.carefullAction()
        }, {
            label: 'Solicito a alguien que esté cerca que cruce la calle y pase la pelota.',
            onclick: () => this.askHelpAction()
        }
        ]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(TennisAnims).forEach(a => anims.create(a));
        Object.values(PersonAnims).forEach(a => anims.create(a));
    }

    create() {
        super.create();
        this.tennis = createTennisSprite(this.scene);
        this.auxPerson = createRandPerson(this.scene);
        this.scene.events.on('update', this.auxPerson.move)
            .once('shutdown', () =>
                this.scene.events.off('update', this.auxPerson.move))
        this.trafficLight = createTrafficLight(this.scene);
        this.scene.events.on('update', this.trafficLight.update)
            .once('shutdown', () =>
                this.scene.events.off('update', this.trafficLight.update))
        return this;
    }

    reboot(onComplete) {
        this.tennis.setTexture(PassingBallSheets.Tennis.key, 0);
        const x = this.auxPerson.follower.x;
        this.auxPerson.sprite.setTexture(PassingBallSheets.PERSON_DOWN.key, 0);
        if (x !== 1886) {
            this.auxPerson.sprite.setFlipX(false);
            this.auxPerson.follower.setPosition(1886, 956);
        }
        onComplete();

    }

    addVehicle() {
        const addDelayedDialogTween = () => this.scene.time.addEvent({
            delay: 2500,
            callback: () => {
                addDialogTween(this.scene, "Casi te atropella un auto")
            }

        })
        const { TWIST } = this.scene.game.ludo.character.anims;
        this.fireman = this.fireman = createFireman(this.scene);
        const destroyFireman = () => {
            this.scene.events.off('update', this.fireman.move);
            this.fireman.follower.destroy();
            this.fireman.sprite.destroy();
        }
        this.fireman.sprite.setAlpha(1);
        this.scene.events.on('update', this.fireman.move);
        this.fireman.follower.goTo(new Vector2(1530, 988))
            .onceMovementComplete(() => {
                this.scene.mc.play(TWIST.key).once('animationcomplete', () => {
                    this.scene.disablePlayerAnim();
                    this.scene.enablePlayerAnim();
                    this.scene.follower.emit('onMovementComplete');
                    this.scene.mc.setFlipX(true);
                    addDelayedDialogTween();
                })
                this.fireman.follower.goTo(new Vector2(1186, 777))
                    .onceMovementComplete(() => {
                        this.fireman.sprite.setAngle(-21);
                        this.fireman.follower.goTo(new Vector2(866, 675))
                            .onceMovementComplete(() => {
                                this.fireman.sprite.setAngle(-36);
                                this.fireman.follower.goTo(new Vector2(15, 671))
                                    .onceMovementComplete(() => {
                                        this.scene.tweens.add({
                                            targets: this.fireman.sprite,
                                            alpha: 0,
                                            onComplete: destroyFireman
                                        })
                                    })
                            })
                    })
            })
    }

    carelessAction() {
        const { TWIST } = this.scene.game.ludo.character.sheets;
        const { GRAB_BALL } = this.scene.game.ludo.character.anims;
        const delayCallback = () => this.scene.time.addEvent({
            delay: 2500,
            callback: () => this.scene.moveMcTo(1629, 958, () => '')
        })
        this.ball.destroy();
        this.scene.mc.toggleFlipX().setTexture(TWIST.key, 0);
        this.scene.time.addEvent({
            delay: 2500,
            callback: () =>
                this.scene.mc.play(GRAB_BALL.key)
                    .once('animationcomplete', () => {
                        delayCallback();
                        this.addVehicle()
                    })
        })
    }

    carefullAction() {
        this.ball.destroy();
        const { GRAB_BALL } = this.scene.game.ludo.character.anims;
        const { TWIST, HOLDING_BALL_DOWN } = this.scene.game.ludo.character.sheets;
        const delayCallback = (delay, callback) => this.scene.time.addEvent({
            delay,
            callback
        });
        delayCallback(2500, () => {
            this.scene.mc.play(GRAB_BALL.key)
                .once('animationcomplete', () => this.scene.moveMcTo(1629, 958, () => {
                    this.scene.mc.setTexture(HOLDING_BALL_DOWN.key, 0).setFlipX(false);
                    delayCallback(1500, () => this.trafficLight.sprite.setTexture(TrafficLight.Yellow.key));
                    delayCallback(3000, () => this.trafficLight.sprite.setTexture(TrafficLight.Red.key))
                    delayCallback(4500, () => this.deliverBall())
                }))
        })
        this.scene.mc.toggleFlipX().setTexture(TWIST.key, 0);
    }

    askHelpAction() {
        const { GRAB_BALL } = this.scene.game.ludo.character.anims;
        const { HOLDING_BALL_DOWN } = this.scene.game.ludo.character.sheets;
        const delayCallback = (delay, callback) => this.scene.time.addEvent({
            delay,
            callback
        });
        this.ball.destroy();
        this.scene.mc.toggleFlipX().play(GRAB_BALL.key)
            .once('animationcomplete', () => {
                delayCallback(1000, () => this.scene.moveMcTo(1750, 880, () => {
                    this.scene.mc.setFlipX(true)
                    delayCallback(1000, () => this.scene.mc
                        .setTexture(HOLDING_BALL_DOWN.key, 8));
                    delayCallback(2000, () => this.auxPerson.sprite
                        .setTexture(PassingBallSheets.PERSON_DOWN.key, 8));

                    delayCallback(3000, () => {
                        this.scene.disablePlayerAnim();
                        this.scene.enablePlayerAnim();
                        this.scene.follower.emit('onMovementComplete');
                        this.auxPerson.follower.emit('onMovementComplete');
                    })
                    delayCallback(4000, () => {
                        this.auxPerson.follower.goTo(new Vector2(1629, 958))
                            .onceMovementComplete(() => {
                                delayCallback(1500, () => this.trafficLight.sprite.setTexture(TrafficLight.Yellow.key));
                                delayCallback(3000, () => this.trafficLight.sprite.setTexture(TrafficLight.Red.key))
                                delayCallback(4500, () => this.deliverBallByAux())
                            })
                    })
                }))
            })
    }

    deliverBallByAux() {
        const delayCallback = (delay, callback) => this.scene.time.addEvent({
            delay,
            callback
        });
        this.auxPerson.follower.goTo(new Vector2(636, 860))
            .onceMovementComplete(() => {
                this.auxPerson.sprite.setFlipX(false);
                delayCallback(1000, () => {
                    this.auxPerson.sprite.setTexture(PassingBallSheets.PERSON_DOWN.key, 8);
                })
                delayCallback(2000, () => {
                    this.tennis.setTexture(PassingBallSheets.Tennis.key, 37);
                })
                delayCallback(3000, () => {
                    this.auxPerson.sprite.setTexture(PassingBallSheets.PERSON_DOWN.key, 0);
                    this.tennis.setTexture(PassingBallSheets.Tennis.key, 43);
                });
                delayCallback(4000, () => {
                    this.auxPerson.sprite.setTexture(PassingBallSheets.PERSON_DOWN.key, 10);
                    this.tennis.setTexture(PassingBallSheets.Tennis.key, 42);
                });
                delayCallback(5000, () => {
                    addDialogTween(this.scene, "La pelota ha sido entregada", this.badge);
                })
            })

    }
    deliverBall() {
        const delayCallback = (delay, callback) => this.scene.time.addEvent({
            delay,
            callback
        });
        const { HOLDING_BALL_DOWN } = this.scene.game.ludo.character.sheets;
        this.scene.moveMcTo(636, 860, () => {
            this.scene.mc.setTexture(HOLDING_BALL_DOWN.key, 8).setFlipX(false)
            delayCallback(1500, () => this.tennis.setTexture(PassingBallSheets.Tennis.key, 37));
            delayCallback(3000, () => {
                this.tennis.setTexture(PassingBallSheets.Tennis.key, 42);
                this.scene.mc.setTexture(HOLDING_BALL_DOWN.key, 10);
                this.scene.disablePlayerAnim();
                this.scene.enablePlayerAnim();
            });
            delayCallback(4500, () => addDialogTween(this.scene, "Has devuelto la pelota", this.badge))
        })
    }

    loseBall() {
        this.trafficLight.sprite.setTexture(TrafficLight.Green.key)
        this.tennis.setTexture(PassingBallSheets.Tennis.key, 30);
        this.ball = createTennisBallSprite(this.scene);
        const { x, y } = this.scene.mc.getBottomLeft();
        this.scene.tweens.add({
            targets: this.ball,
            x: x - this.ball.displayWidth * 0.4,
            y: y - this.scene.mc.displayHeight * 0.2,
            ease: 'Power3',
            onStart: () => this.ball.play(TennisAnims.BOUNCE_BALL.key),
            onComplete: () => {
                this.tennis.play(TennisAnims.LOSE_BALL.key);
                this.scene.time.addEvent({
                    delay: 3000,
                    callback: () => {
                        this.scene.disablePlayerAnim();
                        enablePlayerAnim(this.scene);
                        this.showHint();
                    }
                })
            },
            duration: 6000
        })
    }

    start() {
        this.scene.mc.setFlipX(true);
        this.tennis.play(TennisAnims.PLAY.key)
            .once('animationcomplete', () => this.loseBall())
    }
}

const createTennisSprite = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const key = PassingBallSheets.Tennis.key;
    return scene.add.sprite(0, 0, key, 0)
        .setScale(scaleX * 1.25, scaleY * 1.25)
        .setX(660 * scaleX)
        .setY(845 * scaleY + y)
        .setDepth(7)
}

const createTennisBallSprite = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const key = PassingBallSheets.BALL.key;
    return scene.add.sprite(0, 0, key, 0)
        .setScale(scaleX * 2, scaleY * 2)
        .setDepth(6)
        .setX(650 * scaleX)
        .setY(760 * scaleY + y)
}

const createFireman = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 2403, 1644);
    const sprite = scene.add.sprite(0, 0, PurpleCar.key)
        .setAlpha(0)
        .setDepth(6)
        .setScale(scaleX * 1.5, scaleY * 1.5)
        .setOrigin(0.5, 0.5)

    //.setFlipX(true)
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y)
    return { follower, sprite, move };
}

const createRandPerson = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 1886, 880);
    const sprite = createCharacterSprite(scene, PassingBallSheets.PERSON_DOWN, 0)
        .setDepth(7)
        .setPosition(500, 500)
        .setScale(scene.mc.scale);
    const { WalkUp, WalkDown } = PersonAnims;
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(WalkUp, false))
        .onTopRightMovement(anim(WalkUp, true))
        .onBottomLeftMovement(anim(WalkDown, false))
        .onBottomRightMovement(anim(WalkDown, true))
        .onMovementComplete(() => {
            sprite.anims.stop().setTexture(PassingBallSheets.PERSON_DOWN.key, 1);
        })
    const move = () => {
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);
        sprite.setDepth(sprite.y > scene.mc.y ? 7 : 4)
    }
    return { follower, sprite, move };
}

const createTrafficLight = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const sprite = scene.add.sprite(0, 0, TrafficLight.Red.key)
        .setScale(scaleX, scaleY)
        .setX(1558 * scaleX)
        .setY(845 * scaleY + y)
        .setDepth(7)
    const update = () => sprite.setDepth(scene.mc.y < sprite.y ? 7 : 3);
    return { sprite, update };
}

const enablePlayerAnim = (scene) => {
    scene.disablePlayerAnim();
    const { WALK_DOWN_BALL, WALK_UP_BALL } = scene.game.ludo.character.anims;
    const { TWIST } = scene.game.ludo.character.sheets;
    const play = ({ key }, flipX) => () => scene.mc.play(key).setFlipX(flipX);
    scene.follower.onTopLeftMovement(play(WALK_UP_BALL, false))
        .onTopRightMovement(play(WALK_UP_BALL, true))
        .onBottomLeftMovement(play(WALK_DOWN_BALL, false))
        .onBottomRightMovement(play(WALK_DOWN_BALL, true))
        .onMovementComplete(() => scene.mc.anims.stop()
            .setTexture(TWIST.key, 2));
}

const TennisAnims = {
    PLAY: {
        key: 'PlayingTennis',
        frames: generateFrameNames(PassingBallSheets.Tennis.key, 3, 27),
        frameRate: 5
    },
    LOSE_BALL: {
        key: 'loseBall',
        frames: generateFrameNames(PassingBallSheets.Tennis.key, 30, 34),
        frameRate: 3
    },
    BOUNCE_BALL: {
        key: 'bouncingBall',
        frames: generateFrameNames(PassingBallSheets.BALL.key, 0, 4),
        frameRate: 4
    }
}

const PersonAnims = {
    WalkDown: {
        key: 'auxPersonWalkDown123',
        frames: generateFrameNames(PassingBallSheets.PERSON_DOWN.key, 2, 7),
        frameRate: 7,
        repeat: -1
    },
    WalkUp: {
        key: 'auxPErsonWalkUp123',
        frames: generateFrameNames(PassingBallSheets.PERSON_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}


export default PassingBallSituation;