import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { outlineSetting, OutlineSettingAssets } from "../HabCogGame/utils/settingsConstants/outlineSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import { HuntingFiguresSettingsAssets, getHuntingFiguresDifficultySetting } from "./utils/settings";
import SessionScene from "./scenes/sessionScene";
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';
import { QuantitySettingAssets } from '../HabCogGame/utils/settingsConstants/quantitySetting';
import { GeomTextureAssets, OutlinedGeomTexturesAssets } from '../HabCogGame/utils/assetsPaths/geoms';

const difficultySetting = getHuntingFiguresDifficultySetting();
const quantitySetting = buildQuantitySetting([1, 2]);

const sessionAssets = [
    ...HuntingFiguresSettingsAssets,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...OutlineSettingAssets,
    ...TimeSettingAssets,
    ...GeomTextureAssets,
    ...OutlinedGeomTexturesAssets
]

const data = {
    name: 'Cazando figuras',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './HuntingFiguresGameAssets/logo.png',
    iconUrl: './HuntingFiguresGameAssets/icon.png',
    thumbnail: './HuntingFiguresGameAssets/thumbnail.png',
    getHint: (userConfig) =>
        userConfig.quantity === 0 ? 'Toca la figura.' :
            'Toca la figura izquierda y luego toca la figura derecha.',
    userManual: 'Los estímulos aparecen en la pantalla en posiciones que siguen la ' +
        'secuencia visual utilizada en la lectura. La sucesión se produce con cada ' +
        'selección que realice el usuario. Las opciones de configuración permiten ' +
        'elegir: forma, color, tamaño y número de estímulos, iluminación de ' +
        'contorno o superficie, y tiempo de duración.',
    settings: [
        difficultySetting,
        sizeSetting,
        quantitySetting,
        outlineSetting,
        timeSetting
    ]
};

export default function HuntingFiguresGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
