import Phaser from 'phaser';

class Follower extends Phaser.GameObjects.Sprite {

    constructor(scene, x, y, navMesh, texture) {
        super(scene, x, y, texture);

        this.navMesh = navMesh;
        this.path = null;
        this.currentTarget = null;
        this.scene = scene;

        // Enable arcade physics for moving with velocity
        scene.physics.world.enable(this);

        scene.add.existing(this);
        scene.events.on("update", this.update, this);
        scene.events.once("shutdown", this.destroy, this);
    }

    goTo(targetPoint) {
        this.path = this.navMesh.findPath(new Phaser.Math.Vector2(this.x, this.y), targetPoint);

        if (this.path && this.path.length > 0) this.dispatchNextTarget();
        else this.currentTarget = null;
        return this;
    }

    dispatchNextTarget() {
        this.currentTarget = this.path.shift();
        const speed = 250;

        if (this.currentTarget) {
            const { x, y } = this.currentTarget;
            const distance = Phaser.Math.Distance.Between(this.x, this.y, x, y);
            const duration = distance / speed;
            this.scene.add.tween({
                targets: this,
                x: this.currentTarget.x,
                y: this.currentTarget.y,
                duration: duration * 1000,
                onStart: () => this.emitMovement(),
                onComplete: () => {
                    if (this.path.length > 0) {
                        this.dispatchNextTarget();
                    }
                    else {
                        this.currentTarget = null;
                        this.emit('onMovementComplete');
                    }
                }
            });
        }
    }

    emitMovement() {
        const { x, y } = this.currentTarget;
        const isGoingDown = y > this.y;
        const isGoingRight = x > this.x;

        if (isGoingDown && isGoingRight) {
            this.emit('bottomRightMovement');
        }
        else if (isGoingDown && !isGoingRight) {
            this.emit('bottomLeftMovement')
        }
        else if (!isGoingDown && isGoingRight) {
            this.emit('topRightMovement');
        }
        else if (x !== this.x) { this.emit('topLeftMovement') }
    }

    onTopLeftMovement(callback) {
        return this.on('topLeftMovement', () => {
            if (this.directionState !== FollowerDirection.TOP_LEFT) {
                callback();
                this.directionState = FollowerDirection.TOP_LEFT;
            }
        });
    }

    onTopRightMovement(callback) {
        return this.on('topRightMovement', () => {
            if (this.directionState !== FollowerDirection.TOP_RIGHT) {
                callback();
                this.directionState = FollowerDirection.TOP_RIGHT;
            }
        });
    }

    onBottomLeftMovement(callback) {
        return this.on('bottomLeftMovement', () => {
            if (this.directionState !== FollowerDirection.BOTTOM_LEFT) {
                callback();
                this.directionState = FollowerDirection.BOTTOM_LEFT;
            }
        });
    }

    onBottomRightMovement(callback) {
        return this.on('bottomRightMovement', () => {
            if (this.directionState !== FollowerDirection.BOTTOM_RIGHT) {
                callback();
                this.directionState = FollowerDirection.BOTTOM_RIGHT;
            }
        });
    }

    onMovementComplete(callback) {
        return this.on('onMovementComplete', () => {
            this.directionState = null;
            callback()
        });
    }

    onceMovementComplete(callback) {
        return this.once('onMovementComplete', () => {
            this.directionState = null;
            callback()
        });
    }

    removeMovementEvents() {
        this.off('topLeftMovement')
            .off('topRightMovement')
            .off('bottomLeftMovement')
            .off('bottomRightMovement')
            .off('onMovementComplete')
    }

    destroy() {
        if (this.scene) this.scene.events.off("update", this.update, this);
        super.destroy();
    }

    stop() {
        this.currentTarget = null;
        this.path = null;
        this.emit('onMovementComplete');
    }
}

const FollowerDirection = {
    TOP_LEFT: 0,
    TOP_RIGHT: 1,
    BOTTOM_LEFT: 2,
    BOTTOM_RIGHT: 3

}

export default Follower;