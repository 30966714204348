import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    NO_SHIFT: 0,
    INVERT: 1,
    SYMMETRIC: 2
}

export const PresentationSettingKey = {
    SILHOUETTE: 0,
    CONTOUR: 1
}

export const difficultySettings = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Sin cambios',
            header: 'Sin cambios espaciales',
            description: 'El estímulo patrón no presentará ningún cambio espacial respecto al estímulo objetivo.',
            image: {
                key: 'noShiftSetting',
                url: './ProfilesGameAssets/settings/difficulty/noShift.png'
            },
        },
        {
            title: 'Inversiones',
            header: 'Forma invertida',
            description: 'El estímulo patrón se presentará como una inversión espacial respecto al estímulo objetivo.',
            image: {
                key: 'inversionShift',
                url: './ProfilesGameAssets/settings/difficulty/inversionShift.png'
            },
        },
        {
            title: 'Simetrías',
            header: 'Forma simétrica',
            description: 'El estímulo patrón se presentará como una simetría respecto al estímulo objetivo.',
            image: {
                key: 'symmetricShift',
                url: './ProfilesGameAssets/settings/difficulty/symmetricShift.png'
            },
        }
    ]
};

export const presentationSetting = {
    key: SettingKey.PRESENTATION,
    name: 'Presentación',
    icon: PreviewIcons.PRESENTATION,
    tileIcon: TileIcons.PRESENTATION,
    options: [
        {
            title: 'Silueta',
            header: 'Silueta',
            description: 'El estímulo patrón se presentará como la silueta del estímulo objetivo.',
            image: {
                key: 'silhouetteTarget',
                url: './ProfilesGameAssets/settings/presentation/silhouette.png'
            }
        },
        {
            title: 'Contorno',
            header: 'Contorno',
            description: 'El estímulo patrón se presentará como el contorno del estímulo objetivo.',
            image: {
                key: 'contourTarget',
                url: './ProfilesGameAssets/settings/presentation/contour.png'
            }
        }
    ]
};

export const CustomSettingsAssets = [
    ...difficultySettings.options.map(({ image }) => image),
    ...presentationSetting.options.map(({ image }) => image)
]