import Phaser from "phaser";
import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import {  TableAsset, DisgustingFoodSheets } from "../../utils/assetsPaths/situations/home/disgustingFood";
import { addDialogTween, generateFrameNames, generateFrameNamesByArray } from "../../utils/functions";
import { BOY_SHEETS } from "../../utils/assetsPaths/playerAnim";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { MomIdle, MomBack } from "../../utils/assetsPaths/situations/home/sickPerson";
import { MotherAnims } from "./sickPerson";

const Vector2 = Phaser.Math.Vector2;

export default class DisgustingFoodSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 875, y: 1350 };
        const mcPosition = { x: 1351, y: 1064 };
        super(scene, markPosition, mcPosition, Badges.FORK);
        this.instructionLabel = "Te sirven una comida que no te gusta, ¿qué haces?";
        this.optionsData = [{
            label: "Empujo el plato y no como.",
            onclick: this.runEvent(2500, () => this.refuseFood())
        }, {
            label: "Pienso que es saludable y como.",
            onclick: this.runEvent(2500, () => this.acceptFood())
        }, {
            label: "Agradezco y como.",
            onclick: this.runEvent(2500, () => this.thankFood())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(PlayerAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.table = this.scene.add.sprite(0, 0, TableAsset.key)
            .setPosition(1158 * scaleX, 1025 * scaleY + y)
            .setDepth(this.scene.mc.depth + 1)
            .setScale(this.scene.mc.scale)
        return this;
    }

    refuseFood() {
        const time = 1500;
        const sheets = this.scene.game.ludo.character.sheets;
        const mcSheet = sheets === BOY_SHEETS ?
            DisgustingFoodSheets.MAN_REFUSE_FOOD : DisgustingFoodSheets.WOMAN_REFUSE_FOOD;
        this.table.setTexture(mcSheet.key, 10)
        this.addEvent(time, () => this.table.setTexture(mcSheet.key, 11));
        this.addEvent(time * 2, () => addDialogTween(this.scene, "Rechazaste la comida"));

    }

    acceptFood() {
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ?
            PlayerAnims.MAN_ACCEPT_FOOD : PlayerAnims.WOMAN_ACCEPT_FOOD;
        const eatAnim = this.mcIsBoy ? PlayerAnims.MAN_EAT : PlayerAnims.WOMAN_EAT

        this.table.play(anim.key)
            .once("animationcomplete", () => this.table.play(eatAnim.key)
                .once("animationcomplete", this.runEvent(2500,
                    () => addDialogTween(this.scene, "Te quedaste sin hambre", this.badge))))

    }

    thankFood() {
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ?
            PlayerAnims.MAN_THANK_FOOD : PlayerAnims.WOMAN_THANK_FOOD;
        const eatAnim = this.mcIsBoy ? PlayerAnims.MAN_EAT : PlayerAnims.WOMAN_EAT
        this.table.play(anim.key)
            .once("animationcomplete", () => this.table.play(eatAnim.key)
                .once("animationcomplete", this.runEvent(2500,
                    () => addDialogTween(this.scene, "Te quedaste sin hambre", this.badge))))
    }

    reboot(onComplete) {
        this.table.setTexture(TableAsset.key);
        this.scene.mc.setAlpha(1);
        onComplete();
    }

    start() {
        this.mom = createMother(this.scene);
        attachMotherToEvents(this.scene, this.mom);
        this.scene.mc.setFlipX(true);
        const sheets = this.scene.game.ludo.character.sheets;
        const anim = sheets === BOY_SHEETS ?
            PlayerAnims.MAN_TAKE_SIT : PlayerAnims.WOMAN_TAKE_SIT;
        this.scene.tweens.add({
            targets: this.mom.sprite,
            alpha: 1,
            onStart: () => this.mom.sprite.setTexture(MomBack.key)
        })
        this.addEvent(3000, () => {
            this.mom.follower.goTo(new Vector2(1062, 1068))
                .onceMovementComplete(this.runEvent(1500, () => {
                    this.mom.sprite.setAlpha(0);
                    this.scene.mc.setAlpha(0);
                    this.table.play(anim.key)
                        .once("animationcomplete",
                            this.runEvent(2500, () => this.showHint()))
                }));
        })
    }
}

const createMother = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 769, 854);
    const sprite = createCharacterSprite(scene, MomIdle, 0)
        .setScale(scene.mc.scale).setAlpha(0)
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(MotherAnims.WalkUp, false))
        .onTopRightMovement(anim(MotherAnims.WalkUp, true))
        .onBottomLeftMovement(anim(MotherAnims.WalkDown, true))
        .onBottomRightMovement(anim(MotherAnims.WalkDown, false))
        .onMovementComplete(() => sprite
            .anims.stop()
            .setTexture(MomIdle.key))
    const move = () =>
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);

    return { follower, sprite, move };
}

const attachMotherToEvents = (scene, mom) => {
    scene.events.on("update", mom.move);
    scene.events.once("shutdown",
        () => scene.events.off("update", mom.move));
}

const PlayerAnims = {
    MAN_TAKE_SIT: {
        key: 'manTakeSit',
        frames: generateFrameNames(DisgustingFoodSheets.MAN_REFUSE_FOOD.key, 0, 9),
        frameRate: 2
    },
    MAN_EAT: {
        key: 'manEATFOODTABLE',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.MAN_ACCEPT_FOOD.key, [
            12, 13, 14, 15, 14, 13
        ]),
        repeat: 2,
        frameRate: 3
    },
    MAN_ACCEPT_FOOD: {
        key: 'manAcceptFood',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.MAN_ACCEPT_FOOD.key, [
            9, 10, 11
        ]),
        frameRate: 1
    },
    MAN_THANK_FOOD: {
        key: 'manThankFood',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.MAN_THANK_FOOD.key, [
            9, 10, 11, 12
        ]),
        frameRate: 1
    },
    WOMAN_EAT: {
        key: 'womanEATFOODTABLE',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.WOMAN_ACCEPT_FOOD.key, [
            12, 13, 14, 15, 14, 13
        ]),
        repeat: 2,
        frameRate: 3
    },
    WOMAN_TAKE_SIT: {
        key: 'womanTakeSit',
        frames: generateFrameNames(DisgustingFoodSheets.WOMAN_REFUSE_FOOD.key, 0, 9),
        frameRate: 2
    },
    WOMAN_ACCEPT_FOOD: {
        key: 'womanAcceptFood',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.WOMAN_ACCEPT_FOOD.key, [
            9, 10, 11
        ]),
        frameRate: 1
    },
    WOMAN_THANK_FOOD: {
        key: 'womanThankFood',
        frames: generateFrameNamesByArray(DisgustingFoodSheets.WOMAN_THANK_FOOD.key, [
            9, 10, 11, 12
        ]),
        frameRate: 1
    },

}