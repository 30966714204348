import Phaser from 'phaser';
import { scaleImage, calculateImageScale } from '../utils/resize';
import { Btns } from '../utils/assetsPaths/btn';
import { PreviewIcons } from '../utils/assetsPaths/previewIcons';
import { GameFont } from '../utils/constants';

const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
export default class PreviewBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.PREVIEW_BOX.key);
        this.image = new Image(scene, 0, 0, Btns.WHITE_BOX.key).setOrigin(0.5, 0.3);
        this.headerLabel = new Text(scene, 0, 0, '', PreviewBoxTextStyle).setOrigin(0.5);
        this.descriptionLabel = new Text(scene, 0, 0, '', PreviewBoxTextStyle).setOrigin(0.5, 0);
        this.add([this.background, this.image, this.headerLabel, this.descriptionLabel]);
        this.addOuterHeader();
        this.resizeLabels();
    }

    addOuterHeader() {
        this.outerHeaderLabel = new Text(this.scene, 0, 0, 'Dificultad', OuterHeaderTextStyle).setOrigin(0.5);
        this.iconHeader = new Image(this.scene, 0, 0, PreviewIcons.DIFFICULTY.key).setOrigin(1.25, 0.5);
        this.add([this.outerHeaderLabel, this.iconHeader]);
    }

    updateContent(outerHeaderText, iconTexture, headerText, descriptionText, image) {
        this.outerHeaderLabel.setText(outerHeaderText);
        this.iconHeader.setTexture(iconTexture.key);
        this.headerLabel.setText(headerText);
        this.descriptionLabel.setText(descriptionText);
        this.image.setTexture(image.key);
        this.iconHeader.setX(this.outerHeaderLabel.getLeftCenter().x)
    }

    resizeOuterHeader(height) {
        this.fontSize = height / 7.5;
        const iconSize = this.fontSize * 1.5;
        this.outerHeaderLabel
            .setFontSize(`${this.fontSize}px`)
            .setStroke('#2E7CA2', this.fontSize / 5);
        scaleImage(this.iconHeader, iconSize, iconSize, 0, 1);
    }

    setOuterHeaderPosition() {
        this.outerHeaderLabel.setY(this.background.getTopCenter().y - this.fontSize);
        const { x, y } = this.outerHeaderLabel.getLeftCenter();
        this.iconHeader.setPosition(x, y);

    }

    resizeLabels() {
        const { displayHeight, displayWidth } = this.background
        const { y } = this.background.getTopCenter();
        const headerFontSize = displayHeight / 15;
        this.headerLabel
            .setFontSize(`${headerFontSize}px`)
            .setY(y + headerFontSize * 2);
        this.descriptionLabel
            .setFontSize(`${headerFontSize * 0.75}px`).setWordWrapWidth(displayWidth * 0.8)
            .setY(this.headerLabel.getBottomCenter().y);
    }

    resize(availableWidth, availableHeight, padding, scaleMultiplier) {
        const { width, height } = this.background;
        const scale = calculateImageScale(width, height, availableWidth, availableHeight, padding);
        this.resizeOuterHeader(height * scale);
        scaleImage(this.background, availableWidth, availableHeight - this.outerHeaderLabel.displayHeight, padding, scaleMultiplier);
        this.resizeLabels();
        this.setOuterHeaderPosition();
        const {displayWidth, displayHeight} = this.background;
        scaleImage(this.image, displayWidth / 2, displayHeight / 2, 0, 0.9);

    }

    setPosition(x = 0, y = 0) {
        if (this.outerHeaderLabel)
            super.setPosition(x, y + this.background.getTopCenter().y - this.outerHeaderLabel.y);
        else
            super.setPosition(x, y);
    }
}

const PreviewBoxTextStyle = {
    fontFamily: GameFont,
    color: '#204659'
}

const OuterHeaderTextStyle = {
    fontFamily: GameFont,
    color: '#F8F38A',
    stroke: '#2E7CA2'
}