import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { RainningStoreSheets } from "../../utils/assetsPaths/situations/city/rainningStore";
import { generateFrameNames, addDialogTween } from "../../utils/functions";

class RainningStoreSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 1320, y: 1480 };
        const mcPosition = { x: 1663, y: 1824 };
        super(scene, markPosition, mcPosition, Badges.UMBRELLA);
        this.instructionLabel = 'Cuando llegas a la tienda empieza a llover, ¿qué haces?';
        this.optionsData = [{
            label: 'Juego bajo la lluvia. No me importa si me resfrío.',
            onclick: () => this.doNothing()
        }, {
            label: 'Pido prestado un paraguas para cubrirme de la lluvia.',
            onclick: () => this.takeUmbrella()
        }, {
            label: 'Entro a la tienda y espero hasta que deje de llover.',
            onclick: () => this.takeCover()
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(CloudAnims).forEach(a => anims.create(a));
    }

    takeUmbrella() {
        const { TAKE_UMBRELLA } = this.scene.game.ludo.character.anims;
        const label = "Te protegiste de la lluvia";
        const addDialog = () => addDialogTween(this.scene, label, this.badge)
        this.addEvent(2500, () => {
            this.scene.mc.play(TAKE_UMBRELLA.key)
                .once('animationcomplete', () => this.scene.tweens.add({
                    targets: this.rainDrops,
                    alpha: 0,
                    duration: 2000,
                    onComplete: this.runEvent(2500, () => addDialog())
                }))
        })
    }

    takeCover() {
        const { COVER_HEAD } = this.scene.game.ludo.character.anims;
        const { UP } = this.scene.game.ludo.character.sheets;
        const label = "Te protegiste de la lluvia";
        const addDialog = () => {
            addDialogTween(this.scene, label, this.badge)
            this.scene.tweens.add({
                targets: this.scene.mc,
                alpha: 1
            })
        }

        const startSequence = () => this.scene.tweens.add({
            targets: this.scene.follower,
            x: 1505,
            y: 1704,
            onStart: () => this.scene.follower.emit("topLeftMovement"),
            onComplete: () => {
                this.scene.mc.anims.stop().setTexture(UP.key).setFlipX(true);
                this.scene.tweens.add({
                    targets: this.scene.mc,
                    alpha: 0,
                    duration: 2000,
                    onComplete: () => {
                        this.scene.tweens.add({
                            targets: this.rainDrops,
                            alpha: 0,
                            duration: 2000,
                            onComplete: this.runEvent(1500, addDialog)
                        })
                    }
                })
            }
        })


        this.addEvent(2000, () => {
            this.scene.mc.play(COVER_HEAD.key)
                .once("animationcomplete", this.runEvent(2500, startSequence))
        })

    }

    doNothing() {
        const { SNEEZE, RAIN_DANCE } = this.scene.game.ludo.character.anims;
        const label = 'Te has resfriado';
        const addDialog = () => addDialogTween(this.scene, label);
        this.addEvent(2500, () => {
            this.scene.mc.play(RAIN_DANCE.key)
                .once('animationcomplete', () => this.scene.mc.play(SNEEZE.key)
                    .once('animationcomplete', addDialog))
            this.scene.tweens.add({
                targets: this.rainDrops,
                alpha: 0.25,
                delay: 4000,
                duration: 5000,
                onComplete: () => this.rainDrops.setAlpha(0)
            })
        })
    }

    reboot(onComplete) {
        this.scene.follower.emit('onMovementComplete');
        const garbage = [this.cloud, this.rainDrops];
        garbage.forEach(item => item.destroy());
        onComplete();
    }

    start() {
        this.scene.mc.setFlipX(true);
        this.cloud = createCloud(this.scene);
        this.scene.tweens.add({
            targets: this.cloud,
            alpha: 1,
            duration: 3000,
            onStart: () => this.cloud.play(CloudAnims.Normal.key),
            onComplete: () => {
                const time = 2500
                this.addEvent(time, () => this.cloud.play(CloudAnims.Regular.key));
                this.addEvent(time * 2, () => this.cloud.play(CloudAnims.Rain.key));
                this.addEvent(time * 3, () => {
                    this.rainDrops = createRainDrops(this.scene, this.cloud)
                        .play(CloudAnims.RainDrops.key)
                    this.scene.tweens.add({
                        targets: this.rainDrops,
                        alpha: 1,
                        duration: 3000,
                        onComplete: () => {
                            this.addEvent(time, () => this.showHint())
                        }
                    })
                })
            }
        })
    }
}

const createCloud = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    return scene.add.sprite(0, 0, RainningStoreSheets.CLOUD.key, 0)
        .setScale(scaleX, scaleY)
        .setX(1663 * scaleX)
        .setY(1300 * scaleY + y)
        .setAlpha(0)
        .setDepth(7);
}

const createRainDrops = (scene, cloudSprite) => {
    const x = cloudSprite.x;
    const y = (cloudSprite.y + scene.mc.y) / 2;
    return scene.add.sprite(x, y, RainningStoreSheets.DROPS.key, 0)
        .setAlpha(0)
        .setScale(cloudSprite.scaleX, cloudSprite.scaleY)
        .setDepth(6);
}

const CloudFrameRate = 6;
const CloudAnims = {
    Normal: {
        key: 'cloudHappy',
        frames: generateFrameNames(RainningStoreSheets.CLOUD.key, 0, 1),
        frameRate: CloudFrameRate,
        repeat: -1
    },
    Regular: {
        key: 'cloudRegular',
        frames: generateFrameNames(RainningStoreSheets.CLOUD.key, 2, 3),
        frameRate: CloudFrameRate,
        repeat: -1
    },
    Rain: {
        key: 'cloudRainning',
        frames: generateFrameNames(RainningStoreSheets.CLOUD.key, 4, 5),
        frameRate: CloudFrameRate,
        repeat: -1
    },
    RainDrops: {
        key: 'cloudRainDropds',
        frames: generateFrameNames(RainningStoreSheets.DROPS.key, 0, 3),
        frameRate: CloudFrameRate,
        repeat: -1
    }
}

export default RainningStoreSituation;