import { generateFrameNamesByArray, generateFrameNames } from "../functions"

export const BOY_IDLE = {
    key: 'manIdleDown',
    url: 'SocialInclusionAssets/anims/players/manIdle.png'
}

export const GIRL_IDLE = {
    key: 'womanIdle',
    url: 'SocialInclusionAssets/anims/players/womanIdle.png'
}

export const BOY_LOOK_UP = {
    key: "manLookUpImg",
    url: "SocialInclusionAssets/anims/players/manLookUp.png"
}

export const GIRL_LOOK_UP = {
    key: "womanLookUpImg",
    url: "SocialInclusionAssets/anims/players/womanLookUp.png"
}

export const BOY_SHEETS = {
    DOWN: {
        key: 'manWalkingDown',
        url: 'SocialInclusionAssets/anims/players/manWalkDown.png',
        frameWidth: 270 / 4,
        frameHeight: 417 / 3
    },
    UP: {
        key: 'manWalkingUp',
        url: 'SocialInclusionAssets/anims/players/manWalkUp.png',
        frameWidth: 243 / 4,
        frameHeight: 417 / 3
    },
    SCARED: {
        key: 'manScared',
        url: 'SocialInclusionAssets/anims/players/manScared.png',
        frameWidth: 329 / 4,
        frameHeight: 448 / 3
    },
    PHONE_CALL: {
        key: 'menPhonecall',
        url: 'SocialInclusionAssets/anims/players/manPhonecall.png',
        frameWidth: 346 / 5,
        frameHeight: 262 / 2
    },
    BEEN_STOLEN: {
        key: 'manStoleSheet',
        url: 'SocialInclusionAssets/anims/players/manStolen.png',
        frameWidth: 426 / 4,
        frameHeight: 568 / 4
    },
    REFUSE: {
        key: 'manRefuse',
        url: 'SocialInclusionAssets/anims/players/refuseMan.png',
        frameWidth: 284 / 3,
        frameHeight: 267 / 2
    },
    HOLDING_BALL_DOWN: {
        key: 'manHoldingHandDown',
        url: 'SocialInclusionAssets/anims/players/manHoldingBallDown.png',
        frameWidth: 302 / 4,
        frameHeight: 420 / 3
    },
    HOLDING_BALL_UP: {
        key: 'manHoldingHandUp',
        url: 'SocialInclusionAssets/anims/players/manHoldingBallUp.png',
        frameWidth: 183 / 3,
        frameHeight: 277 / 2
    },
    TWIST: {
        key: 'manTwist',
        url: 'SocialInclusionAssets/anims/players/manTwist.png',
        frameWidth: 465 / 4,
        frameHeight: 439 / 3
    },
    WAIT: {
        key: 'manWaiting',
        url: 'SocialInclusionAssets/anims/players/manWaiting.png',
        frameWidth: 312 / 4,
        frameHeight: 413 / 3
    },
    TAKE_UMBRELLA: {
        key: 'manTakeUmbrella',
        url: 'SocialInclusionAssets/anims/players/manUmbrella.png',
        frameWidth: 354 / 3,
        frameHeight: 305 / 2
    },
    COVER_HEAD: {
        key: 'manCoverHead',
        url: 'SocialInclusionAssets/anims/players/manHeadCover.png',
        frameWidth: 178 / 3,
        frameHeight: 268 / 2
    },
    SNEEZE: {
        key: 'manSneeze',
        url: 'SocialInclusionAssets/anims/players/manSneeze.png',
        frameWidth: 487 / 4,
        frameHeight: 440 / 3
    },
    USE_HAT: {
        key: 'manUseHat',
        url: 'SocialInclusionAssets/anims/players/manUseHat.png',
        frameWidth: 271 / 4,
        frameHeight: 551 / 4
    },
    BURN: {
        key: 'manBurn',
        url: 'SocialInclusionAssets/anims/players/manBurn.png',
        frameWidth: 173 / 3,
        frameHeight: 399 / 3
    },
    REFUSE_PAY: {
        key: 'manRefusePay',
        url: 'SocialInclusionAssets/anims/players/manRefuseToPay.png',
        frameWidth: 329 / 5,
        frameHeight: 658 / 5
    },
    REMEMBER_PAY: {
        key: 'manRememberPay',
        url: 'SocialInclusionAssets/anims/players/manRememberToPay.png',
        frameWidth: 329 / 5,
        frameHeight: 520 / 4
    },
    FORGOT_PAY: {
        key: 'manForgotPay',
        url: 'SocialInclusionAssets/anims/players/manPayCandy.png',
        frameWidth: 329 / 5,
        frameHeight: 652 / 5
    },
    CARD_ACCEPT: {
        key: 'manCardAccept',
        url: 'SocialInclusionAssets/anims/players/manCardAccept.png',
        frameWidth: 400 / 5,
        frameHeight: 532 / 4
    },
    CARD_ANGRY: {
        key: 'manCardAngry',
        url: 'SocialInclusionAssets/anims/players/manCardAngry.png',
        frameWidth: 583 / 5,
        frameHeight: 540 / 4
    },
    CARD_CONGRAT: {
        key: 'manCardCongrat',
        url: 'SocialInclusionAssets/anims/players/manCardCongrat.png',
        frameWidth: 551 / 5,
        frameHeight: 540 / 4
    }
}

export const GIRL_SHEETS = {
    DOWN: {
        key: 'womanWalkingDown',
        url: 'SocialInclusionAssets/anims/players/womanWalkDown.png',
        frameWidth: 277 / 4,
        frameHeight: 435 / 3
    },
    UP: {
        key: 'womanWalkingUp',
        url: 'SocialInclusionAssets/anims/players/womanWalkUp.png',
        frameWidth: 288 / 4,
        frameHeight: 446 / 3
    },
    SCARED: {
        key: 'womanScared',
        url: 'SocialInclusionAssets/anims/players/womanScared.png',
        frameWidth: 339 / 4,
        frameHeight: 470 / 3
    },
    PHONE_CALL: {
        key: 'womanPhonecall',
        url: 'SocialInclusionAssets/anims/players/womanPhonecall.png',
        frameWidth: 68,
        frameHeight: 134
    },
    BEEN_STOLEN: {
        key: 'womanStoleSheet',
        url: 'SocialInclusionAssets/anims/players/womanStolen.png',
        frameWidth: 370 / 4,
        frameHeight: 554 / 4
    },
    REFUSE: {
        key: 'womanRefuse',
        url: 'SocialInclusionAssets/anims/players/refuseWoman.png',
        frameWidth: 149 / 3,
        frameHeight: 272 / 2
    },
    HOLDING_BALL_DOWN: {
        key: 'womanHoldingHandDown',
        url: 'SocialInclusionAssets/anims/players/womanHoldingBallDown.png',
        frameWidth: 319 / 4,
        frameHeight: 439 / 3
    },
    HOLDING_BALL_UP: {
        key: 'womanHoldingHandUp',
        url: 'SocialInclusionAssets/anims/players/womanHoldingBallUp.png',
        frameWidth: 190 / 3,
        frameHeight: 281 / 2
    },
    TWIST: {
        key: 'womanTwist',
        url: 'SocialInclusionAssets/anims/players/womanTwist.png',
        frameWidth: 322 / 4,
        frameHeight: 446 / 3
    },
    WAIT: {
        key: 'womanWaiting',
        url: 'SocialInclusionAssets/anims/players/waitingWoman.png',
        frameWidth: 330 / 4,
        frameHeight: 437 / 3
    },
    TAKE_UMBRELLA: {
        key: 'womanTakeUmbrella',
        url: 'SocialInclusionAssets/anims/players/womanUmbrella.png',
        frameWidth: 351 / 3,
        frameHeight: 299 / 2
    },
    COVER_HEAD: {
        key: 'womanCoverHead',
        url: 'SocialInclusionAssets/anims/players/womanHeadCover.png',
        frameWidth: 174 / 3,
        frameHeight: 270 / 2
    },
    SNEEZE: {
        key: 'womanSneeze',
        url: 'SocialInclusionAssets/anims/players/womanSneeze.png',
        frameWidth: 496 / 4,
        frameHeight: 419 / 3
    },
    USE_HAT: {
        key: 'womanUseHat',
        url: 'SocialInclusionAssets/anims/players/womanUseHat.png',
        frameWidth: 264 / 4,
        frameHeight: 555 / 4
    },
    BURN: {
        key: 'womanBurn',
        url: 'SocialInclusionAssets/anims/players/womanBurn.png',
        frameWidth: 203 / 3,
        frameHeight: 400 / 3
    },
    REFUSE_PAY: {
        key: 'womanRefusePay',
        url: 'SocialInclusionAssets/anims/players/womanRefuseToPay.png',
        frameWidth: 392 / 5,
        frameHeight: 659 / 5
    },
    REMEMBER_PAY: {
        key: 'womanRememberPay',
        url: 'SocialInclusionAssets/anims/players/womanRememberToPay.png',
        frameWidth: 371 / 5,
        frameHeight: 528 / 4
    },
    FORGOT_PAY: {
        key: 'womanForgotPay',
        url: 'SocialInclusionAssets/anims/players/womanPayCandy.png',
        frameWidth: 379 / 5,
        frameHeight: 678 / 5
    },
    CARD_ACCEPT: {
        key: 'womanCardAccept',
        url: 'SocialInclusionAssets/anims/players/womanCardAccept.png',
        frameWidth: 406 / 5,
        frameHeight: 548 / 4
    },
    CARD_ANGRY: {
        key: 'womanCardAngry',
        url: 'SocialInclusionAssets/anims/players/womanCardAngry.png',
        frameWidth: 576 / 5,
        frameHeight: 535 / 4
    },
    CARD_CONGRAT: {
        key: 'womanCardCongrat',
        url: 'SocialInclusionAssets/anims/players/womanCardCongrat.png',
        frameWidth: 544 / 5,
        frameHeight: 534 / 4
    }
}

export const BOY_ANIMS = {
    IDLE: {
        key: BOY_IDLE.key
    },
    LOOK_UP:{
        key: BOY_LOOK_UP.key
    },
    DOWN: {
        key: 'manWalkingDown',
        frameRate: 7,
        repeat: -1
    },
    UP: {
        key: 'manWalkingUp',
        frames: generateFrameNames(BOY_SHEETS.UP.key, 6, 11),
        frameRate: 7,
        repeat: -1
    },
    SCARED: {
        key: 'manScared',
        frameRate: 6,
        repeat: 1
    },
    PHONE_CALL: {
        key: 'menPhonecall',
        frames: generateFrameNamesByArray("menPhonecall",
            [1, 2, 3,
                4, 5, 6, 7,
                6, 5,
                4, 5, 6, 7,
                6, 5,
                4, 5, 6, 7,
                8, 9]),
        frameRate: 5
    },
    BEEN_STOLEN: {
        key: 'manStoleSheet',
        frames: generateFrameNames('manStoleSheet', 1, 5),
        frameRate: 5
    },
    FINISH_STOLEN: {
        key: 'manFinishStoleSheet',
        frames: generateFrameNames('manStoleSheet', 8, 13),
        frameRate: 6,
        repeat: 2
    },
    REFUSE: {
        key: 'manRefuse',
        frames: generateFrameNamesByArray("manRefuse", [2, 3]),
        frameRate: 5,
        repeat: 10
    },
    HOLDING_HAND_UP: {
        key: 'manHoldingHandUp',
        frames: generateFrameNames(BOY_SHEETS.HOLDING_BALL_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    HOLDING_HAND_DOWN: {
        key: 'manHoldingHandDown',
        frames: generateFrameNames(BOY_SHEETS.HOLDING_BALL_DOWN.key, 2, 7),
        frameRate: 7,
        repeat: -1
    },
    GRAB_BALL: {
        key: 'manGrabBallAnim',
        frames: generateFrameNamesByArray(BOY_SHEETS.TWIST.key, [0, 1, 2]),
        frameRate: 1
    },
    TWIST: {
        key: 'manCarelessCrossss',
        frames: generateFrameNamesByArray(BOY_SHEETS.TWIST.key, [
            5, 6, 7, 8,
            7, 6, 5,
            6, 7, 8,
            7, 6, 5,
            9, 9, 9,
            10, 10, 10,
            11, 11, 11, 2
        ]),
        frameRate: 5
    },
    WALK_DOWN_BALL: {
        key: 'manWalkingDownBall',
        frames: generateFrameNames(BOY_SHEETS.HOLDING_BALL_DOWN.key, 2, 7),
        frameRate: 7,
        repeat: -1
    },
    WALK_UP_BALL: {
        key: 'manHoldingHandUp',
        frameRate: 7,
        repeat: -1
    },
    WAIT: {
        key: 'manWaiting',
        frames: generateFrameNamesByArray(BOY_SHEETS.WAIT.key, [
            1, 2, 3, 4
        ]),
        frameRate: 2,
        repeat: 2
    },
    TAKE_UMBRELLA: {
        key: 'manTakeUmbrella',
        frames: generateFrameNames(BOY_SHEETS.TAKE_UMBRELLA.key, 0, 5),
        frameRate: 2
    },
    COVER_HEAD: {
        key: 'manCoverHead',
        frames: generateFrameNames(BOY_SHEETS.COVER_HEAD.key, 0, 4),
        frameRate: 3
    },
    SNEEZE: {
        key: 'manSneeze',
        frames: generateFrameNames(BOY_SHEETS.SNEEZE.key, 8, 11),
        frameRate: 3,
        repeat: 5,
    },
    RAIN_DANCE: {
        key: 'manRainDance',
        frames: generateFrameNamesByArray(BOY_SHEETS.SNEEZE.key, [
            1, 2, 3, 4, 5, 6, 7,
            6, 5, 4,
            5, 6, 7,
            6, 5, 4,
            5, 6, 7,
            6, 5, 4,
            5, 6, 7
        ]),
        frameRate: 3
    },
    USE_HAT: {
        key: 'manUseHat',
        frames: generateFrameNames(BOY_SHEETS.USE_HAT.key, 0, 15),
        frameRate: 3
    },
    EXTEND_HAND: {
        key: "getCandy",
        frames: generateFrameNames(BOY_SHEETS.REFUSE_PAY.key, 1, 2),
        frameRate: 2
    },
    EAT_CANDY: {
        key: "rememberCandyPay",
        frames: generateFrameNames(BOY_SHEETS.REFUSE_PAY.key, 3, 4),
        frameRate: 2
    },
    FINISH_EAT: {
        key: "finishEatCandy",
        frames: generateFrameNames(BOY_SHEETS.FORGOT_PAY.key, 17, 20),
        frameRate: 2
    },
    REMEMBER_PAY: {
        key: "rememberPayAnim123",
        frames: generateFrameNamesByArray(BOY_SHEETS.FORGOT_PAY.key, [
            9, 10, 11, 12, 11, 10, 11, 12, 11, 10, 13, 14, 15, 16

        ]),
        frameRate: 2
    },
    CHECK_PAY: {
        key: "rememberPayAnim456",
        frames: generateFrameNamesByArray(BOY_SHEETS.FORGOT_PAY.key, [
            6, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 7, 8, 13, 14, 15, 16
        ]),
        frameRate: 2
    },
    REFUSE_PAY: {
        key: "refusePayCandy",
        frames: generateFrameNames(BOY_SHEETS.REFUSE_PAY.key, 6, 21),
        frameRate: 2
    },
    TAKE_CARD_SEAT: {
        key: "manCardSeat",
        frames: generateFrameNames(BOY_SHEETS.CARD_ACCEPT.key, 0, 3),
        frameRate: 3
    },
    PLAY_CARDS: {
        key: "manPlayCards",
        frames: generateFrameNames(BOY_SHEETS.CARD_ACCEPT.key, 4, 12),
        frameRate: 1
    },
    ANGRY_LOSE_CARD: {
        key: "manLooseCardGame",
        frames: generateFrameNames(BOY_SHEETS.CARD_ANGRY.key, 13, 17),
        frameRate: 3
    },
    CONGRAT_CARD: {
        key: "manCongratNPCCard",
        frames: generateFrameNamesByArray(BOY_SHEETS.CARD_CONGRAT.key, [
            13, 14, 15, 16, 17, 16, 17, 16, 17
        ]),
        frameRate: 3,
    },
    CARD_ACCEPT: {
        key: "manCardAccept",
        frames: generateFrameNamesByArray(BOY_SHEETS.CARD_ACCEPT.key, [
            13, 14, 15, 15, 15, 15, 16, 17, 16, 17, 16, 17
        ]),
        frameRate: 2
    }
}

export const GIRL_ANIMS = {
    IDLE: {
        key: GIRL_IDLE.key
    },
    LOOK_UP:{
        key: GIRL_LOOK_UP.key
    },
    DOWN: {
        key: 'womanWalkingDown',
        frameRate: 7,
        repeat: -1
    },
    UP: {
        key: 'womanWalkingUp',
        frameRate: 7,
        frames: generateFrameNames(GIRL_SHEETS.UP.key, 0,5),
        repeat: -1
    },
    SCARED: {
        key: 'womanScared',
        frameRate: 6,
        repeat: 1
    },
    PHONE_CALL: {
        key: 'womanPhonecall',
        frames: generateFrameNamesByArray("womanPhonecall",
            [1, 2, 3,
                4, 5, 6, 7,
                6, 5,
                4, 5, 6, 7,
                6, 5,
                4, 5, 6, 7,
                8, 9]),
        frameRate: 5
    },
    BEEN_STOLEN: {
        key: 'womanStoleSheet',
        frames: generateFrameNames("womanStoleSheet", 1, 5),
        frameRate: 5
    },
    FINISH_STOLEN: {
        key: 'womanFinishStoleSheet',
        frames: generateFrameNames("womanStoleSheet", 8, 13),
        frameRate: 6,
        repeat: 2
    },
    REFUSE: {
        key: 'womanRefuse',
        frames: generateFrameNamesByArray("womanRefuse", [2, 3]),
        frameRate: 5,
        repeat: 10
    },
    HOLDING_HAND_UP: {
        key: 'womanHoldingHandUp',
        frames: generateFrameNames(GIRL_SHEETS.HOLDING_BALL_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    HOLDING_HAND_DOWN: {
        key: 'womanHoldingHandDown',
        frames: generateFrameNames(GIRL_SHEETS.HOLDING_BALL_DOWN.key, 2, 7),
        frameRate: 7,
        repeat: -1
    },
    GRAB_BALL: {
        key: 'womanGrabBallAnim',
        frames: generateFrameNamesByArray(GIRL_SHEETS.TWIST.key, [0, 1, 2]),
        frameRate: 1
    },
    TWIST: {
        key: 'womanCarelessCrossss',
        frames: generateFrameNamesByArray(GIRL_SHEETS.TWIST.key, [
            5, 6, 7, 8,
            7, 6, 5,
            6, 7, 8,
            7, 6, 5,
            9, 9, 9,
            10, 10, 10,
            11, 11, 11, 2
        ]),
        frameRate: 5
    },
    WALK_DOWN_BALL: {
        key: 'womanWalkingDownBall',
        frames: generateFrameNames(GIRL_SHEETS.HOLDING_BALL_DOWN.key, 2, 7),
        frameRate: 7,
        repeat: -1
    },
    WALK_UP_BALL: {
        key: 'womanHoldingHandUp',
        frameRate: 7,
        repeat: -1
    },
    WAIT: {
        key: 'womanWaiting',
        frames: generateFrameNamesByArray(GIRL_SHEETS.WAIT.key, [
            1, 2, 3, 4
        ]),
        frameRate: 2,
        repeat: 2
    },
    TAKE_UMBRELLA: {
        key: 'womanTakeUmbrella',
        frames: generateFrameNames(GIRL_SHEETS.TAKE_UMBRELLA.key, 0, 5),
        frameRate: 2
    },
    COVER_HEAD: {
        key: 'womanCoverHead',
        frames: generateFrameNames(GIRL_SHEETS.COVER_HEAD.key, 0, 4),
        frameRate: 3
    },
    SNEEZE: {
        key: 'womanSneeze',
        frames: generateFrameNames(GIRL_SHEETS.SNEEZE.key, 8, 11),
        frameRate: 3,
        repeat: 5,
    },
    RAIN_DANCE: {
        key: 'womanRainDance',
        frames: generateFrameNamesByArray(GIRL_SHEETS.SNEEZE.key, [
            1, 2, 3, 4, 5, 6, 7,
            6, 5, 4,
            5, 6, 7,
            6, 5, 4,
            5, 6, 7,
            6, 5, 4,
            5, 6, 7
        ]),
        frameRate: 3
    },
    USE_HAT: {
        key: 'womanUseHat',
        frames: generateFrameNames(GIRL_SHEETS.USE_HAT.key, 0, 15),
        frameRate: 3
    },
    EXTEND_HAND: {
        key: "womangetCandy",
        frames: generateFrameNames(GIRL_SHEETS.REFUSE_PAY.key, 1, 2),
        frameRate: 2
    },
    EAT_CANDY: {
        key: "womanrememberCandyPay",
        frames: generateFrameNames(GIRL_SHEETS.REFUSE_PAY.key, 3, 4),
        frameRate: 2
    },
    FINISH_EAT: {
        key: "womanfinishEatCandy",
        frames: generateFrameNames(GIRL_SHEETS.FORGOT_PAY.key, 17, 20),
        frameRate: 2
    },
    REMEMBER_PAY: {
        key: "womanrememberPayAnim123",
        frames: generateFrameNames(GIRL_SHEETS.FORGOT_PAY.key, 6, 16),
        frameRate: 2
    },
    CHECK_PAY: {
        key: "womanrememberPayAnim456",
        frames: generateFrameNames(GIRL_SHEETS.REMEMBER_PAY.key, 6, 12),
        frameRate: 2
    },
    REFUSE_PAY: {
        key: "womanrefusePayCandy",
        frames: generateFrameNames(GIRL_SHEETS.REFUSE_PAY.key, 6, 21),
        frameRate: 2
    },
    TAKE_CARD_SEAT: {
        key: "womanCardSeat",
        frames: generateFrameNames(GIRL_SHEETS.CARD_ACCEPT.key, 0, 3),
        frameRate: 3
    },
    PLAY_CARDS: {
        key: "womanPlayCards",
        frames: generateFrameNames(GIRL_SHEETS.CARD_ACCEPT.key, 4, 12),
        frameRate: 1
    },
    ANGRY_LOSE_CARD: {
        key: "womanLooseCardGame",
        frames: generateFrameNames(GIRL_SHEETS.CARD_ANGRY.key, 13, 17),
        frameRate: 3
    },
    CONGRAT_CARD: {
        key: "womanCongratNPCCard",
        frames: generateFrameNamesByArray(GIRL_SHEETS.CARD_CONGRAT.key, [
            13, 14, 15, 16, 17, 16, 17, 16, 17
        ]),
        frameRate: 3,
    },
    CARD_ACCEPT: {
        key: "womanCardAccept",
        frames: generateFrameNamesByArray(GIRL_SHEETS.CARD_ACCEPT.key, [
            13, 14, 15, 15, 15, 15, 16, 17, 16, 17, 16, 17
        ]),
        frameRate: 2
    }
}

export const PlayerAnimAssets = [
    ...Object.values(BOY_SHEETS),
    ...Object.values(GIRL_SHEETS)
]

export const PlayerAnimImages = [
    BOY_IDLE,
    GIRL_IDLE,
    BOY_LOOK_UP,
    GIRL_LOOK_UP
]