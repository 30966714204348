import Modal from "./modal";
import { ModalIcons } from "../utils/assetsPaths/modalIcons";

const description = 'Para enviar los resultados y regresar al menú ' +
    'principal, presiona el boton finalizar.'

export default class SessionCompleteModal extends Modal {

    constructor(scene, x, y, score, block) {
        super(scene, x, y, ModalIcons.TIMEOUT_HEADER, 'Fin del juego', description, score, block);
    }
}