import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    ANIMALS: 0,
    PEOPLE: 1,
    FOOD: 2,
    CLOTHES: 3,
    VEHICLES: 4
}

const difficultySettings = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Animales',
            header: 'Categoría de animales',
            description: 'Los estímulos a seleccionar serán fotografías de animales.',
            image: {
                key: 'animalsDifficultySetting',
                url: './ClasifyGameAssets/settings/difficulty/animalCategory.png'
            },
        },
        {
            title: 'Personas',
            header: 'Categoría de personas',
            description: 'Los estímulos a seleccionar serán fotografías de personas.',
            image: {
                key: 'personsDifficultySetting',
                url: './ClasifyGameAssets/settings/difficulty/personCategory.png'
            },
        },
        {
            title: 'Alimentos',
            header: 'Categoría de frutas',
            description: 'Los estímulos a seleccionar serán fotografías de frutas.',
            image: {
                key: 'fruitsDifficultySetting',
                url: './ClasifyGameAssets/settings/difficulty/foodCategory.png'
            },
        },
        {
            title: 'Vestimenta',
            header: 'Categoría de vestimenta',
            description: 'Los estímulos a seleccionar serán fotografías de prendas de vestir.',
            image: {
                key: 'clothesDifficultySetting',
                url: './ClasifyGameAssets/settings/difficulty/clothCategory.png'
            },
        },
        {
            title: 'Vehículos',
            header: 'Categoría de vehículos',
            description: 'Los estímulos a seleccionar serán fotografías de vehículos.',
            image: {
                key: 'vehiclesDifficultySetting',
                url: './ClasifyGameAssets/settings/difficulty/vehicleCategory.png'
            },
        }
    ]
};

export default difficultySettings;

export const DifficultySettingAssets = difficultySettings.options.map(({ image }) => image);
