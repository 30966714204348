import { generateFrameNames, generateFrameNamesByArray } from "../../../functions"

export const AggressiveCoworkerSheets = {
    COWORKER_ANGRY: {
        key: "w3s2CoworkerAngry",
        url: "SocialInclusionAssets/situations/agressiveCoworker/coworkerAngry.png",
        frameWidth: 240 / 3,
        frameHeight: 401 / 3
    },
    COWORKER_LIFT: {
        key: "w3s2CoworkerAngryLifting",
        url: "SocialInclusionAssets/situations/agressiveCoworker/coworkerLifting.png",
        frameWidth: 795 / 5,
        frameHeight: 674 / 5
    },
    MAN_SHOCK_ANGRY: {
        key: "w3s2ManShockAngry",
        url: "SocialInclusionAssets/situations/agressiveCoworker/manShockAngry.png",
        frameWidth: 230 / 3,
        frameHeight: 392 / 3
    },
    MAN_SHOCK_CALL: {
        key: "w3s2ManShockcall",
        url: "SocialInclusionAssets/situations/agressiveCoworker/manShockCall.png",
        frameWidth: 163 / 3,
        frameHeight: 393 / 3
    },
    MAN_SHOCK_REFUSE: {
        key: "w3s2ManShockrefuse",
        url: "SocialInclusionAssets/situations/agressiveCoworker/manShockRefuse.png",
        frameWidth: 156 / 3,
        frameHeight: 393 / 3
    },
    WOMAN_SHOCK_ANGRY: {
        key: "w3s2woManShockAngry",
        url: "SocialInclusionAssets/situations/agressiveCoworker/womanShockAngry.png",
        frameWidth: 291 / 3,
        frameHeight: 407 / 3
    },
    WOMAN_SHOCK_CALL: {
        key: "w3s2woManShockcall",
        url: "SocialInclusionAssets/situations/agressiveCoworker/womanShockCall.png",
        frameWidth: 267 / 3,
        frameHeight: 407 / 3
    },
    WOMAN_SHOCK_REFUSE: {
        key: "w3s2woManShockrefuse",
        url: "SocialInclusionAssets/situations/agressiveCoworker/womanShockRefuse.png",
        frameWidth: 261 / 3,
        frameHeight: 406 / 3
    },
}

export const BossIdle = {
    FRONT: {
        key: "w3s2BossAngryIdle",
        url: "SocialInclusionAssets/situations/agressiveCoworker/bossIdle.png",
    },
    BACK: {
        key: "w3s2BossAngryIdleBack",
        url: "SocialInclusionAssets/situations/agressiveCoworker/bossIdleBack.png",
    }
}

export const BossSheets = {
    ANGRY: {
        key: "w3s2BossAngry",
        url: "SocialInclusionAssets/situations/agressiveCoworker/bossAngry.png",
        frameWidth: 99 / 2,
        frameHeight: 131 / 1
    },
    BACK: {
        key: "w3s2BossAngryWalkBack",
        url: "SocialInclusionAssets/situations/agressiveCoworker/bossWalkBack.png",
        frameWidth: 182 / 3,
        frameHeight: 267 / 2
    },
    FRONT: {
        key: "w3s2BossAngryWalkFront",
        url: "SocialInclusionAssets/situations/agressiveCoworker/bossWalkFront.png",
        frameWidth: 197 / 3,
        frameHeight: 282 / 2
    }
}

export const AggressiveCoworkerAnims = {
    COWORKER_TAKE_BOX: {
        key: "w3s2COWORKERLiftBox",
        frames: generateFrameNames(AggressiveCoworkerSheets.COWORKER_LIFT.key, 0, 7),
        frameRate: 2,
    },
    COWORKER_RETURN_TO_BASE: {
        key: "w3s2COWORKERReturnToBase",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.COWORKER_LIFT.key, [
            8, 9, 0
        ]),
        frameRate: 2,
    },
    COWORKER_ANGRY: {
        key: "w3s2COWORKERAngry",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.COWORKER_ANGRY.key, [
            0,1,2,3,2,3,2,3,2,3,2,3,2,3,4, 
        ]),
        frameRate: 3
    },
    MAN_TALK:{
        key: "w3s2ManGetShoked",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.MAN_SHOCK_ANGRY.key, [
            0,1,2,1,2,1,2,1,2,1,2,1,2
        ]),
        frameRate: 2
    },
    MAN_AGGRESIVE:{
        key: "w3s2ManAngry",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.MAN_SHOCK_ANGRY.key, [
           4,5,6,7,6,7,6,7,6,7,6,7,6,7,6,7
        ]),
        frameRate: 3
    },
    MAN_REFUSE:{
        key: "w3s2ManRefuse",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.MAN_SHOCK_REFUSE.key, [
           4,5,6
        ]),
        frameRate: 1
    },
    WOMAN_AGGRESIVE:{
        key: "w3s2woManAngry",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.WOMAN_SHOCK_ANGRY.key, [
            4,5,6,7,6,7,6,7,6,7,6,7,6,7,6,7
        ]),
        frameRate: 3
    },
    WOMAN_REFUSE:{
        key: "w3s2woManRefuse",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.WOMAN_SHOCK_REFUSE.key, [
           4,5,6
        ]),
        frameRate: 1
    },
    WOMAN_TALK:{
        key: "w3s2woManGetShoked",
        frames: generateFrameNamesByArray(AggressiveCoworkerSheets.WOMAN_SHOCK_ANGRY.key, [
            0,1,2,1,2,1,2,1,2,1,2,1,2
        ]),
        frameRate: 2
    },
}

export const AggressiveCoworkerAssets = {
    Images: Object.values(BossIdle),
    SpriteSheets: [
        ...Object.values(AggressiveCoworkerSheets),
        ...Object.values(BossSheets)
    ]
}