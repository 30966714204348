export const AlertModalStructure = {
    message: {
        text: '',
        style: {
            fontSize: '90px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#FF0000'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 650,
            objectHeight: 350,
            objectPositionX: 0,
            objectPositionY: -70,
        }
    },
    loadImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 210,
            objectHeight: 210,
            objectPositionX: 0,
            objectPositionY: 90,
        }
    },
    againButton:
    {
        text: '',
        nameAsset: 'retryButton',
        style: {
            fontSize: '25px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 325,
            objectHeight: 80,
            objectPositionX: 0,
            objectPositionY: 150,
        }

    },
    retryButton:
    {
        text: '',
        nameAsset: 'sendButton',
        style: {
            fontSize: '25px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 325,
            objectHeight: 80,
            objectPositionX: -230,
            objectPositionY: 150,
        }

    },
    withoutButton:
    {
        text: '',
        nameAsset: 'dontSendButton',
        style: {
            fontSize: '25px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 325,
            objectHeight: 80,
            objectPositionX: 230,
            objectPositionY: 150,
        }

    },
    withoutSubmitButton:
    {
        text: '',
        nameAsset: 'dontSendButton',
        style: {
            fontSize: '25px',
            fontFamily: 'ArialRounded',
            color: '#205067',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 325,
            objectHeight: 80,
            objectPositionX: 0,
            objectPositionY: 150,
        }

    }
}