import Phaser from 'phaser';
import { SceneKey, Cursors } from "../utils/constants";
import Score from "../utils/classes/score";
import { getDifferentRandomItem } from "../utils/functions";
import { wrapResizeFn } from "../utils/resize";
import { createHub, setZoomBtnOnclick, createZoomCamera } from "../utils/gameObjectFactory";
import { AudioKey } from "../utils/assetsPaths/audio";
import { createImgs } from '../utils/imgSelection';
import { FeedbackSettingKey } from '../utils/settingsConstants/feedbackSetting';
import TargetFeedback from '../utils/assetsPaths/targets';

const { BAD_TARGET } = TargetFeedback;
const Image = Phaser.GameObjects.Image;

export default class GameSessionScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.GAME_SESSION });
    }

    create() {
        this.hubHeight = 0
        const { userConfig, data: { sessionData, getHint } } = this.game
        this.userScore = new Score();
        this.sceneTextInstruction = getHint(userConfig);
        createHub(this, this.sceneTextInstruction, sessionData);
        this.gameZone = this.add.zone(0, 0, 100, 100)
            .setOrigin(0, 0)
            .setInteractive(Cursors.pointerout);
        addAudio(this);
        createZoomCamera(this);
        wrapResizeFn(this);
        this.setSceneInteractive();
        //TODO remove ternary on release;
        this.countDown = (this.game.userConfig.time + 1) * 300;
        this.events.on('resume', () => this.hub.quitBtn.emit('pointerout'))
            .on('shutdown', () => this.events.off('resume'));
        this.hub.setDepth(10);
        this.setGameplay();
        this.speakAtStart();
        this.startCountdown();
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.sceneTextInstruction : ' '
        this.game.voiceAssistant.speak(this.helpText);
    }

    stopVoice() {
        this.game.voiceAssistant.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.sceneTextInstruction : ' '
    }
    setSceneInteractive() {
        const { hub, voiceOverBtn, events } = this;
        const { voiceAssistant } = this.game;
        hub.onExit(() => this.showModal(false));
        voiceOverBtn.onclick(
            () => voiceAssistant.speakHelpText(this.helpText));
        setZoomBtnOnclick(this, this.zoomBtn);
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        events.once('shutdown', () => voiceAssistant.cancel());
        events.on('pause', () => voiceAssistant.cancel());
    }

    resizeLandscape(width, height) {
        const hubHeight = height / 7;
        this.hubHeight = hubHeight;
        this.hub.resizeLandscape(width, hubHeight);
        this.gameZone.setY(hubHeight)
            .setDisplaySize(width, height - hubHeight);
    }

    resizePortrait(width, height) {
        const hubHeight = height / 4;
        this.hubHeight = hubHeight;
        this.hub.resizePortrait(width, hubHeight);
        this.gameZone.setY(hubHeight)
            .setDisplaySize(width, height - hubHeight);
    }

    setGameplay() {
    }

    showModal(isSessionComplete) {
        if (this.isZoomActive)
            this.zoomBtn.emit('pointerdown');
        this.game.canvas.style.cursor = 'default';
        this.scene.pause().launch(SceneKey.MODAL, { isSessionComplete, score: this.userScore });
    }

    createFeedbackImage(img, texture) {
        const { x, y } = img.getCenter();
        const { displayWidth, displayHeight } = img;
        const feedbackImg = new Image(this, x, y, texture.key)
            .setDisplaySize(displayWidth, displayHeight);
        this.gameArea.add(feedbackImg);
        this.feedbackImgs.push(feedbackImg);
    }

    setFailFn(feedback) {
        const addFail = () => this.addFail();
        const addFeedbackFail = (img) => {
            this.addFail();
            this.createFeedbackImage(img, BAD_TARGET);
        }
        const areErrorNeeded = feedback === FeedbackSettingKey.ON;
        this.addFailTarget = areErrorNeeded ? addFeedbackFail : addFail;
    }

    createImgsList(quantity, size, texture, drawBorder = true) {
        const { width, height } = this.getAvailableSize();
        this.imgList = createImgs(this, width, height, texture, quantity, size);
        this.gameArea.add(this.imgList)
        this.imgList[0].setTexture(texture.key);
        this.targets = this.imgList.slice(1, this.imgList.length);
        this.imgList.forEach(img => img.setInteractive(Cursors.pointerover));
        this.pattern = this.imgList[0];
        if (drawBorder)
            this.drawPatternBorder(this.imgList[0]);
    }

    drawPatternBorder(pattern) {
        const lineWidth = 10 * window.devicePixelRatio;
        const { displayWidth, displayHeight } = pattern;
        const corner = pattern.getTopLeft();
        const x = corner.x + lineWidth / 2;
        const y = corner.y + lineWidth / 2;
        const width = displayWidth - lineWidth;
        const height = displayHeight - lineWidth;
        const graphics = this.add.graphics({
            x: 0,
            y: this.gameArea.y,
            lineStyle: { color: 0xBF42F5, width: lineWidth }
        })
        graphics.strokeRect(x, y, width, height);
    }

    tickTimer() {
        if (this.countDown === 0)
            this.showModal(true);
        else {
            this.countDown--;
            this.userScore.time++;
        }
    }

    startCountdown() {
        this.time.addEvent({
            delay: 1000,
            callback: this.tickTimer,
            callbackScope: this,
            loop: true
        });
    }

    addHit(playAudio = true) {
        const hitKeys = ['KISS', 'HIT', 'HIT_2', 'HIT_3'];
        this.userScore.addHits();
        if (this.currentSound)
            this.audioProvider[this.currentSound].stop();
        if (playAudio) {
            this.currentSound = getDifferentRandomItem(hitKeys, this.currentSound);
            this.audioProvider[this.currentSound].play();
        }
    }

    addFail() {
        const errorKeys = ['ERROR_SOUND', 'PHASE_JUMP', 'ERROR_SOUND_OUCH']
        this.userScore.addFails();
        if (this.currentSound)
            this.audioProvider[this.currentSound].stop();
        this.currentSound = getDifferentRandomItem(errorKeys, this.currentSound);
        this.audioProvider[this.currentSound].play();

    }

    clearGameArea() {
        this.gameArea.getAll().forEach(child => child.destroy());
    }

    startStandby(callback) {
        this.standbyEvent = this.time.addEvent({
            delay: [5, 10, 15, 20][this.game.userConfig.standby] * 1000,
            callbackScope: this,
            callback: () => {
                this.addFail();
                callback();
            }
        })
    }

    speakInstruction() {
        this.game.voiceAssistant.speakHelpText(this.sceneTextInstruction);
    }

    speak(text) {
        this.game.voiceAssistant.speak(text);
    }

    getHintText() {
        return this.game.data.getHint(this.game.userConfig)
    }

    getAvailableSize() {
        const { canvas: { width, height } } = this.game;
        const hubHeight = this.hub.background.height;
        return {
            width,
            height: height - hubHeight
        };
    }

    enableFailOnMiss() {
        this.gameZone.on('pointerdown', () => this.addFail());
    }
}

const addAudio = (scene) => {
    const { sound } = scene;
    scene.audioProvider = {};
    Object.entries(AudioKey).forEach(
        entry => scene.audioProvider[entry[0]] = sound.add(entry[1].key))
}
