import Phaser from 'phaser';
import { scaleImage } from './resize';
import { getRandomSubArray } from './functions';

const Image = Phaser.GameObjects.Image;

export const createImgs = (scene, width, height, texture, quantity, size) => {
    const targetsNum = [3, 4, 5, 6, 9][quantity];
    const scale = [0.5, 0.65, 0.75, 1][size];
    const { availableSize, positions } = getImgsPositions(width, height, targetsNum);
    const { cellWidth, cellHeight } = availableSize;
    const imgs = positions.map(({ x, y}) => {
        const img = new Image(scene, 0, 0, texture.key)
            .setOrigin(0.5)
            .setX(x + cellWidth / 2)
            .setY(y + cellHeight / 2)
        scaleImage(img, cellWidth, cellHeight, 0, scale)
        return img;
    })
    if (targetsNum === 5) {
        const image = new Image(scene, width / 2, height / 2, texture.key);
        scaleImage(image, cellWidth, cellHeight, 0, scale);
        imgs.push(image);
    }
    return imgs
}

const getImgsPositions = (width, height, length) => {
    const calc = (...args) => calculateImgPositions(width, height, ...args);
    let config;
    switch (length) {
        case 3:
            config = calc(2, 2);
            config.positions.pop();
            return config;
        case 4: return calc(2, 2);
        case 5:
            const regularSize = getImgSize(width, height, 2, 2);
            const fixedSize = getImgSize(width, height, 2, 3);
            const size = {
                cellWidth: fixedSize.cellWidth,
                cellHeight: regularSize.cellHeight,
                padding: fixedSize.padding
            }
            return calc(2, 2, size);
        case 6: return calc(2, 3);
        default: return calc(3, 3);
    }
}

const calculateImgPositions = (width, height, rows, cols, fixedSize) => {
    const positions = [];
    const availableSize = fixedSize || getImgSize(width, height, rows, cols);
    const cellWidth = (width - availableSize.cellWidth) / (cols - 1);
    const cellHeight = (height - availableSize.cellHeight) / (rows - 1);
    for (let i = 0; i < rows; i++)
        for (let j = 0; j < cols; j++)
            positions.push({
                x: j * cellWidth,
                y: i * cellHeight
            });
    return { availableSize, positions }
};

const getImgSize = (width, height, rows, cols) => {
    const cellWidth = width * 0.95 / cols;
    const cellHeight = height * 0.95 / rows;
    const padding = cellWidth > cellHeight ? cellHeight : cellWidth;
    return { cellWidth, cellHeight, padding }
}

export const createRandomNumericArray = (length) => {
    let array = [];
    for (let i = 0; i < length; i++)
        array.push(i);
    return getRandomSubArray(array, length);
};
