import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import { wrapResizeFn } from "../utils/resize";
import {
    createZoomCamera, createBackgroundImage, createAccesibilityBtns,
    createBtn, createHeaderBar, createLargeMessageBox,
    createUserTag, setZoomBtnOnclick
} from "../utils/gameObjectFactory";
import { Icons } from "../utils/assetsPaths/icons";
import { Btns } from "../utils/assetsPaths/btn";

export default class InstructionsScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.INSTRUCTIONS });
    }

    create() {
        this.textDescription = this.game.data.userManual;
        let { sessionData } = this.game.data;
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        this.returnBtn = createBtn(this, 'Regresar', Icons.RETURN, Btns.SECONDARY);
        createHeaderBar(this, 'Descripción del juego');
        createLargeMessageBox(this, this.textDescription);
        createUserTag(this, sessionData);
        this.setBtnsInteractive();
        this.speakAtStart();
        wrapResizeFn(this);
    }

    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.textDescription : ' '
        this.game.voiceAssistant.speak(this.helpText);
    }

    stopVoice() {
        this.game.voiceAssistant.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? this.textDescription : ' '
    }

    setBtnsInteractive() {
        this.returnBtn.setInteractive().onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.voiceOverBtn.onclick(() => this.game.voiceAssistant.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
        this.events.once('shutdown', () => this.game.voiceAssistant.cancel());
    }

    resizeLandscape(width, height) {
        const { background, accesibilityBtns, returnBtn, header, userTag } = this;
        const headerHeight = height / 7;
        const halfWidth = width / 2;
        const eightHeight = height / 8;
        background.setDisplaySize(width, height);
        returnBtn.resize(halfWidth, eightHeight, 50, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 8, eightHeight, 50, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 100, 1);
        this.setLandscapePositions(width, height, halfWidth, headerHeight);
    }

    setLandscapePositions(width, height, halfWidth, headerHeight) {
        const { accesibilityBtns, returnBtn, msgBox, userTag, header } = this;
        const cellWidth = accesibilityBtns[0].background.displayWidth * 1.25;
        const position = Phaser.Display.Align.CENTER
        msgBox.setPosition(halfWidth, height / 2);
        userTag.setPosition(width - userTag.background.displayWidth / 2 - headerHeight / 4, headerHeight / 2);
        header.alignLabelRightPosition(width, userTag.getLeftCenter().x);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })

        returnBtn.setPosition(halfWidth, accesibilityBtns[0].y);
        msgBox.resize(width, (returnBtn.getTopCenter().y - headerHeight), 100, 1);
        msgBox.setPosition(halfWidth, height / 2);
    }

    resizePortrait(width, height) {
        const { background, header, userTag, returnBtn, accesibilityBtns } = this;
        const headerHeight = height / 7.5;
        const halfWidth = width / 2;
        const eightHeight = height / 8;
        background.setDisplaySize(100, height).setScale(background.scaleY, background.scaleY);
        header.resize(width, headerHeight);
        returnBtn.resize(width, eightHeight, 100, 1);
        accesibilityBtns.forEach(btn => btn.resize(halfWidth, eightHeight, 100, 1));
        userTag.resize(width, height / 5, 100, 1);
        this.setPortraitPositions(width, height);
    }

    setPortraitPositions(width, height) {
        const { returnBtn, userTag, header, msgBox, accesibilityBtns } = this;
        const halfWidth = width / 2;
        const { displayHeight, displayWidth } = accesibilityBtns[0].background;
        const cellWidth = displayWidth * 1.5;
        const position = Phaser.Display.Align.CENTER;
        returnBtn.setPosition(halfWidth, height - returnBtn.background.displayHeight * 1.2);
        userTag.setPosition(halfWidth, header.background.height + userTag.background.displayHeight * 0.6);
        msgBox.resize(width, returnBtn.getTopCenter().y - userTag.getBottomCenter().y - displayHeight, 100, 1);
        msgBox.setPosition(halfWidth, (returnBtn.getTopCenter().y + userTag.getBottomCenter().y) / 2 - displayHeight);
        header.alignLabelLeftPosition(width);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, cellWidth, position,
            x: halfWidth - cellWidth / 2,
            y: msgBox.getBottomCenter().y + displayHeight
        })
    }
}
