export const Icons = {
    PLAY: {
        key: 'playIcon',
        url: './HabCogGameAssets/icons/playIcon.png'
    },
    EXIT: {
        key: 'exitIcon',
        url: './HabCogGameAssets/icons/exitIcon.png'
    },
    INSTRUCTIONS: {
        key: 'instructionsIcon',
        url: './HabCogGameAssets/icons/instructionsIcon.png'
    },
    SETTINGS: {
        key: 'settingsIcon',
        url: './HabCogGameAssets/icons/settingsIcon.png'
    },
    VOICE_OVER: {
        key: 'voiceOverIcon',
        url: './HabCogGameAssets/icons/voiceIcon.png'
    },
    ZOOM: {
        key: 'zoomIcon',
        url: './HabCogGameAssets/icons/zoomIcon.png'
    },
    CONTINUE: {
        key: 'continueIcon',
        url: './HabCogGameAssets/icons/continueIcon.png'
    },
    DEFAULT: {
        key: 'defaultValuesIcon',
        url: './HabCogGameAssets/icons/defaultValuesIcon.png'
    },
    RETURN: {
        key: 'returnIcon',
        url: './HabCogGameAssets/icons/returnIcon.png'
    },
    EXIT_SESSION: {
        key: 'exitSessionIcon',
        url: './HabCogGameAssets/icons/exitHubIcon.png'
    },
    SPINNER: {
        key: 'spinnerIcon',
        url: './HabCogGameAssets/icons/spinner.png'
    },
    SEND: {
        key: 'sendIcon',
        url: './HabCogGameAssets/icons/sendIcon.png'
    },
    NO_SEND: {
        key: 'dontSendIcon',
        url: './HabCogGameAssets/icons/dontSendIcon.png'
    },
    ACCEPT: {
        key: 'acceptIcon',
        url: './HabCogGameAssets/icons/acceptIcon.png'
    },
    ATTEMPTS: {
        key: 'attemptsModalIcon',
        url: './HabCogGameAssets/modalIcons/attemptsIcon.png'
    },
    FAILS: {
        key: 'failsModalIcon',
        url: './HabCogGameAssets/modalIcons/failsIcon.png'
    },
    HITS: {
        key: 'hitsModalIcon',
        url: './HabCogGameAssets/modalIcons/hitsIcon.png'
    },
    TIME: {
        key: 'timeModalIcon',
        url: './HabCogGameAssets/modalIcons/timeIcon.png'
    },
    QUIT_HEADER: {
        key: 'quitHeaderModalIcon',
        url: './HabCogGameAssets/modalIcons/quitHeaderIcon.png'
    },
    TIMEOUT_HEADER: {
        key: 'timeoutModalIcon',
        url: './HabCogGameAssets/modalIcons/timeoutHeaderIcon.png'
    },
    HOME: {
        key: 'ludomundoHomeIcon',
        url: 'SocialInclusionAssets/controllers/home.png'
    },
    EXIT_WORLD: {
        key: 'ludomundoExitWorld',
        url: 'SocialInclusionAssets/controllers/exit.png'
    },
    BADGES: {
        key: 'ludomundoBadgesIcon',
        url: 'SocialInclusionAssets/controllers/badges.png'
    },
    WORLD: {
        key: 'ludomundoWorldIcon',
        url: 'SocialInclusionAssets/controllers/world.png'
    },
    BADGES_MENU: {
        key: 'ludomundoBadgesMenuIcon',
        url: 'SocialInclusionAssets/controllers/badgesDefault.png'
    },
    GAME_ICON: {
        key: 'ludomundoGameIcon',
        url: 'SocialInclusionAssets/controllers/gameIcon.png'
    },
    SPEAK_ICON: {
        key: 'speakingIcon',
        url: './HabCogGameAssets/icons/speakingIcon.png'
    },
    STOP_SPEAK_ICON: {
        key: 'stopVoiceIcon',
        url: './HabCogGameAssets/icons/stopVoiceIcon.png'
    }
}

export const IconAssets = Object.values(Icons);