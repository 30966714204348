import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    HAPPY_SAD: 0,
    HAPPY_SAD_ANGRY: 1,
    HAPPY_SAD_ANGRY_SCARED: 2
}

export const ColorScaleSettingKey = {
    COLOR: 0,
    GRAY: 1
}
export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: '2 expresiones',
            header: '2 expresiones faciales',
            description: 'Se presentarán estímulos con expresiones faciales de alegría y tristeza.',
            image: {
                key: 'twoExpressions',
                url: './FacesAndGesturesGameAssets/settings/difficulty/twoExpressions.png'
            },
        },
        {
            title: '3 expresiones',
            header: '3 expresiones faciales',
            description: 'Se presentarán estímulos con expresiones faciales de alegría, tristeza y enfado.',
            image: {
                key: 'threeExpressions',
                url: './FacesAndGesturesGameAssets/settings/difficulty/threeExpressions.png'
            },
        },
        {
            title: '4 expresiones',
            header: '4 expresiones faciales',
            description: 'Se presentarán estímulos con expresiones faciales de alegría, tristeza, enfado y miedo.',
            image: {
                key: 'fourExpressions',
                url: './FacesAndGesturesGameAssets/settings/difficulty/fourExpressions.png'
            },
        }
    ]
};

export const colorScaleSetting = {
    name: 'Contraste',
    key: SettingKey.COLOR,
    icon: PreviewIcons.CONTRAST,
    tileIcon: TileIcons.CONTRAST,
    options: [
        {
            title: 'Color',
            header: 'Todo color',
            description: 'Los estímulos se presentarán a todo color.',
            image: {
                key: 'colorSetting',
                url: './FacesAndGesturesGameAssets/settings/contrast/colorFace.png',
            }
        },
        {
            title: 'Gris',
            header: 'Escala de grises',
            description: 'Los estímulos se presentarán a escala de grises.',
            image: {
                key: 'greyColorSetting',
                url: './FacesAndGesturesGameAssets/settings/contrast/greyFace.png',
            }
        }
    ]
};

export const CustomSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...colorScaleSetting.options.map(({ image }) => image)
]