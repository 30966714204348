import Phaser from 'phaser'

export default class RoundPipeline extends Phaser.Renderer.WebGL.Pipelines.TextureTintPipeline {
    constructor(game) {
        super({
            game: game,
            renderer: game.renderer,
            fragShader: `
                precision mediump float;
                uniform sampler2D uMainSampler;
                varying vec2 outTexCoord;
                void main(void){
                    if (length(outTexCoord.xy - vec2(0.5, 0.5)) > 0.5) {
                        discard;
                    } else {
                        gl_FragColor = texture2D(uMainSampler, outTexCoord);
                    }
                }`
        })
    }
}
