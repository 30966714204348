export const Visor = {
    key: "sixthSituationVisor",
    url: 'SocialInclusionAssets/situations/warmingWeather/visor.png'
}

export const WarmingWeatherSheets = {
    SUN_BODY: {
        key: 'situation6sunBody',
        url: 'SocialInclusionAssets/situations/warmingWeather/sunBody.png',
        frameWidth: 2350 / 4,
        frameHeight: 1600 / 3
    },
    SUN_FACE: {
        key: 'situation6sunFace',
        url: 'SocialInclusionAssets/situations/warmingWeather/sunFaces.png',
        frameWidth: 1076 / 4,
        frameHeight: 770 / 3
    }
}

export const WarmingWeatherAssets = {
    Images: [Visor],
    SpriteSheets: Object.values(WarmingWeatherSheets)
}