import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { scaleImage } from '../utils/resize';
import { GameFont } from '../utils/constants';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
const Container = Phaser.GameObjects.Container;

export default class OptionSelector extends Container {

    constructor(scene, x, y, label) {
        super(scene, x, y);
        this.leftArrow = new Image(scene, 0, 0, Btns.ARROW.key).setFlipX(true).setOrigin(0, 0.5);
        this.rightArrow = new Image(scene, 0, 0, Btns.ARROW.key).setOrigin(1.05, 0.5);
        this.optionLabel = new Text(scene, 0, 0, label, OptionSelectorTextStyle).setOrigin(0.5);
        this.add([
            this.leftArrow,
            this.rightArrow,
            this.optionLabel
        ])
        this.arrows = [this.leftArrow, this.rightArrow];
        this.isAvailable = true;
        this.arrowTweens = [];
    }

    setInteractive() {
        const arrows = [this.leftArrow, this.rightArrow];
        arrows.forEach(arrow => arrow.setInteractive({ useHandCursor: true }))
        this.addArrowTweens();
        return this;
    }

    setArrowVisible(visible) {
        if (visible === ArrowVisible.ONLY_LEFT_ARROW) {
            this.leftArrow.setVisible(true);
            this.rightArrow.setVisible(false);
        } else if (visible === ArrowVisible.ONLY_RIGHT_ARROW) {
            this.leftArrow.setVisible(false);
            this.rightArrow.setVisible(true);
        } else if (visible === ArrowVisible.BOTH_ARROW) {
            this.leftArrow.setVisible(true);
            this.rightArrow.setVisible(true);
        } else {
            this.leftArrow.setVisible(false);
            this.rightArrow.setVisible(false);
        }
    }

    onLeftClick(callback) {
        this.leftArrow.on('pointerdown', callback);
        return this;
    }

    onRightClick(callback) {
        this.rightArrow.on('pointerdown', callback);
        return this;
    }

    updateLabel(text, index) {
        this.isAvailable = false;
        const originX = this.optionLabel.x;
        const leavingTarget = index > 0 ? this.rightArrow : this.leftArrow;
        const enteringTarget = index < 0 ? this.rightArrow : this.leftArrow;
        const targets = this.optionLabel;
        const duration = 125;
        const ease = 'Power3'
        this.scene.tweens.add({
            targets, duration, ease,
            x: leavingTarget.x,
            alpha: { from: 0.75, to: 0.25 },
            onComplete: () => {
                this.optionLabel.setText(text);
                this.scene.tweens.add({
                    targets, duration, ease,
                    x: { from: enteringTarget.x, to: originX },
                    alpha: { from: 0.5, to: 1 },
                    onComplete: () => this.isAvailable = true,
                })
            }
        })
        return this;
    }

    addArrowTweens() {
        this.arrowTweens = [
            this.addArrowTween(this.leftArrow, '-'),
            this.addArrowTween(this.rightArrow, '+')
        ]
    }

    addArrowTween(arrow, direction) {
        return this.scene.tweens.add({
            targets: arrow,
            ease: 'Power3',
            duration: 500,
            yoyo: true,
            repeat: -1,
            x: `${direction}=${arrow.displayWidth / 4}`
        })
    }

    resize(tileBackground) {
        const { displayWidth, displayHeight } = tileBackground;
        scaleImage(this.leftArrow, displayWidth, displayHeight, 0, 1);
        scaleImage(this.rightArrow, displayWidth, displayHeight, 0, 1);
        this.optionLabel.setFontSize(`${tileBackground.displayHeight * 0.35}px`)
            .setX(tileBackground.displayWidth / 4);
        this.arrowTweens.forEach(tween => tween.stop());
        this.leftArrow.setX(0);
        this.rightArrow.setX(tileBackground.getRightCenter().x);
        this.addArrowTweens();
    }
}

const OptionSelectorTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}

export const ArrowVisible = {
    ONLY_LEFT_ARROW: 0,
    ONLY_RIGHT_ARROW: 1,
    BOTH_ARROW: 2,
    NONE: 3
}