import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import SessionCompleteModal from "../gameObjects/modalFinish";
import ModalPause from "../gameObjects/modalPause";
import { wrapResizeFn, isLandscape } from "../utils/resize";
import ConfirmDialog from "../gameObjects/confirmDialog";
import postScore from '../utils/API/functions';
import { Icons } from '../utils/assetsPaths/icons';

export default class ModalScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.MODAL });
    }

    init(data) {
        const { isSessionComplete, score } = data;
        this.isSessionComplete = isSessionComplete;
        this.score = score;
        this.sessionData = this.game.data.sessionData;
    }

    create() {
        this.confirmDialog = undefined;
        this.postAttempts = 0;
        this.createBackground();
        this.createModal();
        wrapResizeFn(this);
    }

    createBackground() {
        this.rectangle = this.add.rectangle(0, 0, 1000, 1000, 0x000000, 0.5).setOrigin(0);
    }

    createModal() {
        const modalClass = this.isSessionComplete ? SessionCompleteModal : ModalPause;
        const blockModal = this.game.data.sessionData.postId ? false : true;
        this.modal = new modalClass(this, 0, 0, this.score, blockModal);
        this.add.existing(this.modal);
        this.startEnterTween();
    }

    createConfirmDialog(labels, callback = Phaser.Utils.NOOP, icon = Icons.ACCEPT) {
        if (this.confirmDialog)
            this.confirmDialog.destroy();
        const { width, height } = this.game.canvas;
        const { title, description, confirmLabel } = labels;
        this.confirmDialog = new ConfirmDialog(this, width / 2, height / 2, title, description)
            .onConfirm(callback, confirmLabel, icon);
        this.resizeConfirmDialog(width, height);
        this.add.existing(this.confirmDialog);
        this.addConfirmDialogTween();
    }

    createFirstAttemptDialog() {
        const { refuseLabel } = SaveResultsFirstTime;
        this.createConfirmDialog(SaveResultsFirstTime,
            () => this.postResult(), Icons.SEND);
        this.confirmDialog.onBtnClick(
            () => this.startMainMenuScene(), refuseLabel, Icons.NO_SEND);
    }

    createPostFailedDialog() {
        const { refuseLabel } = SaveResultsError;
        this.createConfirmDialog(SaveResultsError,
            () => this.postResult(), Icons.SEND);
        if (this.postAttempts >= 3)
            this.confirmDialog.onBtnClick(
                () => this.startMainMenuScene(), refuseLabel, Icons.RETURN);
    }

    createResultPostedDialog() {
        this.createConfirmDialog(ResultsPosted,
            () => this.startMainMenuScene());
    }

    addConfirmDialogTween() {
        this.tweens.add({
            targets: this.confirmDialog,
            scale: { from: 0, to: 1 },
            ease: 'Power3',
            duration: 500
        })
    }

    showLoadingDialog() {
        const { title, description } = SendingResults;
        this.confirmDialog.setLabels(title, description)
            .showLoadingSpinner();
    }

    startEnterTween() {
        const { height } = this.game.canvas;
        this.tweens.add({
            targets: this.modal,
            ease: 'Power3',
            duration: 1250,
            y: { from: - this.modal.background.displayHeight, to: height / 2 },
            onComplete: () => this.setModalInteractive(this.isSessionComplete)
        })
    }

    startLeaveTween() {
        const duration = 750
        const ease = 'Power1';
        const alpha = 0;
        const onStartTweenConfig = { targets: this.rectangle, ease, duration, alpha }
        this.tweens.add({
            targets: this.modal, ease, duration,
            alpha,
            y: 0,
            onStart: () => this.tweens.add(onStartTweenConfig),
            onComplete: () => this.scene.stop(SceneKey.MODAL).resume(SceneKey.GAME_SESSION)
        })
    }

    setModalInteractive(isSessionComplete) {
        const sendResultsToPlatform = () => {
            this.score.comments = this.modal.getComments();
            this.modal.setPosition(-10000, -10000);
            this.modal = undefined;
            this.createFirstAttemptDialog();
            if (this.isSessionComplete)
                this.postResult();
        }
        const postId = this.game.data.sessionData.postId;
        this.modal.setInteractive()
            .onExit(postId ? sendResultsToPlatform :
                () => this.startMainMenuScene());
        if (!isSessionComplete)
            this.modal.onContinue(() => this.startLeaveTween());
    }

    startMainMenuScene() {
        this.scene.stop(SceneKey.GAME_SESSION).start(SceneKey.MAIN_MENU);
    }

    postResult() {
        this.showLoadingDialog();
        const { postId } = this.sessionData;
        const result = this.createPostJSON();
        postScore(postId, result)
            .then(res => this.createResultPostedDialog()
            ).catch(e => {
                this.postAttempts++;
                this.createPostFailedDialog();
            })
    }

    createPostJSON() {
        const { hits, fails, time, comments } = this.score;
        const d = new Date();
        console.log("mes " + d.getMonth())
        return {
            date: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
            success: hits,
            failures: fails,
            time_played: time,
            observation: comments,
            omissions: 0
        }
    }

    resizeConfirmDialog(width, height) {
        if (isLandscape(this))
            this.confirmDialog.resize(width * 0.75, height, 100, 1);
        else
            this.confirmDialog.resize(width, height, 100, 1);
        this.confirmDialog.setPosition(width / 2, height / 2)
    }

    resizeModal(width, height) {
        this.modal.resize(width, height, 150, 1);
        this.modal.setPosition(width / 2, height / 2);
    }

    resizeLandscape(width, height) {
        this.rectangle.setDisplaySize(width, height);
        if (this.confirmDialog)
            this.resizeConfirmDialog(width, height);
        if (this.modal)
            this.resizeModal(width, height);
    }

    resizePortrait(width, height) {
        this.rectangle.setDisplaySize(width, height);
        if (this.confirmDialog)
            this.resizeConfirmDialog(width, height);
        if (this.modal)
            this.resizeModal(width, height);
    }
}

const SaveResultsFirstTime = {
    title: 'Enviar resultados',
    description: '¿Deseas enviar los resultados a la plataforma?',
    confirmLabel: 'Enviar',
    refuseLabel: 'No enviar'
};

const SaveResultsError = {
    title: 'Ocurrió un problema',
    description: 'No fue posible enviar los resultados a la plataforma. ' +
        'Asegúrate de de tener conexión a internet y vuelve a intentarlo.',
    confirmLabel: 'Reintentar',
    refuseLabel: 'No enviar'
}

const SendingResults = {
    title: 'Enviando resultados',
    description: 'Por favor espera.'
}

const ResultsPosted = {
    title: 'Envio exitoso',
    description: 'Los resultados se han guardado correctamente, presiona el ' +
        'botón salir para regresar al menú principal.',
    confirmLabel: 'Salir'
}