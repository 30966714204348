export const StoriesStructure =
{
    nameAsset: '',
    scale: {
        objectWidthRatio: 1,
        objectHeightRatio: 1,
        objectWidth: 325,
        objectHeight: 650,
        objectPositionX: 300,
        objectPositionY: 720,
    }


}
export const InstructionText = {
    nameAsset: '',
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 200,
    objectPositionX: 970,
    objectPositionY: 230,
}
export const RigthButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 130,
    objectHeight: 160,
    objectPositionX: 1810,
    objectPositionY: 600,
}
export const LeftButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 130,
    objectHeight: 160,
    objectPositionX: 100,
    objectPositionY: 600,
}