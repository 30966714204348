import Phaser from "phaser";
import { Btns } from "../utils/assetsPaths/btn";
import { scaleImage } from "../utils/resize";
import { GameFont } from "../utils/constants";
const Text = Phaser.GameObjects.Text;
const Image = Phaser.GameObjects.Image;

export default class QuestionBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.QUESTION_BOX.key);
        this.label = new Text(scene, 0, 0, label, QuestionBoxTextStyle)
            .setOrigin(0.5);
        this.icon = new Image(scene, 0, 0, Btns.SOUND_ICON.key)
            .setOrigin(1, 0)
            .setInteractive({ useHandCursor: true })
            .on("pointerdown", () => scene.game.voice.speakHelpText(label))
            .on("pointerover", ()=>this.addScaleTween(1.25))
            .on("pointerout", ()=>this.addScaleTween(1))
        this.ludo = new Image(scene, 0, 0, Btns.LUDO_ASK.key)
            .setOrigin(1, 0.5);
        this.add([
            this.background,
            this.label,
            this.ludo,
            this.icon
        ]);
    }

    addScaleTween(scale) {
        this.tween && this.tween.stop();
        this.tween = this.scene.tweens.add({
            targets: this.icon,
            scale: this.iconScale * scale,
            ease: "Power3",
            duration: 500
        });
    }

    resizeLabel() {
        const { displayWidth, displayHeight } = this.background;
        this.label.setFontSize(`${displayHeight * 0.225}px`)
            .setWordWrapWidth(displayWidth * 0.9);
        if (this.label.displayHeight > displayHeight * 0.9)
            this.label.setFontSize(`${displayHeight * 0.175}px`)
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.ludo.setScale(this.background.scale)
            .setX(this.background.getLeftCenter().x);
        const pos = this.background.getTopRight();
        this.icon.setScale(this.background.scale)
            .setPosition(pos.x, pos.y);
        this.iconScale = this.icon.scale;
        this.resizeLabel();
        return this;
    }
}

const QuestionBoxTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'center'
}