import { Badges } from '../../utils/assetsPaths/badges';
import Situation from "../../utils/classes/situation";
import { Waffle, GettingHungrySheets } from "../../utils/assetsPaths/situations/home/gettingHungry";
import { addDialogTween, generateFrameNames } from "../../utils/functions";

export default class GettingHungrySituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 652, y: 400 };
        const mcPosition = { x: 910, y: 800 };
        super(scene, markPosition, mcPosition, Badges.COOKIE);
        this.instructionLabel = "Estás en casa y sientes hambre, ¿qué haces?";
        this.optionsData = [{
            label: "Espero que alguien llegue y prepare la comida.",
            onclick: this.runEvent(2500, () => this.doNothing())
        }, {
            label: "Preparo algo sencillo y como.",
            onclick: this.runEvent(2500, () => this.makeFood())
        }, {
            label: "Busco algo para comer. ",
            onclick: this.runEvent(2500, ()=>this.getSnack())
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(PlayerAnims).forEach(anim => anims.create(anim));
    }

    create() {
        super.create();
        const { scaleX, scaleY, y } = this.scene.background;
        this.waffle = this.scene.add.image(0, 0, Waffle.key)
            .setScale(this.scene.mc.scale)
            .setPosition(860 * scaleX, 623 * scaleY + y)
        return this;
    }

    doNothing() {
        const anim = this.mcIsBoy ? PlayerAnims.MAN_DO_NOTHING : PlayerAnims.WOMAN_DO_NOTHING;
        this.scene.mc.play(anim.key)
            .once("animationcomplete", this.runEvent(2000,
                () => addDialogTween(this.scene, "Te quedaste con hambre")))
    }

    makeFood() {
        const anim = this.mcIsBoy ? PlayerAnims.MAN_COOK : PlayerAnims.WOMAN_COOK;
        this.scene.mc.setFlipX(false).play(anim.key)
            .once("animationcomplete", this.runEvent(2000,
                () => addDialogTween(this.scene, "Te quedaste sin hambre", this.badge)))
    }

    getSnack() {
        const anim = this.mcIsBoy ? PlayerAnims.MAN_GET_SNACK : PlayerAnims.WOMAN_GET_SNACK;
        this.scene.mc.setFlipX(false).play(anim.key)
            .once("animationcomplete", this.runEvent(2000,
                () => addDialogTween(this.scene, "Te quedaste sin hambre", this.badge)))
    }

    reboot(onComplete){
       this.scene.follower.emit("onMovementComplete");
        onComplete();
    }

    start() {
        const anim = this.mcIsBoy? PlayerAnims.MAN_HUNGRY: PlayerAnims.WOMAN_HUNGRY;
        this.scene.mc.setFlipX(true)

        this.addEvent(2000, ()=>this.scene.mc.play(anim.key)
        .once("animationcomplete", this.runEvent(2000, () => this.showHint())))
    }
}

const PlayerAnims = {
    MAN_HUNGRY: {
        key: "Manishungry",
        frames: generateFrameNames(GettingHungrySheets.MAN_REFUSE.key, 1, 7),
        repeat:3,
        frameRate: 3
    },
    MAN_DO_NOTHING: {
        key: "ManRefuseCook",
        frames: generateFrameNames(GettingHungrySheets.MAN_REFUSE.key, 8, 25),
        frameRate: 3
    },
    MAN_GET_SNACK: {
        key: "ManGetSnack",
        frames: generateFrameNames(GettingHungrySheets.MAN_GET_SNACK.key, 8, 17),
        frameRate: 2
    },
    MAN_COOK: {
        key: "ManCookFood",
        frames: generateFrameNames(GettingHungrySheets.MAN_COOK.key, 8, 27),
        frameRate: 2
    },
    WOMAN_HUNGRY: {
        key: "WoManishungry",
        frames: generateFrameNames(GettingHungrySheets.WOMAN_REFUSE.key, 1, 6),
        frameRate: 3,
        repeat:3,
    },
    WOMAN_DO_NOTHING: {
        key: "WomanRefuseCook",
        frames: generateFrameNames(GettingHungrySheets.WOMAN_REFUSE.key, 6, 21),
        frameRate: 3
    },
    WOMAN_GET_SNACK: {
        key: "womanGetSnack",
        frames: generateFrameNames(GettingHungrySheets.WOMAN_GET_SNACK.key, 8, 17),
        frameRate: 2
    },
    WOMAN_COOK: {
        key: "womanCookFood",
        frames: generateFrameNames(GettingHungrySheets.WOMAN_COOK.key, 8, 27),
        frameRate: 2
    }

}