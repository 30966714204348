export const FallingPersonSheets = {
    SKATER:{
        key: 'situation7skater',
        url: 'SocialInclusionAssets/situations/fallingPerson/skaterMove.png',
        frameWidth: 859 / 5,
        frameHeight: 641 / 5
    },
    MAN_HELP:{
        key: 'situation7manHelpSkater',
        url: 'SocialInclusionAssets/situations/fallingPerson/manHelpSkater.png',
        frameWidth: 462 / 3,
        frameHeight: 422 / 3
    },
    WOMAN_HELP:{
        key: 'situation7womanHelpSkater',
        url: 'SocialInclusionAssets/situations/fallingPerson/womanHelpSkater.png',
        frameWidth: 447 / 3,
        frameHeight: 423 / 3
    },
    RANDOM_HELP:{
        key: 'situation7randomHelpSkater',
        url: 'SocialInclusionAssets/situations/fallingPerson/randomHelpSkater.png',
        frameWidth: 462 / 3,
        frameHeight: 433 / 3
    },
    RANDOM_IDLE:{
        key: 'situation7randomIdle',
        url: 'SocialInclusionAssets/situations/fallingPerson/random7Idle.png',
        frameWidth: 92 / 2,
        frameHeight: 131
    },
    RANDOM_WALK_UP:{
        key: 'situation7randomWalkUp',
        url: 'SocialInclusionAssets/situations/fallingPerson/random7WalkUp.png',
        frameWidth: 252 / 4,
        frameHeight: 431 / 3
    },
    RANDOM_WALK_DOWN:{
        key: 'situation7randomWalkDown',
        url: 'SocialInclusionAssets/situations/fallingPerson/random7WalkDown.png',
        frameWidth: 281 / 4,
        frameHeight: 442 / 3
    }
}

export const FallingPersonAssets = {
    Images: [],
    SpriteSheets: Object.values(FallingPersonSheets)
}