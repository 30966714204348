import { SceneKey } from "../constants"

export const Worlds = {
    CITY: {
        key: SceneKey.CITY_WORLD,
        name: 'Ciudad',
        thumbnail: {
            key: 'cityThumbnail',
            url: 'SocialInclusionAssets/worlds/thumbnails/cityThumbnail.png'
        },
        background: {
            key: 'cityMapBackground',
            url: 'SocialInclusionAssets/worlds/backgrounds/cityBackground.png'
        },
        tilemap: {
            key: 'cityTilemap',
            url: 'SocialInclusionAssets/worlds/tilemaps/cityTilemap.json'
        }
    },
    HOME: {
        key: SceneKey.HOME_WORLD,
        name: 'Hogar',
        thumbnail: {
            key: 'homeThumbnail',
            url: 'SocialInclusionAssets/worlds/thumbnails/homeThumbnail.png'
        },
        background: {
            key: 'homeMapBackground',
            url: 'SocialInclusionAssets/worlds/backgrounds/homeBackground.png'
        },
        tilemap: {
            key: 'homeTilemap',
            url: 'SocialInclusionAssets/worlds/tilemaps/homeTilemap.json'
        }
    },
    JOB: {
        key: SceneKey.JOB_WORLD,
        name: 'Trabajo',
        thumbnail: {
            key: 'jobThumbnail',
            url: 'SocialInclusionAssets/worlds/thumbnails/jobThumbnail.png'
        },
        background: {
            key: 'jobMapBackground',
            url: 'SocialInclusionAssets/worlds/backgrounds/workBackground.png'
        },
        tilemap: {
            key: 'jobTilemap',
            url: 'SocialInclusionAssets/worlds/tilemaps/workTilemap.json'
        }
    }
}

export const HomeHander = {
    key: "wHomeHandler",
    url: "SocialInclusionAssets/worlds/backgrounds/homeHands.png"
}

export const WorldsAssets = [
    Worlds.CITY.thumbnail,
    Worlds.HOME.thumbnail,
    Worlds.JOB.thumbnail,
    Worlds.CITY.background,
    Worlds.HOME.background,
    Worlds.JOB.background,
    HomeHander
]

export const WorldJsons = [
    Worlds.CITY.tilemap,
    Worlds.HOME.tilemap,
    Worlds.JOB.tilemap
]