import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import AnimalsPhotos from "../../HabCogGame/utils/assetsPaths/photos/animals";
import PeoplePhotos from "../../HabCogGame/utils/assetsPaths/photos/people";
import CarbsPhotos from "../../HabCogGame/utils/assetsPaths/photos/carbs";
import DairiesPhotos from "../../HabCogGame/utils/assetsPaths/photos/dairies";
import FruitsPhotos from "../../HabCogGame/utils/assetsPaths/photos/fruits";
import GrainsPhotos from "../../HabCogGame/utils/assetsPaths/photos/grains";
import VegetablesPhotos from "../../HabCogGame/utils/assetsPaths/photos/vegetables";
import ClothesPhotos from "../../HabCogGame/utils/assetsPaths/photos/clothes";
import VehiclesPhotos from "../../HabCogGame/utils/assetsPaths/photos/vehicles";
import { hintAssets } from "../utils/assets";
import { getRandomSubArray } from "../../HabCogGame/utils/functions";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { DifficultySettingKey } from '../utils/settings';
import { createRandomNumericArray } from '../../HabCogGame/utils/imgSelection';
const { GOOD_TARGET } = TargetFeedback;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.setSessionTextures(difficulty);
        this.createImgsList(quantity, size, this.hint);
        this.setFailFn(feedback);
        this.fillDistractorsIndexes();
        this.fillTargetIndexes();
        this.startGameplay();
    }

    setSessionTextures(difficulty) {
        const { hint, sTextures, distractors } = getTextures(difficulty);
        this.hint = hint;
        this.sTextures = sTextures;
        this.distractors = distractors;
    }

    getNextDTexture() {
        let index = this.distractorIndexes.pop();
        if (index === undefined) {
            this.fillDistractorsIndexes();
            index = this.distractorIndexes.pop();
        }
        return this.distractors[index];
    }

    getNextHTexture() {
        let index = this.hitIndexes.pop();
        if (index === undefined) {
            this.fillTargetIndexes();
            index = this.hitIndexes.pop();
        }
        return this.sTextures[index];
    }

    fillDistractorsIndexes() {
        const length = this.distractors.length;
        this.distractorIndexes = createRandomNumericArray(length);
    }

    fillTargetIndexes() {
        const length = this.sTextures.length;
        this.hitIndexes = createRandomNumericArray(length);
    }


    setTargetsTexture() {
        const length = this.targets.length;
        const targetLength = Math.floor(length / 2);
        this.targets.forEach(img => img.setTexture(this.getNextDTexture().key));
        return getRandomSubArray(this.targets, targetLength)
            .map(img =>
                img.setTexture(this.getNextHTexture().key));
    }

    setTargetsInteractive(hitTargets) {
        const length = hitTargets.length;
        this.targets.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTargets.forEach(img => img
            .off('pointerdown')
            .once('pointerdown', () => this.addHit(img, length)));
    }

    addHit(img, length) {
        super.addHit();
        this.targetsHitted++;
        this.createFeedbackImage(img, GOOD_TARGET);
        if (this.targetsHitted === length)
            this.restartGameplay();
    }

    restartGameplay() {
        this.targets.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }

    startGameplay() {
        this.feedbackImgs = [];
        this.targetsHitted = 0;
        const hitTargets = this.setTargetsTexture();
        this.setTargetsInteractive(hitTargets);
    }
}

const getTextures = (difficulty) => {
    switch (difficulty) {
        case DifficultySettingKey.ANIMALS:
            return {
                hint: hintAssets.ANIMAL,
                sTextures: AnimalsPhotos,
                distractors: animalDistractors
            }
        case DifficultySettingKey.PEOPLE:
            return {
                hint: hintAssets.PERSON,
                sTextures: PeoplePhotos,
                distractors: peopleDistractors
            }
        case DifficultySettingKey.FOOD:
            return {
                hint: hintAssets.FOOD,
                sTextures: FoodPhotos,
                distractors: foodDistractors
            }
        case DifficultySettingKey.CLOTHES:
            return {
                hint: hintAssets.CLOTH,
                sTextures: ClothesPhotos,
                distractors: clothDistractors
            };
        case DifficultySettingKey.VEHICLES:
            return {
                hint: hintAssets.VEHICLE,
                sTextures: VehiclesPhotos,
                distractors: vehicleDistractors
            };
        default:
            return console.log('undefined')
    }
}

const FoodPhotos = [
    ...CarbsPhotos,
    ...DairiesPhotos,
    ...FruitsPhotos,
    ...GrainsPhotos,
    ...VegetablesPhotos
]

const animalDistractors = [
    ...FoodPhotos,
    ...ClothesPhotos,
    ...PeoplePhotos,
    ...VehiclesPhotos
];

const peopleDistractors = [
    ...FoodPhotos,
    ...AnimalsPhotos,
    ...ClothesPhotos,
    ...VehiclesPhotos
];

const foodDistractors = [
    ...AnimalsPhotos,
    ...ClothesPhotos,
    ...PeoplePhotos,
    ...VehiclesPhotos
];

const clothDistractors = [
    ...FoodPhotos,
    ...AnimalsPhotos,
    ...PeoplePhotos,
    ...VehiclesPhotos
];

const vehicleDistractors = [
    ...FoodPhotos,
    ...AnimalsPhotos,
    ...ClothesPhotos,
    ...PeoplePhotos
];
