import { CustomSettingsAssets } from "./settings";
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { FeedbackSettingAssets } from "../../HabCogGame/utils/settingsConstants/feedbackSetting";
import { ContourPhotosAssets } from "../../HabCogGame/utils/assetsPaths/photos/contour";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import {TimeSettingAssets} from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const LightsAndShadowsGameAssets = [
    ...TargetFeedbackAssets,
    ...ContourPhotosAssets,
    ...CustomSettingsAssets,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...FeedbackSettingAssets,
    ...TimeSettingAssets
];
