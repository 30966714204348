export const Badges = {
    FIRE: {
        key: 'fireBadge',
        url: 'SocialInclusionAssets/worlds/badges/fireBadge.png'
    },
    MONEY: {
        key: 'moneyBadge',
        url: 'SocialInclusionAssets/worlds/badges/moneyBadge.png'
    },
    SUN: {
        key: 'sunBadge',
        url: 'SocialInclusionAssets/worlds/badges/sunBadge.png'
    },
    UMBRELLA: {
        key: 'umbrellaBadge',
        url: 'SocialInclusionAssets/worlds/badges/umbrellaBadge.png'
    },
    SEAT_BELT: {
        key: 'seatBeltBadge',
        url: 'SocialInclusionAssets/worlds/badges/seatBeltBadge.png'
    },
    TRAFFIC_LIGHTS: {
        key: 'trafficLightsBadge',
        url: 'SocialInclusionAssets/worlds/badges/trafficLightsBadge.png'
    },
    POLICE: {
        key: 'policeBadge',
        url: 'SocialInclusionAssets/worlds/badges/policeBadge.png'
    },
    HEALTH: {
        key: 'healthBadge',
        url: 'SocialInclusionAssets/worlds/badges/healthBadge.png'
    },

    MEDIC: {
        key: 'medicBadge',
        url: 'SocialInclusionAssets/worlds/badges/medicBadge.png'
    },
    FORK: {
        key: 'forkBadge',
        url: 'SocialInclusionAssets/worlds/badges/forkBadge.png'
    },
    COOKIE: {
        key: 'cookieBadge',
        url: 'SocialInclusionAssets/worlds/badges/cookieBadge.png'
    },
    GAME: {
        key: 'gameBadge',
        url: 'SocialInclusionAssets/worlds/badges/gameBadge.png'
    },
    BOOK:{
        key: 'bookBadge',
        url: 'SocialInclusionAssets/worlds/badges/bookBadge.png'
    },
    SCARF: {
        key: 'scarfBadge',
        url: 'SocialInclusionAssets/worlds/badges/scarfBadge.png'
    },
    GIFT:{
        key: 'giftBadge',
        url: 'SocialInclusionAssets/worlds/badges/giftBadge.png'
    },

    FACE:{
        key: 'faceBadge',
        url: 'SocialInclusionAssets/worlds/badges/faceBadge.png'
    },
    SWORD:{
        key: 'swordBadge',
        url: 'SocialInclusionAssets/worlds/badges/swordBadge.png'
    },
    COIN:{
        key: 'coinBadge',
        url: 'SocialInclusionAssets/worlds/badges/coinBadge.png'
    },
    SIGN:{
        key: 'signBadge',
        url: 'SocialInclusionAssets/worlds/badges/signBadge.png'
    },
    CAR:{
        key: 'carBadge',
        url: 'SocialInclusionAssets/worlds/badges/carBadge.png'
    },
    STAR:{
        key: 'starBadge',
        url: 'SocialInclusionAssets/worlds/badges/starBadge.png'
    },
    AIR:{
        key: 'airBadge',
        url: 'SocialInclusionAssets/worlds/badges/airBadge.png'
    },
    ICE:{
        key: 'iceBadge',
        url: 'SocialInclusionAssets/worlds/badges/iceBadge.png'
    }
}

export const GrayBadges = {
    FIRE: {
        key: 'fireBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/fireBadgeGray.png'
    },
    MONEY: {
        key: 'moneyBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/moneyBadgeGray.png'
    },
    SUN: {
        key: 'sunBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/sunBadgeGray.png'
    },
    UMBRELLA: {
        key: 'umbrellaBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/umbrellaBadgeGray.png'
    },
    SEAT_BELT: {
        key: 'seatBeltBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/seatBeltBadgeGray.png'
    },
    TRAFFIC_LIGHTS: {
        key: 'trafficLightsBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/trafficLightsBadgeGray.png'
    },
    POLICE: {
        key: 'policeBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/policeBadgeGray.png'
    },
    HEALTH: {
        key: 'healthBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/healthBadgeGray.png'
    },

    MEDIC: {
        key: 'medicBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/medicBadgeGray.png'
    },
    FORK: {
        key: 'forkBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/forkBadgeGray.png'
    },
    COOKIE: {
        key: 'cookieBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/cookieBadgeGray.png'
    },
    GAME: {
        key: 'gameBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/gameBadgeGray.png'
    },
    BOOK:{
        key: 'bookBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/bookBadgeGray.png'
    },
    SCARF: {
        key: 'scarfBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/scarfBadgeGray.png'
    },
    GIFT:{
        key: 'giftBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/giftBadgeGray.png'
    },

    FACE:{
        key: 'faceBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/faceBadgeGray.png'
    },
    SWORD:{
        key: 'swordBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/swordBadgeGray.png'
    },
    COIN:{
        key: 'coinBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/coinBadgeGray.png'
    },
    SIGN:{
        key: 'signBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/signBadgeGray.png'
    },
    CAR:{
        key: 'carBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/carBadgeGray.png'
    },
    STAR:{
        key: 'starBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/starBadgeGray.png'
    },
    AIR:{
        key: 'airBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/airBadgeGray.png'
    },
    ICE:{
        key: 'iceBadgeGray',
        url: 'SocialInclusionAssets/worlds/badges/iceBadgeGray.png'
    }

}

export const BadgeAssets = [...Object.values(Badges), ...Object.values(GrayBadges)];