import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    ROTATE: 0,
    UNSORT: 1,
    ROTATE_AND_UNSORT: 2
}

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Giros',
            header: 'Cambio de rotación',
            description: 'Las piezas del rompecabezas presentarán ' +
                'rotaciones, pero estarán en ' +
                'su posición original.',
            image: {
                key: 'rotationShift',
                url: './PuzzleGameAssets/settings/difficulty/rotationShift.png'
            }
        },
        {
            title: 'Posiciones',
            header: 'Cambios de posición',
            description: 'Las piezas del rompecabezas no presentarán ' +
                'rotaciones, pero estarán cambiadas de posición.',
            image: {
                key: 'positionShift',
                url: './PuzzleGameAssets/settings/difficulty/positionShift.png'
            }
        },
        {
            title: 'Doble',
            header: 'Rotación y posición',
            description: 'Las piezas del rompecabezas presentarán ' +
                'rotaciones de  y estarán cambiadas de posición.',
            image: {
                key: 'dualShift',
                url: './PuzzleGameAssets/settings/difficulty/dualShift.png'
            }
        }
    ]
};

export default difficultySetting;

export const DifficultySettingAssets = difficultySetting.options
    .map(({ image }) => image);
