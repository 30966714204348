import phaser from 'phaser'
import { ScaleFunctionText } from '../../Functions/ScaleFunctions'
import { mouseOverAnimation } from '../../Functions/AnimatedFunctions'



export default class buttonComponent extends phaser.GameObjects.Container {


    constructor(scene, buttonComponentStructure, outline) {
        super(scene, buttonComponentStructure.scale.objectPositionX, buttonComponentStructure.scale.objectPositionY)
        this.background = null
        this.title = null
        this.generated(scene, buttonComponentStructure, outline)
    }

    generated(scene, buttonComponentStructure, outline) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, buttonComponentStructure.nameAsset)
        this.background.setDisplaySize(buttonComponentStructure.scale.objectWidth, buttonComponentStructure.scale.objectHeight);

        // outline
        var xScale = 1.2
        if (outline === 'buttonOutline')
            xScale = 1.05
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, outline).setVisible(false)
        this.outline.setDisplaySize(buttonComponentStructure.scale.objectWidth * xScale, buttonComponentStructure.scale.objectHeight * 1.2);

        //Title
        const oldFontSize = buttonComponentStructure.style.fontSize;
        buttonComponentStructure.style.fontSize = ScaleFunctionText(this.scene.sys.canvas.width, this.scene.sys.canvas.height, buttonComponentStructure.style.fontSize)
        this.title = new phaser.GameObjects.Text(this.scene, 0, 15, buttonComponentStructure.text, buttonComponentStructure.style)
        this.title.setWordWrapWidth(buttonComponentStructure.scale.objectWidth * 2);
        this.title.setOrigin(0.5, -0.2)


        this.on('pointerover', () => {
            mouseOverAnimation(scene, this, 1.05)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimation(scene, this, 1)
            this.outline.setVisible(false)
        })

        this.add([this.outline, this.background, this.title])
        this.setSize(buttonComponentStructure.scale.objectWidth, buttonComponentStructure.scale.objectHeight)
        buttonComponentStructure.style.fontSize = oldFontSize;

    }
    setTexture(texture) {
        this.background.setTexture(texture)
    }

}