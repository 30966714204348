import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    PHOTOS: 0,
    GEOMS:1
}

export const OrientationSettingKey = {
    LANDSCAPE: 0,
    PORTRAIT: 1
}

export const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Fotografías',
            header: 'Fotografías simétricas',
            description: 'Los estímulos serán fotografías.',
            image: {
                key: 'photographs',
                url: './MirrorReflectionGameAssets/settings/difficulty/photograph.png'
            }
        },
        {
            title: 'Formas 3D',
            header: 'Formas 3D simétricas',
            description: 'Los estímulos serán formas 3D.',
            image: {
                key: 'threedShapesoption',
                url: './MirrorReflectionGameAssets/settings/difficulty/threeDforms.png'
            }
        }
    ]
};

export const orientationSetting = {
    key: SettingKey.ORIENTATION,
    name: 'Orientación',
    icon: PreviewIcons.ORIENTATION,
    tileIcon: TileIcons.ORIENTATION,
    options: [
        {
            title: 'Vertical',
            header: 'División vertical',
            description: 'La pantalla se dividirá verticalmente.',
            image: {
                key: 'verticalSplitOption',
                url: './MirrorReflectionGameAssets/settings/orientation/verticalSplit.png'
            }
        },
        {
            title: 'Horizontal',
            header: 'División horizontal',
            description: 'La pantalla se dividirá horizontalmente.',
            image: {
                key: 'horizontalSplitOption',
                url: './MirrorReflectionGameAssets/settings/orientation/horizontalSplit.png'
            }
        }
    ]
};

export const buttonsSetting = {
    key: SettingKey.BUTTONS,
    name: 'Botones',
    icon: PreviewIcons.BUTTONS,
    tileIcon: TileIcons.BUTTONS,
    options: [
        {
            title: 'No',
            header: 'Sin botones',
            description: 'No se presentará ningún botón, para girar o voltear el estímulo es necesario tocar' +
                'sobre el mismo.',
            image: {
                key: 'noButtons',
                url: './MirrorReflectionGameAssets/settings/buttons/hideButtons.png'
            }
        },
        {
            title: 'Sí',
            header: 'Con botones',
            description: 'Se presentarán botones para girar y voltear el estímulo.',
            image: {
                key: 'showButtons',
                url: './MirrorReflectionGameAssets/settings/buttons/showButtons.png'
            }
        }
    ]
};

export const CustomSettingsAssets = [
    ...difficultySetting.options.map(({ image }) => image),
    ...orientationSetting.options.map(({ image }) => image),
    ...buttonsSetting.options.map(({ image }) => image)
]
