export const TileIcons = {
    DIFFICULTY: {
        key: 'difficultyTileIcon',
        url: './HabCogGameAssets/tiles/difficultTileIcon.png'
    },
    SIZE: {
        key: 'sizeTileIcon',
        url: './HabCogGameAssets/tiles/sizeTileIcon.png'
    },
    TIME: {
        key: 'timeTileIcon',
        url: './HabCogGameAssets/tiles/timeTileIcon.png'
    },
    NA: {
        key: 'naTileIcon',
        url: './HabCogGameAssets/tiles/naTileIcon.png'
    },
    QUANTITY: {
        key: 'quantityTileIcon',
        url: './HabCogGameAssets/tiles/quantityTileIcon.png'
    },
    COLOR: {
        key: 'colorTileIcon',
        url: './HabCogGameAssets/tiles/colorTileIcon.png'
    },
    SHAPE: {
        key: 'shapeTileIcon',
        url: './HabCogGameAssets/tiles/shapeTileIcon.png'
    },
    OUTLINE: {
        key: 'outlineTileIcon',
        url: './HabCogGameAssets/tiles/outlineTileIcon.png'
    },
    PLOT: {
        key: 'plotTileIcon',
        url: './HabCogGameAssets/tiles/plotTileIcon.png'
    },
    FEEDBACK: {
        key: 'feedbackTileIcon',
        url: './HabCogGameAssets/tiles/feedbackTileIcon.png'
    },
    PRESENTATION: {
        key: 'presentationTileIcon',
        url: './HabCogGameAssets/tiles/presentationTileIcon.png'
    },
    ROTATE: {
        key: 'rotateTileIcon',
        url: './HabCogGameAssets/tiles/rotateTileIcon.png'
    },
    ORIENTATION: {
        key: 'orientationTileIcon',
        url: './HabCogGameAssets/tiles/orientationTileIcon.png'
    },
    BUTTONS: {
        key: 'buttonsTileIcon',
        url: './HabCogGameAssets/tiles/buttonsTileIcon.png'
    },
    MODEL: {
        key: 'ModelTileIcon',
        url: './HabCogGameAssets/tiles/modelTileIcon.png'
    },
    TIMEOUT: {
        key: 'TimeoutTileIcon',
        url: './HabCogGameAssets/tiles/timeoutTileIcon.png'
    },
    CONTRAST: {
        key: 'contrastTileIcon',
        url: './HabCogGameAssets/tiles/contrastTileIcon.png'
    }
}

export const TileIconsAssets = Object.values(TileIcons);