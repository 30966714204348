import { CustomSettingsAssets } from "./settings";
import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const hintAssets = {
    HAPPY: {
        key: 'happyHint',
        url: './FacesAndGesturesGameAssets/hint/happyHint.png'
    },
    SAD: {
        key: 'sadHint',
        url: './FacesAndGesturesGameAssets/hint/sadHint.png'
    },
    ANGRY: {
        key: 'angryHint',
        url: './FacesAndGesturesGameAssets/hint/angryHint.png'
    },
    SCARED: {
        key: 'scaredHint',
        url: './FacesAndGesturesGameAssets/hint/scaredHint.png'
    }
};

export const faces = [
    {
        HAPPY: {
            key: 'person1Happy',
            url: './FacesAndGesturesGameAssets/faces/person1Happy.png'
        },
        SAD: {
            key: 'person1Sad',
            url: './FacesAndGesturesGameAssets/faces/person1Sad.png'
        },
        ANGRY: {
            key: 'person1Angry',
            url: './FacesAndGesturesGameAssets/faces/person1Angry.png'
        },
        SCARED: {
            key: 'person1Scared',
            url: './FacesAndGesturesGameAssets/faces/person1Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person2Happy',
            url: './FacesAndGesturesGameAssets/faces/person2Happy.png'
        },
        SAD: {
            key: 'person2Sad',
            url: './FacesAndGesturesGameAssets/faces/person2Sad.png'
        },
        ANGRY: {
            key: 'person2Angry',
            url: './FacesAndGesturesGameAssets/faces/person2Angry.png'
        },
        SCARED: {
            key: 'person2Scared',
            url: './FacesAndGesturesGameAssets/faces/person2Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person3Happy',
            url: './FacesAndGesturesGameAssets/faces/person3Happy.png'
        },
        SAD: {
            key: 'person3Sad',
            url: './FacesAndGesturesGameAssets/faces/person3Sad.png'
        },
        ANGRY: {
            key: 'person3Angry',
            url: './FacesAndGesturesGameAssets/faces/person3Angry.png'
        },
        SCARED: {
            key: 'person3Scared',
            url: './FacesAndGesturesGameAssets/faces/person3Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person4Happy',
            url: './FacesAndGesturesGameAssets/faces/person4Happy.png'
        },
        SAD: {
            key: 'person4Sad',
            url: './FacesAndGesturesGameAssets/faces/person4Sad.png'
        },
        ANGRY: {
            key: 'person4Angry',
            url: './FacesAndGesturesGameAssets/faces/person4Angry.png'
        },
        SCARED: {
            key: 'person4Scared',
            url: './FacesAndGesturesGameAssets/faces/person4Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person5Happy',
            url: './FacesAndGesturesGameAssets/faces/person5Happy.png'
        },
        SAD: {
            key: 'person5Sad',
            url: './FacesAndGesturesGameAssets/faces/person5Sad.png'
        },
        ANGRY: {
            key: 'person5Angry',
            url: './FacesAndGesturesGameAssets/faces/person5Angry.png'
        },
        SCARED: {
            key: 'person5Scared',
            url: './FacesAndGesturesGameAssets/faces/person5Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person6Happy',
            url: './FacesAndGesturesGameAssets/faces/person6Happy.png'
        },
        SAD: {
            key: 'person6Sad',
            url: './FacesAndGesturesGameAssets/faces/person6Sad.png'
        },
        ANGRY: {
            key: 'person6Angry',
            url: './FacesAndGesturesGameAssets/faces/person6Angry.png'
        },
        SCARED: {
            key: 'person6Scared',
            url: './FacesAndGesturesGameAssets/faces/person6Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person7Happy',
            url: './FacesAndGesturesGameAssets/faces/person7Happy.png'
        },
        SAD: {
            key: 'person7Sad',
            url: './FacesAndGesturesGameAssets/faces/person7Sad.png'
        },
        ANGRY: {
            key: 'person7Angry',
            url: './FacesAndGesturesGameAssets/faces/person7Angry.png'
        },
        SCARED: {
            key: 'person7Scared',
            url: './FacesAndGesturesGameAssets/faces/person7Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person8Happy',
            url: './FacesAndGesturesGameAssets/faces/person8Happy.png'
        },
        SAD: {
            key: 'person8Sad',
            url: './FacesAndGesturesGameAssets/faces/person8Sad.png'
        },
        ANGRY: {
            key: 'person8Angry',
            url: './FacesAndGesturesGameAssets/faces/person8Angry.png'
        },
        SCARED: {
            key: 'person8Scared',
            url: './FacesAndGesturesGameAssets/faces/person8Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person9Happy',
            url: './FacesAndGesturesGameAssets/faces/person9Happy.png'
        },
        SAD: {
            key: 'person9Sad',
            url: './FacesAndGesturesGameAssets/faces/person9Sad.png'
        },
        ANGRY: {
            key: 'person9Angry',
            url: './FacesAndGesturesGameAssets/faces/person9Angry.png'
        },
        SCARED: {
            key: 'person9Scared',
            url: './FacesAndGesturesGameAssets/faces/person9Scared.png'
        }
    }, {
        HAPPY: {
            key: 'person10Happy',
            url: './FacesAndGesturesGameAssets/faces/person10Happy.png'
        },
        SAD: {
            key: 'person10Sad',
            url: './FacesAndGesturesGameAssets/faces/person10Sad.png'
        },
        ANGRY: {
            key: 'person10Angry',
            url: './FacesAndGesturesGameAssets/faces/person10Angry.png'
        },
        SCARED: {
            key: 'person10Scared',
            url: './FacesAndGesturesGameAssets/faces/person10Scared.png'
        }
    }
];

export const facesAssets = () => {
    let assets = [];
    faces.forEach(face => {
        Object.values(face).forEach(expression => assets.push(expression))
    });
    return assets;
};

export const FacesAndGesturesGameAssets = [
    ...Object.values(hintAssets),
    ...facesAssets(),
    ...CustomSettingsAssets,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...TargetFeedbackAssets,
    ...TimeSettingAssets
];
