import Phaser from 'phaser';
import { SceneKey } from "../utils/constants";
import { scaleImage } from '../utils/resize';
import LoadBar from '../gameObjects/loadBar';
import { createBackgroundImage, createGameLogo, createGameIcon }
    from '../utils/gameObjectFactory';
import { wrapResizeFn } from '../utils/resize';
import { BtnAssets } from '../utils/assetsPaths/btn';
import { OutlineAssets } from '../utils/assetsPaths/outlines';
import { IconAssets } from '../utils/assetsPaths/icons';
import { ModalIconsAssets } from '../utils/assetsPaths/modalIcons';
import { PreviewIconsAssets } from '../utils/assetsPaths/previewIcons';
import { TileIconsAssets } from '../utils/assetsPaths/tileIcons';
import { AudioAssets } from '../utils/assetsPaths/audio';

export class SplashScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.SPLASH });
    }

    preload() {
        this.createStaticComponents();
        const { assets, data: { onLoad = () => { } } } = this.game;
        onLoad(this);
        const CommonAssets = [
            ...BtnAssets,
            ...OutlineAssets,
            ...IconAssets,
            ...PreviewIconsAssets,
            ...TileIconsAssets,
            ...ModalIconsAssets
        ];
        assets.forEach(({ key, url }) => this.load.image(key, url));
        CommonAssets.forEach(({ key, url }) => this.load.image(key, url));
        AudioAssets.forEach(({ key, url }) => this.load.audio(key, url));
        this.load.on('progress', progress => this.loadBar.setProgress(progress))
            .once('complete', () => {
                this.scene.start(SceneKey.MAIN_MENU);
                this.game.voiceAssistant.setVoices();
            });
    }

    createStaticComponents() {
        createBackgroundImage(this);
        createGameLogo(this);
        createGameIcon(this);
        this.loadBar = new LoadBar(this, 0, 0);
        wrapResizeFn(this);
    }

    resizeLandscape(width, height) {
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        const { logo, icon, loadBar, background } = this;
        background.setDisplaySize(width, height);
        scaleImage(logo, halfWidth, halfHeight, 100);
        scaleImage(icon, halfWidth, height * 0.6, 50);
        logo.setPosition(width / 4, height / 4);
        icon.setPosition(width * 0.75, halfHeight);
        loadBar.resize(halfWidth, height / 4, 150);
        loadBar.setPosition(width / 4, height * 0.75);
    }

    resizePortrait(width, height) {
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        const thirdHeight = height / 3;
        const { logo, icon, loadBar, background } = this;
        const { displayHeight } = this.loadBar.background;
        background.setDisplaySize(100, height)
            .setScale(background.scaleY, background.scaleY);
        scaleImage(logo, width, thirdHeight, 100);
        scaleImage(icon, width, thirdHeight, 100);
        loadBar.resize(width, thirdHeight, 100);
        icon.setPosition(halfWidth, halfHeight);
        logo.setPosition(halfWidth, icon.getTopCenter().y / 2);
        this.loadBar.setPosition(halfWidth, height - displayHeight);
    }
}
