import Phaser from 'phaser'
import { Btns } from '../utils/assetsPaths/btn';
import { Outlines } from '../utils/assetsPaths/outlines';
import { scaleImage } from '../utils/resize';
import { GameFont } from '../utils/constants';
const Container = Phaser.GameObjects.Container;
const Text = Phaser.GameObjects.Text;
const Image = Phaser.GameObjects.Image;

export default class Button extends Container {

    constructor(scene, x, y, label, iconTexture, backgroundTexture = Btns.PRIMARY) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, backgroundTexture.key);
        this.icon = new Image(scene, 0, 0, iconTexture.key);
        this.label = new Text(scene, 0, 0, label, ButtonStyle).setOrigin(0.5)
        this.div = new Container(scene, 0, 0);
        this.div.add([this.background, this.icon]);
        this.add([this.div, this.label]);
        this.addOutline();
        this.setChildrenPositions();
    }

    setChildrenPositions() {
        const { displayWidth } = this.icon;
        let { x, y } = this.background.getLeftCenter();
        this.icon.setPosition(x + displayWidth / 4, y);
        x = displayWidth / 8;
        this.label.setPosition(x, 0);
        this.iconOutline.setPosition(this.icon.x, this.icon.y);
    }

    setInteractive(cursor) {
        this.background.setInteractive(cursor || { useHandCursor: true });
        this.setEvents();
        return this;
    }

    removeInteractive() {
        this.background.removeInteractive();
        return this;
    }

    setEvents() {
        this.background
            .on('pointerover', () => this.addScaleTween(1.25, true))
            .on('pointerout', () => this.addScaleTween(1, false))
    }

    addScaleTween(scale, visible) {
        const { displayHeight } = this.background;
        this.tween && this.tween.stop();
        this.tween = this.scene.tweens.add({
            targets: [this.div, this.label], ease: 'Power3', scale, duration: 125,
            onStart: () => {
                this.label.setScale(this.div.scale);
                this.label.setFontSize(`${displayHeight / 2}px`);
                this.outline.setVisible(visible);
            },
            onComplete: () => {
                this.label.setScale(1);
                this.label.setFontSize(`${displayHeight * this.div.scale / 2}px`);
            }
        })
    }

    addOutline() {
        this.outline = new Container(this.scene, 0, 0).setVisible(false);
        this.backgroundOutline = new Image(this.scene, 0, 0, Outlines.BTN.key);
        this.iconOutline = new Image(this.scene, this.icon.x, this.icon.y, Outlines.ICON.key)
        this.outline.add([this.backgroundOutline, this.iconOutline]);
        this.div.add(this.outline).sendToBack(this.outline);
    }

    onclick(callback) {
        const isMobileDevice = this.scene.game.isMovileDevice
        this.onclickEvent = isMobileDevice ? 'pointerout' : 'pointerdown'
        this.onclickCallback = callback;
        this.background.on(this.onclickEvent, callback);
        return this;
    }

    removeClick() {
        this.background.off(this.onclickEvent, this.onclickCallback);
        return this;
    }

    setLabel(label, texture) {
        this.label.setText(label);
        this.icon.setTexture(texture.key)
    }

    resizeLabel() {
        const fontSize = this.background.displayHeight * 0.5;
        this.label.setFontSize(`${fontSize}px`);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier)
        this.icon.setScale(this.background.scale);
        this.backgroundOutline.setScale(this.background.scale);
        this.iconOutline.setScale(this.icon.scale);
        this.setChildrenPositions();
        this.resizeLabel();
        return this;
    }
}

const ButtonStyle = {
    fontFamily: GameFont,
    fontSize: '5em',
    color: 'white'
}