import { SettingKey } from "../../HabCogGame/utils/constants";
import { PreviewIcons } from "../../HabCogGame/utils/assetsPaths/previewIcons";
import { TileIcons } from "../../HabCogGame/utils/assetsPaths/tileIcons";

export const DifficultySettingKey = {
    DOG: 0,
    CAT: 1,
    POLICE: 2,
    NURSE: 3
}

const difficultySetting = {
    key: SettingKey.DIFFICULTY,
    name: 'Dificultad',
    icon: PreviewIcons.DIFFICULTY,
    tileIcon: TileIcons.DIFFICULTY,
    options: [
        {
            title: 'Perro',
            header: 'Forma de perro',
            description: 'Los estímulos tendrán forma de perro.',
            image: {
                key: 'dogDifficultySetting',
                url: './SomethingInCommonGameAssets/settings/difficulty/dog.png'
            },
        },
        {
            title: 'Gato',
            header: 'Forma de gato',
            description: 'Los estímulos tendrán forma de gato.',
            image: {
                key: 'catDifficultySetting',
                url: './SomethingInCommonGameAssets/settings/difficulty/cat.png'
            },
        },
        {
            title: 'Policía',
            header: 'Forma de policía',
            description: 'Los estímulos tendrán forma de policía.',
            image: {
                key: 'policeDifficultySetting',
                url: './SomethingInCommonGameAssets/settings/difficulty/police.png'
            },
        },
        {
            title: 'Enfermera',
            header: 'Forma de enfermera',
            description: 'Los estímulos tendrán forma de enfermera.',
            image: {
                key: 'nurseDifficultySetting',
                url: './SomethingInCommonGameAssets/settings/difficulty/nurse.png'
            },
        }
    ]
};

export default difficultySetting;

export const DifficultySettingAssets = difficultySetting.options.map(({ image }) => image);
