import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { BaseContourPhotos } from '../../HabCogGame/utils/assetsPaths/photos/contour';
import { RotationSettingKey } from '../utils/settings';
import { createRandomNumericArray } from "../../HabCogGame/utils/imgSelection";

const { GOOD_TARGET } = TargetFeedback;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback, rotation } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.sTextures = BaseContourPhotos;
        this.createImgsList(quantity, size, this.sTextures[0], false);
        this.setPattern(rotation);
        this.drawDistorsion(difficulty);
        this.setFailFn(feedback);
        this.fillTargetIndexes();
        this.fillTextureIndexes();
        this.startGameplay();
    }

    setPattern(rotation) {
        if (rotation === RotationSettingKey.INVERT)
            this.imgList[0].setFlipY(true);
        else if (rotation === RotationSettingKey.SYMMETRIC)
            this.imgList[0].setFlipX(true);
        this.imgList[0].setTintFill(0xffffff);
    }

    drawDistorsion(difficulty) {
        const distorsion = [0.5, 1];
        const { displayWidth, displayHeight } = this.imgList[0];
        const lineWidth = displayWidth / 15 * distorsion[difficulty];
        const graphics = this.add.graphics({
            x: 0,
            y: this.gameArea.y,
            fillStyle: { color: 0x000000 }
        })
        const { x, y } = this.imgList[0].getTopLeft();
        graphics.fillRect(x + displayWidth / 3, y, lineWidth, displayHeight)
            .fillRect(x + displayWidth * 2 / 3, y, lineWidth, displayHeight)
            .fillRect(x, y + displayHeight / 3, displayWidth, lineWidth)
            .fillRect(x, y + displayHeight * 2 / 3, displayWidth, lineWidth);
    }

    getNextTarget() {
        let index = this.targetIndexes.pop();
        if (index === undefined) {
            this.fillTargetIndexes();
            index = this.targetIndexes.pop();
        }
        return this.targets[index];
    }

    getNextTexture(hitTexture = {}) {
        let index = this.textureIndexes.pop();
        if (index === undefined) {
            this.fillTextureIndexes();
            index = this.textureIndexes.pop();
        }
        const nextTexture = this.sTextures[index];
        return nextTexture.key === hitTexture.key ?
            this.getNextTexture(hitTexture) : nextTexture;
    }

    fillTargetIndexes() {
        const length = this.targets.length;
        this.targetIndexes = createRandomNumericArray(length);
    }

    fillTextureIndexes() {
        const length = this.sTextures.length;
        this.textureIndexes = createRandomNumericArray(length);
    }

    setTargetsTexture(hitTarget) {
        const hitTexture = this.getNextTexture();
        this.imgList[0].setTexture(`${hitTexture.key}Contour`);
        this.targets.forEach(img =>
            img.setTexture(this.getNextTexture(hitTexture).key));
        hitTarget.setTexture(hitTexture.key);

    }
    setTargetsInteractive(hitTarget) {
        this.targets.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTarget.off('pointerdown')
            .once('pointerdown', () => this.addHit(hitTarget))
    }

    addHit(img) {
        super.addHit();
        this.createFeedbackImage(img, GOOD_TARGET);
        this.restartGameplay(img);
    }

    restartGameplay() {
        this.targets.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }

    startGameplay() {
        this.feedbackImgs = [];
        const hitTarget = this.getNextTarget();
        this.setTargetsTexture(hitTarget);
        this.setTargetsInteractive(hitTarget);
    }
}
