import React from "react";
import { BrowserRouter as Switch, Route, withRouter } from "react-router-dom";
import SocialInclusionGame from "./components/SocialInclusion/SocialInclusionGame";
import LaboralInclusionGame from "./components/LaboralInclusionGame/LaboralInclusionGame";
import Home from "./components/LudomingaUI/LogInFormComponent/Home";
import GameTypeNavigator from "./components/LudomingaUI/GameTypeNavigator/GameTypeNavigator";
import IntervetionPlans from "./components/LudomingaUI/InterventionPlans/InterventionPlans";
import LudoExactusCarousel from "./components/LudomingaUI/LudoExactus/LudoExactusCarousel";
import LuminousWindowsGame from "./components/LuminousWindowsGame/LuminousWindowsGame";
import EverythingChangesGame from "./components/EverythingChangesGame/EverythingChangesGame";
import AlikeGame from "./components/AlikeGame/AlikeGame";
import EndFinaleGame from "./components/EndFinaleGame/EndFinaleGame";
import EscapeRoutGame from "./components/EscapeRouteGame/EscapeRouteGame";
import HereAndThereGame from "./components/HereAndThereGame/HereAndThereGame";
import HuntingFiguresGame from "./components/HuntingFiguresGame/HuntingFiguresGame";
import BoingBoingGame from "./components/BoingBoingGame/BoingBoingGame";
import NosyGame from "./components/NosyGame/NosyGame";
import RememberAndLocateGame from "./components/RememberAndLocateGame/RememberAndLocateGame";
import StepByStepGame from "./components/StepByStepGame/StepByStepGame";
import PuzzleGame from "./components/PuzzleGame/PuzzleGame";
import MirrorReflectionGame from "./components/MirrorReflectionGame/MirrorReflectionGame";
import ProfilesGame from "./components/ProfilesGame/ProfilesGame";
import FacesAndGesturesGame from "./components/FacesAndGesturesGame/FacesAndGesturesGame";
import SpaceRelationGame from "./components/SpaceRelationGame/SpaceRelationGame";
import LightsAndShadowsGame from "./components/LightsAndShadowsGame/LightsAndShadowsGame";
import ClasifyGame from "./components/ClasifyGame/ClasifyGame";
import IntrudersGame from "./components/IntrudersGame/IntrudersGame";
import TwinsGame from "./components/TwinsGame/TwinsGame";
import SomethingInCommonGame from "./components/SomethingInCommonGame/SomethingInCommonGame";
import SomethingMissingGame from "./components/SomethingMissingGame/SomethingMissingGame";
import LudoEspacialCarousel from "./components/LudomingaUI/LudoEspacial/LudoEspacialCarousel";
import LudoCheckCarousel from "./components/LudomingaUI/LudoCheck/LudoCheckCarousel";
import LudoAdivinadorCarousel from "./components/LudomingaUI/LudoAdivinador/LudoAdivinadorCarousel";

const Routes = (props) => {
  const { history } = props;
  console.log(history);
  return (
    <Switch>
      <Route exact path="/" component={Home} props={history} />

      <Route path="/GameTypeNavigator" component={GameTypeNavigator} />
      <Route
        path="/InterventionPlans/:organization_id"
        component={IntervetionPlans}
      />

      <Route path="/ludomundo" component={SocialInclusionGame} />
      <Route path="/ludoempleo" component={LaboralInclusionGame} />

      <Route path="/ludoExactusCarousel" component={LudoExactusCarousel} />
      <Route exact path="/ventanasLuminosas" component={LuminousWindowsGame} />
      <Route exact path="/todoCambia" component={EverythingChangesGame} />
      <Route exact path="/cazandoFiguras" component={HuntingFiguresGame} />
      <Route exact path="/recuerdoUbico" component={RememberAndLocateGame} />
      <Route exact path="/aquiAlla" component={HereAndThereGame} />
      <Route exact path="/finFinalin" component={EndFinaleGame} />
      <Route exact path="/boingBoing" component={BoingBoingGame} />
      <Route exact path="/pregunton" component={NosyGame} />
      <Route exact path="/igualitos" component={AlikeGame} />
      <Route exact path="/viaEscape" component={EscapeRoutGame} />

      <Route path="/ludoEspacialCarousel" component={LudoEspacialCarousel} />
      <Route path="/reflejoEspejo" component={MirrorReflectionGame} />
      <Route path="/rompecabezas" component={PuzzleGame} />
      <Route path="/pasoPaso" component={StepByStepGame} />

      <Route path="/ludoCheckCarousel" component={LudoCheckCarousel} />
      <Route path="/clasifica" component={ClasifyGame} />
      <Route path="/algoComun" component={SomethingInCommonGame} />
      <Route path="/intrusos" component={IntrudersGame} />
      <Route path="/gemelos" component={TwinsGame} />
      <Route path="/algoFalta" component={SomethingMissingGame} />

      <Route
        path="/ludoAdivinadorCarousel"
        component={LudoAdivinadorCarousel}
      />
      <Route path="/perfiles" component={ProfilesGame} />
      <Route path="/lucesSombras" component={LightsAndShadowsGame} />
      <Route path="/carasGestos" component={FacesAndGesturesGame} />
      <Route path="/relacionEspacio" component={SpaceRelationGame} />
    </Switch>
  );
};
export default withRouter(Routes);
