import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { buildQuantitySetting } from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting, { FeedbackSettingAssets } from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import difficultySetting, { DifficultySettingAssets } from './utils/settings';
import { QuantitySettingAssets } from '../HabCogGame/utils/settingsConstants/quantitySetting';
import { TargetFeedbackAssets } from '../HabCogGame/utils/assetsPaths/targets';
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';
import { IntrudersPhotos } from '../IntrudersGame/utils/assets';

const data = {
    name: 'Gemelos',
    sessionAssets: [
        ...IntrudersPhotos,
        ...SizeSettingAssets,
        ...QuantitySettingAssets,
        ...FeedbackSettingAssets,
        ...TargetFeedbackAssets,
        ...DifficultySettingAssets,
        ...TimeSettingAssets
    ],
    sessionScene: SessionScene,
    logoUrl: './TwinsGameAssets/logo.png',
    iconUrl: './TwinsGameAssets/icon.png',
    thumbnail: './TwinsGameAssets/thumbnail.png',
    getHint: () => 'Toca la fotografía que es igual al modelo.',
    userManual: 'El usuario deberá seleccionar la fotografía igual al modelo mostrado ' +
        'En ajustes, se puede configurar el juego por: nivel de dificultad, ' +
        'tamaño y número de estímulos, señalización de errores y tiempo de ' +
        'duración de la sesión.',
    settings: [
        difficultySetting,
        sizeSetting,
        buildQuantitySetting([3, 4, 5, 6, 9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function TwinsGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
