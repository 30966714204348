import Phaser from 'phaser';
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions';
import topBar from '../../GameObjects/TopBarComponent/topBarComponet';
import { errorText, scoreTitle, scoreButton, observation, succesText, modal, scoreBackground, successImage, mistakeImage, coments } from './ScoreStructure';
import score from '../../Services/score';
import AlertModal from '../../GameObjects/AlertModal/AlertModal';
import { buttonPressAnimation, modalMovementAnimation, mouseOverAnimatioArrows } from '../../Functions/AnimatedFunctions';

export default class ScoreScene extends Phaser.Scene {
    constructor() {
        super('Score');
    }
    init(data) {
        this.errors = data.errors;
        this.success = data.success;
        this.historyID = data.historyid
        this.interventionPlanID = this.game.data.interventionID
        this.historyScoreName = data.historyName
        this.gameTime = data.time / 1000
    }

    preload() {
        //load Structure
        this.saveScaleState()
        //preload Background
        this.drawPreloadBackground()
        //Scale this scene
        this.successScale = scaleGameObject(this, this.successScale)
        this.observationScale = scaleGameObject(this, this.observationScale)
        this.scoreButtonScale = scaleGameObject(this, this.scoreButtonScale)
        this.historyNameScale = scaleGameObject(this, this.historyNameScale)
        this.errorTextScale = scaleGameObject(this, this.errorTextScale)
        this.modalScale = scaleGameObject(this, this.modalScale)
        this.scoreBackground = scaleGameObject(this, this.scoreBackground)
        this.successImage = scaleGameObject(this, this.successImage)
        this.mistakeImage = scaleGameObject(this, this.mistakeImage)
        this.coments = scaleGameObject(this, this.coments)
    }
    create() {

        //Top Bar Component
        var bar = new topBar(this, 0, 0, this.sys.canvas.width, 400, 'Puntuación', 'Vocabulary', 'Puntuación de la historia ' + this.historyScoreName)
        this.add.existing(bar)

        //second background
        var secondBackground = new Phaser.GameObjects.Image(this, this.scoreBackground.objectPositionX, this.scoreBackground.objectPositionY, 'scoreBackground')
        secondBackground.setDisplaySize(this.scoreBackground.objectWidth, this.scoreBackground.objectHeight)
        this.add.existing(secondBackground)

        //Title
        var name = new Phaser.GameObjects.Text(this, this.historyNameScale.objectPositionX, this.historyNameScale.objectPositionY, 'Puntuación')
        name.setOrigin(0)
        name.setWordWrapWidth(this.historyNameScale.objectWidth)
        let font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 45)
        name.setFont(font)
        name.setFontFamily('ArialRounded')
        name.setColor('#205067')
        this.add.existing(name)

        //Succes Text
        var successImage = this.add.image(this.successImage.objectPositionX, this.successImage.objectPositionY, 'successImage')
        successImage.setDisplaySize(this.successImage.objectWidth, this.successImage.objectHeight)
        var sucecess = this.add.text(this.successScale.objectPositionX, this.successScale.objectPositionY, '' + this.success)
        font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 60)
        sucecess.setFont(font)
        sucecess.setFontFamily('ArialRounded')
        sucecess.setColor('#ffffff')

        //Fails Text
        var mistakeImage = this.add.image(this.mistakeImage.objectPositionX, this.mistakeImage.objectPositionY, 'mistakeImage')
        mistakeImage.setDisplaySize(this.mistakeImage.objectWidth, this.mistakeImage.objectHeight)
        var errors = this.add.text(this.errorTextScale.objectPositionX, this.errorTextScale.objectPositionY, '' + this.errors)
        font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 60)
        errors.setFont(font)
        errors.setFontFamily('ArialRounded')
        errors.setColor('#ffffff')

        //Coment Title
        var comentTitle = new Phaser.GameObjects.Text(this, this.coments.objectPositionX, this.coments.objectPositionY, 'Comentarios')
        comentTitle.setOrigin(0)
        comentTitle.setWordWrapWidth(this.historyNameScale.objectWidth)
        let comentTitleFont = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 45)
        comentTitle.setFont(comentTitleFont)
        comentTitle.setFontFamily('ArialRounded')
        comentTitle.setColor('#205067')
        this.add.existing(comentTitle)


        //Add html dom
        var textAreaPlaceHolder = "Ingrese aquí los comentarios de la sesión de trabajo"
        var readOnly = ""
        if (this.interventionPlanID === 0) {
            textAreaPlaceHolder = "Sesión de prueba si desea mantener seguimiento de los resultados debe iniciar sesión"
            readOnly = "readonly"
        }
        font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 28)
        var style = 'width:' + this.observationScale.objectWidth + 'px; height:' + this.observationScale.objectHeight + 'px; font:' + font + ' ArialRounded;resize: none; color:#205067;'
        var textArea = this.add.dom(this.observationScale.objectPositionX, this.observationScale.objectPositionY).createFromHTML(`<textarea type="text" placeholder="${textAreaPlaceHolder}" ${readOnly} id="username" name="username"
        rows="4" maxlength="1024" cols="50" style="${style}"></textarea>`)

        //Send Logic
        var finalscore
        var playedTime = (this.gameTime.toFixed(0))
        var date = new Date()

        //Button logic
        const xScale = this.scoreButtonScale.objectWidth
        const yScale = this.scoreButtonScale.objectHeight
        this.outline = new Phaser.GameObjects.Image(this, this.scoreButtonScale.objectPositionX, this.scoreButtonScale.objectPositionY, 'buttonOutlineScore').setVisible(false)
        this.outline.setDisplaySize(xScale * 1.18, yScale * 1.5);
        this.add.existing(this.outline)
        var button = this.add.image(this.scoreButtonScale.objectPositionX, this.scoreButtonScale.objectPositionY, 'summitButton')
        button.setDisplaySize(this.scoreButtonScale.objectWidth, this.scoreButtonScale.objectHeight)
        button.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.sound.play('clickSound')
            buttonPressAnimation(this, button)
            button.disableInteractive()
            //preparate modal into screen
            var inputUsername = textArea.getChildByName('username');
            finalscore = {
                date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                observation: inputUsername.value,
                time_played: playedTime,
                success: this.success,
                omissions: 0,
                failures: this.errors
            }
            //Add Modal
            this.addModal(finalscore, textArea, this.interventionPlanID)
        }).on('pointerover', () => {
            mouseOverAnimatioArrows(this, button, button.scaleX * 1.1, button.scaleY * 1.1)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimatioArrows(this, button, button.scaleX / 1.1, button.scaleY / 1.1)
            this.outline.setVisible(false)
        })
    }

    clone(obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }
        return temp;
    }
    createReport(scene, id, finalscore) {
        return score.postTest(id, finalscore)
            .then(() => {
                scene.scene.start("Stories")
            })
            .catch(error => {
                throw error
            })
    }
    drawPreloadBackground() {
        this.background = this.add.image(0, 0, 'background').setOrigin(0)
        let scaleX = this.sys.canvas.width / this.background.width
        let scaleY = this.sys.canvas.height / this.background.height
        this.background.setScale(scaleX, scaleY).setScrollFactor(0)

        //top bar without funcionality
        var bar = this.add.image(0, 0, 'Bar')
        scaleX = this.sys.canvas.width / 1920
        scaleY = this.sys.canvas.height / 1080
        var scalefull = this.sys.canvas.width / bar.width
        bar.setDisplaySize(bar.width * scalefull, bar.height * scaleY).setOrigin(0)
        var tittleScene = this.add.text(this.cameras.main.width / 2, 0, 'Puntuación').setOrigin(0.5, 0)
        let font = ScaleFunctionText(this.sys.canvas.width, this.sys.canvas.height, 95)
        tittleScene.setFontSize(font)
    }
    saveScaleState() {
        this.successImage = this.clone(successImage)
        this.mistakeImage = this.clone(mistakeImage)
        this.coments = this.clone(coments)
        this.scoreBackground = this.clone(scoreBackground)
        this.successScale = this.clone(succesText)
        this.observationScale = this.clone(observation)
        this.scoreButtonScale = this.clone(scoreButton)
        this.historyNameScale = this.clone(scoreTitle)
        this.errorTextScale = this.clone(errorText)
        this.modalScale = this.clone(modal)
    }
    addModal(finalscore, textArea, interventionPlanId) {
        var mensaje = 'Esta seguro que desea enviar los resultados a la plataforma'
        if (interventionPlanId === 0) {
            mensaje = "Modo de prueba, los resultados no se enviarán a la plataforma"
        }
        var popModal = new AlertModal(this, this.sys.canvas.width / 2, -800,
            this.modalScale.objectWidth, this.modalScale.objectHeight,
            mensaje
            , finalscore, interventionPlanId)
        console.log(interventionPlanId)
        this.add.existing(popModal)
        modalMovementAnimation(this, popModal, this.sys.canvas.width / 2, this.modalScale.objectPositionY)
        setTimeout(() => {
            textArea.setVisible(false).setActive(false)
        }, 1500);
    }
}