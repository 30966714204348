import Phaser from 'phaser';
import {Cursors, GameFont} from '../utils/constants';
import {Btns} from '../utils/assetsPaths/btn';
import {Outlines} from '../utils/assetsPaths/outlines';
import {scaleImage} from '../utils/resize';
import { StepByStepIcons } from '../../StepByStepGame/utils/assets';

const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
export default class BrushThicknessSelector extends Phaser.GameObjects.Container {

    constructor(scene, x, y, length, index = 0) {
        super(scene, x, y);
        const {DECREASE, INCREASE} = StepByStepIcons;
        this.background = new Image(scene, 0, 0, Btns.PRIMARY.key);
        this.decreaseIcon = new Image(scene, 0, 0, DECREASE.key);
        this.increaseIcon = new Image(scene, 0, 0, INCREASE.key);
        this.label = new Text(scene, 0, 0, 'Grosor', ThicknessTextStyle)
            .setOrigin(0.5);
        this.outlineIcon = new Image(scene, 0, 0, Outlines.ICON.key)
            .setVisible(false);
        this.add([
            this.background,
            this.decreaseIcon,
            this.increaseIcon,
            this.outlineIcon,
            this.label
        ]);
        this.index = index;
        this.itemsLength = length;
        this.updateVisible();
    }

    updateVisible() {
        if (this.index === 0) {
            this.decreaseIcon.setVisible(false);
            this.outlineIcon.setVisible(false);
        } else if (this.index === this.itemsLength - 1) {
            this.increaseIcon.setVisible(false);
            this.outlineIcon.setVisible(false);
        } else {
            this.decreaseIcon.setVisible(true);
            this.increaseIcon.setVisible(true);
        }
    }

    addHoverTween(targets, scale) {
        this.scene.add.tween({
            targets,
            scale,
            duration: 100,
            ease: 'Power1'
        })
    }

    setInteractive() {
        const btns = [this.decreaseIcon, this.increaseIcon];
        btns.forEach(btn => btn.setInteractive(Cursors.pointerover)
            .on('pointerover', () => {
                this.outlineIcon.setVisible(true).setX(btn.x);
                this.addHoverTween([btn, this.outlineIcon], this.background.scale * 1.5)
            })
            .on('pointerout', () => {
                this.addHoverTween([btn, this.outlineIcon], this.background.scale);
                this.outlineIcon.setVisible(false);
            }));
        this.decreaseIcon.on('pointerdown', () => {
            this.index--;
            this.updateVisible();
        });
        this.increaseIcon.on('pointerdown', () => {
            this.index++;
            this.updateVisible();
        });
        return this;
    }

    onIndexChange(callback) {
        const btns = [this.decreaseIcon, this.increaseIcon];
        btns.forEach(btn => btn.on('pointerdown', callback));
    }

    getDisplaySize() {
        return {
            displayHeight: this.background.displayHeight,
            displayWidth: this.increaseIcon.getRightCenter().x * 2
        }

    }

    getBottomCenter() {
        return {
            x: this.x,
            y: this.y + this.background.displayHeight / 2
        }
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        const scale = this.background.scale;
        this.decreaseIcon.setScale(scale);
        this.increaseIcon.setScale(scale);
        this.outlineIcon.setScale(scale);
        this.decreaseIcon.setX(this.background.getLeftCenter().x);
        this.increaseIcon.setX(this.background.getRightCenter().x);
        this.label.setFontSize(`${this.background.displayHeight / 2}px`);
    }
}

const ThicknessTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}
