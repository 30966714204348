import phaser from 'phaser'
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions'
import buttonComponent from '../Button/button'
import { topBarComponentStructure } from './topBarComponetStructure'
import { buttonPressAnimation, mouseOverAnimation } from '../../Functions/AnimatedFunctions'
import { RoundedCamera } from '../../GameObjects/ExtraCameras/RoundedCamera'


export default class topBar extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, title, father, text) {
        super(scene, x, y)
        this.barTittle = null
        this.workshopDescription = null
        this.button = null
        this.scene = scene
        this.zoomactive = false
        this.zoomCamera = new RoundedCamera(scene, 0, 0, 300, 1.5).setRenderToTexture(scene.game.roundPipeline);
        this.zoomCamera.setVisible(false);
        scene.cameras.addExisting(this.zoomCamera);

        this.generated(scene, x, y, width, heigth, title, father, text)
    }

    async generated(scene, x, y, width, heigth, title, father, text) {
        //voice
        var voiceTextTalk = (localStorage.getItem('voiceSound') >= 0) ? text : ' '

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'Bar')
        var scaleY = scene.sys.canvas.height * 1.1 / 1080
        this.background.setOrigin(0)
        this.background.setDisplaySize(width, this.background.height * scaleY);

        //Title
        topBarComponentStructure.barTitle.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, 35)
        this.barTitle = new phaser.GameObjects.Text(this.scene, scene.sys.canvas.width / 2, 0, title).setOrigin(0.5, - 0.5)
        this.barTitle.setStyle(topBarComponentStructure.barTitle.style)

        //Game Logo
        var old = topBarComponentStructure.gameLogo.scale
        topBarComponentStructure.gameLogo.scale = scaleGameObject(scene, topBarComponentStructure.gameLogo.scale)
        this.gameLogo = new buttonComponent(this.scene, topBarComponentStructure.gameLogo)
        topBarComponentStructure.gameLogo.scale = old

        //ludo image
        old = topBarComponentStructure.barImage.scale
        topBarComponentStructure.barImage.scale = scaleGameObject(scene, topBarComponentStructure.barImage.scale)
        this.ludoImage = new buttonComponent(this.scene, topBarComponentStructure.barImage)
        topBarComponentStructure.barImage.scale = old

        //exitButton
        old = topBarComponentStructure.exitButton.scale
        topBarComponentStructure.exitButton.scale = scaleGameObject(scene, topBarComponentStructure.exitButton.scale)
        this.exitButton = new buttonComponent(this.scene, topBarComponentStructure.exitButton, 'hubOutline')
        this.exitButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.scene.sound.play('clickSound')
            buttonPressAnimation(scene, this.exitButton)
            window.history.back();

        }).on('pointerover', () => {
            mouseOverAnimation(scene, this.exitButtonLabel, 1.05)

        }).on('pointerout', () => {
            mouseOverAnimation(scene, this.exitButtonLabel, 1)
        });
        topBarComponentStructure.exitButton.scale = old

        //Exit button Label
        old = topBarComponentStructure.exitButtonLabel.scale
        topBarComponentStructure.exitButtonLabel.scale = scaleGameObject(scene, topBarComponentStructure.exitButtonLabel.scale)
        topBarComponentStructure.exitButtonLabel.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, 25)
        this.exitButtonLabel = new phaser.GameObjects.Text(scene, topBarComponentStructure.exitButtonLabel.scale.objectPositionX,
            topBarComponentStructure.exitButtonLabel.scale.objectPositionY,
            'Salir').setOrigin(0.5, 0.5)
        this.exitButtonLabel.setStyle(topBarComponentStructure.exitButtonLabel.style)
        this.exitButtonLabel.setWordWrapWidth(topBarComponentStructure.exitButtonLabel.scale.objectWidth);
        topBarComponentStructure.exitButtonLabel.scale = old

        //SoundButton
        old = topBarComponentStructure.soundButton.scale
        this.scene.game.voice.speak(voiceTextTalk)
        topBarComponentStructure.soundButton.scale = scaleGameObject(scene, topBarComponentStructure.soundButton.scale)
        this.soundButton = new buttonComponent(this.scene, topBarComponentStructure.soundButton, 'accesiblityOutline')
        this.soundButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.scene.game.voice.speakHelpText(voiceTextTalk)
            buttonPressAnimation(scene, this.soundButton)

        });
        topBarComponentStructure.soundButton.scale = old

        //No voice button 
        old = topBarComponentStructure.stopVoiceOverBtn.scale
        topBarComponentStructure.stopVoiceOverBtn.scale = scaleGameObject(scene, topBarComponentStructure.stopVoiceOverBtn.scale)
        topBarComponentStructure.stopVoiceOverBtn.nameAsset = ((localStorage.getItem('voiceSound') >= 0) ? 'voiceButton' : 'noVoiceButton')
        this.stopVoiceOverBtn = new buttonComponent(this.scene, topBarComponentStructure.stopVoiceOverBtn, 'accesiblityOutline')
        this.stopVoiceOverBtn.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.scene.game.voice.speakHelpText('')
            localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
            this.stopVoiceOverBtn.setTexture(((localStorage.getItem('voiceSound') >= 0) ? 'voiceButton' : 'noVoiceButton'))
            voiceTextTalk = (localStorage.getItem('voiceSound') >= 0) ? text : ' '
            buttonPressAnimation(scene, this.stopVoiceOverBtn)
        });
        topBarComponentStructure.stopVoiceOverBtn.scale = old

        //zoom button
        old = topBarComponentStructure.zoomButton.scale
        topBarComponentStructure.zoomButton.scale = scaleGameObject(scene, topBarComponentStructure.zoomButton.scale)
        this.zoomButton = new buttonComponent(this.scene, topBarComponentStructure.zoomButton, 'accesiblityOutline')
        this.zoomButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.zoomactive = !this.zoomactive
            this.createZoomCamera(scene)
            buttonPressAnimation(scene, this.zoomButton)
        });
        topBarComponentStructure.zoomButton.scale = old

        //button home
        old = topBarComponentStructure.barButton.scale
        topBarComponentStructure.barButton.scale = scaleGameObject(scene, topBarComponentStructure.barButton.scale)
        this.button = new buttonComponent(this.scene, topBarComponentStructure.barButton, 'hubOutline')
        this.button.setInteractive({ useHandCursor: true }).on('pointerup', () => {
            this.scene.game.voice.speak('')
            this.scene.scene.start('Workshops')
        }).on('pointerover', () => {
            mouseOverAnimation(scene, this.homeButtonLabel, 1.05)

        }).on('pointerout', () => {
            mouseOverAnimation(scene, this.homeButtonLabel, 1)
        })
        topBarComponentStructure.barButton.scale = old

        //Home button label
        old = topBarComponentStructure.barButtonLabel.scale
        topBarComponentStructure.barButtonLabel.scale = scaleGameObject(scene, topBarComponentStructure.barButtonLabel.scale)
        topBarComponentStructure.barButtonLabel.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, 17)
        this.homeButtonLabel = new phaser.GameObjects.Text(scene, topBarComponentStructure.barButtonLabel.scale.objectPositionX,
            topBarComponentStructure.barButtonLabel.scale.objectPositionY,
            'Pantalla principal').setOrigin(0.5, 0.5)
        this.homeButtonLabel.setStyle(topBarComponentStructure.barButtonLabel.style)
        this.homeButtonLabel.setWordWrapWidth(topBarComponentStructure.barButtonLabel.scale.objectWidth);
        topBarComponentStructure.barButtonLabel.scale = old

        //Back button Label
        old = topBarComponentStructure.barBackLabel.scale
        topBarComponentStructure.barBackLabel.scale = scaleGameObject(scene, topBarComponentStructure.barBackLabel.scale)
        topBarComponentStructure.barBackLabel.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, 22)
        this.barBackLabel = new phaser.GameObjects.Text(scene, topBarComponentStructure.barBackLabel.scale.objectPositionX,
            topBarComponentStructure.barBackLabel.scale.objectPositionY,
            'Regresar').setOrigin(0.5, 0.5)
        this.barBackLabel.setStyle(topBarComponentStructure.barBackLabel.style)
        this.barBackLabel.setWordWrapWidth(topBarComponentStructure.barBackLabel.scale.objectWidth);
        topBarComponentStructure.barBackLabel.scale = old

        //button back
        old = topBarComponentStructure.barBack.scale
        topBarComponentStructure.barBack.scale = scaleGameObject(scene, topBarComponentStructure.barBack.scale)
        this.bakcButton = new buttonComponent(this.scene, topBarComponentStructure.barBack, 'hubOutline')
        this.bakcButton.setInteractive({ useHandCursor: true }).on('pointerup', () => {
            this.scene.game.voice.speak('')
            this.scene.scene.start(father)
        });
        topBarComponentStructure.barBack.scale = old

        this.bakcButton.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            buttonPressAnimation(scene, this.bakcButton)
            this.scene.sound.play('clickSound')
        }).on('pointerover', () => {
            mouseOverAnimation(scene, this.barBackLabel, 1.05)

        }).on('pointerout', () => {
            mouseOverAnimation(scene, this.barBackLabel, 1)
        });
        this.button.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            this.scene.sound.play('clickSound')
            buttonPressAnimation(scene, this.button)
        });

        if (father === '') {
            this.add([this.background, this.barTitle, this.button, this.homeButtonLabel,
            this.stopVoiceOverBtn, this.soundButton, this.zoomButton, this.ludoImage, this.exitButton, this.exitButtonLabel, this.gameLogo])
            this.bakcButton.disableInteractive();
        } else {
            this.add([this.background, this.barTitle, this.button, this.homeButtonLabel, this.bakcButton, this.barBackLabel,
            this.stopVoiceOverBtn, this.soundButton, this.zoomButton, this.ludoImage, this.exitButton, this.exitButtonLabel, this.gameLogo])
        }
        this.setSize(width, this.background.height * scaleY)
    }

    createZoomCamera(scene) {
        if (this.control) {
            this.control = 0
        }
        this.zoomCamera.setVisible(this.zoomactive);
        const inputCallback = (pointer) => {
            if (this.zoomactive) {
                this.zoomCamera.relocate(pointer);

            }

        }
        scene.input.on('pointermove', inputCallback)
    }


}
