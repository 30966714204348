export const StealingPersonSheets = {
    Thief: {
        key: 'situationTwoThief',
        url: 'SocialInclusionAssets/situations/stealingPerson/thief.png',
        frameWidth: 390 / 4,
        frameHeight: 388 / 3
    },
    ThiefWalk: {
        key: 'situationTwoThiefWalking',
        url: 'SocialInclusionAssets/situations/stealingPerson/thiefRun.png',
        frameWidth: 190 / 3,
        frameHeight: 283 / 2
    },
    Seesaw: {
        key: 'situationTwoSeesaw',
        url: 'SocialInclusionAssets/situations/stealingPerson/seesaw.png',
        frameWidth: 635 / 2,
        frameHeight: 630 / 2
    },
    Slide: {
        key: 'situationTwoSlide',
        url: 'SocialInclusionAssets/situations/stealingPerson/slide.png',
        frameWidth: 1363 / 4,
        frameHeight: 1393 / 4,
    },
    PoliceUp: {
        key: 'policeUp',
        url: 'SocialInclusionAssets/situations/stealingPerson/policeWalkUp.png',
        frameWidth: 247 / 4,
        frameHeight: 408 / 3,
    },
    PoliceDown: {
        key: 'policeDown',
        url: 'SocialInclusionAssets/situations/stealingPerson/policeWalkDown.png',
        frameWidth: 270 / 4,
        frameHeight: 419 / 3,
    },
    PoliceAction: {
        key: 'policeAction',
        url: 'SocialInclusionAssets/situations/stealingPerson/policeAction.png',
        frameWidth: 178 / 3,
        frameHeight: 143,
    }
}

export const StealingPersonAssets = {
    Images: [],
    SpriteSheets: Object.values(StealingPersonSheets)
}