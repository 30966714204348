import Phaser from 'phaser';
import { SceneKey } from '../utils/constants';
import { createBackgroundImage, createInstructionBox, createCharacterPic, createZoomCamera, createAccesibilityBtns, createBtn, createMenuBar, createUserTag, setZoomBtnOnclick } from '../utils/factory';
import { Worlds } from '../utils/assetsPaths/worlds';
import { Icons } from '../utils/assetsPaths/icons';
import { Btns } from '../utils/assetsPaths/btn';
import { Characters } from '../utils/assetsPaths/characters';

export default class WorldSelectorScene extends Phaser.Scene {

    constructor() {
        super({ key: SceneKey.WORLD_SELECTOR });
        this.defaultCharacter = Characters.MALE;
    }

    create() {
        createZoomCamera(this);
        createBackgroundImage(this);
        createAccesibilityBtns(this);
        createMenuBar(this, "LudoMundo");
        createUserTag(this, this.game.sessionData);
        createInstructionBox(this, "Elige el mundo");
        this.returnBtn = createBtn(this, "Regresar", Icons.RETURN, Btns.SECONDARY);
        this.worldOptions = Object.values(Worlds).map(world =>
            createCharacterPic(this, world.thumbnail, world.name).setInteractive()
                .onclick(() => {
                    this.game.ludo.world = world;
                    if (!this.game.ludo.character)
                        this.game.ludo.character = this.defaultCharacter
                    this.scene.start(world.key)
                }));
        this.resize();
        const scale = () => this.resize();
        this.scale.on("resize", scale)
        this.events.once("shutdown", () => this.scale.off("resize", scale));
        this.setBtnsInteractive();
        this.speakAtStart();
        this.game.startCountdown(this);
        this.events.once("shutdown", () => this.game.voice.cancel());
    }
    speakAtStart() {
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
        this.game.voice.speak(this.helpText);
    }

    stopVoice() {
        this.game.voice.speakHelpText(' ')
        localStorage.setItem('voiceSound', (localStorage.getItem('voiceSound') * -1))
        this.stopVoiceOverBtn.setIcon((localStorage.getItem('voiceSound') >= 0) ? 'speakingIcon' : 'stopVoiceIcon')
        this.helpText = (localStorage.getItem('voiceSound') >= 0) ? SceneInstruction : ' '
    }

    setBtnsInteractive() {
        this.returnBtn.setInteractive().onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.voiceOverBtn.onclick(() => this.game.voice.speakHelpText(this.helpText));
        this.stopVoiceOverBtn.onclick(() => this.stopVoice());
        setZoomBtnOnclick(this, this.zoomBtn);
    }

    resize() {
        const { width, height } = this.game.canvas;
        const headerHeight = height / 7;
        const { background, accesibilityBtns, returnBtn, header, userTag, instructionBox } = this;
        background.setDisplaySize(width, height);
        returnBtn.resize(width / 2, height / 8, 25, 1);
        accesibilityBtns.forEach(btn => btn.resize(width / 2, height / 8, 25, 1));
        header.resize(width, headerHeight);
        userTag.resize(width, headerHeight, 25, 1);
        instructionBox.resize(width * 0.6, height / 5, 50, 1);
        this.setElementPositions(headerHeight);
    }

    setElementPositions(headerHeight) {
        const { width, height } = this.game.canvas;
        const { accesibilityBtns, returnBtn, userTag, header, instructionBox, worldOptions } = this;
        const position = Phaser.Display.Align.CENTER;
        userTag.setPosition(width - userTag.background.displayWidth / 2 * 1.1, headerHeight / 2);
        instructionBox.setX(width / 2)
            .setY(headerHeight + instructionBox.ludo.displayHeight * 0.65);

        Phaser.Actions.GridAlign(accesibilityBtns, {
            width: -1, position,
            cellWidth: accesibilityBtns[0].background.displayWidth * 1.25,
            y: height - accesibilityBtns[0].background.displayHeight,
            x: width - accesibilityBtns[0].background.displayWidth * 3.5
        })

        returnBtn.setPosition(width / 2, accesibilityBtns[0].y);
        header.alignLabel(userTag.getLeftCenter().x);

        const optionHeight = (returnBtn.getTopCenter().y - instructionBox.getBottomCenter().y) * 0.65;
        worldOptions.forEach(c => c.resize(width / worldOptions.length, optionHeight, 25, 1));
        const cellWidth = worldOptions[0].background.displayWidth * 1.5;

        Phaser.Actions.GridAlign(worldOptions, {
            width: -1,
            cellWidth,
            y: (returnBtn.getTopCenter().y + instructionBox.getBottomCenter().y) / 2,
            x: width / 2 - (cellWidth * (worldOptions.length - 1)) / 2,
            position
        })
    }
}

const SceneInstruction = "Elige el mundo en el que quieres jugar"