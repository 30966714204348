import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { Outlines } from '../utils/assetsPaths/outlines';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
import OptionSelector, { ArrowVisible } from './optionSelector';
import OptionSelectedIndicator from './optionSelectorIndicator';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
const Container = Phaser.GameObjects.Container

export default class SettingTile extends Container {
    constructor(scene, x, y, iconTexture, headerLabel, optionLabels, selectedIndex = 0) {
        super(scene, x, y)
        this.optionLabels = optionLabels;
        this.selectedIndex = selectedIndex;
        this.background = new Image(scene, 0, 0, Btns.SETTING_TILE.key);
        this.outline = new Image(scene, 0, 0, Outlines.SETTING_TILE.key).setVisible(false);
        this.icon = new Image(scene, 0, 0, iconTexture.key);
        this.headerLabel = new Text(scene, 0, 0, headerLabel, TileTextStyle).setOrigin(1, 0.5);
        this.optionSelector = new OptionSelector(scene, 0, 0, optionLabels[this.selectedIndex]);
        this.optionSelectedIndicator = new OptionSelectedIndicator(scene, 0, 0, optionLabels.length, selectedIndex);
        this.div = new Container(scene, 0, 0);
        this.div.add([
            this.outline,
            this.background,
            this.icon,
            ...this.optionSelector.arrows,
            this.optionSelectedIndicator
        ]);
        this.add([this.div, this.headerLabel, this.optionSelector.optionLabel])
        this.resizeLabels();

    }

    setInteractive(callback = Phaser.Utils.NOOP) {
        this.background.setInteractive()
            .on('pointerover', () => {
                this.focusOn();
                callback();
            });
        this.optionSelector.setInteractive()
            .setArrowVisible(ArrowVisible.NONE)
        return this;
    }

    setOptionIndex(index) {
        if (index !== this.selectedIndex) {
            const direction = index < this.selectedIndex ? -1 : 1;
            this.selectedIndex = index;
            this.updateLabel(this.optionLabels[this.selectedIndex], direction);
            this.updateArrowVisible();
            this.optionSelectedIndicator.setSelectedIndex(this.selectedIndex)
        }
    }

    getSelectedIndex() {
        return this.selectedIndex;
    }

    focusOn() {
        this.updateArrowVisible();
        this.outline.setVisible(true);
        this.addFocusTween(1.25);
    }

    focusOff() {
        this.outline.setVisible(false);
        this.addFocusTween(1);
        this.optionSelector.setArrowVisible(ArrowVisible.NONE);
    }

    addFocusTween(scale) {
        const { displayHeight } = this.background;
        const { optionLabel } = this.optionSelector;
        this.tween && this.tween.stop();
        if (this.div.scale !== scale) {
            this.tween = this.scene.tweens.add({
                targets: [this.div, this.headerLabel, optionLabel],
                scale,
                ease: 'Power3',
                duration: 125,
                onStart: () => {
                    this.headerLabel.setScale(this.div.scale);
                    optionLabel.setScale(this.div.scale);
                    this.headerLabel.setFontSize(`${displayHeight * 0.45}px`);
                    optionLabel.setFontSize(`${displayHeight * 0.35}px`)
                },
                onComplete: () => {
                    this.headerLabel.setScale(1);
                    optionLabel.setScale(1);
                    const height = displayHeight * this.div.scale;
                    this.headerLabel.setFontSize(`${height * 0.45}px`);
                    optionLabel.setFontSize(`${height * 0.35}px`);
                },
                onUpdate: () => {
                    const x = this.optionSelectedIndicator.x
                    optionLabel.setX(x * this.div.scale);
                }
            })
        }
    }

    onOptionChange(callback) {
        this.optionSelector
            .onLeftClick(() => {
                let index = this.selectedIndex - 1;
                if (this.optionSelector.isAvailable && index > -1)
                    this.setOptionIndex(index);

                callback();
            })
            .onRightClick(() => {
                let index = this.selectedIndex + 1;
                if (this.optionSelector.isAvailable && index < this.optionLabels.length)
                    this.setOptionIndex(index);
                callback();
            });
        return this;
    }

    updateLabel(text, index) {
        this.optionSelector.updateLabel(text, index);
        return this;
    }

    updateArrowVisible() {
        if (this.optionLabels.length === 1)
            this.optionSelector.setArrowVisible(ArrowVisible.NONE)
        else if (this.selectedIndex === 0)
            this.optionSelector.setArrowVisible(ArrowVisible.ONLY_RIGHT_ARROW);
        else if (this.selectedIndex === this.optionLabels.length - 1)
            this.optionSelector.setArrowVisible(ArrowVisible.ONLY_LEFT_ARROW);
        else
            this.optionSelector.setArrowVisible(ArrowVisible.BOTH_ARROW);

    }

    restoreDefault() {
        this.setOptionIndex(0);
        this.optionSelector.setArrowVisible(ArrowVisible.NONE);
    }

    resizeLabels() {
        const { x } = this.background.getLeftCenter();
        this.icon.setX(x + this.icon.displayWidth / 4);
        this.headerLabel.setFontSize(`${this.background.displayHeight * 0.45}px`)
        const { displayWidth } = this.headerLabel;
        const labelX = this.icon.getRightCenter().x + displayWidth * 1.1
        this.headerLabel.setX(labelX);
        this.optionSelectedIndicator
            .setX(this.background.displayWidth / 4)
            .setY((this.background.displayHeight + this.optionSelector.optionLabel.displayHeight) / 4.5)
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.icon.setScale(this.background.scale);
        this.outline.setScale(this.background.scale);
        this.optionSelector.resize(this.background);
        this.optionSelectedIndicator.resize(this.background);
        this.resizeLabels();
    }
}

const TileTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}