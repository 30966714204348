import Phaser from 'phaser';
import { Btns } from '../utils/assetsPaths/btn';
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;
const Container = Phaser.GameObjects.Container;

export default class UserTag extends Container {

    constructor(scene, x, y, sessionData) {
        super(scene, x, y)
        const timeLabel = `Tiempo: ${sessionData.time ? sessionData.time + 'minutos' : 'Libre'}`
        this.background = new Image(scene, 0, 0, Btns.USER_TAG.key);
        this.userName = new Text(scene, 0, 0, sessionData.name.toUpperCase(), UserTagTextStyle).setOrigin(0.5);
        this.sessionTime = new Text(scene, 0, 0, timeLabel, UserTagTextStyle).setOrigin(0.5);
        this.IPID = new Text(scene, 0, 0, `PI: ${sessionData.piid}`, UserTagTextStyle).setOrigin(0.5)
        this.add([this.background, this.userName, this.sessionTime, this.IPID]);
        this.resizeLabels();
    }

    resizeLabels() {
        const { displayWidth, displayHeight } = this.background;
        const x = displayWidth / 4;
        const y = displayHeight / 5;
        const fontSize = `${displayHeight * 0.2}px`
        this.userName.setY(-y).setFontSize(fontSize)
        this.sessionTime.setPosition(-x, y).setFontSize(fontSize)
        this.IPID.setPosition(x, y).setFontSize(fontSize)
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier)
        this.resizeLabels();
    }

}

const UserTagTextStyle = {
    fontFamily: GameFont,
    color: 'white'
}