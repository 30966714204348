import Phaser from 'phaser';
import { SceneKey } from '../utils/constants';
import {
    createHub,
    createMapBackground,
    createFollower,
    createMainCharacter
} from '../utils/factory';
import { createCharacterAnim, generateFrameNames } from '../utils/functions';
import { HouseSheets } from '../utils/assetsPaths/situations/city/burningHouse';
const Vector2 = Phaser.Math.Vector2;

export default class WorldScene extends Phaser.Scene {

    preload() {
        this.anims.removeAllListeners();
        const cAnims = Object.values(this.game.ludo.character.anims);
        cAnims.forEach(cAnim => createCharacterAnim(this, cAnim));
        this.createSituations();
        this.situations.forEach(s => s.preload());

        this.animalsAnims = {
            DOG: {
                key: "dogAnimation",
                frames: generateFrameNames(HouseSheets.DOG.key, 0, 11),
                repeat: -1,
                frameRate: 5,
            },
            CAT: {
                key: "catAnimation",
                frames: generateFrameNames(HouseSheets.CAT.key, 0, 11),
                repeat: -1,
                frameRate: 5,
            },
            MOUSE: {
                key: "mouseAnimation",
                frames: generateFrameNames(HouseSheets.MOUSE.key, 0, 11),
                repeat: -1,
                frameRate: 5,
            }
        }

        Object.values(this.animalsAnims).forEach(anim => this.anims.create(anim));
    }

    createSituations() {
        this.situations = [];
    }

    addHit() {
        this.game.score.addHits();
    }

    addFail() {
        this.game.score.addFails();
    }

    create() {
        const badges = this.situations.map(s => s.badge);
        const { background } = this.game.ludo.world;
        const { IDLE } = this.game.ludo.character.anims;
        createHub(this, `Mundo ${this.worldName}`, badges);
        createMapBackground(this, background);
        createFollower(this);
        createMainCharacter(this, IDLE);
        this.resize();
        this.enablePlayerAnim();
        this.situations.forEach(s => s.create().resize().enable());
        this.setSceneInteractive();
        this.game.startCountdown(this);
        this.events.on("shutdown", () => this.game.voice.cancel());
        this.events.once("shutdown", () => this.game.voice.cancel());
    }

    setSceneInteractive() {
        this.header.setInteractive();
        this.header.homeBtn.onclick(() => this.scene.start(SceneKey.MAIN_MENU));
        this.header.worldBtn.onclick(() => this.scene.start(SceneKey.WORLD_SELECTOR));
        this.header.modalBtn.onclick(() => this.scene
            .pause(this.scene.key)
            .launch(SceneKey.MODAL, { key: this.scene.key }))
        this.header.badgesBtn.onclick(
            () => this.scene.start(SceneKey.BADGES, { key: this.scene.key }));
    }

    enablePlayerAnim() {
        const { DOWN, UP, IDLE } = this.game.ludo.character.anims;
        const play = ({ key }, flipX) => () => this.mc.play(key).setFlipX(flipX);
        this.follower.onTopLeftMovement(play(UP, true))
            .onTopRightMovement(play(UP, false))
            .onBottomLeftMovement(play(DOWN, true))
            .onBottomRightMovement(play(DOWN, false))
            .onMovementComplete(() => this.mc.anims.stop()
                .setTexture(IDLE.key, 0));
    }
    disablePlayerAnim() {
        this.follower.removeMovementEvents();
    }

    enableSituations() {
        this.situations.forEach(situation => situation.enable())
    }

    moveMcTo(x, y, onComplete) {
        if (x === this.follower.x && y === this.follower.y) {
            onComplete();
        }
        else {
            this.follower.goTo(new Vector2(x, y));
            this.follower.once('onMovementComplete', onComplete);
        }
    }

    update() {
        const { x, y } = this.follower;
        const { scaleX, scaleY } = this.background;
        this.mc.setPosition(x * scaleX, y * scaleY + this.background.y);
    }

    resize() {
        const { width, height } = this.game.canvas;
        const barSize = height / 10;
        this.header.resize(width, barSize);
        this.background.setY(this.header.getHeight())
            .setDisplaySize(width, height - this.background.y)
        const { scaleX, scaleY } = this.background;
        this.mc.setScale(Math.max(scaleX, scaleY) * (this.ludoScale || 2));
    }
}

