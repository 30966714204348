import Phaser from 'phaser';
import QuestionBox from './questionBox';
import OptionBox from './optionBox';
const Rectangle = Phaser.GameObjects.Rectangle;

class OptionSelector extends Phaser.GameObjects.Container {
    constructor(scene, x, y, instructionLabel, optionsData) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, 0x000000, 0.75)
            .setOrigin(0, 0);
        this.instruction = new QuestionBox(scene, 0, 0, instructionLabel);
        this.options = optionsData.map(data => this.createOption(data));
        this.add([this.background, this.instruction, ...this.options]);
        this.setDepth(10);
    }

    createOption({ label, onclick }) {

        return new OptionBox(this.scene, 0, 0, label)
            .setInteractive()
            .onclick(() => {
                onclick();
                this.destroy();
            })
    }

    resize(width, height) {
        this.background.setDisplaySize(width, height);
        const center = this.background.getCenter();
        this.instruction.resize(width / 2, height / 3, 0, 1)
            .setPosition(width / 2, center.y - height * 0.25);
        const w = width / this.options.length;
        this.options.forEach(option => option.resize(w, height / 2, 50, 1));
        const cellWidth = this.options[0].background.displayWidth * 1.25;
        Phaser.Actions.GridAlign(this.options, {
            width: -1,
            cellWidth,
            position: Phaser.Display.Align.CENTER,
            x: center.x - (cellWidth * (this.options.length - 1)) / 2,
            y: center.y + height * 0.2
        })
        return this;
    }
}

export default OptionSelector;