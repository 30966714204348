import React from "react";
import "./Footer.css";
import { Container, Image, Header } from 'semantic-ui-react'



export default function Footer() {
    return (
        <div className="main-footer">
            <Container >
                <Header as="h4" textAlign="center" inverted style={{ padding: '0.5vh 0.5vw' }}>
                    &copy; {new Date().getFullYear()}, Hecho con
                    <Image src='/LudomingaUIAssets/commonAssets/heart.png' style={{ width: "1vw", height: "2vh" }} spaced verticalAlign='bottom' />para una mejor educación
                </Header>
            </Container>

        </div>
    );
}

