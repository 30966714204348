import React from 'react';
import HabCogGame from '../HabCogGame/HabCogGame';
import SessionScene from "./scenes/sessionScene";
import { sizeSetting, SizeSettingAssets } from "../HabCogGame/utils/settingsConstants/sizeSetting";
import { difficultySetting } from "./utils/settings";
import { DifficultySettingAssets } from "./utils/settings";
import timeSetting, { TimeSettingAssets } from '../HabCogGame/utils/settingsConstants/timeSetting';

const sessionAssets = [
    ...DifficultySettingAssets,
    ...SizeSettingAssets,
    ...TimeSettingAssets
]
const data = {
    name: 'Fin finalín',
    sessionAssets,
    sessionScene: SessionScene,
    logoUrl: './EndFinaleGameAssets/logo.png',
    iconUrl: './EndFinaleGameAssets/icon.png',
    thumbnail: './EndFinaleGameAssets/thumbnail.png',
    getHint: () => 'Sigue con la mirada la trayectoria de la figura y cuando pare, toca sobre ella.',
    userManual: 'El estímulo se desplaza progresivamente hasta cubrir el ancho o alto de ' +
        'la pantalla. Al concluir el desplazamiento el usuario señalará el estímulo. ' +
        'Se puede configurar: dificultad en función de la velocidad, el tamaño de ' +
        'los estímulos y tiempo de duración del juego.',
    settings: [
        difficultySetting,
        sizeSetting,
        timeSetting
    ]
};

export default function EndFinaleGame(props) {
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
