import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { HexColor, Cursors } from "../../HabCogGame/utils/constants";
import BrushThicknessSelector from "../../HabCogGame/gameObjects/brushThicknessSelector";
import { modelAssets, StepByStepIcons, physicsModel, brushAsset } from "../utils/assets";
import Button from '../../HabCogGame/gameObjects/button';
import { Icons } from '../../HabCogGame/utils/assetsPaths/icons';
import { DifficultySettingKey } from '../utils/settings';
import { scaleImage } from '../../HabCogGame/utils/resize';
import { wrapVect2 } from '../../HabCogGame/gameObjects/vect2Wrapper';
import { Btns } from '../../HabCogGame/utils/assetsPaths/btn';

const Image = Phaser.GameObjects.Image;
const DPR = window.devicePixelRatio;
const pixelsSize = [
    30 * DPR,
    50 * DPR,
    70 * DPR,
    100 * DPR
]

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, model } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight).setDepth(400);
        this.sColors = Object.values(HexColor);
        this.colorIndex = 0;
        this.brushes = [];
        this.brushSize = pixelsSize[0];
        this.addBtns(difficulty);
        this.setPaintFn(difficulty, model);
        this.startGameplay();
    }

    setPaintFn(difficulty, model) {
        this.sShapes = this.sShapes = this.cache.json.get(physicsModel.key);
        this.modelIndex = model;
        if (difficulty === DifficultySettingKey.FREE)
            this.paintCanvas = () => this.paintOnNoneTargets();
        else {
            this.target = this.createMatterObject();
            this.paintZone = this.createPaintZone();
            this.isTargetDirty = false;
            this.paintCanvas = () => this.paintOnMatterTarget();
            if (difficulty === DifficultySettingKey.COPY)
                this.tweens.add({
                    targets: this.target,
                    alpha: { from: 1, to: 0 },
                    duration: 2000
                })
        }
    }

    startGameplay() {
        this.time.addEvent({
            delay: 35,
            callbackScope: this,
            callback: () => this.paintCanvas(this.target),
            repeat: -1
        });
    }

    getBrushColor() {
        return this.sColors[this.colorIndex];
    }

    setNextColor() {
        const nextIndex = this.colorIndex + 1
        this.colorIndex = nextIndex === this.sColors.length ? 0 : nextIndex;
    }

    getNextTarget() {
        const nextIndex = this.modelIndex + 1;
        this.modelIndex = nextIndex === modelAssets.length ? 0 : nextIndex;
        return this.createMatterObject();
    }

    createMatterObject() {
        const { width, height } = this.getAvailableSize();
        const model = this.modelIndex;
        const { key } = modelAssets[model];
        const physicsOptions = { shape: this.sShapes[key] };
        const x = width / 2;
        const y = height / 2 + this.hubHeight;
        const target = this.matter.add.sprite(x, y, key, 0, physicsOptions)
            .setOrigin(0.5)
            .setIgnoreGravity(true)
        const colorCorner = this.colorBtn.getTopRight();
        const modelCorner = this.modelBtn.getBottomLeft();
        const maxWidth = modelCorner.x - colorCorner.x;
        scaleImage(target, maxWidth, height, 100, 1);
        return target;
    }

    createPaintZone() {
        const { width, height } = this.getAvailableSize();
        const zone = this.add.zone(0, this.gameArea.y, width, height)
            .setInteractive(Cursors.pointerout)
            .setOrigin(0)
            .on('pointerover', () => this.isPointerInside = true)
            .on('pointerout', () => this.isPointerInside = false);
        return zone;
    }

    paintOnNoneTargets() {
        const { x, y, isDown } = this.input.activePointer;
        const size = this.brushSize;
        const color = this.getBrushColor();
        if (isDown) {
            const icon = new Image(this, x, y, brushAsset.key)
                .setDisplaySize(size, size).setTint(color);
            this.brushes.push(icon);
            this.add.existing(icon);
        }
    }

    paintOnMatterTarget() {
        const { x, y, isDown } = this.input.activePointer;
        const size = this.brushSize;
        const color = this.getBrushColor();
        if (isDown && this.isPointerInside) {
            const isTargetDirty = this.matter.containsPoint(this.target, x, y);
            if (isTargetDirty !== this.isTargetDirty) {
                isTargetDirty ? this.addHit() : this.addFail();
                this.isTargetDirty = isTargetDirty;
            }
            const icon = new Image(this, x, y, brushAsset.key)
                .setDisplaySize(size, size).setTint(color);
            this.brushes.push(icon);
            this.add.existing(icon);
        }
    }

    clearBoard() {
        this.brushes.forEach(brush => brush.destroy())
    }

    addBtns(difficulty) {
        this.addColorBtn();
        this.addClearBtn();
        this.addBrushThicknessBtn();
        if (difficulty === DifficultySettingKey.COLOR) {
            this.addNextModelBtn(difficulty);
        }
        else if (difficulty === DifficultySettingKey.COPY) {
            this.addNextModelBtn(difficulty);
            this.addShowModelBtn();
        }


    }

    addColorBtn() {
        const { width, height } = this.getAvailableSize();
        const colorBtn = new Button(this, 0, 0, 'Color', Icons.CONTINUE)
            .setInteractive(Cursors.pointerover).setDepth(100)
        colorBtn.resize(width / 5, height / 5 + this.hubHeight, 100, 1);
        const { displayWidth, displayHeight } = colorBtn.background;
        colorBtn.setX(displayWidth / 1.35);
        colorBtn.setY(height - displayHeight);
        colorBtn.icon.setTintFill(this.getBrushColor());
        this.gameArea.add(colorBtn);
        colorBtn.onclick(() => {
            this.setNextColor();
            colorBtn.icon.setTintFill(this.getBrushColor());
        });
        wrapVect2(colorBtn);
        this.colorBtn = colorBtn;
    }

    addClearBtn() {
        const { width, height } = this.getAvailableSize();
        const icon = StepByStepIcons.CLEAR_CANVAS
        const clearBtn = new Button(this, width, height, 'Borrar', icon)
            .setInteractive(Cursors.pointerover).setDepth(100);
        clearBtn.resize(width / 5, height / 5, 100, 1);
        const { displayWidth, displayHeight } = clearBtn.background;
        clearBtn.setX(width - displayWidth / 1.5)
            .setY(height - displayHeight);
        clearBtn.onclick(() => this.clearBoard())
        this.gameArea.add(clearBtn);
        wrapVect2(clearBtn);
        this.clearBtn = clearBtn;
    }

    addBrushThicknessBtn() {
        const { width, height } = this.getAvailableSize();
        let btBtn = new BrushThicknessSelector(this, 0, 0, 4)
            .setDepth(100);
        btBtn.setInteractive();
        btBtn.onIndexChange(() => this.brushSize = pixelsSize[btBtn.index]);
        btBtn.resize(width / 5, height / 5, 100, 1);
        const { displayWidth, displayHeight } = btBtn.getDisplaySize();
        btBtn.setPosition(displayWidth / 1.65, displayHeight);
        this.gameArea.add(btBtn);
        this.thicknessSelector = btBtn;
    }

    showModel() {
        this.target.destroy();
        this.target = this.getNextTarget();
        this.clearBoard();
    }

    showModelTween() {
        this.target.destroy();
        this.clearBoard();
        this.target = this.getNextTarget();
        this.tweens.add({
            targets: this.target,
            delay: 1000,
            duration: 1000,
            alpha: { from: 1, to: 0 }
        })
    }

    addNextModelBtn(difficulty) {
        const isCopy = difficulty === DifficultySettingKey.COPY;
        const onclickFn = isCopy ? this.showModelTween : this.showModel;
        const { width, height } = this.getAvailableSize();
        const icon = StepByStepIcons.NEXT_MODEL;
        const modelBtn = new Button(this, 0, 0, 'Siguiente', icon)
            .setInteractive(Cursors.pointerover)
            .onclick(onclickFn.bind(this));
        modelBtn.resize(width / 5, height / 5, 100, 1);
        modelBtn.setPosition(this.clearBtn.x, this.thicknessSelector.y);
        wrapVect2(modelBtn);
        this.gameArea.add(modelBtn);
        this.modelBtn = modelBtn;
    }

    addShowModelBtn() {
        const { width, height } = this.getAvailableSize();
        const icon = StepByStepIcons.SHOW_MODEL
        const showBtn = new Button(this, 0, 0, 'Mostrar', icon, Btns.SECONDARY)
            .setInteractive(Cursors.pointerover)
            .onclick(() => this.tweens.add({
                targets: this.target,
                alpha: { from: 0, to: 1 },
                yoyo: true,
                duration: 2000
            }));
        showBtn.resize(width / 5, height / 5, 100, 1);
        const { displayHeight } = showBtn.background;
        showBtn.setX(this.clearBtn.x);
        showBtn.setY(this.modelBtn.getBottomCenter().y + displayHeight);
        this.gameArea.add(showBtn);
    }

}
