const GrainsPhotos = [
    {
        key: 'grainPhoto1',
        url: './photos/grains/grain1.png'
    }, {
        key: 'grainPhoto2',
        url: './photos/grains/grain2.png'
    }, {
        key: 'grainPhoto3',
        url: './photos/grains/grain3.png'
    }, {
        key: 'grainPhoto4',
        url: './photos/grains/grain4.png'
    }, {
        key: 'grainPhoto5',
        url: './photos/grains/grain5.png'
    }
]

export default GrainsPhotos;
