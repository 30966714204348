export const smallCardComponentStructure = {
    cardImage: {
        nameAsset: '',
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 305,
            objectHeight: 580,
            objectPositionX: 0,
            objectPositionY: -150,
        }
    },
    cardTitle: {
        text: '',
        style: {
            fontSize: '32px',
            fontFamily: 'arial rounded',
            color: '#feff00',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 10,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 65,
            objectPositionX: 0,
            objectPositionY: -260
        }
    },
    cardDescription:
    {
        text: '',
        style: {
            fontSize: '30px',
            fontFamily: 'Arial',
            color: '#205067',
            align: 'center',
            stroke: '#205067',
            strokeThickness: 1,
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 308,
            objectHeight: 80,
            objectPositionX: 0,
            objectPositionY: 250
        }

    },
    cardButton:
    {
        text: '',
        nameAsset: 'soundIcon',
        style: {
            fontSize: '30px',
            fontFamily: 'Segoe UI Emoji',
            color: '#00ff00',
            align: 'center',
            stroke: '#00ff00'
        },
        scale: {
            objectWidthRatio: 1,
            objectHeightRatio: 1,
            objectWidth: 70,
            objectHeight: 70,
            objectPositionX: 110,
            objectPositionY: -200,
        }

    }
}