
export const InstructionText = {
    nameAsset: '',
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 1700,
    objectHeight: 175,
    objectPositionX: 920,
    objectPositionY: 230,

}
export const imageStructure = {

    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 500,
    objectHeight: 750,
    objectPositionX: 920,
    objectPositionY: 675,

}
export const RigthButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 150,
    objectHeight: 171.69,
    objectPositionX: 1320,
    objectPositionY: 600,
}
export const LeftButtonStructure = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 150,
    objectHeight: 171.69,
    objectPositionX: 520,
    objectPositionY: 600,
}
export const playButton = {
    objectWidthRatio: 1,
    objectHeightRatio: 1,
    objectWidth: 300,
    objectHeight: 80,
    objectPositionX: 1600,
    objectPositionY: 1000,
}