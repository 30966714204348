import React, { Component } from "react";
import Footer from "../CommonComponents/Footer";
import { Grid, Image, Button } from 'semantic-ui-react'
import range from 'lodash/range';
import ItemsCarousel from 'react-items-carousel';
import "../../../App.css";
import { Link } from "react-router-dom";
import Universitieslogos from "../CommonComponents/Universitieslogos";
import { ludoAdivinatorGamesRoutes } from "../../../catalogue/games"
const gamesRoutes = ludoAdivinatorGamesRoutes

class LudoAdivinadorCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            children: [],
            activeItemIndex: 0,
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                children: this.createChildren(20),
            })
        }, 100);
    }
    createChildren = n => range(n).map(i => <div key={i} style={{ height: 200, background: '#333' }}>{i}</div>);

    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    shouldComponentUpdate() {
        return true;
    }
    render() {
        return (<div className="App">
            <div className="page-container">

                <div className="content-wrap">
                    <Grid textAlign="left" style={{ height: '80vh', marginTop: '5vh', marginLeft: "5vw" }} verticalAlign='middle' columns={2}>
                        <Grid.Column width={5}  >
                            <Grid.Row>
                                <Image style={{ marginLeft: "-3vw", marginTop: '15vh', heigth: "10vh", width: "50vw" }} src="/LudomingaUIAssets/commonAssets/hummingbird.png" />
                            </Grid.Row>
                            <Grid.Row >
                                <div><br></br></div>
                                <Link to="/GameTypeNavigator" >
                                    <Image onClick={this.toggleLeftVisibility} className="backButton" style={{ marginTop: 2, marginLeft: "15%", padding: 1, width: '12vw' }}
                                        src="/LudomingaUIAssets/commonAssets/backButton.png" size="medium" />
                                </Link>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column verticalAlign="top">
                            <Grid style={{ marginTop: '-10vh' }} textAlign="center" verticalAlign='top'>
                                <Grid.Row>
                                    <Image src="/LudomingaUIAssets/commonAssets/LudomingaLogo2.png" verticalAlign='top' />
                                </Grid.Row>
                                <Grid.Row >
                                    <Grid.Column style={{ "margin": "auto" }}>
                                        <ItemsCarousel
                                            infiniteLoop={false}
                                            gutter={12}
                                            activePosition={'center'}
                                            chevronWidth={80}
                                            disableSwipe={false}
                                            alwaysShowChevrons={true}
                                            numberOfCards={4}
                                            slidesToScroll={1}
                                            outsideChevron={true}
                                            showSlither={false}
                                            firstAndLastGutter={true}
                                            activeItemIndex={this.state.activeItemIndex}
                                            requestToChangeActive={value => this.setState({ activeItemIndex: value })}
                                            rightChevron={
                                                <Image src="/LudomingaUIAssets/CarouselAssets/leftArrow.png" />
                                            }
                                            leftChevron={
                                                <Image src="/LudomingaUIAssets/CarouselAssets/rightArrow.png" />
                                            }
                                        >
                                            {gamesRoutes.map((game, i) =>
                                                <Link to={game.route} key={i} >
                                                    <Image className="cardHover" src={game.image} fluid
                                                        style={{
                                                            marginTop: 10,
                                                            marginBottom: 10,
                                                            padding: 5
                                                        }} />
                                                    <Button fluid id="buttonWhite" style={{
                                                        padding: 10
                                                    }} >
                                                        Jugar
                                                    </Button>
                                                    <div>
                                                        `
                                                    </div>
                                                </Link>

                                            )}
                                        </ItemsCarousel>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>

                    </Grid>

                    <Universitieslogos />
                </div>
                <Footer />
            </div>
        </div>);
    }
}


export default LudoAdivinadorCarousel;
