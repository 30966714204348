export const PlotShapeAssets = [
    {
        key: 'plottedSquareShape',
        url: './BoingBoingGameAssets/plot/squarePlot.png'
    },
    {
        key: 'plottedTriangleShape',
        url: './BoingBoingGameAssets/plot/trianglePlot.png'
    },
    {
        key: 'plottedCircleShape',
        url: './BoingBoingGameAssets/plot/circlePlot.png'
    },
    {
        key: 'plottedStarShape',
        url: './BoingBoingGameAssets/plot/starPlot.png'
    }
];

export const SerpentineAsset = {
    key: 'serpentineAsset',
    url: './BoingBoingGameAssets/serpentine.png'
};