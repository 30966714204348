import phaser from 'phaser'
import buttonComponent from '../Button/button'
import { mediumCardComponentStructure } from './MediumCardComponentStructure'
import { buttonPressAnimation, mouseOverAnimation } from '../../Functions/AnimatedFunctions'


export default class mediumCardComponentComponent extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, title, image, description, id) {
        super(scene, x, y)

        this.image = null
        this.title = null
        this.workshopId = id
        this.description = null
        this.button = null
        this.scene = scene
        this.generated(scene, x, y, width, heigth, title, image, description, id)


    }

    generated(scene, x, y, width, heigth, title, image, description, id) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);
        //outline
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, 'rectangleBoardOutline').setVisible(false)
        this.outline.setDisplaySize(width * 1.1, heigth * 1.05);
        //Title
        this.nameBackGround = new phaser.GameObjects.Image(scene, mediumCardComponentStructure.cardTitle.scale.objectPositionX, mediumCardComponentStructure.cardTitle.scale.objectPositionY, 'BackgroundCard')
        this.nameBackGround.setDisplaySize(mediumCardComponentStructure.cardTitle.scale.objectWidth, mediumCardComponentStructure.cardTitle.scale.objectHeight);
        this.title = new phaser.GameObjects.Text(this.scene, mediumCardComponentStructure.cardTitle.scale.objectPositionX, mediumCardComponentStructure.cardTitle.scale.objectPositionY, title, mediumCardComponentStructure.cardTitle.style)
        this.title.setOrigin(0.5)

        //Image
        this.image = new phaser.GameObjects.Image(this.scene, 0, 0, image)
        this.image.setDisplaySize(mediumCardComponentStructure.cardImage.scale.objectWidth, mediumCardComponentStructure.cardImage.scale.objectHeight)

        //Description
        this.descriptionBackground = new phaser.GameObjects.Image(scene, mediumCardComponentStructure.cardDescription.scale.objectPositionX, mediumCardComponentStructure.cardDescription.scale.objectPositionY, 'BackgroundCard')
        this.descriptionBackground.setDisplaySize(mediumCardComponentStructure.cardDescription.scale.objectWidth, mediumCardComponentStructure.cardDescription.scale.objectHeight);
        this.description = new phaser.GameObjects.Text(this.scene, mediumCardComponentStructure.cardDescription.scale.objectPositionX, mediumCardComponentStructure.cardDescription.scale.objectPositionY, description, mediumCardComponentStructure.cardDescription.style)
        this.description.setWordWrapWidth(mediumCardComponentStructure.cardDescription.scale.objectWidth);
        this.description.setOrigin(0.5)
        this.descriptionBackground.setAlpha(0.6)
        //button
        this.button = new buttonComponent(this.scene, mediumCardComponentStructure.cardButton)
        this.button.setInteractive().on('pointerdown', () => {
            console.log("Sonido " + id)
            this.scene.game.voice.speak(description)
        });
        this.click = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.click.setDisplaySize(width, heigth);
        this.click.setAlpha(0.001)
        this.click.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
            console.log("A Vocabulario " + id)
            buttonPressAnimation(scene, this)
            this.scene.game.voice.speak(description)
        }).on('pointerover', () => {
            mouseOverAnimation(scene, this, 1.005)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimation(scene, this, 1)
            this.outline.setVisible(false)
        });



        this.add([this.outline, this.background, this.nameBackGround, this.title, this.image, this.button, this.click])
        this.setSize(width, heigth)
    }
}