import phaser from 'phaser'
import { scaleGameObject, ScaleFunctionText } from '../../Functions/ScaleFunctions'
import buttonComponent from '../Button/button'
import { InstructionTextStructure } from './InstructionTextStructure'
import { VoiceManager } from '../../Plug-ins/voiceManager'
import { buttonPressAnimation } from '../../Functions/AnimatedFunctions'

export default class InstructionTextComponent extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, text, image, id, backScene, historyName, aditionalInstructionText) {
        super(scene, x, y)


        this.title = null
        this.workshopDescription = null
        this.button = null
        this.scene = scene

        this.generated(scene, x, y, width, heigth, text, image, id, backScene, historyName, aditionalInstructionText)


    }

    generated(scene, x, y, width, heigth, text, image, id, backScene, historyName, aditionalInstructionText) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);

        //Image
        var old = InstructionTextStructure.instructionImage.scale
        InstructionTextStructure.instructionImage.scale = scaleGameObject(this.scene, InstructionTextStructure.instructionImage.scale)
        this.image = new phaser.GameObjects.Image(scene, InstructionTextStructure.instructionImage.scale.objectPositionX, InstructionTextStructure.instructionImage.scale.objectPositionY, image)
        this.image.setDisplaySize(InstructionTextStructure.instructionImage.scale.objectWidth, InstructionTextStructure.instructionImage.scale.objectHeight)
        this.image.setOrigin(0.5)

        this.imageBackground = new phaser.GameObjects.Image(scene, InstructionTextStructure.instructionImage.scale.objectPositionX, InstructionTextStructure.instructionImage.scale.objectPositionY, 'blackBackground')
        this.imageBackground.setDisplaySize(InstructionTextStructure.instructionImage.scale.objectWidth, InstructionTextStructure.instructionImage.scale.objectHeight)
        this.imageBackground.setOrigin(0.5)
        this.imageBackground.setAlpha(0.4)
        InstructionTextStructure.instructionImage.scale = old

        //Description
        old = InstructionTextStructure.instructionDescription.style.fontSize
        var oldScale = InstructionTextStructure.instructionDescription.scale
        InstructionTextStructure.instructionDescription.scale = scaleGameObject(this.scene, InstructionTextStructure.instructionDescription.scale)
        InstructionTextStructure.instructionDescription.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, InstructionTextStructure.instructionDescription.style.fontSize)
        this.description = new phaser.GameObjects.Text(this.scene, InstructionTextStructure.instructionDescription.scale.objectPositionX,
            InstructionTextStructure.instructionDescription.scale.objectPositionY, text,
            InstructionTextStructure.instructionDescription.style)
        this.description.setFontSize(InstructionTextStructure.instructionDescription.style.fontSize)
        this.description.setWordWrapWidth(InstructionTextStructure.instructionDescription.scale.objectWidth);
        this.description.setOrigin(0.5)
        InstructionTextStructure.instructionDescription.style.fontSize = old
        InstructionTextStructure.instructionDescription.scale = oldScale

        //Instruction other color
        old = InstructionTextStructure.instructionColorOtherPosition.style.fontSize
        oldScale = InstructionTextStructure.instructionColorOtherPosition.scale
        InstructionTextStructure.instructionColorOtherPosition.scale = scaleGameObject(this.scene, InstructionTextStructure.instructionColorOtherPosition.scale)
        InstructionTextStructure.instructionColorOtherPosition.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, InstructionTextStructure.instructionColorOtherPosition.style.fontSize)
        this.instructionColorOtherPosition = new phaser.GameObjects.Text(this.scene, InstructionTextStructure.instructionColorOtherPosition.scale.objectPositionX,
            InstructionTextStructure.instructionColorOtherPosition.scale.objectPositionY, aditionalInstructionText,
            InstructionTextStructure.instructionColorOtherPosition.style)
        this.instructionColorOtherPosition.setOrigin(0.5)
        InstructionTextStructure.instructionColorOtherPosition.style.fontSize = old
        InstructionTextStructure.instructionColorOtherPosition.scale = oldScale

        //Intruction other color diferent position
        old = InstructionTextStructure.instructionColor.style.fontSize
        oldScale = InstructionTextStructure.instructionColor.scale
        InstructionTextStructure.instructionColor.scale = scaleGameObject(this.scene, InstructionTextStructure.instructionColor.scale)
        InstructionTextStructure.instructionColor.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, InstructionTextStructure.instructionColor.style.fontSize)
        this.instruction = new phaser.GameObjects.Text(this.scene, InstructionTextStructure.instructionColor.scale.objectPositionX,
            InstructionTextStructure.instructionColor.scale.objectPositionY, aditionalInstructionText,
            InstructionTextStructure.instructionColor.style)
        this.instruction.setOrigin(0.5)
        InstructionTextStructure.instructionColor.style.fontSize = old
        InstructionTextStructure.instructionColor.scale = oldScale


        //Description reduction of font size for vocabulary scene
        old = InstructionTextStructure.descriptionReduxFont.style.fontSize
        oldScale = InstructionTextStructure.descriptionReduxFont.scale
        InstructionTextStructure.descriptionReduxFont.scale = scaleGameObject(this.scene, InstructionTextStructure.descriptionReduxFont.scale)
        InstructionTextStructure.descriptionReduxFont.style.fontSize = ScaleFunctionText(scene.sys.canvas.width, scene.sys.canvas.height, InstructionTextStructure.descriptionReduxFont.style.fontSize)
        this.descriptionRedux = new phaser.GameObjects.Text(this.scene, InstructionTextStructure.descriptionReduxFont.scale.objectPositionX,
            InstructionTextStructure.descriptionReduxFont.scale.objectPositionY, text,
            InstructionTextStructure.descriptionReduxFont.style)
        this.descriptionRedux.setOrigin(0.5)
        this.descriptionRedux.setWordWrapWidth(InstructionTextStructure.descriptionReduxFont.scale.objectWidth);
        InstructionTextStructure.descriptionReduxFont.style.fontSize = old
        InstructionTextStructure.descriptionReduxFont.scale = oldScale

        //button sound
        old = InstructionTextStructure.soundButton.scale
        InstructionTextStructure.soundButton.scale = scaleGameObject(scene, InstructionTextStructure.soundButton.scale)
        this.button = new buttonComponent(this.scene, InstructionTextStructure.soundButton)
        InstructionTextStructure.soundButton.scale = old

        //button next
        old = InstructionTextStructure.nextButton.scale
        var txt = InstructionTextStructure.nextButton.text
        if (backScene === undefined) {
            InstructionTextStructure.nextButton.scale = scaleGameObject(scene, InstructionTextStructure.nextButton.scale)
            this.nextButton = new buttonComponent(this.scene, InstructionTextStructure.nextButton, 'buttonOutline')
            this.nextButton.setInteractive({ useHandCursor: true }).on('pointerup', () => {
                console.log("secuencias")
                this.scene.scene.start('Sequence', { historyid: id, name: historyName })

            });
        }
        else {
            InstructionTextStructure.nextButton.text = backScene
            InstructionTextStructure.nextButton.scale = scaleGameObject(scene, InstructionTextStructure.nextButton.scale)
            this.nextButton = new buttonComponent(this.scene, InstructionTextStructure.nextButton, 'buttonOutline')
            this.nextButton.setInteractive({ useHandCursor: true }).on('pointerup', () => {
                console.log("Jugar")
                this.scene.scene.start('Game', { historyid: id, name: historyName })
            });
        }
        InstructionTextStructure.nextButton.scale = old
        InstructionTextStructure.nextButton.text = txt

        this.nextButton.setInteractive().on('pointerdown', () => {
            buttonPressAnimation(scene, this.nextButton)
            this.scene.sound.play('clickSound')
            var voice = new VoiceManager('Paulina')
            voice.speak('')
        });


        if (historyName === undefined) {
            this.add([this.description, this.instruction])
        }
        else {
            this.add([this.descriptionRedux, this.instructionColorOtherPosition, this.nextButton])
        }

        this.setSize(width, heigth)

    }
    setText(text) {
        this.description.setText(text)

    }




}