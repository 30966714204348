export const PlayingCardsSheets = {
    NPC_BAD: {
        key: "world2Situation4NPCBad",
        url: "SocialInclusionAssets/situations/playingCards/cardPlayerBad.png",
        frameWidth: 293 / 4,
        frameHeight: 399 / 4
    },
    NPC_GOOD: {
        key: "world2Situation4NPCGood",
        url: "SocialInclusionAssets/situations/playingCards/cardPlayerGood.png",
        frameWidth: 293 / 4,
        frameHeight: 400 / 4
    }
}

export const PlayingCardsAssets = {
    Images: [],
    SpriteSheets: Object.values(PlayingCardsSheets)
}