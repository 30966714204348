import phaser from 'phaser'
import { gameCardStructure } from './gameCardStructure'
import { mouseOverAnimation } from '../../Functions/AnimatedFunctions'

export default class gameCardComponent extends phaser.GameObjects.Container {


    constructor(scene, x, y, width, heigth, image) {
        super(scene, x, y)
        this.title = null
        this.workshopDescription = null
        this.button = null
        this.scene = scene
        this.generated(scene, x, y, width, heigth, image)
    }

    generated(scene, x, y, width, heigth, image) {

        // Background
        this.background = new phaser.GameObjects.Image(scene, 0, 0, 'BackgroundCard')
        this.background.setDisplaySize(width, heigth);

        //outline
        this.outline = new phaser.GameObjects.Image(scene, 0, 0, 'rectangleBoardOutline').setVisible(false)
        this.outline.setDisplaySize(width * 1.1, heigth * 1.05);

        //Image 
        var old = gameCardStructure.cardImage.scale
        this.image = new phaser.GameObjects.Image(scene, gameCardStructure.cardImage.scale.objectPositionX,
            gameCardStructure.cardImage.scale.objectPositionY, image)
        this.image.setDisplaySize(width, heigth)
        this.image.setOrigin(0.5)
        gameCardStructure.cardImage.scale = old

        this.add([this.outline, this.image, this.background])
        this.setSize(width, heigth)
        this.on('pointerover', () => {
            mouseOverAnimation(scene, this, 1.005)
            this.outline.setVisible(true)
        }).on('pointerout', () => {
            mouseOverAnimation(scene, this, 1)
            this.outline.setVisible(false)
        });
    }




}