import React from "react";
import SessionScene from "./scenes/sessionScene";
import {sizeSetting} from "../HabCogGame/utils/settingsConstants/sizeSetting";
import {buildQuantitySetting} from "../HabCogGame/utils/settingsConstants/functions";
import feedbackSetting from "../HabCogGame/utils/settingsConstants/feedbackSetting";
import {presentationSetting, difficultySettings} from "./utils/settings";
import {ProfilesGameAssets} from "./utils/assets";
import HabCogGame from "../HabCogGame/HabCogGame";
import timeSetting from "../HabCogGame/utils/settingsConstants/timeSetting";

const data = {
    name: 'Perfiles',
    sessionAssets: ProfilesGameAssets,
    sessionScene: SessionScene,
    logoUrl: './ProfilesGameAssets/logo.png',
    iconUrl: './ProfilesGameAssets/icon.png',
    thumbnail: './ProfilesGameAssets/thumbnail.png',
    getHint: (userConfig)=>{
        let {presentation} = userConfig;
        return `Mira ${presentation? 'el contorno': 'la silueta'} y selecciona la imagen que le corresponde`
    },
    userManual: 'El estímulo patrón es un contorno. Entre varias imágenes, el usuario ' +
        'debe seleccionar la que corresponde al contorno. En ajustes, se puede ' +
        'determinar opciones relacionadas con: cambios espaciales, presentación ' +
        'del estímulo patrón, tamaño, número de imágenes, señalización de ' +
        'errores y tiempo de duración de la sesión.',
    settings: [
        difficultySettings,
        presentationSetting,
        sizeSetting,
        buildQuantitySetting([3,4,5,6,9]),
        feedbackSetting,
        timeSetting
    ]
};

export default function ProfilesGame(props){
    const params = props.location.params;
    const interventionPlan = params ? props.location.params.interventionPlan : {};
    return <HabCogGame data={data} interventionPlan={interventionPlan} />
}
