import Phaser from 'phaser';
import AccesibilityButton from "./accesibilityButton";
import { Icons } from "../utils/assetsPaths/icons";
const DOMElement = Phaser.GameObjects.DOMElement;

export default class AccesibilityHtmlBtn extends AccesibilityButton {
    constructor(scene, x, y, iconTexture = Icons.VOICE_OVER, id = 'voiceBtn') {
        super(scene, x, y, iconTexture);
        this.id = id//+scene.scene.key//`${id}${scene.scene.key}`;
        const style = `resize: none; 
                        cursor: pointer;
                        border: none;
                        outline: none;`;
        const html = `<button id="${id}" style="${style}"></button>`
        this.htmlBtn = new DOMElement(scene, 0, 0)
            .createFromHTML(html)
            .setOrigin(0);
        this.add(this.htmlBtn);
    }

    getHtmlElement() {
        return document.getElementById(this.id);
    }
    setInteractive() {

    }

    onclick(callback) {
        const element = this.getHtmlElement();
        element.onclick =callback;
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        super.resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        const { displayWidth, displayHeight } = this.background;
        const element = this.getHtmlElement();
        const { x, y } = this.background.getTopLeft();
        this.htmlBtn.setPosition(x, y);
        element.style.width = `${displayWidth}px`;
        element.style.height = `${displayHeight}px`;
    }
}