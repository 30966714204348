import Phaser from 'phaser';
import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import { Cursors } from "../../HabCogGame/utils/constants";
import { getDifferentRandomItem } from "../../HabCogGame/utils/functions";
import { geomTextures, outlineGeomTextures, getShapeTexture } from "../../HabCogGame/utils/assetsPaths/geoms";
import { QuantitySettingKey } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { DifficultySettingKey } from "../utils/settings";
import { ShapeSettingKey } from "../../HabCogGame/utils/settingsConstants/shapeSetting";
import { OutlineSettingKey } from "../../HabCogGame/utils/settingsConstants/outlineSetting";
import { ColorSettingKey, getHexColor } from "../../HabCogGame/utils/settingsConstants/colorSetting";
import { SizeSettingKey } from '../../HabCogGame/utils/settingsConstants/sizeSetting';

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { userConfig } = this.game;
        this.textureIndex = 0;
        this.positionIndex = 0;
        this.colorIndex = 0;
        this.gameZone.on('pointerdown', () => this.addFail());
        this.sTextures = getSessionTextures(userConfig);
        this.targetColors = getTargetColors(userConfig);
        this.swap = getSwapTextureFn(this, userConfig);
        this.setTargetSize(userConfig);
        this.startGameplay(userConfig);
        this.setResizeEvents(userConfig);
    }

    getNextTexture() {
        const nextIndex = this.textureIndex + 1;
        this.textureIndex = nextIndex === this.sTextures.length ? 0 : nextIndex;
        return this.sTextures[this.textureIndex];
    }

    getCurrentTexture() {
        return this.sTextures[this.textureIndex];
    }

    getNextPosition() {
        const nextIndex = this.positionIndex + 1;
        this.positionIndex = nextIndex === this.positions.length ? 0 : nextIndex;
        return this.positions[this.positionIndex];
    }

    getCurrentPosition() {
        return this.positions[this.positionIndex];
    }

    getPreviousPosition() {
        const length = this.positions.length;
        const index = this.positionIndex;
        const nextIndex = this.positionIndex === 0 ? length - 1 : index - 1;
        return this.positions[nextIndex];
    }

    getNextColor() {
        const nextIndex = this.colorIndex + 1;
        this.colorIndex = nextIndex === this.targetColors.length ? 0 : nextIndex;
        return this.targetColors[this.colorIndex];
    }

    getCurrentColor() {
        return this.targetColors[this.colorIndex];
    }

    getRandomColor() {
        const lastColor = this.targetColors[this.colorIndex];
        const color = getDifferentRandomItem(this.targetColors, lastColor);
        this.colorIndex = this.targetColors.indexOf(color);
        return color;
    }

    setTargetSize({ size, quantity }) {
        const { width, height } = this.getAvailableSize();
        const targetSize = calculateTargetSize(width, height, size);
        this.positions = calculatePositions(width, height, quantity, targetSize);
        this.targetSize = targetSize;
        this.index = 0;
    }

    startGameplay({ quantity }) {
        const isTwoTargets = quantity === QuantitySettingKey.TWO
        if (isTwoTargets)
            this.startDoubleTargetGameplay();
        else this.startSingleTargetGameplay();
    }

    startSingleTargetGameplay() {
        const pos = this.positions[0];
        this.target = this.add.image(pos.x, pos.y + this.hubHeight, this.sTextures[0].key)
            .setDisplaySize(this.targetSize, this.targetSize)
            .setInteractive(Cursors.pointerover)
            .setTint(this.getCurrentColor())
            .on("pointerdown", () => {
                this.addHit();
                const { x, y } = this.getNextPosition();
                this.swap(x, y, this.target);
            })
    }

    startDoubleTargetGameplay() {
        const hubHeight = this.hubHeight;
        const initPos = this.positions[0];
        const secondPos = this.getNextPosition();
        const size = this.targetSize;
        let texture = this.getCurrentTexture().key;
        this.target = this.add.image(initPos.x, initPos.y + hubHeight, texture)
            .setDisplaySize(size, size)
            .setInteractive(Cursors.pointerover)
            .setTint(this.getCurrentColor())
            .on('pointerdown', () => {
                this.addHit();
                copyTarget(this);
                const { x, y } = this.getNextPosition();
                this.swap(x, y, this.dummyTarget);
            })
        texture = this.getNextTexture().key;
        this.dummyTarget = this.add.image(secondPos.x, secondPos.y + hubHeight, texture)
            .setDisplaySize(size, size)
            .setInteractive(Cursors.pointerover)
            .setTint(this.getNextColor())
            .on('pointerdown', () => {
                this.addFail();
            })
    }

    setResizeEvents({ quantity }) {
        let resize;
        switch (quantity) {
            case QuantitySettingKey.TWO:
                resize = () => resizeDoubleTargetGameplay(this);
                break;
            default: resize = () => resizeSingleTargetGameplay(this);
        }
        this.events.on('gameResize', resize)
            .once('shutdown', () => this.events.off('gameResize'));
    }

    resizeLandscape(width, height) {
        super.resizeLandscape(width, height);
        this.events.emit('gameResize');
    }

    resizePortrait(width, height) {
        super.resizePortrait(width, height);
        this.events.emit('gameResize');
    }
}

const resizeSingleTargetGameplay = (scene) => {
    const { target, game: { gameConfig } } = scene;
    const { hub: { background }, targetSize } = scene;
    scene.setTargetSize(gameConfig);
    const { x, y } = scene.getCurrentPosition();
    const { height } = background;
    target.setDisplaySize(targetSize, targetSize).setPosition(x, y + height);
}

const resizeDoubleTargetGameplay = (scene) => {
    const { target, dummyTarget, game: { gameConfig } } = scene;
    const { hub: { background }, targetSize } = scene;
    scene.setTargetSize(gameConfig);
    const { x, y } = scene.getPreviousPosition();
    const { height } = background;
    target.setDisplaySize(targetSize, targetSize).setPosition(x, y + height);
    const { x: x2, y: y2 } = scene.getCurrentPosition();
    dummyTarget.setDisplaySize(targetSize, targetSize).setPosition(x2, y2 + height);


}

const getTargetColors = (config) => {
    const { difficulty = DifficultySettingKey.SHAPE_SHIFT } = config;
    const { color = ColorSettingKey.WHITE } = config;
    if (difficulty === DifficultySettingKey.SHAPE_SHIFT)
        return [getHexColor(color)]
    else 
    return HexColor;
}

const getSessionTextures = (config) => {
    const { difficulty = DifficultySettingKey.SHAPE_SHIFT } = config;
    const { outline = OutlineSettingKey.OFF } = config;
    const { shape = ShapeSettingKey.SQUARE } = config;
    let textures = outline === OutlineSettingKey.OFF ? geomTextures : outlineGeomTextures;
    if (difficulty === DifficultySettingKey.COLOR_SHIFT)
        textures = [getShapeTexture(shape, textures)];
    else textures = Object.values(textures);
    return textures;
}

const getSwapTextureFn = (scene, config) => {
    const { difficulty = DifficultySettingKey.SHAPE_SHIFT } = config;
    if (difficulty === DifficultySettingKey.COLOR_SHIFT)
        return swapColor(scene)
    else if (difficulty === DifficultySettingKey.DOUBLE_SHIFT)
        return swapTextureAndColor(scene);
    else return swapTexture(scene)
}

const createTargetDuplicate = (scene, target) =>
    scene.add.image(0, 0, target.texture.key)
        .setScale(target.scale)
        .setTint(scene.getCurrentColor());

const copyTarget = (scene) => {
    const { target, dummyTarget } = scene;
    const { x, y, texture } = dummyTarget;
    dummyTarget.setTexture(target.texture.key);
    target
        .setTint(scene.getCurrentColor())
        .setTexture(texture.key)
        .setPosition(x, y);


}

const swapTexture = (scene) => (x, y, target) => {
    const lastTarget = createTargetDuplicate(scene, target);
    const nextTexture = scene.getNextTexture();
    target.setTexture(nextTexture.key);
    return swapImg(scene, lastTarget, x, y, target);
}

const swapColor = (scene) => (x, y, target) => {
    const lastTarget = createTargetDuplicate(scene, target)
    const nextColor = scene.getNextColor();
    target.setTint(nextColor);
    swapImg(scene, lastTarget, x, y, target);
}

const swapTextureAndColor = (scene) => (x, y, target) => {
    const lastTarget = createTargetDuplicate(scene, target);
    const nextTexture = scene.getNextTexture();
    const nextColor = scene.getRandomColor();
    target.setTexture(nextTexture.key).setTint(nextColor);
    swapImg(scene, lastTarget, x, y, target);
}

const startSwapTween = (tweens, targets, alpha, onStart = Phaser.Utils.NOOP,
    onComplete = Phaser.Utils.NOOP) => tweens.add({
        targets, alpha, onStart, onComplete,
        ease: 'Linear',
        duration: 500
    })

const swapImg = (scene, lastTarget, x, y, target) => {
    const { tweens, gameZone } = scene;
    target.setPosition(x, y + gameZone.y);
    lastTarget.setPosition(x, y + gameZone.y);
    startSwapTween(tweens, target, { from: 0, to: 1 },
        () => startSwapTween(tweens, lastTarget, { from: 1, to: 0 }),
        () => lastTarget.destroy())
}

const getScale = (size) => {
    switch (size) {
        case SizeSettingKey.SMALL: return 0.65;
        case SizeSettingKey.LARGE: return 0.85;
        case SizeSettingKey.VERY_LARGE: return 1
        default: return 0.5
    }
}

const calculateTargetSize = (width, height, size) => {
    const availableSize = width > height ? height / 3 : width / 3;
    const scale = getScale(size);
    return availableSize * scale;
}

const calculateGrid = (width, height, cols, rows, size) => {
    const padding = size / 2;
    const cellWidth = (width - size) / (cols - 1);
    const cellHeight = (height - size) / (rows - 1);
    let positions = [];
    for (let i = 0; i < rows; i++)
        for (let j = 0; j < cols; j++)
            positions.push({
                x: j * cellWidth + padding,
                y: i * cellHeight + padding
            })
    return positions;
}

const calculatePositions = (width, height, quantity, size) => {
    switch (quantity) {
        case QuantitySettingKey.TWO:
            return calculateGrid(width, height, 2, 3, size);
        default: return calculateGrid(width, height, 3, 3, size);
    }
}

const HexColor = [
    0xFFFFFF,
    0xFFFF00,
    0x0000FF,
    0xFF0000,
    0x1DFF00,
];