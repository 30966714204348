import Phaser from 'phaser';
import Situation from "../../utils/classes/situation";
import { Badges } from "../../utils/assetsPaths/badges";
import { WaitingBusSheets } from "../../utils/assetsPaths/situations/city/waitingBus";
import { createSecondaryFollower, createCharacterSprite } from "../../utils/factory";
import { generateFrameNames, addDialogTween, generateFrameNamesByArray } from "../../utils/functions";

const Vector2 = Phaser.Math.Vector2;

class WaitingBusSituation extends Situation {

    constructor(scene) {
        const markPosition = { x: 265, y: 519 };
        const mcPosition = { x: 590, y: 289 };
        super(scene, markPosition, mcPosition, Badges.SEAT_BELT);
        this.instructionLabel = "Tienes prisa y en la fila para tomar el transporte hay varias personas delante de ti, ¿qué haces?"
        this.optionsData = [{
            label: 'Me ubico adelante sin respetar el turno de los demás.',
            onclick: () => this.disrespectBusLine()
        }, {
            label: 'Espero hasta que llegue mi turno para subir al transporte.',
            onclick: () => this.respectBusLine()
        }, {
            label: 'Cedo mi turno a una mujer embarazada que llegó después de mí.',
            onclick: () => this.helpWoman()
        }]
    }

    preload() {
        const anims = this.scene.anims;
        Object.values(PregnantWomanAnims).forEach(pa => anims.create(pa))
        Object.values(NPCAnims).forEach(na => anims.create(na));
    }

    create() {
        super.create();
        this.pregnant = createPregnantWoman(this.scene);
        this.randomGuys = createRandomGuys(this.scene);

        this.scene.events.on('update', this.pregnant.move)
            .once('shutdown', () => this.scene.events.off('update', this.pregnant.move));
        return this;
    }

    reboot(onComplete) {
        this.pregnant.follower.setPosition(1405, 12);
        onComplete();
    }

    start() {
        this.scene.mc.setFlipX(true);
        const { WAIT } = this.scene.game.ludo.character.anims;
        this.pregnant.follower.goTo(new Vector2(695, 300))
            .onceMovementComplete(this.runEvent(2000, () => {
                this.scene.mc.play(WAIT.key)
                    .once('animationcomplete',
                        () => this.showHint())
                this.pregnant.sprite.setFlipX(true)
            }))
    }

    disrespectBusLine() {
        this.randomGuys[0].play(NPCAnims.BACK_GUY1.key);
        this.randomGuys[1].play(NPCAnims.BACK_GUY2.key);
        this.randomGuys[2].play(NPCAnims.BACK_GUY3.key);

        const moveToLastPoint = this.runEvent(2500, () => {
            this.scene.moveMcTo(138, 300, () => {
                this.randomGuys[0].play(NPCAnims.FRONT_GUY1.key);
                this.randomGuys[1].play(NPCAnims.FRONT_GUY2.key);
                this.randomGuys[2].play(NPCAnims.FRONT_GUY3.key);
                this.addEvent(2500, () => {
                    addDialogTween(this.scene, 'No has respetado tu posición');
                    this.randomGuys.forEach(npc => npc.anims.stop()
                        .setTexture(npc.texture.key, 0)
                    );
                })

            })
        })

        const moveToPointB = this.runEvent(2500, () => {
            this.scene.moveMcTo(278, 347, () => {
                this.randomGuys[0].play(NPCAnims.BACK_GUY1.key);
                this.randomGuys[1].play(NPCAnims.FRONT_GUY2.key);
                this.randomGuys[2].play(NPCAnims.FRONT_GUY3.key);
                moveToLastPoint();
            })
        })

        this.scene.moveMcTo(396, 345, () => {
            this.randomGuys[0].play(NPCAnims.BACK_GUY1.key);
            this.randomGuys[1].play(NPCAnims.BACK_GUY2.key);
            this.randomGuys[2].play(NPCAnims.FRONT_GUY3.key);
            moveToPointB();
        });
    }

    respectBusLine() {
        const { WAIT } = this.scene.game.ludo.character.anims;
        this.scene.mc.play(WAIT.key)
            .once('animationcomplete', () =>
                addDialogTween(this.scene, 'Fuiste paciente', this.badge))
    }

    helpWoman() {
        const { WAIT } = this.scene.game.ludo.character.anims;
        const waitSheet = this.scene.game.ludo.character.sheets.WAIT;
        const togglePositions = () => this.scene.tweens.add({
            targets: this.scene.follower,
            delay: 500,
            duration: 1000,
            x: 676,
            y: 275,
            ease: 'Power3',
            onComplete: () => {
                this.scene.mc.setTexture(waitSheet.key, 8);
                this.pregnant.follower.goTo(new Vector2(590, 289))
                    .onceMovementComplete(() => {
                        this.scene.mc.setTexture(waitSheet.key, 9);
                        this.pregnant.sprite.setFlipX(false);
                        this.addEvent(2000, () => this.scene.mc.setTexture(waitSheet.key, 10));
                        this.addEvent(4000, () => addDialogTween(this.scene, 'Fuiste amable con la mujer embarazada', this.badge))
                    })
            }
        })

        this.scene.mc.play(WAIT.key)
            .once('animationcomplete', () => {
                this.addEvent(1500, () => this.scene.mc.setTexture(waitSheet.key, 5));
                this.addEvent(3000, () => this.scene.mc.setTexture(waitSheet.key, 6));
                this.addEvent(4000, () => this.scene.mc.setTexture(waitSheet.key, 7));
                this.addEvent(5500, togglePositions)
            })

    }


}

const createPregnantWoman = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const follower = createSecondaryFollower(scene, 1405, 12);
    const sprite = createCharacterSprite(scene, WaitingBusSheets.PREGNANT_STAND, 0)
        .setDepth(7)
        .setScale(scene.mc.scale)
    const { WalkUp, WalkDown } = PregnantWomanAnims;
    const anim = ({ key }, flipX) => () => sprite.play(key).setFlipX(flipX);
    follower.onTopLeftMovement(anim(WalkUp, false))
        .onTopRightMovement(anim(WalkUp, true))
        .onBottomLeftMovement(anim(WalkDown, true))
        .onBottomRightMovement(anim(WalkDown, false))
        .onMovementComplete(() => {
            sprite.anims.stop().setTexture(WaitingBusSheets.PREGNANT_STAND.key, 0)
        })
    const move = () => {
        sprite.setPosition(follower.x * scaleX, follower.y * scaleY + y);
        sprite.setDepth(sprite.y > scene.mc.y ? 7 : 4)
    }
    return { follower, sprite, move };

}

const createRandomGuys = (scene) => {
    const { scaleX, scaleY, y } = scene.background;
    const guys = [
        scene.add.sprite(0, 0, WaitingBusSheets.RANDOM_GUY_ONE.key, 0),
        scene.add.sprite(0, 0, WaitingBusSheets.RANDOM_GUY_TWO.key, 0),
        scene.add.sprite(0, 0, WaitingBusSheets.RANDOM_GUY_THREE.key, 0),
    ]
    guys.forEach((g, index) => g.setScale(scene.mc.scale)
        .setOrigin(0.5, 0.9)
        .setX((239 + 100 * index) * scaleX)
        .setY(300 * scaleY + y)
        .setDepth(4)
        .toggleFlipX()
    );
    return guys;
}

const PregnantWomanAnims = {
    WalkDown: {
        key: 'pregnantWomanWalkDown',
        frames: generateFrameNames(WaitingBusSheets.PREGNANT_WALK_DOWN.key, 0, 5),
        frameRate: 7,
        repeat: -1
    },
    WalkUp: {
        key: 'pregnantWomanWalkUp',
        frames: generateFrameNames(WaitingBusSheets.PREGNANT_WALK_UP.key, 0, 5),
        frameRate: 7,
        repeat: -1
    }
}

const NPCFrameRate = 4;
const NPCAnims = {
    FRONT_GUY1: {
        key: 'angryGuy1Front',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_ONE.key, [3, 4]),
        frameRate: NPCFrameRate,
        repeat: -1
    },
    BACK_GUY1: {
        key: 'angryGuy1Back',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_ONE.key, [1, 2]),
        frameRate: NPCFrameRate,
        repeat: -1
    },
    FRONT_GUY2: {
        key: 'angryGuy2Front',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_TWO.key, [3, 4]),
        frameRate: 4,
        repeat: -1
    },
    BACK_GUY2: {
        key: 'angryGuy2Back',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_TWO.key, [1, 2]),
        frameRate: NPCFrameRate,
        repeat: -1
    },
    FRONT_GUY3: {
        key: 'angryGuy3Front',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_THREE.key, [3, 4]),
        frameRate: NPCFrameRate,
        repeat: -1
    },
    BACK_GUY3: {
        key: 'angryGuy3Back',
        frames: generateFrameNamesByArray(WaitingBusSheets.RANDOM_GUY_THREE.key, [1, 2]),
        frameRate: NPCFrameRate,
        repeat: -1
    }
}

export default WaitingBusSituation;