import { buttonPressAnimation, mouseOverAnimatioArrows } from './AnimatedFunctions';
export function carouselLogic(cardComponentArray, rigthButton, leftButton, scene) {
    var carouselFirstGroupPosition = 0
    var carouselDisplayCount = 4
    var caruselGroupCount = 0
    var handCursorOn = true
    for (var i = 0; i < cardComponentArray.length; i++) {
        scene.add.existing(cardComponentArray[i])
        if (i > 3) {
            cardComponentArray[i].setVisible(false)
        }
    }

    if (cardComponentArray.length <= 4) {
        rigthButton.setAlpha(0.4)
        handCursorOn = false
    }

    rigthButton.setInteractive({ useHandCursor: handCursorOn }).on('pointerdown', () => {
        var isGrouped = true
        if (carouselFirstGroupPosition < cardComponentArray.length - 1 && carouselDisplayCount < cardComponentArray.length) {
            buttonPressAnimation(scene, rigthButton)
            scene.sound.play('clickSound')
            while ((carouselFirstGroupPosition < cardComponentArray.length - 1) && isGrouped) {
                cardComponentArray[carouselFirstGroupPosition].setVisible(false)
                carouselFirstGroupPosition++
                if (carouselDisplayCount < cardComponentArray.length) {
                    cardComponentArray[carouselDisplayCount].setVisible(true)
                    carouselDisplayCount++
                }
                if (carouselFirstGroupPosition % 4 === 0) {
                    isGrouped = false
                    caruselGroupCount++
                    leftButton.setAlpha(1)
                }
            }
        }
        if (carouselDisplayCount === cardComponentArray.length) {
            rigthButton.setAlpha(0.4)

        }

    }).on('pointerover', () => {
        if (rigthButton.alpha === 1)
            mouseOverAnimatioArrows(scene, rigthButton, rigthButton.scaleX * 1.1, rigthButton.scaleY * 1.1)
    }).on('pointerout', () => {
        if (rigthButton.alpha === 1)
            mouseOverAnimatioArrows(scene, rigthButton, rigthButton.scaleX / 1.1, rigthButton.scaleY / 1.1)

    })

    leftButton.setInteractive({ useHandCursor: handCursorOn }).on('pointerdown', () => {
        var isGrouped = true
        var isGropShown = true

        if (0 < carouselFirstGroupPosition && 4 < carouselDisplayCount) {
            buttonPressAnimation(scene, leftButton)
            scene.sound.play('clickSound')
            while ((0 < carouselFirstGroupPosition) && isGropShown) {
                carouselFirstGroupPosition--
                cardComponentArray[carouselFirstGroupPosition].setVisible(true)
                if (carouselDisplayCount > (4 * caruselGroupCount) && isGrouped) {
                    carouselDisplayCount--
                    cardComponentArray[carouselDisplayCount].setVisible(false)
                }
                if (carouselFirstGroupPosition % 4 === 0) {
                    isGropShown = false
                }
                if (carouselDisplayCount % (4 * caruselGroupCount) === 0) {
                    isGrouped = false
                    caruselGroupCount--
                    rigthButton.setAlpha(1)
                }

            }
        } if (carouselFirstGroupPosition === 0) {
            leftButton.setAlpha(0.4)

        }

    }).on('pointerover', () => {
        if (leftButton.alpha === 1)
            mouseOverAnimatioArrows(scene, leftButton, leftButton.scaleX * 1.1, leftButton.scaleY * 1.1)
    }).on('pointerout', () => {
        if (leftButton.alpha === 1)
            mouseOverAnimatioArrows(scene, leftButton, leftButton.scaleX / 1.1, leftButton.scaleY / 1.1)
    })


}