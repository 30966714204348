import Phaser from 'phaser';
import { GameFont } from '../utils/constants';
const Rectangle = Phaser.GameObjects.Rectangle;
const Text = Phaser.GameObjects.Text;

export default class HeaderBar extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label, color = 0x2F7BA1) {
        super(scene, x, y);
        this.background = new Rectangle(scene, 0, 0, 10, 10, color).setOrigin(0);
        this.label = new Text(scene, 0, 0, label, HeaderBarTextStyle).setOrigin(0.5);
        this.add([this.background, this.label]);
    }

    getHeight() {
        return this.background.displayHeight;
    }

    resize(width, height) {
        this.background.setDisplaySize(width, height);
        this.label.setFontSize(`${height * 0.35}px`)
            .setPosition(width / 2, height / 2);
        return this;
    }
}

const HeaderBarTextStyle = {
    fontFamily: GameFont,
    color: 'white',
    align: 'center'
}