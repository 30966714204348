import Phaser from 'phaser';
import { Btns } from "../utils/assetsPaths/btn";
import { GameFont } from '../utils/constants';
import { scaleImage } from '../utils/resize';
const Image = Phaser.GameObjects.Image;
const Text = Phaser.GameObjects.Text;

export default class MessageBox extends Phaser.GameObjects.Container {

    constructor(scene, x, y, label) {
        super(scene, x, y);
        this.background = new Image(scene, 0, 0, Btns.MESSAGE_BOX.key);
        this.label = new Text(scene, 0, 0, label, MessageBoxTextStyle).setOrigin(0.5);
        this.add([this.background, this.label]);
    }

    resize(availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier) {
        scaleImage(this.background, availableSpaceWidth, availableSpaceHeight, padding, scaleMultiplier);
        this.label.setFontSize(`${this.background.displayHeight * 0.4}px`)
    }
}

const MessageBoxTextStyle = {
    fontFamily: GameFont
}