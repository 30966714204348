import GameSessionScene from "../../HabCogGame/scenes/gameSessionScene";
import TargetFeedback from '../../HabCogGame/utils/assetsPaths/targets';
import { getDifferentRandomItem } from "../../HabCogGame/utils/functions";
import { DifficultySettingKey, ColorScaleSettingKey } from '../utils/settings';
import { GeomAssets } from '../utils/assets';
import { createRandomNumericArray } from '../../HabCogGame/utils/imgSelection';
import { ColorScalePipelineKey } from '../../HabCogGame/utils/constants';

const { GOOD_TARGET } = TargetFeedback;

export default class SessionScene extends GameSessionScene {

    setGameplay() {
        const { difficulty, quantity, size, feedback, color } = this.game.userConfig;
        this.gameArea = this.add.container(0, this.hubHeight);
        this.sTextures = this.setSessionTextures(difficulty);
        this.createImgsList(quantity, size, this.sTextures[0]);
        this.setFailFn(feedback);
        this.fillTargetIndexes();
        this.fillTextureIndexes();
        this.setColorScale(color);
        this.startGameplay();
    }

    setSessionTextures(difficulty) {
        switch (difficulty) {
            case DifficultySettingKey.THREE_SHAPES:
                return GeomAssets.REGULAR.THREE_GEOM;
            case DifficultySettingKey.FOUR_SHAPE:
                return GeomAssets.REGULAR.FOUR_GEOM;
            default:
                return GeomAssets.REGULAR.TWO_GEOM;
        }
    }

    setColorScale(color) {
        if (color !== ColorScaleSettingKey.REGULAR) {
            const pipelineKey = getTextureTintKey(color);
            this.imgList.forEach(img => img.setPipeline(pipelineKey));
            this.gameArea.setDepth(0);
        }
    }

    getNextTarget() {
        let index = this.targetIndexes.pop();
        if (index === undefined) {
            this.fillTargetIndexes();
            index = this.targetIndexes.pop();
        }
        return this.targets[index];
    }

    getNextTexture() {
        let index = this.textureIndexes.pop();
        if (index === undefined) {
            this.fillTextureIndexes();
            index = this.textureIndexes.pop();
        }
        return this.sTextures[index];
    }

    fillTargetIndexes() {
        const length = this.targets.length;
        this.targetIndexes = createRandomNumericArray(length);
    }

    fillTextureIndexes() {
        const length = this.sTextures.length;
        this.textureIndexes = createRandomNumericArray(length);
    }

    setTargetsTexture() {
        const hitTexture = this.getNextTexture();
        const getTexture = () => getDifferentRandomItem(this.sTextures, hitTexture);
        this.targets.forEach(img => img.setTexture(getTexture().key));
        this.imgList[0].setTexture(hitTexture.key);
        return this.getNextTarget().setTexture(hitTexture.key);
    }

    setTargetsInteractive(hitTarget) {
        this.targets.forEach(img => img
            .on('pointerdown', () => this.addFailTarget(img)));
        hitTarget.off('pointerdown')
            .once('pointerdown', () => this.addHit(hitTarget))
    }

    addHit(img) {
        super.addHit();
        this.targetsHitted++;
        this.createFeedbackImage(img, GOOD_TARGET);
        this.restartGameplay();
    }

    restartGameplay() {
        this.imgList.forEach(img => img.off('pointerdown'));
        this.time.addEvent({
            delay: 1000,
            callback: () => {
                this.feedbackImgs.forEach(img => img.destroy());
                this.startGameplay()
            }
        })
    }
    startGameplay() {
        this.feedbackImgs = [];
        const hitTarget = this.setTargetsTexture();
        this.setTargetsInteractive(hitTarget);
    }
}

const getTextureTintKey = (color) => {
    const { RED, BLUE, GREEN, YELLOW } = ColorScaleSettingKey;
    switch (color) {
        case RED:
            return ColorScalePipelineKey.RED;
        case BLUE:
            return ColorScalePipelineKey.BLUE;
        case GREEN:
            return ColorScalePipelineKey.GREEN;
        case YELLOW:
            return ColorScalePipelineKey.YELLOW;
        default:
            return ColorScalePipelineKey.GRAY;
    }
}
