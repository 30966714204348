import { SizeSettingAssets } from "../../HabCogGame/utils/settingsConstants/sizeSetting";
import { QuantitySettingAssets } from "../../HabCogGame/utils/settingsConstants/quantitySetting";
import { DifficultySettingAssets } from "./settings";
import { TargetFeedbackAssets } from "../../HabCogGame/utils/assetsPaths/targets";
import { TimeSettingAssets } from "../../HabCogGame/utils/settingsConstants/timeSetting";

export const PuzzleGameIcons = {
    CHECK: {
        key: 'puzzleCheckIcon',
        url: './PuzzleGameAssets/icons/checkIcon.png'
    },
    MOVE: {
        key: 'puzzleMoveIcon',
        url: './PuzzleGameAssets/icons/moveIcon.png'
    },
    ROTATE: {
        key: 'puzzleRotateIcon',
        url: './PuzzleGameAssets/icons/rotateIcon.png'
    }
}

export const PuzzleGamePhotosAssets = [
    {
        key: 'animalPhoto1',
        url: './photos/animals/animal1.png'
    }, {
        key: 'animalPhoto12',
        url: './photos/animals/animal12.png'
    }, {
        key: 'animalPhoto14',
        url: './photos/animals/animal14.png'
    }, {
        key: 'animalPhoto16',
        url: './photos/animals/animal16.png'
    }, {
        key: 'carbPhoto1',
        url: './photos/carbs/carb1.png'
    }, {
        key: 'carbPhoto2',
        url: './photos/carbs/carb2.png'
    }, {
        key: 'clothesPhoto3',
        url: './photos/clothes/clothes3.png'
    }, {
        key: 'clothesPhoto4',
        url: './photos/clothes/clothes4.png'
    }, {
        key: 'clothesPhoto5',
        url: './photos/clothes/clothes5.png'
    }, {
        key: 'clothesPhoto11',
        url: './photos/clothes/clothes11.png'
    }, {
        key: 'clothesPhoto12',
        url: './photos/clothes/clothes12.png'
    }, {
        key: 'clothesPhoto19',
        url: './photos/clothes/clothes19.png'
    }, {
        key: 'clothesPhoto20',
        url: './photos/clothes/clothes20.png'
    }, {
        key: 'clothesPhoto23',
        url: './photos/clothes/clothes23.png'
    }, {
        key: 'clothesPhoto24',
        url: './photos/clothes/clothes24.png'
    }, {
        key: 'dairyPhoto2',
        url: './photos/dairies/dairy2.png'
    }, {
        key: 'fruitPhoto1',
        url: './photos/fruits/fruit1.png'
    }
];

export const SessionAssets = [
    ...Object.values(PuzzleGameIcons),
    ...PuzzleGamePhotosAssets,
    ...SizeSettingAssets,
    ...QuantitySettingAssets,
    ...DifficultySettingAssets,
    ...TargetFeedbackAssets,
    ...TimeSettingAssets
]